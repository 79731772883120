import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import { updateEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import { createEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
//import FormBody from './EgsMTraderAdmForm';
import FormBody from './EgsMTraderNewForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMTraderNew({ isOpen, doClose, processId }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    id: '',
    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    certifyId: '',
    traderKbn: '',
    traderName: '',
    traderKana: '',
    traderDispName: '',
    jwnetNo: '',
    jwnetEdipass: '',
    jwnetSubno: '',
    exhaustPasswd: '',
    prefectureCd: '',
    zip: '',
    address: '',
    address2: '',
    address3: '',
    address4: '',
    phone: '',
    fax: '',
    email1: '',
    email1Flg: '',
    email2: '',
    email2Flg: '',
    email3: '',
    email3Flg: '',
    memo: '',
    egsUserFlg: 0,
    ediClientKbn: '',
    effectiveflg: '',
    chargeDivision: '',
    mkKbn3000: '',

    rawpasswd: '',
    groupCd1: '',
    groupCd2: '',
    groupCd3: '',
    groupCd4: '',
    groupCd5: '',
    groupId1: 0,
    groupId2: 0,
    groupId3: 0,
    groupId4: 0,
    groupId5: 0,

    message1: 'aaa'

  });

  useEffect(() => {
    handleGetData(processId);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMTraderDetail(processId);
      // console.log(res.data);
      setValue({

        id: res.data.id,
        companyCd: res.data.companyCd,
        egsMCompanyId: res.data.egsMCompanyId,
        traderCd: res.data.traderCd,
        certifyId: res.data.certifyId,
        traderKbn: res.data.traderKbn,
        traderName: res.data.traderName,
        traderKana: res.data.traderKana,
        traderDispName: res.data.traderDispName,
        jwnetNo: res.data.jwnetNo,
        jwnetEdipass: res.data.jwnetEdipass,
        jwnetSubno: res.data.jwnetSubno,
        exhaustPasswd: res.data.exhaustPasswd,
        prefectureCd: res.data.prefectureCd,
        zip: res.data.zip,
        address: res.data.address,
        address2: res.data.address2,
        address3: res.data.address3,
        address4: res.data.address4,
        phone: res.data.phone,
        fax: res.data.fax,
        email1: res.data.email1,
        email1Flg: res.data.email1Flg,
        email2: res.data.email2,
        email2Flg: res.data.email2Flg,
        email3: res.data.email3,
        email3Flg: res.data.email3Flg,
        memo: res.data.memo,
        egsUserFlg: res.data.egsUserFlg,
        ediClientKbn: res.data.ediClientKbn,
        effectiveflg: res.data.effectiveflg,
        chargeDivision: res.data.chargeDivision,
        mkKbn3000: res.data.mkKbn3000,

        rawpasswd: res.data.rawpasswd,
        groupCd1: res.data.groupCd1,
        groupCd2: res.data.groupCd2,
        groupCd3: res.data.groupCd3,
        groupCd4: res.data.groupCd4,
        groupCd5: res.data.groupCd5,
        groupId1: res.data.groupId1,
        groupId2: res.data.groupId2,
        groupId3: res.data.groupId3,
        groupId4: res.data.groupId4,
        groupId5: res.data.groupId5,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {

//    let str = "あいうえお";

    // 全角文字チェック
    if (value.email1.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)) {
      //全角文字
      // console.log("全角文字です",value.email1,'>');  // alert
      value.message1 = "全角文字です"
//      setErrorEmail1(true);
    } else {
      //全角文字以外
      // console.log("全角文字ではありません",value.email1,'>');
      value.message1 = "全角文字ではありません"
//      setErrorEmail1(false);
    }

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();

    if (window.confirm(' 事業場マスタ「' + value.traderCd + '」を新規登録します。よろしいですか？')) {
      try {
//      const res = await updateEgsMTrader(processId, params);
        const res = await createEgsMTrader(params);
        // console.log('res',res);
        handleCancel();
//        history.push('/egs_m_trader_adm');
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }

/*
    try {
//      const res = await updateEgsMTrader(processId, params);
      const res = await createEgsMTrader(params);
      // console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
*/
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
//      traderCd: value.traderCd,
      traderCd: value.jwnetNo,  // traderCd
      certifyId: value.certifyId,
      traderKbn: value.traderKbn,
      traderName: value.traderName,
      traderKana: value.traderKana,
      traderDispName: value.traderDispName,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      prefectureCd: value.prefectureCd,
      zip: value.zip,
      address: value.address,
      address2: value.address2,
      address3: value.address3,
      address4: value.address4,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      email1Flg: value.email1Flg,
      email2: value.email2,
      email2Flg: value.email2Flg,
      email3: value.email3,
      email3Flg: value.email3Flg,
      memo: value.memo,
      egsUserFlg: value.egsUserFlg,
      ediClientKbn: value.ediClientKbn,
      effectiveflg: value.effectiveflg,
      chargeDivision: value.chargeDivision,
      mkKbn3000: value.mkKbn3000,

      rawpasswd: value.rawpasswd,
      groupCd1: value.groupCd1,
      groupCd2: value.groupCd2,
      groupCd3: value.groupCd3,
      groupCd4: value.groupCd4,
      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}  // doClose
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>事業場マスタ新規作成</h2>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMTraderNew;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
