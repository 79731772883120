import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, TextField, Typography, Box, Checkbox, MenuItem, Select } from '@material-ui/core';
import { updateWkExhaustPeriod, getWkExhaustPeriodDetail } from '../../lib/api/wk_exhaust_period';
//import FormBody from './ExhaustDateForm';
//import FormBody from './ExhaustDateNewForm';

//import { updateUser } from '../../lib/api/user';
//import dayjs from 'dayjs';  // 検索用

function ExhaustDateEdit(dataList, currentUser, handleGetList) {
  const [value, setValue] = useState({
    id: '',
    exhaustFrom: '',
    exhaustTo: '',
  });

//  const query = useParams();

  const history = useHistory();

//  const today = dayjs().format('YYYY-MM-DD');
//  // console.log('today',today);
//  value.exhaustFrom = today;
//  value.exhaustTo = today;


  useEffect(() => {
    handleGetData(); //query
  }, []); //query


  const handleGetData = async () => {  // query
    try {
      const res = await getWkExhaustPeriodDetail(1);  // query.id
      // console.log(res.data);
      setValue({
        id: res.data.id,
        exhaustFrom: res.data.exhaustFrom,
        exhaustTo: res.data.exhaustTo,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {

    if (window.confirm(' 排出期間を更新します。よろしいですか？')) {
      try {

        e.preventDefault();
        // 追加
        const params = generateParams();
    //    const paramt = generateUserParams();
        try {
          const res = await updateWkExhaustPeriod(1, params);  // query.id
          // console.log('res',res);
    //      const ret = await updateUser(query.id, paramt);
    //      // console.log('ret',ret);
    //      history.push('/egs_m_traders');
          window.location.reload();
//          handleGetList();    // ここには記載出来ない
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }

      } catch (e) {
        console.log(e);
      }
    }



  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      exhaustFrom: value.exhaustFrom,
      exhaustTo: value.exhaustTo,
    };
    return params;
  };

  /*
    // signUp用パラメータのオブジェクト構造を加工
    const generateUserParams = () => {
      const signUpParams = {

        name: value.traderName,
        email: value.email1,
        password: value.password,
        passwordConfirmation: value.passwordConfirmation,
        trader_cd:value.traderCd,
        traderName: value.traderName,


      };
      return signUpParams;
    };
*/

  return (
    <>
{/*}
      <h1>排出期間変更</h1>
          <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20 }}>排出期間①：　</Typography>
          <TextField id="exhaustFrom" value={value.exhaustFrom}
            onChange={(e) => handleChange(e)} label="" type={"date"}
          //  InputLabelProps={{ shrink: true, }}
          />
          <Typography variant="h7">　～　</Typography>
          <TextField
            id="exhaustTo" value={value.exhaustTo}
            onChange={(e) => handleChange(e)} label="" type={"date"} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button'
            onClick={(e) => handleSubmit(e)} variant='contained' color='primary' style={{ marginLeft: 24, marginBottom : 20 }} >
            【更新NG】排出期間変更
          </Button>
*/}
<Box>
          <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 30 }}>排出期間：</Typography>
          <TextField id='exhaustFrom' value={value.exhaustFrom} name='exhaustFrom'
             onChange={(e) => handleChange(e)} variant="outlined" halfWidth type='date' margin="dense" style={{ fontWeight: 700 , marginBottom : 20 , marginRight : 20 }} />
          <Typography variant="h7" >～　</Typography>
          <TextField id='exhaustTo' value={value.exhaustTo} name='exhaustTo'
            onChange={(e) => handleChange(e)} variant="outlined" halfWidth type='date' margin="dense" style={{ fontWeight: 700 , marginBottom : 20 , marginRight : 20 }} />
          <Button
            type='button'     // ～～～～button とsubmit の違いは？？？？？？？？～～～～～～
            onClick={(e) => handleSubmit(e)} variant='contained' color='primary' style={{ marginRight: 10 ,      marginBottom : 20 }} >
           {'排出期間更新'}  {/* buttonType */}
          </Button>
</Box>
    </>
  );
}
export default ExhaustDateEdit;

