import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './app.css';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro';
import { getChargeOperation } from '../../lib/api/charge';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "1000px",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
}));

function EditDelReqListTable(props) {
  const { reqdataList, setReqDataList } = props; // dataListをpropsから受け取り、更新するためのsetDataListも受け取る
  const classes = useStyles();
  const [open2, setOpen2] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowClick = (params) => {
    const newRow = params.row;
    if (!selectedRows.find(row => row.id === newRow.id)) {
      setSelectedRows([...selectedRows, newRow]); // 新しい行を選択状態に追加
      setReqDataList([...reqdataList, newRow]); // 新しい行をdataListに追加
    }
  };

  const getRowClassName = (params) => {
    if (params.row.status === '処理前') {
      return 'pre-processing-row';
    } else if (params.row.status === '処理中') {
      return 'processing-row';
    } else {
      return '';
    }
  }

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      width: 80
    },
    {
      field: 'reqDate',
      headerName: '依頼日',
      width: 100
    },
    {
      field: 'ediCode',
      headerName: '依頼事業者',
      width: 220
    },
    {
      field: 'reqCharge',
      headerName: '依頼担当者',
      width: 100
    },
    {
      field: 'status',
      headerName: '処理状況',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 220
    },
    {
      field: 'garbageId',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 120
    },
    {
      field: 'reqKind',
      headerName: '依頼種別',
      width: 140
    },
    {
      field: 'reqComment',
      headerName: '補足事項',
      width: 100
    },
    {
      field: 'reqItem_1',
      headerName: '依頼項目１',
      width: 120
    },
    {
      field: 'oldData_1',
      headerName: '修正前１',
      width: 100
    },
    {
      field: 'newData_1',
      headerName: '修正後１',
      width: 100
    },
    {
      field: 'reqItem_2',
      headerName: '依頼項目２',
      width: 120
    },
    {
      field: 'oldData_2',
      headerName: '修正前２',
      width: 100
    },
    {
      field: 'newData_2',
      headerName: '修正後２',
      width: 100
    },
    {
      field: 'reqItem_3',
      headerName: '依頼項目３',
      width: 120
    },
    {
      field: 'oldData_3',
      headerName: '修正前３',
      width: 100
    },
    {
      field: 'newData_3',
      headerName: '修正後３',
      width: 100
    },
    {
      field: 'reqItem_4',
      headerName: '依頼項目４',
      width: 120
    },
    {
      field: 'oldData_4',
      headerName: '修正前４',
      width: 100
    },
    {
      field: 'newData_4',
      headerName: '修正後４',
      width: 100
    },
    {
      field: 'reqItem_5',
      headerName: '依頼項目５',
      width: 120
    },
    {
      field: 'oldData_5',
      headerName: '修正前５',
      width: 100
    },
    {
      field: 'newData_5',
      headerName: '修正後５',
      width: 100
    },
    {
      field: 'reqItem_6',
      headerName: '依頼項目６',
      width: 120
    },
    {
      field: 'oldData_6',
      headerName: '修正前６',
      width: 100
    },
    {
      field: 'newData_6',
      headerName: '修正後６',
      width: 100
    },
    {
      field: 'reqItem_7',
      headerName: '依頼項目７',
      width: 120
    },
    {
      field: 'oldData_7',
      headerName: '修正前７',
      width: 100
    },
    {
      field: 'newData_7',
      headerName: '修正後７',
      width: 100
    },
    {
      field: 'reqItem_8',
      headerName: '依頼項目８',
      width: 120
    },
    {
      field: 'oldData_8',
      headerName: '修正前８',
      width: 100
    },
    {
      field: 'newData_8',
      headerName: '修正後８',
      width: 100
    },
    {
      field: 'reqItem_9',
      headerName: '依頼項目９',
      width: 120
    },
    {
      field: 'oldData_9',
      headerName: '修正前９',
      width: 100
    },
    {
      field: 'newData_9',
      headerName: '修正後９',
      width: 100
    },
    {
      field: 'reqItem_10',
      headerName: '依頼項目１０',
      width: 120
    },
    {
      field: 'oldData_10',
      headerName: '修正前１０',
      width: 100
    },
    {
      field: 'newData_10',
      headerName: '修正後１０',
      width: 100
    },
  ]

  return (
    <>
      <Backdrop className={classes.backdrop} open={open2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.container}>
        <Box sx={{ height: 300, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={reqdataList}
            density="compact"
            showColumnRightBorder
            showCellRightBorder
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            // onRowClick={handleRowClick}
            getRowClassName={getRowClassName} // ここに関数を設定
          />
        </Box>
      </div>
    </>
  );
}
const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#c71585',
      color: '#fff',
    },
    '& .pre-processing-row': { // 処理前の行に適用するスタイル
      backgroundColor: '#f5deb3', 
    },
    '& .processing-row': { // 処理中の行に適用するスタイル
      backgroundColor: '#add8e6', // 薄青色
    }
  }
}
export default EditDelReqListTable;