import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from "qrcode.react"

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SpaceRow from '../commons/SpaceRow';
import './app.css';

import '@grapecity/wijmo.styles/wijmo.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  jaJP
} from '@mui/x-data-grid-pro'


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '担当者マスタ',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,  // 650
  },
  fontWeight: {
    fontWeight: 2000,  // 900
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "60vw",    // "85vw"    ⇒　横幅を20％に設定
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function ExhaustSumTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
  // console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);

  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);

  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };

  const handleRowClick = (param, event) => {
    // console.log(param.row);
    setDataList2(param.row);
  };

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    // console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const cols = [
    {
      field: 'yyyy',
      headerName: '年',
      width: 50
    },
    {
        field: 'mm',
        headerName: '月',
        width: 50
    },
    {
      field: 'amount',
      headerName: '数量',
      width: 80
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 80
    },
    {
        field: 'exhaustName',
        headerName: '排出事業者',
        width: 300
    },
    {
      field: 'wasteclassName',
      headerName: '廃棄物名称',
      width: 300
  },
]

  const qrCodeUrl = "https://www.sdgs12.com/" + currentUser.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.chargeCd
  // console.log('qrCodeUrl',qrCodeUrl);

  return (
    <>

      <div className={classes.container}>
        <DataGridPro
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          sx={styles.grid}
          columns={cols}
          rows={dataList}
          density="compact"
          autoHeight
          // checkboxSelection
          // disableSelectionOnClick
          // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
        </div>
      <SpaceRow height={20} />

    </>

  );
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default ExhaustSumTable;
