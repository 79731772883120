// /src/lib/api/egs_m_packing.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMPackingList = () => {
  return client.get('/egs_m_packings');
};

// 事業所指定一覧 idは事業所CD
export const getEgsMPackingUser = (id) => {
  return client.get(`/egs_m_packings/${id}/showuser`);
};

// 詳細（追加・更新用）
export const getEgsMPackingDetail = (id) => {
  return client.get(`/egs_m_packings/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMPacking = (params) => {
  return client.post('/egs_m_packings', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMPacking = (id, params) => {
  return client.patch(`/egs_m_packings/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMPacking = (id) => {
  return client.delete(`/egs_m_packings/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
