// /src/lib/api/process_mobile.js
import client from './client';
//import Cookies from 'js-cookie';

// 一覧
export const getProcessMobileList = () => {
  return client.get('/process_mobiles');
};

// 事業所指定一覧 idは事業所CD
export const getProcessMobileUser = (id) => {
  return client.get(`/process_mobiles/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD
export const getProcessMobileUser47 = (id) => {
  return client.get(`/process_mobiles/${id}/showuser47`);
};

// 事業所指定一覧 idは事業所CD
export const getProcessMobileId = (id) => {
  return client.get(`/process_mobiles/${id}/showid`);
};

// グループ指定一覧 idはグループ１と２
export const getProcessMobileGroup = (id) => {
  return client.get(`/process_mobiles/${id}/showgroup`);
};
// 処理フロー番号指定 idは処理フロー番号
export const getProcessMobileReserveNo = (id) => {
  return client.get(`/process_mobiles/${id}/showreserveno`);
};

// 詳細（詳細表示用）
export const getProcessMobileDetail = (id) => {
  console.log(id)
  return client.get(`/process_mobiles/${id}`);    // process_mobiles
};

// 運用中廃棄物 idは'all'
export const getProcessMobileOperation = (id) => {
  return client.get(`/process_mobiles/${id}/showope`);
};
