// Form.jsx
//import React from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import './app.css';

import React, { useState, useEffect } from 'react';

import { useRef } from 'react';

// style
// style
import {
//  Button,
//  TextField,
//  MenuItem,
//  Select,
  Box,
  Grid,
//  Backdrop,
//  CircularProgress,
  Typography,
//  InputLabel,
//  RadioGroup,
//  FormControlLabel,
//  Radio,
//  Checkbox
} from '@material-ui/core';
//import { Checkbox, MenuItem, Select } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';
import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getEgsMPrefectureList } from '../../lib/api/egs_m_prefecture';
//import { getEgsMAddressList } from '../../lib/api/egs_m_address';
import { getEgsMGroupList } from '../../lib/api/egs_m_group';
import { getBusinessTypeList } from '../../lib/api/business_type';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },


  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },

  title_box2: {
    background: "rgb(224, 224, 224)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },


}));



const EgsMTraderNewForm = (props) => {
  const classes = useStyles();

/*    6/1 必須入力チェック。yupのインストールが必要…

  const basicSchema = Yup.object().shape({
    checkBox: Yup.boolean()
        .oneOf([true], 'チェックが必要です'),
    jwnetEdipass: Yup.string()
        .required('必須項目です'),
    pullDown: Yup.string()
        .oneOf(['one', 'two', 'three'], 'いずれかを選択してください'),
});

*/

  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);


  const history = useHistory();
  const { handleBack,      handleChange, handleSubmit, value, buttonType,     currentUser   } = props;

  const [open, setOpen] = React.useState(false);
  const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}

  useEffect(() => {
    handleGetList();
  }, []);

  const [dataListPrefecture,setDataListPrefecture] = useState([]);  {/* 都道府県 */}
//  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  const [dataListGroup,setDataListGroup] = useState([]);  {/* グループ */}
  const [dataListBusiness,setDataListBusiness] = useState([]);  {/* 職種 */}

  const [errorJwnetNo, setErrorJwnetNo] = useState(false);  // 加入者番号
  const [errorJwnetSubno, setErrorJwnetSubno] = useState(false);  // JWNET事業場番号
  const [errorTraderCd, setErrorTraderCd] = useState(false);  // 事業場CD
  const [errorCertifyId, setErrorCertifyId] = useState(false);  // 認証タグID
  const [errorTraderKbn, setErrorTraderKbn] = useState(false);  // 事業者区分
  const [errorTraderName, setErrorTraderName] = useState(false);  // 事業場名
  const [errorTraderKana, setErrorTraderKana] = useState(false);  // ふりがな

  const checkValidation = () => {
    let available = true;
    if (!value.jwnetNo ||
//      !value.traderCd ||
      !value.certifyId ||
      !value.traderKbn ||
      !value.traderName ||
      !value.traderKana
    ) {
//        console.log(
//         !value.jwnetNO,
// //        !value.traderCd,
//         !value.certifyId,
//         !value.traderKbn,
//         !value.traderName,
//         !value.traderKana
//       );
      // console.log(value.approvalflg);
      available = false;
//      setExhaustChargeId(!value.exhaustChargeId);
      setErrorJwnetNo(!value.jwnetNo);
      setErrorJwnetSubno(!value.jwnetSubno || value.jwnetSubno > 999 || value.jwnetSubno < 0);
//      setErrorTraderCd(!value.traderCd);
      setErrorCertifyId(!value.certifyId);
      setErrorTraderKbn(!value.traderKbn);
      setErrorTraderName(!value.traderName);
      setErrorTraderKana(!value.traderKana);
//      setErrorApproveflg(value.approvalflg !== "1");
    }
    return available;
  }



  const handleGetList = async () => {
    try {
     setOpen(!open);
      const dataListPrefecture = await getEgsMPrefectureList();
      setDataListPrefecture(dataListPrefecture.data);
//    // console.log('prefectureCd',value.prefecureCd,'dataListPrefecure',dataListPrefecure.data);
      const dataListGroup = await getEgsMGroupList();
      setDataListGroup(dataListGroup.data);

      const dataListBusiness = await getBusinessTypeList();
      setDataListBusiness(dataListBusiness.data);

//      const dataListAddress = await getEgsMAddressList();
//      setDataListAddress(dataListAddress.data);
//    // console.log('zip',value.zip,'dataListAddress',dataListAddress.data);

      const resT01 = await getEgsMTraderList();
  //    // console.log(resT01.data);
      setDataListT01(resT01.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


  const handleChange2 = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
  };



const prefecturedataList = dataListPrefecture.filter(dataListPrefecture => { return dataListPrefecture.prefectureCd != '' });
//// console.log('prefecuredataList',prefecuredataList);
const groupdataList = dataListGroup.filter(dataListGroup => { return dataListGroup.groupCd != '' });

const businessdataList = dataListBusiness.filter(dataListBusiness => { return dataListBusiness.businessTypeCd != '' });


//const addressdataList = dataListAddress.filter(dataListAddress => { return dataListAddress.zip == value.zip });
//const addressdataList = dataListAddress.filter(dataListAddress => { return dataListAddress.zip != '' });
//// console.log('addressdataList',addressdataList);

  // 排出事業場　変更不可
//  // console.log('排出・運搬先・運搬先担当・最終<',value.egsMTraderId,':',value.traderCd,':',value.chargeCd,'>')
//  // console.log('事業場<',value.id,'>')
//0511  // console.log('事業場<',value.traderCd,'>',currentUser.traderCd)
//  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.id });
//0511  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.traderCd == value.traderCd });
  //// console.log('traderDataList00',traderdataList00);

  return (
    <>

      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>    {/*  egs_m_traders_adm  */}
          戻る
        </Button>
      </div>




      <form className={classes.root} noValidate autoComplete='off'>

<table>
{/*}  <tr><th>事業場CD</th><td><TextField id='jwnetNo' label='事業場CD' variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense" value={value.jwnetNo} /> </td></tr> */}
{/*}  <tr><th>事業場CD</th><td><TextField id='jwnetNo' label='事業場CD' variant="standard" type='text' name='jwnetNo' halfWidth margin="dense" value={value.jwnetNo} /> </td></tr> */}
  </table>
      </form>

<table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場CD
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo' hiddenLabel variant="standard" type='text' name='jwnetNo' halfWidth margin="dense"  // outlined
//              onChange={(e) => handleChange(e)}
              value={value.jwnetNo} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

</tr></table>
<tr></tr>

<grid></grid>

<div></div>
<div className={classes.container}></div>
<tr></tr>
<table><tr>


      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              認証タグID
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='certifyId' hiddenLabel variant="outlined" type='text' name='certifyId' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorCertifyId}
              value={value.certifyId} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
           <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業者区分
              </Typography>
          </Box>
          </Grid>
          <Grid item xs={6}>
{/*}            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo' hiddenLabel variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.jwnetNo} fullWidth />
      </Box>  */}
              <Select
                id='traderKbn'
                label='事業者区分'
                variant="outlined"
                type='text'
                name='traderKbn'
                margin="dense"

                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}}

                onChange={(e) => handleChange(e)}
                error={errorTraderKbn}
                value={value.traderKbn}
              >
                <MenuItem value={value.traderKbn}>
                  <em>事業者区分</em>
                </MenuItem>
                <MenuItem value={'1000000000'}>排出事業者  :1000000000</MenuItem>
                <MenuItem value={'0100000000'}>収集運搬業者:0100000000</MenuItem>
                <MenuItem value={'0010000000'}>積替保管業者:0010000000</MenuItem>
                <MenuItem value={'0001000000'}>中間排出業者:0001001000</MenuItem>
                <MenuItem value={'0001001000'}>中間排出業者:0001001000</MenuItem>
                <MenuItem value={'0000100000'}>最終処分業者:0000100000</MenuItem>
               </Select>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場名称
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderName' hiddenLabel variant="outlined" type='text' name='traderName' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorTraderName}
              value={value.traderName} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場パスワード
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='exhaustPasswd' hiddenLabel variant="outlined" type='text' name='exhaustPasswd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.exhaustPasswd} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場名称かな
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderKana' hiddenLabel variant="outlined" type='text' name='traderKana' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorTraderKana}
              value={value.traderKana} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場表示名称
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderDispName' hiddenLabel variant="outlined" type='text' name='traderDispName' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.traderDispName} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNET加入者番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo' hiddenLabel variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorJwnetNo}
              value={value.jwnetNo} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNETＥＤＩ版パスワード
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetEdipass' hiddenLabel variant="outlined" type='text' name='jwnetEdipass' halfWidth margin="dense"


              error={inputError}
              inputProps={{ maxLength: 9, pattern: "^[a-zA-Z0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
              inputRef={inputRef}
              defaultValue=""
//            id="outlined-basic"
//            label="Outlined"
//            variant="outlined"
              helperText={inputRef?.current?.validationMessage}


              onChange={(e) => handleChange(e)}
              value={value.jwnetEdipass} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNET事業場番号（同一加入者番号のサブ番号）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetSubno' hiddenLabel variant="outlined" type='number' name='jwnetSubno' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorJwnetSubno}
              inputProps={{ maxLength: 3, pattern: "0-9" }}     // ^[a-zA-Z0-9_]+$
//              defaultValue={"001"} 効果なし
              value={value.jwnetSubno} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              郵便番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='zip' hiddenLabel variant="outlined" type='text' name='zip' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.zip} fullWidth />
            </Box>
          </Grid>

        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所（都道府県）
              </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='prefectureCd' hiddenLabel variant="outlined" type='text' name='prefectureCd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.prefectureCd} fullWidth />
            </Box>
    </Grid>  */}

          <Select
              id='prefectureCd'
              label='都道府県'
              variant="outlined"
              type='text'
              name='prefectureCd'
              margin="dense"

              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}

              onChange={(e) => handleChange(e)}
              value={value.prefectureCd}
            >
            {prefecturedataList.map((prefecturedataList) => (
              <MenuItem
                key={prefecturedataList.prefectureCd}
                value={prefecturedataList.prefectureCd}
              >
                {prefecturedataList.prefectureCd}:{prefecturedataList.prefectureName}
              </MenuItem>
            ))}
            </Select>

        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所2（市区町村）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address2' hiddenLabel variant="outlined" type='text' name='address2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address2} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所3（町域）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address3' hiddenLabel variant="outlined" type='text' name='address3' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address3} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所4（丁目・番地以降）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address4' hiddenLabel variant="outlined" type='text' name='address4' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address4} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              連絡先電話番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='phone' hiddenLabel variant="outlined" type='text' name='phone' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.phone} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              FAX番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='fax' hiddenLabel variant="outlined" type='text' name='fax' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.fax} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              メールアドレス1
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='email1' hiddenLabel variant="outlined" type='text' name='email1' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.email1} fullWidth />

            <Checkbox
              id='email1Flg'
              label='メール有効フラグ1'
              defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)}
              value={value.email1Flg}
            />
            メール有効フラグ1

            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              メモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              環境ガードユーザフラグ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='egsUserFlg' hiddenLabel variant="outlined" type='text' name='egsUserFlg' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.egsUserFlg} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              使用EDIクライアント区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='address2' hiddenLabel variant="outlined" type='text' name='address2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
            value={value.address2} fullWidth />  */}

        <Select
          id='ediClientKbn'
          label='EDIクライアント区分'
          variant="outlined"
          type='text'
          name='ediClientKbn'
          margin="dense"

//          style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
          style={{ marginTop: 10 ,marginBottom: 10 }}

          onChange={(e) => handleChange(e)}
          value={value.ediClientKbn}
        >
          <MenuItem value={value.ediClientKbn}>
            <em>EDIクライアント区分</em>
          </MenuItem>
          <MenuItem value={' '}> :　　</MenuItem>
          <MenuItem value={'1'}>1:EDIクライアント1</MenuItem>
          <MenuItem value={'2'}>2:EDIクライアント2</MenuItem>
         </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              有効フラグ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='effectiveflg' hiddenLabel variant="outlined" type='text' name='effectiveflg' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.effectiveflg} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              料金区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='chargeDivision' hiddenLabel variant="outlined" type='text' name='chargeDivision' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
            value={value.chargeDivision} fullWidth />  */}

        <Select
          id='ediClientKbn'
          label='料金区分'
          variant="outlined"
          type='text'
          name='chargeDivision'
          margin="dense"

//          style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
          style={{ marginTop: 10 ,marginBottom: 10 }}

          onChange={(e) => handleChange(e)}
          value={value.chargeDivision}
        >
          <MenuItem value={value.chargeDivision}>
            <em>料金区分</em>
          </MenuItem>
          <MenuItem value={' '}> :　　</MenuItem>
          <MenuItem value={'1'}>1:料金区分A</MenuItem>
          <MenuItem value={'2'}>2:料金区分B</MenuItem>
          <MenuItem value={'3'}>3:料金区分C</MenuItem>
         </Select>


            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              mk区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='mkKbn3000' hiddenLabel variant="outlined" type='text' name='mkKbn3000' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
          value={value.mkKbn3000} fullWidth />  */}

        <Select
          id='mkKbn3000'
          label='mk区分'
          variant="outlined"
          type='text'
          name='mkKbn3000'
          margin="dense"
          style={{ marginTop: 10 ,marginBottom: 10 }}
          onChange={(e) => handleChange(e)}
          value={value.mkKbn3000}
        >
          <MenuItem value={value.mkKbn3000}>
            <em>mk区分</em>
          </MenuItem>
          <MenuItem value={' '}> </MenuItem>
          <MenuItem value={'1'}>1</MenuItem>
          <MenuItem value={'2'}>2</MenuItem>
         </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={3}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              所属団体
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='chargeDivision' hiddenLabel variant="outlined" type='text' name='chargeDivision' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
            value={value.chargeDivision} fullWidth />  */}

              <Select id='groupCd1' label='グループ１' variant="outlined" type='text' name='groupCd1' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd1}
               >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd2' label='グループ２' variant="outlined" type='text' name='groupCd2' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd2}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd3' label='グループ３' variant="outlined" type='text' name='groupCd3' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd3}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd4' label='グループ４' variant="outlined" type='text' name='groupCd4' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd4}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd5' label='グループ５' variant="outlined" type='text' name='groupCd5' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd5}
            >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

</tr></table>

<SpaceRow height={20} />
<div style={{ textAlign: 'right' }}>
<Button
        type='submit'
        variant='contained'
        color='primary'
//        onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//            handleSubmit(e);
             if (checkValidation()) {
               handleSubmit(e);
             }
          }}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
{/*}      <Button variant='contained' onClick={() => history.push('/egs_m_traders_adm')}>
        戻る
  </Button>   */}
      </div>

    </>
  );
};
export default EgsMTraderNewForm;
/*
*/
