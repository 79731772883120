// /src/lib/api/address.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getAddressList = () => {
  return client.get('/addresses');
};

// 詳細
export const getAddressDetail = (id) => {
  return client.get(`/addresses/${id}`);
};

// 事業所指定一覧 idはグループ１と２
export const getAddressZip = (id) => {
  return client.get(`/addresses/${id}/showZip`);
};

// 新規作成
// header情報を追加
export const createAddress = (params) => {
  return client.post('/addresses', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateAddress = (id, params) => {
  return client.patch(`/addresses/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteAddress = (id) => {
  return client.delete(`/addresses/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
