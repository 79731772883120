import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button } from '@material-ui/core';

import { updateEgsMEntry, getEgsMEntryDetail } from '../../lib/api/egs_m_entry';
import { createEgsMCompany } from '../../lib/api/egs_m_company';
import { createEgsMTrader } from '../../lib/api/egs_m_trader';
import { createEgsMCharge } from '../../lib/api/egs_m_charge';
import { createEgsMLink } from '../../lib/api/egs_m_link';
import { createUser } from '../../lib/api/user';

import { getEntryLinkOpe } from '../../lib/api/entry_link';
import { getPasswdList } from '../../lib/api/passwd'; 

import { updateEgsMCompany } from '../../lib/api/egs_m_company';
import { updateEgsMTrader } from '../../lib/api/egs_m_trader';
import { updateEgsMCharge } from '../../lib/api/egs_m_charge';
import { updateEgsMLink } from '../../lib/api/egs_m_link';
import { updateEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { updateUser } from '../../lib/api/user';
//import FormBody from './EgsMEntryForm';
import FormBody from './EgsMEntryAdminForm';  // フォーム分割

import { getAddressZip } from '../../lib/api/address';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMEntryAdmin({ isOpen, doClose, dataList2, processId }) {

//  console.log("dataList2",processId,dataList2);

  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
//  const [dataListPasswd1,setDataListPasswd1] = useState([]);  {/* 承認コード */}
//  const [dataListPasswd2,setDataListPasswd2] = useState([]);  {/* 生1パスワード */}

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({

//  データ入出力用項目

    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    companyKana: '',
    companyName: '',
    companyZip: '',
    companyPref: '',
    companyCity: '',
    companyTown: '',
    companyAddress: '',
    companyBuilding: '',
    companyPhone: '',
    companyFax: '',
    daihyo: '',
    daihyoKana1: '',
    daihyoKana2: '',
    daihyoName1: '',
    daihyoName2: '',
    traderKana: '',
    traderName: '',
    chargeKana1: '',
    chargeKana2: '',
    chargeName1: '',
    chargeName2: '',
    zip: '',
    pref: '',
    city: '',
    town: '',
    address: '',
    building: '',
    phone: '',
    fax: '',
    email1: '',
    memo: '',
    effectiveflg: '0',
    officeDivKana: '',
    officeDivName: '',
    officeChargeKana1: '',
    officeChargeKana2: '',
    officeChargeName1: '',
    officeChargeName2: '',
    email2: '',
    entryDate: '',
    businessType: '83',
    businessCategory: 'P',
    traderKbn: '1000000000',
    chargeDivision: '3',
    demandCd: '',
    demandId: 0,
    prefectureCd: '',
    groupId1: 0,
    groupId2: 0,
    jwnetNo: '',
    jwnetEdipass: '',
    rawpasswd: '',
    exhaustPasswd: '',
    companyKind: '99',
    companyKbn: '1',
    henkouDate: '',
    egsMTraderId: 0,
    henkouKbn: '',
    email3: '',
    
//  マスタ（entry_link）表示用項目
    
    companyCdLink: '',
    egsMCompanyIdLink: 0,
    traderCdLink: '',
    companyKanaLink: '',
    companyNameLink: '',
    companyZipLink: '',
    companyPrefLink: '',
    companyCityLink: '',
    companyTownLink: '',
    companyAddressLink: '',
    companyBuildingLink: '',
    companyPhoneLink: '',
    companyFaxLink: '',
    daihyoLink: '',
    daihyoKana1Link: '',
    daihyoKana2Link: '',
    daihyoName1Link: '',
    daihyoName2Link: '',
    traderKanaLink: '',
    traderNameLink: '',
    chargeKana1Link: '',
    chargeKana2Link: '',
    chargeName1Link: '',
    chargeName2Link: '',
    zipLink: '',
    prefLink: '',
    cityLink: '',
    townLink: '',
    addressLink: '',
    buildingLink: '',
    phoneLink: '',
    faxLink: '',
    email1Link: '',
    memoLink: '',
    effectiveflgLink: '0',
    officeDivKanaLink: '',
    officeDivNameLink: '',
    officeChargeKana1Link: '',
    officeChargeKana2Link: '',
    officeChargeName1Link: '',
    officeChargeName2Link: '',
    email2Link: '',
    entryDateLink: '',
    businessTypeLink: '83',
    businessCategoryLink: 'P',
    traderKbnLink: '1000000000',
    chargeDivisionLink: '3',
    demandCdLink: '',
    demandIdLink: 0,
    prefectureCdLink: '',
    groupId1Link: 0,
    groupId2Link: 0,
    jwnetNoLink: '',
    jwnetEdipassLink: '',
    rawpasswdLink: '',
    exhaustPasswdLink: '',
    companyKindLink: '99',
    companyKbnLink: '1',
    henkouDateLink: '',
    egsMTraderIdLink: 0,
    henkouKbnLink: '',
    email3Link: '',
  });

  useEffect(() => {
    handleGetData(processId,dataList2);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId, dataList2) => {
    try {
      setLoading(true);

      const resPasswd1 = await getPasswdList();  // 承認コード
//      setDataListPasswd1(dataListPasswd1.data);
//      setValue({
//        exhaustPasswd: dataListPasswd1.data.exhaustPasswd, // 承認コードは管理ユーザで取得
//      });

      const resPasswd2 = await getPasswdList();  // 生パスワード
//      setDataListPasswd2(dataListPasswd2.data);
//      setValue({
//        rawpasswd: setDataListPasswd2.data.rawpasswd,         // パスワードは管理ユーザで取得
//      });

      const resEntry = await getEgsMEntryDetail(processId);
//      setValue({
//        effectiveflg: resEntry.data.effectiveflg,
//        entryDate:    resEntry.data.entryDate,
//        henkouDate:   resEntry.data.henkouDate,
//        henkouKbn:    resEntry.data.henkouKbn,
//        companyKind:  resEntry.data.companyKind,
//        companyKbn:   resEntry.data.companyKbn,
//
//
//      })

      processId = dataList2.demandCd + '_0100000000_' + dataList2.jwnetNo // dataList2.egsMTraderId
      const resLink = await getEntryLinkOpe(processId);
//      console.log(resLink.data);
//      console.log('dataList2',dataList2);
      setValue({
        egsMEntryId:  resEntry.data.id,
        effectiveflg: resEntry.data.effectiveflg,
        entryDate:    resEntry.data.entryDate,
        henkouDate:   resEntry.data.henkouDate,
        henkouKbn:    resEntry.data.henkouKbn,
        companyKind:  resEntry.data.companyKind,
        companyKbn:   resEntry.data.companyKbn,

//  変更内容の書換用に、変更入力中をegs_m_entryからセット

        companyCd: resEntry.data.companyCd,
        egsMCompanyId: resEntry.data.egsMCompanyId,
        traderCd: resEntry.data.traderCd,
        companyKana: resEntry.data.companyKana,
        companyName: resEntry.data.companyName,
        companyZip: resEntry.data.companyZip,
        companyPref: resEntry.data.companyPref,
        companyCity: resEntry.data.companyCity,
        companyTown: resEntry.data.companyTown,
        companyAddress: resEntry.data.companyAddress,
        companyBuilding: resEntry.data.companyBuilding,
        companyPhone: resEntry.data.companyPhone,
        companyFax: resEntry.data.companyFax,
        daihyo: resEntry.data.daihyo,
        daihyoKana1: resEntry.data.daihyoKana1,
        daihyoKana2: resEntry.data.daihyoKana2,
        daihyoName1: resEntry.data.daihyoName1,
        daihyoName2: resEntry.data.daihyoName2,
        traderKana: resEntry.data.traderKana,
        traderName: resEntry.data.traderName,
        chargeKana1: resEntry.data.chargeKana1,
        chargeKana2: resEntry.data.chargeKana2,
        chargeName1: resEntry.data.chargeName1,
        chargeName2: resEntry.data.chargeName2,
        zip: resEntry.data.zip,
        pref: resEntry.data.pref,
        city: resEntry.data.city,
        town: resEntry.data.town,
        address: resEntry.data.address,
        building: resEntry.data.building,
        phone: resEntry.data.phone,
        fax: resEntry.data.fax,
        email1: resEntry.data.email,  // email1
        memo: resEntry.data.memo,
        officeDivKana: resEntry.data.officeDivKana,
        officeDivName: resEntry.data.officeDivName,
        officeChargeKana1: resEntry.data.officeChargeKana1,
        officeChargeKana2: resEntry.data.officeChargeKana2,
        officeChargeName1: resEntry.data.officeChargeName1,
        officeChargeName2: resEntry.data.officeChargeName2,
        email2: resEntry.data.email2,
        businessType: resEntry.data.businessType,
        businessCategory: resEntry.data.businessCategory,
        traderKbn: resEntry.data.traderKbn,
        chargeDivision: resEntry.data.chargeDivision,
        demandCd: resEntry.data.demandCd,
        demandId: resEntry.data.demandId,
        prefectureCd: resEntry.data.prefectureCd,
        groupId1: resEntry.data.groupId1,
        groupId2: resEntry.data.groupId2,
        jwnetNo: resEntry.data.jwnetNo,
        jwnetEdipass: resEntry.data.jwnetEdipass,
        rawpasswd: resPasswd2.data[0].passwd,         // パスワードは管理ユーザで取得
        exhaustPasswd: resPasswd1.data[0].passwd, // 承認コードは管理ユーザで取得
        egsMTraderId: resEntry.data.egsMTraderId,
        email3: resEntry.data.email3,

//  変更後の入力前後と区別するためのマスタデータentry_link

        companyCdLink: resLink.data[0].companyCd,
        egsMCompanyIdLink: resLink.data[0].egsMCompanyId,
        traderCdLink: resLink.data[0].traderCd,
        companyKanaLink: resLink.data[0].companyKana,
        companyNameLink: resLink.data[0].companyName,
        companyZipLink: resLink.data[0].companyZip,
        companyPrefLink: resLink.data[0].companyPref,
        companyCityLink: resLink.data[0].companyCity,
        companyTownLink: resLink.data[0].companyTown,
        companyAddressLink: resLink.data[0].companyAddress,
        companyBuildingLink: resLink.data[0].companyBuilding,
        companyPhoneLink: resLink.data[0].companyPhone,
        companyFaxLink: resLink.data[0].companyFax,
        daihyoLink: resLink.data[0].daihyo,
        daihyoKana1Link: resLink.data[0].daihyoKana1,
        daihyoKana2Link: resLink.data[0].daihyoKana2,
        daihyoName1Link: resLink.data[0].daihyoName1,
        daihyoName2Link: resLink.data[0].daihyoName2,
        traderKanaLink: resLink.data[0].traderKana,
        traderNameLink: resLink.data[0].traderName,
        chargeKana1Link: resLink.data[0].chargeKana1,
        chargeKana2Link: resLink.data[0].chargeKana2,
        chargeName1Link: resLink.data[0].chargeName1,
        chargeName2Link: resLink.data[0].chargeName2,
        zipLink: resLink.data[0].zip,
        prefLink: resLink.data[0].pref,
        cityLink: resLink.data[0].city,
        townLink: resLink.data[0].town,
        addressLink: resLink.data[0].address,
        buildingLink: resLink.data[0].building,
        phoneLink: resLink.data[0].phone,
        faxLink: resLink.data[0].fax,
        email1Link: resLink.data[0].email,  // email1
        memoLink: resLink.data[0].memo,
        officeDivKanaLink: resLink.data[0].officeDivKana,
        officeDivNameLink: resLink.data[0].officeDivName,
        officeChargeKana1Link: resLink.data[0].officeChargeKana1,
        officeChargeKana2Link: resLink.data[0].officeChargeKana2,
        officeChargeName1Link: resLink.data[0].officeChargeName1,
        officeChargeName2Link: resLink.data[0].officeChargeName2,
        email2Link: resLink.data[0].email2,
        businessTypeLink: resLink.data[0].businessType,
        businessCategoryLink: resLink.data[0].businessCategory,
        traderKbnLink: resLink.data[0].traderKbn,
        chargeDivisionLink: resLink.data[0].chargeDivision,
        demandCdLink: resLink.data[0].demandCd,
        demandIdLink: resLink.data[0].demandId,
        prefectureCdLink: resLink.data[0].prefectureCd,
        groupId1Link: resLink.data[0].groupId1,
        groupId2Link: resLink.data[0].groupId2,
        jwnetNoLink: resLink.data[0].jwnetNo,
        jwnetEdipassLink: resLink.data[0].jwnetEdipass,
        rawpasswdLink: resLink.data[0].rawpasswd,
        exhaustPasswdLink: resLink.data[0].exhaustPasswd,
        egsMTraderIdLink: resLink.data[0].egsMTraderId,
        email3Link: resLink.data[0].email3,
      });
    } catch (e) {
//      console.log(e);
//      alert('エラー1',e.response)
    } finally {
      setLoading(false);
//      alert(e.response)
//      alert('setLoadingエラー')
    }
  };

  const companyZipChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.value != null && e.target.value.length == 7) {
        try {
          
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

        value.prefectureCd = dataListAddress.data[0].prefectureCd

//        value.companyZipAddress = dataListAddress.data[0].address1
//        value.companyZipAddress2 = dataListAddress.data[0].address2
//        value.companyZipAddress3 = dataListAddress.data[0].address3

        value.companyPref = dataListAddress.data[0].address1
        value.companyCity = dataListAddress.data[0].address2
        value.companyTown = dataListAddress.data[0].address3

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
//        console.log(e);
        alert('エラー2',e.response)
      }

    }

  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
//  console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleCreate1 = async (e) => {
    if (window.confirm(' 加入者「' + value.companyCd + ':' + value.companyName + '」より企業マスタを登録します。よろしいですか？')) {
//      try {
//        e.preventDefault();
        // 追加
        const createParmCompany = createParamsCompany();
        try {
          const res = await createEgsMCompany(createParmCompany);
//          value.wkCompanyId = res.data.id;
          value.egsMCompanyId = res.data.id;
//          handleCancel();
//          window.location.reload();
        } catch (e) {
//          console.log(e);
//          console.log(e.response);
            alert('エラー3',e.response)
        }
//      } catch (e) {
//      console.log(e.response);
//        alert('エラー4',e.response)
//      }
     }

     if (window.confirm(' 加入者「' + value.traderCd + ':' + value.traderName + '」より事業場マスタを登録します。よろしいですか？')) {
//      try {
//????        e.preventDefault();
        // 追加
        const createParmTrader = createParamsTrader();
        try {
          const res = await createEgsMTrader(createParmTrader);
//          value.wkTraderId = res.data.id;
          value.egsMTraderId = res.data.id;
////          handleCancel();
////          window.location.reload();
        } catch (e) {
//          console.log(e);
//          console.log(e.response);
            alert('エラー5',e.response)
        }
//        alert('エラー51',e.response)
//      } catch (e) {
//      console.log(e.response);
//            alert('エラー6',e.response)
//      }
//      alert('エラー61',e.response)
    }

     if (window.confirm(' 加入者「' + value.chargeName1 + ':' + value.chargeName2 + '」より担当者マスタを登録します。よろしいですか？')) {
//      try {
//????        e.preventDefault();
        // 追加
        const createParmCharge = createParamsCharge();
        try {
          const res = await createEgsMCharge(createParmCharge);
          value.wkChargeId = res.data.id;
////          handleCancel();
////          window.location.reload();
//            alert('エラー70',e.response)
        } catch (e) {
//          console.log(e);
//          console.log(e.response);
            alert('エラー7',e.response)
        }
//        alert('エラー71',e.response)
//      } catch (e) {
//      console.log(e.response);
//            alert('エラー8',e.response)
//      }
//      alert('エラー81',e.response)
    }
/*
    }

    const handleCreate3 = async (e) => {
*/      
      if (window.confirm(' 加入者「' + value.demandId + ':' + value.egsMTraderId + '」より連携マスタを登録します。よろしいですか？')) {
//        try {
//????          e.preventDefault();
          // 追加
          const createParmLink = createParamsLink();
          try {
            const res = await createEgsMLink(createParmLink);
  //          value.wkChargeId = res.data.id;
////            handleCancel();
////            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
            alert('エラー9',e.response)
          }
//        } catch (e) {
//        console.log(e.response);
//            alert('エラー10',e.response)
//        }
       }

       if (window.confirm(' 加入者「' + value.traderCd + ':' + value.traderName + '」を更新します。よろしいですか？')) {
//        try {
//          e.preventDefault();
          // 追加
          processId = value.egsMEntryId; // resEntry.data.id  //  value.egsMTraderId;
          const createParmTrader = createParamsTrader();
    //      console.log('createTrader',createTrader)
          try {
            const res = await updateEgsMEntry(processId, createParmTrader);
    //        console.log(res);
            handleCancel();
    //        history.push('/permit');  // egs_m_permits
            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
            alert('エラー11',e.response)
          }
//        } catch (e) {
//        console.log(e.response);
//            alert('エラー12',e.response)
//        }
      }    
    }

    const handleCreate2 = async (e) => {
     if (window.confirm(' 加入者「' + value.traderName + '」よりユーザマスタを登録します。よろしいですか？')) {
      
      try {
        e.preventDefault();
        // 追加
        const createParmUser = createParamsUser();
        try {
//          const res = await createUser(createParmUser);
          const res = await updateUser(400001, createParmUser);
//          value.wkChargeId = res.data.id;
          handleCancel();
          window.location.reload();
        } catch (e) {
//          console.log(e);
//          console.log(e.response);
            alert('エラー13',e.response)
        }
      } catch (e) {
//      console.log(e.response);
            alert('エラー14',e.response)
      }
      
     }

    };  

    const handleUpdate1 = async (e) => {
      if (window.confirm(' 加入者「' + value.traderName + '」より企業マスタ（住所反映あり）を更新します。よろしいですか？')) {
       try {
         e.preventDefault();
         // 追加
         processId = value.egsMCompanyId;
         const updateParmCompany1 = updateParamsCompany1();
         try {
           const res = await updateEgsMCompany(processId, updateParmCompany1);
           handleCancel();
//           window.location.reload();
         } catch (e) {
//           console.log(e);
//           console.log(e.response);
           alert('企業マスタ更新失敗！！！');
         }
       } catch (e) {
//       console.log(e.response);
            alert('エラー15',e.response)
       }
      }

      if (window.confirm(' 加入者「' + value.traderName + '」より事業場マスタを更新します。よろしいですか？')) {
       try {
         e.preventDefault();
         // 追加
         processId = value.egsMTraderId;
         const updateParmTrader = updateParamsTrader();
         try {
           const res = await updateEgsMTrader(processId, updateParmTrader);
 //          value.wkChargeId = res.data.id;
           handleCancel();
           window.location.reload();
         } catch (e) {
//           console.log(e);
//           console.log(e.response);
           alert('事業場マスタ更新失敗！！！',e.response)
          }
       } catch (e) {
//       console.log(e.response);
            alert('エラー16',e.response)
       }
      }

    };  
 
     const handleUpdate2 = async (e) => {
/*
      if (window.confirm(' 加入者「' + value.traderName + '」よりユーザマスタを更新します。よろしいですか？')) {
       try {
         e.preventDefault();
         // 追加
         const createParmUser = createParamsUser();
         try {
           const res = await updateUser(processId, createParmUser);
 //          value.wkChargeId = res.data.id;
           handleCancel();
           window.location.reload();
         } catch (e) {
//           console.log(e);
//           console.log(e.response);
         }
       } catch (e) {
//       console.log(e.response);
       }
      }
*/

      if (window.confirm(' 加入者「' + value.traderName + '」より企業マスタ（企業名のみ）を更新します。よろしいですか？')) {
        try {
          e.preventDefault();
          // 追加
          processId = value.egsMCompanyId;
          const updateParmCompany2 = updateParamsCompany2();
          try {
            const res = await updateEgsMCompany(processId, updateParmCompany2);
            handleCancel();
      //           window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
            alert('企業マスタ更新失敗！！！',e.response)
          }
        } catch (e) {
//        console.log(e.response);
            alert('エラー17',e.response)
        }
      }

      if (window.confirm(' 加入者「' + value.traderName + '」より事業場マスタを更新します。よろしいですか？')) {
        try {
          e.preventDefault();
          // 追加
          processId = value.egsMTraderId;
          const updateParmTrader = updateParamsTrader();
          try {
            const res = await updateEgsMTrader(processId, updateParmTrader);
      //          value.wkChargeId = res.data.id;
            handleCancel();
            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
            alert('事業場マスタ更新失敗！！！',e.response)
          }
        } catch (e) {
//        console.log(e.response);
            alert('エラー18',e.response)
        }
      }

     };  

     const handleDelete1 = async (e) => {

      if (window.confirm(' 加入者「' + value.traderName + '」より企業マスタを解約します。よろしいですか？')) {
        try {
          e.preventDefault();
          // 追加
          processId = value.egsMCompanyId;
          const deleteParmCompany = deleteParamsCompany();
          try {
            const res = await updateEgsMCompany(processId, deleteParmCompany);
//            handleCancel();
            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
            alert('エラー19',e.response)
          }
        } catch (e) {
//        console.log(e.response);
            alert('エラー20',e.response)
        }
       }

      if (window.confirm(' 加入者「' + value.traderName + '」より事業場マスタを解約します。よろしいですか？')) {
       try {
         e.preventDefault();
         // 追加
         processId = value.egsMTraderId;
         const deleteParmTrader = deleteParamsTrader();
         try {
           const res = await updateEgsMTrader(processId, deleteParmTrader);
           handleCancel();
           window.location.reload();
         } catch (e) {
//           console.log(e);
//           console.log(e.response);
            alert('エラー21',e.response)
         }
       } catch (e) {
//       console.log(e.response);
            alert('エラー22',e.response)
       }
      }

/*     事業所を無効にしたら、担当者・連携・処理フローは無効になる

       if (window.confirm(' 加入者「' + value.traderName + '」より担当者マスタを解約します。よろしいですか？')) {
        try {
          e.preventDefault();
          // 追加
          processId = value.egsMTraderId;
          const deleteParmTrader = deleteParamsTrader();
          try {
            const res = await updateEgsMTrader(processId, deleteParmTrader);
            handleCancel();
            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
          }
        } catch (e) {
//        console.log(e.response);
        }
       }

       if (window.confirm(' 加入者「' + value.traderName + '」より連携マスタを解約します。よろしいですか？')) {
        try {
          e.preventDefault();
          // 追加
          processId = value.egsMTraderId;
          const deleteParmTrader = deleteParamsTrader();
          try {
            const res = await updateEgsMTrader(processId, deleteParmTrader);
            handleCancel();
            window.location.reload();
          } catch (e) {
//            console.log(e);
//            console.log(e.response);
          }
        } catch (e) {
//        console.log(e.response);
        }
       }
        
     事業所を無効にしたら、担当者・連携・処理フローは無効になる    */
    };  

     const handleDelete2 = async (e) => {
      if (window.confirm(' 加入者「' + value.traderName + '」よりユーザマスタを解約します。よろしいですか？')) {
       try {
         e.preventDefault();
         // 追加
//         const deleteParmUser = deleteParamsUser();
//         try {
//           const res = await updateUser(deleteParmUser);
 //          value.wkChargeId = res.data.id;
//           handleCancel();
//           window.location.reload();
//         } catch (e) {
//           console.log(e);
//           console.log(e.response);
//         }
       } catch (e) {
//       console.log(e.response);
       alert('エラー23',e.response)
      }
      }
    };  

  const handleSubmit = async (e) => {
/*    
    e.preventDefault();
    // 追加
    const createParmEntry = updateParamsEntry();
    try {
      const res = await updateEgsMEntry(processId, createParmEntry);
//      console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
//      console.log(e);
    }
*/

  if (window.confirm(' 加入者「' + value.traderCd + ':' + value.traderName + '」を更新します。よろしいですか？')) {
    try {
      e.preventDefault();
      // 追加
      const createParmEntry = updateParamsEntry();
//      console.log('createParmEntry',createParmEntry)
      try {
        const res = await updateEgsMEntry(processId, createParmEntry);
//        console.log(res);
        handleCancel();
//        history.push('/permit');  // egs_m_permits
        window.location.reload();
      } catch (e) {
//        console.log(e);
//        console.log(e.response);
            alert('エラー24',e.response)
      }
    } catch (e) {
//    console.log(e.response);
            alert('エラー25',e.response)
    }
}

  };

  // パラメータのオブジェクト構造を加工
  const createParamsCompany = () => {
    const createParmCompany = {
      companyCd: value.companyCd,
      certifyId: value.companyCd,
      traderKbn: value.traderKbn,

      companyName: value.companyName,
      companyKana: value.companyKana,
//      jwnetNo: value.jwnetNo,
//      jwnetEdipass: value.jwnetEdipass,
//      jwnetSubno: value.jwnetSubno,
//      exhaustPasswd: value.exhaustPasswd,
//      prefectureCd: value.companyPrefectureCd,
      zip: value.companyZip,
      address: value.companyCity,
      address2: value.companyTown,
      address3: value.companyAddress,
      address4: value.companyBuilding,
      phone: value.companyPhone,
      fax: value.companyFax,
      email: value.email1,
//      permitComno: value.permitComno,
//      businessTypeCd: value.businessTypeCd,
      effectiveflg: '1',  //value.effectiveflg,
//      groupCd1: value.groupCd1,
//      groupCd2: value.groupCd2,
//      groupCd3: value.groupCd3,
//      groupCd4: value.groupCd4,
//      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,

      businessTypeCd: value.businessType,

    };
    return createParmCompany;
  };

  // パラメータのオブジェクト構造を加工
  const createParamsTrader = () => {
    const createParmTrader = {
      companyCd: value.companyCd,
//      egsMCompanyId: value.wkCompanyId,
      egsMCompanyId: value.egsMCompanyId,
//      traderCd: value.traderCd,
      traderCd: value.companyCd.substring(0,8) + '0110',
      traderKana: value.traderKana,
      traderName: value.traderName,
      traderDispName: value.traderName,
      zip: value.zip,
//      pref: value.pref,
      prefectureCd: value.prefectureCd,
      address: value.city,
      address2: value.town,
      address3: value.address,
      address4: value.building,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      email1Flg: '0',
      email2: value.email2,
      email2Flg: '0',
      email3Flg: '0',
      memo: value.memo,
      effectiveflg: '1',  //value.effectiveflg,

      traderKbn: value.traderKbn,
      chargeDivision: value.chargeDivision,
      
      certifyId: value.traderCd,  // certifyId,
      traderKbn: value.traderKbn,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      
      rawpasswd: value.rawpasswd,
      
      groupId1: value.groupId1,
      groupId2: value.groupId2,

    };
    return createParmTrader;
  };

  // パラメータのオブジェクト構造を加工
  const createParamsCharge = () => {
    const createParmCharge = {
//      egsMTraderId: value.wkTraderId, // 999999, //value.egsMTraderId,
      egsMTraderId: value.egsMTraderId, // 999999, //value.egsMTraderId,
      traderCd: value.traderCd,
      chargeCd: '001',
      chargeName: value.chargeName1 + '　' + value.chargeName2,
      chargeKana: value.chargeKana1 + '　' + value.chargeKana2,
      email: value.email1,
//      emailFlg: value.emailFlg,
//      ediKbn: value.ediKbn,
      effectiveflg: '1',  //value.effectiveflg,

    };
    return createParmCharge;
  };


  
  // パラメータのオブジェクト構造を加工
  const createParamsLink = () => {
    const createParmLink = {
      egsMTraderId1: value.demandId,
      egsMTraderId2: value.egsMTraderId,
      jwnetNo1: '1234567',

    };
    return createParmLink;
  };

  // パラメータのオブジェクト構造を加工
  const createParamsUser = () => {
    const createParmUser = {
      provider: 'email',
      uid: value.traderCd + '@sdgs12com',
      name: value.traderName,
      nickname: value.traderName,
      email: value.traderCd + '@sdgs12com',
      traderCd: value.traderCd,
      traderKbn: value.traderKbn,

    };
    return createParmUser;
  };

  // パラメータのオブジェクト構造を加工
  const updateParamsEntry = () => {
    const createParmEntry = {
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd,
      companyKana: value.companyKana,
      companyName: value.companyName,
      companyZip: value.companyZip,
      companyPref: value.companyPref,
      companyCity: value.companyCity,
      companyTown: value.companyTown,
      companyAddress: value.companyAddress,
      companyBuilding: value.companyBuilding,
      companyPhone: value.companyPhone,
      companyFax: value.companyFax,
      daihyo: value.daihyo,
      daihyoKana1: value.daihyoKana1,
      daihyoKana2: value.daihyoKana2,
      daihyoName1: value.daihyoName1,
      daihyoName2: value.daihyoName2,
      traderKana: value.traderKana,
      traderName: value.traderName,
      chargeKana1: value.chargeKana1,
      chargeKana2: value.chargeKana2,
      chargeName1: value.chargeName1,
      chargeName2: value.chargeName2,
      zip: value.zip,
      pref: value.pref,
      city: value.city,
      town: value.town,
      address: value.address,
      building: value.building,
      phone: value.phone,
      fax: value.fax,
      email: value.email1,
      memo: value.memo,
      effectiveflg: value.effectiveflg,
      officeDivKana: value.officeDivKana,
      officeDivName: value.officeDivName,
      officeChargeKana1: value.officeChargeKana1,
      officeChargeKana2: value.officeChargeKana2,
      officeChargeName1: value.officeChargeName1,
      officeChargeName2: value.officeChargeName2,
      email2: value.email2,
      entryDate: value.entryDate,
      businessType: value.businessType,
      businessCategory: value.businessCategory,
      traderKbn: value.traderKbn,
      chargeDivision: value.chargeDivision,
      demandCd: value.demandCd,
      demandId: value.demandId,
      prefectureCd: value.prefectureCd,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      rawpasswd: value.rawpasswd,
      exhaustPasswd: value.exhaustPasswd,
      
      rawpasswd: value.rawpasswd,
      
      companyKind: value.companyKind,
      companyKbn: value.companyKbn,
      henkouDate: value.henkouDate,
      egsMTraderId: value.egsMTraderId,
      henkouKbn: value.henkouKbn,
      email3: value.email3,
      };
    return createParmEntry;
  };


  // パラメータのオブジェクト構造を加工
  const updateParamsCompany1 = () => {
    const updateParmCompany1 = {
      companyName: value.companyName,
      companyKana: value.companyKana,
      zip: value.zip,            //companyZip,
      address: value.city,       //companyCity,
      address2: value.town,      //companyTown,
      address3: value.address,   //companyAddress,
      address4: value.building,  //companyBuilding,
      phone: value.phone,        // companyPhone,
      fax: value.fax,            //  companyFax,
      email: value.email1,
    };
    return updateParmCompany1;
  };

  const updateParamsCompany2 = () => {
    const updateParmCompany2 = {
      companyName: value.companyName,
      companyKana: value.companyKana,
    };
    return updateParmCompany2;
  };

  // パラメータのオブジェクト構造を加工
  const updateParamsTrader = () => {
    const updateParmTrader = {
      traderKana: value.traderKana,
      traderName: value.traderName,
      traderDispName: value.traderName,
      zip: value.zip,
//      pref: value.pref,
      address: value.city,
      address2: value.town,
      address3: value.address,
      address4: value.building,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      email2: value.email2,
      email3: value.email3,
    };
    return updateParmTrader;
  };

  // パラメータのオブジェクト構造を加工
  const updateParamsCharge = () => {
    const updateParmCharge = {
//      egsMTraderId: value.wkTraderId, // 999999, //value.egsMTraderId,
      egsMTraderId: value.egsMTraderId, // 999999, //value.egsMTraderId,
      traderCd: value.traderCd,
      chargeCd: '001',
      chargeName: value.chargeName1 + '　' + value.chargeName2,
      chargeKana: value.chargeKana1 + '　' + value.chargeKana2,
      email: value.email1,
//      emailFlg: value.emailFlg,
//      ediKbn: value.ediKbn,
      effectiveflg: '1',  //value.effectiveflg,

    };
    return updateParmCharge;
  };


  // パラメータのオブジェクト構造を加工
  const deleteParamsCompany = () => {
    const deleteParmCompany = {
//      companyCd: value.companyCd,
      effectiveflg: '9',
    };
    return deleteParmCompany;
  };

  // パラメータのオブジェクト構造を加工
  const deleteParamsTrader = () => {
    const deleteParmTrader = {
//      companyCd: value.companyCd,
      effectiveflg: '9',
    };
    return deleteParmTrader;
  };

  // パラメータのオブジェクト構造を加工
  const deleteParamsCharge = () => {
    const deleteParmCharge = {
//      companyCd: value.companyCd,
      effectiveflg: '9',
    };
    return deleteParmCharge;
  };

  // パラメータのオブジェクト構造を加工
  const deleteParamsLink = () => {
    const deleteParmLink = {
//      companyCd: value.companyCd,
      effectiveflg: '9',
    };
    return deleteParmLink;
  };

  // パラメータのオブジェクト構造を加工
  const deleteParamsProcessFlow = () => {
    const deleteParmProcessFlow = {
//      companyCd: value.companyCd,
      effectiveflg: '9',
    };
    return deleteParmProcessFlow;
  };



  if (value.henkouKbn == '1'){
    return (
      <Dialog
        open={isOpen}
  //      onClose={doClose}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>加入者マスタ　加入申込編集</h2>
          <div style={{ textAlign: 'right' }}>
            <Button variant='contained' 
  //           onClick={() => history.push('/permit')}>
              onClick={e => {
                e.preventDefault();
  //              handleBack();
                handleCancel();
              }}>
              戻る
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
  
          <FormBody
            handleBack={handleCancel}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleCreate1={handleCreate1}
            handleCreate2={handleCreate2}
  //          handleCreate3={handleCreate3}
            companyZipChange={companyZipChange}
            value={value}
            dataList2={dataList2}
          />
  
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
  }else if (value.henkouKbn == '2' ){ 
    return (
      <Dialog
        open={isOpen}
  //      onClose={doClose}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
        PaperComponent={PaperComponent}
      >
        <DialogTitle id="form-dialog-title">
          <h2>加入者マスタ　変更申込編集</h2>
          <div style={{ textAlign: 'right' }}>
            <Button variant='contained' 
  //           onClick={() => history.push('/permit')}>
              onClick={e => {
                e.preventDefault();
  //              handleBack();
                handleCancel();
              }}>
              戻る
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
  
          <FormBody
            handleBack={handleCancel}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleUpdate1={handleUpdate1}
            handleUpdate2={handleUpdate2}
  //          handleUpdate3={handleUpdate3}
            companyZipChange={companyZipChange}
            value={value}
            dataList2={dataList2}
          />
  
          <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
    }else{
      return (
        <Dialog
          open={isOpen}
    //      onClose={doClose}
          onClose={() => {}}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="form-dialog-title"
          maxWidth={"xl"}
          PaperComponent={PaperComponent}
        >
          <DialogTitle id="form-dialog-title">
            <h2>加入者マスタ　解約申込編集</h2>
            <div style={{ textAlign: 'right' }}>
              <Button variant='contained' 
    //           onClick={() => history.push('/permit')}>
                onClick={e => {
                  e.preventDefault();
    //              handleBack();
                  handleCancel();
                }}>
                戻る
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
    
            <FormBody
              handleBack={handleCancel}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleDelete1={handleDelete1}
              handleDelete2={handleDelete2}
    //          handleDelete3={handleDelete3}
              companyZipChange={companyZipChange}
              value={value}
              dataList2={dataList2}
            />
    
            <Backdrop className={classes.backdrop} open={loading} >
              <CircularProgress color="inherit" />
            </Backdrop>
          </DialogContent>
        </Dialog>
      );
  }  

}
export default EgsMEntryAdmin;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
