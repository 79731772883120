import React, { useEffect, useState } from 'react';
import { getEgsMCompanyDetail } from '../../lib/api/egs_m_company';

//import EgsMTraderPass from "../egs_m_companys/EgsMTraderPass";
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EE8",  // EDF
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function EgsMCompanyListTableForm(props) {
  const classes = useStyles();
  const { dataList2 } = props;
  // console.log('dataList2',dataList2)
//  const { dataCom } = props;
///  // console.log('dataCom3',dataCom)
  const { currentUser } = props;
  // console.log('currentUser',currentUser)
  const { egsMCompanyId } = props;
  // console.log('egsMCompanyId',egsMCompanyId)
//  const { handleGetCom } = props;
//  handleGetCom();

  const [passOpen, setPassOpen] = useState(false);


  const [dataCom, setDataCom] = useState([]);
  const [open, setOpen] = React.useState(false);



  const handlePassOpen = () => {
    setPassOpen(true);
  };
  const handleOpen = () => {
    handleGetCom();
  };


  const handleChange = () => {
    handleGetCom();
  };

  let resCom;

  const handleGetCom = async () => {
    try {
      setOpen(!open);
       resCom = await getEgsMCompanyDetail(dataList2.egsMCompanyId);
       setDataCom(resCom.data);
       // console.log(resCom.data)
//       // console.log(dataCom)
      setOpen(false);
     } catch (e) {
       console.log(e);
       console.log(e.response);
     }

  };

       // console.log(dataCom)

  return (
    <>
      <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
{/*}        <Typography variant="h4" style={{ fontWeight: 700 }}>
        企業情報
  </Typography>  */}
{/*}        <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.egsMCompanyId}:{dataList2.traderCd}</Typography>
        <TextField id='egsMCompanyId' label='企業ID' variant="outlined" type='text' name='egsMCompanyId' onChange={(e) => handleChange(e)} value={dataList2.egsMCompanyId} /> */}
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleChange}
//          disabled={!dataList2.id}
          >
          企業情報
        </Button>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  JWNET加入者番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.jwnetNo}</Typography>
              </Box>
            </Grid>
{/*}
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.jwnetSubno}</Typography>
              </Box>
            </Grid>
*/}
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  JWNET加入者 EDI利用確認キー
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.jwnetEdipass}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  企業CD
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.companyCd}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者区分  {/* {dataCom.data.companyName}  */}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.traderKbn}</Typography>
{/*}                <Typography variant="h7" style={{ fontWeight: 700 }}>：{dataCom.data.traderKbn}</Typography>  */}
              </Box>
            </Grid>
           <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  企業名称
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.companyName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  企業名称かな
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.companyKana}</Typography>
              </Box>
            </Grid>
           <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  郵便番号・都道府県
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.zip}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.prefectureName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（市区町村）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.address}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（町域）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.address2}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  住所（丁目・番地以降）
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.address3}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                連絡先電話番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.phone}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ＦＡＸ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.fax}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>


          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  メールアドレス
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.email1}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                業種
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataCom.businessTypeCd}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>
        </div>

    </>
  )
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMCompanyListTableForm;
