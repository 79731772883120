import React, { useEffect, useState } from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CommonDialog from "./CommonDialog";
import Slide from "@material-ui/core/Slide";
import FormBody from '../egs_m_traders/EgsMTraderForm';

import { useHistory, useParams } from 'react-router-dom';
import { updateEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';

function FormDialog({ isOpen, doClose, dataList }) {
  const [open, setOpen] = React.useState(false);
  const [commDlg, setCommDlg] = React.useState(false);
  // console.log('FormDialog_dataList',dataList);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const handleDo = () => {
    setCommDlg(true);
  };
  const handleCancel = () => {
    setOpen(false);
    doClose();
  };
  const execute = () => {
    setCommDlg(false);
    handleCancel();
    // console.log("execute");
  };

  const [value, setValue] = useState({
    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    certifyId: '',
    traderKbn: '',
    traderName: '',
    email1: '',
    email1Flg: '',
    exhaustPasswd: '',
  });

  const query = useParams();

  const history = useHistory();

  useEffect(() => {
    handleGetData(query);
  }, [query]);

  const handleGetData = async (query) => {
    try {
      const res = await getEgsMTraderDetail(query.id);
      // console.log('query',query.id);
      setValue({
        companyCd: res.data.companyCd,
        egsMCompanyId: res.data.egsMCompanyId,
        traderCd: res.data.traderCd,
        certifyId: res.data.certifyId,
        traderKbn: res.data.traderKbn,
        traderName: res.data.traderName,
        email1: res.data.email1,
        email1Flg: res.data.email1Flg,
        exhaustPasswd: res.data.exhaustPasswd,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params', params);
    try {
      const res = await updateEgsMTrader(query.id, params);
      // console.log('res', res);
      history.push('/egs_m_traders');
    } catch (e) {
      console.log(e);
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd,
      certifyId: value.certifyId,
      traderKbn: value.traderKbn,
      traderName: value.traderName,
      email1: value.email1,
      email1Flg: value.email1Flg,
      exhaustPasswd: value.exhaustPasswd,
    };
    return params;
  };



  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Material-UI</DialogTitle>
        <DialogContent>

          <h1>事業場マスタ編集</h1>
          <FormBody
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            value={value}
            buttonType='更新' />
          <p>kbn:{value.traderKbn}</p>


          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDo} color="primary">
            登録
          </Button>
        </DialogActions>
      </Dialog>
      <CommonDialog
        msg={"登録しますか？"}
        isOpen={commDlg}
        doYes={execute}
        doNo={() => {
          setCommDlg(false);
        } } />
    </div>
  );
}
export default FormDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
