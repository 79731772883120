// /src/lib/api/exhaust_plan.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getSagyoReportList = () => {
  return client.get('/sagyo_reports');
};

// 詳細
export const getSagyoReportDetail = (id) => {
  console.log(id)
  return client.get(`/sagyo_reports/${id}`);
};

// 事業所指定一覧 idは事業所CD
export const getSagyoReportChargeId = (id) => {
  return client.get(`/sagyo_reports/${id}/showchargeid`);
};
// 新規作成
// header情報を追加
export const createSagyoReport = (params) => {
  return client.post('/sagyo_reports', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateSagyoReport = (id, params) => {
  return client.patch(`/sagyo_reports/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteSagyoReport = (id) => {
  return client.delete(`/sagyo_reports/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
