// /src/lib/api/egs_m_unit.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMUnitList = () => {
  return client.get('/egs_m_units');
};

// 事業所指定一覧 idは事業所CD
export const getEgsMUnitUser = (id) => {
  return client.get(`/egs_m_units/${id}/showuser`);
};

// 詳細（追加・更新用）
export const getEgsMUnitDetail = (id) => {
  return client.get(`/egs_m_units/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMUnit = (params) => {
  return client.post('/egs_m_units', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMUnit = (id, params) => {
  return client.patch(`/egs_m_units/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMUnit = (id) => {
  return client.delete(`/egs_m_units/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
