import React, { useEffect, useState, useContext } from 'react';
//import { getProcessFlowList } from '../../lib/api/process_flow';
import { getProcessFlowUser } from '../../lib/api/process_flow';
import { deleteEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { useHistory } from 'react-router-dom';
// style
//import { Button } from '@material-ui/core';
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import ProcessFlowSelTable from './ProcessFlowSelTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ProcessFlowSel = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getProcessFlowList();
      const res = await getProcessFlowUser(currentUser.traderCd);
      // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {

   alert(' ' + item.process_flowName + 'を削除します。よろしいですか？')

    // Alert.alert(
    //   'Alert Title',
    //   'My Alert Msg',
    //   [
    //      {text: 'Cancel', onPress: () => // console.log('Cancel Pressed'), style: 'cancel'},
    //     {text: 'OK', onPress: () => // console.log('OK Pressed')},
    //   ],
    //   { cancelable: false }
    // )

    // console.log('click', item.id);
    try {
      const res = await deleteEgsMProcessFlow(item.id);
      // console.log(res.data);
      handleGetList();
    } catch (e) {
      console.log(e.response);
    }
  };

  return (
    <>
      <h1>排出登録（処理フローマスタ選択）</h1>
      <Button variant='contained' onClick={() => history.push('/garbage_edi')}>
        戻る
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpaceRow height={20} />
      <ProcessFlowSelTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default ProcessFlowSel;
/*
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_process_flows/select')}
      >
        新規作成
      </Button>
*/
