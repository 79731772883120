import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Box, Grid, Typography } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';

import './app.css';
import { getEgsMTraderList } from '../../lib/api/egs_m_trader';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },

  title_box2: {
    background: "rgb(224, 224, 224)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
}));

const EgsMTraderPassForm = (props) => {
  const { handleBack, handleChange, handleSubmit, value, buttonType } = props;
  const classes = useStyles();

  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}

  // useEffect(() => {
  //   handleGetList();
  // }, []);

  // const handleGetList = async () => {
  //   try {
  //    setOpen(!open);
  //     const resT01 = await getEgsMTraderList();
  // //    // console.log(resT01.data);
  //     setDataListT01(resT01.data);

  //    setOpen(false);
  //   } catch (e) {
  //     console.log(e);
  //     console.log(e.response);
  //   }
  // };

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
      </div>
      <SpaceRow height={20} />
    <table><tr>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={6}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ログインパスワード
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField
                id='rawpasswd'
                hiddenLabel
                variant="outlined"
                type='text'
                name='rawpasswd'
                halfWidth
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.rawpasswd}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      </tr></table>
      <SpaceRow height={20} />
      <div style={{ textAlign: 'right' }}>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
      >
        {buttonType}
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
    </>
  );
};
export default EgsMTraderPassForm;
