import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import { createEgsMLinkage, getEgsMLinkageDetail } from '../../lib/api/egs_m_linkage';
import { createEgsMLink, getEgsMLinkDetail } from '../../lib/api/egs_m_link';
import { getEgsMTraderUser } from '../../lib/api/egs_m_trader';
//import FormBody from './EgsMLinkageForm';
//import FormBody from './EgsMLinkageNewForm';
import FormBody from './EgsMLinkageCreatForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMLinkageCreat({ isOpen, doClose, processId ,     currentUser}) {
  const [loading, setLoading] = useState(false);

//  const classes = useStyles();													//			yamauchi 追加
  const [open, setOpen] = React.useState(false);							//				yamauchi 追加

  const [value, setValue] = useState({
    egsMTraderId1: 0,
    egsMTraderId2: 0,

    jwnetNo1: '',
    jwnetNo2: '',
    traderCd2: '',
  });

  useEffect(() => {
    handleGetData(processId,    currentUser);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId,    currentUser) => {
    try {
      setLoading(true);
//      const res = await getEgsMLinkageDetail(processId);
      const res = await getEgsMTraderUser(currentUser.traderCd);
      // console.log(res.data);
      setValue({
//        egsMTraderId1: res.data.egsMTraderId1,
//        egsMTraderId2: res.data.egsMTraderId2,

//        jwnetNo1: res.data.jwnetNo1,

//        egsMTraderId1: res.data.id,
//        jwnetNo1: res.data.jwnetNo,
        egsMTraderId1: res.data[0].id,
        jwnetNo1: res.data[0].jwnetNo,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
/*
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params',params)
    try {
//      const res = await createEgsMLinkage(params);
      const res = await createEgsMLink(params);
      // console.log(res);
      handleCancel();
      history.push('/egs_m_linkages');
    } catch (e) {
      console.log(e);
    }
*/

    e.preventDefault();
    // 追加
    const params = generateParams();

//    if (window.confirm(' 連携マスタ「' + value.jwnetNo1 + ':' + value.jwnetNo2 + ':' + value.traderCd2 + '」を新規登録します。よろしいですか？')) {
    if (window.confirm(' 連携マスタを新規登録します。よろしいですか？')) {
      try {
        setOpen(!open);
//        const res = await createEgsMLinkage(params);
        const res = await createEgsMLink(params);
        // console.log('res',res);
        setOpen(false);
        handleCancel();
//        history.push('/egs_m_trader_adm');
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }


  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
//      egs_m_trader_id1: value.egsMTraderId1,  // ？？？？？
//      egs_m_trader_id2: value.egsMTraderId2,  // ？？？？？

      jwnetNo1: value.jwnetNo1,

      egsMTraderId1: value.egsMTraderId1,
      egsMTraderId2: value.egsMTraderId2,

    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>連携マスタ新規作成</h2>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          setValue={setValue}
          buttonType='作成' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMLinkageCreat;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
