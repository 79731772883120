// /src/lib/api/demand_b_cnt0930_edi.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getDemandBCnt0930EdiList = () => {
  return client.get('/demand_b_cnt0930_edis');
};

// 詳細
export const getDemandBCnt0930EdiDetail = (id) => {
  return client.get(`/demand_b_cnt0930_edis/${id}`);
};

// 月指定
export const getDemandBCnt0930EdiMonth = (id) => {
  return client.get(`/demand_b_cnt0930_edis/${id}/showmonth`);
};

