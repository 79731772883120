// Form.jsx
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
//import { getTraderSelList } from '../../lib/api/trader_sel';

import './app.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',  // 100ch
    },
  },
}));

const EdiD02ItemForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType } = props;

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);

  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}


  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

//      const res = await getEgsMChargeList();
//      // console.log('res',res.data);
//      setDataList(res.data);
//      const res2 = await getTraderSelList();
//      // console.log(res2.data);
//      setDataList2(res2.data);
    } catch (e) {
      console.log(e);
    }
  };

  const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
  //// console.log('toxicdataList',toxicdataList);


/*
  const chargedataList = dataList.filter(dataList => {
    return dataList.traderCd == value.exhaustCd
  });
  // console.log('chargedataList',chargedataList);
  const traderdataList = dataList2.filter(dataList2 => {
    return dataList2.traderCd != '' & dataList2.traderKbn != '1000000000'
  });
  // console.log('traderdataList',traderdataList);
*/

  return (
    <>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      <Button variant='contained' onClick={() => history.push('/garbage_edi')}>  {/* egs_m_reserve_sets */}
        戻る
      </Button>
      <SpaceRow height={20} />

      <form className={classes.root} noValidate autoComplete='off'>
        <table>
    <th>ゴミタグ番号</th><td><TextField id='garbageTag' label='ゴミタグ番号' variant="outlined" type='text' name='garbageTag' halfWidth margin="dense" value={value.garbageTag} /></td>
    <th>引渡し日</th><td><TextField id='exhaustDate' label='引渡し日' variant="outlined" type='date' name='exhaustDate' halfWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} /></td>
    <th>マニフェスト番号</th><td><TextField id='manifestNo' label='マニフェスト番号' variant="outlined" type='text' name='manifestNo' halfWidth margin="dense" value={value.manifestNo} onChange={(e) => handleChange(e)} /></td>
{/*}          <tr><th>レイアウト番号</th><td><TextField id='layoutNo' label='レイアウト番号' variant="outlined" type='text' name='layoutNo' fullWidth margin="dense" value={value.layoutNo} /></td></tr>  */}
{/*}
          <tr><th>機能番号</th>
              <td>
                <Select
                  id='functionNo'
          label='機能番号'
          variant="outlined"
          type='text'
          name='functionNo'
          margin="dense" fullWidth
          onChange={(e) => handleChange(e)}
          value={value.functionNo}
        >
          <MenuItem value={value.functionNo}>
            <em>機能番号</em>
          </MenuItem>
          <MenuItem value={'0101'}>0101:予約情報の登録</MenuItem>
          <MenuItem value={'0102'}>0102:２次予約情報の登録</MenuItem>
          <MenuItem value={'0201'}>0201:予約情報の修正（抽出）</MenuItem>
          <MenuItem value={'0202'}>0202:予約情報の修正（運搬）</MenuItem>
          <MenuItem value={'0203'}>0203:予約情報の修正（処分）</MenuItem>
          <MenuItem value={'0204'}>0204:２次予約情報の修正（排出）</MenuItem>
          <MenuItem value={'0205'}>0205:２次予約情報の修正（運搬）</MenuItem>
          <MenuItem value={'0206'}>0206:２次予約情報の修正（処分）</MenuItem>
          <MenuItem value={'0401'}>0401:マニフェスト情報の登録（予約情報利用）</MenuItem>
          <MenuItem value={'0402'}>0402:２次マニフェスト情報の登録（予約情報利用）</MenuItem>
          <MenuItem value={'0501'}>0501:マニフェスト情報の登録（新規）</MenuItem>
          <MenuItem value={'0502'}>0502:２次マニフェスト情報の登録（新規）</MenuItem>
          <MenuItem value={'0601'}>0601:マニフェスト情報の修正</MenuItem>
          <MenuItem value={'0603'}>0603:２次マニフェスト情報の修正</MenuItem>
          <MenuItem value={'0103'}>0103:予約情報の登録</MenuItem>
          <MenuItem value={'0104'}>0104:２次予約情報の登録</MenuItem>
          <MenuItem value={'0207'}>0207:予約情報の修正（抽出）</MenuItem>
          <MenuItem value={'0208'}>0208:予約情報の修正（運搬）</MenuItem>
          <MenuItem value={'0209'}>0209:予約情報の修正（処分）</MenuItem>
          <MenuItem value={'0210'}>0210:２次予約情報の修正（排出）</MenuItem>
          <MenuItem value={'0211'}>0211:２次予約情報の修正（運搬）</MenuItem>
          <MenuItem value={'0212'}>0212:２次予約情報の修正（処分）</MenuItem>
          <MenuItem value={'0403'}>0403:マニフェスト情報の登録（予約情報利用）</MenuItem>
          <MenuItem value={'0404'}>0404:２次マニフェスト情報の登録（予約情報利用）</MenuItem>
          <MenuItem value={'0503'}>0503:マニフェスト情報の登録（新規）</MenuItem>
          <MenuItem value={'0504'}>0504:２次マニフェスト情報の登録（新規）</MenuItem>
          <MenuItem value={'0605'}>0605:マニフェスト情報の修正</MenuItem>
          <MenuItem value={'0607'}>0607:２次マニフェスト情報の修正</MenuItem>
         </Select>
    </td>
  </tr>
*/}
    <tr>
    <th>有害物質</th>
    <td>

      <Select id='toxicCd01' label='有害物質１' variant="outlined" type='text' name='toxicCd01' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd01} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>
{/*</td><td>*/}
      <Select id='toxicCd02' label='有害物質２' variant="outlined" type='text' name='toxicCd02' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd02} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>
{/*<tr></tr>*/}
{/*</td><td>*/}
      <Select id='toxicCd03' label='有害物質３' variant="outlined" type='text' name='toxicCd03' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd03} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>
{/*</td><td>*/}
      <Select id='toxicCd04' label='有害物質４' variant="outlined" type='text' name='toxicCd04' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd04} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>
{/*<tr></tr>*/}
{/*</td><td>*/}
      <Select id='toxicCd05' label='有害物質５' variant="outlined" type='text' name='toxicCd05' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd05} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>
{/*</td><td>*/}
      <Select id='toxicCd06' label='有害物質６' variant="outlined" type='text' name='toxicCd06' margin="dense" onChange={(e) => handleChange(e)} value={value.toxicCd06} >
      {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
          {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem> ))}
      </Select>


{/*}
        <Select
          id='toxicCd01'
          label='有害物質名１'
          variant="outlined"
          type='text'
          name='toxicCd01'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd01}
        >
          <MenuItem value={value.toxicCd01}>
            <em>有害物質名１</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
  <tr>
    <th>有害物質名２</th>
    <td>
        <Select
          id='toxicCd02'
          label='有害物質名２'
          variant="outlined"
          type='text'
          name='toxicCd02'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd02}
        >
          <MenuItem value={value.toxicCd03}>
            <em>有害物質名２</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
  <tr>
    <th>有害物質名３</th>
    <td>
        <Select
          id='toxicCd03'
          label='有害物質名３'
          variant="outlined"
          type='text'
          name='toxicCd03'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd03}
        >
          <MenuItem value={value.toxicCd03}>
            <em>有害物質名３</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
  <tr>
    <th>有害物質名４</th>
    <td>
        <Select
          id='toxicCd04'
          label='有害物質名４'
          variant="outlined"
          type='text'
          name='toxicCd04'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd04}
        >
          <MenuItem value={value.toxicCd04}>
            <em>有害物質名４</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
  <tr>
    <th>有害物質名５</th>
    <td>
        <Select
          id='toxicCd05'
          label='有害物質名５'
          variant="outlined"
          type='text'
          name='toxicCd05'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd05}
        >
          <MenuItem value={value.toxicCd05}>
            <em>有害物質名５</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
  <tr>
    <th>有害物質名６</th>
    <td>
        <Select
          id='toxicCd06'
          label='有害物質名６'
          variant="outlined"
          type='text'
          name='toxicCd06'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd06}
        >
          <MenuItem value={value.toxicCd06}>
            <em>有害物質名６</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>

*/}
    </td>
  </tr>
       </table>
      </form>
    </>
  );
};
export default EdiD02ItemForm;
