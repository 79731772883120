import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import { blue } from '@material-ui/core/colors';
import { QrReader } from 'react-qr-reader'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function QRReaderDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [data, setData] = useState('No result');
  const [qrurl, setQrurl] = useState('No result');

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };
  const handleCancel = () => {
    onClose();
  };
  // const [result, setResult] = useState("No result")
  // const handleScan = data => {
  //   if (data) {
  //     setResult(data);
  //   }
  // }
  const handleError = err => {
    console.error(err)
  }


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">排出担当者QRコード読み込み</DialogTitle>
      {/* <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: 400, hight: 400, }}
      />
        <p>{result}</p> */}
        <QrReader
          constraints={{facingMode: 'environment'}}
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
              // 文字列を'/'で分割
              const parts = result?.text.split('/');
              // 最後の要素を除いた配列を取得
              const partsWithoutLast = parts.slice(0, -1);
              // 配列を'/'で再結合してdataに設定
              const modifiedText = partsWithoutLast.join('/');
              setQrurl(modifiedText);
            }
            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: '100%' }}
        />
        {/* <p>{data}</p> */}
        <p>{qrurl}</p>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          キャンセル
        </Button>
        <Button onClick={() => handleListItemClick(data)} key={data} color="primary">
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default QRReaderDialog;

QRReaderDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };
