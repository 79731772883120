import React, { useEffect, useState, useContext } from 'react';
//import { getLinkageSetList } from '../../lib/api/linkage_set';
//import { getEgsMLinkageList } from '../../lib/api/egs_m_linkage';
//import { getLinkageList } from '../../lib/api/linkage';
import { getLinkageUser } from '../../lib/api/linkage';
import { useHistory } from 'react-router-dom';
// style
//import { Button } from '@material-ui/core';
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import LinkageSelTable from './LinkageSelTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LinkageSetSel = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getLinkageList();
      const res = await getLinkageUser(currentUser.traderCd);
      // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>連携マスタ選択</h1>
      <Button variant='contained' onClick={() => history.push('/egs_m_process_flows')}>   {/* garbage_info */}
        戻る
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpaceRow height={20} />
      <LinkageSelTable
        dataList={dataList}
        currentUser={currentUser}
      />
    </>
  );
};
export default LinkageSetSel;
