// /src/lib/api/business_type.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getBusinessTypeList = () => {
  return client.get('/business_types');
};

// 詳細
export const getBusinessTypeDetail = (id) => {
  return client.get(`/business_types/${id}`);
};

// 新規作成
// header情報を追加
export const createBusinessType = (params) => {
  return client.post('/business_types', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateBusinessType = (id, params) => {
  return client.patch(`/business_types/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteBusinessType = (id) => {
  return client.delete(`/business_types/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
