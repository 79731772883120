// Form.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
//import { getTraderSelList } from '../../lib/api/trader_sel';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMReserveSetForm = (props) => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const { handleBack,            handleChange, handleSubmit, value, buttonType } = props;

//  const [dataListT01, setDataListT01] = useState([]);
  const [dataListT01, setDataListT01] = useState([]);
//  const [dataListT02, setDataListT02] = useState([]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
      const dataListT01 = await getEgsMTraderList();
///      const dataListT01 = await getTraderSelList();
      // console.log(dataListT01.data);
      setDataListT01(dataListT01.data);
//      const dataListT02 = await getTraderSelList();
//      setDataListT02(dataListT02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };
  // console.log('currentUser.traderCd',currentUser.traderCd,currentUser.traderKbn,'egsMTraderId',value.egsMTraderId1,value.egsMTraderId2);
  const traderdataList01 = dataListT01.filter(dataListT01 => { return ( dataListT01.id == value.egsMTraderId1 )});
//    dataListT01.traderCd1 == currentUser.traderCd && dataListT01.traderKbn == currentUser.traderKbn ) ||
//    dataListT01.traderCd1 != currentUser.traderCd && dataListT01.traderKbn == '1000000000' });
  // console.log('traderdataList01',traderdataList01);
//  const traderdataList02 = dataListT02.filter(dataListT02 => { return ( dataListT02.id == value.egsMTraderId2 && dataListT02.id != '1000000000' ) });
//  const traderdataList02 = dataListT02.filter(dataListT02 => { return ( dataListT02.id != '1000000000' || dataListT02.id == '1000000000' ) });
//   ( dataListT02.traderCd == currentUser.traderCd ) ||
//   ( dataListT02.traderCd != currentUser.traderCd && dataListT02.traderKbn != '0100000000' ) ) });
  const traderdataList02 = dataListT01.filter(dataListT01 => { return ( dataListT01.id != '1000000000' || dataListT01.id == '1000000000' ) });
  // console.log('traderdataList02',traderdataList02);
//  const traderdataList01 = dataListT01.filter(dataListT01 => { return (
//    dataListT01.traderCd1 == currentUser.traderCd && dataListT01.traderKbn == currentUser.traderKbn ) ||
//    dataListT01.traderCd1 != currentUser.traderCd && dataListT01.traderKbn == '1000000000' });
//  // console.log('traderdataList01',traderdataList01);
//  const traderdataList02 = dataListT01.filter(dataListT01 => { return (
//    dataListT01.traderCd2 == currentUser.traderCd && dataListT01.traderKbn == currentUser.traderKbn ) ||
//    dataListT01.traderCd2 != currentUser.traderCd && dataListT01.traderKbn != '1000000000' });
//  // console.log('traderdataList02',traderdataList02);


  return (
    <>
{/*}
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button variant='contained' onClick={() => history.push('/egs_m_linkage')}>
        戻る
      </Button>
      <SpaceRow height={20} />
  */}

      <div style={{ textAlign: 'right' }}>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>    {/*  egs_m_reserve_sets  */}
          キャンセル
        </Button>
      </div>



      <form className={classes.root} noValidate autoComplete='off'>
       <table>
         <tr><th>事業場１</th>

{/*}
                          <td><TextField id='traderCd1' label='事業場名１' variant="outlined" type='text' name='traderCd1' fullWidth margin="dense" value={value.traderCd1} /></td>
</tr><tr>   <th>事業場名１</th><td><TextField id='traderName1' label='事業場名１' variant="outlined" type='text' name='traderName1' fullWidth margin="dense" value={value.traderName1} /></td>
</tr><tr>   <th>加入者番号１</th><td><TextField id='jwnetNo1' label='加入者番号１' variant="outlined" type='text' name='jwnetNo1' fullWidth margin="dense" value={value.jwnetNo1} /></td>
</tr><tr>   <th>事業場区分１</th><td><TextField id='traderKbn1' label='事業場区分１' variant="outlined" type='text' name='traderKbn1' fullWidth margin="dense" value={value.traderKbn1} /></td>
*/}
          <td>
              <Select id='egsMTraderId1' label='事業場１' variant="outlined" type='text' name='egsMTraderId1' margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId1} >

            {traderdataList01.map((traderdataList01) => (
              <MenuItem
                key={traderdataList01.id}
                value={traderdataList01.id}
              >
                {traderdataList01.id} : {traderdataList01.jwnetNo} : {traderdataList01.traderKbn} : {traderdataList01.traderName}
              </MenuItem>
            ))}
            </Select>
            </td>

   {/*}       <td><TextField id='traderCd1' label='事業場１' variant="outlined" type='text' name='traderCd1' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd1} /></td>  */}
{/*
</tr><tr>   <th>事業場名１</th>       <td><TextField id='traderName1' label='事業場名１' variant="outlined" type='text' name='traderName1' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName1} /></td>
</tr><tr>   <th>加入者番号１</th>       <td><TextField id='jwnetNo1' label='加入者番号１' variant="outlined" type='text' name='jwnetNo1' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetNo1} /></td>
</tr><tr>   <th>事業場区分１</th>       <td><TextField id='traderKbn1' label='事業場区分１' variant="outlined" type='text' name='traderKbn1' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKbn1} /></td>
*/}
        </tr>
        <tr><th>事業場２</th>

          <td>
              <Select
              id='egsMTraderId2'
              label='事業場２'
              variant="outlined"
              type='text'
              name='egsMTraderId2'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.egsMTraderId2}
            >
            {traderdataList02.map((traderdataList02) => (
              <MenuItem
                key={traderdataList02.id}
                value={traderdataList02.id}
              >
                {traderdataList02.id} : {traderdataList02.traderCd} : {traderdataList02.jwnetNo} : {traderdataList02.traderKbn} : {traderdataList02.traderName}
              </MenuItem>
            ))}
            </Select>
            </td>
      {/*}    <td><TextField id='traderCd2' label='事業場２' variant="outlined" type='text' name='traderCd2' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd2} /></td>   */}
{/*
</tr><tr>   <th>事業場名２</th>       <td><TextField id='traderName2' label='事業場名２' variant="outlined" type='text' name='traderName2' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderName2} /></td>
</tr><tr>   <th>加入者番号２</th>       <td><TextField id='jwnetNo2' label='加入者番号２' variant="outlined" type='text' name='jwnetNo2' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.jwnetNo2} /></td>
</tr><tr>   <th>事業場区分２</th>       <td><TextField id='traderKbn2' label='事業場区分２' variant="outlined" type='text' name='traderKbn2' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderKbn2} /></td>
*/}

        </tr>
       </table>
      </form>
    </>
  );
};
export default EgsMReserveSetForm;
/*
        <tr><th>事業場１</th>
      </tr><tr>
    </tr>
        <tr><th>事業場２</th>
          </tr>
*/
