// /src/lib/api/egs_t_garbage_old.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsTGarbageOldList = () => {
  return client.get('/egs_t_garbage_olds');
};

// 事業所指定一覧 idは事業所CD
export const getEgsTGarbageOldUser = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD
export const getEgsTGarbageOldCsvCreate = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/createcsv`);
};

// 収集運搬事業所を指定して排出事業者を検索 idは事業所CD
export const getEgsTGarbageOldExhaust = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showExhaust`);
};

// 詳細（追加・更新用）
export const getEgsTGarbageOldDetail = (id) => {
//  return client.get(`/egs_t_garbage_olds/${id}/showFirst`);
  return client.get(`/egs_t_garbage_olds/${id}`);
};

// 1次マニフェスト更新
// 詳細（追加・更新用）
export const getEgsTGarbageOldDetailFirst = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/updateFirst`);
};

// ステータスと荷受日更新
// 詳細（追加・更新用）
export const getEgsTGarbageOldDetailStatus = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/updateStatus`);
};

// 運用中廃棄物 idは'all'
export const getEgsTGarbageOldOperation = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getEgsTGarbageOldUserOpe = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showuserope`);
};

//  運用中廃棄物 グループ１指定一覧 idはグループCD
export const getEgsTGarbageOldGroup1Ope = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showgroup1ope`);
};

//  運用中廃棄物 グループ２指定一覧 idはグループCD
export const getEgsTGarbageOldGroup2Ope = (id) => {
  return client.get(`/egs_t_garbage_olds/${id}/showgroup2ope`);
};

// 新規作成
// header情報を追加
export const createEgsTGarbageOld = (params) => {
  return client.post('/egs_t_garbage_olds', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTGarbageOld = (id, params) => {
  return client.patch(`/egs_t_garbage_olds/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 1次マニフェスト更新
// header情報を追加
export const updateEgsTGarbageOldFirst = (id, params) => {
  return client.patch(`/egs_t_garbage_olds/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// ステータスと荷受日更新
// header情報を追加
export const updateEgsTGarbageOldStatus = (id, params) => {
  return client.patch(`/egs_t_garbage_olds/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTGarbageOld = (id) => {
  return client.delete(`/egs_t_garbage_olds/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
