// /src/lib/api/trader_sel.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getTraderSelList = () => {
  return client.get('/trader_sels');
};

// 詳細
export const getTraderSelDetail = (id) => {
  return client.get(`/trader_sels/${id}`);
};

// 新規作成
// header情報を追加
export const createTraderSel = (params) => {
  return client.post('/trader_sels', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateTraderSel = (id, params) => {
  return client.patch(`/trader_sels/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteTraderSel = (id) => {
  return client.delete(`/trader_sels/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
