import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";

import dayjs from 'dayjs';
import {
  //  Button,
    TextField,
    MenuItem,
    Select,
    Box,
    Grid,
    Typography,
    InputLabel
  } from '@material-ui/core';
  import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    jaJP
  } from '@mui/x-data-grid-pro'

// import { updateEgsMProcessFlow, getEgsMProcessFlowDetail } from '../../lib/api/egs_m_process_flow';
import { createEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { getProcessFlowId } from '../../lib/api/process_flow';
import FormBody from './EgsMProcessFlowNew4Form';
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
//import { getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
//import { getLinkageList, getLinkageUser ,getLinkageGroup } from '../../lib/api/linkage';
//import { getLinkageExhaust, getLinkageUserExhaust ,getLinkageGroupExhaust } from '../../lib/api/linkage';
import { getTraderAdmUser } from '../../lib/api/trader_adm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}


function EgsMProcessFlowNew4(props) {
  const { isOpen, doClose, currentUser } = props;
  // // console.log("dataList2",dataList2);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataList3, setDataList3] = useState([]);

  const [value, setValue] = useState({
    jwnetNo: '',

    id: 0,
    reserveNo: 0,
    reserveSubno: '',
    reserveName: '',
    manifestNo: '',
    arrangementNo: '',
    reserveDate: '',
    exhaustId: 0,
    exhaustCd: '',
    exhaustChargeId: 0,
    exhaustChargeCd: '',
    sect1TransportId: 0,
    sect1TransportCd: '',
    sect1TransportChargeId: 0,
    sect1TransportChargeCd: '',
    sect1TransportMethodId: 0,
    sect1TransportMethodCd: '',
    sect1CarNo: '',
    destination1Id: 0,
    destination1Cd: '',
    destination1ChargeId: 0,
    destination1ChargeCd: '',
    sect2TransportId: 0,
    sect2TransportCd: '',
    sect2TransportChargeId: 0,
    sect2TransportChargeCd: '',
    sect2TransportMethodId: 0,
    sect2TransportMethodCd: '',
    sect2CarNo: '',
    destination2Id: 0,
    destination2Cd: '',
    destination2ChargeId: 0,
    destination2ChargeCd: '',
    sect3TransportId: 0,
    sect3TransportCd: '',
    sect3TransportChargeId: 0,
    sect3TransportChargeCd: '',
    sect3TransportMethodId: 0,
    sect3TransportMethodCd: '',
    sect3CarNo: '',
    destination3Id: 0,
    destination3Cd: '',
    destination3ChargeId: 0,
    destination3ChargeCd: '',
    sect4TransportId: 0,
    sect4TransportCd: '',
    sect4TransportChargeId: 0,
    sect4TransportChargeCd: '',
    sect4TransportMethodId: 0,
    sect4TransportMethodCd: '',
    sect4CarNo: '',
    destination4Id: 0,
    destination4Cd: '',
    destination4ChargeId: 0,
    destination4ChargeCd: '',
    sect5TransportId: 0,
    sect5TransportCd: '',
    sect5TransportChargeId: 0,
    sect5TransportChargeCd: '',
    sect5TransportMethodId: 0,
    sect5TransportMethodCd: '',
    sect5CarNo: '',
    destination5Id: 0,
    destination5Cd: '',
    destination5ChargeId: 0,
    destination5ChargeCd: '',
    middleProcessingTraderId: 0,
    middleProcessingTraderCd: '',
    middleProcessingChargeId: 0,
    middleProcessingChargeCd: '',
    lastProcessingTraderId: 0,
    lastProcessingTraderCd: '',
    lastProcessingChargeId: 0,
    lastProcessingChargeCd: '',
    processTrustTraderId: 0,
    processTrustTraderCd: '',
    middleWasteFlg: 0,
    lastPlaceFlg: '1',
    egsMWasteclassId: 0,
    wasteKindCd: '',
    wasteCd: '',
    egsMDisposalMethodId: 0,
    disposalMethodCd: '',
    otherMethod: '',
    egsMUnitId: 0,
    unitCd: '',
    egsMPackingId: 0,
    packingCd: '',
    egsMDatasheetId: 0,
    datasheetCd: '',
    registTraderId: 0,
    registTraderCd: '',
    approvalflg: '',
    effectiveflg: '',

    exhaustName: '',
    sect1TransportName: '',
    sect2TransportName: '',
    sect3TransportName: '',
    sect4TransportName: '',
    sect5TransportName: '',
    middleProcessingTraderName: '',
    lastProcessingTraderName: '',
    processTrustTraderName: '',
    destination1Name: '',
    destination2Name: '',
    destination3Name: '',
    destination4Name: '',
    destination5Name: '',
    exhaustChargeName: '',
    sect1TransportChargeName: '',
    sect2TransportChargeName: '',
    sect3TransportChargeName: '',
    sect4TransportChargeName: '',
    sect5TransportChargeName: '',
    middleProcessingChargeName: '',
    lastProcessingChargeName: '',
    destination1ChargeName: '',
    destination2ChargeName: '',
    destination3ChargeName: '',
    destination4ChargeName: '',
    destination5ChargeName: '',
    sect1TransportMethodName: '',
    sect2TransportMethodName: '',
    sect3TransportMethodName: '',
    sect4TransportMethodName: '',
    sect5TransportMethodName: '',
    wasteKindName: '',
    wasteName: '',
    disposalMethodName: '',
    unitName: '',
    packingName: '',

    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',

    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicName01: '',
    toxicName02: '',
    toxicName03: '',
    toxicName04: '',
    toxicName05: '',
    toxicName06: '',

    phone: '',
  });

  // useEffect(() => {
  //   handleGetData(dataList2);
  // }, [dataList2.id]);

  // useEffect(() => {
  //   handleGetData(dataList2);
  // });

  let res;
  let selRows = React.useRef([]);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open);

      res = await getTraderAdmUser(currentUser.traderCd);
/*      
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getLinkageList();
        res = await getLinkageExhaust();
      }else{                                       //事業場区分は管理者以外？
        if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ管理者？
//          res = await getLinkageList();
//          res = await getLinkageGroup(currentUser.traderCd);
          res = await getLinkageGroupExhaust(currentUser.traderCd);
        }else{                                       //事業場区分は管理者以外？
//          res = await getLinkageUser(currentUser.traderCd);
          res = await getLinkageUserExhaust(currentUser.traderCd);
        }
      }
*/
      // console.log(res.data);
      setDataList(res.data);
      value.phone = res.data[0].phone2;
      value.reserveNo = value.phone;

      value.exhaustId = res.data[0].id;
      value.exhaustName = res.data[0].traderName;
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();


  const handleGetData = async (dataList2) => {  //
    try {
      setLoading(true);
//0829      const res = await getEgsMProcessFlowDetail(processId);
//0829      const res = await getEgsMProcessFlowDetail(dataList2.id);
//      const res = await getProcessFlowId(processId);  // getProcessFlowDetail      取得してるがフォームに渡らない…
//0829      // console.log(res.data);
// // console.log('New',New);
      setValue({

      });

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
/*
    if (value.wasteCd) {
      var str_Waste = value.wasteCd;
      value.wasteKindCd = str_Waste.substr(0,2) + '00000';
    }
*/
//    value.reserveNo = value.jwnetNo + '-' + value.wasteCd;    //20221201test
//    value.reserveNo = value.phone + '-' + value.wasteCd;    //20221201test
    value.reserveNo = value.phone + value.wasteCd.substring(0,4);    //20221201test

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    // console.log('setvalue1', value);

/*
    if (value.wasteCd) {
      var str_Waste = value.wasteCd;
      value.wasteKindCd = str_Waste.substr(0,2) + '00000';
    }
    value.reserveNo = value.jwnetNo + '-' + value.wasteCd;    //20221201test
*/

  };

  const wasteChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    value.reserveNo = value.phone + e.target.value.substring(0,4);    //20221201test

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

  };

/*
  useEffect(() => {
    getjwnetno();
  }, [value.exhaustId]);

  const getjwnetno = async (e) => {
      try {
        const res = await getEgsMTraderDetail(value.exhaustId);
        // console.log('getEgsMTraderDetail res',res);
//        value.reserveNo = res.data.jwnetNo + '-' + value.wasteKindCd;
        value.jwnetNo = res.data.jwnetNo;;

      } catch (e) {
        console.log(e);
      }
  };
*/

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params',params);
    if (window.confirm(' 処理フロー「' + value.reserveName + ':' + value.reserveNo + '」を新規登録します。よろしいですか？')) {
      try {
       setOpen(!open);
        const res = await createEgsMProcessFlow(params);
        // console.log('res',res);
        handleCancel();
        history.push('/egs_m_process_flows');
//        window.location.reload();
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      reserveNo: value.reserveNo,
      reserveSubno: value.reserveSubno,
      reserveName: value.reserveName,
      reserveDate: value.reserveDate,
      exhaustId: value.exhaustId,
      exhaustChargeId: value.exhaustChargeId,
      sect1TransportId: value.sect1TransportId,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1ChargeId: value.destination1ChargeId,
      sect2TransportId: value.sect2TransportId,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportMethodId: value.sect2TransportMethodId,
      sect2TransportMethodCd: value.sect2TransportMethodCd,
      sect2CarNo: value.sect2CarNo,
      destination2Id: value.destination2Id,
      destination2ChargeId: value.destination2ChargeId,
      sect3TransportId: value.sect3TransportId,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportMethodCd: value.sect3TransportMethodCd,
      sect3CarNo: value.sect3CarNo,
      destination3Id: value.destination3Id,
      destination3ChargeId: value.destination3ChargeId,
      sect4TransportId: value.sect4TransportId,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportMethodCd: value.sect4TransportMethodCd,
      sect4CarNo: value.sect4CarNo,
      destination4Id: value.destination4Id,
      destination4ChargeId: value.destination4ChargeId,
      sect5TransportId: value.sect5TransportId,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportMethodId: value.sect5TransportMethodId,
      sect5TransportMethodCd: value.sect5TransportMethodCd,
      sect5CarNo: value.sect5CarNo,
      destination5Id: value.destination5Id,
      destination5ChargeId: value.destination5ChargeId,
      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingChargeId: value.lastProcessingChargeId,
      middleWasteFlg: value.middleWasteFlg,
      lastPlaceFlg: value.lastPlaceFlg,
      wasteKindCd: value.wasteKindCd,
      wasteCd: value.wasteCd,
      disposalMethodCd: value.disposalMethodCd,
      otherMethod: value.otherMethod,
      unitCd: value.unitCd,
      packingCd: value.packingCd,
      registTraderCd: value.registTraderCd,
      approvalflg: value.approvalflg,
      effectiveflg: value.effectiveflg,
      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>処理フロー　新規登録</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
      </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleChange={handleChange}
          wasteChange={wasteChange}
          handleSubmit={handleSubmit}
          value={value}
          currentUser={currentUser}
          buttonType='登録' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <h3>ＱＲコード</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p> */}
      </DialogContent>
    </Dialog>
  );
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMProcessFlowNew4;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
