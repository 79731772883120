// /src/lib/api/garbage_mobile.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getGarbageMobileList = () => {
  return client.get('/garbage_mobiles');
};

// id指定
export const getGarbageMobileId = (id) => {
  return client.get(`/garbage_mobiles/${id}/showid`);
};

// マニフェスト指定
export const getGarbageMobileManifest = (id) => {
  return client.get(`/garbage_mobiles/${id}/showManifest`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageMobileUser = (id) => {
  return client.get(`/garbage_mobiles/${id}/showuser`);
};

// グループ１指定一覧 idはグループCD
export const getGarbageMobileGroup1 = (id) => {
  return client.get(`/garbage_mobiles/${id}/showgroup1`);
};

// グループ２指定一覧 idはグループCD
export const getGarbageMobileGroup2 = (id) => {
  return client.get(`/garbage_mobiles/${id}/showgroup2`);
};

// 運用中廃棄物 idは'all'
export const getGarbageMobileOperation = (id) => {
  return client.get(`/garbage_mobiles/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageMobileUserOpe = (id) => {
  return client.get(`/garbage_mobiles/${id}/showuserope`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageMobileUserToday = (id) => {
  return client.get(`/garbage_mobiles/${id}/showusertoday`);
};

//  運用中廃棄物 グループ１指定一覧 idはグループCD
export const getGarbageMobileGroup1Ope = (id) => {
  return client.get(`/garbage_mobiles/${id}/showgroup1ope`);
};

//  運用中廃棄物 グループ２指定一覧 idはグループCD
export const getGarbageMobileGroup2Ope = (id) => {
  return client.get(`/garbage_mobiles/${id}/showgroup2ope`);
};

// 詳細（追加・更新用）
export const getGarbageMobileDetail = (id) => {
  return client.get(`/garbage_mobiles/${id}`);
};

// 新規作成
// header情報を追加
export const createGarbageMobile = (params) => {
  return client.post('/garbage_mobiles', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateGarbageMobile = (id, params) => {
  return client.patch(`/garbage_mobiles/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteGarbageMobile = (id) => {
  return client.delete(`/garbage_mobiles/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
