// /src/lib/api/garbage_cnt_period.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getGarbageCntPeriodList = () => {
  return client.get('/garbage_cnt_periods');
};


