import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import GarbageTmpListTable from './GarbageTmpListTable';
import { getEgsTGarbageTmpList, getEgsTGarbageTmpUser } from '../../lib/api/egs_t_garbage_tmp';
import { getEgsTGarbagePlnUser } from '../../lib/api/egs_t_garbage_pln';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GarbageTmpList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
    handleGetList2();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open); 
      //   res = await getEgsTGarbageTmpOperation(currentUser.traderCd + "_" + currentUser.traderKbn);
       // setDataList(res.data);
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
        res = await getEgsTGarbageTmpList();
      }else if(currentUser.traderKbn == '0100000000'){                                       //事業場区分は管理者以外？
        res = await getEgsTGarbageTmpUser(currentUser.traderCd);
      }
      setDataList(res.data);
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  const handleGetList2 = async () => {
    try {
      setOpen(!open); 
      //   res = await getEgsTGarbageTmpOperation(currentUser.traderCd + "_" + currentUser.traderKbn);
       // setDataList(res.data);
       const  res = await getEgsTGarbagePlnUser(currentUser.traderCd);
       setDataList2(res.data);
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  const history = useHistory();

  return (
    <>
      {/* <h1>排出仮登録状況（{new Date().toLocaleString()})</h1> */}
  
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <SpaceRow height={10} />
  
      <GarbageTmpListTable
        dataList={dataList}
        dataList2={dataList2}
        currentUser={currentUser}
      />
    </>
  );
};
export default GarbageTmpList;
