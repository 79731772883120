// Form.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Checkbox, MenuItem, Select, Typography } from '@material-ui/core';
import './app.css';

//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getEgsMPermitMunicipalityList } from '../../lib/api/egs_m_permit_municipality';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
}));


const PermitEditForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, handleSubmit, value, currentUser, buttonType } = props;
  const [open, setOpen] = React.useState(false);

  const [traderList, setTraderList] = useState([]);
  const [muniList, setMuniDataList] = useState([]);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      const res = await getEgsMTraderList();
//      // console.log(res.data);
//      setTraderList(res.data);
//    }

      const resMuni = await getEgsMPermitMunicipalityList();
      setMuniDataList(resMuni.data);
      // console.log('permit_municipality',muniList.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

//  const traderdataList = traderList.filter(traderList => { return traderList.traderCd == value.traderCd });
//  // console.log('traderdataList',traderdataList)
  const munidataList = muniList.filter(muniList => { return muniList.municipalityCd != '' });
  // console.log('munidataList',munidataList)

//  const [errorTraderCd, setErrorTraderCd] = useState(false);  // 事業場CD
  const [errorPermitNo, setErrorPermitNo] = useState(false);  // 許可証番号
  const [errorMunicipalityName, setErrorMunicipalityName] = useState(false);  // 発行自治体
  const [errorPermitType, setErrorPermitType] = useState(false);  // 種別

  const checkValidation = () => {
    let available = true;
/*
    if (!value.traderCd) {  // || !value.traderName) {    //  || !traderdataList.traderName) {
      // console.log('traderCd',value.traderCd,value.traderName,traderdataList.traderName);
      alert('事業場CDに誤りがあります')
      available = false;
      setErrorTraderCd(true);
    }else{
      setErrorTraderCd(false);
    }
*/
//                                     符号あり  /^-?[0-9]+$/
    if (!value.permitNo || !value.permitNo.match(/^[0-9]+$/) || value.permitNo < 0 || value.permitNo.match(/^[0-9]+\.[0-9]+$/)) {
      // console.log('permitNo',value.permitNo);
//      alert('許可証番号に誤りがあります')
      available = false;
      setErrorPermitNo(true);
    }else{
      setErrorPermitNo(false);
    }

    if (!value.municipalityName ) {
//      alert('発行自治体に誤りがあります')
      available = false;
      setErrorMunicipalityName(true);
    }else{
      setErrorMunicipalityName(false);
    }

    if (!value.permitType ) {
//      alert('種別に誤りがあります')
      available = false;
      setErrorPermitType(true);
    }else{
      setErrorPermitType(false);
    }
  return available;
  }

  if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
    return (
      <>
{/*}
        <div style={{ textAlign: 'right' }}>
        <Button variant='contained'
  //       onClick={() => history.push('/permit')}>
          onClick={e => {
            e.preventDefault();
            handleBack();
          }}>
          戻る
        </Button>
        </div>
        <SpaceRow height={20} />
*/}
        <form className={classes.root} noValidate autoComplete='off'>
         <table>
          <tr><th>事業場CD</th><td>
{/*}
            <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorTraderCd}
              value={value.traderCd}
            />
*/}
          <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderCd}</Typography>

          </td></tr>
          <tr><th>事業場名称</th><td>
            <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderName}</Typography>
{/*}
            <Select
                id='traderCd'
                label='事業場名称'
                variant="standard"  // outlined
                type='text'
                name='traderCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.traderName}
                </MenuItem>
              ))}
            </Select>
*/}
          </td></tr>
          <tr><th>許可証番号</th>
            <td>
              <Typography variant="h7" style={{ marginTop: 10 }}>{value.permitNo}</Typography>
{/*}
              <TextField id='permitNo' label='許可証番号' variant="outlined" type='text' name='permitNo' halfWidth margin="dense"
                onChange={(e) => handleChange(e)}
                error={errorPermitNo}
                value={value.permitNo}
                // これでも小数点以下が入力できてしまう！！！
                inputProps={{ maxLength: 20, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
             />
*/}
            </td>
          </tr>
          <tr><th>発行自治体</th><td>
            <Select
              id='municipalityName'
              label='単位'
              variant="outlined"
              type='text'
              name='municipalityName'
              margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorMunicipalityName}
              value={value.municipalityName}
              halfWidth
              style={{
                marginRight: 16
              }}
            >
            {munidataList.map((munidataList) => (
              <MenuItem
                key={munidataList.municipalityName}
                value={munidataList.municipalityName}
              >
                {munidataList.municipalityName}
              </MenuItem>
            ))}
            </Select>
      </td>
      </tr>
    <tr>
      <th>種別</th>
      <td>
          <Select
            id='permitType'
            label='種別'
            variant="outlined"
            type='text'
            name='permitType'
            margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorPermitType}
            value={value.permitType}
          >
            <MenuItem value={value.permitType}>
              <em>種別</em>
            </MenuItem>
            <MenuItem value={'産業廃棄物収集運搬業(積替を含まないもの)'}>産業廃棄物収集運搬業(積替を含まないもの)</MenuItem>
            <MenuItem value={'産業廃棄物収集運搬業(積替を含むもの)'}>産業廃棄物収集運搬業(積替を含むもの)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(中間処分のみ)'}>産業廃棄物処分業(中間処分のみ)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(最終処分のみ)'}>産業廃棄物処分業(最終処分のみ)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(中間処分・最終処分)'}>産業廃棄物処分業(中間処分・最終処分)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物収集運搬業(積替を含まないもの)'}>特別管理産業廃棄物収集運搬業(積替を含まないもの)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物収集運搬業(積替を含むもの)'}>特別管理産業廃棄物収集運搬業(積替を含むもの)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(中間処分のみ)'}>特別管理産業廃棄物処分業(中間処分のみ)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(最終処分のみ)'}>特別管理産業廃棄物処分業(最終処分のみ)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(中間処分・最終処分)'}>特別管理産業廃棄物処分業(中間処分・最終処分)</MenuItem>
           </Select>
      </td>
    </tr>
          <tr><th>概要</th><td>
            <TextField id='dispName' label='表示名称' variant="outlined" type='text' name='dispName' fullWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.dispName}
            /></td></tr>
          <tr><th>詳細</th><td>
            <TextField id='content' label='許可証内容' variant="outlined" type='text' name='content' fullWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.content}
            /></td></tr>
         </table>
        </form>
        <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
                }
            }}
            style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
        </div>
      </>
    );
    }else{                                       //事業場区分は管理者以外？
    return (
      <>
        <form className={classes.root} noValidate autoComplete='off'>
         <table>
{/*}
         <tr><th>事業場CD</th><td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
//            onChange={(e) => handleChange(e)}
            value={value.traderCd}
//            defaultValue={currentUser.traderCd}
            />
          </td></tr>
          <tr><th>事業場名称</th><td>
            <Select
                id='traderCd'
                label='事業場名称'
                variant="standard"  // outlined
                type='text'
                name='traderCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.traderName}
                </MenuItem>
              ))}
            </Select>
            </td></tr>
*/}

          <tr><th>許可証番号</th>
            <td>
              <TextField id='permitNo' label='許可証番号' variant="outlined" type='number' name='permitNo' halfWidth margin="dense"
                onChange={(e) => handleChange(e)}
                error={errorPermitNo}
                value={value.permitNo}
                // これでも小数点以下が入力できてしまう！！！
                inputProps={{ maxLength: 20, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
                />
              </td>
            </tr>
          <tr><th>発行自治体</th><td>
            <Select
              id='municipalityName'
              label='単位'
              variant="outlined"
              type='text'
              name='municipalityName'
              margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorMunicipalityName}
              value={value.municipalityName}
              halfWidth
              style={{
                marginRight: 16
              }}
            >
            {munidataList.map((munidataList) => (
              <MenuItem
                key={munidataList.municipalityName}
                value={munidataList.municipalityName}
              >
                {munidataList.municipalityName}
              </MenuItem>
            ))}
            </Select>
      </td>
      </tr>
    <tr>
      <th>種別</th>
      <td>
          <Select
            id='permitType'
            label='種別'
            variant="outlined"
            type='text'
            name='permitType'
            margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorPermitType}
            value={value.permitType}
          >
            <MenuItem value={value.permitType}>
              <em>種別</em>
            </MenuItem>
            <MenuItem value={'産業廃棄物収集運搬業(積替を含まないもの)'}>産業廃棄物収集運搬業(積替を含まないもの)</MenuItem>
            <MenuItem value={'産業廃棄物収集運搬業(積替を含むもの)'}>産業廃棄物収集運搬業(積替を含むもの)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(中間処分のみ)'}>産業廃棄物処分業(中間処分のみ)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(最終処分のみ)'}>産業廃棄物処分業(最終処分のみ)</MenuItem>
            <MenuItem value={'産業廃棄物処分業(中間処分・最終処分)'}>産業廃棄物処分業(中間処分・最終処分)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物収集運搬業(積替を含まないもの)'}>特別管理産業廃棄物収集運搬業(積替を含まないもの)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物収集運搬業(積替を含むもの)'}>特別管理産業廃棄物収集運搬業(積替を含むもの)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(中間処分のみ)'}>特別管理産業廃棄物処分業(中間処分のみ)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(最終処分のみ)'}>特別管理産業廃棄物処分業(最終処分のみ)</MenuItem>
            <MenuItem value={'特別管理産業廃棄物処分業(中間処分・最終処分)'}>特別管理産業廃棄物処分業(中間処分・最終処分)</MenuItem>
           </Select>
      </td>
    </tr>
          <tr><th>概要</th><td><TextField id='dispName' label='表示名称' variant="outlined" type='text' name='dispName' fullWidth margin="dense" onChange={(e) => handleChange(e)} /></td></tr>
          <tr><th>詳細</th><td><TextField id='content' label='許可証内容' variant="outlined" type='text' name='content' fullWidth margin="dense" onChange={(e) => handleChange(e)} /></td></tr>
         </table>
        </form>
        <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
               }
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
        </div>
      </>
    );
    }


};
export default PermitEditForm;
/*

        <TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='number' name='egsMTraderId' onChange={(e) => handleChange(e)} />
        <TextField id='permitType' label='許可証種別' variant="outlined" type='text' name='permitType' fullWidth margin="dense" onChange={(e) => handleChange(e)} />
        <TextField id='filePath' label='ファイルパス' variant="outlined" type='text' name='filePath' onChange={(e) => handleChange(e)} />
        <TextField id='fileName' label='ファイル名' variant="outlined" type='text' name='fileName' onChange={(e) => handleChange(e)} />
        <TextField id='mimeType' label='MIME-TYPE' variant="outlined" type='text' name='mimeType' onChange={(e) => handleChange(e)} />
        <TextField id='effectiveflg' label='有効フラグ' variant="outlined" type='text' name='effectiveflg' onChange={(e) => handleChange(e)} />

*/
