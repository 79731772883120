// /src/lib/api/egs_m_transport_method.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMTransportMethodList = () => {
  return client.get('/egs_m_transport_methods');
};

// 事業所指定一覧 idは事業所CD
export const getEgsMTransportMethodUser = (id) => {
  return client.get(`/egs_m_transport_methods/${id}/showuser`);
};

// 詳細（追加・更新用）
export const getEgsMTransportMethodDetail = (id) => {
  return client.get(`/egs_m_transport_methods/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMTransportMethod = (params) => {
  return client.post('/egs_m_transport_methods', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMTransportMethod = (id, params) => {
  return client.patch(`/egs_m_transport_methods/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMTransportMethod = (id) => {
  return client.delete(`/egs_m_transport_methods/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
