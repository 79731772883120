// /src/lib/api/entry_links.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEntryLinkList = () => {
  return client.get('/entry_links');
};


// 詳細
export const getEntryLinkDetail = (id) => {
  return client.get(`/entry_links/${id}`);
};

// 検索条件 idは'all'
export const getEntryLinkOpe = (id) => {
  return client.get(`/entry_links/${id}/showope`);
};

