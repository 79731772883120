// /src/lib/api/receipt_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptExhaustList = () => {
  return client.get('/receipt_exhausts');
};

// 事業場選択一覧
export const getReceiptExhaustUserDetail = (id) => {
  return client.get(`/receipt_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptExhaustUser = (id) => {
  return client.get(`/receipt_exhausts/${id}/showuser`);
};

// 事業場選択一覧  荷降用
export const getReceiptExhaustUnload = (id) => {
  return client.get(`/receipt_exhausts/${id}/showUnload`);
};

