// /src/lib/api/process_flow.js
import client from './client';
//import Cookies from 'js-cookie';

// 一覧
export const getProcessFlowList = () => {
  return client.get('/process_flows');
};

// 事業所指定一覧 idは事業所CD
export const getProcessFlowUser = (id) => {
  return client.get(`/process_flows/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD
export const getProcessFlowUser47 = (id) => {
  return client.get(`/process_flows/${id}/showuser47`);
};

// 事業所指定一覧 idは事業所CD
export const getProcessFlowId = (id) => {
  return client.get(`/process_flows/${id}/showid`);
};

// グループ指定一覧 idはグループ１と２
export const getProcessFlowGroup = (id) => {
  return client.get(`/process_flows/${id}/showgroup`);
};
// 処理フロー番号指定 idは処理フロー番号
export const getProcessFlowReserveNo = (id) => {
  return client.get(`/process_flows/${id}/showreserveno`);
};

// 詳細（詳細表示用）
export const getProcessFlowDetail = (id) => {
  console.log(id)
  return client.get(`/process_flows/${id}`);    // process_flows
};

// 運用中廃棄物 idは'all'
export const getProcessFlowOperation = (id) => {
  return client.get(`/process_flows/${id}/showope`);
};
