// /src/lib/api/garbage_detail_csv.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getGarbageDetailCsvList = () => {
  return client.get('/garbage_detail_csvs');
};

// id指定
export const getGarbageDetailCsvId = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showid`);
};

// マニフェスト指定
export const getGarbageDetailCsvManifest = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showManifest`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageDetailCsvUser = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageDetailCsvCreate = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/createcsv`);
};

// グループ１指定一覧 idはグループCD
export const getGarbageDetailCsvGroup1 = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showgroup1`);
};

// グループ２指定一覧 idはグループCD
export const getGarbageDetailCsvGroup2 = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showgroup2`);
};

// 運用中廃棄物 idは'all'
export const getGarbageDetailCsvOperation = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageDetailCsvUserOpe = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showuserope`);
};

//  運用中廃棄物 グループ１指定一覧 idはグループCD
export const getGarbageDetailCsvGroup1Ope = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showgroup1ope`);
};

//  運用中廃棄物 グループ２指定一覧 idはグループCD
export const getGarbageDetailCsvGroup2Ope = (id) => {
  return client.get(`/garbage_detail_csvs/${id}/showgroup2ope`);
};

// 詳細（追加・更新用）
export const getGarbageDetailCsvDetail = (id) => {
  return client.get(`/garbage_detail_csvs/${id}`);
};

// 新規作成
// header情報を追加
export const createGarbageDetailCsv = (params) => {
  return client.post('/garbage_detail_csvs', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateGarbageDetailCsv = (id, params) => {
  return client.patch(`/garbage_detail_csvs/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteGarbageDetailCsv = (id) => {
  return client.delete(`/garbage_detail_csvs/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
