import React, { useState, useEffect } from 'react';
import { Dialog,
         DialogTitle,
         DialogContent,
         Backdrop,
         CircularProgress,
         makeStyles,
         Button,
         Typography,
         TextField,
         Paper,
         Slide } from '@material-ui/core';
import Draggable from "react-draggable";
import SpaceRow from '../commons/SpaceRow';
import { updateSagyoReport } from '../../lib/api/sagyo_report';

const PaperComponent = ({...props}) => {
    const nodeRef = React.useRef(null);
  
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        nodeRef={nodeRef}
      >
        <Paper ref={nodeRef} {...props} />
      </Draggable>
    );
  }

function MblSagyoReportEdit(props) {
    const { isOpen, doClose, dataList, onDataUpdated } = props;
    const [editedData, setEditedData] = useState({ ...dataList });
    const [loading, setLoading] = useState(false);
    // console.log('dataList:', dataList);

    useEffect(() => {
      setEditedData({ ...dataList }); // Ensure editedData is updated when dataList changes
    }, [dataList]);

    const useStyles = makeStyles((theme) => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
      }));
    
    const classes = useStyles();

    const handleFieldChange = (field, value) => {
      if (field === 'sagyoTime') {
          // Ensure the input is in hh:mm format
          const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          if (timePattern.test(value)) {
              setEditedData({ ...editedData, [field]: value });
          }
      } else {
          setEditedData({ ...editedData, [field]: value });
      }
  };

    const handleCancel = () => {
        setLoading(false);
        doClose();
    };

    const handleSave = async () => {
        if (window.confirm('作業報告を更新します。よろしいですか？')) {
          setLoading(true);
          try {
            console.log('保存するデータ:', editedData);
            const res = await updateSagyoReport(dataList.id,editedData);
            console.log("updateSagyoReport_res",res);
            onDataUpdated(); // 親コンポーネントのコールバックを呼び出す
          } catch (e) {
            console.log(e);
            console.log(e.response);
          } 
          setLoading(false);
          doClose(); // ダイアログを閉じる
        }
    };

    return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者作業報告編集</h2>
      </DialogTitle>
        <DialogContent>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <table style={{ fontSize: '1rem' }}>
            <tr>
                <th>日時</th>
                <td>
                    <TextField
                      type="date"
                      onChange={(e) => handleFieldChange('sagyoDate', e.target.value)}
                      variant="outlined"
                      value={editedData.sagyoDate || dataList.sagyoDate}
                      size="small"
                      InputLabelProps={{
                          shrink: true,
                      }}
                    />
                    <Typography variant="h6" style={{ fontWeight: 700 }}>　{new Date(dataList.sagyoTime).toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' })}
                    　⇒　
                    <TextField
                      type="time"
                      onChange={(e) => handleFieldChange('sagyoTime', e.target.value)}
                      variant="outlined"
                      value={editedData.sagyoTime || (dataList.sagyoTime ? new Date('1970-01-01T' + dataList.sagyoTime + 'Z').toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit', hour12: false }) : '')}
                      placeholder="hh:mm"
                      size="small"
                      InputLabelProps={{
                          shrink: true,
                      }}
                    />
                    </Typography>
                </td>
            </tr>
            <tr>
                <th>内容</th>
                <td>
                    <Typography variant="h6" style={{ fontWeight: 700 }}>{dataList.sagyoTask}</Typography>
                </td>
            </tr>
            <tr>
                <th>場所</th>
                <td>
                    <TextField
                      type="text"
                      onChange={(e) => handleFieldChange('sagyoPlace', e.target.value)}
                      variant="outlined"
                      value={editedData.sagyoPlace}
                      size="small"
                      disabled={!(dataList.sagyoTask === '作業開始' || dataList.sagyoTask === '作業終了')}
                      fullWidth
                      style={{ fontWeight: 700, fontSize: '1.2rem' }}
                    />
                </td>
            </tr>
            <tr>
                <th>メモ</th>
                <td>
                  <TextField
                    type="text"
                    onChange={(e) => handleFieldChange('sagyoMemo', e.target.value)}
                    variant="outlined"
                    value={editedData.sagyoMemo}
                    size="small"
                    multiline
                    maxRows={4}
                    fullWidth
                    style={{ fontWeight: 700, fontSize: '1.2rem' }}
                  />
                </td>
            </tr>
        </table>
        <SpaceRow height={20} />
        </DialogContent>
        <div style={{ textAlign: 'right' }}>
            <Button variant='contained' onClick={handleCancel} style={{ marginRight: 30 }}>
                戻る
            </Button>
            <Button variant='contained' color="primary" onClick={handleSave} style={{ marginRight: 30 }}>
                更新
            </Button>
        </div>
        <SpaceRow height={20} />
    </Dialog>

    );
}

export default MblSagyoReportEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });