// /src/lib/api/trader_adm.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getTraderAdmList = () => {
  return client.get('/trader_adms');
};

// 事業所指定一覧 idは事業所CD
export const getTraderAdmUser = (id) => {
  return client.get(`/trader_adms/${id}/showuser`);
};

// 事業所指定一覧 idは加入者番号
export const getTraderAdmJwnet = (id) => {
  return client.get(`/trader_adms/${id}/showjwnetNo`);
};

// 事業所指定一覧 idはグループ１と２
export const getTraderAdmGroup = (id) => {
  return client.get(`/trader_adms/${id}/showgroup`);
};

// 事業所指定一覧 idはグループ１
export const getTraderAdmGroup1 = (id) => {
  return client.get(`/trader_adms/${id}/showgroup1`);
};

// 事業所指定一覧 idはグループ２
export const getTraderAdmGroup2 = (id) => {
  return client.get(`/trader_adms/${id}/showgroup2`);
};

// 事業所指定一覧 idは事業者区分
export const getTraderAdmKbn = (id) => {
  return client.get(`/trader_adms/${id}/showKbn`);
};

// 事業所指定一覧 idはid
export const getTraderAdmId = (id) => {
  return client.get(`/trader_adms/${id}/showId`);
};

// 詳細
export const getTraderAdmDetail = (id) => {
  return client.get(`/trader_adms/${id}`);
};

// 検索条件 idは'all'
export const getTraderAdmOperation = (id) => {
  return client.get(`/trader_adms/${id}/showope`);
};

// 新規作成
// header情報を追加
export const createTraderAdm = (params) => {
  return client.post('/trader_adms', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateTraderAdm = (id, params) => {
  return client.patch(`/trader_adms/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteTraderAdm = (id) => {
  return client.delete(`/trader_adms/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
