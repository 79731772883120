// /src/lib/api/user.js
import client from './client';
import Cookies from 'js-cookie';

export const getUserPosts = (id) => {
  return client.get(`/users/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};


// 一覧
export const getUserList = () => {
  return client.get('/users');
};

// 詳細
export const getUserDetail = (id) => {
  return client.get(`/users/${id}`);
};

// 新規作成
// header情報を追加
export const createUser = (params) => {
  return client.post('/users', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateUser = (id, params) => {
  return client.patch(`/users/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteUser = (id) => {
  return client.delete(`/users/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
