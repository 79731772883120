// /src/lib/api/garbage_pc.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getGarbagePcList = () => {
  return client.get('/garbage_pcs');
};

// id指定
export const getGarbagePcId = (id) => {
  return client.get(`/garbage_pcs/${id}/showid`);
};

// マニフェスト指定
export const getGarbagePcManifest = (id) => {
  return client.get(`/garbage_pcs/${id}/showManifest`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbagePcUser = (id) => {
  return client.get(`/garbage_pcs/${id}/showuser`);
};

// グループ１指定一覧 idはグループCD
export const getGarbagePcGroup1 = (id) => {
  return client.get(`/garbage_pcs/${id}/showgroup1`);
};

// グループ２指定一覧 idはグループCD
export const getGarbagePcGroup2 = (id) => {
  return client.get(`/garbage_pcs/${id}/showgroup2`);
};

// 運用中廃棄物 idは'all'
export const getGarbagePcOperation = (id) => {
  return client.get(`/garbage_pcs/${id}/showope`);
};

//  運用中廃棄物 グループ１指定一覧 idはグループCD
export const getGarbagePcGroup1Ope = (id) => {
  return client.get(`/garbage_pcs/${id}/showgroup1ope`);
};

//  運用中廃棄物 グループ２指定一覧 idはグループCD
export const getGarbagePcGroup2Ope = (id) => {
  return client.get(`/garbage_pcs/${id}/showgroup2ope`);
};

// 詳細（追加・更新用）
export const getGarbagePcDetail = (id) => {
  return client.get(`/garbage_pcs/${id}`);
};

// 新規作成
// header情報を追加
export const createGarbagePc = (params) => {
  return client.post('/garbage_pcs', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateGarbagePc = (id, params) => {
  return client.patch(`/garbage_pcs/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteGarbagePc = (id) => {
  return client.delete(`/garbage_pcs/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
