import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';

import { getChargeLinkageUser } from '../../lib/api/charge_linkage';

import { getLinkageSectUser } from '../../lib/api/linkage_sect';
import { getChargeUser } from '../../lib/api/charge';
import { getChargeSelList } from '../../lib/api/charge_sel';
import { getEgsMTraderList, getEgsMTraderUser } from '../../lib/api/egs_m_trader';
//import { getEgsMLinkageUser } from '../../lib/api/egs_m_linkage';
import { getLinkageUser } from '../../lib/api/linkage';
import { getLinkageList } from '../../lib/api/linkage';

//import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getWasteCdList } from '../../lib/api/waste_cd';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { toxicSubstanceName, transportMethodName } from '../../constants'

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '150ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "70vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EgsMProcessFlowAddForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, dataList2, buttonType } = props;

  // console.log('setvalue2',value);

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const [open, setOpen] = React.useState(false);

  const [dataListT00, setDataListT00] = useState([]);    {/* 選択事業場 */}
  const [dataListT10, setDataListT10] = useState([]);    {/* 選択事業場 */}
  const [dataListC00, setDataListC00] = useState([]); {/* 排出担当者 */ }
  // const [dataListC10, setDataListC10] = useState([]); {/* 区間1運搬業者担当者 */ }
  // const [dataListC11, setDataListC11] = useState([]); {/* 区間1運搬先業者担当者 */ }
  // const [dataListC20, setDataListC20] = useState([]); {/* 区間2運搬業者担当者 */ }
  // const [dataListC21, setDataListC21] = useState([]); {/* 区間2運搬先業者担当者 */ }
  // const [dataListC30, setDataListC30] = useState([]); {/* 区間3運搬業者担当者 */ }
  // const [dataListC31, setDataListC31] = useState([]); {/* 区間3運搬先業者担当者 */ }
  // const [dataListC40, setDataListC40] = useState([]); {/* 区間4運搬業者担当者 */ }
  // const [dataListC41, setDataListC41] = useState([]); {/* 区間4運搬先業者担当者 */ }
  // const [dataListC50, setDataListC50] = useState([]); {/* 区間5運搬業者担当者 */ }
  // const [dataListC51, setDataListC51] = useState([]); {/* 区間5運搬先業者担当者 */ }
  // const [dataListC70, setDataListC70] = useState([]); {/* 最終処分場担当者 */ }

  const [dataListWaste, setDataListWaste] = useState([]); {/* 廃棄物種類 */ }
  const [dataListToxic, setDataListToxic] = useState([]); {/* 有害物質 */ }
  const [dataListDisposal, setDataListDisposal] = useState([]); {/* 処分方法 */ }
  const [dataListTransport, setDataListTransport] = useState([]); {/* 運搬方法 */ }
  const [dataListUnit, setDataListUnit] = useState([]); {/* 単位 */ }
  const [dataListPacking, setDataListPacking] = useState([]); {/* 荷姿 */ }

  const [errorExhaustId, setErrorExhaustId] = useState(false);
  const [errorExhaustChargeId, setExhaustChargeId] = useState(false);
  const [errorWasteCd1, setErrorWasteCd1] = useState(false);
  const [errorWasteCd, setErrorWasteCd] = useState(false);
  const [errorDisposalMethodCd, setErrorDisposalMethodCd] = useState(false);
  const [errorUnitCd, setErrorUnitCd] = useState(false);
  const [errorPackingCd, setErrorPackingCd] = useState(false);
  const [errorLastPlaceFlg, setErrorLastPlaceFlg] = useState(false);
  const [errorLastProcessingTraderId, setErrorLastProcessingTraderId] = useState(false);
  const [errorLastProcessingChargeId, setErrorLastProcessingChargeId] = useState(false);
  const [errorSect1TransportId, setErrorSect1TransportId] = useState(false);
  const [errorApproveflg, setErrorApproveflg] = useState(false);

  const checkValidation = () => {
    let available = true;
    if (!value.exhaustId ||
      !value.exhaustChargeId ||
      !value.wasteCd1 ||
      !value.wasteCd ||
      !value.disposalMethodCd ||
      !value.unitCd ||
      !value.packingCd ||
      !value.lastPlaceFlg ||
      !value.lastProcessingTraderId ||
      !value.lastProcessingChargeId ||
      !value.sect1TransportId ||
      !value.approvalflg
    ) {
      // console.log(
//        !value.exhaustId,
//        !value.exhaustChargeId,
//        !value.wasteCd1,
//        !value.wasteCd,
//        !value.disposalMethodCd,
//        !value.unitCd,
//        !value.packingCd,
//        !value.lastPlaceFlg,
//        !value.lastProcessingTraderId,
//        !value.lastProcessingChargeId,
//        !value.sect1TransportId,
//        !value.approvalflg
//      );
      // console.log(value.approvalflg);
      available = false;
//      setErrorExhaustId(value.exhaustId === undefined);
      setExhaustChargeId(!value.exhaustChargeId);
//      setErrorWasteCd1(!value.wasteCd1);
//      setErrorWasteCd(!value.wasteCd);
      setErrorDisposalMethodCd(!value.disposalMethodCd);
//      setErrorUnitCd(!value.unitCd);
//      setErrorPackingCd(!value.packingCd);
      setErrorLastPlaceFlg(!value.lastPlaceFlg);
//      setErrorLastProcessingTraderId(!value.lastProcessingTraderId);
      setErrorLastProcessingChargeId(!value.lastProcessingChargeId);
//      setErrorSect1TransportId(!value.sect1TransportId);
      setErrorApproveflg(value.approvalflg !== "1");
    }
    return available;
  }

  const handleClick2 = (e) => {
    value.sect2TransportId = 0;
    value.sect2TransportChargeId = 0;
    value.sect2TransportMethodCd = '';
    value.sect2CarNo = '';
    value.destination2Id = 0;
    value.destination2ChargeId = 0;
    value.memo2 = '';
    handleChange(e);
  };
  const handleClick3 = (e) => {
    value.sect3TransportId = 0;
    value.sect3TransportChargeId = 0;
    value.sect3TransportMethodCd = '';
    value.sect3CarNo = '';
    value.destination3Id = 0;
    value.destination3ChargeId = 0;
    value.memo3 = '';
    handleChange(e);
  };
  const handleClick4 = (e) => {
    value.sect4TransportId = 0;
    value.sect4TransportChargeId = 0;
    value.sect4TransportMethodCd = '';
    value.sect4CarNo = '';
    value.destination4Id = 0;
    value.destination4ChargeId = 0;
    value.memo4 = '';
    handleChange(e);
  };
  const handleClick5 = (e) => {
    value.sect5TransportId = 0;
    value.sect5TransportChargeId = 0;
    value.sect5TransportMethodCd = '';
    value.sect5CarNo = '';
    value.destination5Id = 0;
    value.destination5ChargeId = 0;
    value.memo5 = '';
    handleChange(e);
  };

  useEffect(() => {
    handleGetList();
  }, [value.id]);

  const handleGetList = async () => {
    try {
      setOpen(!open);

      const resT00 = await getLinkageUser(value.sect1TransportCd);
      setDataListT00(resT00.data);

      const resT10 = await getLinkageSectUser(value.sect1TransportCd);
      setDataListT10(resT10.data);

//      const resC00 = await getEgsMChargeList();
      const resC00 = await getChargeLinkageUser(currentUser.traderCd);
      setDataListC00(resC00.data);
//      const dataListWaste = await getEgsMWasteclassList();
      const dataListWaste = await getWasteCdList();
      setDataListWaste(dataListWaste.data);
      const dataListToxic = await getEgsMToxicSubstanceList();
      setDataListToxic(dataListToxic.data);
      const dataListDisposal = await getEgsMDisposalMethodList();
      setDataListDisposal(dataListDisposal.data);
      const dataListTransport = await getEgsMTransportMethodList();
      setDataListTransport(dataListTransport.data);
      const dataListUnit = await getEgsMUnitList();
      setDataListUnit(dataListUnit.data);
      const dataListPacking = await getEgsMPackingList();
      setDataListPacking(dataListPacking.data);

      // console.log('getLinkageUser resT00', resT00);
      // console.log("resC00.data",resC00.data);
      //    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);
      //    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);
      //    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);
      //    // console.log('sect1TransportMethodCd', value.sect1TraresC20nsportMethodCd, 'dataListTransport', dataListTransport.data);
      //    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);
      //    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);

      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


const dataListWasteKind = dataListWaste.filter(dataListWaste =>
  { return dataListWaste.wasteKindCd == dataListWaste.wasteCd });

const dataListWasteCd = dataListWaste.filter(dataListWaste =>
  { return dataListWaste.wasteKindCd == value.wasteKindCd && dataListWaste.effectiveflg == '1' });


//const traderdataListT00 = dataListT00.filter(dataListT00 =>
//  { return dataListT00.traderKbn2 == '1000000000' });
//const traderdataListT10 = dataListT00.filter(dataListT00 =>
//  { return dataListT00.traderKbn1 == '0100000000' });

const traderdataListT10 = dataListT10;

const traderdataListT11 = dataListT00.filter(dataListT00 =>
  { return dataListT00.traderKbn2 == '0010000000' ||
           dataListT00.traderKbn2 == '0001000000' ||
           dataListT00.traderKbn2 == '0000100000' ||
           dataListT00.traderKbn2 == '0001001000' });
const traderdataListT70 = dataListT00.filter(dataListT00 =>
  { return dataListT00.traderKbn2 == '0001000000' ||  // 仮で中間処理場も対象
           dataListT00.traderKbn2 == '0001001000' ||  // 中間排出も対象
           dataListT00.traderKbn2 == '0000100000' });
/*
const traderdataListT80 = dataListT00.filter(dataListT00 =>
  { return dataListT00.traderKbn2 == '0001000000' ||  // 仮で中間処理場も対象
           dataListT00.traderKbn2 == '0000100000' });
*/
// console.log('traderdataListT00',traderdataListT00);
// console.log('traderdataListT10',traderdataListT10);
// console.log('traderdataListT11',traderdataListT11);
// console.log('traderdataListT70',traderdataListT70);

const chargedataList00 = dataListC00.filter(dataListC00 =>
  { return value.exhaustId != 0 && dataListC00.egsMTraderId2 == value.exhaustId });
//const chargedataList00 = dataListC00.filter(dataListC00 =>
//  { return value.exhaustId != 0 && dataListC00.egsMTraderId2 == value.exhaustId && dataListC00.effectiveflg != '9' });
const chargedataList10 = dataListC00.filter(dataListC00 =>
  { return value.sect1TransportId != 0 && dataListC00.egsMTraderId2 == value.sect1TransportId });
const chargedataList11 = dataListC00.filter(dataListC00 =>
  { return value.destination1Id != 0 && dataListC00.egsMTraderId2 == value.destination1Id });
const chargedataList20 = dataListC00.filter(dataListC00 =>
  { return value.sect2TransportId != 0 && dataListC00.egsMTraderId2 == value.sect2TransportId });
const chargedataList21 = dataListC00.filter(dataListC00 =>
  { return value.destination2Id != 0 && dataListC00.egsMTraderId2 == value.destination2Id });
const chargedataList30 = dataListC00.filter(dataListC00 =>
  { return value.sect3TransportId != 0 && dataListC00.egsMTraderId2 == value.sect3TransportId });
const chargedataList31 = dataListC00.filter(dataListC00 =>
  { return value.destination3Id != 0 && dataListC00.egsMTraderId2 == value.destination3Id });
const chargedataList40 = dataListC00.filter(dataListC00 =>
  { return value.sect4TransportId != 0 && dataListC00.egsMTraderId2 == value.sect4TransportId });
const chargedataList41 = dataListC00.filter(dataListC00 =>
  { return value.destination4Id != 0 && dataListC00.egsMTraderId2 == value.destination4Id });
const chargedataList50 = dataListC00.filter(dataListC00 =>
  { return value.sect5TransportId != 0 && dataListC00.egsMTraderId2 == value.sect5TransportId });
const chargedataList51 = dataListC00.filter(dataListC00 =>
  { return value.destination5Id != 0 && dataListC00.egsMTraderId2 == value.destination5Id });
const chargedataList70 = dataListC00.filter(dataListC00 =>
  { return value.lastProcessingTraderId != 0 && dataListC00.egsMTraderId2 == value.lastProcessingTraderId });

// console.log('chargedataList00',chargedataList00);
// console.log('chargedataList10',chargedataList10);
// console.log('chargedataList11',chargedataList11);
// console.log('chargedataList20',chargedataList20);
// console.log('chargedataList21',chargedataList21);
// console.log('chargedataList30',chargedataList30);
// console.log('chargedataList31',chargedataList31);
// console.log('chargedataList40',chargedataList40);
// console.log('chargedataList41',chargedataList41);
// console.log('chargedataList50',chargedataList50);
// console.log('chargedataList51',chargedataList51);
// console.log('chargedataList70',chargedataList70);


return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpaceRow height={20} />

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                フロー名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveName}</Typography>
              <TextField id='reserveName' hiddenLabel variant="outlined" type='text' name='reserveName' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveName} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                契約日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveDate}</Typography>
              <TextField id='reserveDate' label='' hiddenLabel variant="outlined" type='date' name='reserveDate' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveDate} fullWidth />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ID：
              </Typography>
            </Box>
      </Grid>
{/*}          <Grid item xs> */}
          {/* <Grid item xs={1}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='id' label='id' hiddenLabel variant="outlined" type='text' name='id' halfWidth margin="dense" value={value.id} fullWidth />
            </Box>
          </Grid> */}
          <Grid item xs />
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                フロー番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveNo}<br/></Typography>
              <Typography variant="h7" style={{ fontSize: 20, fontWeight: 700 }}>
                {value.reserveNo}
        </Typography>
              {/* <TextField id='reserveNo' label="" hiddenLabel variant="outlined" type='text' name='reserveNo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveNo} fullWidth /> */}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                フローサブ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.reserveSubno}<br/></Typography>
              <TextField id='reserveSubno' label='' hiddenLabel variant="outlined" type='text' name='reserveSubno' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.reserveSubno} fullWidth />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業場
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.exhaustName}</Typography><br/>
{/*}            
              <Select
                id='exhaustId'
                variant="outlined"
                type='text'
                name='exhaustId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustId}
                fullWidth
              >
                {traderdataListT00.map((traderdataListT00) => (
                  <MenuItem
                    key={traderdataListT00.egsMTraderId2}
                    value={traderdataListT00.egsMTraderId2}
                  >
                    {traderdataListT00.traderName2}
                  </MenuItem>
                ))}
                </Select>
*/}
                <Typography variant="h7" style={{ fontWeight: 700 }}>{value.exhaustName}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              排出担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.exhaustChargeName}</Typography>
              <Select
                id='exhaustChargeId'
                label='排出担当者'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
                defaultValue={value.exhaustChargeId}
                fullWidth
//0829                error={errorExhaustChargeId}
              >
                {chargedataList00.map((chargedataList00) => (
                  <MenuItem
                    key={chargedataList00.chargeId}
                    value={chargedataList00.chargeId}
                  >
                    {chargedataList00.chargeCd}:{chargedataList00.chargeName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物種類
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.wasteKindName}</Typography>
            {/* <TextField id='wasteKindName' label="" hiddenLabel variant="outlined" type='text' name='wasteKindName' fullWidth margin="dense" value={value.wasteKindName} /> */}
            <Select
                id='wasteKindCd'
                label='廃棄物種類'
                variant="outlined"
                type='text'
                name='wasteKindCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteKindCd}
                fullWidth
//0829                error={errorWasteCd}
              >
{/*}
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
*/}
                {dataListWasteKind.map((dataListWasteKind) => (
                  <MenuItem
                    key={dataListWasteKind.wasteKindCd}  // wasteclassCd
                    value={dataListWasteKind.wasteKindCd}  //wasteclassCd
                  >
                    {dataListWasteKind.wasteKindCd}:{dataListWasteKind.wasteKindDispName}
                  </MenuItem>
                ))}

                </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.wasteName}</Typography>
            {/* <TextField id='wasteName' label="" hiddenLabel variant="outlined" type='text' name='wasteName' fullWidth margin="dense" value={value.wasteName} /> */}
              <Select
                id='wasteCd'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteCd}
                fullWidth
//0829                error={errorWasteCd}
              >
{/*}                
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
*/}
                {dataListWasteCd.map((dataListWasteCd) => (
                  <MenuItem
                    key={dataListWasteCd.wasteCd}
                    value={dataListWasteCd.wasteCd}
                  >
                    {dataListWasteCd.wasteCd}:{dataListWasteCd.wasteDispName}
                  </MenuItem>

                ))}

              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                単位
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.unitName}</Typography>
            {/* <TextField id='unitName' label="" hiddenLabel variant="outlined" type='text' name='unitName' fullWidth margin="dense" value={value.unitName} /> */}
            <Select
              id='unitCd'
              label='単位'
              variant="outlined"
              type='text'
              name='unitCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.unitCd}
              fullWidth
//0829                error={errorUnitCd}
            >
              {dataListUnit.map((dataListUnit) => (
                <MenuItem
                  key={dataListUnit.unitCd}
                  value={dataListUnit.unitCd}
                >
                  {dataListUnit.unitCd}:{dataListUnit.unitName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                荷姿
              </InputLabel>
            </Box>
          </Grid>
{/*}          <Grid item xs={1}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                荷姿
              </InputLabel>
            </Box>
                </Grid>  */}
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.packingName}</Typography>
            {/* <TextField id='packingName' label="" hiddenLabel variant="outlined" type='text' name='packingName' fullWidth margin="dense" value={value.packingName} /> */}
              <Select
                id='packingCd'
                label='荷姿'
                variant="outlined"
                type='text'
                name='packingCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.packingCd}
                fullWidth
//0829                error={errorPackingCd}
              >
                {dataListPacking.map((dataListPacking) => (
                  <MenuItem
                    key={dataListPacking.packingCd}
                    value={dataListPacking.packingCd}
                  >
                    {dataListPacking.packingCd}:{dataListPacking.packingName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                メモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' label='' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
          </Grid>

        </Grid>
        
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>
          <Box p={1} flex={1}>
            <Grid
              container
              spacing={1}
            >
              <Grid xs item>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd01)] }</Typography> */}
                <Typography variant="h7" style={{ fontWeight: 700 }}>{ dataList2.toxicName01 }</Typography>
                <Select fullWidth id='toxicCd01' label='有害物質１' variant="outlined" type='text' name='toxicCd01' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd01} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd02)] }</Typography> */}
                <Typography variant="h7" style={{ fontWeight: 700 }}>{ dataList2.toxicName02 }</Typography>
                <Select fullWidth id='toxicCd02' label='有害物質２' variant="outlined" type='text' name='toxicCd02' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd02} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName03}</Typography>
                <Select fullWidth id='toxicCd03' label='有害物質３' variant="outlined" type='text' name='toxicCd03' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd03} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
               </Select>
              </Grid>
              <Grid xs item>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName04}</Typography>
                <Select fullWidth id='toxicCd04' label='有害物質４' variant="outlined" type='text' name='toxicCd04' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd04} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName05}</Typography>
                <Select fullWidth id='toxicCd05' label='有害物質５' variant="outlined" type='text' name='toxicCd05' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd05} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.toxicName06}</Typography>
                <Select fullWidth id='toxicCd06' label='有害物質６' variant="outlined" type='text' name='toxicCd06' margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={value.toxicCd06} >
                  {dataListToxic.map((dataListToxic) => (<MenuItem key={dataListToxic.toxicSubstanceCd} value={dataListToxic.toxicSubstanceCd} >
                    {dataListToxic.toxicSubstanceCd}:{dataListToxic.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                最終処分の場所
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <RadioGroup
                aria-labelledby=""
                defaultValue={"1"}
                id="lastPlaceFlg"
                name="lastPlaceFlg"
                aria-orientation='horizontal'
                row
                onChange={e => handleChange(e)}
                value={value.lastPlaceFlg}
//0829                error={errorLastPlaceFlg}
              >
                <FormControlLabel value={"1"} control={<Radio />} label="委託契約書記載のとおり" />
                <FormControlLabel value={"2"} control={<Radio />} label="当欄記載のとおり" />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分方法
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.disposalMethodName}</Typography>
              <Select
                id='disposalMethodCd'
                label='処分方法'
                variant="outlined"
                type='text'
                name='disposalMethodCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.disposalMethodCd}
                fullWidth
//0829                error={errorDisposalMethodCd}
              >
                {dataListDisposal.map((dataListDisposal) => (
                  <MenuItem
                    key={dataListDisposal.disposalMethodCd}
                    value={dataListDisposal.disposalMethodCd}
                  >
                    {dataListDisposal.disposalMethodCd}:{dataListDisposal.disposalMethodName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{ borderBottomWidth: 2, borderColor: 'black' }}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                最終処分場
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.lastProcessingTraderName}</Typography>
              <Select
                id='lastProcessingTraderId'
                variant="outlined"
                type='text'
                name='lastProcessingTraderId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastProcessingTraderId}
                fullWidth
              >
                {traderdataListT70.map((traderdataListT70) => (
                  <MenuItem
                    key={traderdataListT70.egsMTraderId2}    // id
                    value={traderdataListT70.egsMTraderId2}  // id
                  >
                    {traderdataListT70.traderName2}
                  </MenuItem>
                ))}
                </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分場担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.lastProcessingChargeName}</Typography>
              <Select
                id='lastProcessingChargeId'
                label='最終処分事業場担当者'
                variant="outlined"
                type='text'
                name='lastProcessingChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastProcessingChargeId}
                fullWidth
//0829                error={errorLastProcessingChargeId}
              >
                {chargedataList70.map((chargedataList70) => (
                  <MenuItem
                    key={chargedataList70.chargeId}
                    value={chargedataList70.chargeId}
                  >
                    {chargedataList70.chargeCd}:{chargedataList70.chargeName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬情報
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬方法
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  車両番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬先事業場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderRightWidth: 0 }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  備考
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              区間１
            </InputLabel>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.sect1TransportName}
              </Typography>

{/*}              
              <Select
                id='sect1TransportId'
                variant="outlined"
                type='text'
                name='sect1TransportId'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                value={value.sect1TransportId}
                fullWidth
//                error={errorSect1TransportId}
              >
                {traderdataListT10.map((traderdataListT10) => (
                  <MenuItem
                    key={traderdataListT10.egsMTraderId2}
                    value={traderdataListT10.egsMTraderId2}
                  >
                    {traderdataListT10.traderName2}
                  </MenuItem>
                ))}
              </Select>
*/}

            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect1TransportChargeName}</Typography>
              <Select fullWidth id='sect1TransportChargeId' label='区間１運搬担当者' variant="outlined" type='text' name='sect1TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeId} >
                {chargedataList10.map((chargedataList10) => (<MenuItem key={chargedataList10.chargeId} value={chargedataList10.chargeId} > {chargedataList10.chargeCd}:{chargedataList10.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
                              {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{ toxicSubstanceName[Number(dataList2.toxicCd01)] }</Typography> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect1TransportMethodCd)]}</Typography>
              <Select fullWidth id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect1TransportMethodCd} >
                {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect1CarNo}</Typography>
              <TextField id='sect1CarNo' hiddenLabel variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.destination1Name}
              </Typography>
              <Select
                id='destination1Id'
                variant="outlined"
                type='text'
                name='destination1Id'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.destination1Id}
                fullWidth
//                error={errorDestination1Id}
              >
                {traderdataListT11.map((traderdataListT11) => (
                  <MenuItem
                    key={traderdataListT11.egsMTraderId2}
                    value={traderdataListT11.egsMTraderId2}
                  >
                    {traderdataListT11.traderName2}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination1ChargeName}</Typography>
              <Select fullWidth id='destination1ChargeId' label='区間１運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                {chargedataList11.map((chargedataList11) => (<MenuItem key={chargedataList11.chargeId} value={chargedataList11.chargeId} > {chargedataList11.chargeCd}:{chargedataList11.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo1}</Typography>
              <TextField id='memo1' hiddenLabel variant="outlined" type='text' name='memo1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間２
            </Typography>
            <Button
              size="small"
              type='submit'
              variant='outlined'
              color='primary'
              onClick={(e) => handleClick2(e)}
              style={{ marginRight: 10 }}
            >
              クリア
            </Button>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.sect2TransportName}
              </Typography>
              <Select
                id='sect2TransportId'
                variant="outlined"
                type='text'
                name='sect2TransportId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect2TransportId}
                fullWidth
//                error={errorSect1TransportId}
              >
                {traderdataListT10.map((traderdataListT10) => (
                  <MenuItem
                    key={traderdataListT10.egsMTraderId2}
                    value={traderdataListT10.egsMTraderId2}
                  >
                    {traderdataListT10.traderName2}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect2TransportChargeName}</Typography>
              <Select fullWidth id='sect2TransportChargeId' label='区間２運搬担当者' variant="outlined" type='text' name='sect2TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportChargeId} >
                {chargedataList20.map((chargedataList20) => (<MenuItem key={chargedataList20.chargeId} value={chargedataList20.chargeId} > {chargedataList20.chargeCd}:{chargedataList20.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect2TransportMethodCd)]}</Typography>
              <Select fullWidth id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect2TransportMethodCd} >
                {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect2CarNo}</Typography>
              <TextField id='sect2CarNo' hiddenLabel variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={2}>
              <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
                {value.destination2Name}
              </Typography>
              <Select
                id='destination2Id'
                variant="outlined"
                type='text'
                name='destination2Id'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.destination2Id}
                fullWidth
//                error={errorDestination1Id}
              >
                {traderdataListT11.map((traderdataListT11) => (
                  <MenuItem
                    key={traderdataListT11.egsMTraderId2}
                    value={traderdataListT11.egsMTraderId2}
                  >
                    {traderdataListT11.traderName2}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination2ChargeName}</Typography>
              <Select fullWidth id='destination2ChargeId' label='区間２運搬先担当者' variant="outlined" type='text' name='destination2ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeId} >
                {chargedataList21.map((chargedataList21) => (<MenuItem key={chargedataList21.chargeId} value={chargedataList21.chargeId} > {chargedataList21.chargeCd}:{chargedataList21.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo2}</Typography>
              <TextField fullWidth id='memo2' variant="outlined" type='text' name='memo2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間３
          </Typography>
          <Button
            size="small"
            type='submit'
            variant='outlined'
            color='primary'
            onClick={(e) => handleClick3(e)}
            style={{ marginRight: 10 }}
          >
            クリア
          </Button>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect3TransportName}
            </Typography>
            <Select
                id='sect3TransportId'
                variant="outlined"
                type='text'
                name='sect3TransportId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect3TransportId}
                fullWidth
//                error={errorSect1TransportId}
              >
                {traderdataListT10.map((traderdataListT10) => (
                  <MenuItem
                    key={traderdataListT10.egsMTraderId2}
                    value={traderdataListT10.egsMTraderId2}
                  >
                    {traderdataListT10.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect3TransportChargeName}</Typography>
            <Select fullWidth id='sect3TransportChargeId' label='区間３運搬担当者' variant="outlined" type='text' name='sect3TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportChargeId} >
              {chargedataList30.map((chargedataList30) => (<MenuItem key={chargedataList30.chargeId} value={chargedataList30.chargeId} > {chargedataList30.chargeCd}:{chargedataList30.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect3TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect3TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect3CarNo}</Typography>
            <TextField fullWidth id='sect3CarNo' hiddenLabel variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination3Name}
            </Typography>
            <Select
                id='destination3Id'
                variant="outlined"
                type='text'
                name='destination3Id'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.destination3Id}
                fullWidth
//                error={errorDestination1Id}
              >
                {traderdataListT11.map((traderdataListT11) => (
                  <MenuItem
                    key={traderdataListT11.egsMTraderId2}
                    value={traderdataListT11.egsMTraderId2}
                  >
                    {traderdataListT11.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination3ChargeName}</Typography>
            <Select fullWidth id='destination3ChargeId' label='区間３運搬先担当者' variant="outlined" type='text' name='destination3ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeId} >
              {chargedataList31.map((chargedataList31) => (<MenuItem key={chargedataList31.chargeId} value={chargedataList31.chargeId} > {chargedataList31.chargeCd}:{chargedataList31.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo3}</Typography>
            <TextField fullWidth id='memo3' variant="outlined" type='text' name='memo3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} />
          </Box>
        </Grid>
      </Grid>
    </Grid>

    <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間４
          </Typography>
          <Button
            size="small"
            type='submit'
            variant='outlined'
            color='primary'
            onClick={(e) => handleClick4(e)}
            style={{ marginRight: 10 }}
          >
            クリア
          </Button>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect4TransportName}
            </Typography>
            <Select
                id='sect4TransportId'
                variant="outlined"
                type='text'
                name='sect4TransportId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect4TransportId}
                fullWidth
//                error={errorSect1TransportId}
              >
                {traderdataListT10.map((traderdataListT10) => (
                  <MenuItem
                    key={traderdataListT10.egsMTraderId2}
                    value={traderdataListT10.egsMTraderId2}
                  >
                    {traderdataListT10.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect4TransportChargeName}</Typography>
            <Select fullWidth id='sect4TransportChargeId' label='区間４運搬担当者' variant="outlined" type='text' name='sect4TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportChargeId} >
              {chargedataList40.map((chargedataList40) => (<MenuItem key={chargedataList40.chargeId} value={chargedataList40.chargeId} > {chargedataList40.chargeCd}:{chargedataList40.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect4TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect4TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect4CarNo}</Typography>
            <TextField fullWidth id='sect4CarNo' hiddenLabel variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination4Name}
            </Typography>
            <Select
                id='destination4Id'
                variant="outlined"
                type='text'
                name='destination4Id'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.destination4Id}
                fullWidth
//                error={errorDestination1Id}
              >
                {traderdataListT11.map((traderdataListT11) => (
                  <MenuItem
                    key={traderdataListT11.egsMTraderId2}
                    value={traderdataListT11.egsMTraderId2}
                  >
                    {traderdataListT11.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination4ChargeName}</Typography>
            <Select fullWidth id='destination1ChargeId' label='区間４運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
              {chargedataList41.map((chargedataList41) => (<MenuItem key={chargedataList41.chargeId} value={chargedataList41.chargeId} > {chargedataList41.chargeCd}:{chargedataList41.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo4}</Typography>
            <TextField fullWidth id='memo4' hiddenLabel variant="outlined" type='text' name='memo4' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} />
          </Box>
        </Grid>
      </Grid>
    </Grid>

    <Grid
      container
      spacing={0}
      className={classes.row}
    >
      <Grid item xs={2}>
        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            区間５
          </Typography>
          <Button
            size="small"
            type='submit'
            variant='outlined'
            color='primary'
            onClick={(e) => handleClick5(e)}
            style={{ marginRight: 10 }}
          >
            クリア
          </Button>
        </Box>
      </Grid>
      <Grid container xs={10}>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.sect5TransportName}
            </Typography>
            <Select
                id='sect5TransportId'
                variant="outlined"
                type='text'
                name='sect5TransportId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect5TransportId}
                fullWidth
//                error={errorSect1TransportId}
              >
                {traderdataListT10.map((traderdataListT10) => (
                  <MenuItem
                    key={traderdataListT10.egsMTraderId2}
                    value={traderdataListT10.egsMTraderId2}
                  >
                    {traderdataListT10.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.sect5TransportChargeName}</Typography>
            <Select fullWidth id='sect5TransportChargeId' label='区間５運搬担当者' variant="outlined" type='text' name='sect5TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportChargeId} >
              {chargedataList50.map((chargedataList50) => (<MenuItem key={chargedataList50.chargeId} value={chargedataList50.chargeId} > {chargedataList50.chargeCd}:{chargedataList50.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{transportMethodName[Number(dataList2.sect5TransportMethodCd)]}</Typography>
            <Select fullWidth id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect5TransportMethodCd} >
              {dataListTransport.map((dataListTransport) => (<MenuItem key={dataListTransport.transportMethodCd} value={dataListTransport.transportMethodCd} > {dataListTransport.transportMethodCd}:{dataListTransport.transportMethodName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>前：{dataList2.sect5CarNo}</Typography>
            <TextField fullWidth id='sect5CarNo' hiddenLabel variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} />
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={2}>
            <Typography variant="h7"style={{ fontSize: 16, fontWeight: 700 }}>
              {value.destination5Name}
            </Typography>
            <Select
                id='destination5Id'
                variant="outlined"
                type='text'
                name='destination5Id'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.destination5Id}
                fullWidth
//                error={errorDestination1Id}
              >
                {traderdataListT11.map((traderdataListT11) => (
                  <MenuItem
                    key={traderdataListT11.egsMTraderId2}
                    value={traderdataListT11.egsMTraderId2}
                  >
                    {traderdataListT11.traderName2}
                  </MenuItem>
                ))}
              </Select>
          </Box>
        </Grid>
        <Grid item xs={2} className={classes.value_box}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.destination5ChargeName}</Typography>
            <Select fullWidth id='destination1ChargeId' label='区間５運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
              {chargedataList51.map((chargedataList51) => (<MenuItem key={chargedataList51.chargeId} value={chargedataList51.chargeId} > {chargedataList51.chargeCd}:{chargedataList51.chargeName} </MenuItem>))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.memo5}</Typography>
            <TextField fullWidth id='memo5' variant="outlined" type='text' name='memo5' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} />
          </Box>
        </Grid>
      </Grid>
    </Grid>

        <Grid
          container
          spacing={0}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                処分受託者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
{/*}            <Box bgcolor="primary.gray" color="primary.black" p={1} flex alignItems={"center"}>
              <Typography variant='h7'>
                特定非営利活動法人エコ·テクル [中間最終用]
              </Typography>
            </Box>
*/}
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.processTrustTraderName}</Typography>
{/*}              
              <Typography variant="h7" style={{ fontWeight: 700 }}>変更前：{dataList2.processTrustTraderName}</Typography>
              <Select
                id='processTrustTraderId'
                variant="outlined"
                type='text'
                name='processTrustTraderId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.processTrustTraderId}
//                error={errorLastProcessingTraderId}
                fullWidth
              >
                {traderdataListT80.map((traderdataListT80) => (
                  <MenuItem
                    key={traderdataListT80.egsMTraderId2}    // id
                    value={traderdataListT80.egsMTraderId2}  // id
                  >
                    {traderdataListT80.traderName2}
                  </MenuItem>
                ))}
                </Select>
*/}
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}
              style={{ borderLeft: "1px solid #CCC" }}
            >
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                承認済み
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Checkbox
                sx={{
                  color: errorApproveflg ? "red" : "gray",
                }}
                name='approvalflg'
                value={value.approvalflg === "1"}  onChange={e => {
                  handleChange({
                    target: {
                      name: 'approvalflg',
                      value: e.target.checked ? "1" : "0"
                    }
                  });
                }} />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
            handleSubmit(e);
            // if (checkValidation()) {
            //   handleSubmit(e);
            // }
          }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>
    </>
  );
};
export default EgsMProcessFlowAddForm;
