import React from "react";
import { View } from "@react-pdf/renderer";
import { Grid, Col } from "../../atoms/Grid";
import Text from "../../atoms/Text";

export default props => (
  <>
    <Col
      subTitle
      borderLeft
      borderRight
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Text>処分受託者</Text>
    </Col>
    <Col size={props.size || 2} borderRight>
      {props.children}
    </Col>
  </>
);
