// /src/lib/api/receipt_adm_transport.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptAdmTransportList = () => {
  return client.get('/receipt_adm_transports');
};

// 事業場選択一覧
export const getReceiptAdmTransportUserDetail = (id) => {
  return client.get(`/receipt_adm_transports/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptAdmTransportUser = (id) => {
  return client.get(`/receipt_adm_transports/${id}/showuser`);
};


