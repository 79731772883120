// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getLinkageUser } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';


import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FirstSelForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType,            dataList } = props;

  // console.log('dataList',dataList)
//  // console.log('selectFlg',dataList[0].selectFlg)     // 表示NG
//Uncaught TypeError: Cannot read properties of undefined (reading 'selectFlg')
//Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'selectFlg')



  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const [dataListT01, setdataListT01] = useState([]);
  const [dataListT07, setdataListT07] = useState([]);
  const [dataListC01, setdataListC01] = useState([]);
  const [dataListC02, setdataListC02] = useState([]);

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}


//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
    const dataListWaste = await getEgsMWasteclassList();
    setDataListWaste(dataListWaste.data);
//    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
//    // console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);



//      const resT1 = await getEgsMTraderList();
//      setdataListT01(resT1.data);
//      // console.log('resT1',resT1.data);
//0517      const dataListT01 = await getEgsMTraderList();
      const dataListT01 = await getLinkageUser(currentUser.traderCd);
      setdataListT01(dataListT01.data);
      // console.log('dataListT01',dataListT01.data);

//      const resT2 = await getTraderSelList();
//      setdataListT07(resT2.data);
//      const dataListT07 = await getTraderSelList();
//      setdataListT07(dataListT07.data);

//      const resC1 = await getEgsMChargeList();
//      // console.log('resC1',resC1.data);
//      setdataListC01(resC1.data);
      const dataListC01 = await getEgsMChargeList();
//      // console.log('dataListC01',dataListC01.data);
      setdataListC01(dataListC01.data);

//      const resC2 = await getChargeSelList();
//      // console.log('resC2',resC2.data);
//      setdataListC02(resC2.data);
      const dataListC02 = await getChargeSelList();
//      // console.log('dataListC02',dataListC02.data);
      setdataListC02(dataListC02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//// console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//// console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//// console.log('disposaldataList',disposaldataList);

const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
//// console.log('transport',value.sect1TransportMethodCd,value.sect2TransportMethodCd,value.sect3TransportMethodCd,value.sect4TransportMethodCd,value.sect5TransportMethodCd);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//// console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
//// console.log('packingdataList',packingdataList);




//  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.traderCd == value.exhaustCd });
//  const traderdataList00 = dataListT01.filter(resT1 => { return resT1.id == value.exhaustId });
//0517  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.exhaustId });
  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '1000000000' });
//  // console.log('traderdataList00',traderdataList00,'<-',value.exhaustId);
//  const traderdataList07 = dataListT07.filter(dataListT07 => { return dataListT07.traderKbn == '0000100000' });    // 最終処分
//  const traderdataList07 = dataListT07.filter(resT2 => { return resT2.traderKbn == '0000100000' });    // 最終処分
//  const traderdataList07 = dataListT07.filter(resT2 => { return resT2.id == value.lastProcessingTraderId });    // 最終処分
//0517  const traderdataList07 = dataListT07.filter(dataListT07 => { return dataListT07.id == value.lastProcessingTraderId });    // 最終処分
////////  const traderdataList07 = dataListT01.filter(dataListT01 => { return dataListT01.egsMTraderId1 == value.sect1TransportId &&
  const traderdataList07 = dataListT01.filter(dataListT01 => { return ( dataListT01.traderKbn2 == '0001000000'
                                                                     || dataListT01.traderKbn2 == '0000100000'
                                                                     || dataListT01.traderKbn2 == '1001000000'
                                                                     || dataListT01.traderKbn2 == '1000100000' ) });    // 最終処分
//// console.log('traderdataList07',traderdataList07,'<-',value.lastProcessingTraderId);



// 区間１～５
//const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect1TransportId });
const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' || dataListT01.traderKbn1 == '0100000000'});
//// console.log('traderdataList01',traderdataList01);
//// console.log('sect1TransportId',value.sect1TransportId,':',value.sect1TransportChargeId,':',chargedataList01)
const traderdataList02 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
//// console.log('traderdataList02',traderdataList02);
const traderdataList03 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
//// console.log('traderdataList03',traderdataList03);
const traderdataList04 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
//// console.log('traderdataList04',traderdataList04);
const traderdataList05 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
//// console.log('traderdataList05',traderdataList05);

const traderdataList11 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000'
                                                                 || dataListT01.traderKbn2 == '0001000000'
                                                                 || dataListT01.traderKbn2 == '0000100000'
                                                                 || dataListT01.traderKbn2 == '0000010000' });
//// console.log('traderdataList11',traderdataList11);
const traderdataList12 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
//// console.log('traderdataList12',traderdataList11);
const traderdataList13 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
//// console.log('traderdataList13',traderdataList11);
const traderdataList14 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
//// console.log('traderdataList14',traderdataList11);
const traderdataList15 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
//// console.log('traderdataList15',traderdataList11);


//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.id == value.exhaustId });
//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.egsMTraderid == value.exhaustId });
const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
//  // console.log('chargedataList00','->',chargedataList00,'<-',value.exhaustId);

//  const chargedataList07 = dataListC02.filter(resC2 => { return resC2.id == value.lastProcessingChargeId });
  const chargedataList07 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.lastProcessingTraderId });
//  // console.log('chargedataList07','->',chargedataList07,'<-',value.lastProcessingTraderId);

const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });
const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });
const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });
const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });
const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });


  return (
    <>
{/*}    ボタン不要…
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
  */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

{/*   EdiD01ItemForm.jsx の戻る機能
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
          props.handleCancel();
        }}>    //  egs_m_reserve_sets
          戻る
        </Button>
*/}
{/*}    ポップアップ画面の戻るには不適
      <Button variant='contained' onClick={() => history.push('/garbage_edi')}>
        戻る
      </Button>
*/}
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
          props.handleCancel();
        }}>
          戻る
        </Button>
      <SpaceRow height={20} />


      <form className={classes.root} noValidate autoComplete='off'>
        <table>
    <tr><th>1次マニフェスト番号</th><td><TextField id='selectTag' label='1次マニフェスト' variant="outlined" type='text' name='selectTag' fullWidth margin="dense" value={value.selectTag} onChange={(e) => handleChange(e)} /></td></tr>
{/*}    <tr><th>1次マニフェスト</th><td><TextField id='selectTag' label='1次マニフェスト' variant="outlined" type='text' name='selectTag' fullWidth margin="dense" value={currentUser.updatedAt} /></td></tr>  */}
        </table><table>
{/*}          <tr><th>レイアウト番号</th><td><TextField id='layoutNo' label='レイアウト番号' variant="outlined" type='text' name='layoutNo' disabled halfWidth margin="dense" value={value.layoutNo} onChange={(e) => handleChange(e)} /></td></tr>  */}
    <th>引渡し日</th><td><TextField id='exhaustDate' label='引渡し日' variant="outlined" type='date' name='exhaustDate' halfWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} /></td>
    <th>ゴミタグ番号</th><td><TextField id='garbageTag' label='ゴミタグ番号' variant="outlined" type='text' name='garbageTag' disabled halfWidth margin="dense" value={value.garbageTag} /></td>
    <th>マニフェスト番号</th><td><TextField id='manifestNo' label='マニフェスト番号' variant="outlined" type='text' name='manifestNo' halfWidth margin="dense" value={value.manifestNo} onChange={(e) => handleChange(e)} /></td>
<tr>
    <th>排出事業者</th>
    <td>
            <Select
              id='exhaustId'
              label='排出事業場'
              variant="outlined"
              type='text'
              name='exhaustId'
              margin="dense"
//              onChange={(e) => handleChange(e)}    表示のみか、選択出来ないようにしたいが…
              value={value.exhaustId}
            >
            {traderdataList00.map((traderdataList00) => (
              <MenuItem
                key={traderdataList00.egsMTraderId2}
                value={traderdataList00.egsMTraderId2}
              >
                {traderdataList00.traderCd2}:{traderdataList00.traderName2}
              </MenuItem>
            ))}
            </Select>
            </td>
    <th>排出担当者</th>
    <td>
            <Select
              id='exhaustChargeId'
              label='排出担当者'
              variant="outlined"
              type='text'
              name='exhaustChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.exhaustChargeId}
            >
            {chargedataList00.map((chargedataList00) => (
              <MenuItem
                key={chargedataList00.id}
                value={chargedataList00.id}
              >
                {chargedataList00.chargeCd}:{chargedataList00.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
          </tr>
       </table>
      </form>
    </>
  );
};
export default FirstSelForm;
