import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

import { toxicSubstanceName } from '../../constants'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function AdmEdiInfoListTable(props) {
  const classes = useStyles();
  const { dataList } = props;
  const [dataList2, setDataList2] = useState({});

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2",dataList2);
  };

  const cols = [
    {
        field: 'col_8',
        headerName: 'マニフェスト番号',
        width: 150
      },
      {
        field: 'col_13',
        headerName: '排出事業場名',
        width: 300
      },
      {
        field: 'col_15',
        headerName: '区間番号',
        width: 100
      },
    {
      field: 'col_4',
      headerName: '通知コード',
      width: 100
    },
    {
      field: 'contentName',
      headerName: '通知内容',
      width: 300
    },
    {
      field: 'statusName',
      headerName: '通知情報ステータス',
      width: 150
    },
    {
      field: 'col_6',
      headerName: '通知日',
      width: 100
    },
    {
      field: 'col_7',
      headerName: '通知時間',
      width: 100
    },
    {
        field: 'col_16',
        headerName: '備考',
        width: 300
    }
  ]

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <>
      <div className={classes.container}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={dataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
    </>

  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default AdmEdiInfoListTable;

