// /src/lib/api/receipt_unload_waste.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptUnloadWasteList = () => {
  return client.get('/receipt_unload_wastes');
};

// 事業場選択一覧
export const getReceiptUnloadWasteUserDetail = (id) => {
  return client.get(`/receipt_unload_wastes/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptUnloadWasteUser = (id) => {
  return client.get(`/receipt_unload_wastes/${id}/showuser`);
};

// 詳細
export const getReceiptUnloadWasteDetail = (id) => {
  return client.get(`/receipt_unload_wastes/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptUnloadWaste = (params) => {
  return client.post('/receipt_unload_wastes', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptUnloadWaste = (id, params) => {
  return client.patch(`/receipt_unload_wastes/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptUnloadWaste = (id) => {
  return client.delete(`/receipt_unload_wastes/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
