// /src/lib/api/demand_b_cnt1001_edi.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getDemandBCnt1001EdiList = () => {
  return client.get('/demand_b_cnt1001_edis');
};

// 詳細
export const getDemandBCnt1001EdiDetail = (id) => {
  return client.get(`/demand_b_cnt1001_edis/${id}`);
};

// 月指定
export const getDemandBCnt1001EdiMonth = (id) => {
  return client.get(`/demand_b_cnt1001_edis/${id}/showmonth`);
};

