// /src/lib/api/egs_t_garbage_tag.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsTGarbageTagList = () => {
  return client.get('/egs_t_garbage_tags');
};

// 事業所指定一覧 idは事業所CD
export const getEgsTGarbageTagUser = (id) => {
  return client.get(`/egs_t_garbage_tags/${id}/showuser`);
};

// 詳細
export const getEgsTGarbageTagDetail = (id) => {
  return client.get(`/egs_t_garbage_tags/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsTGarbageTag = (params) => {
  return client.post('/egs_t_garbage_tags', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTGarbageTag = (id, params) => {
  return client.patch(`/egs_t_garbage_tags/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTGarbageTag = (id) => {
  return client.delete(`/egs_t_garbage_tags/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
