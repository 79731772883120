import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { Link , useParams} from 'react-router-dom';
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import SpaceRow from '../commons/SpaceRow';
import SagyoReportPdf from "./SagyoReportPdf"
import './app.css';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'
import { getChargeOperation  } from '../../lib/api/charge';
import { getSagyoReportChargeId } from '../../lib/api/sagyo_report';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '担当者マスタ',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "1000px",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
}));

function SagyoReportListTable(props) {
  const classes = useStyles();

  const [open2, setOpen2] = React.useState(false);

  const { dataList, handleDelete, currentUser } = props;
  console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);
  const [clickedRowId, setClickedRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const resdataList = dataList.filter(dataList => {
  //   return dataList.traderCd === currentUser.traderCd
  // })
  const [pdfOpen, setPdfOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
      sagyoDateStart: '',
      sagyoDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
//    traderName: '',
//    statusName: '',
//    traderCd: '',
//    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  // var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const handlePdfOpen = () => {
    setPdfOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };

  const handleRowClick = async (param, event) => {
    setClickedRowId(param.id);
    console.log("param.id",param.id);
    if (filter.sagyoDateStart == ''){
      filter.sagyoDateStart = dayjs().format('YYYY-MM-DD');
    }
    if (filter.sagyoDateEnd == ''){
      filter.sagyoDateEnd = dayjs().format('YYYY-MM-DD');
    }
    const param_item = param.id + "_"+filter.sagyoDateStart+"_"+filter.sagyoDateEnd;
    console.log("param_item",param_item);
    try {
        const res = await getSagyoReportChargeId(param_item);
        setDataList2(res.data);
        setSelectedRowData(param.row);
        console.log('dataList2',dataList2)
    } catch (e) {
        console.log(e);
    }
  };
  // 行の削除
  const delRows = () => {
//    console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'egsMTraderId',
      headerName: '事業場ID',
      width: 100
    },
    {
        field: 'traderCd',
        headerName: '事業場CD',
        width: 150
    },
    {
        field: 'traderName',
        headerName: '事業場名',
        width: 300
    },
    {
      field: 'chargeCd',
      headerName: '担当者CD',
      width: 150
    },
    {
      field: 'chargeName',
      headerName: '担当者名',
      width: 200
    },
    {
        field: 'chargeKana',
        headerName: '担当者名かな',
        width: 200
    },
    {
      field: 'email',
      headerName: 'メールアドレス',
      width:250
    },
    {
      field: 'effectiveName',
      headerName: '有効フラグ',
      width: 100,
//      type: 'boolean',
    },
  ]
  const colrep = [
    {
      field: 'id',
      headerName: 'ID',
      hide:true,
      width: 100
    },
    {
      field: 'sagyoDate',
      headerName: '作業日',
      width: 100
    },
    {
      field: 'sagyoTime',
      headerName: '作業時刻',
      width: 100,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' });
      }
    },
    {
      field: 'sagyoTask',
      headerName: '作業内容',
      width: 200
    },
    {
      field: 'sagyoPlace',
      headerName: '作業場所',
      width: 300
    },
    {
      field: 'sagyoMemo',
      headerName: 'メモ',
      width: 300
    },
  ]
  console.log('dataList', dataList);

  return (
    <>
    <BrowserView>
      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>作業期間：</Typography>
          </Box>
          <TextField
            id="sagyoDate-start" value={filter.sagyoDateStart || new Date().toISOString().split('T')[0]} onChange={e => setFilter({ ...filter, sagyoDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="sagyoDate-end" value={filter.sagyoDateEnd || new Date().toISOString().split('T')[0]} onChange={e => setFilter({ ...filter, sagyoDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true }}
          />
          <div style={{ marginLeft: 16, marginRight: 16, fontWeight: 'bold', fontSize: '1.0rem' }}>　　← 作業期間を設定後、↓ 作業報告を表示する担当者を選択してください</div>
        </Grid>
      </Grid>
      <div className={classes.container}>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
      <SpaceRow height={20} />
      <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
      {/* <div className={classes.container2}>
          {selectedRowData && (
              <div>
                  <Typography variant="h6">選択したレコード情報:</Typography>
                  <Typography>ID: {selectedRowData.id}</Typography>
                  <Typography>担当者名: {selectedRowData.chargeName}　　　作業期間：{filter.sagyoDateStart} ～ {filter.sagyoDateEnd}</Typography>

              </div>
          )}
      </div> */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ height: 520, width: '70%' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
            担当者名: {selectedRowData ? selectedRowData.chargeName : 'N/A'}　　　作業期間：{filter.sagyoDateStart} ～ {filter.sagyoDateEnd}
          </Typography>
          <SpaceRow height={10} />          
            <DataGridPro
                sx={styles.grid}
                columns={colrep}
                rows={dataList2}  // dataList
                density="compact"
                components={{
                    Toolbar: CustomToolbar,　// カスタムツールバーを指定する
                }}
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                disableSelectionOnClick // クリックでの選択を無効にする
            />
          </Box>
        </Box>
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <SagyoReportPdf
            chargeName={selectedRowData ? selectedRowData.chargeName : 'N/A'}
            sagyouStart={filter.sagyoDateStart}
            sagyouEnd={filter.sagyoDateEnd}
            isOpen={pdfOpen}
            doClose={() => setPdfOpen(false)}
            dataList2={dataList2}
          />
          <Button
            variant="outlined"
            color='primary'
            style={{
              marginRight: 10,
              padding: '10px 20px', // パディングを増やしてボタンを大きくする
              fontSize: '1.1rem' // フォントサイズを大きくする
            }}
            onClick={handlePdfOpen}
            startIcon={<PictureAsPdfIcon />} // アイコンをボタンに追加
            disabled={!selectedRowData} // selectedRowDataがnullの場合、ボタンを非活性にする
          >
            担当者作業報告PDF
          </Button>
        </Box>
      </BrowserView>
    </>
  );
}

const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default SagyoReportListTable;
