import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import '@grapecity/wijmo.styles/wijmo.css';
import './app.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
});

function LinkageSetSelTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;

  // console.log('currentUser',currentUser);

  const resdataList = dataList.filter(dataList => {
    return dataList.traderKbn2 === '1000000000'
//    return dataList.traderCd1 === currentUser.traderCd ||
//           dataList.traderCd2 === currentUser.traderCd
//     ||
//    dataList.sect1TransportCd         === currentUser.traderCd ||
//    dataList.sect2TransportCd         === currentUser.traderCd ||
//    dataList.sect3TransportCd         === currentUser.traderCd ||
//    dataList.sect4TransportCd         === currentUser.traderCd ||
//    dataList.sect5TransportCd         === currentUser.traderCd ||
//    dataList.destination1Cd           === currentUser.traderCd ||
//    dataList.destination2Cd           === currentUser.traderCd ||
//    dataList.destination3Cd           === currentUser.traderCd ||
//    dataList.destination4Cd           === currentUser.traderCd ||
//    dataList.destination5Cd           === currentUser.traderCd ||
//    dataList.middleProcessingTraderCd === currentUser.traderCd ||
//    dataList.lastProcessingTraderCd   === currentUser.traderCd
})

  return (
    <>

      <FlexGrid  autoSearch={true} isReadOnly={true} selectionMode="ListBox" autoGenerateColumns={false} itemsSource={resdataList}>
        <FlexGridFilter />
        <FlexGridColumn binding="id" header="id" width={80}></FlexGridColumn>
        <FlexGridColumn binding="traderCd1" header="事業場CD１" width={120}></FlexGridColumn>
        <FlexGridColumn binding="traderName1" header="事業場名１" width={200}></FlexGridColumn>
        <FlexGridColumn binding="jwnetNo1" header="加入者番号１" width={120}></FlexGridColumn>
        <FlexGridColumn binding="traderKbn1" header="事業場区分１" width={120}></FlexGridColumn>
        <FlexGridColumn binding="traderCd2" header="事業場CD２" width={120}></FlexGridColumn>
        <FlexGridColumn binding="traderName2" header="事業場名２" width={200}></FlexGridColumn>
        <FlexGridColumn binding="jwnetNo2" header="加入者番号２" width={120}></FlexGridColumn>
        <FlexGridColumn binding="traderKbn2" header="事業場区分２" width={120}></FlexGridColumn>
        <FlexGridColumn header="選択" binding="id" width={60} cellTemplate={CellMaker.makeLink({
          text: '<b>選択</b>',
          href: '/process_flowAdd/${item.id}',   // reserve_setAdd
          attributes: {
            rel: 'noopener noreferrer',
            tabIndex: -1
          }
        })} />
      </FlexGrid>
    </>

  );
}
export default LinkageSetSelTable;
