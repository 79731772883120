// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
} from '@material-ui/core';
// import ReactToPdf from "react-to-pdf";

import SpaceRow from '../commons/SpaceRow';

import './app.css';
import { AuthContext } from '../../App';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    // width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EdiD01ItemCancelForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const pdfRef = React.createRef();


//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };



const [errorPackingAmount1, setErrorPackingAmount1] = useState(false);  // 荷姿数量
const [errorSectNo, setErrorSectNo] = useState(false);  // 区間番号


const checkValidation = () => {
  let available = true;
/*
  if (!value.packingAmount1 ||
      !value.packingAmount1
  ) {
    // console.log(
      !value.packingAmount1,
    );
    available = false;
    setErrorPackingAmount1(!value.packingAmount1);
  }
*/

    if (value.sectno == '0' || value.sectNo == '6' || value.sectNo == '7') {
      setErrorSectNo(false);
    }else{
      if ( (value.sectNo > '1' && value.sect2TransportId == 0) ||
           (value.sectNo > '2' && value.sect3TransportId == 0) ||
           (value.sectNo > '3' && value.sect4TransportId == 0) ||
           (value.sectNo > '4' && value.sect5TransportId == 0) )
         {
          alert('区間番号に誤りがあります');
          available = false;
          setErrorSectNo(true);
      }else{
          setErrorSectNo(false);
      }
    }

  return available;
}


  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>


      <div style={{ textAlign: 'right' }}>

        <FormControl sx={{ minWidth: 100 }}>
  {/*}    <Typography variant="h7" style={{ fontWeight: 700 }}>取消対象区間番号：</Typography>  */}
          <Typography variant="h6" style={{ fontWeight: 700 }}>取消対象：</Typography>
        </FormControl>
        <FormControl sx={{ minWidth: 250 }}>
          <Select
            id='sectNo'
            label='状態'
            variant="outlined"
            type='text'
            name='sectNo'
            margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorSectNo}
            value={value.sectNo}
          >
            <MenuItem value={value.sectNo}>
              <em>区間番号</em>
            </MenuItem>
            <MenuItem value={'0'}>0:マニフェスト取消</MenuItem>
            <MenuItem value={'1'}>1:区間１取消</MenuItem>
            <MenuItem value={'2'}>2:区間２取消</MenuItem>
            <MenuItem value={'3'}>3:区間３取消</MenuItem>
            <MenuItem value={'4'}>4:区間４取消</MenuItem>
            <MenuItem value={'5'}>5:区間５取消</MenuItem>
            <MenuItem value={'6'}>6:中間処分取消</MenuItem>
            <MenuItem value={'7'}>7:最終処分取消</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h7" style={{ fontWeight: 700 }}>　　　　</Typography>
        <FormControl sx={{ minWidth: 30 }}>
          <Button variant='contained' onClick={e => {
              e.preventDefault();
              // history.push('/garbage_edi');
              props.handleCancel();
            }}>    {/*  egs_m_reserve_sets  */}
              戻る
          </Button>
        </FormControl>

      </div>

      <SpaceRow height={20} />
{/*}
      <div className={classes.container} ref={pdfRef}>


      <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              最新状態
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}> {value.statusName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                排出日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustDate} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustChargeName} </Typography>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel variant="h7" style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物種類
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}           justifyContent={"flex-start"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.wasteKindName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                廃棄物の数量
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.amount1} </Typography>
                　　
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel variant="h7" style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}       justifyContent={"flex-start"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.wasteclassName} </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                荷姿の数量（整数）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingAmount1} </Typography>
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
              </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                処分方法
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.disposalMethodName} </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分の場所
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.disposalMethodName} </Typography>
            </Box>
          </Grid>

          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={10}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography
             variant="h7"
             style={{ fontWeight: 700 }}>
              {value.toxicName01}　{value.toxicName02}　{value.toxicName03}　
              {value.toxicName04}　{value.toxicName05}　{value.toxicName06}
            </Typography>
          </Box>
        </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分業者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingTraderName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分担当者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingChargeName} </Typography>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分開始
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingStart} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分終了
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingEnd} </Typography>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

      </div>
*/}
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!value.sectNo}
          onClick={(e) => {
//            handleSubmit(e);
            if (checkValidation()) {
              handleSubmit(e);
            }
         }}
//          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>



    </>
  );
};
export default EdiD01ItemCancelForm;
