import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';
import { statusName } from '../../constants'
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function GarbageCsvListTableForm(props) {
  const classes = useStyles();
  const { dataList2 } = props;

  return (
    <>
      <Box component='div' sx={{ p: 0, textAlign: 'right' }}></Box>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
          <FormControl sx={{ minWidth: 100 }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最新状態・JWNET登録状況
            </Typography>
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.statusName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.ediSendName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <FormControl sx={{ minWidth: 100 }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出日
            </Typography>
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustDate}</Typography>
          </Box>
        </Grid>

        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              マニフェスト番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.manifestNo}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              ゴミタグ番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.garbageTag}</Typography>
          </Box>
        </Grid>
        <Grid item xs />
        </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出事業者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustName}<br />〒{dataList2.exhaustAddress}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
          <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustChargeName}<br />TEL:{dataList2.exhaustPhone}<br /></Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
              廃棄物種類
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteKindName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              廃棄物の数量
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.amount1}  ({dataList2.unitName})</Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >

        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
              廃棄物名称
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteName}</Typography>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              荷姿の数量
            </Typography>
          </Box>
        </Grid>

        <Grid item xs>
          <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.packingAmount1}  ({dataList2.packingName})</Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              処分方法
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.disposalMethodName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              有害物質
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography
             variant="h7"
             style={{ fontWeight: 700 }}>
              {dataList2.toxicName01}　{dataList2.toxicName02}　{dataList2.toxicName03}　
              {dataList2.toxicName04}　{dataList2.toxicName05}　{dataList2.toxicName06}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>


      <Grid
        container
        spacing={0}
        className={classes.row}
      >

        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              処分受託者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.companyName}<br />〒{dataList2.companyZip} {dataList2.companyAddress}</Typography>
          </Box>
        </Grid>
{/*}
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              中間処分担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>中間処分担当者名</Typography>
          </Box>
        </Grid>
*/}
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              備考
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo1}　　　{dataList2.memo2}　　　{dataList2.memo3}　　　{dataList2.memo4}　　　{dataList2.memo5}</Typography>
          </Box>
        </Grid>


        <Grid item xs />
        </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分の場所
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastReportName}</Typography>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              処理フローメモ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo}</Typography>
          </Box>
        </Grid>

        <Grid item xs />
      </Grid>


      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分業者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingTraderName}<br />〒{dataList2.lastProcessingTraderAddress}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingChargeName}<br />TEL:{dataList2.lastProcessingTraderPhone}</Typography>
          </Box>
        </Grid>
        <Grid item xs />
        </Grid>
{/*}
      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分開始
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingStart}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分終了
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingEnd}</Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
*/}
      <Grid
        container
        spacing={0}
        className={classes.row}
        style={{
          borderTopWidth: 2,
          borderTopColor: "black"
        }}
      >
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間１）<br />運搬受託者<br />運搬担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.sect1TransportName}<br />
              〒{dataList2.sect1TransportAddress}<br />
              {dataList2.sect1TransportChargeName}　　TEL:{dataList2.sect1TransportPhone}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間１）<br />運搬方法<br />車両番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.sect1TransportMethodName}<br />
              {dataList2.sect1CarNo}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            （区間１）<br />運搬先事業者<br />担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.destination1Name}<br />
              〒{dataList2.destination1Address}<br />
              {dataList2.destination1ChargeName}　　TEL:{dataList2.destination1Phone}
          </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間１）<br />備考<br />　　
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.memo1}
          </Typography>
          </Box>
        </Grid> */}
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間２）<br />運搬受託者<br />運搬担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.sect2TransportName}<br />
              〒{dataList2.sect2TransportAddress}<br />
              {dataList2.sect2TransportChargeName}　　TEL:{dataList2.sect2TransportPhone}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間２）<br />運搬方法<br />車両番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.sect2TransportMethodName}<br />
              {dataList2.sect2CarNo}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            （区間２）<br />運搬先事業者<br />担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.destination2Name}<br />
              〒{dataList2.destination2Address}<br />
              {dataList2.destination2ChargeName}　　TEL:{dataList2.destination2Phone}
          </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間２）<br />備考<br />　　
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.memo2}
          </Typography>
          </Box>
        </Grid> */}
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間３）<br />運搬受託者<br />運搬担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.sect3TransportName}<br />
              〒{dataList2.sect3TransportAddress}<br />
              {dataList2.sect3TransportChargeName}　　TEL:{dataList2.sect3TransportPhone}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間３）<br />運搬方法<br />車両番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.sect3TransportMethodName}<br />
              {dataList2.sect3CarNo}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            （区間３）<br />運搬先事業者<br />担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.destination3Name}<br />
              〒{dataList2.destination3Address}<br />
              {dataList2.destination3ChargeName}　　TEL:{dataList2.destination3Phone}
          </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間３）<br />備考<br />　　
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.memo3}
          </Typography>
          </Box>
        </Grid> */}
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間４）<br />運搬受託者<br />運搬担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.sect4TransportName}<br />
              〒{dataList2.sect4TransportAddress}<br />
              {dataList2.sect4TransportChargeName}　　TEL:{dataList2.sect4TransportPhone}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間４）<br />運搬方法<br />車両番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.sect4TransportMethodName}<br />
              {dataList2.sect4CarNo}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            （区間４）<br />運搬先事業者<br />担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.destination4Name}<br />
              〒{dataList2.destination4Address}<br />
              {dataList2.destination4ChargeName}　　TEL:{dataList2.destination4Phone}
          </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間４）<br />備考<br />　　
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.memo4}
          </Typography>
          </Box>
        </Grid> */}
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
          <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間５）<br />運搬受託者<br />運搬担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.sect5TransportName}<br />
              〒{dataList2.sect5TransportAddress}<br />
              {dataList2.sect5TransportChargeName}　　TEL:{dataList2.sect5TransportPhone}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間５）<br />運搬方法<br />車両番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.sect5TransportMethodName}<br />
              {dataList2.sect5CarNo}
          </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            （区間５）<br />運搬先事業者<br />担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              {dataList2.destination5Name}<br />
              〒{dataList2.destination5Address}<br />
              {dataList2.destination5ChargeName}　　TEL:{dataList2.destination5Phone}
          </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={1}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              （区間５）<br />備考<br />　　
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Typography
            variant="h7"
            style={{ fontWeight: 700 }}>
              <br />
              {dataList2.memo5}
          </Typography>
          </Box>
        </Grid> */}
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
        style={{
          borderTopWidth: 2,
          borderTopColor: "black"
        }}
      >
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>運搬開始日<br />運搬終了日</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              　区間１：{dataList2.sect1At}<br />
              　　　　：{dataList2.destination1At}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              　区間２：{dataList2.sect2At}<br />
              　　　　：{dataList2.destination2At}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間３：{dataList2.sect3At}<br />
              　　　：{dataList2.destination3At}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間４：{dataList2.sect4At}<br />　　　：
              {dataList2.destination4At}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間５：{dataList2.sect5At}<br />
              　　　：{dataList2.destination5At}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
{/*}            <Typography variant="h7" style={{ fontWeight: 700 }}>処分開始日<br />処分終了日</Typography>  */}
            <Typography variant="h7" style={{ fontWeight: 700 }}>処分終了日</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
{/*}            中間処分：{dataList2.middleStart}<br />
            　　　　：{dataList2.middleEnd}  */}
            中間処分：{dataList2.middleEnd}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
{/*}            最終処分：{dataList2.lastProcessingStart}<br />
            　　　　：{dataList2.lastProcessingEnd}  */}
            最終処分：{dataList2.lastProcessingEnd}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      </div>

    </>
  )
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default GarbageCsvListTableForm;
