// /src/lib/api/wk_exhaust_period.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getWkExhaustPeriodList = () => {
  return client.get('/wk_exhaust_periods');
};

// 事業所指定一覧 idは事業所CD
export const getWkExhaustPeriodUser = (id) => {
  return client.get(`/wk_exhaust_periods/${id}/showuser`);
};

// 詳細
export const getWkExhaustPeriodDetail = (id) => {
  return client.get(`/wk_exhaust_periods/${id}`);
};

// 新規作成
// header情報を追加
export const createWkExhaustPeriod = (params) => {
  return client.post('/wk_exhaust_periods', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateWkExhaustPeriod = (id, params) => {
  return client.patch(`/wk_exhaust_periods/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteWkExhaustPeriod = (id) => {
  return client.delete(`/wk_exhaust_periods/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
