// /src/lib/api/approve_wait.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getApproveWaitList = () => {
  return client.get('/approve_waits');
};

// 詳細
export const getApproveWaitDetail = (id) => {
  return client.get(`/approve_waits/${id}`);
};

// 新規作成
// header情報を追加
export const createApproveWait = (params) => {
  return client.post('/approve_waits', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateApproveWait = (id, params) => {
  return client.patch(`/approve_waits/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteApproveWait = (id) => {
  return client.delete(`/approve_waits/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
