// Form.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, Select, Checkbox, Typography } from '@material-ui/core';
import './app.css';

//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
//import { getTraderAdmGroup1, getTraderAdmGroup2 } from '../../lib/api/trader_adm';
import { getTraderAdmGroup } from '../../lib/api/trader_adm';
//import { getCurrentUser } from '../../lib/api/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
}));

const EgsMChargeAddForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, dataList2, handleSubmit, value, buttonType } = props;

  const [open, setOpen] = React.useState(false);
  const [traderList, setTraderList] = useState([]);
//  const [t02List, setT02List] = useState([]);

  const [errorTraderCd, setErrorTraderCd] = useState(false);  // 事業場CD
  const [errorChargeCd, setErrorChargeCd] = useState(false);  // 担当者CD
  const [errorChargeName, setErrorChargeName] = useState(false);  // 担当者名
  const [errorChargeKana, setErrorChargeKana] = useState(false);  // ふりがな
  const [errorEmail, setErrorEmail] = useState(false);  // メールアドレス
  const [errorEmailFlg, setErrorEmailFlg] = useState(false);  // メールアドレス有効フラグ
  const [errorEffectiveflg, setErrorEffectiveflg] = useState(false);  // 有効フラグ
  const [errorEdiKbn, setErrorEdiKbn] = useState(false);  // EDI担当者区分

  return (
    <>
{/*}      <SpaceRow height={20} />  */}
      <form className={classes.root} noValidate autoComplete='off'>
       <table>

       <tr><th>事業場CD *</th>
         <td>
            <Typography variant="h6" style={{ marginTop: 10 }}>{dataList2[0].traderCd}</Typography>
{/*}            <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorTraderCd}
              value={value.traderCd}
              inputProps={{ maxLength: 15, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
  />  */}
          </td>
        </tr>
        <tr><th>事業場名称</th><td>
{/*}            <Typography variant="h7" style={{ marginTop: 10 }}>{currentUser.name}</Typography>  */}
            <Typography variant="h6" style={{ marginTop: 10 }}>{dataList2[0].traderName}</Typography>
{/*}
            <Select
                id='traderCd'  // traderNameではNGだった
                label='事業場名称'
                variant="standard"  // outlined
                type='text'
                name='traderCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.traderName}
                </MenuItem>
              ))}
            </Select>
*/}
          </td></tr>

{/*}
        <tr><th>事業場ID</th><td><TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='number' name='egsMTraderId' fullWidth margin="dense" value={value.egsMTraderId} /></td></tr>
        <tr><th>事業場CD</th><td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd} /></td></tr>
*/}
        <tr><th>担当者CD *</th><td>
          <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeCd}
            value={value.chargeCd}
            inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
{/*}          <Typography variant="h7" style={{ marginTop: 10 }}>{value.chargeCd}</Typography>  */}
{/*}
          <Typography variant="h7" style={{ marginTop: 10, marginLeft: 30 }}>最大担当者CD：</Typography>
            <Select
                id='traderCd'  // chargeCdではNGだった
                label='新・担当者CD'
                variant="standard"  // outlined
                type='text'  //
                name='chartraderCdgeCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
                style={{ marginTop: 10, marginLeft: 10 }}
                >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.chargeCdMax}
                </MenuItem>
              ))}
            </Select>
*/}
          </td>
        </tr>
        <tr><th>担当者名 *</th><td><TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeName}
          value={value.chargeName}
        /></td></tr>
        <tr><th>ふりがな *</th><td><TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeKana}
          value={value.chargeKana}
        /></td></tr>
        <tr><th>メールアドレス</th><td><TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorEmail}
          value={value.email}
          inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
        />
{/*}            <TextField id='emailFlg' label='有効フラグ' variant="outlined" type='text' name='emailFlg' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.emailFlg} />  */}
{/*}        <tr><th>有効フラグ</th><td>  */}
{/*}
          <Checkbox
          id='emailFlg'
          label='有効フラグ'
          type='checkbox'
          defaultChecked
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={(e) => handleChange(e)}
          value={value.emailFlg}
        />
        有効フラグ
*/}
        </td></tr>
       </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
                handleSubmit(e);
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>
    </>
  );
}

export default EgsMChargeAddForm;
