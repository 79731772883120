import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { Link , useParams} from 'react-router-dom';
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SpaceRow from '../commons/SpaceRow';
import EgsMChargeEdit from "./EgsMChargeEdit";
import EgsMChargeCreat from "./EgsMChargeCreat";
import EgsMChargeListTableForm from "./EgsMChargeListTableForm"
import './app.css';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'
import { getChargeOperation  } from '../../lib/api/charge';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '担当者マスタ',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "1000px",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
}));

function EgsMChargeListTable(props) {
  const classes = useStyles();

  const [open2, setOpen2] = React.useState(false);

  const { dataList, handleDelete, currentUser } = props;
  console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);

  // const resdataList = dataList.filter(dataList => {
  //   return dataList.traderCd === currentUser.traderCd
  // })
  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
//    exhaustDateStart: '',
//    exhaustDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
    traderName: '',
//    statusName: '',
    traderCd: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
/*    
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
*/
//    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.traderCd+"_"+filter.traderName+"_"+filter.sect1TransportName;
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.traderCd+"_"+filter.traderName+"_";
//    console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getChargeOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };

  const handleRowClick = (param, event) => {
//    console.log(param.row);
    setDataList2(param.row);
//    console.log('dataList2',dataList2);
  };

  // 行の削除
  const delRows = () => {
//    console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'egsMTraderId',
      headerName: '事業場ID',
      width: 100
    },
    {
        field: 'traderCd',
        headerName: '事業場CD',
        width: 150
    },
    {
        field: 'traderName',
        headerName: '事業場名',
        width: 300
    },
    {
      field: 'chargeCd',
      headerName: '担当者CD',
      width: 150
    },
    {
      field: 'chargeName',
      headerName: '担当者名',
      width: 200
    },
    {
        field: 'chargeKana',
        headerName: '担当者名かな',
        width: 200
    },
    {
      field: 'email',
      headerName: 'メールアドレス',
      width:250
    },
    {
      field: 'effectiveName',
      headerName: '有効フラグ',
      width: 100,
//      type: 'boolean',
    },
  ]
  console.log('dataList', dataList);
  const qrCodeUrl = "https://www.sdgs12.com/" + dataList2.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.chargeCd
  console.log('qrCodeUrl',qrCodeUrl);

//  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
  if (currentUser.traderKbn == '1111111111' || 
      currentUser.traderKbn == '0000000001' ){   //事業場区分は管理者かグループ？
      return (
      <>
      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場Cd：</Typography>
            <TextField id="traderCd" value={filter.traderCd} onChange={e => setFilter({ ...filter, traderCd: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場名称：</Typography>
            <TextField id="traderName" value={filter.traderName} onChange={e => setFilter({ ...filter, traderName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={filteredDataList}  // dataList
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <EgsMChargeEdit
          isOpen={detailOpen}
          doClose={() => setDetailOpen(false)}
          dataList2={dataList2}
          processId={dataList2.id}
        />
        <EgsMChargeCreat
          isOpen={creOpen}
          doClose={() => setCreOpen(false)}
//          processId={dataList2.id}
          currentUser={currentUser}
        />
        <SpaceRow height={20} />

        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        <div className={classes.container2}>

          <EgsMChargeListTableForm
            dataList2={dataList2}
          />

          <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 340 }} onClick={handleCreOpen}>
              新規作成
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
              編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
              削除
            </Button>
          </Box>
        </div>
        </BrowserView>
      </>
    );
  }else{                                       //事業場区分は管理者以外？
    return (
      <>

      <BrowserView>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>
        <div className={classes.printHide}>
        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={cols}
              rows={filteredDataList}
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        </div>
        <EgsMChargeEdit
          isOpen={detailOpen}
          doClose={() => setDetailOpen(false)}
          dataList2={dataList2}
          processId={dataList2.id}
        />
        <EgsMChargeCreat
          isOpen={creOpen}
          doClose={() => setCreOpen(false)}
//          processId={dataList2.id}
          currentUser={currentUser}
        />
        <SpaceRow height={20} />
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        <div className={classes.container2}>

          <EgsMChargeListTableForm
            dataList2={dataList2}
          />
          <div className={classes.printHide}>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
            {/* 左側のコンテンツ */}
            <Button variant="contained" color="primary" onClick={handleCreOpen}>
              新規作成
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
              編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
              削除
            </Button>
            {/* 右側のコンテンツ */}
            <div>
              <Button variant="contained" color="primary" onClick={() => window.print()} style={{ marginLeft: 'auto' }}>
                印刷（A3）
              </Button>
            </div>
          </Box>
          </div>
          {/* <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 340 }} onClick={handleCreOpen}>
              新規作成
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
              編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
              削除
            </Button>
          </Box> */}
        </div>
        </BrowserView>
      </>
    );
  }
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default EgsMChargeListTable;
