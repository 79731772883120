import React, { useEffect, useState, useContext } from 'react';
import { BrowserView, MobileView } from "react-device-detect"

import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import { getGarbageCntPeriodList } from '../../lib/api/garbage_cnt_period';
import { getGarbageCntList, getGarbageCntUser, getGarbageCntOpe } from '../../lib/api/garbage_cnt';
import { getDemandBKensu0930EdiList, getDemandBKensu0930EdiUser, getDemandBKensu0930EdiOpe } from '../../lib/api/demand_b_kensu0930_edi';
import { getDemandBKensu1001EdiList, getDemandBKensu1001EdiUser, getDemandBKensu1001EdiOpe } from '../../lib/api/demand_b_kensu1001_edi';
import { getDemandBKensuOldList, getDemandBKensuOldUser, getDemandBKensuOldOpe } from '../../lib/api/demand_b_kensu_old';
//import { getGarbageCntList } from '../../lib/api/garbage_cnt';
//import { getGarbageCntUser } from '../../lib/api/garbage_cnt';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
import {
//  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
//  Backdrop,
//  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  jaJP
} from '@mui/x-data-grid-pro'
// component
//import GarbageCntTable from './GarbageCntTable';
//import GarbageBList0930 from './GarbageBList0930';
//import GarbageBList1001 from './GarbageBList1001';
//import GarbageBListOld from './GarbageBListOld';
import SpaceRow from '../commons/SpaceRow';
import FormControl from '@mui/material/FormControl';
// context
import { AuthContext } from '../../App';
/*
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
*/
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,  // 650
  },
  fontWeight: {
    fontWeight: 2000,  // 900
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "60vw",    // "85vw"    ⇒　横幅を20％に設定
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function CustomToolbar0() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'garbage_cnt',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomToolbar1() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'demand_b_kensu0930',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomToolbar2() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'demand_b_kensu1001',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomToolbar3() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'demand_b_kensu_old',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const GarbageCnt = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataPeriodList, setDataPeriodList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [cnt0930List, setCnt0930List] = useState([]);
  const [cnt1001List, setCnt1001List] = useState([]);
  const [cntOldList, setCntOldList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

//  const [filteredDataList, setFilteredDataList] = React.useState([]);

let ymMin = '000000';
let ymMax = '999999';

  const [filter, setFilter] = React.useState({
    ymFrom: ymMin, // '202403',
    ymTo: ymMax,  //'202406',
//    ymFrom: '',
//    ymTo: '',
    exhaustName: '',
    exhaustCd: '',
    chargeDivision: ''
  })

  let resPeriod;
  let res;
  let res0930;
  let res1001;
  let resold;

  useEffect(() => {
    handleGetList();
  }, []);

  var param_item = '';
/*
  useEffect(() => {
    handleGetList();
//    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])
*/
/*  
  const startFilter = () => {
//    if (filter.mm == '') {
//      param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "__"+filter.exhaustName+"_";
//    }else{  
      param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_" + filter.mm + "_" + filter.exhaustName + "_" + filter.chargeDivision;
//    }
    handleGetList(param_item);
  }
*/  

const handleGetList = async () => {  //　初期表示用
    try {
     setOpen(!open);
//      const res = await getGarbageCntList();
//      const res = await getGarbageCntUser(currentUser.traderCd);
      resPeriod = await getGarbageCntPeriodList();
      if (currentUser.traderKbn == '1111111111') {
//          res = await getGarbageCntList();
//          res0930 = await getDemandBKensu0930EdiList();
//          res1001 = await getDemandBKensu1001EdiList();
//          resold = await getDemandBKensuOldList();
            res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
      }else{
        if (currentUser.traderKbn == '0000000001') {
//            res = await getGarbageCntUser(currentUser.traderCd);
            res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
            resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
        }else{
//          res = await getGarbageCntUser(currentUser.traderCd);
          res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
//          res0930 = await getDemandBKensu0930EdiUser(currentUser.traderCd);
          res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
//          res1001 = await getDemandBKensu1001EdiUser(currentUser.traderCd);
          res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
//          resold = await getDemandBKensuOldUser(currentUser.traderCd);
          resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn);
        }
      }
      // console.log(res.data);
      setDataPeriodList(resPeriod.data);
      ymMin = resPeriod.data[0].yyyymm;
      ymMax = resPeriod.data[3].yyyymm;
      setDataList(res.data);
      setCnt0930List(res0930.data);
      setCnt1001List(res1001.data);
      setCntOldList(resold.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleFindList = async () => {    // 検索用
    try {
     setOpen(!open);
//      const res = await getGarbageCntList();
//      const res = await getGarbageCntUser(currentUser.traderCd);
      if (currentUser.traderKbn == '1111111111') {
//          res = await getGarbageCntList();
//          res0930 = await getDemandBKensu0930EdiList();
//          res1001 = await getDemandBKensu1001EdiList();
//          resold = await getDemandBKensuOldList();
//            if (ymMin > '000000' & ymMax > '000000' & ymMin <= ymMax){
              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.ymFrom + "_" + filter.ymTo + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            }else{  
//              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_000000_999999_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            } 
            res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
            res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
            resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
      }else{
        if (currentUser.traderKbn == '0000000001') {
//            res = await getGarbageCntUser(currentUser.traderCd);
//            if (ymMin > '000000' & ymMax > '000000' & ymMin <= ymMax){
              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.ymFrom + "_" + filter.ymTo + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            }else{
//              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_000000_999999_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            }
            res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
            res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
            resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
        }else{
//          res = await getGarbageCntUser(currentUser.traderCd);
//            if (ymMin > '000000' & ymMax > '000000' & ymMin <= ymMax){
              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.ymFrom + "_" + filter.ymTo + "_" + filter.exhaustName + "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            }else{
//              res = await getGarbageCntOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_000000_999999_" + filter.exhaustName + "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//            }  
//          res0930 = await getDemandBKensu0930EdiUser(currentUser.traderCd);
          res0930 = await getDemandBKensu0930EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//          res1001 = await getDemandBKensu1001EdiUser(currentUser.traderCd);
          res1001 = await getDemandBKensu1001EdiOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
//          resold = await getDemandBKensuOldUser(currentUser.traderCd);
          resold = await getDemandBKensuOldOpe(currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.exhaustName+ "_" + filter.exhaustCd + "_" + filter.chargeDivision);
        }
      }
      // console.log(res.data);
      setDataList(res.data);
      setCnt0930List(res0930.data);
      setCnt1001List(res1001.data);
      setCntOldList(resold.data);

      if (filter.ymFrom > filter.ymTo){
//        window.confirm('年月（開始・終了）に誤りがあります ' + ymMin + '～' + ymMax);
        window.confirm('年月（開始・終了）に誤りがあります ' + filter.ymFrom + '：' + filter.ymTo);
      }

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


  const history = useHistory();

  const colsAll = [
//    { field: 'entryMonth', headerName: '加入月',      width: 110    },
//    {      field: 'sectName',      headerName: '運搬業者名',      hide: true,      width: 300    },
//    {      field: 'exhaustName',      headerName: '排出業者名',      width: 300    },
//    {      field: 'chargeDivision',      headerName: '料金区分',      hide: true,      width: 100    },
//    {      field: 'kensu',      headerName: '件数',      align: 'right',      width: 60    },
//    {      field: 'period',      headerName: '期間',      width: 200    }
//  ]

//  const cols = [
    {      field: 'yyyymm',      headerName: '年月',      align: 'right',      width: 70    },
//    {      field: 'yyyy',      headerName: '年',      align: 'right',      width: 60    },
//    {      field: 'mm',      headerName: '月',      align: 'right',      width: 30    },
    {      field: 'sect1TransportCd',      headerName: '収集運搬業者CD',      hide: true,      width: 150    },
    {      field: 'sect1TransportName',      headerName: '収集運搬業者',      hide: true,      width: 250    },
    {      field: 'jwnetNo',      headerName: '加入者番号',      width: 100    },
    {      field: 'exhaustCd',      headerName: '排出事業者CD',      width: 150    },
    {      field: 'exhaustName',      headerName: '排出事業者',      width: 300    },
    {      field: 'cnt',      headerName: '件数',      align: 'right',      width: 60    },
    {      field: 'chargeDivision',      headerName: '料金区分',      align: 'right',      width: 80    },
    {      field: 'datePeriod',      headerName: '期間',      align: 'left',      width: 200    },
    {      field: 'id',      headerName: 'ID',      align: 'right',      hide: true,      width: 280    }
]

  const cols0930 = [
    {      field: 'entryMonth',      headerName: '加入月',      width: 70    },
    {      field: 'sectCd',      headerName: '収集運搬業者CD',      hide: true,      width: 150    },
    {      field: 'sectName',      headerName: '運搬業者名',      hide: true,      width: 300    },
    {      field: 'jwnetNo',      headerName: '加入者番号',      width: 100    },
    {      field: 'exhaustCd',      headerName: '排出事業者CD',      width: 150    },
    {      field: 'exhaustName',      headerName: '排出事業者',      width: 300    },
    {      field: 'chargeDivision',      headerName: '料金区分',      hide: true,      width: 100    },
    {      field: 'kensu',      headerName: '件数',      align: 'right',      width: 60    },
    {      field: 'period',      headerName: '期間',      width: 200    }
  ]

  const cols1001 = [
    {      field: 'entryMonth',      headerName: '加入月',      width: 70    },
    {      field: 'sectCd',      headerName: '収集運搬業者CD',      hide: true,      width: 150    },
    {      field: 'sectName',      headerName: '運搬業者名',      hide: true,      width: 300    },
    {      field: 'jwnetNo',      headerName: '加入者番号',      width: 100    },
    {      field: 'exhaustCd',      headerName: '排出事業者CD',      width: 150    },
    {      field: 'exhaustName',      headerName: '排出業者名',      width: 300    },
    {      field: 'chargeDivision',      headerName: '料金区分',      hide: true,      width: 100    },
    {      field: 'kensu',      headerName: '件数',      align: 'right',      width: 60    },
    {      field: 'period',      headerName: '期間',      width: 200    }
  ]

  const colsOld = [
    {      field: 'entryMonth',      headerName: '加入月',      width: 70    },
    {      field: 'sectCd',      headerName: '収集運搬業者CD',      hide: true,      width: 150    },
    {      field: 'sectName',      headerName: '運搬業者名',      hide: true,      width: 300    },
    {      field: 'jwnetNo',      headerName: '加入者番号',      width: 100    },
    {      field: 'exhaustCd',      headerName: '排出事業者CD',      width: 150    },
    {      field: 'exhaustName',      headerName: '排出業者名',      width: 300    },
    {      field: 'chargeDivision',      headerName: '料金区分',      hide: true,      width: 100    },
    {      field: 'kensu',      headerName: '件数',      align: 'right',      width: 60    },
    {      field: 'period',      headerName: '期間',      width: 200    }
  ]

  return (
    <>
      <h1>廃棄物件数</h1>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>

            <Typography variant="h7" style={{ fontWeight: 700 }}>年月</Typography>

           <FormControl sx={{ minWidth: 100 }}>
            <Select
                id='ymFrom'
                label='開始年月'
                variant="outlined"
                type='text'
                name='ymFrom'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,ymFrom: e.target.value})}
//                error={errorReceiptCharge}
                value={filter.ymFrom}
//                defaultValue={wk_charge_id}
              >
                {dataPeriodList.map((dataPeriodList) => (
                  <MenuItem
                    key={dataPeriodList.yyyymm}
                    value={dataPeriodList.yyyymm}
                  >
                    {dataPeriodList.yyyymm}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

            <Typography variant="h7" style={{ fontWeight: 700 }}>～</Typography>

            <FormControl sx={{ minWidth: 100 }}>
            <Select
                id='ymTo'
                label='終了年月'
                variant="outlined"
                type='text'
                name='ymTo'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,ymTo: e.target.value})}
//                error={errorReceiptCharge}
                value={filter.ymTo}
//                defaultValue={wk_charge_id}
              >
                {dataPeriodList.map((dataPeriodList) => (
                  <MenuItem
                    key={dataPeriodList.yyyymm}
                    value={dataPeriodList.yyyymm}
                  >
                    {dataPeriodList.yyyymm}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場CD</Typography>
            <TextField
              id="exhaustCd"
              value={filter.exhaustCd}
              onChange={e => setFilter({ ...filter, exhaustCd: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
//                  startFilter();
                  handleFindList();
                }
              }}
            />

            <Typography variant="h7" style={{ fontWeight: 700 }}>　事業場名</Typography>
            <TextField
              id="exhaustName"
              value={filter.exhaustName}
              onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
//                  startFilter();
                  handleFindList();
                }
              }}
            />

            <Typography variant="h7" style={{ fontWeight: 700 }}>　料金区分</Typography>

              <Select
                id='chargeDivision'
                label='料金区分'
                variant="outlined"
                type='text'
                name='chargeDivision'
                margin="dense"

                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}}

//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({ ...filter, chargeDivision: e.target.value })}
                value={filter.chargeDivision}
              >
                <MenuItem value={filter.chargeDivision}>
                  <em>料金区分</em>
                </MenuItem>
                <MenuItem value={'A'}>A料金</MenuItem>
                <MenuItem value={'B'}>B料金</MenuItem>
                <MenuItem value={'C'}>C料金</MenuItem>
               </Select>

            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); 
//              startFilter();
              handleFindList();
            }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>

{/*}      <GarbageCntTable        dataList={dataList}        currentUser={currentUser}      /> */}

      <BrowserView>
        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={colsAll}
              rows={dataList}  // filteredDataList
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar0,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        </BrowserView>

      
      <h2>B料金　料金改定前</h2>
{/*}      <GarbageBList0930
        cnt0930List={cnt0930List}
        currentUser={currentUser}
            /> */}

      <BrowserView>
        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={cols0930}
              rows={cnt0930List}  // filteredDataList
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar1,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        </BrowserView>

      <h2>B料金　料金改定後</h2>
{/*}      <GarbageBList1001
        cnt1001List={cnt1001List}
        currentUser={currentUser}
            /> */}

      <BrowserView>
        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={cols1001}
              rows={cnt1001List}  // filteredDataList
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar2,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        </BrowserView>

      <h2>B料金　環境ガードシステム</h2>
{/*}      <GarbageBListOld
        cntOldList={cntOldList}
        currentUser={currentUser}
            /> */}

      <BrowserView>
        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
//              pageSize={pageSize}
//              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//              rowsPerPageOptions={[10, 20, 50]}
//              pagination
              sx={styles.grid}
              columns={colsOld}
              rows={cntOldList}  // filteredDataList
              density="compact"
//              autoHeight
              components={{
                Toolbar: CustomToolbar3,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        </BrowserView>

    </>
  );
};
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default GarbageCnt;
