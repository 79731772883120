// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
} from '@material-ui/core';
// import ReactToPdf from "react-to-pdf";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getLinkageUser } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

//import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getWasteCdList } from '../../lib/api/waste_cd';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';

import { getFirstSelList, getFirstSelUser, getFirstSelId } from '../../lib/api/first_sel';


import './app.css';
import { AuthContext } from '../../App';
import { integerPropType } from '@mui/utils';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    // width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  memo_box: {
    color: "#C44",
//    fontcolor: "rgb(050, 050, 050)",
//    foreground: "rgb(001, 001, 001)",
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EgsTGarbageEdi2ndForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType    ,dataList2,  selRows,  inputRef1, inputError1, inputRef2, inputError2 } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const [dataListC01, setdataListC01] = useState([]);
  const [dataListC02, setdataListC02] = useState([]);

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}

  const pdfRef = React.createRef();

//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//    const dataListWaste = await getEgsMWasteclassList();
    const dataListWaste = await getWasteCdList();
    setDataListWaste(dataListWaste.data);
//    console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
//    console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);

//      const resC1 = await getEgsMChargeList();
//      console.log('resC1',resC1.data);
//      setdataListC01(resC1.data);
      const dataListC01 = await getEgsMChargeList();
//      console.log('dataListC01',dataListC01.data);
      setdataListC01(dataListC01.data);

//      const resC2 = await getChargeSelList();
//      console.log('resC2',resC2.data);
//      setdataListC02(resC2.data);
      const dataListC02 = await getChargeSelList();
//      console.log('dataListC02',dataListC02.data);
      setdataListC02(dataListC02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

const dataListWasteKind = dataListWaste.filter(dataListWaste =>
  { return dataListWaste.wasteKindCd == dataListWaste.wasteCd });

const dataListWasteCd = dataListWaste.filter(dataListWaste =>
  { return dataListWaste.wasteKindCd == value.wasteKindCd });

const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//console.log('disposaldataList',disposaldataList);

//const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
//console.log('transport',value.sect1TransportMethodCd,trans1dataList);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
//console.log('packingdataList',packingdataList);


// 区間１～５

//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.id == value.exhaustId });
//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.egsMTraderid == value.exhaustId });
const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
//  console.log('chargedataList00','->',chargedataList00,'<-',value.exhaustId);

//  const chargedataList07 = dataListC02.filter(resC2 => { return resC2.id == value.lastProcessingChargeId });
  const chargedataList07 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.lastProcessingTraderId });
//  console.log('chargedataList07','->',chargedataList07,'<-',value.lastProcessingTraderId);

const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });
const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });
const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });
const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });
const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });

const [errorAmount1, setErrorAmount1] = useState(false);  // 荷姿数量
const [errorPackingAmount1, setErrorPackingAmount1] = useState(false);  // 荷姿数量

//let selRows = React.useRef([]);
const [pageSize, setPageSize] = React.useState(10);

const [filteredDataList, setFilteredDataList] = React.useState([]);
const [filter, setFilter] = React.useState({
  exhaustDateStart: '',
  exhaustDateEnd: '',
})



const [dataList3, setDataList3] = useState({});


var selectdataList = [];
let res;

useEffect(() => {
  handleGetList2(dataList2);
}, [dataList2]);

const handleGetList2 = async () => {
  try {
   setOpen(!open);
//      const resC00 = await getChargeUser(dataList2.exhaustCd);
//      // console.log("resC00.data",resC00.data);
//      setDataListC00(resC00.data);
//      const resC01 = await getChargeUser(dataList2.sect1TransportCd);
//      // console.log("resC01.data",resC01.data);
//      setDataListC01(resC01.data);

    if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getFirstSelList();
//        res = await getFirstSelList2(23);
//      res = await getFirstSelId(dataList2.id);
      res = await getFirstSelUser(dataList2.exhaustCd);
    }else if (currentUser.traderKbn == '0001001000'){   //事業場区分は中間処理・排出事業者？
      res = await getFirstSelList();
    }else{                                       //事業場区分は管理者以外？
      res = await getFirstSelUser(currentUser.traderCd);
    }
    // console.log(res.data);
    setDataList3(res.data);
//      // console.log('test1',selRows.current[0])

//      const dataListTransport = await getEgsMTransportMethodList();
//      setDataListTransport(dataListTransport.data);


   setOpen(false);
  } catch (e) {
    console.log(e);
    console.log(e.response);
  }
};



useEffect(() => {
  setFilteredDataList(dataList3);
  // console.log('setFilteredDataList', filteredDataList);
}, [dataList3, currentUser])


//    startFilter();

const startFilter = () => {
  // console.log('dataList', dataList3);
  // console.log('currentUser', currentUser);
  let originalList = dataList3;

  originalList = originalList.filter(item => item.selectId === '' || item.selectId == dataList2.id);


  // console.log('originalList', originalList);
  // console.log('filter', filter);
  if (filter.exhaustDateStart) {
    const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
    });
  }
  if (filter.exhaustDateEnd) {
    const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
    });
  }

//    // console.log(dataList2.garbageTag,dataList3.selectId,item.selectId)
  // console.log(dataList2.garbageTag,dataList3.selectId)
{/*}
  //    if (dataList3.selectId == '' || dataList3.selectId == dataList2.garbageTag) {
    originalList = originalList.filter(item => {
      const originalList_selectId = item.selectId;
      const null_selectId = '';
//        return originalList_selectId.isEmpty || originalList_selectId.isSame('') || originalList_selectId.isSame(dataList2.garbageTag);
//        return originalList_selectId.isSame(null_selectId) || originalList_selectId.isSame(dataList2.garbageTag);
//        return originalList_selectId || originalList_selectId.isSame(dataList2.garbageTag);
      return dataList2.garbageTag.isSame(originalList_selectId);
    });
//    }
*/}
//    if (filter.manifestNo) {
//      originalList = originalList.filter(item => item.selectId === dataList2.garbageTag);
//      originalList = originalList.filter(item => item.selectId !== '' || item.selectId === '99997978327798791');
//      originalList = originalList.filter(item => item.selectId === '' || item.selectId === '491787');
//      originalList = originalList.filter(item => item.selectId === '' || item.selectId === dataList2.garbageTag);
//    項目属性が異なるので、===でなく==で比較
    originalList = originalList.filter(item => item.selectId === '' || item.selectId == dataList2.id);
//    }

  // console.log(originalList)



//    startFilter();




  setFilteredDataList(originalList);
}


const cols = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'selectName', headerName: '選択', width: 0 },
  { field: 'secondFlg', headerName: '2次フラグ', width: 0 },
  { field: 'selectFlg', headerName: '選択フラグ', width: 50 },
  { field: 'selectTag', headerName: '選択タグ', width: 100 },
  { field: 'selectId', headerName: '選択ID', width: 60 },
  { field: 'statusName', headerName: '状態', width: 100 },
  { field: 'exhaustDate', headerName: '排出日', type: 'date', width: 100 },
  { field: 'garbageTag', headerName: 'ゴミタグ', width: 200 },
  { field: 'manifestNo', headerName: 'マニフェストＮｏ', width: 150 },
  { field: 'exhaustName', headerName: '排出事業場', width: 200 },
  { field: 'wasteName', headerName: '廃棄物種類', width: 200 },
  { field: 'amount1', headerName: '数量', width: 100 },
  { field: 'unitName', headerName: '単位', width: 100 },
  { field: 'lastProcessingTraderName', headerName: '最終処分場', width: 200 }
]


const selectSet = async (e) => {

  selRows.current = e;

  let i = 0;

  // e.preventDefault();
  // 追加
  // // console.log("selRows",selRows);
//  value.amount1 = 0
//  if (selRows.current.length > 0){
//    for (let i = 0; i<selRows.current.length; i++){
      for (let j = 0; j<dataList3.length; j++){
//        if (selRows.current[i] == dataList3[j].id) {
        if (dataList3[j].selectName == '☑')  {
//            value.amount1 = value.amount1 + dataList3[j].amount1 * 1
//          value.amount1 = Math.round((value.amount1 + dataList3[j].amount1 * 1)*1000)/1000

          selRows.current[i] = dataList3[j].id
          i = i + 1
          selRows.current.length = i
        }
      }    
      //    }
//  }
//    alert(value.amount1)
};

const selectChange = async (v) => {

  selRows.current = v;

  // e.preventDefault();
  // 追加
  // // console.log("selRows",selRows);
  value.amount1 = 0
  value.packingAmount1 = 0
//  if (selRows.current.length > 0){
    for (let i = 0; i<selRows.current.length; i++){
      for (let j = 0; j<dataList3.length; j++){
        if (selRows.current[i] == dataList3[j].id) {
//        if (selRows.current[j] !== 0)  {
//            value.amount1 = value.amount1 + dataList3[j].amount1 * 1
          value.amount1 = Math.round((value.amount1 + dataList3[j].amount1 * 1)*1000)/1000
          value.packingAmount1 = value.packingAmount1 + dataList3[j].packingAmount1 * 1

//          selRows.current[j] = dataList3[j].id
        }
      }    
    }
//  }
//    alert(value.amount1)
};


const checkValidation = () => {
  let available = true;
/*
    // 数量チェック
//    if (length(value.amount1) > 9 || value.amount1 > 99999.999) {    
    if (value.amount1 > 99999.999) {
//        alert('数量が５桁を超えます <' + value.amount1 + '>')
      available = false;
      setErrorAmount1(true);
    } else {
      if (value.amount1 < 0) {
//        alert('数量がマイナスです <' + value.amount1 + '>')
        available = false;
        setErrorAmount1(true);
      } else {
        if (String(value.amount1).split('.')[1].length > 3) {
//        if (Number.isInteger(value.amount1)) {
//        }else{
          alert('小数が4桁を超えます <' + value.amount1 + '>')
          available = false;
          setErrorAmount1(false);
        }
      }
    }

  // 荷姿数量チェック
    if (value.packingAmount1 > 99999) {    
//      alert('荷姿数量が５桁を超えます <' + value.packingAmount1 + '>')
        available = false;
        setErrorPackingAmount1(true);
    } else {
        if (value.packingAmount1 < 0) {    
//          alert('荷姿数量がマイナスです <' + value.packingAmount1 + '>')
          available = false;
          setErrorPackingAmount1(true);
        }else{
//          if (Number.isInteger(value.packingAmount1)) {
//          if (value.packingAmount1.length > 4 && value.packingAmount1 <= 999) {
          if (String(value.packingAmount1).split('.')[1].length > 0) {
//            }else{
//            alert('荷姿数量が小数です <' + value.packingAmount1 + '>')
            available = false;
            setErrorPackingAmount1(false);
          }  
        }
    }
*/  

//  if (!value.packingAmount1 ||
//      !value.packingAmount1
  if (value.packingAmount1 < '0' || value.packingAmount1 > '99999' ) {
    console.log(
      !value.packingAmount1,
    );
    available = false;
    setErrorPackingAmount1(!value.packingAmount1);
  }
    
/*
    if (!available) {
      alert('入力に誤りがあります')
    }
*/
  return available;
}


  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
{/*}      
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
          props.handleCancel();
        }}>
          戻る
        </Button>
      </div>

      <SpaceRow height={20} />
*/}

{/*}      <DialogContent>  */}

      <form className={classes.root}>

        <h3>【一次マニフェスト選択】</h3>
        <Grid
      container
      spacing={2}
    >
    <Grid item xs={2}>
      <Box p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
          排出期間：
        </Typography>
      </Box>
    </Grid>
    <Grid item xs style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    }}>
      <TextField
        id="exhaustDate-start"
        label=""
        type={"date"}
        value={filter.exhaustDateStart}
        onChange={e => setFilter({
          ...filter,
          exhaustDateStart: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
      <TextField
        id="exhaustDate-start"
        label=""
        type={"date"}
        value={filter.exhaustDateEnd}
        onChange={e => setFilter({
          ...filter,
          exhaustDateEnd: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.preventDefault();
          startFilter();
        }}
        style={{ marginLeft: 24 }}
      >
        検索
      </Button>
    </Grid>

    </Grid>

        <h3>　↓ 対象の一次マニフェストを選択（ㇾ）して下さい。※　前回選択済は選択欄：☑</h3>
        <div className={classes.container2}>
         <Box sx={{ height: 600, width: '100%' }}>  {/*300*/}{/*520*/} {/*120*/}
          <DataGridPro
//            pageSize={pageSize}
//            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//            rowsPerPageOptions={[10, 20, 50]}
//            pagination
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
//            autoHeight
            checkboxSelection
            disableSelectionOnClick
//            onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            onSelectionModelChange={(v) => selectChange(v)}
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
         </Box>
        </div>
{/*}
        <h3>　↑ 対象の一次マニフェストを選択（ㇾ）して下さい。※　前回選択済は選択欄：☑</h3>

          <SpaceRow height={100} />
*/}

      </form>
{/*}      </DialogContent>  */}

      <div className={classes.container} ref={pdfRef}>
        

      <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              最新状態
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}> {value.statusName} </Typography>
{/*}
            <Select
              id='status'
              label='状態'
              variant="outlined"
              type='text'
              name='status'
              margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.status}
            >
              <MenuItem value={value.status}>
                <em>最新状態</em>
              </MenuItem>
              <MenuItem value={'00'}>00:未設定</MenuItem>
              <MenuItem value={'10'}>10:区間１運搬中</MenuItem>
              <MenuItem value={'11'}>11:区間１積替保管中</MenuItem>
              <MenuItem value={'20'}>20:区間２運搬中</MenuItem>
              <MenuItem value={'21'}>21:区間２積替保管中</MenuItem>
              <MenuItem value={'30'}>30:区間３運搬中</MenuItem>
              <MenuItem value={'31'}>31:区間３積替保管中</MenuItem>
              <MenuItem value={'40'}>40:区間４運搬中</MenuItem>
              <MenuItem value={'41'}>41:区間４積替保管中</MenuItem>
              <MenuItem value={'50'}>50:区間５運搬中</MenuItem>
              <MenuItem value={'51'}>51:区間６積替保管中</MenuItem>
              <MenuItem value={'60'}>60:中間処理中</MenuItem>
              <MenuItem value={'61'}>61:中間処理済</MenuItem>
              <MenuItem value={'70'}>70:処分中</MenuItem>
              <MenuItem value={'71'}>71:最終処分済</MenuItem>
            </Select>
*/}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                排出日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='exhaustDate' variant="outlined" type='date' name='exhaustDate' halfWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} />
            </Box>
          </Grid>
        </Grid>
{/*}
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分開始
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='lastProcessingStart' variant="outlined" type='date' name='lastProcessingStart' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingStart} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分終了
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='lastProcessingEnd' variant="outlined" type='date' name='lastProcessingEnd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingEnd} />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
*/}

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='manifestNo' variant="outlined" type='text' name='manifestNo' halfWidth margin="dense" value={value.manifestNo}
//              onChange={(e) => handleChange(e)}
/>  */}
            <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs />  */}

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='garbageTag' variant="outlined" type='text' name='garbageTag' disabled halfWidth margin="dense" value={value.garbageTag} />  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs />  */}
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
{/*}              <TextField id='exhaustName' variant="outlined" type='text' name='exhaustName' fullWidth margin="dense" value={value.exhaustName} /> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='exhaustChargeId'
                label='排出担当者'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
              {chargedataList00.map((chargedataList00) => (
                <MenuItem
                  key={chargedataList00.id}
                  value={chargedataList00.id}
                >
                  {chargedataList00.chargeCd}:{chargedataList00.chargeName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>




        <Grid
          container
          spacing={0}
          className={classes.row}
        >

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物種類
              </InputLabel>
            </Box>
          </Grid>

          
          <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}
            <Typography variant="h7" style={{ fontSize: 20, fontWeight: 700 }}>
                {value.wasteCd}⇒{value.wasteKindCd}
              </Typography>
*/}

            <Select
                id='wasteKindCd'
                label='廃棄物種類'
                variant="outlined"
                type='text'
                name='wasteKindCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteKindCd}
                fullWidth
//                error={errorWasteKindCd}
              >
{/*}
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
*/}
                {dataListWasteKind.map((dataListWasteKind) => (
                  <MenuItem
                    key={dataListWasteKind.wasteKindCd}  // wasteclassCd
                    value={dataListWasteKind.wasteKindCd}  //wasteclassCd
                  >
                    {dataListWasteKind.wasteKindCd}:{dataListWasteKind.wasteKindName}
                  </MenuItem>
                ))}
                </Select>

            </Box>
          </Grid>  

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='wasteCd'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteCd}
                fullWidth
//                error={errorWasteCd}
              >
{/*}
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteclassCd}
                    value={dataListWaste.wasteclassCd}
                  >
                    {dataListWaste.wasteclassCd}:{dataListWaste.wasteclassName}
                  </MenuItem>
                ))}
*/}
{/*}
                {dataListWaste.map((dataListWaste) => (
                  <MenuItem
                    key={dataListWaste.wasteCd}
                    value={dataListWaste.wasteCd}
                  >
                    {dataListWaste.wasteKindCd}:{dataListWaste.wasteKindName}　　{dataListWaste.wasteCd}:{dataListWaste.wasteName}
                  </MenuItem>
*/}
                {dataListWasteCd.map((dataListWasteCd) => (
                  <MenuItem
                    key={dataListWasteCd.wasteCd}
                    value={dataListWasteCd.wasteCd}
                  >
                    {dataListWasteCd.wasteCd}:{dataListWasteCd.wasteName}
                  </MenuItem>

                ))}
  
              </Select>
            </Box>
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
{/*}            <Box className={classes.memo_box} p={1} display={"flex"} alignItems={"center"}  >  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>廃棄物の数量<br />（小数点以下３桁）</Typography>
{/*}              <Typography variant="h7" style={{ fontWeight: 700 ,fontcolor : red}}>（小数点以下３桁）</Typography>  */}
            </Box>
{/*}
            <Box className={classes.memo_box} p={1} display={"flex"} alignItems={"center"} >
              <Typography variant="h7" style={{ fontWeight: 700 }}>（小数点以下３桁）</Typography>
            </Box>
*/}
{/*}
            <Box bgcolor="primary.gray" color="red" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>（小数点以下３桁）</Typography>
           </Box>
*/}
          </Grid>
          <Grid item xs={4}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <Box bgcolor="primary.gray" color="primary.black" p={1}>  */}
{/*}
                <TextField id='amount1' variant="outlined" type='number' name='amount1' size="small" halfWidth margin="dense" value={value.amount1}
//                  pattern="100(.0{1,3})?|\d{1,3}(.\d{1,3})?"    // 小数点以下３桁チェック　NG
                  onChange={(e) => handleChange(e)} />
*/}
              <TextField id='amount1' hiddenLabel variant="outlined" type='number' name='amount1' halfWidth margin="dense"
//              inputProps={{ maxLength: 7, pattern: "^[0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
//              inputProps={{ maxLength: 7, step: "0.001", pattern: "100(.0{1,2})?|\d{1,2}(.\d{1,2})?" }}     // ^[a-zA-Z0-9_]+$

                error={inputError1}
                inputProps={{ max: 99999.999, min:0.001, step:0.001 }}
                inputRef={inputRef1}
                helperText={inputRef1?.current?.validationMessage}

                onChange={(e) => handleChange(e)}
                value={value.amount1} />

{/*}            <TextField id='unitName' variant="outlined" type='text' name='unitName' halfWidth margin="dense" value={value.unitName} /> */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>

                <Button variant="contained" color='secondary' 
//                 style={{ marginLeft: 10 }}
//                 style={{ textAlign: 'right' }}
//                 style={{ marginTop: 10, textAlign: 'right' }}
                 style={{ marginTop: 10, marginLeft: 50 }}
//                 onClick={(e) => handleSelect()} >
                 onClick={(e) => handleChange(e)} >
                 数量反映
               </Button>

               <Button variant="contained" color='secondary' 
//                 style={{ marginLeft: 10 }}
//                 style={{ textAlign: 'right' }}
//                 style={{ marginTop: 10, textAlign: 'right' }}
                 style={{ marginTop: 10, marginLeft: 50 }}
//                 onClick={(e) => handleSelect()} >
                 onClick={(e) => selectSet(e)} >
                 選択反映
               </Button>

{/*}              </Box>  */}
            </Box>
          </Grid>
{/*}          <Grid item xs /> */}
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
{/*}            <Box className={classes.memo_box} p={1} display={"flex"} alignItems={"center"}  >  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>荷姿の数量<br />（整数）</Typography>
            </Box>
{/*}
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} color="red" >
              <Typography variant="h7" style={{ fontWeight: 700 }}>（整数）</Typography>
            </Box>
*/}
          </Grid>
          <Grid item xs={4}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <Box bgcolor="primary.gray" color="primary.black" p={1}>  */}
{/*}
                <TextField id='packingAmount1' variant="outlined"
                 type='number' // number text integer
                 name='packingAmount1' size="small" halfWidth margin="dense"
                 error={errorPackingAmount1}
                 // これでも小数点以下が入力できてしまう！！！
                 inputProps={{ pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
                 value={value.packingAmount1}
                 onChange={(e) => handleChange(e)}
                 />
*/}
              <TextField id='packingAmount1' hiddenLabel variant="outlined" type='number' name='packingAmount1' halfWidth margin="dense"
//              inputProps={{ maxLength: 7, pattern: "^[0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
//              inputProps={{ maxLength: 3, step: "1", pattern: "[0-9]" }}     // ^[a-zA-Z0-9_]+$  , step: "1"

                error={inputError2}
                inputProps={{ max: 99999, min:1, step:1 }}
                inputRef={inputRef2}
                helperText={inputRef2?.current?.validationMessage}

                onChange={(e) => handleChange(e)}
              value={value.packingAmount1} />

{/*}              <TextField id='packingName' variant="outlined" type='text' name='packingName' halfWidth margin="dense" value={value.packingName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.packingName} </Typography>
              </Box>
{/*}            </Box> */}
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                メモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' label='' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                処分方法
              </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs>  */}
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='disposalMethodCd'
                label='処分方法'
                variant="outlined"
                type='text'
                name='disposalMethodCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.disposalMethodCd}
              >
              {disposaldataList.map((disposaldataList) => (
                <MenuItem
                  key={disposaldataList.disposalMethodCd}
                  value={disposaldataList.disposalMethodCd}
                >
                  {disposaldataList.disposalMethodCd}:{disposaldataList.disposalMethodName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>

          <Grid item xs={2}>
{/*}        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginTop={1} marginBottom={1}>  */}
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分の場所
              </Typography>
            </Box>
          </Grid>

{/*}          <Grid item xs={4}>  */}
{/*}          <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography
             variant="h7"
             style={{ fontWeight: 700 }}>
              {value.lastReportName}
            </Typography>
          </Box>
          </Grid>
*/}

{/*}
        <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分事業場記載フラグ
              </Typography>
            </Box>
          </Grid>
*/}
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='lastReportFlg'
                label='最終処分事業場記載フラグ'
                variant="outlined"
                type='text'
                name='lastReportFlg'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastReportFlg}
                fullWidth
              >
                <MenuItem value={value.lastReportFlg}>
                  <em>最終処分事業場記載フラグ</em>
                </MenuItem>
{/*}                <MenuItem value={'0:委託契約書記載のとおり'}>0:委託契約書記載のとおり</MenuItem>
                <MenuItem value={'1:最終処分事業場（予定）を記載する'}>1:最終処分事業場（予定）を記載する</MenuItem>  */}
                <MenuItem value={'0'}>0:委託契約書記載のとおり</MenuItem>
                <MenuItem value={'1'}>1:最終処分事業場（予定）を記載する</MenuItem>
              </Select>
            </Box>
          </Grid>

          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
{/*}            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginTop={1} marginBottom={1}>  */}
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>

{/*}          <Grid item xs={4}>  */}
          <Grid item xs={10}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography
             variant="h7"
             style={{ fontWeight: 700 }}>
              {value.toxicName01}　{value.toxicName02}　{value.toxicName03}　
              {value.toxicName04}　{value.toxicName05}　{value.toxicName06}
            </Typography>
          </Box>
        </Grid>
{/*}
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName01' variant="standard" type='text' name='toxicName01' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName01 || value.toxicName02 || value.toxicName03} />
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName02' variant="standard" type='text' name='toxicName02' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName02} />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName03' variant="standard" type='text' name='toxicName03' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName03} />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName04' variant="standard" type='text' name='toxicName04' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName04} />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName05' variant="standard" type='text' name='toxicName05' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName05} />
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='toxicName06' variant="standard" type='text' name='toxicName06' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.toxicName06} />
            </Box>
          </Grid>
*/}
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分業者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='lastProcessingTraderName' variant="outlined" type='text' name='lastProcessingTraderName' fullWidth margin="dense" value={value.lastProcessingTraderName} />  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingTraderName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分担当者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='lastProcessingChargeId'
                variant="outlined"
                type='text'
                name='lastProcessingChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastProcessingChargeId}
                style={{
                  marginRight: 16
                }}
                fullWidth
              >
              {chargedataList07.map((chargedataList07) => (
                <MenuItem
                  key={chargedataList07.id}
                  value={chargedataList07.id}
                >
                  {chargedataList07.chargeCd}:{chargedataList07.chargeName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分開始
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='lastProcessingStart' variant="outlined" type='date' name='lastProcessingStart' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingStart} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分終了
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='lastProcessingEnd' variant="outlined" type='date' name='lastProcessingEnd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingEnd} />
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬情報
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬方法
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  車両番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬先事業場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderRightWidth: 0 }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  備考
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                区間１
              </InputLabel>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect1TransportName' variant="outlined" type='text' name='sect1TransportName' halfWidth margin="dense" value={value.sect1TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect1TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect1TransportChargeId' label='区間１運搬担当者' variant="outlined" type='text' name='sect1TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeId} >
                  {chargedataList01.map((chargedataList01) => (<MenuItem key={chargedataList01.id} value={chargedataList01.id} > {chargedataList01.chargeCd}:{chargedataList01.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                  value={value.sect1TransportMethodCd} >
                  {trans1dataList.map((trans1dataList) => (<MenuItem key={trans1dataList.transportMethodCd} value={trans1dataList.transportMethodCd} > {trans1dataList.transportMethodCd}:{trans1dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.value_box}>
              <Box p={1}>
                <TextField id='sect1CarNo' hiddenLabel variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} fullWidth />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination1Name' variant="outlined" type='text' name='destination1Name' halfWidth margin="dense" value={value.destination1Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination1Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間１運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList11.map((chargedataList11) => (<MenuItem key={chargedataList11.id} value={chargedataList11.id} >   {chargedataList11.chargeCd}:{chargedataList11.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={1}>
                <TextField id='memo1' hiddenLabel variant="outlined" type='text' name='memo1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} fullWidth />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間２
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect2TransportName' variant="outlined" type='text' name='sect2TransportName' halfWidth margin="dense" value={value.sect2TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect2TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect2TransportChargeId' label='区間２運搬担当者' variant="outlined" type='text' name='sect2TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportChargeId} >
                  {chargedataList02.map((chargedataList02) => (<MenuItem key={chargedataList02.id} value={chargedataList02.id} > {chargedataList02.chargeCd}:{chargedataList02.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect2TransportMethodCd} >
                  {trans2dataList.map((trans2dataList) => (<MenuItem key={trans2dataList.transportMethodCd} value={trans2dataList.transportMethodCd} > {trans2dataList.transportMethodCd}:{trans2dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.value_box}>
              <Box p={1}>
                <TextField id='sect2CarNo' hiddenLabel variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} fullWidth />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination2Name' variant="outlined" type='text' name='destination2Name' halfWidth margin="dense" value={value.destination2Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination2Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination2ChargeId' label='区間２運搬先担当者' variant="outlined" type='text' name='destination2ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeId} >
                  {chargedataList12.map((chargedataList12) => (<MenuItem key={chargedataList12.id} value={chargedataList12.id} >   {chargedataList12.chargeCd}:{chargedataList12.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={1}>
                <TextField fullWidth id='memo2' variant="outlined" type='text' name='memo2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間３
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect3TransportName' variant="outlined" type='text' name='sect3TransportName' halfWidth margin="dense" value={value.sect3TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect3TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect3TransportChargeId' label='区間３運搬担当者' variant="outlined" type='text' name='sect3TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportChargeId} >
                  {chargedataList03.map((chargedataList03) => (<MenuItem key={chargedataList03.id} value={chargedataList03.id} > {chargedataList03.chargeCd}:{chargedataList03.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect3TransportMethodCd} >
                  {trans3dataList.map((trans3dataList) => (<MenuItem key={trans3dataList.transportMethodCd} value={trans3dataList.transportMethodCd} > {trans3dataList.transportMethodCd}:{trans3dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect3CarNo' variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination3Name' variant="outlined" type='text' name='destination3Name' halfWidth margin="dense" value={value.destination3Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination3Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination3ChargeId' label='区間３運搬先担当者' variant="outlined" type='text' name='destination3ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeId} >
                  {chargedataList13.map((chargedataList13) => (<MenuItem key={chargedataList13.id} value={chargedataList13.id} >   {chargedataList13.chargeCd}:{chargedataList13.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={1}>
                <TextField fullWidth id='memo3' variant="outlined" type='text' name='memo3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間４
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect4TransportName' variant="outlined" type='text' name='sect4TransportName' halfWidth margin="dense" value={value.sect4TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect4TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect4TransportChargeId' label='区間４運搬担当者' variant="outlined" type='text' name='sect4TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportChargeId} >
                  {chargedataList04.map((chargedataList04) => (<MenuItem key={chargedataList04.id} value={chargedataList04.id} > {chargedataList04.chargeCd}:{chargedataList04.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect4TransportMethodCd} >
                  {trans4dataList.map((trans4dataList) => (<MenuItem key={trans4dataList.transportMethodCd} value={trans4dataList.transportMethodCd} > {trans4dataList.transportMethodCd}:{trans4dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect4CarNo' hiddenLabel variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination4Name' variant="outlined" type='text' name='destination4Name' halfWidth margin="dense" value={value.destination4Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination4Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間４運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList14.map((chargedataList14) => (<MenuItem key={chargedataList14.id} value={chargedataList14.id} >   {chargedataList14.chargeCd}:{chargedataList14.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={1}>
                <TextField fullWidth id='memo4' hiddenLabel variant="outlined" type='text' name='memo4' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間５
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect5TransportName' variant="outlined" type='text' name='sect5TransportName' halfWidth margin="dense" value={value.sect5TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect5TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect5TransportChargeId' label='区間５運搬担当者' variant="outlined" type='text' name='sect5TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportChargeId} >
                  {chargedataList05.map((chargedataList05) => (<MenuItem key={chargedataList05.id} value={chargedataList05.id} > {chargedataList05.chargeCd}:{chargedataList05.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect5TransportMethodCd} >
                  {trans5dataList.map((trans5dataList) => (<MenuItem key={trans5dataList.transportMethodCd} value={trans5dataList.transportMethodCd} > {trans5dataList.transportMethodCd}:{trans5dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect5CarNo' variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination5Name' variant="outlined" type='text' name='destination5Name' halfWidth margin="dense" value={value.destination5Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination5Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間５運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList15.map((chargedataList15) => (<MenuItem key={chargedataList15.id} value={chargedataList15.id} >   {chargedataList15.chargeCd}:{chargedataList15.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box p={1}>
                <TextField fullWidth id='memo5' variant="outlined" type='text' name='memo5' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                中間処理産業廃棄物情報管理方法フラグ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='middleProcessingInfoFlg'
                label='中間処理産業廃棄物情報管理方法フラグ'
                variant="outlined"
                type='text'
                name='middleProcessingInfoFlg'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.middleProcessingInfoFlg}
              >
                <MenuItem value={value.middleProcessingInfoFlg}>
                  <em>中間処理産業廃棄物情報管理方法フラグ</em>
                </MenuItem>
                <MenuItem value={'1'}>1:当欄指定のとおり</MenuItem>
                <MenuItem value={'2'}>2:１次不要</MenuItem>
                <MenuItem value={'3'}>3:帳簿記載のとおり</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>



      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
//            handleSubmit(e);
            if (checkValidation()) {
              handleSubmit(e);
            }
         }}
          style={{ marginRight: 10 }}
          disabled={!value.amount1 || !value.packingAmount1 ||inputError1 || inputError2}
        >
          {buttonType}
        </Button>
      </div>



    </>
  );



  
};

///////////}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}


export default EgsTGarbageEdi2ndForm;
/*

  <th>EDIレコード識別番号</th><td><TextField id='ediRecordNo' label='EDIレコード識別番号' variant="outlined" type='text' name='ediRecordNo' halfWidth margin="dense" value={value.ediRecordNo} onChange={(e) => handleChange(e)} /></td>
  <th>予約登録マニフェスト修正権限コード</th><td><TextField id='reserveAuthorityCd' label='予約登録マニフェスト修正権限コード' variant="outlined" type='text' name='reserveAuthorityCd' halfWidth margin="dense" value={value.reserveAuthorityCd} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>登録担当者</th>
    <td><TextField id='registCharge' label='登録担当者' variant="outlined" type='text' name='registCharge' halfWidth margin="dense" value={value.registCharge} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='registCharge'
  label='登録担当者'
  variant="outlined"
  type='text'
  name='registCharge'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.registCharge}
>
  <MenuItem value={value.registCharge}>
    <em>登録担当者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
<th>引渡し担当者</th>
<td><TextField id='exhaustCharge' label='引渡し担当者' variant="outlined" type='text' name='exhaustCharge' halfWidth margin="dense" value={value.exhaustCharge} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='exhaustCharge'
  label='引渡し担当者'
  variant="outlined"
  type='text'
  name='exhaustCharge'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.exhaustCharge}
>
  <MenuItem value={value.exhaustCharge}>
    <em>引渡し担当者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
<th>数量確定者</th>
<td><TextField id='amountConfirmCd' label='数量確定者' variant="outlined" type='text' name='amountConfirmCd' fullWidth margin="dense" value={value.amountConfirmCd} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='amountConfirmCd'
  label='数量確定者'
  variant="outlined"
  type='text'
  name='amountConfirmCd'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.amountConfirmCd}
>
  <MenuItem value={value.amountConfirmCd}>
    <em>数量確定者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
</tr><tr>
<th>簡易情報取得フラグ</th>
<td><TextField id='simpleInfoFlg' label='簡易情報取得フラグ' variant="outlined" type='text' name='simpleInfoFlg' fullWidth margin="dense" value={value.simpleInfoFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='simpleInfoFlg'
  label='簡易情報取得フラグ'
  variant="outlined"
  type='text'
  name='simpleInfoFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.simpleInfoFlg}
>
  <MenuItem value={value.simpleInfoFlg}>
    <em>簡易情報取得フラグ</em>
  </MenuItem>
  <MenuItem value={'1:通常結果ファイル要求'}>1:通常結果ファイル要求</MenuItem>
  <MenuItem value={'2:簡易結果ファイル要求'}>2:簡易結果ファイル要求</MenuItem>
 </Select>
</td>
<th>最終処分事業場記載フラグ</th>
<td><TextField id='lastReportFlg' label='最終処分事業場記載フラグ' variant="outlined" type='text' name='lastReportFlg' halfWidth margin="dense" value={value.lastReportFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='lastReportFlg'
  label='最終処分事業場記載フラグ'
  variant="outlined"
  type='text'
  name='lastReportFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.lastReportFlg}
>
  <MenuItem value={value.lastReportFlg}>
    <em>最終処分事業場記載フラグ</em>
  </MenuItem>
  <MenuItem value={'0:委託契約書記載のとおり'}>0:委託契約書記載のとおり</MenuItem>
  <MenuItem value={'1:最終処分事業場（予定）を記載する'}>1:最終処分事業場（予定）を記載する</MenuItem>
 </Select>
</td>

<th>中間処理産業廃棄物情報管理方法フラグ</th>
<td><TextField id='middleProcessingInfoFlg' label='中間処理産業廃棄物情報管理方法フラグ' variant="outlined" type='text' name='middleProcessingInfoFlg' halfWidth margin="dense" value={value.middleProcessingInfoFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='middleProcessingInfoFlg'
  label='中間処理産業廃棄物情報管理方法フラグ'
  variant="outlined"
  type='text'
  name='middleProcessingInfoFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.middleProcessingInfoFlg}
>
  <MenuItem value={value.middleProcessingInfoFlg}>
    <em>中間処理産業廃棄物情報管理方法フラグ</em>
  </MenuItem>
  <MenuItem value={'1:当欄指定のとおり'}>1:当欄指定のとおり</MenuItem>
  <MenuItem value={'2:１次不要'}>2:１次不要</MenuItem>
  <MenuItem value={'3:帳簿記載のとおり'}>3:帳簿記載のとおり</MenuItem>
 </Select>
</td>

</tr><tr>
<th>予約登録発行件数</th><td><TextField id='reserveCnt' label='予約登録発行件数' variant="outlined" type='number' name='reserveCnt' halfWidth margin="dense" value={value.reserveCnt} onChange={(e) => handleChange(e)} /></td>
<th>有害物質情報件数</th><td><TextField id='toxicSubstanceCnt' label='有害物質情報件数' variant="outlined" type='number' name='toxicSubstanceCnt' halfWidth margin="dense" value={value.toxicSubstanceCnt} onChange={(e) => handleChange(e)} /></td>
<th>収集運搬情報件数</th><td><TextField id='transportInfoCnt' label='収集運搬情報件数' variant="outlined" type='number' name='transportInfoCnt' halfWidth margin="dense" value={value.transportInfoCnt} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
<th>最終処分情報件数</th><td><TextField id='lastProcessingCnt' label='最終処分情報件数' variant="outlined" type='number' name='lastProcessingCnt' halfWidth margin="dense" value={value.lastProcessingCnt} onChange={(e) => handleChange(e)} /></td>
<th>連絡番号情報件数</th><td><TextField id='contractCnt' label='連絡番号情報件数' variant="outlined" type='number' name='contractCnt' halfWidth margin="dense" value={value.contractCnt} onChange={(e) => handleChange(e)} /></td>
<th>備考情報件数</th><td><TextField id='memoInfoCnt' label='備考情報件数' variant="outlined" type='number' name='memoInfoCnt' halfWidth margin="dense" value={value.memoInfoCnt} onChange={(e) => handleChange(e)} /></td>
<th>中間処理産業廃棄物情報件数</th><td><TextField id='middleProcessingInfoCnt' label='中間処理産業廃棄物情報件数' variant="outlined" type='number' name='middleProcessingInfoCnt' halfWidth margin="dense" value={value.middleProcessingInfoCnt} onChange={(e) => handleChange(e)} /></td>





</tr><tr>
  <th>排出事業場電話番号</th><td><TextField id='exhaust' label='排出事業場電話番号' variant="outlined" type='text' name='exhaustPhone' halfWidth margin="dense" value={value.exhaustPhone} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場郵便番号</th><td><TextField id='exhaustZip' label='排出事業場郵便番号' variant="outlined" type='text' name='exhaustZip' halfWidth margin="dense" value={value.exhaustZip} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>排出事業場所在地１</th><td><TextField id='exhaustAdr1' label='排出事業場所在地１' variant="outlined" type='text' name='exhaustAdr1' halfWidth margin="dense" value={value.exhaustAdr1} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場所在地２</th><td><TextField id='exhaustAdr2' label='排出事業場所在地２' variant="outlined" type='text' name='exhaustAdr2' halfWidth margin="dense" value={value.exhaustAdr2} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>排出事業場所在地３</th><td><TextField id='exhaustAdr3' label='排出事業場所在地３' variant="outlined" type='text' name='exhaustAdr3' halfWidth margin="dense" value={value.exhaustAdr3} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場所在地４</th><td><TextField id='exhaustAdr4' label='排出事業場所在地４' variant="outlined" type='text' name='exhaustAdr4' halfWidth margin="dense" value={value.exhaustAdr4} onChange={(e) => handleChange(e)} /></td>


<th>処分業者郵便番号</th><td><TextField id='disposalZip' label='処分業者郵便番号' variant="outlined" type='text' name='disposalZip' halfWidth margin="dense" value={value.disposalZip} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者所在地１</th><td><TextField id='disposalAdr1' label='処分業者所在地１' variant="outlined" type='text' name='disposalAdr1' halfWidth margin="dense" value={value.disposalAdr1} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者所在地２</th><td><TextField id='disposalAdr2' label='処分業者所在地２' variant="outlined" type='text' name='disposalAdr2' halfWidth margin="dense" value={value.disposalAdr2} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者所在地３</th><td><TextField id='disposalAdr3' label='処分業者所在地３' variant="outlined" type='text' name='disposalAdr3' halfWidth margin="dense" value={value.disposalAdr3} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者所在地４</th><td><TextField id='disposalAdr4' label='処分業者所在地４' variant="outlined" type='text' name='disposalAdr4' halfWidth margin="dense" value={value.disposalAdr4} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者電話番号</th><td><TextField id='disposal' label='処分業者電話番号' variant="outlined" type='text' name='disposalPhone' halfWidth margin="dense" value={value.disposalPhone} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者FAX</th><td><TextField id='disposalFax' label='処分業者FAX' variant="outlined" type='text' name='disposalFax' halfWidth margin="dense" value={value.disposalFax} onChange={(e) => handleChange(e)} /></td>


*/
