// Form.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getTraderAdmJwnet, getTraderAdmUser } from '../../lib/api/trader_adm';

import './app.css';
import { AuthContext } from '../../App';

// style
import {
  //  Button,
  //  TextField,
  //  MenuItem,
  //  Select,
    Box,
    Grid,
  //  Backdrop,
  //  CircularProgress,
    Typography,
  //  InputLabel,
  //  RadioGroup,
  //  FormControlLabel,
  //  Radio,
  //  Checkbox
  } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '120ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },

  title_box2: {
    background: "rgb(224, 224, 224)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },

}));

const EgsMLinkageNewForm = (props) => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const { handleBack,       handleChange, handleSubmit, value, buttonType, setValue } = props;

//  const [dataListT01, setDataListT01] = useState([]);
  const [dataListT01, setDataListT01] = useState([]);
  const [dataListT02, setDataListT02] = useState([]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
////     setOpen(!open);
      //      const dataListT01 = await getEgsMTraderList();
////      const dataListT01 = await getEgsMTraderList();
//      console.log(dataListT01.data);
////      setDataListT01(dataListT01.data);
//      const dataListT02 = await getTraderSelList();
//      setDataListT02(dataListT02.data);
////     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const jwnetChange1 = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

     if (e.target.value.length == 7) {
        try {
         setOpen(!open);
          const dataListT01 = await getTraderAdmJwnet(e.target.value);
          setDataListT01(dataListT01.data);
         setOpen(false);
        } catch (e) {
          console.log(e);
        }
      }
    };
    
  const jwnetChange2 = async (e) => {
//    value.jwnetNo2 = e.target.value;
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    if (e.target.value.length == 7) {
      try {
        setOpen(!open);
        const dataListT02 = await getTraderAdmJwnet(e.target.value);
        setDataListT02(dataListT02.data);
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const traderChange2 = async (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    
    if (e.target.value.length == 12) {
      try {
        setOpen(!open);
          const dataListT02 = await getTraderAdmUser(e.target.value);
          setDataListT02(dataListT02.data);
        setOpen(false);
        } catch (e) {
          console.log(e);
        }
      }
    };

if (currentUser.traderKbn == '1111111111') {  // 管理者

//  const traderName = currentUser.traderName

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>    {/*  egs_m_reserve_sets  */}
          キャンセル
        </Button>
      </div>

      <form className={classes.root} noValidate autoComplete='off'>
      <table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者番号1
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo1' hiddenLabel variant="outlined" type='text' name='jwnetNo1' halfWidth margin="dense" 
//              onChange={(e) => handleChange(e)} 
              onChange={(e) => jwnetChange1(e)} 
              value={value.jwnetNo1} />  {/*fullWidth */}

            </Box>
          </Grid>
        </Grid>
      </div>

</tr><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者情報1
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              
            <Select id='egsMTraderId1' label='事業場１' variant="standard" type='text' name='egsMTraderId1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId1} >
              {dataListT01.map((dataListT01) => (
                <MenuItem
                  key={dataListT01.id}
                  value={dataListT01.id}
                >
                  {dataListT01.traderDispName} : {dataListT01.phone} : {dataListT01.address} : {dataListT01.traderKbnName} : { dataListT01.jwnetNo} : { dataListT01.traderCd}
                </MenuItem>
              ))}

              </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

</tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者番号2
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo2' hiddenLabel variant="outlined" type='text' name='jwnetNo2' halfWidth margin="dense" 
//              onChange={(e) => handleChange(e)} 
              onChange={(e) => jwnetChange2(e)} 
              value={value.jwnetNo2} />  {/*fullWidth */}

            </Box>
          </Grid>
        </Grid>
      </div>

</tr><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場CD2　　　　　　　（加入者番号がない場合）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderCd2' hiddenLabel variant="outlined" type='text' name='traderCd2' halfWidth margin="dense" 
//              onChange={(e) => handleChange(e)} 
              onChange={(e) => traderChange2(e)} 
              value={value.traderCd2} />  {/*fullWidth */}

            </Box>
          </Grid>
        </Grid>
      </div>

</tr><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者情報2
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select id='egsMTraderId2' label='事業場２' variant="standard" type='text' name='egsMTraderId2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId2} >
              {dataListT02.map((dataListT02) => (
                <MenuItem
                  key={dataListT02.id}
                  value={dataListT02.id}
                >
                  {dataListT02.traderDispName} : {dataListT02.phone} : {dataListT02.address} : {dataListT02.traderKbnName} : { dataListT02.jwnetNo} : { dataListT02.traderCd}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table>
      </form>

      <div style={{ textAlign: 'right' }}>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}

        disabled={(!value.egsMTraderId1 || !value.egsMTraderId2)}  // 09/19

        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      </div>

    </>
  );

}else{                                        // 管理者以外
  
//  const traderName = currentUser.traderName

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>    {/*  egs_m_reserve_sets  */}
          キャンセル
        </Button>
      </div>

      <form className={classes.root} noValidate autoComplete='off'>
      <table><tr>

</tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者番号2
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo2' hiddenLabel variant="outlined" type='text' name='jwnetNo2' halfWidth margin="dense" 
//              onChange={(e) => handleChange(e)} 
              onChange={(e) => jwnetChange2(e)} 
              value={value.jwnetNo2} />  {/*fullWidth */}

            </Box>
          </Grid>
        </Grid>
      </div>

</tr><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場CD2　　　　　　　（加入者番号がない場合）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='traderCd2' hiddenLabel variant="outlined" type='text' name='traderCd2' halfWidth margin="dense" 
//              onChange={(e) => handleChange(e)} 
              onChange={(e) => traderChange2(e)} 
              value={value.traderCd2} />  {/*fullWidth */}

            </Box>
          </Grid>
        </Grid>
      </div>

</tr><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              加入者情報2
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select id='egsMTraderId2' label='事業場２' variant="standard" type='text' name='egsMTraderId2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.egsMTraderId2} >
              {dataListT02.map((dataListT02) => (
                <MenuItem
                  key={dataListT02.id}
                  value={dataListT02.id}
                >
                  {dataListT02.traderDispName} : {dataListT02.phone} : {dataListT02.address} : {dataListT02.traderKbnName} : { dataListT02.jwnetNo} : { dataListT02.traderCd}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table>
      </form>

      <div style={{ textAlign: 'right' }}>
      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}

        disabled={(!value.egsMTraderId1 || !value.egsMTraderId2)}  // 09/19

        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      </div>

    </>
  );
  
}  

};
export default EgsMLinkageNewForm;
