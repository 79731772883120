// /src/lib/api/permit.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getPermitList = () => {
  return client.get('/permits');
};

// 詳細
export const getPermitDetail = (id) => {
  return client.get(`/permits/${id}`);
};

// 事業場選択一覧
export const getPermitUser = (id) => {
  return client.get(`/permits/${id}/showuser`);
};

// 新規作成
// header情報を追加
export const createPermit = (params) => {
  return client.post('/permits', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updatePermit = (id, params) => {
  return client.patch(`/permits/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deletePermit = (id) => {
  return client.delete(`/permits/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
