import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, Box, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'

import FormBody from './GarbageEdiPdfDLForm';

const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function GarbageEdiPdfDL({ currentUser, isOpen, doClose, dataList }) {
  const [loading, setLoading] = useState(false);
  // チェックボックスの状態を管理するstateを追加
  const [selectedRows, setSelectedRows] = useState([]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const handleCancel = () => {
    doClose();
  };

  // チェックボックスが変更された時のハンドラー
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };
  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 200
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 200
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]
  
  return (
    // <Dialog
    //   open={isOpen}
    //   onClose={() => {}}
    //   TransitionComponent={Transition}
    //   keepMounted
    //   aria-labelledby="form-dialog-title"
    //   // maxWidth={"lg"}
    //   PaperComponent={PaperComponent}
    // >

    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'xl'}
    >

      <DialogTitle id="form-dialog-title">
        <h2>廃棄物実績報告PDF一括ダウンロード</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
      <Box sx={{ height: 520, width: '100%', maxWidth: '90vw' }}>
      {/* // DataGridPro内のcheckboxSelectionとonSelectionModelChangeを有効化 */}
        <DataGridPro
          sx={styles.grid}
          columns={cols}
          rows={dataList}
          density="compact"
          checkboxSelection
          onSelectionModelChange={handleSelectionModelChange}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          // onRowClick={handleRowClick}
        />
      </Box>
      {/* // 選択された行のデータのみをフィルタリングしてGarbageEdiPdfDLFormに渡す */}
      <p style={{ color: 'red', fontSize: '1.2em' }}>↑ 実績報告PDFを作成する廃棄物を選択して「PDFダウンロード」ボタンを押してください。
      全件選択の場合は処理に少々時間がかかりますので「PDFダウンロード」ボタンが活性化するまでお待ちください</p>
      <FormBody
        currentUser={currentUser}
        doClose={doClose}
        dataList={dataList.filter(row => selectedRows.includes(row.id))}
      />
      </DialogContent>
    </Dialog>
  );
}
export default GarbageEdiPdfDL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
