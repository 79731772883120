import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { getProcessFlowId } from '../../lib/api/process_flow';
import { getEgsTGarbageTmpGbt, updateEgsTGarbageTmp } from '../../lib/api/egs_t_garbage_tmp';

import QRReaderDialog from './QRReaderDialog';
import SpaceRow from '../commons/SpaceRow';

import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeUser } from '../../lib/api/charge';
import { updateEgsTGarbageTag } from '../../lib/api/egs_t_garbage_tag';
import { getEgsMTraderUser }  from '../../lib/api/egs_m_trader';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';

import { makeStyles,styled } from '@material-ui/core/styles';
import { Button, TextField, InputBase, MenuItem, Select, Box } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Typography from '@material-ui/core/Typography';
import FormControl from '@mui/material/FormControl';
import { transportMethodName } from '../../constants'

const useStyles = makeStyles((theme) => ({

}));

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'blue',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});

function MblEmissionUpdate() {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  // const [value, setValue] = useState({
  //   exhaustDate: 0,
  //   garbageTag: '',
  //   manifestNo: '',
  //   amount1: 0,
  //   unitName: '',
  //   packingName: '',
  //   packingAmount1: 0,
  //   memo1: '',    // memo
  //   exhaustChargeId: 0,
  //   exhaustChargeCd: '',
  //   exhaustChargeName: '',
  //   sect1CarNo: '',
  //   sect1TransportChargeId: 0,
  //   sect1TransportChargeCd: '',
  //   sect1TransportChargeName: '',
  //   secondFlg: '0',       // 2次マニフェストフラグ
  // });

  const query = useParams();
  const history = useHistory();

  const [dataListPacking, setDataListPacking] = useState([]); {/* 荷姿 */ }
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}

  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);
  // console.log("inputError",inputError1,inputError2)
  let cookie_uid = Cookies.get('_uid');
  // console.log("Cookie_uid",cookie_uid);
  let id;

  useEffect(() => {
    handleGetData(query);
  }, [query]);

  const handleGetData = async (query) => {
    const flowid = query.id.split('-');

    const res = await getEgsTGarbageTmpGbt(query.id);
     console.log("res_garbage_tag",res);
    id = res.data[0].id
    console.log("id",id);
    setValue({
      id: res.data[0].id,
      //reserveNo: res.data[0].reserveNo,
      reserveNo: query.id,
      reserveSubno: res.data[0].reserveSubno,
      reserveName: res.data[0].reserveName,
      manifestNo: '',
      arrangementNo: res.data[0].arrangementNo,
      reserveDate: res.data[0].reserveDate,
      exhaustId: res.data[0].exhaustId,
      exhaustCd: res.data[0].exhaustCd,
      exhaustChargeId: res.data[0].exhaustChargeId,
      exhaustChargeCd: res.data[0].exhaustChargeCd,
      sect1TransportId: res.data[0].sect1TransportId,
      sect1TransportCd: res.data[0].sect1TransportCd,
      sect1TransportChargeId: res.data[0].sect1TransportChargeId,
      sect1TransportChargeCd: res.data[0].sect1TransportChargeCd,
      sect1TransportMethodId: res.data[0].sect1TransportMethodId,
      sect1TransportMethodCd: res.data[0].sect1TransportMethodCd,
      sect1CarNo: res.data[0].sect1CarNo,
      destination1Id: res.data[0].destination1Id,
      destination1Cd: res.data[0].destination1Cd,
      destination1ChargeId: res.data[0].destination1ChargeId,
      destination1ChargeCd: res.data[0].destination1ChargeCd,
      sect2TransportId: res.data[0].sect2TransportId,
      sect2TransportCd: res.data[0].sect2TransportCd,
      sect2TransportChargeId: res.data[0].sect2TransportChargeId,
      sect2TransportChargeCd: res.data[0].sect2TransportChargeCd,
      sect2TransportMethodId: res.data[0].sect2TransportMethodId,
      sect2TransportMethodCd: res.data[0].sect2TransportMethodCd,
      sect2CarNo: res.data[0].sect2CarNo,
      destination2Id: res.data[0].destination2Id,
      destination2Cd: res.data[0].destination2Cd,
      destination2ChargeId: res.data[0].destination2ChargeId,
      destination2ChargeCd: res.data[0].destination2ChargeCd,
      sect3TransportId: res.data[0].sect3TransportId,
      sect3TransportCd: res.data[0].sect3TransportCd,
      sect3TransportChargeId: res.data[0].sect3TransportChargeId,
      sect3TransportChargeCd: res.data[0].sect3TransportChargeCd,
      sect3TransportMethodId: res.data[0].sect3TransportMethodId,
      sect3TransportMethodCd: res.data[0].sect3TransportMethodCd,
      sect3CarNo: res.data[0].sect3CarNo,
      destination3Id: res.data[0].destination3Id,
      destination3Cd: res.data[0].destination3Cd,
      destination3ChargeId: res.data[0].destination3ChargeId,
      destination3ChargeCd: res.data[0].destination3ChargeCd,
      sect4TransportId: res.data[0].sect4TransportId,
      sect4TransportCd: res.data[0].sect4TransportCd,
      sect4TransportChargeId: res.data[0].sect4TransportChargeId,
      sect4TransportChargeCd: res.data[0].sect4TransportChargeCd,
      sect4TransportMethodId: res.data[0].sect4TransportMethodId,
      sect4TransportMethodCd: res.data[0].sect4TransportMethodCd,
      sect4CarNo: res.data[0].sect4CarNo,
      destination4Id: res.data[0].destination4Id,
      destination4Cd: res.data[0].destination4Cd,
      destination4ChargeId: res.data[0].destination4ChargeId,
      destination4ChargeCd: res.data[0].destination4ChargeCd,
      sect5TransportId: res.data[0].sect5TransportId,
      sect5TransportCd: res.data[0].sect5TransportCd,
      sect5TransportChargeId: res.data[0].sect5TransportChargeId,
      sect5TransportChargeCd: res.data[0].sect5TransportChargeCd,
      sect5TransportMethodId: res.data[0].sect5TransportMethodId,
      sect5TransportMethodCd: res.data[0].sect5TransportMethodCd,
      sect5CarNo: res.data[0].sect5CarNo,
      destination5Id: res.data[0].destination5Id,
      destination5Cd: res.data[0].destination5Cd,
      destination5ChargeId: res.data[0].destination5ChargeId,
      destination5ChargeCd: res.data[0].destination5ChargeCd,
      middleProcessingTraderId: res.data[0].middleProcessingTraderId,
      middleProcessingTraderCd: res.data[0].middleProcessingTraderCd,
      middleProcessingTraderChargeId: res.data[0].middleProcessingTraderChargeId,
      middleProcessingTraderChargeCd: res.data[0].middleProcessingTraderChargeCd,
      lastProcessingTraderId: res.data[0].lastProcessingTraderId,
      lastProcessingTraderCd: res.data[0].lastProcessingTraderCd,
      lastProcessingTraderChargeId: res.data[0].lastProcessingTraderChargeId,
      lastProcessingTraderChargeCd: res.data[0].lastProcessingTraderChargeCd,
      processTrustTraderId: res.data[0].processTrustTraderId,
      processTrustTraderCd: res.data[0].processTrustTraderCd,
      middleWasteFlg: res.data[0].middleWasteFlg,
      lastPlaceFlg: res.data[0].lastPlaceFlg,
      egsMWasteclassId: res.data[0].egsMWasteclassId,
      wasteKindCd: res.data[0].wasteKindCd,
      wasteCd: res.data[0].wasteCd,
      egsMDisposalMethodId: res.data[0].egsMDisposalMethodId,
      disposalMethodCd: res.data[0].disposalMethodCd,
      otherMethod: res.data[0].otherMethod,
      egsMUnitId: res.data[0].egsMUnitId,
      unitCd: res.data[0].unitCd,
      egsMPackingId: res.data[0].egsMPackingId,
      packingCd: res.data[0].packingCd,
      egsMDatasheetId: res.data[0].egsMDatasheetId,
      datasheetCd: res.data[0].datasheetCd,
      registTraderId: res.data[0].registTraderId,
      registTraderCd: res.data[0].registTraderCd,
      approvalflg: res.data[0].approvalflg,
      effectiveflg: res.data[0].effectiveflg,

      exhaustDate: res.data[0].exhaustDate,
      exhaustName: res.data[0].exhaustName,
      sect1TransportName: res.data[0].sect1TransportName,
      sect2TransportName: res.data[0].sect2TransportName,
      sect3TransportName: res.data[0].sect3TransportName,
      sect4TransportName: res.data[0].sect4TransportName,
      sect5TransportName: res.data[0].sect5TransportName,
      middleProcessingTraderName: res.data[0].middleProcessingTraderName,
      lastProcessingTraderName: res.data[0].lastProcessingTraderName,
      processTrustTraderName: res.data[0].processTrustTraderName,
      destination1Name: res.data[0].destination1Name,
      destination2Name: res.data[0].destination2Name,
      destination3Name: res.data[0].destination3Name,
      destination4Name: res.data[0].destination4Name,
      destination5Name: res.data[0].destination5Name,
      exhaustChargeName: res.data[0].exhaustChargeName,
      sect1TransportChargeName: res.data[0].sect1TransportChargeName,
      sect2TransportChargeName: res.data[0].sect2TransportChargeName,
      sect3TransportChargeName: res.data[0].sect3TransportChargeName,
      sect4TransportChargeName: res.data[0].sect4TransportChargeName,
      sect5TransportChargeName: res.data[0].sect5TransportChargeName,
      middleProcessingChargeName: res.data[0].middleProcessingChargeName,
      lastProcessingChargeName: res.data[0].lastProcessingChargeName,
      destination1ChargeName: res.data[0].destination1ChargeName,
      destination2ChargeName: res.data[0].destination2ChargeName,
      destination3ChargeName: res.data[0].destination3ChargeName,
      destination4ChargeName: res.data[0].destination4ChargeName,
      destination5ChargeName: res.data[0].destination5ChargeName,
      // sect1TransportMethodName: transportMethodName[Number(res.data[0].sect1TransportMethodCd)],
      // sect2TransportMethodName: transportMethodName[Number(res.data[0].sect2TransportMethodCd)],
      // sect3TransportMethodName: transportMethodName[Number(res.data[0].sect3TransportMethodCd)],
      // sect4TransportMethodName: transportMethodName[Number(res.data[0].sect4TransportMethodCd)],
      // sect5TransportMethodName: transportMethodName[Number(res.data[0].sect5TransportMethodCd)],
      wasteKindName: res.data[0].wasteKindName,
      wasteName: res.data[0].wasteName,
      disposalMethodName: res.data[0].disposalMethodName,
      unitName: res.data[0].unitName,
      packingName: res.data[0].packingName,

      memo1: res.data[0].memo1,  // 備考1
      memo2: res.data[0].memo2,  // 備考2
      memo3: res.data[0].memo3,  // 備考3
      memo4: res.data[0].memo4,  // 備考4
      memo5: res.data[0].memo5,  // 備考5

      toxicCd01: res.data[0].toxicCd01,
      toxicCd02: res.data[0].toxicCd02,
      toxicCd03: res.data[0].toxicCd03,
      toxicCd04: res.data[0].toxicCd04,
      toxicCd05: res.data[0].toxicCd05,
      toxicCd06: res.data[0].toxicCd06,
      toxicCd07: res.data[0].toxicCd07,

      amount1: res.data[0].amount1,
      packingAmount1: res.data[0].packingAmount1,
    });

    const resC00 = await getChargeUser(res.data[0].exhaustCd);
    setDataListC00(resC00.data);
    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);

    console.log('value1',value);

  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    if (window.confirm(' 排出仮登録（マニフェスト情報作成）を更新します。よろしいですか？')) {
      e.preventDefault();
      // console.log("排出事業者CD",value.exhaustCd)
      const res = await getEgsMTraderUser(value.exhaustCd);

      // if (value.exhaustPasswd != res.data[0].exhaustPasswd){
      //   alert('排出事業者 承認コードが一致しません。確認して再入力ください。')

      // }else{

        // 追加
        // console.log("dataListC00",dataListC00);
        const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.id == value.exhaustChargeId });
        value.exhaustChargeCd = chargedataList00[0].chargeCd;
        value.exhaustChargeName = chargedataList00[0].chargeName;
        const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd == value.packingCd });
        // console.log("packingdataList",packingdataList);
        value.packingCd = packingdataList[0].packingCd;
        value.packingName = packingdataList[0].packingName;
        // console.log("value.exhaustChargeName",value.exhaustChargeName);
        // setchargedataList00(chargedataList00);
        // // console.log("chargedataList00",chargedataList00);

        // // console.log("dataListC01",dataListC01);
        // const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.id == value.sect1TransportChargeId });
        // value.sect1TransportChargeCd = chargedataList01[0].chargeCd;
        // value.sect1TransportChargeName = chargedataList01[0].chargeName;
        // // console.log("value.sect1TransportChargeName",value.sect1TransportChargeName);
        // setchargedataList01(chargedataList01);
        // // console.log("chargedataList01",chargedataList01);

        const params = generateParams();
        try {
          console.log("updateEgsTGarbageTmp",id);
          console.log("params",params);
          const res = await updateEgsTGarbageTmp(params.id,params);
          console.log("res_updateEgsTGarbageTmp",res)

          history.push(`/mbl_garbage_tmp/${params.exhaustCd}`);
        } catch (e) {
          console.log(e);
        }
      // };
    }
  };

  const generateParams = () => {
    const params = {
      id: value.id,
      exhaustCd: value.exhaustCd,
      exhaustChargeId: value.exhaustChargeId,
      exhaustChargeCd: value.exhaustChargeCd,
      exhaustChargeName: value.exhaustChargeName,
      exhaustDate: value.exhaustDate,
      amount1: value.amount1,
      packingAmount1: value.packingAmount1,
      packingCd: value.packingCd,
      packingName: value.packingName,
      sect1CarNo: value.sect1CarNo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      // memo1: value.memo1.replace(/,/g, '/'),
      // memo2: value.memo2.replace(/,/g, '/'),
      // memo3: value.memo3.replace(/,/g, '/'),
      // memo4: value.memo4.replace(/,/g, '/'),
      // memo5: value.memo5.replace(/,/g, '/'),
    };
    return params;
  };

  const [open, setOpen] = React.useState(false);
  const [qrcodeValue, setQrcodeValue] = React.useState();

  // console.log('value',value);

  const [errors, setErrors] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataListC00, setDataListC00] = useState([]); {/* 排出担当者 */ }
  const [dataListC01, setDataListC01] = useState([]); {/* 運搬担当者 */ }

  const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });

  var mbluser = Cookies.get('mbl_user').split(':');
  // console.log("mbluser",mbluser);

  // useEffect(() => {
  //   handleGetList(value.id);
  // }, [value.id]);

  // const handleGetList = async () => {
  //   try {
  //     const resC00 = await getChargeUser(value.exhaustCd);
  //     // console.log("resC00.data",resC00.data);
  //     setDataListC00(resC00.data);
  //     const resC01 = await getChargeUser(value.sect1TransportCd);
  //     // console.log("resC01.data",resC01.data);
  //     setDataListC01(resC01.data);

  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClicClose = (value) => {
    setOpen(false);
    setQrcodeValue(value);
    // handleGetList(value.id);
  };

  if (qrcodeValue) {
    // 区切り文字に「/」を指定
    var rtn = qrcodeValue.split( '/' );
    // 2番目の要素から3番目の要素まで取り出し（ドメイン）
    var rtn2 = rtn.slice(2, 3);
    // 3番目の要素から4番目の要素まで取り出し（廃棄物タグwaste）
    var rtn3 = rtn.slice(3, 4);
    // 4番目の要素から5番目の要素まで取り出し（ゴミタグ）
    var rtn4 = rtn.slice(4, 5);
    // console.log('rtn2',rtn2)
    // console.log('rtn3',rtn3)
    // console.log('rtn4',rtn4)
  };

  const [dialogopen, setDialogOpen] = React.useState(false);
  const handleOpen = () => {
      setDialogOpen(true);
  };
  const handleClose = () => {
      setDialogOpen(false);
  };

  return (
    <>
      <h1>排出仮登録（マニフェスト情報）修正</h1>
      <h2>{query.id}</h2>

      <SpaceRow height={10} />
      {/* <Button variant='contained'  startIcon={<CropFreeIcon />} color="primary" onClick={handleClickOpen} style={{ marginRight: 10, fontSize: '1.2em' }}>
         担当者QRコード読み込み
        </Button>
      <QRReaderDialog qrcodeValue={qrcodeValue} open={open} onClose={handleClicClose} /> */}

      <form className={classes.root}>
        <table>
          <tr>
            <th>排出事業者</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{value.exhaustName}</Typography>
            </td>
          </tr>
          <tr>
            <th>廃棄物種類</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{value.wasteName}</Typography>
            </td>
          </tr>
          {/* </table>
          <table> */}
          <tr>
            <th>排出担当者</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{value.exhaustChargeName}</Typography>
                　　　　　
                <FormControl sx={{ minWidth: 200 }}>
                <Select
                id='exhaustChargeId'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
                {dataListC00.map((dataListC00) => (
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>収集運搬担当者</th>
            <td>
                <Typography
                  variant="h7"
                  style={{ fontWeight: 700 }}>
                    {mbluser[2]}
                </Typography>
            </td>
          </tr>
        {/* </table>
        <table> */}
          <tr>
            <th>排出日</th>
            <td>
                {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{value.exhaustDate}</Typography> */}
                <TextField id='exhaustDate' variant="outlined" type='date' defaultValue={value.exhaustDate} name='exhaustDate' margin="dense" onChange={(e) => handleChange(e)}  value={value.exhaustDate} />
            </td>
          </tr>
          <tr>
            <th>数量・単位</th>
            <td>
              <TextField
                error={inputError1}
                inputProps={{ max: 99999.999, min:0.001, step:0.001 }}
                inputRef={inputRef1}
                defaultValue=""
                id='amount1'
                margin="dense"
                variant="outlined"
                type='number'
                name='amount1'
                helperText={inputRef1?.current?.validationMessage}
                onChange={(e) => handleChange(e)}
                value={value.amount1}
              />
              {/* <TextField id='amount1' variant="outlined" type='number' name='amount1' margin="dense" onChange={(e) => handleChange(e)}  value={value.amount1} /> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>　　{value.unitName}</Typography>
            </td>
          </tr>
          <tr>
            <th>荷姿・荷姿の数量</th>
            <td>
              <TextField
                error={inputError2}
                inputProps={{ max: 99999, min:1, step:1 }}
                inputRef={inputRef2}
                defaultValue=""
                id='packingAmount1'
                margin="dense"
                variant="outlined"
                type='number'
                name='packingAmount1'
                helperText={inputRef2?.current?.validationMessage}
                onChange={(e) => handleChange(e)}
                value={value.packingAmount1}
              />
              {/* <TextField id='packingAmount1' variant="outlined" type='number' name='packingAmount1' margin="dense" onChange={(e) => handleChange(e)} value={value.packingAmount1} /> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>　{value.packingName}　</Typography>
              <Select
                id='packingCd'
                variant="outlined"
                type='text'
                name='packingCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.packingCd}
                defaultValue={value.packingCd}
                // fullWidth
              >
                {dataListPacking.map((dataListPacking) => (
                  <MenuItem
                    key={dataListPacking.packingCd}
                    value={dataListPacking.packingCd}
                  >
                    {dataListPacking.packingName}
                  </MenuItem>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <th>運搬方法・車両番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(value.sect1TransportMethodCd)]}</Typography>
                {/* <TextField id='sect1TransportMethodName' variant="outlined" type='text' name='sect1TransportMethodName' margin="dense" onChange={(e) => handleChange(e)} value={dataList2.sect1TransportMethodName} /> */}
                {/* <Select id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                  value={value.sect1TransportMethodCd} >
                  {trans1dataList.map((trans1dataList) => (<MenuItem key={trans1dataList.transportMethodCd} value={trans1dataList.transportMethodCd} > {trans1dataList.transportMethodCd}:{trans1dataList.transportMethodName} </MenuItem>))}
                </Select>                　　 */}
                <TextField id='sect1CarNo' variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} />
            </td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
              <tr>
                <TextField fullWidth id='memo1' variant="outlined" type='text' name='memo1' label="備考1" margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} />
                </tr><tr>
                <TextField fullWidth id='memo2' variant="outlined" type='text' name='memo2' label="備考2" margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} />
                </tr><tr>
                <TextField fullWidth id='memo3' variant="outlined" type='text' name='memo3' label="備考3" margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} />
                </tr><tr>
                <TextField fullWidth id='memo4' variant="outlined" type='text' name='memo4' label="備考4" margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} />
                </tr><tr>
                <TextField fullWidth id='memo5' variant="outlined" type='text' name='memo5' label="備考5" margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} />
              </tr>
            </td>
          </tr>
        </table>
        </form>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant='contained' onClick={() => history.push(`/mbl_garbage_tmp/${value.exhaustCd}` )} style={{ marginRight: 30, fontSize: '1.2em' }}>
            戻る
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={(e) => handleSubmit(e)}
            style={{ marginRight: 10, fontSize: '1.2em' }}
            disabled={!value.amount1 ||inputError1 || inputError2}
          >
            仮登録（マニフェスト情報）更新
          </Button>
        </Box>

      {/* <FormBody
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={value}
        buttonType='登録（ゴミタグ作成）' /> */}
    </>
  );
}
export default MblEmissionUpdate;

