import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import QRCode from "qrcode.react"

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SpaceRow from '../commons/SpaceRow';
import EgsMLinkageEdit from "./EgsMLinkageEdit";
import EgsMLinkageCreat from "./EgsMLinkageCreat";
import EgsMChargeAdd from "../egs_m_charges/EgsMChargeAdd";
import EgsMChargeEdit from "../egs_m_charges/EgsMChargeEdit";
import ExtChargeQrCre from "../egs_m_linkage/ExtChargeQrCre";
import './app.css';

import { getChargeUser } from '../../lib/api/charge';
import { deleteEgsMCharge } from '../../lib/api/egs_m_charge';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  jaJP
} from '@mui/x-data-grid-pro'
import Br from '../pdf_components/atoms/Br';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '連携マスタ',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function LinkageListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
  // console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);
  const [dataList3, setDataList3] = useState([]);
  const [dataList4, setDataList4] = useState([]);
  const [open, setOpen] = React.useState(false);
  var res;
  let restraderCd;
  // const resdataList = dataList.filter(dataList => {
  //   return dataList.traderCd === currentUser.traderCd
  // })
  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);

  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };
  const handleQrOpen = () => {
    setQrOpen(true);
  };

  // const handleRowClick = (param, event) => {
  //   console.log(param.row);
  //   setDataList2(param.row);
  // };
  // 行の削除
  const delRows = () => {
    //    console.log('delRows',dataList2)
        handleDelete(dataList2);
  }
  // 行の削除
  const delChargeRows = () => {
    //    console.log('delRows',dataList2)
    handleChargeDelete(dataList3);
  }
  const handleChargeDelete = async (item) => {
    if (window.confirm(' 担当者「' + item.chargeName + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMCharge(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  }
  const handleRowClick = (param, event) => {
    // console.log("param.row",param.row);
    setDataList4(param.row);
    //setDataList2(param.row);
    handleGetList(param.row.traderCd2);
  };

  const handleGetList = async (traderCd) => {
    try {
      setOpen(!open);
        res = await getChargeUser(traderCd);
        setDataList2(res.data);
        let max_val = Math.max(res.data.          chargeCd)
        // console.log('res.data', res.data);
        setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleRowClickCharge = (param, event) => {
    // console.log(param.row);
    setDataList3(param.row);
    //handleGetList(param.row.traderCd2);
  };
  


  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    // console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const colsAdm = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70
    },
    {
      field: 'egsMTraderId1',
      headerName: '事業場ID1',
      hide: true,
      width: 80
    },
    {
      field: 'traderCd1',
      headerName: '事業場CD1',
      width: 150
    },
    {
      field: 'jwnetNo1',
      headerName: '加入者番号1',
      width: 100
    },
    {
      field: 'traderName1',
      headerName: '事業場名1',
      width: 300
    },
    {
      field: 'traderKbn1',
      headerName: '事業場区分1',
      hide: true,
      width: 100
    },
    {
      field: 'traderKbnName1',
      headerName: '事業場区分名1',
      width: 120
    },
    {
      field: 'egsMTraderId2',
      headerName: '事業場ID2',
      hide: true,
      width: 80
    },
    {
      field: 'traderCd2',
      headerName: '事業場CD2',
      width: 150
    },
    {
      field: 'jwnetNo2',
      headerName: '加入者番号2',
      width: 100
    },
    {
      field: 'traderName2',
      headerName: '事業場名2',
      width: 300
    },
    {
      field: 'phone2',
      headerName: '事業場電話2',
      width: 150
    },
    {
      field: 'traderKbn2',
      headerName: '事業場区分2',
      hide: true,
      width: 100
    },
    {
      field: 'traderKbnName2',
      headerName: '事業場区分名2',
      width: 120
    },
    {
      field: 'email2_1',
      headerName: 'メールアドレス2',
      hide: true,
      width: 300
    },
  // {
    //     field: 'emailFlg',
    //     headerName: '有効フラグ',
    //     width: 50
    // },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 100,
    //   getActions: () => [
    //     <GridActionsCellItem icon={<EditIcon />} label="Edit"/>,
    //     <GridActionsCellItem icon={<DeleteIcon />} label="Delete" />,
    //   ],
    // },
  ]

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70
    },
    {
      field: 'egsMTraderId1',
      headerName: '事業場ID1',
      hide: true,
      width: 80
    },
    {
      field: 'traderCd1',
      headerName: '事業場CD1',
      hide: true,
      width: 150
    },
    {
      field: 'jwnetNo1',
      headerName: '加入者番号1',
      hide: true,
      width: 100
    },
    {
      field: 'traderName1',
      headerName: '事業場名1',
      hide: true,
      width: 300
    },
    {
      field: 'traderKbn1',
      headerName: '事業場区分1',
      hide: true,
      width: 100
    },
    {
      field: 'traderKbnName1',
      headerName: '事業場区分名1',
      hide: true,
      width: 120
    },
    {
      field: 'egsMTraderId2',
      headerName: '事業場ID',
      hide: true,
      width: 80
    },
    {
      field: 'traderCd2',
      headerName: '連携事業場CD',
      width: 150
    },
    {
      field: 'jwnetNo2',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'traderName2',
      headerName: '連携事業場名',
      width: 300
    },
    {
      field: 'phone2',
      headerName: '連携事業場電話番号',
      width: 150
    },
    {
      field: 'traderKbn2',
      headerName: '事業場区分2',
      hide: true,
      width: 100
    },
    {
      field: 'traderKbnName2',
      headerName: '連携事業区分名',
      width: 200
    },
    {
      field: 'email2_1',
      headerName: 'メールアドレス2',
      hide: true,
      width: 300
    },
  ]

  const colsCharge = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'egsMTraderId',
      headerName: '事業場ID',
      width: 100
    },
    {
        field: 'traderCd',
        headerName: '事業場CD',
        width: 150
    },
    {
        field: 'traderName',
        headerName: '事業場名',
        width: 300
    },
    {
      field: 'chargeCd',
      headerName: '担当者CD',
      width: 150
    },
    {
      field: 'chargeName',
      headerName: '担当者名',
      width: 200
    },
    {
        field: 'chargeKana',
        headerName: '担当者名かな',
        width: 200
    },
    {
      field: 'email',
      headerName: 'メールアドレス',
      width:250
    },
    {
      field: 'effectiveName',
      headerName: '有効フラグ',
      width: 100,
//      type: 'boolean',
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 100,
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<EditIcon />} label="Edit"/>,
    //     <GridActionsCellItem key={params.id} icon={<DeleteIcon />} label="Delete" onClick={delRows}/>,
    //   ],
    // },
  ]


  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
    return (
      <>
        <div className={classes.container}>
        <Box sx={{ height: 414, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={colsAdm}
            rows={dataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        </div>
          <EgsMLinkageEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            processId={dataList2.id}
            dataList2={dataList2}
          />
          <EgsMLinkageCreat
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
            processId={dataList2.id}
            currentUser={currentUser}
          />
        <SpaceRow height={20} />
        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 400 }} onClick={handleCreOpen}>
              新規作成
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>削除</Button>
        </Box>
      </>
    );
  }else if ((currentUser.traderKbn == '0100000000')
          || (currentUser.traderKbn == '0001000000')
          || (currentUser.traderKbn == '0001001000')){   //事業場区分は収集運搬業者？
    return (
      <>
        <div className={classes.container}>
        <Box sx={{ height: 414, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={dataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        </div>
        <SpaceRow height={10} />
        　　　↑ 担当者を新規作成、編集、削除する事業場を選択してください
        <SpaceRow height={10} />
        <div className={classes.container}>
          <Box sx={{ height: 414, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={colsCharge}
              rows={dataList2}  // dataList
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClickCharge}
            />
          </Box>
          <EgsMChargeAdd
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
            dataList2={dataList4}
          />
          <EgsMChargeEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            dataList2={dataList3}
            processId={dataList3.id}
          />
          <ExtChargeQrCre
            isOpen={qrOpen}
            doClose={() => setQrOpen(false)}
            dataList2={dataList3}
            processId={dataList3.id}
          />
          <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 200 }} onClick={handleCreOpen} disabled={!dataList2[0]}>
              新規作成
            </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList3.id}>
              編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delChargeRows} disabled={!dataList3.id}>
              削除
            </Button>
             　注意：担当者を「削除」すると過去の廃棄物データの担当者が正しく表示されなくなります。
             　回避するには、「編集」にて有効フラグを無効へ更新してご対応ください。
             　　　
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleQrOpen} disabled={!dataList3.id || dataList3.traderKbn !== '1000000000'}>
              排出担当者ＱＲコード作成
            </Button>
          </Box>
        </div>
      </>
    );
  }
}
const styles = {
  grid: {
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default LinkageListTable;
