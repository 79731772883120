// /src/lib/api/egs_t_photo.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsTPhotoList = () => {
  return client.get('/egs_t_photos');
};


// 詳細（追加・更新用）
export const getEgsTPhotoDetail = (id) => {
  return client.get(`/egs_t_photos/${id}`);
};

// 廃棄物情報ID指定一覧
export const getEgsTPhotoGbgid = (id) => {
  return client.get(`/egs_t_photos/${id}/show_gbgid`);
};

// 新規作成
// header情報を追加
export const createEgsTPhoto = (params) => {
  return client.post('/egs_t_photos', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTPhoto = (id, params) => {
  return client.patch(`/egs_t_photos/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTPhoto = (id) => {
  return client.delete(`/egs_t_photos/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
