// /src/lib/api/receipt_disposal_adm_transport.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptDisposalAdmTransportList = () => {
  return client.get('/receipt_disposal_adm_transports');
};

// 事業場選択一覧
export const getReceiptDisposalAdmTransportUserDetail = (id) => {
  return client.get(`/receipt_disposal_adm_transports/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptDisposalAdmTransportUser = (id) => {
  return client.get(`/receipt_disposal_adm_transports/${id}/showuser`);
};


