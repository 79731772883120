import React, { useEffect, useState, useContext } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
//import { getProcessFlowList } from '../../lib/api/reserve_set';
//import { getEgsMProcessFlowList } from '../../lib/api/egs_m_process_flow';
//import { getProcessFlowList } from '../../lib/api/process_flow';
import { getProcessFlowList, getProcessFlowUser, getProcessFlowUser47 ,getProcessFlowGroup, getProcessFlowOperation } from '../../lib/api/process_flow';
//import { deleteEgsMProcessFlow } from '../../lib/api/egs_m_reserve_set';
import { deleteEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { getTraderAdmKbn } from '../../lib/api/trader_adm'

import { useHistory } from 'react-router-dom';
// style
//import { Button } from '@material-ui/core';
import { Button,Backdrop,CircularProgress,makeStyles,Box } from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
// component
import ProcessFlowListTable from './ProcessFlowListTable';
import RsvQRReaderDialog from './RsvQRReaderDialog';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const ProcessFlowList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [dataTrdList, setDataTrdList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [qrcodeValue, setQrcodeValue] = React.useState();
  let res;
  let restrd;

  useEffect(() => {
//    handleGetList();    // 初期表示は0件（検索しない）とする
  }, []);

  const [dataList2, setDataList2] = useState({});

  const handleRowClick = (param, event) => {
    // console.log("param.row",param.row);
    // setDataList2(param.row);
    // // console.log("dataList2",dataList2);
    handleGetList2(param.row.traderCd);
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 120
    },
    {
      field: 'traderCd',
      headerName: '事業場CD',
      width: 120
    },
    {
      field: 'traderName',
      headerName: '収集運搬業者',
      width: 300
    },
    {
      field: 'zip',
      headerName: '郵便番号',
      width: 100
    },
    {
      field: 'prefectureName',
      headerName: '都道府県',
      width: 100
    },
    {
      field: 'address',
      headerName: '住所（市区町村）',
      width: 150
    },
    {
      field: 'address2',
      headerName: '住所（町域）',
      width: 200
    },
    {
      field: 'address3',
      headerName: '住所（丁目・番地以降）',
      width: 200
    },
    {
      field: 'phone',
      headerName: '連絡先電話番号',
      width: 150
    }
  ]

  const handleGetList = async () => {
    try {
      setOpen(!open);
      
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
/*
        // res = await getProcessFlowList();
        restrd = await getTraderAdmKbn('0100000000');
        // console.log("restrd",restrd);
        setDataTrdList(restrd.data);
        // console.log("dataTrdList",dataTrdList);
*/
          res = await getProcessFlowOperation('_' + currentUser.traderKbn);


      }else{                                       //事業場区分は管理者以外？
        if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ管理者？
//          res = await getProcessFlowGroup(currentUser.traderCd);
          res = await getProcessFlowOperation(currentUser.traderCd + '_' + currentUser.traderKbn);
        }else{                                       //事業場区分は管理者以外？
          if (currentUser.traderKbn == '0001001000' ||
              currentUser.traderKbn == '0001000000'){  //事業場区分は中間処理・排出事業者？
//            res = await getProcessFlowUser47(currentUser.traderCd);
            res = await getProcessFlowOperation(currentUser.traderCd + '_' + currentUser.traderKbn);
          }else{                                       //事業場区分は管理者以外？
//            res = await getProcessFlowUser(currentUser.traderCd);
//            res = await getProcessFlowOperation('400293810220_0100000000_0926635665__');
            res = await getProcessFlowOperation(currentUser.traderCd + '_' + currentUser.traderKbn);
          }
        }
      }

//      res = await getProcessFlowOperation(currentUser.traderCd + '_' + currentUser.traderKbn);
      // console.log(res.data);
//      setDataList(res.data);
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleGetList2 = async (traderCd) => {
    try {
      setOpen(!open);
        res = await getProcessFlowUser(traderCd);
        setDataList(res.data);
        setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {
    if (window.confirm(' 処理フロー「' + item.reserveName + '」を削除します。よろしいですか？')) {
    // console.log('click', item.id);
      try {
        const res = await deleteEgsMProcessFlow(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  // const timer = 60000    // ミリ秒で間隔の時間を指定
  // window.addEventListener('load',function(){
  //   setInterval('location.reload()',timer);
  // });

  const [dialogopen, setDialogOpen] = React.useState(false);
  const handleOpen = () => {
      setDialogOpen(true);
  };
  const handleClose = () => {
      setDialogOpen(false);
  };

  const handleClickOpen = () => {
    // setOpen(true);
    setDialogOpen(true);
  };

  const handleClicClose = (value) => {
    // setOpen(false);
    setDialogOpen(false);
    setQrcodeValue(value);
  };

  if (qrcodeValue) {

    // 区切り文字に「/」を指定
    var rtn = qrcodeValue.split( '/' );
    // 2番目の要素から3番目の要素まで取り出し（ドメイン）
    var rtn2 = rtn.slice(2, 3);
    // 3番目の要素から4番目の要素まで取り出し（廃棄物タグwaste）
    var rtn3 = rtn.slice(3, 4);
    // 4番目の要素から5番目の要素まで取り出し（ゴミタグ）
    var rtn4 = rtn.slice(4, 5);

    // console.log('rtn2',rtn2)
    // console.log('rtn3',rtn3)
    // console.log('rtn4',rtn4)

    history.push('/ds_reserve_sets/'+ rtn4 );

  }

  if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
    return (
      <div>
{/*}        
        <h1>処理フロー・排出登録（システム運用管理者）</h1>
        <h4>処理フローを表示する収集運搬業者を選択下さい ↓</h4>
*/}
        <div className={classes.container}>
{/*}
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={dataTrdList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
*/}
      </div>
          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <SpaceRow height={20} />
{/*}          <h4>【処理フロー一覧】</h4>  */}
          <h1>処理フロー一覧</h1>
          <ProcessFlowListTable
            dataList={dataList}
            handleDelete={handleDelete}
            currentUser={currentUser}
          />
      </div>
    );
  }else if ((currentUser.traderKbn == '0001001000') ||
            (currentUser.traderKbn == '0001000000')) {   //事業場区分は中間処理・排出事業者？
    return (
      <div>
        <h1>処理フロー・排出登録（２次マニフェスト）</h1>

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <SpaceRow height={20} />
          <ProcessFlowListTable
            dataList={dataList}
            handleDelete={handleDelete}
            currentUser={currentUser}
          />

      </div>
    );
  }else{
    return (
      <div>
        <h1>処理フロー一覧</h1>

          <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <SpaceRow height={20} />
          <ProcessFlowListTable
            dataList={dataList}
            handleDelete={handleDelete}
            currentUser={currentUser}
          />

      </div>
    );
  }
};
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default ProcessFlowList;
