// Form.jsx
//import React from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Checkbox, MenuItem, Select } from '@material-ui/core';
import './app.css';

import React, { useState, useEffect } from 'react';

import { useRef } from 'react';

// style
import {
  //  Button,
  //  TextField,
  //  MenuItem,
  //  Select,
    Box,
    Grid,
  //  Backdrop,
  //  CircularProgress,
    Typography,
  //  InputLabel,
  //  RadioGroup,
  //  FormControlLabel,
  //  Radio,
  //  Checkbox
  } from '@material-ui/core';
  //import { Checkbox, MenuItem, Select } from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';
//import { getEgsMCompanyList } from '../../lib/api/egs_m_company';
import { getEgsMPrefectureList } from '../../lib/api/egs_m_prefecture';
//import { getEgsMAddressList } from '../../lib/api/egs_m_address';
import { getEgsMGroupList } from '../../lib/api/egs_m_group';
import { getBusinessTypeList } from '../../lib/api/business_type';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },



  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },

  title_box2: {
    background: "rgb(224, 224, 224)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },


}));


const EgsMCompanyForm = (props) => {
  const classes = useStyles();

/*    6/1 必須入力チェック。yupのインストールが必要…

  const basicSchema = Yup.object().shape({
    checkBox: Yup.boolean()
        .oneOf([true], 'チェックが必要です'),
    jwnetEdipass: Yup.string()
        .required('必須項目です'),
    pullDown: Yup.string()
        .oneOf(['one', 'two', 'three'], 'いずれかを選択してください'),
});

*/
  const history = useHistory();
  const { handleChange, zipChange, handleSubmit, value, buttonType } = props;

  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);

  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}

  useEffect(() => {
    handleGetList();
  }, []);

  const [dataListPrefecture,setDataListPrefecture] = useState([]);  {/* 都道府県 */}
//  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  const [dataListGroup,setDataListGroup] = useState([]);  {/* グループ */}
  const [dataListBusiness,setDataListBusiness] = useState([]);  {/* 職種 */}

  const handleGetList = async () => {
    try {
     setOpen(!open);
      const dataListPrefecture = await getEgsMPrefectureList();
      setDataListPrefecture(dataListPrefecture.data);
//    // console.log('prefectureCd',value.prefecureCd,'dataListPrefecure',dataListPrefecure.data);
      const dataListGroup = await getEgsMGroupList();
      setDataListGroup(dataListGroup.data);

      const dataListBusiness = await getBusinessTypeList();
      setDataListBusiness(dataListBusiness.data);


//      const dataListAddress = await getEgsMAddressList();
//      setDataListAddress(dataListAddress.data);
//    // console.log('zip',value.zip,'dataListAddress',dataListAddress.data);

//      const resT01 = await getEgsMCompanyList();
//  //    // console.log(resT01.data);
//      setDataListT01(resT01.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


  // const handleChange2 = () => {
  //   if (inputRef.current) {
  //     const ref = inputRef.current;
  //     if (!ref.validity.valid) {
  //       setInputError(true);
  //     } else {
  //       setInputError(false);
  //     }
  //   }
  // };
  const handleChange2 = (e) => {
    handleChange(e);
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
        value.companyCd = value.jwnetNo;
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }
    // console.log('setvalue', value);
  };


const prefecturedataList = dataListPrefecture.filter(dataListPrefecture => { return dataListPrefecture.prefectureCd != '' });
//// console.log('prefecuredataList',prefecuredataList);

const groupdataList = dataListGroup.filter(dataListGroup => { return dataListGroup.groupCd != '' });

const businessdataList = dataListBusiness.filter(dataListBusiness => { return dataListBusiness.businessTypeCd != '' });

//const addressdataList = dataListAddress.filter(dataListAddress => { return dataListAddress.zip == value.zip });
//const addressdataList = dataListAddress.filter(dataListAddress => { return dataListAddress.zip != '' });
//// console.log('addressdataList',addressdataList);

  // 排出事業場　変更不可
//  // console.log('排出・運搬先・運搬先担当・最終<',value.egsMCompanyId,':',value.companyCd,':',value.chargeCd,'>')
//  // console.log('事業場<',value.id,'>')
//0511  // console.log('事業場<',value.companyCd,'>',currentUser.companyCd)
//  const companydataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.id });
//0511  const companydataList00 = dataListT01.filter(dataListT01 => { return dataListT01.companyCd == value.companyCd });
  //// console.log('companyDataList00',companydataList00);

  return (
    <>

<form className={classes.root} noValidate autoComplete='off'>


<table>
{/*}
  <tr><th>事業場CD</th><td><TextField id='companyCd' label='事業場CD' variant="outlined" type='text' name='companyCd' halfWidth margin="dense" value={value.companyCd} /> </td></tr>
*/}
  </table>

</form>

<table><tr>
{/*}
<div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNET加入者番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo' hiddenLabel variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense"
                error={inputError1}
                inputProps={{ maxLength: 8, pattern: "^[a-zA-Z0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
                inputRef={inputRef1}
                defaultValue=""
                helperText={inputRef1?.current?.validationMessage}
                onChange={(e) => handleChange2(e)}
                value={value.jwnetNo} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNETＥＤＩ利用確認キー
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetEdipass' hiddenLabel variant="outlined" type='text' name='jwnetEdipass' halfWidth margin="dense"
                error={inputError2}
                inputProps={{ maxLength: 9, pattern: "^[a-zA-Z0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
                inputRef={inputRef2}
                defaultValue=""
                helperText={inputRef2?.current?.validationMessage}
                onChange={(e) => handleChange2(e)}
                value={value.jwnetEdipass} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              JWNET事業場番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetSubno' hiddenLabel variant="outlined" type='text' name='jwnetSubno' halfWidth margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.jwnetSubno} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>
*/}
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
           <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              企業CD
              </Typography>
           </Box>
{/*}
            <Box className={classes.title_box2} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業場CD
              </Typography>
            </Box>
*/}
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}
              <TextField id='jwnetNo' hiddenLabel variant="standard" type='text' name='jwnetNo' halfWidth margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.jwnetNo} fullWidth />
*/}
              <SpaceRow height={10} />
              <Typography variant="h6" style={{ fontWeight: 700 }}> {value.companyCd} </Typography>
              <SpaceRow height={10} />
            </Box>
          </Grid>
        </Grid>
      </div>

</tr></table>
<tr></tr>

<grid></grid>

<div></div>
<div className={classes.container}></div>
<tr></tr>
<table><tr>



      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              企業名称
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='companyName' hiddenLabel variant="outlined" type='text' name='companyName' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.companyName} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              企業名称かな
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='companyKana' hiddenLabel variant="outlined" type='text' name='companyKana' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.companyKana} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>
{/*}
      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              企業表示名称
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='companyDispName' hiddenLabel variant="outlined" type='text' name='companyDispName' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.companyDispName} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>
*/}
      </tr></table><table><tr>
{/*}
      <div className={classes.container}>
  <Grid
    container
    spacing={0}
    className={classes.row}
  >
    <Grid item xs={4}>
     <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
        事業者区分
        </Typography>
    </Box>
    </Grid>
    <Grid item xs={6}>

         <Checkbox id='traderKbn1' label='排出　　' checked ={value.checkKbn1} //defaultChecked ={value.checkKbn1} // ={true} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn1}
            />
            排出　　　

         <Checkbox id='traderKbn2' label='収集運搬' // defaultChecked={false} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn2}
            />
            収集運搬　
            
         <Checkbox id='traderKbn3' label='積替保管' // defaultChecked={false} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn3}
            />
            積替保管
            
            <Checkbox id='traderKbn4' label='中間処理' // checked={false} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn4}
            />
            中間処理　
            
            <Checkbox id='traderKbn5' label='最終処分' // defaultChecked={false} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn5}
            />
            最終処分　
            
            <Checkbox id='traderKbn6' label='管理　　' // defaultChecked={false} // checked // defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)} value={value.traderKbn6}
            />
            管理　　
            
    </Grid>
  </Grid>
</div>

</tr></table><table><tr>
*/}
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
           <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              事業者区分
              </Typography>
          </Box>
          </Grid>
          <Grid item xs={6}>
{/*}            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='jwnetNo' hiddenLabel variant="outlined" type='text' name='jwnetNo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.jwnetNo} fullWidth />
      </Box>  */}
      {/*}
            <TextField id='traderKbn' hiddenLabel variant="outlined" type='text' name='traderKbn' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.traderKbn} />
            <Select
              id='traderKbn'
              label='事業者区分'
              variant="outlined"
              type='text'
              name='traderKbn'
              margin="dense"

              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}}

              onChange={(e) => handleChange(e)}
              value={value.traderKbn}
            >
              <MenuItem value={value.traderKbn}>
                <em>事業者区分</em>
              </MenuItem>
              <MenuItem value={'1000000000'}>排出事業者  :1000000000</MenuItem>
              <MenuItem value={'0100000000'}>収集運搬業者:0100000000</MenuItem>
              <MenuItem value={'0010000000'}>積替保管業者:0010000000</MenuItem>
              <MenuItem value={'0001000000'}>中間処分業者:0001000000</MenuItem>
              <MenuItem value={'0001001000'}>中間排出業者:0001001000</MenuItem>
              <MenuItem value={'0000100000'}>最終処分業者:0000100000</MenuItem>
              </Select>
    */}
               <Select
                id='traderKbn1' label='排出　　' variant="outlined" type='text' name='traderKbn1' margin="dense"
                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
                onChange={(e) => handleChange(e)} value={value.traderKbn1}
              >
                <MenuItem value={value.traderKbn1}>
                  <em>排出　　</em>
                </MenuItem>
                <MenuItem value={'0'}>　　　　</MenuItem>
                <MenuItem value={'1'}>排出　　</MenuItem>
              </Select>
              <Select
                id='traderKbn2' label='収集運搬' variant="outlined" type='text' name='traderKbn2' margin="dense"
                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
                onChange={(e) => handleChange(e)} value={value.traderKbn2}
              >
                <MenuItem value={value.traderKbn2}>
                  <em>収集運搬</em>
                </MenuItem>
                <MenuItem value={'0'}>　　　　</MenuItem>
                <MenuItem value={'1'}>収集運搬</MenuItem>
              </Select>
              <Select
                id='traderKbn3' label='積替保管' variant="outlined" type='text' name='traderKbn3' margin="dense"
                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
                onChange={(e) => handleChange(e)} value={value.traderKbn3}
              >
                <MenuItem value={value.traderKbn3}>
                  <em>積替保管</em>
                </MenuItem>
                <MenuItem value={'0'}>　　　　</MenuItem>
                <MenuItem value={'1'}>積替保管</MenuItem>
              </Select>
              <Select
                id='traderKbn4' label='中間処理' variant="outlined" type='text' name='traderKbn4' margin="dense"
                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
                onChange={(e) => handleChange(e)} value={value.traderKbn4}
              >
                <MenuItem value={value.traderKbn4}>
                  <em>中間処理</em>
                </MenuItem>
                <MenuItem value={'0'}>　　　　</MenuItem>
                <MenuItem value={'1'}>中間処理</MenuItem>
              </Select>
              <Select
                id='traderKbn5' label='最終処分' variant="outlined" type='text' name='traderKbn5' margin="dense"
                style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
                onChange={(e) => handleChange(e)} value={value.traderKbn5}
              >
                <MenuItem value={value.traderKbn5}>
                  <em>最終処分</em>
                </MenuItem>
                <MenuItem value={'0'}>　　　　</MenuItem>
                <MenuItem value={'1'}>最終処分</MenuItem>
            </Select>
            <Select
              id='traderKbn6' label='管理　　' variant="outlined" type='text' name='traderKbn6' margin="dense"
              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10}} 
              onChange={(e) => handleChange(e)} value={value.traderKbn6}
            >
              <MenuItem value={value.traderKbn6}>
                <em>管理　　</em>
              </MenuItem>
              <MenuItem value={'0'}>　　　　</MenuItem>
              <MenuItem value={'1'}>管理　　</MenuItem>
           </Select>

          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              所属団体
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='chargeDivision' hiddenLabel variant="outlined" type='text' name='chargeDivision' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
            value={value.chargeDivision} fullWidth />  */}

              <Select id='groupCd1' label='グループ１' variant="outlined" type='text' name='groupCd1' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd1}
               >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd2' label='グループ２' variant="outlined" type='text' name='groupCd2' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd2}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd3' label='グループ３' variant="outlined" type='text' name='groupCd3' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd3}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd4' label='グループ４' variant="outlined" type='text' name='groupCd4' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd4}
              >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

              <Select id='groupCd5' label='グループ５' variant="outlined" type='text' name='groupCd5' margin="dense"
//              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}
                style={{ marginTop: 2 ,marginBottom: 2 }} fullWidth onChange={(e) => handleChange(e)} value={value.groupCd5}
            >
                {groupdataList.map((groupdataList) => (
                  <MenuItem key={groupdataList.groupCd} value={groupdataList.groupCd} > {groupdataList.groupCd}:{groupdataList.groupName} </MenuItem>
                ))}
              </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

</tr></table><table><tr>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              郵便番号（数値7桁）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='zip' hiddenLabel variant="outlined" type='text' name='zip' halfWidth margin="dense"
              onChange={(e) => zipChange(e)}  // handleChange
              value={value.zip} fullWidth />
            </Box>
            <Typography variant="h7" style={{ fontWeight: 700 }}>住所　{value.zipAddress}　:　{value.zipAddress2}　:　{value.zipAddress3}</Typography>  
          </Grid>

        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              都道府県
              </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='prefectureCd' hiddenLabel variant="outlined" type='text' name='prefectureCd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.prefectureCd} fullWidth />
            </Box>
    </Grid>  */}

          <Select
              id='prefectureCd'
              label='都道府県'
              variant="outlined"
              type='text'
              name='prefectureCd'
              margin="dense"

              style={{ marginLeft: 10 , marginTop: 10 ,marginBottom: 10 }}

              onChange={(e) => handleChange(e)}
              value={value.prefectureCd}
            >
            {prefecturedataList.map((prefecturedataList) => (
              <MenuItem
                key={prefecturedataList.prefectureCd}
                value={prefecturedataList.prefectureCd}
              >
                {prefecturedataList.prefectureCd}:{prefecturedataList.prefectureName}
              </MenuItem>
            ))}
            </Select>

        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所1（市区町村）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address' hiddenLabel variant="outlined" type='text' name='address' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所2（町域）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address2' hiddenLabel variant="outlined" type='text' name='address2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address2} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所3（丁目・番地以降）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address3' hiddenLabel variant="outlined" type='text' name='address3' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address3} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              住所4（ビル名など）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='address4' hiddenLabel variant="outlined" type='text' name='address4' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.address4} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              連絡先電話番号（"-"使用可）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='phone' hiddenLabel variant="outlined" type='text' name='phone' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.phone} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              FAX番号（"-"使用可）
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='fax' hiddenLabel variant="outlined" type='text' name='fax' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.fax} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              メールアドレス
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='email1' hiddenLabel variant="outlined" type='text' name='email1' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.email1} fullWidth />

            {/* <Checkbox
              id='email1Flg'
              label='メール有効フラグ1'
              defaultChecked
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => handleChange(e)}
              value={value.email1Flg}
            />
            メール有効フラグ1 */}

            </Box>
          </Grid>
        </Grid>
      </div>
{/*}
      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              メモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>
*/}

      </tr></table>

      {/* <table><tr>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              環境ガードユーザフラグ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='egsUserFlg' hiddenLabel variant="outlined" type='text' name='egsUserFlg' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.egsUserFlg} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              使用EDIクライアント区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>

        <Select
          id='ediClientKbn'
          label='EDIクライアント区分'
          variant="outlined"
          type='text'
          name='ediClientKbn'
          margin="dense"

          style={{ marginTop: 10 ,marginBottom: 10 }}

          onChange={(e) => handleChange(e)}
          value={value.ediClientKbn}
        >
          <MenuItem value={value.ediClientKbn}>
            <em>EDIクライアント区分</em>
          </MenuItem>
          <MenuItem value={' '}> :　　</MenuItem>
          <MenuItem value={'1'}>1:EDIクライアント1</MenuItem>
          <MenuItem value={'2'}>2:EDIクライアント2</MenuItem>
         </Select>

            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              有効フラグ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='effectiveflg' hiddenLabel variant="outlined" type='text' name='effectiveflg' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.effectiveflg} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              認証タグID
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='certifyId' hiddenLabel variant="outlined" type='text' name='certifyId' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.certifyId} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </div>

      </tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              mk区分
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>

        <Select
          id='mkKbn3000'
          label='mk区分'
          variant="outlined"
          type='text'
          name='mkKbn3000'
          margin="dense"
          style={{ marginTop: 10 ,marginBottom: 10 }}
          onChange={(e) => handleChange(e)}
          value={value.mkKbn3000}
        >
          <MenuItem value={value.mkKbn3000}>
            <em>mk区分</em>
          </MenuItem>
          <MenuItem value={' '}> </MenuItem>
          <MenuItem value={'1'}>1</MenuItem>
          <MenuItem value={'2'}>2</MenuItem>
         </Select>

            </Box>
          </Grid>
        </Grid>
      </div>
      </tr></table> */}

      <table><tr>

      <div className={classes.container}>
  <Grid
    container
    spacing={0}
    className={classes.row}
  >
    <Grid item xs={4}>
      <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
        認可証事業者番号
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box bgcolor="primary.gray" color="primary.black" p={1}>
        <TextField id='permitComno' hiddenLabel variant="outlined" type='text' name='permitComno' halfWidth margin="dense"
        onChange={(e) => handleChange(e)}
        value={value.permitComno} fullWidth />
      </Box>
    </Grid>
  </Grid>
</div>

</tr></table><table><tr>

      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={4}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              システム利用
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Select
          id='effectiveflg'
          label='有効フラグ'
          variant="outlined"
          type='text'
          name='effectiveflg'
          margin="dense"
          style={{ marginLeft: 10, marginTop: 10 ,marginBottom: 10 }}
          onChange={(e) => handleChange(e)}
          value={value.effectiveflg}
        >
          <MenuItem value={value.effectiveflg}>
            <em>システム利用</em>
          </MenuItem>
          <MenuItem value={'1'}>1:有効</MenuItem>
          <MenuItem value={'9'}>9:無効</MenuItem>
         </Select>

          </Grid>
        </Grid>
      </div>


</tr></table>

<SpaceRow height={20} />
<div style={{ textAlign: 'right' }}>
<Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
{/*}      <Button variant='contained' onClick={() => history.push('/egs_m_companys_adm')}>
        戻る
  </Button>   */}
      </div>



    </>
  );
};
export default EgsMCompanyForm;
