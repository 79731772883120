import React, { useEffect, useState } from 'react';
import QRCode from "qrcode.react"

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';
import { statusName } from '../../constants'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#f5f5f5",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function EgsMChargeListTableForm(props) {
  const classes = useStyles();
  const { dataList2 } = props;

  const qrCodeUrl = "https://www.sdgs12.com/" + dataList2.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.chargeCd
  const qrCodeUrlPlus = qrCodeUrl + "/" + dataList2.exhaustPasswd
  
  // console.log('qrCodeUrl',qrCodeUrl);

  return (
    <>
            <Grid
              container
              spacing={0}
              className={classes.row}
            >
            <Grid item xs={7}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"left"}>
                <Typography variant="h6" gutterBottom component="div">
                  事業場ID：{dataList2.egsMTraderId}<br />
                  事業場CD：{dataList2.traderCd}<br />
                  事業場区分：{dataList2.traderKbn}<br />
                  事業場名：{dataList2.traderName}<br />
                  担当者CD：{dataList2.chargeCd}<br />
                  ふりがな：{dataList2.chargeKana}<br />
                  担当者名：{dataList2.chargeName}<br />
                  メールアドレス：{dataList2.email}<br />
                  有効フラグ：{dataList2.effectiveName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
                <h2>ＱＲコード</h2>
                <QRCode value={qrCodeUrlPlus} />
                <p>{qrCodeUrl}</p>
              </Box>
            </Grid>
          <Grid item xs />
          </Grid>
    </>
  )
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMChargeListTableForm;
