import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMLinkageList, deleteEgsMLinkage } from '../../lib/api/egs_m_linkage';
//import { deleteEgsMLinkage } from '../../lib/api/egs_m_linkage';
import { deleteEgsMLink } from '../../lib/api/egs_m_link';
//import { getEgsMLinkageList } from '../../lib/api/egs_m_linkage';
import { getLinkageList } from '../../lib/api/linkage';
import { getLinkageUser } from '../../lib/api/linkage';
//import { getEgsMLinkageUser } from '../../lib/api/egs_m_linkage';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import LinkageListTable from './LinkageListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LinkageList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
      const res = await getLinkageList();
      // console.log(res.data);
      setDataList(res.data);
     }else{                                       //事業場区分は管理者以外？
 //      const res = await getEgsMLinkageList();
      const res = await getLinkageUser(currentUser.traderCd);
//      const res = await getEgsMLinkageUser(currentUser.traderCd);    // ＮＧ
//      const res = await getLinkageList();
      // console.log(res.data);
      setDataList(res.data);
     }
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  // const handleDelete = async (item) => {

  //  alert(' ' + item.linkageName + 'を削除します。よろしいですか？')
  //   // console.log('click', item.id);
  //   try {
  //     const res = await deleteEgsMLinkage(item.id);
  //     // console.log(res.data);
  //     handleGetList();
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // };

  const handleDelete = async (item) => {
    if (window.confirm(' 連携マスタ「' + item.jwnetNo1 + ':' + item.jwnetNo2 + '」を削除します。よろしいですか？')) {
      try {
//        const res = await deleteEgsMLinkage(item.id);
        const res = await deleteEgsMLink(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>連携・担当者マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_linkagesSel')}
        style={{ marginRight: 10 }}
      >
        参照作成
      </Button> */}
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_linkagesNew')}
      >
        新規作成
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <LinkageListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default LinkageList;
