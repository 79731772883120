import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button } from '@material-ui/core';

import { createEgsMCharge, getEgsMChargeDetail } from '../../lib/api/egs_m_charge';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
import FormBody from './EgsMChargeNewForm';  // フォーム分割
//import { getCurrentUser } from '../../lib/api/auth';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMChargeNew({ isOpen, doClose, dataList2 , currentUser}) {  // processId  , currentUser

  const [loading, setLoading] = useState(false);


  const [value, setValue] = useState({
    egsMTraderId: 0,
    traderCd: '',
    traderName: '',
    chargeCd: '',
    chargeName: '',
    chargeKana: '',
    email: '',
    emailFlg: '0',
    ediKbn: 0,
    effectiveflg: '1',  //  新規作成時は有効とする　  '0',
  });

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();



  useEffect(() => {
    handleGetData(dataList2);
  }, [dataList2]);

//  const wkTraderId = dataList2.id;
//  const wkTraderCd = dataList2.traderCd;
//  const wkTraderName = dataList2.traderName;
  

  const handleGetData = async (dataList2) => {
    try {
      setLoading(true);

//        const dataListPasswd1 = await getPasswdList();  // 承認コード
//        setDataListPasswd1(dataListPasswd1.data);

//        const dataListPasswd2 = await getPasswdList();  // 承認コード
//        setDataListPasswd2(dataListPasswd2.data);

      setLoading(true);
      const resT02 = await getTraderUser(dataList2.traderCd);

      setValue({

//        egsMTraderId: wkTraderId,
//        traderCd: wkTraderCd,
//        traderName: wkTraderName,

//        egsMTraderId: dataList2.id,
//        traderCd: dataList2.traderCd,
//        traderName: dataList2.traderName,

        egsMTraderId: resT02.data[0].id,
        traderCd: resT02.data[0].traderCd,
        traderName: resT02.data[0].traderName,
        chargeCd: resT02.data[0].nextChargeCd,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };





    const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

    const handleSubmit = async (e) => {

    if (window.confirm(' 担当者「' + value.traderCd + ':' + value.traderName + ':' + value.chargeCd + '」を登録します。よろしいですか？')) {
      try {
        e.preventDefault();
        // 追加
        const params = generateParams();
        // console.log('params',params)
        try {
          const res = await createEgsMCharge(params);
          // console.log(res);
          handleCancel();
    //      history.push('/permit');  // egs_m_permits
          window.location.reload();
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      egsMTraderId: value.egsMTraderId,
      traderCd: value.traderCd,
      chargeCd: value.chargeCd,
      chargeName: value.chargeName,
      chargeKana: value.chargeKana,
      email: value.email,
      emailFlg: value.emailFlg,
      ediKbn: value.ediKbn,
      effectiveflg: value.effectiveflg,

    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者新規作成</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
//              handleBack();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          currentUser={currentUser}
          value={value}
          buttonType='作成' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMChargeNew;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
