import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";

import { updateEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { getProcessFlowId } from '../../lib/api/process_flow';
import FormBody from './EgsMProcessFlowForm4';
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMProcessFlowEdit4(props) {
  const { isOpen, doClose, dataList2 } = props;
  // console.log("dataList2",dataList2);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState({
    id: 0,
    reserveNo: 0,
    reserveSubno: '',
    reserveName: '',
    manifestNo: '',
    arrangementNo: '',
    reserveDate: '',
    exhaustId: 0,
    exhaustCd: '',
    exhaustChargeId: 0,
    exhaustChargeCd: '',
    sect1TransportId: 0,
    sect1TransportCd: '',
    sect1TransportChargeId: 0,
    sect1TransportChargeCd: '',
    sect1TransportMethodId: 0,
    sect1TransportMethodCd: '',
    sect1CarNo: '',
    destination1Id: 0,
    destination1Cd: '',
    destination1ChargeId: 0,
    destination1ChargeCd: '',
    sect2TransportId: 0,
    sect2TransportCd: '',
    sect2TransportChargeId: 0,
    sect2TransportChargeCd: '',
    sect2TransportMethodId: 0,
    sect2TransportMethodCd: '',
    sect2CarNo: '',
    destination2Id: 0,
    destination2Cd: '',
    destination2ChargeId: 0,
    destination2ChargeCd: '',
    sect3TransportId: 0,
    sect3TransportCd: '',
    sect3TransportChargeId: 0,
    sect3TransportChargeCd: '',
    sect3TransportMethodId: 0,
    sect3TransportMethodCd: '',
    sect3CarNo: '',
    destination3Id: 0,
    destination3Cd: '',
    destination3ChargeId: 0,
    destination3ChargeCd: '',
    sect4TransportId: 0,
    sect4TransportCd: '',
    sect4TransportChargeId: 0,
    sect4TransportChargeCd: '',
    sect4TransportMethodId: 0,
    sect4TransportMethodCd: '',
    sect4CarNo: '',
    destination4Id: 0,
    destination4Cd: '',
    destination4ChargeId: 0,
    destination4ChargeCd: '',
    sect5TransportId: 0,
    sect5TransportCd: '',
    sect5TransportChargeId: 0,
    sect5TransportChargeCd: '',
    sect5TransportMethodId: 0,
    sect5TransportMethodCd: '',
    sect5CarNo: '',
    destination5Id: 0,
    destination5Cd: '',
    destination5ChargeId: 0,
    destination5ChargeCd: '',
    middleProcessingTraderId: 0,
    middleProcessingTraderCd: '',
    middleProcessingChargeId: 0,
    middleProcessingChargeCd: '',
    lastProcessingTraderId: 0,
    lastProcessingTraderCd: '',
    lastProcessingChargeId: 0,
    lastProcessingChargeCd: '',
    processTrustTraderId: 0,
    processTrustTraderCd: '',
    middleWasteFlg: 0,
    lastPlaceFlg: '1',
    egsMWasteclassId: 0,
    wasteKindCd: '',
    wasteCd: '',
    egsMDisposalMethodId: 0,
    disposalMethodCd: '',
    otherMethod: '',
    egsMUnitId: 0,
    unitCd: '',
    egsMPackingId: 0,
    packingCd: '',
    egsMDatasheetId: 0,
    datasheetCd: '',
    registTraderId: 0,
    registTraderCd: '',
    approvalflg: '',
    effectiveflg: '',

    exhaustName: '',
    sect1TransportName: '',
    sect2TransportName: '',
    sect3TransportName: '',
    sect4TransportName: '',
    sect5TransportName: '',
    middleProcessingTraderName: '',
    lastProcessingTraderName: '',
    processTrustTraderName: '',
    destination1Name: '',
    destination2Name: '',
    destination3Name: '',
    destination4Name: '',
    destination5Name: '',
    exhaustChargeName: '',
    sect1TransportChargeName: '',
    sect2TransportChargeName: '',
    sect3TransportChargeName: '',
    sect4TransportChargeName: '',
    sect5TransportChargeName: '',
    middleProcessingChargeName: '',
    lastProcessingChargeName: '',
    destination1ChargeName: '',
    destination2ChargeName: '',
    destination3ChargeName: '',
    destination4ChargeName: '',
    destination5ChargeName: '',
    sect1TransportMethodName: '',
    sect2TransportMethodName: '',
    sect3TransportMethodName: '',
    sect4TransportMethodName: '',
    sect5TransportMethodName: '',
    wasteKindName: '',
    wasteName: '',
    disposalMethodName: '',
    unitName: '',
    packingName: '',

    memo: '',
    memo01: '',
    memo02: '',
    memo03: '',
    memo04: '',
    memo05: '',

    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicName01: '',
    toxicName02: '',
    toxicName03: '',
    toxicName04: '',
    toxicName05: '',
    toxicName06: '',

    processTrustTraderId: 0,
    processTrustTraderCd: '',
    processTrustTraderName: '',

  });

  useEffect(() => {
    handleGetData(dataList2);
  }, [dataList2.id]);

  // useEffect(() => {
  //   handleGetData(dataList2);
  // });

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();


  const handleGetData = async (dataList2) => {  //
    try {
      setLoading(true);
//0829      const res = await getEgsMProcessFlowDetail(processId);
//0829      const res = await getEgsMProcessFlowDetail(dataList2.id);
//      const res = await getProcessFlowId(processId);  // getProcessFlowDetail      取得してるがフォームに渡らない…
//0829      // console.log(res.data);
// console.log('dataList2',dataList2);
      setValue({
        id: dataList2.id,
        reserveNo: dataList2.reserveNo,
        reserveSubno: dataList2.reserveSubno,
        reserveName: dataList2.reserveName,
        manifestNo: dataList2.manifestNo,
        arrangementNo: dataList2.arrangementNo,
        reserveDate: dataList2.reserveDate,
        exhaustId: dataList2.exhaustId,
        exhaustCd: dataList2.exhaustCd,
        exhaustChargeId: dataList2.exhaustChargeId,
        exhaustChargeCd: dataList2.exhaustChargeCd,
        sect1TransportId: dataList2.sect1TransportId,
        sect1TransportCd: dataList2.sect1TransportCd,
        sect1TransportChargeId: dataList2.sect1TransportChargeId,
        sect1TransportChargeCd: dataList2.sect1TransportChargeCd,
        sect1TransportMethodId: dataList2.sect1TransportMethodId,
        sect1TransportMethodCd: dataList2.sect1TransportMethodCd,
        sect1CarNo: dataList2.sect1CarNo,
        destination1Id: dataList2.destination1Id,
        destination1Cd: dataList2.destination1Cd,
        destination1ChargeId: dataList2.destination1ChargeId,
        destination1ChargeCd: dataList2.destination1ChargeCd,
        sect2TransportId: dataList2.sect2TransportId,
        sect2TransportCd: dataList2.sect2TransportCd,
        sect2TransportChargeId: dataList2.sect2TransportChargeId,
        sect2TransportChargeCd: dataList2.sect2TransportChargeCd,
        sect2TransportMethodId: dataList2.sect2TransportMethodId,
        sect2TransportMethodCd: dataList2.sect2TransportMethodCd,
        sect2CarNo: dataList2.sect2CarNo,
        destination2Id: dataList2.destination2Id,
        destination2Cd: dataList2.destination2Cd,
        destination2ChargeId: dataList2.destination2ChargeId,
        destination2ChargeCd: dataList2.destination2ChargeCd,
        sect3TransportId: dataList2.sect3TransportId,
        sect3TransportCd: dataList2.sect3TransportCd,
        sect3TransportChargeId: dataList2.sect3TransportChargeId,
        sect3TransportChargeCd: dataList2.sect3TransportChargeCd,
        sect3TransportMethodId: dataList2.sect3TransportMethodId,
        sect3TransportMethodCd: dataList2.sect3TransportMethodCd,
        sect3CarNo: dataList2.sect3CarNo,
        destination3Id: dataList2.destination3Id,
        destination3Cd: dataList2.destination3Cd,
        destination3ChargeId: dataList2.destination3ChargeId,
        destination3ChargeCd: dataList2.destination3ChargeCd,
        sect4TransportId: dataList2.sect4TransportId,
        sect4TransportCd: dataList2.sect4TransportCd,
        sect4TransportChargeId: dataList2.sect4TransportChargeId,
        sect4TransportChargeCd: dataList2.sect4TransportChargeCd,
        sect4TransportMethodId: dataList2.sect4TransportMethodId,
        sect4TransportMethodCd: dataList2.sect4TransportMethodCd,
        sect4CarNo: dataList2.sect4CarNo,
        destination4Id: dataList2.destination4Id,
        destination4Cd: dataList2.destination4Cd,
        destination4ChargeId: dataList2.destination4ChargeId,
        destination4ChargeCd: dataList2.destination4ChargeCd,
        sect5TransportId: dataList2.sect5TransportId,
        sect5TransportCd: dataList2.sect5TransportCd,
        sect5TransportChargeId: dataList2.sect5TransportChargeId,
        sect5TransportChargeCd: dataList2.sect5TransportChargeCd,
        sect5TransportMethodId: dataList2.sect5TransportMethodId,
        sect5TransportMethodCd: dataList2.sect5TransportMethodCd,
        sect5CarNo: dataList2.sect5CarNo,
        destination5Id: dataList2.destination5Id,
        destination5Cd: dataList2.destination5Cd,
        destination5ChargeId: dataList2.destination5ChargeId,
        destination5ChargeCd: dataList2.destination5ChargeCd,
        middleProcessingTraderId: dataList2.middleProcessingTraderId,
        middleProcessingTraderCd: dataList2.middleProcessingTraderCd,
        middleProcessingChargeId: dataList2.middleProcessingChargeId,
        middleProcessingChargeCd: dataList2.middleProcessingChargeCd,
        lastProcessingTraderId: dataList2.lastProcessingTraderId,
        lastProcessingTraderCd: dataList2.lastProcessingTraderCd,
        lastProcessingChargeId: dataList2.lastProcessingChargeId,
        lastProcessingChargeCd: dataList2.lastProcessingChargeCd,
        processTrustTraderId: dataList2.processTrustTraderId,
        processTrustTraderCd: dataList2.processTrustTraderCd,
        middleWasteFlg: dataList2.middleWasteFlg,
        lastPlaceFlg: dataList2.lastPlaceFlg,
        egsMWasteclassId: dataList2.egsMWasteclassId,
        wasteKindCd: dataList2.wasteKindCd,
        wasteCd: dataList2.wasteCd,
        egsMDisposalMethodId: dataList2.egsMDisposalMethodId,
        disposalMethodCd: dataList2.disposalMethodCd,
        otherMethod: dataList2.otherMethod,
        egsMUnitId: dataList2.egsMUnitId,
        unitCd: dataList2.unitCd,
        egsMPackingId: dataList2.egsMPackingId,
        packingCd: dataList2.packingCd,
        egsMDatasheetId: dataList2.egsMDatasheetId,
        datasheetCd: dataList2.datasheetCd,
        registTraderId: dataList2.registTraderId,
        registTraderCd: dataList2.registTraderCd,
        approvalflg: dataList2.approvalflg,
        effectiveflg: dataList2.effectiveflg,

        exhaustName: dataList2.exhaustName,  // res.data.exhaustName,
        sect1TransportName: dataList2.sect1TransportName,
        sect2TransportName: dataList2.sect2TransportName,
        sect3TransportName: dataList2.sect3TransportName,
        sect4TransportName: dataList2.sect4TransportName,
        sect5TransportName: dataList2.sect5TransportName,
        middleProcessingTraderName: dataList2.middleProcessingTraderName,
        lastProcessingTraderName: dataList2.lastProcessingTraderName,  // res.data.lastProcessingTraderName,
        processTrustTraderName: dataList2.processTrustTraderName,
        destination1Name: dataList2.destination1Name,
        destination2Name: dataList2.destination2Name,
        destination3Name: dataList2.destination3Name,
        destination4Name: dataList2.destination4Name,
        destination5Name: dataList2.destination5Name,
        exhaustChargeName: dataList2.exhaustChargeName,
        sect1TransportChargeName: dataList2.sect1TransportChargeName,
        sect2TransportChargeName: dataList2.sect2TransportChargeName,
        sect3TransportChargeName: dataList2.sect3TransportChargeName,
        sect4TransportChargeName: dataList2.sect4TransportChargeName,
        sect5TransportChargeName: dataList2.sect5TransportChargeName,
        middleProcessingChargeName: dataList2.middleProcessingChargeName,
        lastProcessingChargeName: dataList2.lastProcessingChargeName,
        destination1ChargeName: dataList2.destination1ChargeName,
        destination2ChargeName: dataList2.destination2ChargeName,
        destination3ChargeName: dataList2.destination3ChargeName,
        destination4ChargeName: dataList2.destination4ChargeName,
        destination5ChargeName: dataList2.destination5ChargeName,
        sect1TransportMethodName: dataList2.sect1TransportMethodName,
        sect2TransportMethodName: dataList2.sect2TransportMethodName,
        sect3TransportMethodName: dataList2.sect3TransportMethodName,
        sect4TransportMethodName: dataList2.sect4TransportMethodName,
        sect5TransportMethodName: dataList2.sect5TransportMethodName,
        wasteKindName: dataList2.wasteKindName,
        wasteName: dataList2.wasteName,
        disposalMethodName: dataList2.disposalMethodName,
        unitName: dataList2.unitName,
        packingName: dataList2.packingName,

        memo: dataList2.memo,
        memo1: dataList2.memo1,
        memo2: dataList2.memo2,
        memo3: dataList2.memo3,
        memo4: dataList2.memo4,
        memo5: dataList2.memo5,

        toxicCd01: dataList2.toxicCd01,
        toxicCd02: dataList2.toxicCd02,
        toxicCd03: dataList2.toxicCd03,
        toxicCd04: dataList2.toxicCd04,
        toxicCd05: dataList2.toxicCd05,
        toxicCd06: dataList2.toxicCd06,
        toxicName01: dataList2.toxicName01,
        toxicName02: dataList2.toxicName02,
        toxicName03: dataList2.toxicName03,
        toxicName04: dataList2.toxicName04,
        toxicName05: dataList2.toxicName05,
        toxicName06: dataList2.toxicName06,

        processTrustTraderId: dataList2.processTrustTraderId, 
        processTrustTraderCd: dataList2.processTrustTraderCd, 
        processTrustTraderName: dataList2.processTrustTraderName, 
      });

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    // console.log('setvalue', value);
  };

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params',params);
    if (window.confirm(' 処理フロー「' + value.reserveName + ':' + value.reserveNo + '」を更新します。よろしいですか？')) {
      try {
        setOpen(!open);
        const res = await updateEgsMProcessFlow(value.id, params);  //
        // console.log('res',res);
        handleCancel();
        history.push('/egs_m_process_flows');
//        window.location.reload();
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      reserveNo: value.reserveNo,
      reserveSubno: value.reserveSubno,
      reserveName: value.reserveName,
      manifestNo: value.manifestNo,
      arrangementNo: value.arrangementNo,
      reserveDate: value.reserveDate,
      exhaustId: value.exhaustId,
      exhaustCd: value.exhaustCd,
      exhaustChargeId: value.exhaustChargeId,
      exhaustChargeCd: value.exhaustChargeCd,
      sect1TransportId: value.sect1TransportId,
      sect1TransportCd: value.sect1TransportCd,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,
      sect1TransportMethodId: value.sect1TransportMethodId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1Cd: value.destination1Cd,
      destination1ChargeId: value.destination1ChargeId,
      destination1ChargeCd: value.destination1ChargeCd,
      sect2TransportId: value.sect2TransportId,
      sect2TransportCd: value.sect2TransportCd,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportChargeCd: value.sect2TransportChargeCd,
      sect2TransportMethodId: value.sect2TransportMethodId,
      sect2TransportMethodCd: value.sect2TransportMethodCd,
      sect2CarNo: value.sect2CarNo,
      destination2Id: value.destination2Id,
      destination2Cd: value.destination2Cd,
      destination2ChargeId: value.destination2ChargeId,
      destination2ChargeCd: value.destination2ChargeCd,
      sect3TransportId: value.sect3TransportId,
      sect3TransportCd: value.sect3TransportCd,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportChargeCd: value.sect3TransportChargeCd,
      sect3TransportMethodId: value.sect3TransportMethodId,
      sect3TransportMethodCd: value.sect3TransportMethodCd,
      sect3CarNo: value.sect3CarNo,
      destination3Id: value.destination3Id,
      destination3Cd: value.destination3Cd,
      destination3ChargeId: value.destination3ChargeId,
      destination3ChargeCd: value.destination3ChargeCd,
      sect4TransportId: value.sect4TransportId,
      sect4TransportCd: value.sect4TransportCd,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportChargeCd: value.sect4TransportChargeCd,
      sect4TransportMethodId: value.sect4TransportMethodId,
      sect4TransportMethodCd: value.sect4TransportMethodCd,
      sect4CarNo: value.sect4CarNo,
      destination4Id: value.destination4Id,
      destination4Cd: value.destination4Cd,
      destination4ChargeId: value.destination4ChargeId,
      destination4ChargeCd: value.destination4ChargeCd,
      sect5TransportId: value.sect5TransportId,
      sect5TransportCd: value.sect5TransportCd,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportChargeCd: value.sect5TransportChargeCd,
      sect5TransportMethodId: value.sect5TransportMethodId,
      sect5TransportMethodCd: value.sect5TransportMethodCd,
      sect5CarNo: value.sect5CarNo,
      destination5Id: value.destination5Id,
      destination5Cd: value.destination5Cd,
      destination5ChargeId: value.destination5ChargeId,
      destination5ChargeCd: value.destination5ChargeCd,
      middleProcessingTraderId: value.middleProcessingTraderId,
      middleProcessingTraderCd: value.middleProcessingTraderCd,
      middleProcessingChargeId: value.middleProcessingChargeId,
      middleProcessingChargeCd: value.middleProcessingChargeCd,
      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingTraderCd: value.lastProcessingTraderCd,
      lastProcessingChargeId: value.lastProcessingChargeId,
      lastProcessingChargeCd: value.lastProcessingChargeCd,
      processTrustTraderId: value.processTrustTraderId,
      processTrustTraderCd: value.processTrustTraderCd,
      middleWasteFlg: value.middleWasteFlg,
      lastPlaceFlg: value.lastPlaceFlg,
      egsMWasteclassId: value.egsMWasteclassId,
      wasteKindCd: value.wasteKindCd,
      wasteCd: value.wasteCd,
      egsMDisposalMethodId: value.egsMDisposalMethodId,
      disposalMethodCd: value.disposalMethodCd,
      otherMethod: value.otherMethod,
      egsMUnitId: value.egsMUnitId,
      unitCd: value.unitCd,
      egsMPackingId: value.egsMPackingId,
      packingCd: value.packingCd,
      egsMDatasheetId: value.egsMDatasheetId,
      datasheetCd: value.datasheetCd,
      registTraderId: value.registTraderId,
      registTraderCd: value.registTraderCd,
      approvalflg: value.approvalflg,
      effectiveflg: value.effectiveflg,

      exhaustName: value.exhaustName,
      sect1TransportName: value.sect1TransportName,
      sect2TransportName: value.sect2TransportName,
      sect3TransportName: value.sect3TransportName,
      sect4TransportName: value.sect4TransportName,
      sect5TransportName: value.sect5TransportName,
      middleProcessingTraderName: value.middleProcessingTraderName,
      lastProcessingTraderName: value.lastProcessingTraderName,
      processTrustTraderName: value.processTrustTraderName,
      destination1Name: value.destination1Name,
      destination2Name: value.destination2Name,
      destination3Name: value.destination3Name,
      destination4Name: value.destination4Name,
      destination5Name: value.destination5Name,
      exhaustChargeName: value.exhaustChargeName,
      sect1TransportChargeName: value.sect1TransportChargeName,
      sect2TransportChargeName: value.sect2TransportChargeName,
      sect3TransportChargeName: value.sect3TransportChargeName,
      sect4TransportChargeName: value.sect4TransportChargeName,
      sect5TransportChargeName: value.sect5TransportChargeName,
      middleProcessingChargeName: value.middleProcessingChargeName,
      lastProcessingChargeName: value.lastProcessingChargeName,
      destination1ChargeName: value.destination1ChargeName,
      destination2ChargeName: value.destination2ChargeName,
      destination3ChargeName: value.destination3ChargeName,
      destination4ChargeName: value.destination4ChargeName,
      destination5ChargeName: value.destination5ChargeName,
      sect1TransportMethodName: value.sect1TransportMethodName,
      sect2TransportMethodName: value.sect2TransportMethodName,
      sect3TransportMethodName: value.sect3TransportMethodName,
      sect4TransportMethodName: value.sect4TransportMethodName,
      sect5TransportMethodName: value.sect5TransportMethodName,
      wasteKindName: value.wasteKindName,
      wasteName: value.wasteName,
      disposalMethodName: value.disposalMethodName,
      unitName: value.unitName,
      packingName: value.packingName,

      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,

      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,

      processTrustTraderId: value.processTrustTraderId,
      processTrustTraderCd: value.processTrustTraderCd,
      processTrustTraderName: value.processTrustTraderName,
    };
    return params;
  };
  // let d = new Date();
  // const qrCodeUrl = "https://www.sdgs12.com/waste/" + processId + "-" + d.valueOf();    //
  // // console.log('qrCodeUrl', qrCodeUrl);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>処理フロー　編集</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
        </div>
      </DialogTitle>
        <DialogContent>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>

        <FormBody
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          dataList2={dataList2}
          buttonType='更新' />

        {/* <h3>ＱＲコード</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p> */}
      </DialogContent>
    </Dialog>
  );
}
export default EgsMProcessFlowEdit4;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
