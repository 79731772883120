// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
//import { getLinkageUser } from '../../lib/api/linkage';
//import { getLinkageList } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';


import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',    // 200ch 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    // width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EdiD01ItemForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const [dataListT01, setdataListT01] = useState([]);
  const [dataListT07, setdataListT07] = useState([]);
  const [dataListC01, setdataListC01] = useState([]);
  const [dataListC02, setdataListC02] = useState([]);

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}


//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
    const dataListWaste = await getEgsMWasteclassList();
    setDataListWaste(dataListWaste.data);
//    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
//    // console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);



//      const resT1 = await getEgsMTraderList();
//      setdataListT01(resT1.data);
//      // console.log('resT1',resT1.data);
        const dataListT01 = await getEgsMTraderList();
//      const dataListT01 = await getLinkageUser(currentUser.traderCd);
//      const dataListT01 = await getLinkageList();
      setdataListT01(dataListT01.data);
      // console.log('dataListT01',dataListT01.data);

//      const resT2 = await getTraderSelList();
//      setdataListT07(resT2.data);
//      const dataListT07 = await getTraderSelList();
//      setdataListT07(dataListT07.data);

//      const resC1 = await getEgsMChargeList();
//      // console.log('resC1',resC1.data);
//      setdataListC01(resC1.data);
      const dataListC01 = await getEgsMChargeList();
//      // console.log('dataListC01',dataListC01.data);
      setdataListC01(dataListC01.data);

//      const resC2 = await getChargeSelList();
//      // console.log('resC2',resC2.data);
//      setdataListC02(resC2.data);
      const dataListC02 = await getChargeSelList();
//      // console.log('dataListC02',dataListC02.data);
      setdataListC02(dataListC02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//// console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//// console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//// console.log('disposaldataList',disposaldataList);

//const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
//// console.log('transport',value.sect1TransportMethodCd,value.sect2TransportMethodCd,value.sect3TransportMethodCd,value.sect4TransportMethodCd,value.sect5TransportMethodCd);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//// console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
//// console.log('packingdataList',packingdataList);




//  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.traderCd == value.exhaustCd });
//  const traderdataList00 = dataListT01.filter(resT1 => { return resT1.id == value.exhaustId });
//0517  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.exhaustId });
//  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '1000000000' });
//  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.egsMTraderId1 == value.exhaustId });
  const traderdataList00 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.exhaustId });
  // console.log('traderdataList00',traderdataList00,'<-',value.exhaustId);
//  const traderdataList07 = dataListT07.filter(dataListT07 => { return dataListT07.traderKbn == '0000100000' });    // 最終処分
//  const traderdataList07 = dataListT07.filter(resT2 => { return resT2.traderKbn == '0000100000' });    // 最終処分
//  const traderdataList07 = dataListT07.filter(resT2 => { return resT2.id == value.lastProcessingTraderId });    // 最終処分
//0517  const traderdataList07 = dataListT07.filter(dataListT07 => { return dataListT07.id == value.lastProcessingTraderId });    // 最終処分
////////  const traderdataList07 = dataListT01.filter(dataListT01 => { return dataListT01.egsMTraderId1 == value.sect1TransportId &&
//  const traderdataList07 = dataListT01.filter(dataListT01 => { return ( dataListT01.traderKbn2 == '0001000000'
//                                                                     || dataListT01.traderKbn2 == '0000100000'
//                                                                     || dataListT01.traderKbn2 == '1001000000'
//                                                                     || dataListT01.traderKbn2 == '1000100000' ) });    // 最終処分
  const traderdataList07 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.lastProcessingTraderId });
  // console.log('traderdataList07',traderdataList07,'<-',value.lastProcessingTraderId);



// 区間１～５
//const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect1TransportId });
//const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' || dataListT01.traderKbn1 == '0100000000'});
const traderdataList01 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect1TransportId });
// console.log('traderdataList01',traderdataList01);
//// console.log('sect1TransportId',value.sect1TransportId,':',value.sect1TransportChargeId,':',chargedataList01)
//const traderdataList02 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
const traderdataList02 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect2TransportId });
//// console.log('traderdataList02',traderdataList02);
//const traderdataList03 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
const traderdataList03 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect3TransportId });
//// console.log('traderdataList03',traderdataList03);
//const traderdataList04 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
const traderdataList04 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect4TransportId });
//// console.log('traderdataList04',traderdataList04);
//const traderdataList05 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0100000000' });
const traderdataList05 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect5TransportId });
//// console.log('traderdataList05',traderdataList05);

//const traderdataList11 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000'
//                                                                 || dataListT01.traderKbn2 == '0001000000'
//                                                                 || dataListT01.traderKbn2 == '0000100000'
//                                                                 || dataListT01.traderKbn2 == '0000010000' });
const traderdataList11 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.destination1Id });
// console.log('traderdataList11',traderdataList11);
//const traderdataList12 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
const traderdataList12 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.destination2Id });
//// console.log('traderdataList12',traderdataList11);
//const traderdataList13 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
const traderdataList13 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.destination3Id });
//// console.log('traderdataList13',traderdataList11);
//const traderdataList14 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
const traderdataList14 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.destination4Id });
//// console.log('traderdataList14',traderdataList11);
//const traderdataList15 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn2 == '0010000000' });
const traderdataList15 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.destination5Id });
//// console.log('traderdataList15',traderdataList11);


//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.id == value.exhaustId });
//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.egsMTraderid == value.exhaustId });
const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
//  // console.log('chargedataList00','->',chargedataList00,'<-',value.exhaustId);

//  const chargedataList07 = dataListC02.filter(resC2 => { return resC2.id == value.lastProcessingChargeId });
  const chargedataList07 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.lastProcessingTraderId });
//  // console.log('chargedataList07','->',chargedataList07,'<-',value.lastProcessingTraderId);

const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });
const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });
const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });
const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });
const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });


  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
          props.handleCancel();
        }}>    {/*  egs_m_reserve_sets  */}
          戻る
        </Button>
      </div>

      <SpaceRow height={20} />

      <div className={classes.container}>
      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              引渡し日
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='exhaustDate' variant="outlined" type='date' name='exhaustDate' halfWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              ゴミタグ番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='garbageTag' variant="outlined" type='text' name='garbageTag' disabled halfWidth margin="dense" value={value.garbageTag} />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              マニフェスト番号
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='manifestNo' variant="outlined" type='text' name='manifestNo' halfWidth margin="dense" value={value.manifestNo} onChange={(e) => handleChange(e)} />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              排出事業者
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={4}>      {/*  0にも出来るが、消えるわけではなく、後続がずれる  */}
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
                id='exhaustId'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustId'
                margin="dense"
                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.exhaustId}
                fullWidth
              >
              {traderdataList00.map((traderdataList00) => (
                <MenuItem
                  key={traderdataList00.id}
                  value={traderdataList00.id}
                >
                  {traderdataList00.traderName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
{/*}
        <Grid item xs={4}>      {/*  0にも出来るが、消えるわけではなく、後続がずれる  */}
{/*}          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
                id='exhaustCd'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustCd'
                margin="dense"
                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.exhaustCd}
                fullWidth
              >
              {traderdataList00.map((traderdataList00) => (
                <MenuItem
                  key={traderdataList00.traderCd}
                  value={traderdataList00.traderCd}
                >
                  {traderdataList00.traderName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
*/}
{/*}
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
                id='exhaustName'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustName'
                margin="dense"
                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.exhaustName}
                fullWidth
              >
              {traderdataList00.map((traderdataList00) => (
                <MenuItem
                  key={traderdataList00.traderName}
                  value={traderdataList00.traderName}
                >
                  {traderdataList00.traderName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
*/}
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              排出担当者
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='exhaustChargeId'
              label='排出担当者'
              variant="outlined"
              type='text'
              name='exhaustChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.exhaustChargeId}
            >
            {chargedataList00.map((chargedataList00) => (
              <MenuItem
                key={chargedataList00.id}
                value={chargedataList00.id}
              >
                {chargedataList00.chargeCd}:{chargedataList00.chargeName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <InputLabel variant="h7" style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              廃棄物種類
            </InputLabel>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='wasteCd'
              label='廃棄物名称１'
              variant="outlined"
              type='text'
              name='wasteCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.wasteCd}
            >
            {wastedataList.map((wastedataList) => (
              <MenuItem
                key={wastedataList.wasteclassCd}
                value={wastedataList.wasteclassCd}
              >
                {wastedataList.wasteclassCd}:{wastedataList.wasteclassName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              廃棄物の数量
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}
          <TextField id='amount1' variant="outlined" type='text' name='amount1' halfWidth margin="dense" value={value.amount1} onChange={(e) => handleChange(e)} />
          <Grid item xs={12}>
*/}
{/*}          <Grid item xs={24}>
            <Box p={1}>   */}
              <TextField id='amount1' hiddenLabel variant="outlined" type='number' name='amount1' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.amount1} />
{/*}            </Box>
          </Grid>  */}

          <Grid item xs={12}>
            <Box p={1}>

            <Select
              id='unitCd'
              label='単位'
              variant="outlined"
              type='text'
              name='unitCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.unitCd}
              fullWidth
              style={{
                marginRight: 16
              }}
            >
            {unitdataList.map((unitdataList) => (
              <MenuItem
                key={unitdataList.unitCd}
                value={unitdataList.unitCd}
              >
                {unitdataList.unitCd}:{unitdataList.unitName}
              </MenuItem>
            ))}
            </Select>

            </Box>
          </Grid>
{/*}
            <Select
              id='packingCd'
              label='荷姿'
              variant="outlined"
              type='text'
              name='packingCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.packingCd}
              fullWidth
            >
            {packingdataList.map((packingdataList) => (
              <MenuItem
                key={packingdataList.packingCd}
                value={packingdataList.packingCd}
              >
                {packingdataList.packingCd}:{packingdataList.packingName}
              </MenuItem>
            ))}
            </Select>
*/}
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              荷姿の数量
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>

{/*}          <Grid item xs={2}>  */}
{/*}            <Box p={1}> */}
              <TextField id='packingAmount1' hiddenLabel variant="outlined" type='number' name='packingAmount1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.packingAmount1} />
{/*}            </Box> */}
{/*}          </Grid> */}

{/*}          <Grid item xs={2}> */}
{/*}            <Box p={1}> */}
            <Select
              id='packingCd'
              label='荷姿'
              variant="outlined"
              type='text'
              name='packingCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.packingCd}
            >
            {packingdataList.map((packingdataList) => (
              <MenuItem
                key={packingdataList.packingCd}
                value={packingdataList.packingCd}
              >
                {packingdataList.packingCd}:{packingdataList.packingName}
              </MenuItem>
            ))}
            </Select>
{/*}            </Box> */}
{/*}          </Grid>  */}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
            状態
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
          <Select
            id='status'
            label='状態'
            variant="outlined"
            type='text'
            name='status'
            margin="dense"
            onChange={(e) => handleChange(e)}
            value={value.status}
          >
            <MenuItem value={value.status}>
              <em>状態</em>
            </MenuItem>
            <MenuItem value={'00'}>00:未設定</MenuItem>
            <MenuItem value={'10'}>10:区間１運搬中</MenuItem>
            <MenuItem value={'11'}>11:区間１積替保管中</MenuItem>
            <MenuItem value={'20'}>20:区間２運搬中</MenuItem>
            <MenuItem value={'21'}>21:区間２積替保管中</MenuItem>
            <MenuItem value={'30'}>30:区間３運搬中</MenuItem>
            <MenuItem value={'31'}>31:区間３積替保管中</MenuItem>
            <MenuItem value={'40'}>40:区間４運搬中</MenuItem>
            <MenuItem value={'41'}>41:区間４積替保管中</MenuItem>
            <MenuItem value={'50'}>50:区間５運搬中</MenuItem>
            <MenuItem value={'51'}>51:区間６積替保管中</MenuItem>
            <MenuItem value={'60'}>60:中間処理中</MenuItem>
            <MenuItem value={'61'}>61:中間処理済</MenuItem>
            <MenuItem value={'70'}>70:処分中</MenuItem>
            <MenuItem value={'71'}>71:最終処分済</MenuItem>
          </Select>
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分開始
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='lastProcessingStart' variant="outlined" type='date' name='lastProcessingStart' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingStart} />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分終了
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField id='lastProcessingEnd' variant="outlined" type='date' name='lastProcessingEnd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingEnd} />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分業者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='lastProcessingTraderId'
              label='最終処分業者'
              variant="outlined"
              type='text'
              name='lastProcessingTraderId'
              margin="dense"
              onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
              value={value.lastProcessingTraderId}
            >
            {traderdataList07.map((traderdataList07) => (
              <MenuItem
                key={traderdataList07.id}
                value={traderdataList07.id}
              >
                {traderdataList07.traderName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
{/*}
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='lastProcessingTraderCd'
              label='最終処分業者'
              variant="outlined"
              type='text'
              name='lastProcessingTraderCd'
              margin="dense"
              onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
              value={value.lastProcessingTraderCd}
            >
            {traderdataList07.map((traderdataList07) => (
              <MenuItem
                key={traderdataList07.traderCd}
                value={traderdataList07.traderCd}
              >
                {traderdataList07.traderName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='lastProcessingTraderName'
              label='最終処分業者'
              variant="outlined"
              type='text'
              name='lastProcessingTraderName'
              margin="dense"
              onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
              value={value.lastProcessingTraderName}
            >
            {traderdataList07.map((traderdataList07) => (
              <MenuItem
                key={traderdataList07.traderName}
                value={traderdataList07.traderName}
              >
                {traderdataList07.traderName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
*/}
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分担当者
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='lastProcessingChargeId'
              variant="outlined"
              type='text'
              name='lastProcessingChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.lastProcessingChargeId}
              style={{
                marginRight: 16
              }}
              fullWidth
            >
            {chargedataList07.map((chargedataList07) => (
              <MenuItem
                key={chargedataList07.id}
                value={chargedataList07.id}
              >
                {chargedataList07.chargeCd}:{chargedataList07.chargeName}
              </MenuItem>
            ))}
            </Select>
{/*}
            <Select
              id='disposalMethodCd'
              variant="outlined"
              type='text'
              name='disposalMethodCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.disposalMethodCd}
              fullWidth
            >
            {disposaldataList.map((disposaldataList) => (
              <MenuItem
                key={disposaldataList.disposalMethodCd}
                value={disposaldataList.disposalMethodCd}
              >
                {disposaldataList.disposalMethodCd}:{disposaldataList.disposalMethodName}
              </MenuItem>
            ))}
            </Select>
*/}
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              処分方法
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='disposalMethodCd'
              label='処分方法'
              variant="outlined"
              type='text'
              name='disposalMethodCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.disposalMethodCd}
            >
            {disposaldataList.map((disposaldataList) => (
              <MenuItem
                key={disposaldataList.disposalMethodCd}
                value={disposaldataList.disposalMethodCd}
              >
                {disposaldataList.disposalMethodCd}:{disposaldataList.disposalMethodName}
              </MenuItem>
            ))}
            </Select>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
        style={{
          borderTopWidth: 2,
          borderTopColor: "black"
        }}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              運搬情報
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                事業者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                担当者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬方法
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                車両番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬先事業場
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                担当者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderRightWidth: 0 }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                備考
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
              区間１
            </InputLabel>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
{/*
              <Select id='sect1TransportCd' label='区間１運搬事業場' variant="outlined" type='text' name='sect1TransportCd' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportCd} fullWidth>
                {traderdataList01.map((traderdataList01) => (<MenuItem key={traderdataList01.traderCd} value={traderdataList01.traderCd} > {traderdataList01.traderName} </MenuItem>))}
              </Select>
*/}
              <Select id='sect1TransportId' label='区間１運搬事業場' variant="outlined" type='text' name='sect1TransportId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportId} fullWidth>
                {traderdataList01.map((traderdataList01) => (<MenuItem key={traderdataList01.id} value={traderdataList01.id} > {traderdataList01.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect1TransportChargeId' label='区間１運搬担当者' variant="outlined" type='text' name='sect1TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeId} >
                {chargedataList01.map((chargedataList01) => (<MenuItem key={chargedataList01.id} value={chargedataList01.id} > {chargedataList01.chargeCd}:{chargedataList01.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                //      onChange={(e) => handleChange(e)}
                alue={value.sect1TransportMethodCd} >
                {trans1dataList.map((trans1dataList) => (<MenuItem key={trans1dataList.transportMethodCd} value={trans1dataList.transportMethodCd} > {trans1dataList.transportMethodCd}:{trans1dataList.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <TextField id='sect1CarNo' hiddenLabel variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination1Id' label='区間１運搬先事業場' variant="outlined" type='text' name='destination1Id' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1Id} >
                {traderdataList11.map((traderdataList11) => (<MenuItem key={traderdataList11.id} value={traderdataList11.id} > {traderdataList11.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination1ChargeId' label='区間１運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                {chargedataList11.map((chargedataList11) => (<MenuItem key={chargedataList11.id} value={chargedataList11.id} >   {chargedataList11.chargeCd}:{chargedataList11.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <TextField id='memo1' hiddenLabel variant="outlined" type='text' name='memo1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} fullWidth />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間２
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect2TransportId' label='区間２運搬事業場' variant="outlined" type='text' name='sect2TransportId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportId} >
                {traderdataList02.map((traderdataList02) => (<MenuItem key={traderdataList02.id} value={traderdataList02.id} > {traderdataList02.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect2TransportChargeId' label='区間２運搬担当者' variant="outlined" type='text' name='sect2TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportChargeId} >
                {chargedataList02.map((chargedataList02) => (<MenuItem key={chargedataList02.id} value={chargedataList02.id} > {chargedataList02.chargeCd}:{chargedataList02.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
                //      onChange={(e) => handleChange(e)}
                alue={value.sect2TransportMethodCd} >
                {trans2dataList.map((trans2dataList) => (<MenuItem key={trans2dataList.transportMethodCd} value={trans2dataList.transportMethodCd} > {trans2dataList.transportMethodCd}:{trans2dataList.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <TextField id='sect2CarNo' hiddenLabel variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination2Id' label='区間２運搬先事業場' variant="outlined" type='text' name='destination2Id' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2Id} >
                {traderdataList12.map((traderdataList12) => (<MenuItem key={traderdataList12.id} value={traderdataList12.id} > {traderdataList12.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination2ChargeId' label='区間２運搬先担当者' variant="outlined" type='text' name='destination2ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeId} >
                {chargedataList12.map((chargedataList12) => (<MenuItem key={chargedataList12.id} value={chargedataList12.id} >   {chargedataList12.chargeCd}:{chargedataList12.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <TextField fullWidth id='memo2' variant="outlined" type='text' name='memo2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間３
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect3TransportId' label='区間３運搬事業場' variant="outlined" type='text' name='sect3TransportId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportId} >
                {traderdataList03.map((traderdataList03) => (<MenuItem key={traderdataList03.id} value={traderdataList03.id} > {traderdataList03.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect3TransportChargeId' label='区間３運搬担当者' variant="outlined" type='text' name='sect3TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportChargeId} >
                {chargedataList03.map((chargedataList03) => (<MenuItem key={chargedataList03.id} value={chargedataList03.id} > {chargedataList03.chargeCd}:{chargedataList03.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
                //      onChange={(e) => handleChange(e)}
                alue={value.sect3TransportMethodCd} >
                {trans3dataList.map((trans3dataList) => (<MenuItem key={trans3dataList.transportMethodCd} value={trans3dataList.transportMethodCd} > {trans3dataList.transportMethodCd}:{trans3dataList.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <TextField fullWidth id='sect3CarNo' variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination3Id' label='区間３運搬先事業場' variant="outlined" type='text' name='destination3Id' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3Id} >
                {traderdataList13.map((traderdataList13) => (<MenuItem key={traderdataList13.id} value={traderdataList13.id} > {traderdataList13.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination3ChargeId' label='区間３運搬先担当者' variant="outlined" type='text' name='destination3ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeId} >
                {chargedataList13.map((chargedataList13) => (<MenuItem key={chargedataList13.id} value={chargedataList13.id} >   {chargedataList13.chargeCd}:{chargedataList13.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <TextField fullWidth id='memo3' variant="outlined" type='text' name='memo3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間４
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect4TransportId' label='区間４運搬事業場' variant="outlined" type='text' name='sect4TransportId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportId} >
                {traderdataList04.map((traderdataList04) => (<MenuItem key={traderdataList04.id} value={traderdataList04.id} > {traderdataList04.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect4TransportChargeId' label='区間４運搬担当者' variant="outlined" type='text' name='sect4TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportChargeId} >
                {chargedataList04.map((chargedataList04) => (<MenuItem key={chargedataList04.id} value={chargedataList04.id} > {chargedataList04.chargeCd}:{chargedataList04.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
                //      onChange={(e) => handleChange(e)}
                alue={value.sect4TransportMethodCd} >
                {trans4dataList.map((trans4dataList) => (<MenuItem key={trans4dataList.transportMethodCd} value={trans4dataList.transportMethodCd} > {trans4dataList.transportMethodCd}:{trans4dataList.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <TextField fullWidth id='sect4CarNo' hiddenLabel variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination4Id' label='区間４運搬先事業場' variant="outlined" type='text' name='destination4Id' margin="dense" onChange={(e) => handleChange(e)} value={value.destination4Id} >
                {traderdataList14.map((traderdataList14) => (<MenuItem key={traderdataList14.id} value={traderdataList14.id} > {traderdataList14.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination1ChargeId' label='区間４運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                {chargedataList14.map((chargedataList14) => (<MenuItem key={chargedataList14.id} value={chargedataList14.id} >   {chargedataList14.chargeCd}:{chargedataList14.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <TextField fullWidth id='memo4' hiddenLabel variant="outlined" type='text' name='memo4' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              区間５
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect5TransportId' label='区間５運搬事業場' variant="outlined" type='text' name='sect5TransportId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportId} >
                {traderdataList05.map((traderdataList05) => (<MenuItem key={traderdataList05.id} value={traderdataList05.id} > {traderdataList05.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect5TransportChargeId' label='区間５運搬担当者' variant="outlined" type='text' name='sect5TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportChargeId} >
                {chargedataList05.map((chargedataList05) => (<MenuItem key={chargedataList05.id} value={chargedataList05.id} > {chargedataList05.chargeCd}:{chargedataList05.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
                //      onChange={(e) => handleChange(e)}
                alue={value.sect5TransportMethodCd} >
                {trans5dataList.map((trans5dataList) => (<MenuItem key={trans5dataList.transportMethodCd} value={trans5dataList.transportMethodCd} > {trans5dataList.transportMethodCd}:{trans5dataList.transportMethodName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.value_box}>
            <Box p={1}>
              <TextField fullWidth id='sect5CarNo' variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} />
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination5Id' label='区間５運搬先事業場' variant="outlined" type='text' name='destination5Id' margin="dense" onChange={(e) => handleChange(e)} value={value.destination5Id} >
                {traderdataList15.map((traderdataList15) => (<MenuItem key={traderdataList15.id} value={traderdataList15.id} > {traderdataList15.traderName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2} className={classes.value_box}>
            <Box p={1} marginTop={1}>
              <Select fullWidth id='destination1ChargeId' label='区間５運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                {chargedataList15.map((chargedataList15) => (<MenuItem key={chargedataList15.id} value={chargedataList15.id} >   {chargedataList15.chargeCd}:{chargedataList15.chargeName} </MenuItem>))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box p={1}>
              <TextField fullWidth id='memo5' variant="outlined" type='text' name='memo5' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
        style={{
          borderTopWidth: 2,
          borderTopColor: "black"
        }}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              EDI反映フラグ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='ediSendFlg'
              label='EDI反映フラグ'
              variant="outlined"
              type='text'
              name='ediSendFlg'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.ediSendFlg}
            >
              <MenuItem value={value.ediSendFlg}>
                <em>EDI反映フラグ</em>
              </MenuItem>
              <MenuItem value={'0'}>0:送信不要</MenuItem>
              <MenuItem value={'1'}>1:未送信（変更あり）</MenuItem>
              <MenuItem value={'2'}>2:送信済</MenuItem>
              <MenuItem value={'3'}>3:受信済</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              予約登録マニフェスト修正権限コード
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField fullWidth id='reserveAuthorityCd' label='予約登録マニフェスト修正権限コード' variant="outlined" type='text' name='reserveAuthorityCd' margin="dense" value={value.reserveAuthorityCd} onChange={(e) => handleChange(e)} />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分事業場記載フラグ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='lastReportFlg'
              label='最終処分事業場記載フラグ'
              variant="outlined"
              type='text'
              name='lastReportFlg'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.lastReportFlg}
              fullWidth
            >
              <MenuItem value={value.lastReportFlg}>
                <em>最終処分事業場記載フラグ</em>
              </MenuItem>
              <MenuItem value={'0:委託契約書記載のとおり'}>0:委託契約書記載のとおり</MenuItem>
              <MenuItem value={'1:最終処分事業場（予定）を記載する'}>1:最終処分事業場（予定）を記載する</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              最終処分情報件数
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <TextField fullWidth id='lastProcessingCnt' label='最終処分情報件数' variant="outlined" type='number' name='lastProcessingCnt' margin="dense" value={value.lastProcessingCnt} onChange={(e) => handleChange(e)} />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.row}
      >
        <Grid item xs={2}>
          <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              中間処理産業廃棄物情報管理方法フラグ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Select
              id='middleProcessingInfoFlg'
              label='中間処理産業廃棄物情報管理方法フラグ'
              variant="outlined"
              type='text'
              name='middleProcessingInfoFlg'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.middleProcessingInfoFlg}
            >
              <MenuItem value={value.middleProcessingInfoFlg}>
                <em>中間処理産業廃棄物情報管理方法フラグ</em>
              </MenuItem>
              <MenuItem value={'1:当欄指定のとおり'}>1:当欄指定のとおり</MenuItem>
              <MenuItem value={'2:１次不要'}>2:１次不要</MenuItem>
              <MenuItem value={'3:帳簿記載のとおり'}>3:帳簿記載のとおり</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
            handleSubmit(e);
          }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
      </div>

    </>
  );
};
export default EdiD01ItemForm;
/*

  <th>EDIレコード識別番号</th><td><TextField id='ediRecordNo' label='EDIレコード識別番号' variant="outlined" type='text' name='ediRecordNo' halfWidth margin="dense" value={value.ediRecordNo} onChange={(e) => handleChange(e)} /></td>
  <th>予約登録マニフェスト修正権限コード</th><td><TextField id='reserveAuthorityCd' label='予約登録マニフェスト修正権限コード' variant="outlined" type='text' name='reserveAuthorityCd' halfWidth margin="dense" value={value.reserveAuthorityCd} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>登録担当者</th>
    <td><TextField id='registCharge' label='登録担当者' variant="outlined" type='text' name='registCharge' halfWidth margin="dense" value={value.registCharge} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='registCharge'
  label='登録担当者'
  variant="outlined"
  type='text'
  name='registCharge'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.registCharge}
>
  <MenuItem value={value.registCharge}>
    <em>登録担当者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
<th>引渡し担当者</th>
<td><TextField id='exhaustCharge' label='引渡し担当者' variant="outlined" type='text' name='exhaustCharge' halfWidth margin="dense" value={value.exhaustCharge} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='exhaustCharge'
  label='引渡し担当者'
  variant="outlined"
  type='text'
  name='exhaustCharge'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.exhaustCharge}
>
  <MenuItem value={value.exhaustCharge}>
    <em>引渡し担当者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
<th>数量確定者</th>
<td><TextField id='amountConfirmCd' label='数量確定者' variant="outlined" type='text' name='amountConfirmCd' fullWidth margin="dense" value={value.amountConfirmCd} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='amountConfirmCd'
  label='数量確定者'
  variant="outlined"
  type='text'
  name='amountConfirmCd'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.amountConfirmCd}
>
  <MenuItem value={value.amountConfirmCd}>
    <em>数量確定者</em>
  </MenuItem>
  <MenuItem value={'1:排出担当者'}>1:排出担当者</MenuItem>
  <MenuItem value={'2:収集運搬担当者'}>2:収集運搬担当者</MenuItem>
 </Select>
</td>
</tr><tr>
<th>簡易情報取得フラグ</th>
<td><TextField id='simpleInfoFlg' label='簡易情報取得フラグ' variant="outlined" type='text' name='simpleInfoFlg' fullWidth margin="dense" value={value.simpleInfoFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='simpleInfoFlg'
  label='簡易情報取得フラグ'
  variant="outlined"
  type='text'
  name='simpleInfoFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.simpleInfoFlg}
>
  <MenuItem value={value.simpleInfoFlg}>
    <em>簡易情報取得フラグ</em>
  </MenuItem>
  <MenuItem value={'1:通常結果ファイル要求'}>1:通常結果ファイル要求</MenuItem>
  <MenuItem value={'2:簡易結果ファイル要求'}>2:簡易結果ファイル要求</MenuItem>
 </Select>
</td>
<th>最終処分事業場記載フラグ</th>
<td><TextField id='lastReportFlg' label='最終処分事業場記載フラグ' variant="outlined" type='text' name='lastReportFlg' halfWidth margin="dense" value={value.lastReportFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='lastReportFlg'
  label='最終処分事業場記載フラグ'
  variant="outlined"
  type='text'
  name='lastReportFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.lastReportFlg}
>
  <MenuItem value={value.lastReportFlg}>
    <em>最終処分事業場記載フラグ</em>
  </MenuItem>
  <MenuItem value={'0:委託契約書記載のとおり'}>0:委託契約書記載のとおり</MenuItem>
  <MenuItem value={'1:最終処分事業場（予定）を記載する'}>1:最終処分事業場（予定）を記載する</MenuItem>
 </Select>
</td>

<th>中間処理産業廃棄物情報管理方法フラグ</th>
<td><TextField id='middleProcessingInfoFlg' label='中間処理産業廃棄物情報管理方法フラグ' variant="outlined" type='text' name='middleProcessingInfoFlg' halfWidth margin="dense" value={value.middleProcessingInfoFlg} onChange={(e) => handleChange(e)} /></td>
<td>
<Select
  id='middleProcessingInfoFlg'
  label='中間処理産業廃棄物情報管理方法フラグ'
  variant="outlined"
  type='text'
  name='middleProcessingInfoFlg'
  margin="dense"
  onChange={(e) => handleChange(e)}
  value={value.middleProcessingInfoFlg}
>
  <MenuItem value={value.middleProcessingInfoFlg}>
    <em>中間処理産業廃棄物情報管理方法フラグ</em>
  </MenuItem>
  <MenuItem value={'1:当欄指定のとおり'}>1:当欄指定のとおり</MenuItem>
  <MenuItem value={'2:１次不要'}>2:１次不要</MenuItem>
  <MenuItem value={'3:帳簿記載のとおり'}>3:帳簿記載のとおり</MenuItem>
 </Select>
</td>

</tr><tr>
<th>予約登録発行件数</th><td><TextField id='reserveCnt' label='予約登録発行件数' variant="outlined" type='number' name='reserveCnt' halfWidth margin="dense" value={value.reserveCnt} onChange={(e) => handleChange(e)} /></td>
<th>有害物質情報件数</th><td><TextField id='toxicSubstanceCnt' label='有害物質情報件数' variant="outlined" type='number' name='toxicSubstanceCnt' halfWidth margin="dense" value={value.toxicSubstanceCnt} onChange={(e) => handleChange(e)} /></td>
<th>収集運搬情報件数</th><td><TextField id='transportInfoCnt' label='収集運搬情報件数' variant="outlined" type='number' name='transportInfoCnt' halfWidth margin="dense" value={value.transportInfoCnt} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
<th>最終処分情報件数</th><td><TextField id='lastProcessingCnt' label='最終処分情報件数' variant="outlined" type='number' name='lastProcessingCnt' halfWidth margin="dense" value={value.lastProcessingCnt} onChange={(e) => handleChange(e)} /></td>
<th>連絡番号情報件数</th><td><TextField id='contractCnt' label='連絡番号情報件数' variant="outlined" type='number' name='contractCnt' halfWidth margin="dense" value={value.contractCnt} onChange={(e) => handleChange(e)} /></td>
<th>備考情報件数</th><td><TextField id='memoInfoCnt' label='備考情報件数' variant="outlined" type='number' name='memoInfoCnt' halfWidth margin="dense" value={value.memoInfoCnt} onChange={(e) => handleChange(e)} /></td>
<th>中間処理産業廃棄物情報件数</th><td><TextField id='middleProcessingInfoCnt' label='中間処理産業廃棄物情報件数' variant="outlined" type='number' name='middleProcessingInfoCnt' halfWidth margin="dense" value={value.middleProcessingInfoCnt} onChange={(e) => handleChange(e)} /></td>





</tr><tr>
  <th>排出事業場電話番号</th><td><TextField id='exhaust' label='排出事業場電話番号' variant="outlined" type='text' name='exhaustPhone' halfWidth margin="dense" value={value.exhaustPhone} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場郵便番号</th><td><TextField id='exhaustZip' label='排出事業場郵便番号' variant="outlined" type='text' name='exhaustZip' halfWidth margin="dense" value={value.exhaustZip} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>排出事業場所在地１</th><td><TextField id='exhaustAdr1' label='排出事業場所在地１' variant="outlined" type='text' name='exhaustAdr1' halfWidth margin="dense" value={value.exhaustAdr1} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場所在地２</th><td><TextField id='exhaustAdr2' label='排出事業場所在地２' variant="outlined" type='text' name='exhaustAdr2' halfWidth margin="dense" value={value.exhaustAdr2} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>排出事業場所在地３</th><td><TextField id='exhaustAdr3' label='排出事業場所在地３' variant="outlined" type='text' name='exhaustAdr3' halfWidth margin="dense" value={value.exhaustAdr3} onChange={(e) => handleChange(e)} /></td>
  <th>排出事業場所在地４</th><td><TextField id='exhaustAdr4' label='排出事業場所在地４' variant="outlined" type='text' name='exhaustAdr4' halfWidth margin="dense" value={value.exhaustAdr4} onChange={(e) => handleChange(e)} /></td>


<th>処分業者郵便番号</th><td><TextField id='disposalZip' label='処分業者郵便番号' variant="outlined" type='text' name='disposalZip' halfWidth margin="dense" value={value.disposalZip} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者所在地１</th><td><TextField id='disposalAdr1' label='処分業者所在地１' variant="outlined" type='text' name='disposalAdr1' halfWidth margin="dense" value={value.disposalAdr1} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者所在地２</th><td><TextField id='disposalAdr2' label='処分業者所在地２' variant="outlined" type='text' name='disposalAdr2' halfWidth margin="dense" value={value.disposalAdr2} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者所在地３</th><td><TextField id='disposalAdr3' label='処分業者所在地３' variant="outlined" type='text' name='disposalAdr3' halfWidth margin="dense" value={value.disposalAdr3} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者所在地４</th><td><TextField id='disposalAdr4' label='処分業者所在地４' variant="outlined" type='text' name='disposalAdr4' halfWidth margin="dense" value={value.disposalAdr4} onChange={(e) => handleChange(e)} /></td>
</tr><tr>
  <th>処分業者電話番号</th><td><TextField id='disposal' label='処分業者電話番号' variant="outlined" type='text' name='disposalPhone' halfWidth margin="dense" value={value.disposalPhone} onChange={(e) => handleChange(e)} /></td>
  <th>処分業者FAX</th><td><TextField id='disposalFax' label='処分業者FAX' variant="outlined" type='text' name='disposalFax' halfWidth margin="dense" value={value.disposalFax} onChange={(e) => handleChange(e)} /></td>


*/
