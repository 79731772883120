import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button,   Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import FormBody from './EntryPdfForm';
//import MblExhaustUserEdit from './MblExhaustUserEdit';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EntryPdf({ currentUser, isOpen, doClose, dataList2 }) {
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const handleCancel = () => {
    doClose();
  };
  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"lg"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>加入者マスタPDF</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>

{/*}      <DialogContent>

        <FormBody
          dataList2={dataList2}
          currentUser={currentUser}
        />
*/}       
        {/* <MblExhaustUserEdit /> */}

        {/* <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> */}
{/*}      </DialogContent>  */}

    </Dialog>
  );
}
export default EntryPdf;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
