import React, { useEffect, useState, useContext } from 'react';
import { getTransList } from '../../lib/api/trans_list';
//import { getTransList } from '../../lib/api/trans_list';
//import { getTransListUser } from '../../lib/api/trans_list';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import TransListTable from './TransListTable';
import ExhaustDateEdit from './ExhaustDateEdit';
//import ExhaustDateForm from './ExhaustDateForm';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const TransList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getTransList();
//      const res = await getTransListUser(currentUser.traderCd);
      const res = await getTransList();
      // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>運搬業者別積込登録集計</h1>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
{/*}      <h1>排出期間変更</h1>  */}
      <ExhaustDateEdit
        dataList={dataList}
        currentUser={currentUser}
        handleGetList={handleGetList}s
      />
{/*}      <ExhaustDateForm
        dataList={dataList}
        currentUser={currentUser}
  />  */}
      <TransListTable
        dataList={dataList}
        currentUser={currentUser}
        handleGetList={handleGetList}
      />
    </>
  );
};
export default TransList;
