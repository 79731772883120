// /src/lib/api/linkage_sect.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getLinkageSectList = () => {
  return client.get('/linkage_sects');
};


// 事業所指定一覧 idは事業所CD
export const getLinkageSectUser = (id) => {
  return client.get(`/linkage_sects/${id}/showuser`);
};

// 詳細（追加・更新用）
export const getLinkageSectDetail = (id) => {
  return client.get(`/linkage_sects/${id}`);
};

// 新規作成
// header情報を追加
export const createLinkageSect = (params) => {
  return client.post('/linkage_sects', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateLinkageSect = (id, params) => {
  return client.patch(`/linkage_sects/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteLinkageSect = (id) => {
  return client.delete(`/linkage_sects/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
