import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"

//import { updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
//import { getEgsMReserveSetDetail } from '../../lib/api/egs_m_reserve_set';
import { getEgsMProcessFlowDetail } from '../../lib/api/egs_m_process_flow';
//NG apiなし   import { getProcessFlowDetail } from '../../lib/api/process_flow';
//import { getProcessFlowId } from '../../lib/api/process_flow';
import { createEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
//import FormBody from './EdiD01ItemForm';
import FormBody from './EdiD01ItemAddForm';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';

import dayjs from 'dayjs';

function EgsTGarbageEdiEdit() {
  const [value, setValue] = useState({
    garbageTag: '',
    processingSeqno: '',
    manifestNo: '',
    arrangementNo: '',
    exhaustDate: '',
    exhaustId: '',
    exhaustCd: '',
    exhaustName: '',
    exhaustChargeId: '',
    exhaustChargeCd: '',
    exhaustChargeName: '',
    sect1TransportId: '',
    sect1TransportCd: '',
    sect1TransportName: '',
    sect1TransportChargeId: '',
    sect1TransportChargeCd: '',
    sect1TransportChargeName: '',
    sect1TransportMethodId: '',
    sect1TransportMethodCd: '',
    sect1TransportMethodName: '',
    sect1CarNo: '',
    destination1Id: '',
    destination1Cd: '',
    destination1Name: '',
    destination1ChargeId: '',
    destination1ChargeCd: '',
    destination1ChargeName: '',
    sect2TransportId: '',
    sect2TransportCd: '',
    sect2TransportName: '',
    sect2TransportChargeId: '',
    sect2TransportChargeCd: '',
    sect2TransportChargeName: '',
    sect2TransportMethodId: '',
    sect2TransportMethodCd: '',
    sect2TransportMethodName: '',
    sect2CarNo: '',
    destination2Id: '',
    destination2Cd: '',
    destination2Name: '',
    destination2ChargeId: '',
    destination2ChargeCd: '',
    destination2ChargeName: '',
    sect3TransportId: '',
    sect3TransportCd: '',
    sect3TransportName: '',
    sect3TransportChargeId: '',
    sect3TransportChargeCd: '',
    sect3TransportChargeName: '',
    sect3TransportMethodId: '',
    sect3TransportMethodCd: '',
    sect3TransportMethodName: '',
    sect3CarNo: '',
    destination3Id: '',
    destination3Cd: '',
    destination3Name: '',
    destination3ChargeId: '',
    destination3ChargeCd: '',
    destination3ChargeName: '',
    sect4TransportId: '',
    sect4TransportCd: '',
    sect4TransportName: '',
    sect4TransportChargeId: '',
    sect4TransportChargeCd: '',
    sect4TransportChargeName: '',
    sect4TransportMethodId: '',
    sect4TransportMethodCd: '',
    sect4TransportMethodName: '',
    sect4CarNo: '',
    destination4Id: '',
    destination4Cd: '',
    destination4Name: '',
    destination4ChargeId: '',
    destination4ChargeCd: '',
    destination4ChargeName: '',
    sect5TransportId: '',
    sect5TransportCd: '',
    sect5TransportName: '',
    sect5TransportChargeId: '',
    sect5TransportChargeCd: '',
    sect5TransportChargeName: '',
    sect5TransportMethodId: '',
    sect5TransportMethodCd: '',
    sect5TransportMethodName: '',
    sect5CarNo: '',
    destination5Id: '',
    destination5Cd: '',
    destination5Name: '',
    destination5ChargeId: '',
    destination5ChargeCd: '',
    destination5ChargeName: '',






    lastProcessingTraderId: '',
    lastProcessingTraderCd: '',
    lastProcessingTraderName: '',
    lastProcessingChargeId: '',
    lastProcessingChargeCd: '',
    lastProcessingTraderChargeName: '',



    wasteCd: '',
    wasteName: '',

    disposalMethodCd: '',
    disposalMethodName: '',






//    amount: '',
    amount1: '',





    unitCd: '',
    unitName: '',

    packingCd: '',
    packingName: '',
//    packingAmount: '',
    packingAmount1: '',






    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',
    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',

    status: '',
    sect1At: '',
    destination1At: '',
    sect2At: '',
    destination2At: '',
    sect3At: '',
    destination3At: '',
    sect4At: '',
    destination4At: '',
    sect5At: '',
    destination5At: '',
    middleStart: '',
    middleEnd: '',
    lastProcessingStart: '',
    lastProcessingEnd: '',

    ediSendFlg: '',






    reserveAuthorityCd: '',
    lastReportFlg: '',
    middleProcessingInfoFlg: '',


    lastProcessingCnt: '',
  });

  const query = useParams();

  const history = useHistory();

  useEffect(() => {
    handleGetData(query);
  }, [query]);


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);




  const handleGetData = async (query) => {
    try {
     setOpen(!open);
      const res = await getEgsMProcessFlowDetail(query.id);
// apiなし      const res = await getProcessFlowDetail(query.id);
//      const res = await getProcessFlowId(query.id);


      // console.log(res.data);
{/*}
      // console.log('exhaustName',res.data.exhaustName);
*/}
      const today1 = dayjs().format('YYYY-MM-DD');
{/*}
      const today2 = dayjs().format('YYMMDD');
      // console.log('today1',today1,'today2',today2);
//      value.exhaustDate = today1;

//      const wkGarbageTag = res.data.reserveNo + '-' + res.data.reserveDate
      const wkGarbageTag = res.data.id + '-' + today2 + '-1'
//      const wkGarbageTag = res.data.reserveNo + '-' + DateTime.Now
//      // console.log(DateTime.Now)
//      // console.log(DateTime.Today)
      // console.log(wkGarbageTag)

*/}


      setValue({
//        garbageTag: res.data.garbageTag,
        garbageTag: res.data.garbageTag,
        processingSeqno: res.data.processingSeqno,
        manifestNo: res.data.manifestNo,
        arrangementNo: res.data.arrangementNo,
//        exhaustDate: res.data.exhaustDate,
        exhaustDate: today1,  // exhaustDate,  reserveDate
        exhaustId: res.data.exhaustId,
        exhaustCd: res.data.exhaustCd,
        exhaustName: res.data.exhaustName,
        exhaustChargeId: res.data.exhaustChargeId,
        exhaustChargeCd: res.data.exhaustChargeCd,
        exhaustChargeName: res.data.exhaustChargeName,
        sect1TransportId: res.data.sect1TransportId,
        sect1TransportCd: res.data.sect1TransportCd,
        sect1TransportName: res.data.sect1TransportName,
        sect1TransportChargeId: res.data.sect1TransportChargeId,
        sect1TransportChargeCd: res.data.sect1TransportChargeCd,
        sect1TransportChargeName: res.data.sect1TransportChargeName,
        sect1TransportMethodId: res.data.sect1TransportMethodId,
        sect1TransportMethodCd: res.data.sect1TransportMethodCd,
        sect1TransportMethodName: res.data.sect1TransportMethodName,
        sect1CarNo: res.data.sect1CarNo,
        destination1Id: res.data.destination1Id,
        destination1Cd: res.data.destination1Cd,
        destination1Name: res.data.destination1Name,
        destination1ChargeId: res.data.destination1ChargeId,
        destination1ChargeCd: res.data.destination1ChargeCd,
        destination1ChargeName: res.data.destination1ChargeName,
        sect2TransportId: res.data.sect2TransportId,
        sect2TransportCd: res.data.sect2TransportCd,
        sect2TransportName: res.data.sect2TransportName,
        sect2TransportChargeId: res.data.sect2TransportChargeId,
        sect2TransportChargeCd: res.data.sect2TransportChargeCd,
        sect2TransportChargeName: res.data.sect2TransportChargeName,
        sect2TransportMethodId: res.data.sect2TransportMethodId,
        sect2TransportMethodCd: res.data.sect2TransportMethodCd,
        sect2TransportMethodName: res.data.sect2TransportMethodName,
        sect2CarNo: res.data.sect2CarNo,
        destination2Id: res.data.destination2Id,
        destination2Cd: res.data.destination2Cd,
        destination2Name: res.data.destination2Name,
        destination2ChargeId: res.data.destination2ChargeId,
        destination2ChargeCd: res.data.destination2ChargeCd,
        destination2ChargeName: res.data.destination2ChargeName,
        sect3TransportId: res.data.sect3TransportId,
        sect3TransportCd: res.data.sect3TransportCd,
        sect3TransportName: res.data.sect3TransportName,
        sect3TransportChargeId: res.data.sect3TransportChargeId,
        sect3TransportChargeCd: res.data.sect3TransportChargeCd,
        sect3TransportChargeName: res.data.sect3TransportChargeName,
        sect3TransportMethodId: res.data.sect3TransportMethodId,
        sect3TransportMethodCd: res.data.sect3TransportMethodCd,
        sect3TransportMethodName: res.data.sect3TransportMethodName,
        sect3CarNo: res.data.sect3CarNo,
        destination3Id: res.data.destination3Id,
        destination3Cd: res.data.destination3Cd,
        destination3Name: res.data.destination3Name,
        destination3ChargeId: res.data.destination3ChargeId,
        destination3ChargeCd: res.data.destination3ChargeCd,
        destination3ChargeName: res.data.destination3ChargeName,
        sect4TransportId: res.data.sect4TransportId,
        sect4TransportCd: res.data.sect4TransportCd,
        sect4TransportName: res.data.sect4TransportName,
        sect4TransportChargeId: res.data.sect4TransportChargeId,
        sect4TransportChargeCd: res.data.sect4TransportChargeCd,
        sect4TransportChargeName: res.data.sect4TransportChargeName,
        sect4TransportMethodId: res.data.sect4TransportMethodId,
        sect4TransportMethodCd: res.data.sect4TransportMethodCd,
        sect4TransportMethodName: res.data.sect4TransportMethodName,
        sect4CarNo: res.data.sect4CarNo,
        destination4Id: res.data.destination4Id,
        destination4Cd: res.data.destination4Cd,
        destination4Name: res.data.destination4Name,
        destination4ChargeId: res.data.destination4ChargeId,
        destination4ChargeCd: res.data.destination4ChargeCd,
        destination4ChargeName: res.data.destination4ChargeName,
        sect5TransportId: res.data.sect5TransportId,
        sect5TransportCd: res.data.sect5TransportCd,
        sect5TransportName: res.data.sect5TransportName,
        sect5TransportChargeId: res.data.sect5TransportChargeId,
        sect5TransportChargeCd: res.data.sect5TransportChargeCd,
        sect5TransportChargeName: res.data.sect5TransportChargeName,
        sect5TransportMethodId: res.data.sect5TransportMethodId,
        sect5TransportMethodCd: res.data.sect5TransportMethodCd,
        sect5TransportMethodName: res.data.sect5TransportMethodName,
        sect5CarNo: res.data.sect5CarNo,
        destination5Id: res.data.destination5Id,
        destination5Cd: res.data.destination5Cd,
        destination5Name: res.data.destination5Name,
        destination5ChargeId: res.data.destination5ChargeId,
        destination5ChargeCd: res.data.destination5ChargeCd,
        destination5ChargeName: res.data.destination5ChargeName,






        lastProcessingTraderId: res.data.lastProcessingTraderId,
        lastProcessingTraderCd: res.data.lastProcessingTraderCd,
        lastProcessingTraderName: res.data.lastProcessingTraderName,
        lastProcessingChargeId: res.data.lastProcessingChargeId,
        lastProcessingChargeCd: res.data.lastProcessingChargeCd,
        lastProcessingTraderChargeName: res.data.lastProcessingTraderChargeName,



        wasteCd: res.data.wasteCd,
        wasteName: res.data.wasteName,

        disposalMethodCd: res.data.disposalMethodCd,
        disposalMethodName: res.data.disposalMethodName,






//        amount: res.data.amount,
        amount1: res.data.amount1,





        unitCd: res.data.unitCd,
        unitName: res.data.unitName,

        packingCd: res.data.packingCd,
        packingName: res.data.packingName,
//        packingAmount: res.data.packingAmount,
        packingAmount1: res.data.packingAmount1,






        memo: res.data.memo,
        memo1: res.data.memo1,
        memo2: res.data.memo2,
        memo3: res.data.memo3,
        memo4: res.data.memo4,
        memo5: res.data.memo5,
        toxicCd01: res.data.toxicCd01,
        toxicCd02: res.data.toxicCd02,
        toxicCd03: res.data.toxicCd03,
        toxicCd04: res.data.toxicCd04,
        toxicCd05: res.data.toxicCd05,
        toxicCd06: res.data.toxicCd06,

        status: res.data.status,
        sect1At: res.data.sect1At,
        destination1At: res.data.destination1At,
        sect2At: res.data.sect2At,
        destination2At: res.data.destination2At,
        sect3At: res.data.sect3At,
        destination3At: res.data.destination3At,
        sect4At: res.data.sect4At,
        destination4At: res.data.destination4At,
        sect5At: res.data.sect5At,
        destination5At: res.data.destination5At,
        middleStart: res.data.middleStart,
        middleEnd: res.data.middleEnd,
        lastProcessingStart: res.data.lastProcessingStart,
        lastProcessingEnd: res.data.lastProcessingEnd,

        ediSendFlg: res.data.ediSendFlg,






        reserveAuthorityCd: res.data.reserveAuthorityCd,
        lastReportFlg: res.data.lastReportFlg,
        middleProcessingInfoFlg: res.data.middleProcessingInfoFlg,


        lastProcessingCnt: res.data.lastProcessingCnt,

        });
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setValue',value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    try {
      const res = await createEgsTGarbageEdi(params);  // update ⇒ create  query.id,
      // console.log(res);
      history.push('/garbage_edi');               // egs_m_reserve_sets exhaust_plan edi_d01
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      garbageTag: value.garbageTag,
      processingSeqno: value.processingSeqno,
      manifestNo: value.manifestNo,
      arrangementNo: value.arrangementNo,
      exhaustDate: value.exhaustDate,
      exhaustId: value.exhaustId,
      exhaustCd: value.exhaustCd,
      exhaustName: value.exhaustName,
      exhaustChargeId: value.exhaustChargeId,
      exhaustChargeCd: value.exhaustChargeCd,
      exhaustChargeName: value.exhaustChargeName,
      sect1TransportId: value.sect1TransportId,
      sect1TransportCd: value.sect1TransportCd,
      sect1TransportName: value.sect1TransportName,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,
      sect1TransportChargeName: value.sect1TransportChargeName,
      sect1TransportMethodId: value.sect1TransportMethodId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1TransportMethodName: value.sect1TransportMethodName,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1Cd: value.destination1Cd,
      destination1Name: value.destination1Name,
      destination1ChargeId: value.destination1ChargeId,
      destination1ChargeCd: value.destination1ChargeCd,
      destination1ChargeName: value.destination1ChargeName,
      sect2TransportId: value.sect2TransportId,
      sect2TransportCd: value.sect2TransportCd,
      sect2TransportName: value.sect2TransportName,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportChargeCd: value.sect2TransportChargeCd,
      sect2TransportChargeName: value.sect2TransportChargeName,
      sect2TransportMethodId: value.sect2TransportMethodId,
      sect2TransportMethodCd: value.sect2TransportMethodCd,
      sect2TransportMethodName: value.sect2TransportMethodName,
      sect2CarNo: value.sect2CarNo,
      destination2Id: value.destination2Id,
      destination2Cd: value.destination2Cd,
      destination2Name: value.destination2Name,
      destination2ChargeId: value.destination2ChargeId,
      destination2ChargeCd: value.destination2ChargeCd,
      destination2ChargeName: value.destination2ChargeName,
      sect3TransportId: value.sect3TransportId,
      sect3TransportCd: value.sect3TransportCd,
      sect3TransportName: value.sect3TransportName,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportChargeCd: value.sect3TransportChargeCd,
      sect3TransportChargeName: value.sect3TransportChargeName,
      sect3TransportMethodId: value.sect3TransportMethodId,
      sect3TransportMethodCd: value.sect3TransportMethodCd,
      sect3TransportMethodName: value.sect3TransportMethodName,
      sect3CarNo: value.sect3CarNo,
      destination3Id: value.destination3Id,
      destination3Cd: value.destination3Cd,
      destination3Name: value.destination3Name,
      destination3ChargeId: value.destination3ChargeId,
      destination3ChargeCd: value.destination3ChargeCd,
      destination3ChargeName: value.destination3ChargeName,
      sect4TransportId: value.sect4TransportId,
      sect4TransportCd: value.sect4TransportCd,
      sect4TransportName: value.sect4TransportName,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportChargeCd: value.sect4TransportChargeCd,
      sect4TransportChargeName: value.sect4TransportChargeName,
      sect4TransportMethodId: value.sect4TransportMethodId,
      sect4TransportMethodCd: value.sect4TransportMethodCd,
      sect4TransportMethodName: value.sect4TransportMethodName,
      sect4CarNo: value.sect4CarNo,
      destination4Id: value.destination4Id,
      destination4Cd: value.destination4Cd,
      destination4Name: value.destination4Name,
      destination4ChargeId: value.destination4ChargeId,
      destination4ChargeCd: value.destination4ChargeCd,
      destination4ChargeName: value.destination4ChargeName,
      sect5TransportId: value.sect5TransportId,
      sect5TransportCd: value.sect5TransportCd,
      sect5TransportName: value.sect5TransportName,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportChargeCd: value.sect5TransportChargeCd,
      sect5TransportChargeName: value.sect5TransportChargeName,
      sect5TransportMethodId: value.sect5TransportMethodId,
      sect5TransportMethodCd: value.sect5TransportMethodCd,
      sect5TransportMethodName: value.sect5TransportMethodName,
      sect5CarNo: value.sect5CarNo,
      destination5Id: value.destination5Id,
      destination5Cd: value.destination5Cd,
      destination5Name: value.destination5Name,
      destination5ChargeId: value.destination5ChargeId,
      destination5ChargeCd: value.destination5ChargeCd,
      destination5ChargeName: value.destination5ChargeName,






      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingTraderCd: value.lastProcessingTraderCd,
      lastProcessingTraderName: value.lastProcessingTraderName,
      lastProcessingChargeId: value.lastProcessingChargeId,
      lastProcessingChargeCd: value.lastProcessingChargeCd,
      lastProcessingTraderChargeName: value.lastProcessingTraderChargeName,



      wasteCd: value.wasteCd,
      wasteName: value.wasteName,

      disposalMethodCd: value.disposalMethodCd,
      disposalMethodName: value.disposalMethodName,






//      amount: value.amount,
      amount1: value.amount1,





      unitCd: value.unitCd,
      unitName: value.unitName,

      packingCd: value.packingCd,
      packingName: value.packingName,
//      packingAmount: value.packingAmount,
      packingAmount1: value.packingAmount1,






      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,

      status: value.status,
      sect1At: value.sect1At,
      destination1At: value.destination1At,
      sect2At: value.sect2At,
      destination2At: value.destination2At,
      sect3At: value.sect3At,
      destination3At: value.destination3At,
      sect4At: value.sect4At,
      destination4At: value.destination4At,
      sect5At: value.sect5At,
      destination5At: value.destination5At,
      middleStart: value.middleStart,
      middleEnd: value.middleEnd,
      lastProcessingStart: value.lastProcessingStart,
      lastProcessingEnd: value.lastProcessingEnd,

      ediSendFlg: value.ediSendFlg,






      reserveAuthorityCd: value.reserveAuthorityCd,
      lastReportFlg: value.lastReportFlg,
      middleProcessingInfoFlg: value.middleProcessingInfoFlg,


      lastProcessingCnt: value.lastProcessingCnt,

    };
    return params;
  };

/*    必要？-S

  // const qrCodeUrl = "https://www.sdgs12.com/rsv/" + value.reserveNo + "/" + value.reserveSubno ;
  const qrCodeUrl = "https://www.sdgs12.com/rsv/" + query.id ;
  // console.log('qrCodeUrl',qrCodeUrl);

      必要？-E
*/

  return (
    <>
      <h1>廃棄物情報新規登録</h1>
      <FormBody
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={value}
        buttonType='更新' />

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
}
export default EgsTGarbageEdiEdit;
/*    必要？

      <h2>ＱＲコード</h2>
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>

*/
