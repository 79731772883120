import React, { useEffect, useState, useContext } from 'react';
import { getFirstSelList, deleteFirstSel } from '../../lib/api/first_sel';
import { getFirstSelUser } from '../../lib/api/first_sel';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import FirstSelListTable from './FirstSelListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const FirstSelList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getFirstSelList();
//      const res = await getFirstSelUser(currentUser.traderCd);
       if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
        res = await getFirstSelList();
      }else{                                       //事業場区分は管理者以外？
        res = await getFirstSelUser(currentUser.traderCd);
      }
      // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {

   alert(' ' + item.dispName + 'を削除します。よろしいですか？')

    // Alert.alert(
    //   'Alert Title',
    //   'My Alert Msg',
    //   [
    //      {text: 'Cancel', onPress: () => // console.log('Cancel Pressed'), style: 'cancel'},
    //     {text: 'OK', onPress: () => // console.log('OK Pressed')},
    //   ],
    //   { cancelable: false }
    // )

    // console.log('click', item.id);
    try {
      const res = await deleteFirstSel(item.id);
      // console.log(res.data);
      handleGetList();
    } catch (e) {
      console.log(e.response);
    }
  };

  return (
    <>
      <h1>１次マニフェスト一覧</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/receiptNew')}
        style={{ marginRight: 10 }}
      >
        荷受実行
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FirstSelListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default FirstSelList;

/*
      <SpaceRow height={20} />
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/receiptNew')}
      >
        新規作成
      </Button>
*/
