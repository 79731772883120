// /src/lib/api/first_sel.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getFirstSelList = () => {
  return client.get('/first_sels');
};

// 事業場選択一覧
export const getFirstSelUserDetail = (id) => {
  return client.get(`/first_sels/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getFirstSelUser = (id) => {
  return client.get(`/first_sels/${id}/showuser`);
};

// 事業場選択一覧　未選択のみ
export const getFirstSelUser0 = (id) => {
  return client.get(`/first_sels/${id}/showuser0`);
};

// 事業場選択一覧　指定したIDで選択済と、未選択を抽出
export const getFirstSelId = (id) => {
  return client.get(`/first_sels/${id}/showId`);
};

// 選択IDのみ一覧
export const getFirstSelId0 = (id) => {
  return client.get(`/first_sels/${id}/showId0`);
};

// 詳細
export const getFirstSelDetail = (id) => {
  return client.get(`/first_sels/${id}`);
};

// 運用中廃棄物 idは'all'
export const getFirstSelOperation = (id) => {
  return client.get(`/first_sels/${id}/showope`);
};

// 運用中廃棄物 idは'all'
export const getFirstSelOpe0 = (id) => {
  return client.get(`/first_sels/${id}/showope0`);
};

// 新規作成
// header情報を追加
export const createFirstSel = (params) => {
  return client.post('/first_sels', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateFirstSel = (id, params) => {
  return client.patch(`/first_sels/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteFirstSel = (id) => {
  return client.delete(`/first_sels/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
