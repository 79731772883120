import React, { useEffect, useState,      useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
  makeStyles
} from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getChargeUser } from '../../lib/api/charge';
import { createEditDelRequest } from '../../lib/api/edit_del_request';
import EditDelRequestForm from './EditDelRequestForm';
// style
const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EditDelRequest({ isOpen, doClose, currentUser, ediId , dataList2}) {

  // console.log("currentUser",currentUser);
  // console.log("dataList2",dataList2);

  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);
  const [value, setValue] = useState([]);
  const [sectChargeList, setSectChargeList] = useState('');
  const [selectedCharge, setSelectedCharge] = useState('');

  const history = useHistory();

  useEffect(() => {
    setValue({
      trader_name: currentUser.nickname,
      trader_cd: currentUser.traderCd,
      trader_kbn: currentUser.traderKbn,
      exhaust_cd: dataList2.exhaustCd,
      exhaust_name: dataList2.exhaustName,
      garbage_id: dataList2.id,
      manifest_no: dataList2.manifestNo,
      req_date: dayjs().format('YYYY-MM-DD'),
      req_charge: '',
      req_kind: '',
      req_comment: '',
    });
    handleGetChargeUser(currentUser.traderCd);
  }, [ediId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  // console.log('setvalue',value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async () => {
    if (window.confirm('マニフェスト情報「' + value.manifest_no + '」の' + value.req_kind + 'を依頼します。よろしいですか？')) {
      let shouldSubmit = false;
      let requestData = {
        trader_cd: value.trader_cd,
        exhaust_cd: value.exhaust_cd,
        exhaust_name: value.exhaust_name,
        garbage_id: value.garbage_id,
        manifest_no: value.manifest_no,
        req_charge: selectedCharge,
        req_date: value.req_date,
        req_kind: value.req_kind,
        req_comment: value.req_comment,
        status: '処理前',
        edi_code:  value.trader_name,
      };
  
      // 最大10項目まで登録可能
      let index = 1; // 項目番号の開始  
      for (let i = 1; i <= 15; i++) {
        if (value[`req_item_${i}`] && value[`old_data_${i}`] !== value[`new_data_${i}`]) {
          requestData[`req_item_${index}`] = value[`req_item_${i}`];
          requestData[`old_data_${index}`] = value[`old_data_${i}`];
          requestData[`new_data_${index}`] = value[`new_data_${i}`];
          index++;
        }
      }
      // req_kind に「取消」が含まれているかまたはvalue.req_commentにデータが入っている場合true に設定
      if (value.req_kind.includes('取消') || value.req_comment) {
        shouldSubmit = true;
      }
      // データ送信
      if (index > 1 || shouldSubmit) { // 変更があった場合のみ送信
        try {
          await createEditDelRequest(requestData);
          alert('登録が完了しました。');
        } catch (error) {
          alert('登録に失敗しました。');
        }
      } else {
        alert('変更点がないため、登録は行われませんでした。');
      }
      doClose();
      window.location.reload();
    }
  };

  const handleGetChargeUser = async (traderCd) => {
    try {
      const res = await getChargeUser(traderCd);
      console.log(res.data);
      setSectChargeList(res.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  const handleSect1ChargeChange = (event) => {
    const newSect1ChargeName = event.target.value;
    setSelectedCharge(newSect1ChargeName);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>マニフェスト情報修正・取消依頼</h2>
      </DialogTitle>
      <div style={{ textAlign: 'right' , marginRight : 20}}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
//          props.handleCancel();
          handleCancel();
        }}>    {/*  egs_m_reserve_sets  */}
          戻る
        </Button>
      </div>
      <DialogContent>

        <EditDelRequestForm
          dataList2={dataList2}
          value={value}
        />

        <Box bgcolor="primary.gray" color="primary.black" p={2} display="flex" alignItems="center" style={{ fontSize: "1.2rem" }}>
          <Typography variant="h5" style={{ fontWeight: 700, marginRight: "20px", color: "#c71585" }}>
            【依頼担当者】
          </Typography>
          <Select
            value={selectedCharge} // 選択された運搬担当者
            onChange={handleSect1ChargeChange} // 運搬担当者が変更された時の処理
            style={{ fontSize: "20px", color: "#ff00ff" }} // 選択後の文字の大きさを設定
          >
            {sectChargeList && sectChargeList.map((charge) => (
              <MenuItem key={charge.id} value={charge.chargeName}>{charge.chargeName}</MenuItem>
            ))}
          </Select>
          <Typography variant="h5" style={{ fontWeight: 700, marginRight: "20px", fontSize: "1.0rem", color: "#ff0000" }}>
            　← 「依頼担当者」を設定し、「依頼実行」を押してください → 　
          </Typography>          
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: 0, fontSize: "1.2rem" }}
            disabled={!selectedCharge}
          >
            依頼実行
          </Button>
        </Box>
        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EditDelRequest;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
