// /src/lib/api/receipt_unload_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptUnloadExhaustList = () => {
  return client.get('/receipt_unload_exhausts');
};

// 事業場選択一覧
export const getReceiptUnloadExhaustUserDetail = (id) => {
  return client.get(`/receipt_unload_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptUnloadExhaustUser = (id) => {
  return client.get(`/receipt_unload_exhausts/${id}/showuser`);
};

// 事業場選択一覧  荷降用
export const getReceiptUnloadExhaustUnload = (id) => {
  return client.get(`/receipt_unload_exhausts/${id}/showUnload`);
};

