import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
//import { getEgsMLinkageSetDetail } from '../../lib/api/egs_m_linkage_set';
//import { getEgsMLinkageDetail } from '../../lib/api/egs_m_linkage';
import { getEgsMLinkDetail } from '../../lib/api/egs_m_link';
//import { getLinkageDetail } from '../../lib/api/linkage';
//import { createEgsMReserveSet } from '../../lib/api/egs_m_reserve_set';
//import { createEgsMProcessFlow } from '../../lib/api/wk_reserve_set_name';
import { createEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
//import FormBody from './EgsMProcessFlowForm';
import FormBody from './ProcessFlowAddForm';

import dayjs from 'dayjs';

function ReserveSetAdd() {
  const [value, setValue] = useState({
    reserveNo: 0,

    reserveNo: '',
    reserveSubno: '',
    reserveName: '',
    reserveDate: '',
    exhaustId: 0,
    exhaustChargeId: 0,

    sect1TransportId: 0,
    sect1TransportChargeId: 0,
    sect1TransportMethodCd: '',
    sect1CarNo: '',
    destination1Id: 0,
    destination1ChargeId: 0,

    sect2TransportId: 0,
    sect2TransportChargeId: 0,
    sect2TransportMethodCd: '',
    sect2CarNo: '',
    destination2Id: 0,
    destination2ChargeId: 0,

    sect3TransportId: 0,
    sect3TransportChargeId: 0,
    sect3TransportMethodCd: '',
    sect3CarNo: '',
    destination3Id: 0,
    destination3ChargeId: 0,

    sect4TransportId: 0,
    sect4TransportChargeId: 0,
    sect4TransportMethodCd: '',
    sect4CarNo: '',
    destination4Id: 0,
    destination4ChargeId: 0,

    sect5TransportId: 0,
    sect5TransportChargeId: 0,
    sect5TransportMethodCd: '',
    sect5CarNo: '',
    destination5Id: 0,
    destination5ChargeId: 0,

    lastProcessingTraderId: 0,
    lastProcessingChargeId: 0,
    middleWasteFlg: '',
    lastPlaceFlg: '',
    wasteKindCd: '',
    wasteCd: '',
    disposalMethodCd: '',
    otherMethod: '',
    unitCd: '',
    packingCd: '',
    registTraderCd: '',
    approvalflg: '',
    effectiveflg: '',
    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',
    toxicCd01: '00',
    toxicCd02: '00',
    toxicCd03: '00',
    toxicCd04: '00',
    toxicCd05: '00',
    toxicCd06: '00',

  });

  const query = useParams();

  const history = useHistory();

  useEffect(() => {
    handleGetData(query);
  }, [query]);


  const handleGetData = async (query) => {
    try {
      // console.log(query.id);
//      const res = await getEgsMLinkageDetail(query.id);
      const res = await getEgsMLinkDetail(query.id);
//      const res = await getLinkageDetail(query.id);
      // console.log(res.data);
      const today1 = dayjs().format('YYYY-MM-DD');
      const today2 = dayjs().format('YYMMDD');
//      const reserveNo = query.id + '-' + res.data.planDate
//      const wkReserveNo = query.id + '-' + today2 + '-1'
//      const wkReserveNo = res.data.jwnetNo1 + '-' + wasteCd
//      // console.log(wkReserveNo,today1,today2)

      const wkJwnetNo1 = res.data.jwnetNo1

      setValue({
//        exhaustCd: res.data.traderCd1,    // exhaustCd,
//        exhaustName: res.data.traderName1,

//        memo: res.data.jwnetNo1,   //   処理フローには持っていないが、処理フロー番号作成用に使用

//        sect1TransportCd: res.data.traderCd2,    // sect1TransportCd,
//        sect1TransportName: res.data.traderName2,

//        reserveNo: res.data.reserveNo,
//        reserveNo: value.traderdataList00.jwnetNo + res.data.wasteCd,  // '加入者番号＋廃棄物CDで自動採番',
        jwnetNo1: res.data.jwnetNo1,
//        reserveNo: 'jwnetNo1-' + res.data.wasteCd,    // '加入者番号＋廃棄物CDで自動採番',
//        reserveNo: res.data.jwnetNo1 + '-' + res.data.wasteCd,    // '加入者番号＋廃棄物CDで自動採番',
        reserveNo: res.data.jwnetNo1 + '-0000000',
        reserveSubno: res.data.reserveSubno,
        reserveName: res.data.reserveName,
        reserveDate: today1,                                // res.data.reserveDate,
//        exhaustId:        res.data.egsMTraderId1,           //  res.data.exhaustId,
        exhaustId:        res.data.egsMTraderId2,           //  res.data.exhaustId,
        exhaustChargeId: res.data.exhaustChargeId,

//        sect1TransportId: res.data.egsMTraderId2,           // res.data.sect1TransportId,
        sect1TransportId: res.data.egsMTraderId1,           // res.data.sect1TransportId,
        sect1TransportChargeId: res.data.sect1TransportChargeId,
//        sect1TransportMethodCd: res.data.sect1TransportMethodCd,
        sect1TransportMethodCd: '0',
        sect1CarNo: res.data.sect1CarNo,
        destination1Id: res.data.destination1Id,
        destination1ChargeId: res.data.destination1ChargeId,

      });
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    try {
      const res = await createEgsMProcessFlow(params);   // update ⇒ create  query.id, createEgsMReserveSet
      // console.log(res);
      history.push('/egs_m_process_flows');  // egs_m_reserve_sets
    } catch (e) {
      console.log(e);
      console.log(e.response);
      // console.log(params);
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {

//    const wkReserveNo = value.exhaustCd + '-' + value.wasteCd
//    // console.log('wkReserveNo',wkReserveNo)
//  // console.log('jwnetNo1',wkJwnetNo1)

//if (reserveNo=='') {
  var wk_wasteCd = value.jwnetNo1 + '-' + value.wasteCd.slice(0, 4);
//}else{
//  var wk_wasteCd = value.reserveNo;
//}

// console.log(wk_wasteCd,value.wasteCd,value.reserveNo)

    const params = {
      // 2番目の要素から3番目の要素まで取り出し（ドメイン）


//      reserveNo: value.reserveNo,
//      reserveNo: value.exhaustId + '-' + value.wasteCd,    // wkReserveNo,  // value.reserveNo,
//      reserveNo: value.jwnetNo1 + '-' + value.wasteCd,    // wkReserveNo,  // value.reserveNo,
//      reserveNo: value.jwnetNo1 + '-' + wk_wasteCd,    // wkReserveNo,  // value.reserveNo,
      reserveNo: wk_wasteCd,    // wkReserveNo,  // value.reserveNo,
      reserveSubno: value.reserveSubno,
      reserveName: value.reserveName,
      reserveDate: value.reserveDate,
      exhaustId: value.exhaustId,
      exhaustChargeId: value.exhaustChargeId,

      sect1TransportId: value.sect1TransportId,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1ChargeId: value.destination1ChargeId,

//      sect2TransportId: value.sect2TransportId,                   画面にない項目
//      sect2TransportChargeId: value.sect2TransportChargeId,
//      sect2TransportMethodCd: value.sect2TransportMethodCd,
//      sect2CarNo: value.sect2CarNo,
//      destination2Id: value.destination2Id,
//      destination2ChargeId: value.destination2ChargeId,

//      sect3TransportId: value.sect3TransportId,
//      sect3TransportChargeId: value.sect3TransportChargeId,
//      sect3TransportMethodCd: value.sect3TransportMethodCd,
//      sect3CarNo: value.sect3CarNo,
//      destination3Id: value.destination3Id,
//      destination3ChargeId: value.destination3ChargeId,

//      sect4TransportId: value.sect4TransportId,
//      sect4TransportChargeId: value.sect4TransportChargeId,
//      sect4TransportMethodCd: value.sect4TransportMethodCd,
//      sect4CarNo: value.sect4CarNo,
//      destination4Id: value.destination4Id,
//      destination4ChargeId: value.destination4ChargeId,

//      sect5TransportId: value.sect5TransportId,
//      sect5TransportChargeId: value.sect5TransportChargeId,
//      sect5TransportMethodCd: value.sect5TransportMethodCd,
//      sect5CarNo: value.sect5CarNo,
//      destination5Id: value.destination5Id,
//      destination5ChargeId: value.destination5ChargeId,

      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingChargeId: value.lastProcessingChargeId,
//      middleWasteFlg: value.middleWasteFlg,
//      lastPlaceFlg: value.lastPlaceFlg,
      wasteKindCd: value.wasteKindCd,
      wasteCd: value.wasteCd,
      disposalMethodCd: value.disposalMethodCd,
//      otherMethod: value.otherMethod,
      unitCd: value.unitCd,
      packingCd: value.packingCd,
      registTraderCd: value.registTraderCd,
//      approvalflg: value.approvalflg,
//      effectiveflg: value.effectiveflg,
      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,
    };
    return params;
  };

  return (
    <>
      <h1>処理フロー登録編集</h1>
      <FormBody
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={value}
        buttonType='更新' />
    </>
  );
}
export default ReserveSetAdd;
