// /src/lib/api/receipt_load_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptLoadExhaustList = () => {
  return client.get('/receipt_load_exhausts');
};

// 事業場選択一覧
export const getReceiptLoadExhaustUserDetail = (id) => {
  return client.get(`/receipt_load_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptLoadExhaustUser = (id) => {
  return client.get(`/receipt_load_exhausts/${id}/showuser`);
};


