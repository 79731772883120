import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { transportMethodName } from '../../constants'
import './app.css';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#CFF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function ProcessFlowListTableForm(props) {
  const classes = useStyles();
  const { dataList2 } = props;

  return (
    <>
      <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  フロー名称
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  契約日
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveDate}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  ID
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.id}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  フロー番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveNo}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  フローサブ番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveSubno}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出事業場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  排出担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustChargeName}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物種類
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteKindName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  廃棄物名称
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteName}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  処分方法
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.disposalMethodName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  単位
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.unitName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  荷姿
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.packingName}</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  メモ
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  {dataList2.memo}
                </Typography>
              </Box>
            </Grid>

          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  有害物質
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  {dataList2.toxicName01}　　 {dataList2.toxicName02}　　 {dataList2.toxicName03}　　 {dataList2.toxicName04}　　 {dataList2.toxicName05}　　 {dataList2.toxicName06}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  最終処分場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingTraderName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingChargeName}</Typography>
              </Box>
            </Grid>
            <Grid item xs />
          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>
          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬情報
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    事業者
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    担当者
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    運搬方法
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    車両番号
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    運搬先事業場
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    担当者
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderRightWidth: 0 }}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>
                    備考
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  区間１
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1TransportName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1TransportChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1TransportMethodName}</Typography> */}
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect1TransportMethodCd)]}</Typography>

                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1CarNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination1Name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination1ChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo1}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  区間２
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect2TransportName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect2TransportChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect2TransportMethodName}</Typography> */}
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect2TransportMethodCd)]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect2CarNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination2Name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination2ChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo2}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  区間３
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect3TransportName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect3TransportChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect3TransportMethodName}</Typography> */}
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect3TransportMethodCd)]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect3CarNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination3Name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination3ChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo3}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  区間４
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect4TransportName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect4TransportChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect4TransportMethodName}</Typography> */}
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect4TransportMethodCd)]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect4CarNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination4Name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination4ChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo4}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  区間５
                </Typography>
              </Box>
            </Grid>
            <Grid container xs={10}>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect5TransportName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect5TransportChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  {/* <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect5TransportMethodName}</Typography> */}
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect5TransportMethodCd)]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect5CarNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination5Name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.value_box}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination5ChargeName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p={1} marginTop={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo5}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
        </Grid>

        <Grid
            container
            spacing={0}
            className={classes.row}
          >
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  処分受託者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box bgcolor="primary.gray" color="primary.black" p={1}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  {dataList2.processTrustTraderName}
                </Typography>
              </Box>
            </Grid>

          </Grid>

        </div>

    </>
  )
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default ProcessFlowListTableForm;
