import applyCaseMiddleware from "axios-case-converter"
import axios from "axios"

// applyCaseMiddleware:
// axiosで受け取ったレスポンスの値をスネークケース→キャメルケースに変換
// または送信するリクエストの値をキャメルケース→スネークケースに変換してくれるライブラリ

// ヘッダーに関してはケバブケースのままで良いので適用を無視するオプションを追加
const options = {
  ignoreHeaders: true
}

const client = applyCaseMiddleware(axios.create({
//	baseURL: "http://3.113.8.29:3001/api/v1"
	baseURL: "https://www.sdgs12.com:3001/api/v1"
//	baseURL: "https://www.sdgs12.com/api/v1"
}), options)

export default client
