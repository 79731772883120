import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button,   Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { createEgsMPermit, getEgsMPermitDetail } from '../../lib/api/egs_m_permit';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
//import FormBody from './PermitNewForm';
//import FormBody from './PermitForm';
import FormBody from './PermitNewForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMPermitCreat({ isOpen, doClose, processId, currentUser }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    egsMTraderId: 0,
    traderCd: currentUser.traderCd, //   '',
//    chargeCd: '',
//    chargeName: '',
//    chargeKana: '',
//    email: '',
//    emailFlg: '',
//    ediKbn: 0,
//    effectiveflg: '',

//  egsMTraderId: res.data.egsMTraderId,
//  traderCd: res.data.traderCd,

    traderName: '',


    permitNo: '',
    municipalityName: '',
    permitType: '',
    dispName: '',
    content: '',
    filePath: '',
    fileName: '',
    mimeType: '',
    effectiveflg: '',

  });


/*
  if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
    const wk_traderCd = currentUser.traderCd
  }else{                                       //事業場区分は管理者以外？
    const wk_traderCd = value.traderCd
  }
*/

  useEffect(() => {
    handleGetData(processId);
  }, [processId]);


  useEffect(() => {
    traderGetList(value.traderCd);
  }, [value.traderCd]);


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMPermitDetail(processId);
      // console.log(res.data);
      setValue({
        egsMTraderId: res.data.egsMTraderId,
        traderCd: res.data.traderCd,
        // chargeCd: res.data.chargeCd,
        // chargeName: res.data.chargeName,
        // chargeKana: res.data.chargeKana,
        // email: res.data.email,
        // emailFlg: res.data.emailFlg,
        // ediKbn: res.data.ediKbn,
        // effectiveflg: res.data.effectiveflg,

//        egsMTraderId: res.data.egsMTraderId,
//        traderCd: res.data.traderCd,
        permitNo: res.data.permitNo,
        municipalityName: res.data.municipalityName,
        permitType: res.data.permitType,
        dispName: res.data.dispName,
        content: res.data.content,
        filePath: res.data.filePath,
        fileName: res.data.fileName,
        mimeType: res.data.mimeType,
        effectiveflg: res.data.effectiveflg,

      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const traderGetList = async () => {
    try {
      setLoading(true);
      const resT02 = await getTraderUser(value.traderCd);
      // console.log(resT02.data);
      setValue({
        egsMTraderId: resT02.data[0].id,
        traderCd: resT02.data[0].traderCd,
        traderName: resT02.data[0].traderName,
//        chargeCd: resT02.data[0].chargeCdMax,
        chargeCd: resT02.data[0].nextChargeCd,
        chargeName: value.chargeName,
        chargeKana: value.chargeKana,
        email: value.email,
        emailFlg: value.emailFlg,
        ediKbn: value.ediKbn,
        effectiveflg: value.effectiveflg,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    if (window.confirm(' 許可証「' + value.traderCd + ':' + value.traderName + ':' + value.permitNo + '」を登録します。よろしいですか？')) {
      try {
        e.preventDefault();
        // 追加
        const params = generateParams();
        // console.log('params',params)
        try {
          const res = await createEgsMPermit(params);
          // console.log(res);
          handleCancel();
    //      history.push('/permit');  // egs_m_permits
          window.location.reload();
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      egsMTraderId: value.egsMTraderId,
      traderCd: value.traderCd,
      permitNo: value.permitNo,
      municipalityName: value.municipalityName,
      permitType: value.permitType,
      dispName: value.dispName,
      content: value.content,
      filePath: value.filePath,
      fileName: value.fileName,
      mimeType: value.mimeType,
      effectiveflg: value.effectiveflg,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>許可証新規作成</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
//              handleBack();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
{/*}
      <Button variant='contained'
//       onClick={() => history.push('/permit')}>
        onClick={e => {
          e.preventDefault();
//          handleBack();
          handleCancel();
        }}>
        戻る
      </Button>
*/}
        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          currentUser={currentUser}
          buttonType='更新' />  {/*作成*/}

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMPermitCreat;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
