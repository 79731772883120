// Form.jsx
import React, { useState, useEffect ,  useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  InputLabel,
} from '@material-ui/core';

import {
  DataGridPro,
  jaJP
} from '@mui/x-data-grid-pro'

import SpaceRow from '../commons/SpaceRow';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getLinkageUser } from '../../lib/api/linkage';
import { getTraderSelList } from '../../lib/api/trader_sel';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';

//import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getWasteCdList } from '../../lib/api/waste_cd';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';
import { getEditDelRequestManiNo } from '../../lib/api/edit_del_request';

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '200ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    marginBottom: 8,
    width: "70vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  memo_box: {
    color: "#C44",
//    fontcolor: "rgb(050, 050, 050)",
//    foreground: "rgb(001, 001, 001)",
    background: "rgb(225, 242, 188)",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

const EdiD01ItemForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleChange, handleSubmit, value, buttonType,    inputRef1, inputError1, inputRef2, inputError2 } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);

  const [dataListC01, setdataListC01] = useState([]);
  const [dataListC02, setdataListC02] = useState([]);
  const [newData, setnewData] = useState([]);

  const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
  const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
  const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
  const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
  const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
  const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}

  const pdfRef = React.createRef();

//  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  console.log("EditDelRequestManiNo value.manifestNo",value.manifestNo)

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    fetchEditDelRequestManiNo(); // getEditDelRequestManiNoを呼び出す処理を追加
  }, [value.manifestNo]);


  const fetchEditDelRequestManiNo = async () => {
    try {
      const response = await getEditDelRequestManiNo(value.manifestNo); // API呼び出し
      setnewData(response.data);
      console.log("EditDelRequestManiNo data:", newData);
      
    } catch (error) {
      console.error("Failed to fetch EditDelRequestManiNo:", error);
    }
  };

  const handleGetList = async () => {
    console.log("handleGetList value",value)
    try {
     setOpen(!open);
//    const dataListWaste = await getEgsMWasteclassList();
    const dataListWaste = await getWasteCdList();
    setDataListWaste(dataListWaste.data);
//    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
//    // console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);

//      const resC1 = await getEgsMChargeList();
//      // console.log('resC1',resC1.data);
//      setdataListC01(resC1.data);
      const dataListC01 = await getEgsMChargeList();
//      // console.log('dataListC01',dataListC01.data);
      setdataListC01(dataListC01.data);

//      const resC2 = await getChargeSelList();
//      // console.log('resC2',resC2.data);
//      setdataListC02(resC2.data);
      const dataListC02 = await getChargeSelList();
//      // console.log('dataListC02',dataListC02.data);
      setdataListC02(dataListC02.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const dataListWasteKind = dataListWaste.filter(dataListWaste =>
    { return dataListWaste.wasteKindCd == dataListWaste.wasteCd });

  const dataListWasteCd = dataListWaste.filter(dataListWaste =>
    { return dataListWaste.wasteKindCd == value.wasteKindCd });

const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//// console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//// console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//// console.log('disposaldataList',disposaldataList);

//const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
// console.log('transport',value.sect1TransportMethodCd,trans1dataList);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//// console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });
//// console.log('packingdataList',packingdataList);


// 区間１～５

//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.id == value.exhaustId });
//  const chargedataList00 = dataListC01.filter(resC1 => { return resC1.egsMTraderid == value.exhaustId });
const chargedataList00 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.exhaustId });
//  // console.log('chargedataList00','->',chargedataList00,'<-',value.exhaustId);

//  const chargedataList07 = dataListC02.filter(resC2 => { return resC2.id == value.lastProcessingChargeId });
  const chargedataList07 = dataListC02.filter(dataListC02 => { return dataListC02.egsMTraderId == value.lastProcessingTraderId });
//  // console.log('chargedataList07','->',chargedataList07,'<-',value.lastProcessingTraderId);

const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });
const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });
const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });
const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });
const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });

const [errorPackingAmount1, setErrorPackingAmount1] = useState(false);  // 荷姿数量

const checkValidation = () => {
  let available = true;
  return available;
}

const cols = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    width: 80
  },
  {
    field: 'reqDate',
    headerName: '依頼日',
    hide: true,
    width: 100
  },
  {
    field: 'reqCharge',
    headerName: '依頼担当者',
    hide: true,
    width: 100
  },
  {
    field: 'status',
    headerName: '処理状況',
    hide: true,
    width: 100
  },
  {
    field: 'exhaustName',
    headerName: '排出事業場',
    hide: true,
    width: 300
  },
  {
    field: 'manifestNo',
    headerName: 'マニフェストＮｏ',
    hide: true,
    width: 140
  },
  {
    field: 'reqKind',
    headerName: '依頼種別',
    hide: true,
    width: 200
  },
  {
    field: 'reqComment',
    headerName: '補足事項',
    cellClassName: 'newData-cell',
    width: 200
  },
  {
    field: 'reqItem_1',
    headerName: '依頼項目１',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_1',
    headerName: '修正前１',
    width: 100
  },
  {
    field: 'newData_1',
    headerName: '修正後１',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_2',
    headerName: '依頼項目２',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_2',
    headerName: '修正前２',
    width: 100
  },
  {
    field: 'newData_2',
    headerName: '修正後２',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_3',
    headerName: '依頼項目３',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_3',
    headerName: '修正前３',
    width: 100
  },
  {
    field: 'newData_3',
    headerName: '修正後３',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_4',
    headerName: '依頼項目４',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_4',
    headerName: '修正前４',
    width: 100
  },
  {
    field: 'newData_4',
    headerName: '修正後４',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_5',
    headerName: '依頼項目５',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_5',
    headerName: '修正前５',
    width: 100
  },
  {
    field: 'newData_5',
    headerName: '修正後５',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_6',
    headerName: '依頼項目６',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_6',
    headerName: '修正前６',
    width: 100
  },
  {
    field: 'newData_6',
    headerName: '修正後６',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_7',
    headerName: '依頼項目７',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_7',
    headerName: '修正前７',
    width: 100
  },
  {
    field: 'newData_7',
    headerName: '修正後７',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_8',
    headerName: '依頼項目８',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_8',
    headerName: '修正前８',
    width: 100
  },
  {
    field: 'newData_8',
    headerName: '修正後８',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_9',
    headerName: '依頼項目９',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_9',
    headerName: '修正前９',
    width: 100
  },
  {
    field: 'newData_9',
    headerName: '修正後９',
    cellClassName: 'newData-cell',
    width: 100
  },
  {
    field: 'reqItem_10',
    headerName: '依頼項目１０',
    cellClassName: 'reqItem-cell',
    width: 120
  },
  {
    field: 'oldData_10',
    headerName: '修正前１０',
    width: 100
  },
  {
    field: 'newData_10',
    headerName: '修正後１０',
    cellClassName: 'newData-cell',
    width: 100
  },
]

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.container}>
        <Box sx={{ height: 160, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols}
            rows={newData}
            density="compact"
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </div>
      <SpaceRow height={10} />
      <div className={classes.container} ref={pdfRef}>

      <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
              最新状態
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography variant="h7" style={{ fontWeight: 700 }}> {value.statusName} </Typography>

            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                排出日
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='exhaustDate' variant="outlined" type='date' name='exhaustDate' halfWidth margin="dense" value={value.exhaustDate} onChange={(e) => handleChange(e)} />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                マニフェスト番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>

            <Typography variant="h7" style={{ fontWeight: 700 }}> {value.manifestNo} </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs />  */}

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                ゴミタグ番号
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='garbageTag' variant="outlined" type='text' name='garbageTag' disabled halfWidth margin="dense" value={value.garbageTag} />  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.garbageTag} </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs />  */}
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出事業者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}      justifyContent={"flex-start"} alignItems={"center"}>
{/*}              <TextField id='exhaustName' variant="outlined" type='text' name='exhaustName' fullWidth margin="dense" value={value.exhaustName} /> */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.exhaustName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                排出担当者
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='exhaustChargeId'
                label='排出担当者'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
              {chargedataList00.map((chargedataList00) => (
                <MenuItem
                  key={chargedataList00.id}
                  value={chargedataList00.id}
                >
                  {chargedataList00.chargeCd}:{chargedataList00.chargeName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.row}
        >

<Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物種類
              </InputLabel>
            </Box>
          </Grid>


          <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>

            <Select
                id='wasteKindCd'
                label='廃棄物種類'
                variant="outlined"
                type='text'
                name='wasteKindCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteKindCd}
                fullWidth
//////                error={errorWasteKindCd}
              >

                {dataListWasteKind.map((dataListWasteKind) => (
                  <MenuItem
                    key={dataListWasteKind.wasteKindCd}  // wasteclassCd
                    value={dataListWasteKind.wasteKindCd}  //wasteclassCd
                  >
                    {dataListWasteKind.wasteKindCd}:{dataListWasteKind.wasteKindName}
                  </MenuItem>
                ))}
                </Select>

            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                廃棄物名称
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='wasteCd'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.wasteCd}
                fullWidth
//////                error={errorWasteCd}
              >

                {dataListWasteCd.map((dataListWasteCd) => (
                  <MenuItem
                    key={dataListWasteCd.wasteCd}
                    value={dataListWasteCd.wasteCd}
                  >
                    {dataListWasteCd.wasteCd}:{dataListWasteCd.wasteName}
                  </MenuItem>

                ))}

              </Select>
            </Box>
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
{/*}            <Box className={classes.memo_box} p={1} display={"flex"} alignItems={"center"}  >  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}>廃棄物の数量<br />（小数点以下３桁）</Typography>
{/*}              <Typography variant="h7" style={{ fontWeight: 700 ,fontcolor : red}}>（小数点以下３桁）</Typography>  */}
            </Box>
{/*}
            <Box className={classes.memo_box} p={1} display={"flex"} alignItems={"center"} >
              <Typography variant="h7" style={{ fontWeight: 700 }}>（小数点以下３桁）</Typography>
            </Box>
*/}
{/*}
            <Box bgcolor="primary.gray" color="red" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>（小数点以下３桁）</Typography>
           </Box>
*/}
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <Box bgcolor="primary.gray" color="primary.black" p={1}>  */}
{/*}
                <TextField id='amount1' variant="outlined" type='number' name='amount1' size="small" halfWidth margin="dense" value={value.amount1}
//                  pattern="100(.0{1,3})?|\d{1,3}(.\d{1,3})?"    // 小数点以下３桁チェック　NG
                  onChange={(e) => handleChange(e)} />
*/}
              <TextField id='amount1' hiddenLabel variant="outlined" type='number' name='amount1' halfWidth margin="dense"
//              inputProps={{ maxLength: 7, pattern: "^[0-9_]+$" }}     // ^[a-zA-Z0-9_]+$
//              inputProps={{ maxLength: 7, step: "0.001", pattern: "100(.0{1,2})?|\d{1,2}(.\d{1,2})?" }}     // ^[a-zA-Z0-9_]+$

                error={inputError1}
                inputProps={{ max: 99999.999, min:0.001, step:0.001 }}
                inputRef={inputRef1}
                helperText={inputRef1?.current?.validationMessage}

                onChange={(e) => handleChange(e)}
                value={value.amount1} />

{/*}            <TextField id='unitName' variant="outlined" type='text' name='unitName' halfWidth margin="dense" value={value.unitName} /> */}
{/*}                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.unitName} </Typography>  */}

                <Grid item xs>
                  <Select
                    id='unitCd'
                    label='単位'
                    variant="outlined"
                    type='text'
                    name='unitCd'
                    margin="dense"
                    onChange={(e) => handleChange(e)}
                    value={value.unitCd}
                    fullWidth
      //0829                error={errorUnitCd}
                  >
                    {dataListUnit.map((dataListUnit) => (
                      <MenuItem
                        key={dataListUnit.unitCd}
                        value={dataListUnit.unitCd}
                      >
                        {dataListUnit.unitCd}:{dataListUnit.unitName}
                      </MenuItem>
                    ))}
                    </Select>
          </Grid>

            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>荷姿の数量<br />（整数）</Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='packingAmount1' hiddenLabel variant="outlined" type='number' name='packingAmount1' halfWidth margin="dense"
                inputProps={{ max: 99999, min:0, step:1 }}
                inputRef={inputRef2}
                helperText={inputRef2?.current?.validationMessage}

                onChange={(e) => handleChange(e)}
              value={value.packingAmount1} />

                  <Select
                    id='packingCd'
                    label='荷姿'
                    variant="outlined"
                    type='text'
                    name='packingCd'
                    margin="dense"
                    onChange={(e) => handleChange(e)}
                    value={value.packingCd}
                    fullWidth
    //0829                error={errorPackingCd}
                  >
                    {dataListPacking.map((dataListPacking) => (
                      <MenuItem
                        key={dataListPacking.packingCd}
                        value={dataListPacking.packingCd}
                      >
                        {dataListPacking.packingCd}:{dataListPacking.packingName}
                      </MenuItem>
                    ))}
                </Select>

              </Box>
{/*}            </Box> */}
          </Grid>

        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                備考１～５
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo1' label='' hiddenLabel variant="outlined" type='text' name='memo1' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo1} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo2' label='' hiddenLabel variant="outlined" type='text' name='memo2' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo2} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo3' label='' hiddenLabel variant="outlined" type='text' name='memo3' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo3} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo4' label='' hiddenLabel variant="outlined" type='text' name='memo4' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo4} fullWidth />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo5' label='' hiddenLabel variant="outlined" type='text' name='memo5' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo5} fullWidth />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"} style={{ borderLeft: "1px solid #CCC" }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                処理フローメモ
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <TextField id='memo' label='' hiddenLabel variant="outlined" type='text' name='memo' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.memo} fullWidth />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                処分方法
              </Typography>
            </Box>
          </Grid>
{/*}          <Grid item xs>  */}
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='disposalMethodCd'
                label='処分方法'
                variant="outlined"
                type='text'
                name='disposalMethodCd'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.disposalMethodCd}
              >
              {disposaldataList.map((disposaldataList) => (
                <MenuItem
                  key={disposaldataList.disposalMethodCd}
                  value={disposaldataList.disposalMethodCd}
                >
                  {disposaldataList.disposalMethodCd}:{disposaldataList.disposalMethodName}
                </MenuItem>
              ))}
              </Select>
            </Box>
          </Grid>

          <Grid item xs={2}>
{/*}        <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginTop={1} marginBottom={1}>  */}
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分の場所
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Select
                id='lastReportFlg'
                label='最終処分事業場記載フラグ'
                variant="outlined"
                type='text'
                name='lastReportFlg'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.lastReportFlg}
                fullWidth
              >
                <MenuItem value={value.lastReportFlg}>
                  <em>最終処分事業場記載フラグ</em>
                </MenuItem>
{/*}                <MenuItem value={'0:委託契約書記載のとおり'}>0:委託契約書記載のとおり</MenuItem>
                <MenuItem value={'1:最終処分事業場（予定）を記載する'}>1:最終処分事業場（予定）を記載する</MenuItem>  */}
                <MenuItem value={'0'}>0:委託契約書記載のとおり</MenuItem>
                <MenuItem value={'1'}>1:最終処分事業場（予定）を記載する</MenuItem>
              </Select>
            </Box>
          </Grid>

          </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}      marginBottom={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={10}>
          <Box bgcolor="primary.gray" color="primary.black" p={1}>
            <Typography
             variant="h7"
             style={{ fontWeight: 700 }}>
              {value.toxicName01}　{value.toxicName02}　{value.toxicName03}　
              {value.toxicName04}　{value.toxicName05}　{value.toxicName06}
            </Typography>
          </Box>
        </Grid>
          <Grid item xs />
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分業者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
{/*}              <TextField id='lastProcessingTraderName' variant="outlined" type='text' name='lastProcessingTraderName' fullWidth margin="dense" value={value.lastProcessingTraderName} />  */}
              <Typography variant="h7" style={{ fontWeight: 700 }}> {value.lastProcessingTraderName} </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}  >
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                最終処分担当者
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box bgcolor="primary.gray" color="primary.black" p={1}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{value.lastProcessingChargeName}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
          style={{
            borderTopWidth: 2,
            borderTopColor: "black"
          }}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                運搬情報
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  事業者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬方法
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  車両番号
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  運搬先事業場
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>
                  担当者
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <InputLabel style={{ color: 'black', fontSize: 14, fontWeight: 700 }} required>
                区間１
              </InputLabel>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect1TransportName' variant="outlined" type='text' name='sect1TransportName' halfWidth margin="dense" value={value.sect1TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect1TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect1TransportChargeId' label='区間１運搬担当者' variant="outlined" type='text' name='sect1TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeId} >
                  {chargedataList01.map((chargedataList01) => (<MenuItem key={chargedataList01.id} value={chargedataList01.id} > {chargedataList01.chargeCd}:{chargedataList01.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
                  value={value.sect1TransportMethodCd} >
                  {trans1dataList.map((trans1dataList) => (<MenuItem key={trans1dataList.transportMethodCd} value={trans1dataList.transportMethodCd} > {trans1dataList.transportMethodCd}:{trans1dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
                <TextField id='sect1CarNo' hiddenLabel variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} fullWidth />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination1Name' variant="outlined" type='text' name='destination1Name' halfWidth margin="dense" value={value.destination1Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination1Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間１運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList11.map((chargedataList11) => (<MenuItem key={chargedataList11.id} value={chargedataList11.id} >   {chargedataList11.chargeCd}:{chargedataList11.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間２
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect2TransportName' variant="outlined" type='text' name='sect2TransportName' halfWidth margin="dense" value={value.sect2TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect2TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect2TransportChargeId' label='区間２運搬担当者' variant="outlined" type='text' name='sect2TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect2TransportChargeId} >
                  {chargedataList02.map((chargedataList02) => (<MenuItem key={chargedataList02.id} value={chargedataList02.id} > {chargedataList02.chargeCd}:{chargedataList02.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect2TransportMethodCd} >
                  {trans2dataList.map((trans2dataList) => (<MenuItem key={trans2dataList.transportMethodCd} value={trans2dataList.transportMethodCd} > {trans2dataList.transportMethodCd}:{trans2dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
                <TextField id='sect2CarNo' hiddenLabel variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} fullWidth />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination2Name' variant="outlined" type='text' name='destination2Name' halfWidth margin="dense" value={value.destination2Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination2Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination2ChargeId' label='区間２運搬先担当者' variant="outlined" type='text' name='destination2ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeId} >
                  {chargedataList12.map((chargedataList12) => (<MenuItem key={chargedataList12.id} value={chargedataList12.id} >   {chargedataList12.chargeCd}:{chargedataList12.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間３
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect3TransportName' variant="outlined" type='text' name='sect3TransportName' halfWidth margin="dense" value={value.sect3TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect3TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect3TransportChargeId' label='区間３運搬担当者' variant="outlined" type='text' name='sect3TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect3TransportChargeId} >
                  {chargedataList03.map((chargedataList03) => (<MenuItem key={chargedataList03.id} value={chargedataList03.id} > {chargedataList03.chargeCd}:{chargedataList03.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect3TransportMethodCd} >
                  {trans3dataList.map((trans3dataList) => (<MenuItem key={trans3dataList.transportMethodCd} value={trans3dataList.transportMethodCd} > {trans3dataList.transportMethodCd}:{trans3dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect3CarNo' variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination3Name' variant="outlined" type='text' name='destination3Name' halfWidth margin="dense" value={value.destination3Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination3Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination3ChargeId' label='区間３運搬先担当者' variant="outlined" type='text' name='destination3ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeId} >
                  {chargedataList13.map((chargedataList13) => (<MenuItem key={chargedataList13.id} value={chargedataList13.id} >   {chargedataList13.chargeCd}:{chargedataList13.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間４
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect4TransportName' variant="outlined" type='text' name='sect4TransportName' halfWidth margin="dense" value={value.sect4TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect4TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect4TransportChargeId' label='区間４運搬担当者' variant="outlined" type='text' name='sect4TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect4TransportChargeId} >
                  {chargedataList04.map((chargedataList04) => (<MenuItem key={chargedataList04.id} value={chargedataList04.id} > {chargedataList04.chargeCd}:{chargedataList04.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect4TransportMethodCd} >
                  {trans4dataList.map((trans4dataList) => (<MenuItem key={trans4dataList.transportMethodCd} value={trans4dataList.transportMethodCd} > {trans4dataList.transportMethodCd}:{trans4dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect4CarNo' hiddenLabel variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination4Name' variant="outlined" type='text' name='destination4Name' halfWidth margin="dense" value={value.destination4Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination4Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間４運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList14.map((chargedataList14) => (<MenuItem key={chargedataList14.id} value={chargedataList14.id} >   {chargedataList14.chargeCd}:{chargedataList14.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                区間５
              </Typography>
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='sect5TransportName' variant="outlined" type='text' name='sect5TransportName' halfWidth margin="dense" value={value.sect5TransportName} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.sect5TransportName} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect5TransportChargeId' label='区間５運搬担当者' variant="outlined" type='text' name='sect5TransportChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.sect5TransportChargeId} >
                  {chargedataList05.map((chargedataList05) => (<MenuItem key={chargedataList05.id} value={chargedataList05.id} > {chargedataList05.chargeCd}:{chargedataList05.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
                  //      onChange={(e) => handleChange(e)}
                  value={value.sect5TransportMethodCd} >
                  {trans5dataList.map((trans5dataList) => (<MenuItem key={trans5dataList.transportMethodCd} value={trans5dataList.transportMethodCd} > {trans5dataList.transportMethodCd}:{trans5dataList.transportMethodName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
                <TextField fullWidth id='sect5CarNo' variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1}>
{/*}                <TextField id='destination5Name' variant="outlined" type='text' name='destination5Name' halfWidth margin="dense" value={value.destination5Name} />  */}
                <Typography variant="h7" style={{ fontWeight: 700 }}> {value.destination5Name} </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.value_box}>
              <Box p={1} marginTop={1}>
                <Select fullWidth id='destination1ChargeId' label='区間５運搬先担当者' variant="outlined" type='text' name='destination1ChargeId' margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeId} >
                  {chargedataList15.map((chargedataList15) => (<MenuItem key={chargedataList15.id} value={chargedataList15.id} >   {chargedataList15.chargeCd}:{chargedataList15.chargeName} </MenuItem>))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
//            handleSubmit(e);
            if (checkValidation()) {
              handleSubmit(e);
            }
         }}
          style={{ marginRight: 10 }}
          disabled={!value.amount1 ||inputError1 || inputError2}
        >
          {buttonType}
        </Button>
      </div>

    </>
  );
};
const styles = {
  grid: {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#c71585',
      color: '#fff',
    }
  }
 }
 export default EdiD01ItemForm;