import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button,   Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { updateEgsMPermit, getEgsMPermitDetail } from '../../lib/api/egs_m_permit';
//import FormBody from './PermitForm';
import FormBody from './PermitEditForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function PermitEdit({ isOpen, doClose, processId, currentUser, dataList2 }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    permitNo: 0,

    traderCd: '',
    traderName: '',

    municipalityName: '',
    permitType: '',
    dispName: '',
    content: '',
    imgSeqno: '',
  });

  useEffect(() => {
    handleGetData(processId, dataList2);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId, dataList2) => {
    try {
      setLoading(true);
      const res = await getEgsMPermitDetail(processId);
      // console.log(res.data);
      setValue({
        traderCd: res.data.traderCd,
//        traderName: res.data.traderName,
        traderName: dataList2.traderName,
        permitNo: res.data.permitNo,
        municipalityName: res.data.municipalityName,
        permitType: res.data.permitType,
        dispName: res.data.dispName,
        content: res.data.content,
        imgSeqno: res.data.imgSeqno,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    if (window.confirm(' 許可証「' + value.traderCd + ':' + value.traderName + ':' + value.permitNo + '」を更新します。よろしいですか？')) {
        e.preventDefault();
      // 追加
      const params = generateParams();
      try {
        const res = await updateEgsMPermit(processId, params);
        // console.log(res);
        handleCancel();
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      traderCd: value.traderCd,
      permitNo: value.permitNo,
      municipalityName: value.municipalityName,
      permitType: value.permitType,
      dispName: value.dispName,
      content: value.content,
      imgSeqno: value.imgSeqno,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>許可証マスタ編集</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
//              handleBack();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          currentUser={currentUser}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default PermitEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
