// /src/lib/api/egs_t_garbage_edi.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsTGarbageEdiList = () => {
  return client.get('/egs_t_garbage_edis');
};

// 事業所指定一覧 idは事業所CD
export const getEgsTGarbageEdiUser = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showuser`);
};

// 作成済みチェック idはgarbage_tag
export const getEgsTGarbageEdiGbt = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showgbt`);
};

// 詳細（追加・更新用）
export const getEgsTGarbageEdiDetail = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showFirst`);
};

// 請求データ件数（事業所ごと）取り出し
export const getEgsTGarbageEdiInvoiceItem = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showinvoiceitem`);
};
// 請求データ件数（事業所、廃棄物種類ごと）取り出し
export const getEgsTGarbageEdiInvoiceData = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showinvoicedata`);
};
// 請求データ件数（詳細）取り出し
export const getEgsTGarbageEdiInvoiceDetail = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/showinvoicedetail`);
};
// 1次マニフェスト更新
// 詳細（追加・更新用）
export const getEgsTGarbageEdiDetailFirst = (id) => {
  return client.get(`/egs_t_garbage_edis/${id}/updateFirst`);
};

// 新規作成
// header情報を追加
export const createEgsTGarbageEdi = (params) => {
  return client.post('/egs_t_garbage_edis', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTGarbageEdi = (id, params) => {
  return client.patch(`/egs_t_garbage_edis/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 1次マニフェスト更新
// header情報を追加
export const updateEgsTGarbageEdiFirst = (id, params) => {
  return client.patch(`/egs_t_garbage_edis/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTGarbageEdi = (id) => {
  return client.delete(`/egs_t_garbage_edis/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
