import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";

// import { updateEgsMProcessFlow, getEgsMProcessFlowDetail } from '../../lib/api/egs_m_process_flow';
import { createEgsMProcessFlow } from '../../lib/api/egs_m_process_flow';
import { getProcessFlowId } from '../../lib/api/process_flow';
import FormBody from './EgsMProcessFlowAddForm';
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
//import { getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import { getTraderAdmId } from '../../lib/api/trader_adm';
//import { getLinkageExhaust, getLinkageUserExhaust ,getLinkageGroupExhaust } from '../../lib/api/linkage';
import { getLinkageOpe } from '../../lib/api/linkage';
import { getChargeLinkageOpe } from '../../lib/api/charge_linkage';

import dayjs from 'dayjs';
import {
  //  Button,
    TextField,
    MenuItem,
    Select,
    Box,
    Grid,
    Typography,
    InputLabel
  } from '@material-ui/core';
  import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    jaJP
  } from '@mui/x-data-grid-pro'


const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function EgsMProcessFlowAdd(props) {
  const { isOpen, doClose, dataList2, currentUser } = props;
  // console.log("dataList2",dataList2);
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([]);

  const [dataChargeList, setChargeDataList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    id: 0,
    reserveNo: 0,
    reserveSubno: '',
    reserveName: '',
    manifestNo: '',
    arrangementNo: '',
    reserveDate: '',
    exhaustId: 0,
    exhaustCd: '',
    exhaustChargeId: 0,
    exhaustChargeCd: '',
    sect1TransportId: 0,
    sect1TransportCd: '',
    sect1TransportChargeId: 0,
    sect1TransportChargeCd: '',
    sect1TransportMethodId: 0,
    sect1TransportMethodCd: '',
    sect1CarNo: '',
    destination1Id: 0,
    destination1Cd: '',
    destination1ChargeId: 0,
    destination1ChargeCd: '',
    sect2TransportId: 0,
    sect2TransportCd: '',
    sect2TransportChargeId: 0,
    sect2TransportChargeCd: '',
    sect2TransportMethodId: 0,
    sect2TransportMethodCd: '',
    sect2CarNo: '',
    destination2Id: 0,
    destination2Cd: '',
    destination2ChargeId: 0,
    destination2ChargeCd: '',
    sect3TransportId: 0,
    sect3TransportCd: '',
    sect3TransportChargeId: 0,
    sect3TransportChargeCd: '',
    sect3TransportMethodId: 0,
    sect3TransportMethodCd: '',
    sect3CarNo: '',
    destination3Id: 0,
    destination3Cd: '',
    destination3ChargeId: 0,
    destination3ChargeCd: '',
    sect4TransportId: 0,
    sect4TransportCd: '',
    sect4TransportChargeId: 0,
    sect4TransportChargeCd: '',
    sect4TransportMethodId: 0,
    sect4TransportMethodCd: '',
    sect4CarNo: '',
    destination4Id: 0,
    destination4Cd: '',
    destination4ChargeId: 0,
    destination4ChargeCd: '',
    sect5TransportId: 0,
    sect5TransportCd: '',
    sect5TransportChargeId: 0,
    sect5TransportChargeCd: '',
    sect5TransportMethodId: 0,
    sect5TransportMethodCd: '',
    sect5CarNo: '',
    destination5Id: 0,
    destination5Cd: '',
    destination5ChargeId: 0,
    destination5ChargeCd: '',
    middleProcessingTraderId: 0,
    middleProcessingTraderCd: '',
    middleProcessingChargeId: 0,
    middleProcessingChargeCd: '',
    lastProcessingTraderId: 0,
    lastProcessingTraderCd: '',
    lastProcessingChargeId: 0,
    lastProcessingChargeCd: '',
    processTrustTraderId: 0,
    processTrustTraderCd: '',
    middleWasteFlg: 0,
    lastPlaceFlg: '1',
    egsMWasteclassId: 0,
    wasteKindCd: '',
    wasteCd: '',
    egsMDisposalMethodId: 0,
    disposalMethodCd: '',
    otherMethod: '',
    egsMUnitId: 0,
    unitCd: '',
    egsMPackingId: 0,
    packingCd: '',
    egsMDatasheetId: 0,
    datasheetCd: '',
    registTraderId: 0,
    registTraderCd: '',
    approvalflg: '',
    effectiveflg: '',

    exhaustName: '',
    sect1TransportName: '',
    sect2TransportName: '',
    sect3TransportName: '',
    sect4TransportName: '',
    sect5TransportName: '',
    middleProcessingTraderName: '',
    lastProcessingTraderName: '',
    processTrustTraderName: '',
    destination1Name: '',
    destination2Name: '',
    destination3Name: '',
    destination4Name: '',
    destination5Name: '',
    exhaustChargeName: '',
    sect1TransportChargeName: '',
    sect2TransportChargeName: '',
    sect3TransportChargeName: '',
    sect4TransportChargeName: '',
    sect5TransportChargeName: '',
    middleProcessingChargeName: '',
    lastProcessingChargeName: '',
    destination1ChargeName: '',
    destination2ChargeName: '',
    destination3ChargeName: '',
    destination4ChargeName: '',
    destination5ChargeName: '',
    sect1TransportMethodName: '',
    sect2TransportMethodName: '',
    sect3TransportMethodName: '',
    sect4TransportMethodName: '',
    sect5TransportMethodName: '',
    wasteKindName: '',
    wasteName: '',
    disposalMethodName: '',
    unitName: '',
    packingName: '',

    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',

    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicName01: '',
    toxicName02: '',
    toxicName03: '',
    toxicName04: '',
    toxicName05: '',
    toxicName06: '',

    phone2: '',
  });






  let res;
  let selRows = React.useRef([]);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open);
/*      
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getLinkageList();
        res = await getLinkageExhaust();
      }else{                                       //事業場区分は管理者以外？
        if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ管理者？
//          res = await getLinkageList();
//          res = await getLinkageGroup(currentUser.traderCd);
          res = await getLinkageGroupExhaust(currentUser.traderCd);
        }else{                                       //事業場区分は管理者以外？
//          res = await getLinkageUser(currentUser.traderCd);
          res = await getLinkageUserExhaust(currentUser.traderCd);
        }
        res = await getLinkageUserExhaust(currentUser.traderCd);
      }
*/      
      res = await getLinkageOpe(currentUser.traderCd + '_' + currentUser.traderKbn + '_' + filter.traderCd + '_' + filter.traderName + '__' + filter.phone2);
      // console.log(res.data);
      setDataList(res.data);
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

//====================================   フィルタ絞込と一覧-S
//const [filteredDataList, setFilteredDataList] = React.useState([]);
const [filter, setFilter] = React.useState({
  exhaustDateStart: '',
  exhaustDateEnd: '',

  traderCd: '',
  traderName: '',
  phone2: '',
})

/*
useEffect(() => {
//  setFilteredDataList(dataList3);
  setFilteredDataList(dataList);
  // console.log('setFilteredDataList', filteredDataList);
//}, [dataList3, currentUser])
}, [dataList, currentUser])


//    startFilter();

const startFilter = () => {
//  // console.log('dataList', dataList3);
  // console.log('dataList', dataList);
  // console.log('currentUser', currentUser);
//  let originalList = dataList3;
  let originalList = dataList;

  // console.log('originalList', originalList);
  // console.log('filter', filter);
  if (filter.exhaustDateStart) {
    const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
    });
  }
  if (filter.exhaustDateEnd) {
    const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
    });
  }

//    // console.log(dataList0.garbageTag,dataList3.selectId,item.selectId)
//  // console.log(dataList0.garbageTag,dataList3.selectId)

  //    if (dataList3.selectId == '' || dataList3.selectId == dataList0.garbageTag) {
    originalList = originalList.filter(item => {
      const originalList_selectId = item.selectId;
      const null_selectId = '';
//        return originalList_selectId.isEmpty || originalList_selectId.isSame('') || originalList_selectId.isSame(dataList0.garbageTag);
//        return originalList_selectId.isSame(null_selectId) || originalList_selectId.isSame(dataList0.garbageTag);
//        return originalList_selectId || originalList_selectId.isSame(dataList0.garbageTag);
      return dataList0.garbageTag.isSame(originalList_selectId);
    });
//    }

//    if (filter.manifestNo) {
//      originalList = originalList.filter(item => item.selectId === dataList0.garbageTag);
//      originalList = originalList.filter(item => item.selectId !== '' || item.selectId === '99997978327798791');
//      originalList = originalList.filter(item => item.selectId === '' || item.selectId === '491787');
//      originalList = originalList.filter(item => item.selectId === '' || item.selectId === dataList0.garbageTag);
//    項目属性が異なるので、===でなく==で比較
//    originalList = originalList.filter(item => item.selectId === '' || item.selectId == dataList0.id);
//////    originalList = originalList.filter(item => item.selectId === '' || item.selectId == dataList.id);
//    originalList = originalList.filter(item => item.traderCd1 == '404018500110' || item.traderCd2 == '404018500110');
//    }
    if (filter.traderCd) {
//    originalList = originalList.filter(item => item.traderCd1 == '404018500110' || item.traderCd2 == '404018500110');
      originalList = originalList.filter(item => ( item.traderCd1 == filter.traderCd || item.traderCd2 == filter.traderCd ) &&
                                                  item.traderKbn1 == '0100000000' &&
                                                  item.traderKbn2 == '1000000000' );
    }

    if (filter.phone2) {
      //    originalList = originalList.filter(item => item.traderCd1 == '404018500110' || item.traderCd2 == '404018500110');
            originalList = originalList.filter(item => ( item.phone2 == filter.phone2 ));
    }
      
  // console.log(originalList)
//    startFilter();

  setFilteredDataList(originalList);
}

*/

const [dataList0, setDataList0] = useState({});

let resCharge;

const handleRowClick = (param, event) => {
  setDataList0(param.row);
//  // console.log("dataList0",dataList0);
  // console.log("param.row",param.row);
  value.exhaustId = param.row.egsMTraderId2;
  value.exhaustCd = param.row.traderCd2;
  value.exhaustName = param.row.traderName2;
  value.jwnetNo = param.row.jwnetNo2;
  value.phone2 = param.row.phone2;

  value.sect1TransportId = param.row.egsMTraderId1;
  value.sect1TransportCd = param.row.traderCd1;
  value.sect1TransportName = param.row.traderName1;

//  value.reserveNo = value.jwnetNo + '-' + value.wasteKindCd;    //20221201test
//  value.reserveNo = value.jwnetNo + '-' + value.wasteCd;    //20221201test
  value.reserveNo = value.phone2 + value.wasteCd.substring(0,4);    //20221201test

  value.exhaustCd = param.row.traderCd2;
  handleGetChargeList();
};


  const handleGetChargeList = async () => {
    try {
      setOpen(!open);
	    resCharge = await getChargeLinkageOpe(currentUser.traderCd + '_' + currentUser.traderKbn + '_' + value.exhaustCd);
	    setChargeDataList(resCharge.data);
      
	    value.exhaustChargeId = resCharge.data[0].chargeId;
	    value.exhaustChargeCd = resCharge.data[0].chargeCd;
	    value.exhaustChargeName = resCharge.data[0].chargeName;
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


const cols = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'traderCd1', headerName: '収集運搬業者CD', width: 120 ,hide:true},
  { field: 'jwnetNo1', headerName: '収集運搬加入者番号', width: 120 ,hide:true},
  { field: 'traderName1', headerName: '収集運搬業者名', width: 250 ,hide:true},
  { field: 'traderCd2', headerName: '排出事業CD', width: 120 },
  { field: 'phone2', headerName: '電話番号', width: 120 },
  { field: 'jwnetNo2', headerName: '加入者番号', width: 120 },
  { field: 'traderName2', headerName: '排出事業名', width: 250 },
  { field: 'traderKbnName2', headerName: '排出業者区分', width: 150 },
//  { field: 'traderKbn1', headerName: '収集運搬業者区分', width: 100 },
/*
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'selectName', headerName: '選択', width: 0 },
  { field: 'secondFlg', headerName: '2次フラグ', width: 0 },
  { field: 'selectFlg', headerName: '選択フラグ', width: 50 },
  { field: 'selectTag', headerName: '選択タグ', width: 100 },
  { field: 'selectId', headerName: '選択ID', width: 60 },
  { field: 'statusName', headerName: '状態', width: 100 },
  { field: 'exhaustDate', headerName: '排出日', type: 'date', width: 100 },
  { field: 'garbageTag', headerName: 'ゴミタグ', width: 200 },
  { field: 'manifestNo', headerName: 'マニフェストＮｏ', width: 150 },
  { field: 'exhaustName', headerName: '排出事業場', width: 200 },
  { field: 'wasteclassName', headerName: '廃棄物種類', width: 200 },
  { field: 'amount1', headerName: '数量', width: 100 },
  { field: 'unitName', headerName: '単位', width: 100 },
  { field: 'lastProcessingTraderName', headerName: '最終処分場', width: 200 }
*/
]
//====================================   フィルタ絞込と一覧-E







  useEffect(() => {
    handleGetData(dataList2);
  }, [dataList2.id]);

  // useEffect(() => {
  //   handleGetData(dataList2);
  // });

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();


  const handleGetData = async (dataList2) => {  //
    try {
      setLoading(true);
//0829      const res = await getEgsMProcessFlowDetail(processId);
//0829      const res = await getEgsMProcessFlowDetail(dataList2.id);
//      const res = await getProcessFlowId(processId);  // getProcessFlowDetail      取得してるがフォームに渡らない…
//0829      // console.log(res.data);
// console.log('dataList2',dataList2);
      setValue({
        id: dataList2.id,
        reserveNo: dataList2.reserveNo,
//NG        reserveNo: value.phone2 + value.wasteCd.substring(0,4), // dataList2.reserveNo,
        reserveSubno: dataList2.reserveSubno,
        reserveName: dataList2.reserveName,
        manifestNo: dataList2.manifestNo,
        arrangementNo: dataList2.arrangementNo,
        reserveDate: dataList2.reserveDate,
        exhaustId: dataList2.exhaustId,
        exhaustCd: dataList2.exhaustCd,
        exhaustChargeId: dataList2.exhaustChargeId,
        exhaustChargeCd: dataList2.exhaustChargeCd,
        sect1TransportId: dataList2.sect1TransportId,
        sect1TransportCd: dataList2.sect1TransportCd,
        sect1TransportChargeId: dataList2.sect1TransportChargeId,
        sect1TransportChargeCd: dataList2.sect1TransportChargeCd,
        sect1TransportMethodId: dataList2.sect1TransportMethodId,
        sect1TransportMethodCd: dataList2.sect1TransportMethodCd,
        sect1CarNo: dataList2.sect1CarNo,
        destination1Id: dataList2.destination1Id,
        destination1Cd: dataList2.destination1Cd,
        destination1ChargeId: dataList2.destination1ChargeId,
        destination1ChargeCd: dataList2.destination1ChargeCd,
        sect2TransportId: dataList2.sect2TransportId,
        sect2TransportCd: dataList2.sect2TransportCd,
        sect2TransportChargeId: dataList2.sect2TransportChargeId,
        sect2TransportChargeCd: dataList2.sect2TransportChargeCd,
        sect2TransportMethodId: dataList2.sect2TransportMethodId,
        sect2TransportMethodCd: dataList2.sect2TransportMethodCd,
        sect2CarNo: dataList2.sect2CarNo,
        destination2Id: dataList2.destination2Id,
        destination2Cd: dataList2.destination2Cd,
        destination2ChargeId: dataList2.destination2ChargeId,
        destination2ChargeCd: dataList2.destination2ChargeCd,
        sect3TransportId: dataList2.sect3TransportId,
        sect3TransportCd: dataList2.sect3TransportCd,
        sect3TransportChargeId: dataList2.sect3TransportChargeId,
        sect3TransportChargeCd: dataList2.sect3TransportChargeCd,
        sect3TransportMethodId: dataList2.sect3TransportMethodId,
        sect3TransportMethodCd: dataList2.sect3TransportMethodCd,
        sect3CarNo: dataList2.sect3CarNo,
        destination3Id: dataList2.destination3Id,
        destination3Cd: dataList2.destination3Cd,
        destination3ChargeId: dataList2.destination3ChargeId,
        destination3ChargeCd: dataList2.destination3ChargeCd,
        sect4TransportId: dataList2.sect4TransportId,
        sect4TransportCd: dataList2.sect4TransportCd,
        sect4TransportChargeId: dataList2.sect4TransportChargeId,
        sect4TransportChargeCd: dataList2.sect4TransportChargeCd,
        sect4TransportMethodId: dataList2.sect4TransportMethodId,
        sect4TransportMethodCd: dataList2.sect4TransportMethodCd,
        sect4CarNo: dataList2.sect4CarNo,
        destination4Id: dataList2.destination4Id,
        destination4Cd: dataList2.destination4Cd,
        destination4ChargeId: dataList2.destination4ChargeId,
        destination4ChargeCd: dataList2.destination4ChargeCd,
        sect5TransportId: dataList2.sect5TransportId,
        sect5TransportCd: dataList2.sect5TransportCd,
        sect5TransportChargeId: dataList2.sect5TransportChargeId,
        sect5TransportChargeCd: dataList2.sect5TransportChargeCd,
        sect5TransportMethodId: dataList2.sect5TransportMethodId,
        sect5TransportMethodCd: dataList2.sect5TransportMethodCd,
        sect5CarNo: dataList2.sect5CarNo,
        destination5Id: dataList2.destination5Id,
        destination5Cd: dataList2.destination5Cd,
        destination5ChargeId: dataList2.destination5ChargeId,
        destination5ChargeCd: dataList2.destination5ChargeCd,
        middleProcessingTraderId: dataList2.middleProcessingTraderId,
        middleProcessingTraderCd: dataList2.middleProcessingTraderCd,
        middleProcessingChargeId: dataList2.middleProcessingChargeId,
        middleProcessingChargeCd: dataList2.middleProcessingChargeCd,
        lastProcessingTraderId: dataList2.lastProcessingTraderId,
        lastProcessingTraderCd: dataList2.lastProcessingTraderCd,
        lastProcessingChargeId: dataList2.lastProcessingChargeId,
        lastProcessingChargeCd: dataList2.lastProcessingChargeCd,
        processTrustTraderId: dataList2.processTrustTraderId,
        processTrustTraderCd: dataList2.processTrustTraderCd,
        middleWasteFlg: dataList2.middleWasteFlg,
        lastPlaceFlg: dataList2.lastPlaceFlg,
        egsMWasteclassId: dataList2.egsMWasteclassId,
        wasteKindCd: dataList2.wasteKindCd,
        wasteCd: dataList2.wasteCd,
        egsMDisposalMethodId: dataList2.egsMDisposalMethodId,
        disposalMethodCd: dataList2.disposalMethodCd,
        otherMethod: dataList2.otherMethod,
        egsMUnitId: dataList2.egsMUnitId,
        unitCd: dataList2.unitCd,
        egsMPackingId: dataList2.egsMPackingId,
        packingCd: dataList2.packingCd,
        egsMDatasheetId: dataList2.egsMDatasheetId,
        datasheetCd: dataList2.datasheetCd,
        registTraderId: dataList2.registTraderId,
        registTraderCd: dataList2.registTraderCd,
        approvalflg: dataList2.approvalflg,
        effectiveflg: dataList2.effectiveflg,

        exhaustName: dataList2.exhaustName,  // res.data.exhaustName,
        sect1TransportName: dataList2.sect1TransportName,
        sect2TransportName: dataList2.sect2TransportName,
        sect3TransportName: dataList2.sect3TransportName,
        sect4TransportName: dataList2.sect4TransportName,
        sect5TransportName: dataList2.sect5TransportName,
        middleProcessingTraderName: dataList2.middleProcessingTraderName,
        lastProcessingTraderName: dataList2.lastProcessingTraderName,  // res.data.lastProcessingTraderName,
        processTrustTraderName: dataList2.processTrustTraderName,
        destination1Name: dataList2.destination1Name,
        destination2Name: dataList2.destination2Name,
        destination3Name: dataList2.destination3Name,
        destination4Name: dataList2.destination4Name,
        destination5Name: dataList2.destination5Name,
        exhaustChargeName: dataList2.exhaustChargeName,
        sect1TransportChargeName: dataList2.sect1TransportChargeName,
        sect2TransportChargeName: dataList2.sect2TransportChargeName,
        sect3TransportChargeName: dataList2.sect3TransportChargeName,
        sect4TransportChargeName: dataList2.sect4TransportChargeName,
        sect5TransportChargeName: dataList2.sect5TransportChargeName,
        middleProcessingChargeName: dataList2.middleProcessingChargeName,
        lastProcessingChargeName: dataList2.lastProcessingChargeName,
        destination1ChargeName: dataList2.destination1ChargeName,
        destination2ChargeName: dataList2.destination2ChargeName,
        destination3ChargeName: dataList2.destination3ChargeName,
        destination4ChargeName: dataList2.destination4ChargeName,
        destination5ChargeName: dataList2.destination5ChargeName,
        sect1TransportMethodName: dataList2.sect1TransportMethodName,
        sect2TransportMethodName: dataList2.sect2TransportMethodName,
        sect3TransportMethodName: dataList2.sect3TransportMethodName,
        sect4TransportMethodName: dataList2.sect4TransportMethodName,
        sect5TransportMethodName: dataList2.sect5TransportMethodName,
        wasteKindName: dataList2.wasteKindName,
        wasteName: dataList2.wasteName,
        disposalMethodName: dataList2.disposalMethodName,
        unitName: dataList2.unitName,
        packingName: dataList2.packingName,

        memo: dataList2.memo,
        memo1: dataList2.memo1,
        memo2: dataList2.memo2,
        memo3: dataList2.memo3,
        memo4: dataList2.memo4,
        memo5: dataList2.memo5,

        toxicCd01: dataList2.toxicCd01,
        toxicCd02: dataList2.toxicCd02,
        toxicCd03: dataList2.toxicCd03,
        toxicCd04: dataList2.toxicCd04,
        toxicCd05: dataList2.toxicCd05,
        toxicCd06: dataList2.toxicCd06,
        toxicName01: dataList2.toxicName01,
        toxicName02: dataList2.toxicName02,
        toxicName03: dataList2.toxicName03,
        toxicName04: dataList2.toxicName04,
        toxicName05: dataList2.toxicName05,
        toxicName06: dataList2.toxicName06,

        phone2: dataList2.phone,
//NG        reserveNo: value.phone2 + value.wasteCd.substring(0,4), // dataList2.reserveNo,

      });

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    value.reserveNo = value.phone2 + value.wasteCd.substring(0,4);    //20230329test

////    if (e.target.name == 'exhaustChargeId' && e.target.value == 0) {
////      window.confirm('担当者を選択してください')
//////      e.target.value = value.exhaustChargeId
////    }  

    if (e.target.name == 'wasteCd') {
        value.reserveNo = value.phone2 + e.target.value.substring(0,4);    //20231023
    }  

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    // console.log('setvalue1', value);

//    getjwnetno();


  };
/*
  useEffect(() => {
    getjwnetno();
  }, [value.exhaustId]);

  
  const getjwnetno = async (e) => {
    if (value.exhaustId > 0) {
      try {
//        const res = await getEgsMTraderDetail(value.exhaustId);
        const res = await getTraderAdmId(value.exhaustId);
        // console.log('getEgsMTraderDetail res',res);
//        value.reserveNo = res.data.jwnetNo + '-' + value.wasteKindCd;  // 20230224
        value.phone2 = res.data[0].phone2
//        value.reserveNo = res.data[0].phone2 + value.wasteCd.substring(0,4);  // 20230224
        value.reserveNo = value.phone2 + value.wasteCd.substring(0,4);  // 20230224
//        alert(value.reserveNo)
      } catch (e) {
        console.log(e);
      }
    }  
  };
*/

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加

//  if (value.exhaustChargeId == 0) {
//    window.confirm('担当者を選択してください!!')
//  }else{


    const params = generateParams();
    // console.log('params',params);
    if (params.exhaustChargeId == 0 || params.exhaustChargeId == null || value.exhaustChargeId == null || value.exhaustChargeId == 0) {
      window.confirm('担当者を選択してください!!')
    }else{
      if (window.confirm(' 処理フロー「' + value.reserveName + ':' + value.reserveNo + '」を新規登録します。よろしいですか？')) {
//    if (window.confirm(' 処理フロー「' + value.reserveName + ':' + value.phone2 + value.wasteCd.substring(0,4) + '」を新規登録します。よろしいですか？')) {
        try {
       setOpen(!open);
        const res = await createEgsMProcessFlow(params);
        // console.log('res',res);
        handleCancel();
        history.push('/egs_m_process_flows');
//        window.location.reload();
//      if (window.confirm('引き続き処理フローの追加を行いますか？')) {
//        window.location.reload();
//      }
       setOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  }  
};

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
//      reserveNo: value.phone2 + value.wasteCd.substring(0,4), // value.reserveNo,
      reserveNo: value.reserveNo,
      reserveSubno: value.reserveSubno,
      reserveName: value.reserveName,
      reserveDate: value.reserveDate,
      exhaustId: value.exhaustId,
      exhaustChargeId: value.exhaustChargeId,
      sect1TransportId: value.sect1TransportId,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1ChargeId: value.destination1ChargeId,
      sect2TransportId: value.sect2TransportId,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportMethodId: value.sect2TransportMethodId,
      sect2TransportMethodCd: value.sect2TransportMethodCd,
      sect2CarNo: value.sect2CarNo,
      destination2Id: value.destination2Id,
      destination2ChargeId: value.destination2ChargeId,
      sect3TransportId: value.sect3TransportId,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportMethodCd: value.sect3TransportMethodCd,
      sect3CarNo: value.sect3CarNo,
      destination3Id: value.destination3Id,
      destination3ChargeId: value.destination3ChargeId,
      sect4TransportId: value.sect4TransportId,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportMethodCd: value.sect4TransportMethodCd,
      sect4CarNo: value.sect4CarNo,
      destination4Id: value.destination4Id,
      destination4ChargeId: value.destination4ChargeId,
      sect5TransportId: value.sect5TransportId,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportMethodId: value.sect5TransportMethodId,
      sect5TransportMethodCd: value.sect5TransportMethodCd,
      sect5CarNo: value.sect5CarNo,
      destination5Id: value.destination5Id,
      destination5ChargeId: value.destination5ChargeId,
      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingChargeId: value.lastProcessingChargeId,
      middleWasteFlg: value.middleWasteFlg,
      lastPlaceFlg: value.lastPlaceFlg,
      wasteKindCd: value.wasteKindCd,
      wasteCd: value.wasteCd,
      disposalMethodCd: value.disposalMethodCd,
      otherMethod: value.otherMethod,
      unitCd: value.unitCd,
      packingCd: value.packingCd,
      registTraderCd: value.registTraderCd,
      approvalflg: value.approvalflg,
      effectiveflg: value.effectiveflg,
      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>処理フロー　新規登録（複写）</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
      </div>
      </DialogTitle>
      <DialogContent>








      
    <Typography variant="h5" style={{ fontWeight: 700 }}>
      　　　文字列の部分検索を可能にしました
    </Typography>
      

      <Grid item xs style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}>

        <Typography variant="h7" style={{ fontWeight: 700 }}>
          事業場CD：
        </Typography>
        <TextField
          id="trader-cd"
          label=""
          type={"text"}
          value={filter.traderCd}
          onChange={e => setFilter({
            ...filter,
            traderCd: e.target.value
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}

          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
//                  startFilter();
              handleGetList();
            }
          }}

        />

        <Typography variant="h7" style={{ fontWeight: 700 }}>
          事業場名：
        </Typography>
        <TextField
          id="trader-name"
          label=""
          type={"text"}
          value={filter.traderName}
          onChange={e => setFilter({
            ...filter,
            traderName: e.target.value
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}

          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
//                  startFilter();
              handleGetList();
            }
          }}

        />

        <Typography variant="h7" style={{ fontWeight: 700 }}>
          電話番号：
        </Typography>
        <TextField
          id="trader-cd"
          label=""
          type={"text"}
          value={filter.phone2}
          onChange={e => setFilter({
            ...filter,
            phone2: e.target.value
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}

          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
//                  startFilter();
              handleGetList();
            }
          }}

        />

{/*}        <Typography variant="h7" style={{ fontWeight: 700 }}>
          排出期間：
        </Typography>
        <TextField
          id="exhaustDate-start"
          label=""
          type={"date"}
          value={filter.exhaustDateStart}
          onChange={e => setFilter({
            ...filter,
            exhaustDateStart: e.target.value
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
      <TextField
        id="exhaustDate-start"
        label=""
        type={"date"}
        value={filter.exhaustDateEnd}
        onChange={e => setFilter({
          ...filter,
          exhaustDateEnd: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />  */}
      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.preventDefault();
//          startFilter();
            handleGetList();
        }}
        style={{ marginLeft: 24 }}
      >
        検索
      </Button>
    </Grid>

    <div className={classes.container2}>
         <Box sx={{ height: 300, width: '100%' }}>  {/*520*/} {/*120*/}
          <DataGridPro
//            pageSize={pageSize}
//            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//            rowsPerPageOptions={[10, 20, 50]}
//            pagination
            sx={styles.grid}
            columns={cols}
            rows={dataList}  // filteredDataList
            density="compact"
//            autoHeight
///            checkboxSelection
///            disableSelectionOnClick
///            onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}

            onRowClick={handleRowClick}  // 選択した行データを取得する

          />
         </Box>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>

        <h3>　↑ 対象の排出業者と収集運搬を選択して下さい</h3>







        <FormBody
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          dataList2={dataList2}
          buttonType='登録' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <h3>ＱＲコード</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p> */}
      </DialogContent>
    </Dialog>
  );
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMProcessFlowAdd;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
