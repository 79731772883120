// src/components/users/SignIn.jsx
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// style
import { Backdrop,CircularProgress,makeStyles,Grid,Typography,Link,Button,Card, CardHeader, CardContent, } from '@material-ui/core';
import SpaceRow from '../commons/SpaceRow';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  newscard: {
    padding: theme.spacing(2),
    maxWidth: 1000,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.ecotechl.com/">
        ecotechl.com
      </Link>{' '}
      {'2023-'+ new Date().getFullYear()}
      {'.'}
      {'　　'}
      <Link target="_blank" color="inherit" href="https://s3-sdgs12com-manual.s3.ap-northeast-1.amazonaws.com/SDGs12TermsofUse_20230201.pdf">
        利用規約
      </Link>{' '}
    </Typography>
  );
}

const OldNews = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <h1>産業廃棄物SDGs推進システム</h1>

        <SpaceRow height={75} />
        <Card className={classes.newscard}>
          <CardHeader
              title="【過去のお知らせ】"
          />
          <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
              ●2023年12月26日　JWNETシステム年始停止のお知らせ<br />
              【JWNETシステム停止期間】　2024年1月1日（金）午前0:00 ～ 1月4日（月）午前4:00<br />
               　SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br />
              ●2023年12月21日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧CSV出力用画面最下段に、「CSVファイルダウンロード（取消分・UTF-8形式）」釦を追加しました。<br />
              ●2023年11月29日　次の機能変更を行いましたのでお知らせ致します。<br />
                ・同時接続時の負荷軽減のため、廃棄物一覧の初期表示を最新90日から最新30日へ変更しました。<br />
              ●2023年11月15日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧旧システムに、「CSVファイルダウンロード（全件・UTF-8形式）」釦を追加しました。<br />
                　また、データ表示は、検索キー入力後の表示へ変更しました。<br />
              ●2023年11月08日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧CSV出力用に、「CSVファイルダウンロード（全件・UTF-8形式）」釦を追加しました。<br />
                　また、データ表示は、検索キー入力後の表示へ変更しました。<br />
              ●2023年10月28日　次の機能追加を行いましたのでお知らせ致します。<br />
                ・スマートフォンの廃棄物一覧（現在、運搬・処分中廃棄物）に、表示対象データを当日分のみとするか全データとするかを切り替えるスイッチを追加しました。デフォルトは当日分のみです。<br />
              ●2023年10月15日　次の改善を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧CSV出力用のデータ作成（最新データに更新）時間に、10時11分、13時11分、16時11分を追加しました。<br />
              ●2023年10月8日　次の機能改善および不具合改修を行いましたのでお知らせ致します。<br />
                ・排出仮登録データの修正機能を追加致しました。<br />
                ・排出登録への影響軽減のため、メニューより廃棄物一覧を外し、廃棄物一覧CSV出力用については、<br />
                　メニューからの呼び出し時でなく、夜間にデータ作成を行うようにしました。<br />
                　リアルタイムなCSVデータ等を必要とする際はサポートまで連絡をお願いします。<br />
              ●2023年10月2日　JWNET定期メンテナンスに伴うシステム停止のお知らせ<br />
              　【JWNETシステム停止期間】<br />
              　　2023年10月8日（日）午前0:00～2023年10月9日（月）午前4:00<br />
              　SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br />
              ●2023年9月4日　次の機能改善および不具合改修を行いましたのでお知らせ致します。<br />
                ・廃棄物一覧CSV出力用の検索機能を、部分文字列で検索可能としました。<br />
                 　排出業者「○○クリニック」や廃棄物種類「プラ」等で、検索可能です。<br />
              ●2023年8月27日　次の機能改善および不具合改修を行いましたのでお知らせ致します。<br />
                ・収集運搬業者の連携マスタを連携・担当者マスタとし、連携先担当者情報の更新を可能としました。<br />
                ・排出事業者のスマホでのログイン後に担当者選択が表示される不具合の改修を行いました。<br />
              ●2023年8月6日　次の機能改善を行いましたのでお知らせ致します。<br />
                ・ヘッダの「産業廃棄物SDGs推進システム」をクリックするとトップページへ戻るようにしました。<br />
                ・スマホのトップページ、廃棄物一覧に表示されている廃棄物データより「排出仮登録（マニフェスト情報作成）」または「排出仮登録一覧」へ遷移出来るようにしました。<br />
                ・廃棄物一覧にマニフェスト情報取消（削除）完了後の廃棄物データを表示しないように致しました。<br />
              ●2023年夏季休業のお知らせ<br />
              【JWNETシステム停止期間】<br />
                 2023年8月12日（土）午前0:00 から 8月14日（月）午前4:00<br />
                 SDGsシステムにおいては、JWNETシステム停止期間中もご登録可能ですが、マニフェスト番号、JWNET通知情報取得は行えませんのでよろしくお願いします。<br />
              ●2023年8月2日　処理フローの新規登録と新規登録（複写）でトップ画面の再読込をしないよう修正しました。<br />
              　※　新規追加した処理フローを確認したいときは、データ表示ボタンをクリックしてください。<br />
              ●2023年7月26日　処理フローメモをマニフェスト情報登録の備考１へ自動追加するようにしました。<br />
              ●2023年7月18日　処理フロー一覧に検索機能を追加しました。初期表示では0件のため、「データ表示」で絞込検索して下さい。<br />
              ●2023年7月12日　廃棄物一覧(環境ガードシステム)の検索機能見直し、PDF出力機能を追加しました。<br />
              ●2023年7月1日　環境ガードシステムは６月末をもって終了（完全停止）しまたのでご連絡致します。<br />
              　長らくご愛顧いただき誠にありがとうございました。今後は本システムで皆様により良いサービスを<br />
              　提供できるよう努めてまいりますので、ご理解とご協力を賜りますよう、心よりお願い申し上げます。
              ●2023年6月18日　廃棄物一覧の初期表示を最新60日から最新90日へ変更致しました。<br />
              ●2023年6月4日　次の機能改善を行いましたのでお知らせ致します。<br />
                ・PCでの廃棄物一覧（現在、運搬・処分中の廃棄物）を廃棄物一覧（初期表示：最新60日の排出データを表示）へ変更致しました<br />
                ・上記、廃棄物一覧において、あいまい検索でのデータ表示を可能と致しました。<br />
              ●2023年5月21日　次の機能改善を行いましたのでお知らせ致します。<br />
                ・排出登録時の備考欄を１枠から５枠へ増やしました。<br />
                ・排出登録時の荷降登録ボタンを区間１用ボタンと区間２用ボタンに分けました。<br />
                ・日中の処理スピードの高速化を図るためにJWNET通知情報取得を夜間に行うように変更致しました。<br />
              ●2023年5月1日　産業廃棄物SDGs推進システム（新システム）への移行ありがとうございます。<br />
              　環境ガードシステム（現行システム）での廃棄物登録を停止致しましたのでお知らせします。<br />
              ●2023年2月　産業廃棄物SDGs推進システム（新システム）の運用を開始致しました。<br />
              　環境ガードシステム（現行システム）ご利用の事業者さまは速やかな移行をお願いします。<br />
              　なお、環境ガードシステムは４月下旬の停止を予定しております。<br />
              　皆様のご理解、ご協力のほど何卒よろしくお願い申し上げます。<br />
              ●移行に関する注意事項<br />
              　JWNETへ登録中の廃棄物情報は、現行システムと新システムで共有できません。<br />
              　現行システムで排出登録した廃棄物情報は最終処分登録まで現行システムでお願いします。<br /><br />
          </Typography>
          <Link target="_blank" href="https://s3-sdgs12com-manual.s3.ap-northeast-1.amazonaws.com/EGStoSDGs12Flyer.pdf">案内チラシ「現行システム（環境ガードシステム）リプレース
新システム（産業廃棄物SDGs推進システム）への移行のお願い」
</Link>
          </CardContent>
      </Card>
      </Grid>
      <SpaceRow height={50} />
      <Copyright />
    </div>
  );
};
export default OldNews;
