// /src/lib/api/egs_m_group.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsMGroupList = () => {
  return client.get('/egs_m_groups');
};

// 詳細
export const getEgsMGroupDetail = (id) => {
  return client.get(`/egs_m_groups/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMGroup = (params) => {
  return client.post('/egs_m_groups', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMGroup = (id, params) => {
  return client.patch(`/egs_m_groups/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMGroup = (id) => {
  return client.delete(`/egs_m_groups/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
