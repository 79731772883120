import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { getProcessMobileReserveNo } from '../../lib/api/process_mobile';
import { createEgsTGarbageEdi, getEgsTGarbageEdiGbt } from '../../lib/api/egs_t_garbage_edi';

import QRReaderDialog from './QRReaderDialog';
import SpaceRow from '../commons/SpaceRow';

import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeUser } from '../../lib/api/charge';
import { updateEgsTGarbageTag } from '../../lib/api/egs_t_garbage_tag';
import { getEgsMTraderPhone }  from '../../lib/api/egs_m_trader';

import { makeStyles,styled } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { transportMethodName } from '../../constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function MblProcessFlow() {
  const classes = useStyles();
  const [value, setValue] = useState([]);


  const query = useParams();
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState({});

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2",dataList2);
  };

  const cols = [
    {
      field: 'reserveSubno',
      headerName: 'サブ番号',
      width: 60
    },
    {
      field: 'reserveName',
      headerName: 'フロー名称',
      width: 300
    },
    {
      field: 'exhaustName',
      headerName: '排出事業者',
      width: 300
    },
    {
      field: 'wasteKindName',
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width: 100
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      width: 70
    }
  ]

  var userdataList;
  var resdataUser;

  useEffect(() => {
    handleGetData(query);
  }, [query]);

  const handleGetData = async (query) => {
    // console.log("query", query);
    if (query.id.slice(-4) === '9999') {
      const idWithoutLast4Digits = query.id.slice(0, -4); // 下4桁を除いた値を取得
      const res = await getEgsMTraderPhone(idWithoutLast4Digits);
      history.push(`/mbl_garbage_tmp/${res.data.traderCd}`);
    } else {
      const res = await getProcessMobileReserveNo(query.id);
      setDataList(res.data);
      userdataList = res.data;
      let cookie_uid = Cookies.get('_uid');
      const userTraderCd = cookie_uid.split('@');
      if (userTraderCd[0] !== undefined) {
        let resdataUser = userdataList.filter(userdataList => {
          return (userdataList.sect1TransportCd == userTraderCd[0]);
        });
        setDataList(resdataUser);
      }
    }
  };

  const handleClickRsvno = async() => {
    let d = new Date();
    const garbageTag = dataList2.id + "-" + d.valueOf();
    history.push(`/mbl_emission_set/${garbageTag}` );
  };

  return (
    <>
      <h1>排出仮登録（処理フロー選択）</h1>
      <h2>{query.id}　{dataList2.exhaustName}</h2>

      <SpaceRow height={20} />
      <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={dataList}
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        <SpaceRow height={10} />
        <table>
          <tr>
            <th>廃棄物種類</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteKindName}</Typography>
            </td>
          </tr>
          <tr>
            <th>処分方法</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.disposalMethodName}</Typography>
            </td>
          </tr>
          <tr>
            <th>区間１運搬先事業者</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination1Name}</Typography>
            </td>
          </tr>
          <tr>
            <th>最終処分場</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingTraderName}</Typography>
            </td>
          </tr>
          </table>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>詳細</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table>
                <tr>
                  <th>廃棄物名称</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteName}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>単位</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.unitName}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>荷姿</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.packingName}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>有害物質</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>
                        {dataList2.toxicName01}　{dataList2.toxicName02}　
                        {dataList2.toxicName03}　{dataList2.toxicName04}　
                        {dataList2.toxicName05}　{dataList2.toxicName06}
                      </Typography>
                  </td>
                </tr>
                <tr>
                  <th>メモ</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo}</Typography>
                  </td>
                </tr>
                <tr>
                <th>区間２運搬事業者</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect2TransportName}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>区間２運搬先事業者</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination2Name}</Typography>
                  </td>
                </tr>
              </table>
            </AccordionDetails>
          </Accordion>
          <SpaceRow height={10} />

          <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant='contained' onClick={() => history.push('/')} style={{ marginRight: 30, fontSize: '1.2em' }}>
            戻る
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleClickRsvno(e)}
            style={{ marginRight: 10, fontSize: '1.2em' }}
            disabled={!dataList2.id}
          >
            排出仮登録
          </Button>
        </Box>

    </>
  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default MblProcessFlow;

