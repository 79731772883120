import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"

//import ReactToPdf from "react-to-pdf";
import QRCode from "qrcode.react"
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  jaJP
} from '@mui/x-data-grid-pro'

import './app.css';
import SpaceRow from '../commons/SpaceRow';
import { statusName,ediFlg,toxicSubstanceName } from '../../constants'

import { getEgsTGarbageTmpUser  } from '../../lib/api/egs_t_garbage_tmp';
import { creProcessFlowtogbtpln,deleteEgsTGarbagePln,PlncopyTmpanddelPln } from '../../lib/api/egs_t_garbage_pln';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_GarbageTmp',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function GarbageTmpListTable(props) {
  const classes = useStyles();
  const { dataList, dataList2, currentUser } = props; // handleDelete , handleCancel
  const [clickedRowId, setClickedRowId] = useState(null);
 
  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filteredDataList2, setFilteredDataList2] = React.useState([]);
  const [selectedDataList, setSelectedDataList] = React.useState([]);　//2024-01-17add
  const [selectedRows, setSelectedRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  // 処理中マークの表示状態を管理するstate
  const [processing, setProcessing] = useState(false);

  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    manifestNo: '',
//    wasteKindName: '',
    wasteName: '',
    statusName: '',
    exhaustName: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    setFilteredDataList2(dataList2);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, dataList2])

  const handleGetList = async () => {
    const mbluser = Cookies.get('mbl_user');
    const mbluser2 = mbluser.split(':');
    console.log("mbluser2",mbluser2);
  };

  // DataGridProの選択モデルが変更されたときに呼び出される関数
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection.map((selection) => filteredDataList2[selection]));
    console.log('filteredDataList2:',filteredDataList2)
  };

  // 選択された行の情報をコンソールログに表示する関数
  const logSelectedRows = () => {
    console.log(selectedDataList);
    console.log(filteredDataList2); // 追加行: filteredDataList2も確認
  };


  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    readCSVFile(file);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    readCSVFile(file);
  };

  const readCSVFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      const rows = content.split('\n').slice(1); // Skip the header row
      setCsvData(rows);
    };
    reader.readAsText(file);
  };

  const handleCSVDataSave = async (csvData) => {
    // console.log("csvdata", csvData);
    if (window.confirm('CSVデータより排出仮登録予約へ登録します。よろしいですか？\nよろしければ、「ＯＫ」を押して表示更新するまで約10秒お待ちください')) {
      // ボタンを非活性化し、処理中マークを表示
      try {
        setProcessing(true); // 処理中フラグを立てる
        // CSV形式のcsvDataにcurrentUser.traderCdを追加して処理する
        const updatedCsvData = csvData.map((record) => {
          return `${currentUser.traderCd},${record}`;
        });
        // console.log('updatedCsvData', updatedCsvData);  
        updatedCsvData.forEach(async (record) => {
          const response = await creProcessFlowtogbtpln(record);
          // console.log('CSV data saved successfully:', response.data);
          // 返ってきたデータを適切に処理する
        });
        // 処理が終了したら処理中フラグを下げる
        setProcessing(false);
      } catch (error) {
        console.error('Failed to save CSV data:', error.response.data);
        // エラー処理を行う
        // エラーが発生した場合も処理中フラグを下げる
        setProcessing(false);
      }
      // 5秒待って表示更新
      setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
      // ドラッグオーバー時のスタイルを設定
    // e.target.style.backgroundColor = 'lightblue'; // 任意の背景色に変更
  };
  // ドラッグリーブ時に元の背景色に戻すための関数
  const handleDragLeave = (e) => {
    // ドラッグリーブ時のスタイルを設定
    e.target.style.backgroundColor = 'rgba(220, 220, 220, 0.7)'; // 元の背景色に戻す
  };

  const handleCheckboxChange = (event, row) => {
    console.log("selectedRows",selectedRows)
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
    }
  };

// チェックされた行のIDをバックエンドに送信して削除処理を実行する関数
const handleMoveSelectedRows = async () => {
  const checkedRowsData = filteredDataList2.filter(row => selectedDataList.some(selectedRow => selectedRow.id === row.id));
  console.log("checkedRowsData", checkedRowsData);

  if (window.confirm('選択された排出仮登録予約データを仮登録へ移行します。よろしいですか？')) {
    try {
      // ボタンを非活性化し、処理中マークを表示
      setProcessing(true);
      const deletePromises = checkedRowsData.map(async (row) => {
        const response = await PlncopyTmpanddelPln(row.id);
        return response.ok;
      });

      const deleteResults = await Promise.all(deletePromises);

      if (deleteResults.every(result => result)) {
        console.log('Selected rows deleted successfully');
        const updatedDataList = filteredDataList2.filter(row => !selectedDataList.includes(row));
        setFilteredDataList(updatedDataList);
        setSelectedDataList([]);
      } else {
        console.error('Failed to delete selected rows');
      }
      // 処理が終了したら処理中マークを非表示にし、ボタンを活性化
      setProcessing(false);
    } catch (error) {
      console.error('Error:', error);
      // エラーが発生した場合も処理中マークを非表示にする
      setProcessing(false);
    }
  }
  const updatedDataList = filteredDataList2.filter(row => !selectedRows.includes(row));
  setFilteredDataList(updatedDataList);
  setSelectedRows([]);
  window.location.reload();
};

// チェックされた行のIDをバックエンドに送信して削除処理を実行する関数
const handleDeleteSelectedRows = async () => {
  const checkedRowsData = filteredDataList2.filter(row => selectedDataList.some(selectedRow => selectedRow.id === row.id));
  console.log("checkedRowsData", checkedRowsData);

  if (window.confirm('選択された排出仮登録予約データを削除します。よろしいですか？')) {
    try {
      // ボタンを非活性化し、処理中マークを表示
      setProcessing(true);
      const deletePromises = checkedRowsData.map(async (row) => {
        const response = await deleteEgsTGarbagePln(row.id);
        return response.ok;
      });

      const deleteResults = await Promise.all(deletePromises);

      if (deleteResults.every(result => result)) {
        console.log('Selected rows deleted successfully');
        const updatedDataList = filteredDataList2.filter(row => !selectedDataList.includes(row));
        setFilteredDataList2(updatedDataList);
        setSelectedDataList([]);
      } else {
        console.error('Failed to delete selected rows');
      }
      // 処理が終了したら処理中マークを非表示にし、ボタンを活性化
      setProcessing(false);
    } catch (error) {
      console.error('Error:', error);
      // エラーが発生した場合も処理中マークを非表示にする
      setProcessing(false);
    }
  }
  const updatedDataList = filteredDataList2.filter(row => !selectedRows.includes(row));
  setFilteredDataList2(updatedDataList);
  setSelectedRows([]);
  window.location.reload();
};

  const handleRowClick = (param, event) => {

    setClickedRowId(param.id); // 追加: クリックされた行のIDを状態に保存
      console.log("dataList2",dataList2);
      console.log("selRows",selRows);
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);

  const cols2 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      hide: true,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出予定日',
      type: 'date',
      width: 100
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '収集運搬担当者',
      width: 120
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 280
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 220
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 140
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 160
    },
    {
      field: 'memo2',
      headerName: '備考２',
      width: 80
    },
    {
      field: 'memo3',
      headerName: '備考３',
      width: 80
    },
    {
      field: 'memo4',
      headerName: '備考４',
      width: 80
    },
    {
      field: 'memo5',
      headerName: '備考５',
      width: 80
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 240
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 240
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 240
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 240
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 240
    }
  ]

  const cols9 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      hide: true,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出予定日',
      type: 'date',
      width: 100
    },
    {
      field: 'sect1TransportName',
      headerName: '収集運搬業者',
      width: 280
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '収集運搬担当者',
      width: 120
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 280
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 220
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 140
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 160
    },
    {
      field: 'memo2',
      headerName: '備考２',
      width: 80
    },
    {
      field: 'memo3',
      headerName: '備考３',
      width: 80
    },
    {
      field: 'memo4',
      headerName: '備考４',
      width: 80
    },
    {
      field: 'memo5',
      headerName: '備考５',
      width: 80
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 240
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 240
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 240
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 240
    }
  ]

  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
    return (
      <>
        <h1>排出仮登録状況（{new Date().toLocaleString()})　　
          <Button onClick={() => window.location.reload()} color="primary">最新（再読み込み）</Button>
        </h1>
        <p style={{ color: 'brown' }}>
          ※排出登録（マニフェスト情報作成）前、排出事業場承認待ちデータです。修正、削除、承認はスマートフォンで行ってください。排出予定日当日分以外はAM0:01に自動削除されます
        </p>
        <div style={{ width: 2200, margin: '0 auto' }}>
          <Box sx={{ height: 1020, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols9}
              rows={filteredDataList}
              density="compact"
              components={{
                Toolbar: CustomToolbar, // カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </>
    );

}else if (currentUser.traderKbn == '0100000000'){   //事業場区分は収集運搬業者？

  return (
    <>
      <h1>排出仮登録状況（{new Date().toLocaleString()})　　
        <Button onClick={() => window.location.reload()} color="primary">最新（再読み込み）</Button>
      </h1>
      <p style={{ color: 'brown' }}>
        ※排出登録（マニフェスト情報作成）前、排出事業場承認待ちデータです。修正、削除、承認はスマートフォンで行ってください。排出予定日当日分以外はAM0:01に自動削除されます
      </p>
      <div style={{ width: 2200, margin: '0 auto' }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols2}
            rows={filteredDataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar, // カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
      <h1>排出仮登録予約一覧</h1>
      <div>
        <div
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          style={{ 
            width: '50%', 
            height: 'auto', 
            border: '2px dashed #ccc', 
            padding: '10px', 
            backgroundColor: '#ccc', // 初期背景色を薄灰色に設定
            margin: '-50px auto', // 水平方向中央に配置
            textAlign: 'center' // テキストを中央揃え
          }}
        >
          <p style={{ margin: '0' }}>排出仮登録用CSVファイル(*1)をこちらにドラッグ＆ドロップするか、選択してください　<input type="file" onChange={handleFileSelect} style={{ margin: '2px 0' }} /></p>
          <p style={{ margin: '0' }}>(*1)処理フロー一覧でひな型をダウンロードして排出予定日、数量等を更新して作成してください</p>

        </div>
        <div>
          <h2>　　　CSVデータ</h2>
          <div>
            {csvData.map((row, index) => (
              <p key={index}>{row}</p>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={() => handleCSVDataSave(csvData)}
            disabled={csvData.length === 0 || processing} // データなしまたは処理中の場合はボタンを非活性化
          >
            {processing ? '処理中...' : 'CSVデータより仮登録予約へ登録'}
          </Button>
            <p style={{ marginRight: 10, color: 'brown' }}>　　　　　※排出予定日当日分の排出仮登録予約データ（下）はAM0:01に排出仮登録（上）へ自動で移行します</p>
          </div>
          <SpaceRow height={10} />
        </div>
      </div>
      {/* <div style={{ width: 2200, margin: '0 auto' }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid2}
            columns={cols2}
            rows={filteredDataList2}
            density="compact"
            components={{
              Toolbar: CustomToolbar,
            }}
            showColumnRightBorder
            showCellRightBorder
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            checkboxSelection
            onSelectionModelChange={handleSelectionModelChange}
          />
        </Box>
      </div> */}
      <div style={{ width: 2200, margin: '0 auto' }}>
        <Box sx={{ height: 860, width: '100%' }}>
          <DataGridPro
            sx={styles.grid2}
            columns={cols2}  // cols
            rows={filteredDataList2}  // dataList
            density="compact"
            checkboxSelection
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
              },
            }}
            disableSelectionOnClick
            onSelectionModelChange={(newSelection) => {
              const selectedRowsData = filteredDataList2.filter((row) =>
                newSelection.includes(row.id)
              );
              setSelectedDataList(selectedRowsData);
            }}
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
            getRowClassName={(params) =>
              params.id === clickedRowId ? 'clicked-row' : ''
            }
          />
        </Box>
      </div>
      <SpaceRow height={10} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold', marginRight: 10 }}>
          　↑ 「仮登録へ移行」または「削除」する排出仮登録予約データを選択
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
          onClick={handleMoveSelectedRows}
          disabled={selectedDataList.length === 0 || processing} // 処理中はボタンを非活性化
        >
          {processing ? '処理中...' : '仮登録へ移行'}
        </Button>
        <Button
          variant="contained"
          color='secondary'
          onClick={handleDeleteSelectedRows}
          disabled={selectedDataList.length === 0 || processing} // 処理中はボタンを非活性化
        >
          {processing ? '処理中...' : '削除'}
        </Button>
      </div>
    </>
  );

}else{                                       //事業場区分は管理者以外？
  return (
    <>
    </>
  );

}
}export default GarbageTmpListTable;
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#ffa500',  // '#65b2c6',
      color: '#fff',
    }
  },
  grid2: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#C5956B',
      color: '#fff',
    },
    // 選択した行の背景色を薄黄色に指定
    '.MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'rgba(255, 255, 0, 0.2)',
    },
    // クリックで選択した行の背景色を薄桃色に指定
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'rgba(220, 220, 220, 0.7)', // 薄灰色
    },
    '& .clicked-row': {
      backgroundColor: 'rgba(255, 182, 193, 0.3) !important', // 薄桃色
    },
  }
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
