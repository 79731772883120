// /src/lib/api/receipt_load_adm_transport.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptLoadAdmTransportList = () => {
  return client.get('/receipt_load_adm_transports');
};

// 事業場選択一覧
export const getReceiptLoadAdmTransportUserDetail = (id) => {
  return client.get(`/receipt_load_adm_transports/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptLoadAdmTransportUser = (id) => {
  return client.get(`/receipt_load_adm_transports/${id}/showuser`);
};


