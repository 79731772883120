import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { createSagyoReport, deleteSagyoReport, getSagyoReportChargeId } from '../../lib/api/sagyo_report';
import MblSagyoReportEdit from './MblSagyoReportEdit';
import MblSagyoReportAdd from './MblSagyoReportAdd';
import SpaceRow from '../commons/SpaceRow';

import {
  Button,
  TextField,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

import { makeStyles,styled } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function MblSagyoReport() {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const [queryValue, setQueryValue] = useState(null);

  const query = useParams();
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState({});
  const [filter, setFilter] = useState({
    sagyoDateStart: dayjs().format('YYYY-MM-DD'), // 当日日付を開始日にセット
    sagyoDateEnd: dayjs().format('YYYY-MM-DD') // 当日日付を終了日にセット
  });
  const [showDialog, setShowDialog] = useState(false); // ダイアログ表示状態を管理するstate
  const [dialogOpenEdit, setDialogOpenEdit] = useState(false);
  const [dialogOpenAdd, setDialogOpenAdd] = useState(false);
  const [mblUserName, setMblUserName] = useState([]);

  useEffect(() => {
    handleGetData();
  }, []);

  const handleDataUpdated = () => {
    handleGetData(); // データを再取得して更新
  };

  const handleGetData = async () => {
    setQueryValue(query); // queryの値をstateにセット
    const mbluser = Cookies.get('mbl_user');
    console.log("mbluser",mbluser);
    const mbluser2 = mbluser.split(':');
    setMblUserName(mbluser2[2]);

    if (filter.sagyoDateStart == ''){
      filter.sagyoDateStart = dayjs().format('YYYY-MM-DD');
    }
    if (filter.sagyoDateEnd == ''){
      filter.sagyoDateEnd = dayjs().format('YYYY-MM-DD');
    }
    const param_item = mbluser2[0] + "_"+filter.sagyoDateStart+"_"+filter.sagyoDateEnd;
    console.log("param_item",param_item);
    try {
        const res = await getSagyoReportChargeId(param_item);
        setDataList(res.data);
        // setSelectedRowData(param.row);
    } catch (e) {
        console.log(e);
    }
  };

  const handleDataDisplay = () => {
    handleGetData();
  };
  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    console.log("dataList2",dataList2);
  };

  // ポップアップで編集画面を閉じる関数
  const openEditForm = () => {
    setDialogOpenEdit(true);
  };
  const closeEditForm = () => {
    setDialogOpenEdit(false);
  };

  const openAddForm = () => {
    setDialogOpenAdd(true);
  };
  const closeAddForm = () => {
    setDialogOpenAdd(false);
  };

  const handleWorkStartCreate = async (e) => {
    if (window.confirm('作業開始登録を行います。よろしいですか？')) {
      e.preventDefault();
        const params = generateParamsStart();
        // console.log('params',params)
        try {
          const res = await createSagyoReport(params);
          // console.log("createSagyoReport_res",res);
        } catch (e) {
          console.log(e);
          console.log(e.response);
        } 
        handleGetData();
    }
  };

  const generateParamsStart = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const currentTime = dayjs().format('HH:mm');
    const params = {
        chargeId: queryValue.id,
        sagyoDate: currentDate,
        sagyoTime: currentTime,
        sagyoPlace: '',
        sagyoTask: '作業開始',
        sagyoMemo: '',
    };
    return params;
  };

  const handleWorkCreate = async (e) => {
    setDialogOpenAdd(true);
  };

  const handleWorkEndCreate = async (e) => {
    if (window.confirm('作業終了登録を行います。よろしいですか？')) {
      e.preventDefault();
        const params = generateParamsEnd();
        // console.log('params',params)
        try {
          const res = await createSagyoReport(params);
          // console.log("createSagyoReport_res",res);
        } catch (e) {
          console.log(e);
          console.log(e.response);
        } 
        handleGetData();
    }
  };

  const generateParamsEnd = () => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const currentTime = dayjs().format('HH:mm');
    const params = {
        chargeId: queryValue.id,
        sagyoDate: currentDate,
        sagyoTime: currentTime,
        sagyoPlace: '',
        sagyoTask: '作業終了',
        sagyoMemo: '',
    };
    return params;
  };

  const hanleDeleteWork = async() => {
    if (window.confirm('選択の作業を削除します。よろしいですか？')) {
      try {
        const res = await deleteSagyoReport(dataList2.id);
        // console.log("sr_createSagyoReport_res",res);
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
      handleGetData();
    }    
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      width: 100
    },
    {
      field: 'sagyoDate',
      headerName: '作業日',
      width: 100
    },
    {
      field: 'sagyoTime',
      headerName: '作業時刻',
      width: 80,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' });
      }
    },
    {
      field: 'sagyoTask',
      headerName: '作業内容',
      width: 110
    },
    {
      field: 'sagyoPlace',
      headerName: '作業場所',
      width: 200
    },
    {
      field: 'sagyoMemo',
      headerName: 'メモ',
      width: 300
    },
  ]

  const handleClickRsvno = async() => {
    let d = new Date();
    const garbageTag = dataList2.id + "-" + d.valueOf();
    history.push(`/mbl_emission_set/${garbageTag}` );
  };

  return (
    <>
      <h1>担当者作業報告　　{mblUserName}</h1>
      <SpaceRow height={10} />

      <Grid
        container
        spacing={2}
      >
      <Button variant="contained" size="large" style={{ color: '#fff', backgroundColor: '#ff69b4', marginLeft: 30 }} onClick={handleWorkStartCreate}>
        作業開始
      </Button>
      <Button variant="contained" size="large" style={{ color: '#fff', backgroundColor: '#cd5c5c', margin: '20px' }} onClick={openAddForm}>
        作業登録
      </Button>
      <Button variant="contained" size="large" style={{ color: '#fff', backgroundColor: '#1e90ff' }} onClick={handleWorkEndCreate}>
        作業終了
      </Button>
      <Button variant='contained' onClick={() => history.push('/')} style={{ margin: '20px', marginLeft: 50 }}>
        戻る
      </Button>
      <SpaceRow height={10} />
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>

          <Typography variant="h7" style={{ fontWeight: 700 }}>作業期間：</Typography>
          <TextField
            id="sagyoDate-start"
            value={filter.sagyoDateStart}
            onChange={e => setFilter({ ...filter, sagyoDateStart: e.target.value })}
            label=""
            type={"date"}
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="sagyoDate-end"
            value={filter.sagyoDateEnd}
            onChange={e => setFilter({ ...filter, sagyoDateEnd: e.target.value })}
            label=""
            type={"date"}
            className={classes.textField}
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" color="primary" style={{ marginLeft: 20 }} onClick={handleDataDisplay}>データ表示</Button>

        </Grid>
      </Grid>

      <SpaceRow height={20} />
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={cols}
          rows={dataList}
          density="compact"
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
      </Box>
      <SpaceRow height={10} />
      <table>
        <tr>
          <th>作業日時</th>
          <td>
          <Typography variant="h7" style={{ fontWeight: 700 }}>
            {dataList2.sagyoDate} {new Date(dataList2.sagyoTime).toLocaleTimeString('ja-JP', { hour: '2-digit', minute: '2-digit' })}
          </Typography>            </td>
        </tr>
        <tr>
          <th>作業内容</th>
          <td>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sagyoTask}</Typography>
          </td>
        </tr>
        <tr>
          <th>作業場所</th>
          <td>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sagyoPlace}</Typography>
          </td>
        </tr>
        <tr>
          <th>メモ</th>
          <td>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sagyoMemo}</Typography>
          </td>
        </tr>
      </table>
      <SpaceRow height={10} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='contained' color='secondary' onClick={hanleDeleteWork} disabled={!dataList2.id} style={{ marginRight: 30 }}>
          削除
        </Button>
        <Button variant="contained" color="primary" onClick={openEditForm} disabled={!dataList2.id}>
          編集
        </Button>
      </Box>
      <MblSagyoReportEdit
        isOpen={dialogOpenEdit}
        doClose={() => closeEditForm()}
        dataList={dataList2}
        onDataUpdated={handleDataUpdated} // データ更新後のコールバックを渡す
      />
      <MblSagyoReportAdd
        isOpen={dialogOpenAdd}
        doClose={() => closeAddForm()}
        onDataUpdated={handleDataUpdated} // データ更新後のコールバックを渡す
      />
    </>
  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#a52a2a',
      color: '#fff',
    }
  }
}
export default MblSagyoReport;