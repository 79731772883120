// Form.jsx
//import React, { useState, useEffect } from 'react';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { Backdrop, CircularProgress, } from '@material-ui/core';

import SpaceRow from '../commons/SpaceRow';
import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeSelList } from '../../lib/api/charge_sel';
//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
//import { getTraderSelList } from '../../lib/api/trader_sel';
//import { getWkLinkageSetList } from '../../lib/api/wk_linkage_set';
//import { getLinkageList } from '../../lib/api/linkage';
//import { getEgsMLinkageUser } from '../../lib/api/egs_m_linkage';
import { getLinkageUser } from '../../lib/api/linkage';

import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getEgsMToxicSubstanceList } from '../../lib/api/egs_m_toxic_substance';
import { getEgsMDisposalMethodList } from '../../lib/api/egs_m_disposal_method';
import { getEgsMTransportMethodList } from '../../lib/api/egs_m_transport_method';
import { getEgsMUnitList } from '../../lib/api/egs_m_unit';
import { getEgsMPackingList } from '../../lib/api/egs_m_packing';

import './app.css';
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

//const useStyles = makeStyles((theme) => ({													yamauchi 追加-s
//  backdrop: {
//    zIndex: theme.zIndex.drawer + 1,
//    color: '#fff',
//  },
//}));																						yamauchi 追加-e

const ProcessFlowAddForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, handleSubmit, value, buttonType } = props;

  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);


//  const [dataList, setDataList] = useState([]);
//  const [dataList2, setDataList2] = useState([]);

//const classes = useStyles();
const [open, setOpen] = React.useState(false);

useEffect(() => {
    handleGetList();
  }, []);

/*
  const handleGetList = async () => {
    try {
      const res = await getEgsMChargeList();
      // console.log('res',res.data);
      setDataList(res.data);
      const res2 = await getTraderSelList();
      // console.log(res2.data);
      setDataList2(res2.data);
    } catch (e) {
      console.log(e);
    }
  };
  const chargedataList = dataList.filter(dataList => {
    return dataList.traderCd == value.exhaustCd
  });
  // console.log('chargedataList',chargedataList);
  const traderDataList = dataList2.filter(dataList2 => {
    return dataList2.traderCd != '' && dataList2.traderKbn != '1000000000'
  });
  // console.log('traderDataList',traderDataList);
*/

const [dataListC00, setDataListC00] = useState([]);    {/* 排出担当者 */}
const [dataListC01, setDataListC01] = useState([]);    {/* 運搬担当者 */}
//const [dataListC07, setDataListC07] = useState([]);    {/* 最終処分担当者 */}
//const [dataListc11, setDataListc11] = useState([]);    {/* 積替え保管 */}

const [dataListT00, setDataListT00] = useState([]);    {/* 排出事業場 */}
//const [dataListT01, setDataListT01] = useState([]);    {/* 運搬事業場 */}
//const [dataListT07, setDataListT07] = useState([]);    {/* 最終処分 */}
//const [dataListT11, setDataListT11] = useState([]);    {/* 運搬先事業場 */}
////const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}

const [dataListWaste,setDataListWaste] = useState([]);  {/* 廃棄物種類 */}
const [dataListToxic,setDataListToxic] = useState([]);  {/* 有害物質 */}
const [dataListDisposal,setDataListDisposal] = useState([]);  {/* 処分方法 */}
const [dataListTransport,setDataListTransport] = useState([]);  {/* 運搬方法 */}
const [dataListUnit,setDataListUnit] = useState([]);  {/* 単位 */}
const [dataListPacking,setDataListPacking] = useState([]);  {/* 荷姿 */}

useEffect(() => {
  handleGetList();
}, []);

const handleGetList = async () => {
  try {
    // console.log('value 1',value.exhaustId,value.sect1TransportId,currentUser.traderCd);

   setOpen(!open);
    const dataListWaste = await getEgsMWasteclassList();
    setDataListWaste(dataListWaste.data);
//    // console.log('wasteCd',value.wasteCd,'dataListWaste',dataListWaste.data);

    const dataListToxic = await getEgsMToxicSubstanceList();
    setDataListToxic(dataListToxic.data);
//    // console.log('toxicCd01',value.toxicCd01,'dataListToxic',dataListToxic.data);

    const dataListDisposal = await getEgsMDisposalMethodList();
    setDataListDisposal(dataListDisposal.data);
//    // console.log('disposalMethodCd',value.disposalMethodCd,'dataListDisposal',dataListDisposal.data);

    const dataListTransport = await getEgsMTransportMethodList();
    setDataListTransport(dataListTransport.data);
    // console.log('sect1TransportMethodCd',value.sect1TransportMethodCd,'dataListTransport',dataListTransport.data);

    const dataListUnit = await getEgsMUnitList();
    setDataListUnit(dataListUnit.data);
//    // console.log('unitCd',value.unitCd,'dataListUnit',dataListUnit.data);

    const dataListPacking = await getEgsMPackingList();
    setDataListPacking(dataListPacking.data);
//    // console.log('packingCd',value.packingCd,'dataListPacking',dataListPacking.data);


    const resc00 = await getEgsMChargeList();
//      // console.log('resc00',resc00.data);
    setDataListC00(resc00.data);
    const resc01 = await getChargeSelList();
    setDataListC01(resc01.data);
//    const resc07 = await getChargeSelList();
//    setDataListC07(resc07.data);
//    const resc11 = await getChargeSelList();
//    setDataListc11(resc11.data);

//    const resT00 = await getEgsMLinkageList();
//    const resT00 = await getEgsMTraderList();
//    const resT00 = await getEgsMLinkageSetList();
//    const resT00 = await getLinkageList();
//    const resT00 = await getEgsMLinkageUser(value.sect1TransportId);
//    const resT00 = await getEgsMLinkageUser(currentUser.traderCd);
    const resT00 = await getLinkageUser(currentUser.traderCd);
      // console.log(resT00.data,'<-',value.sect1TransportId);
    setDataListT00(resT00.data);
//    const resT01 = await getTraderSelList();
//    // console.log(resT01.data);
//    setDataListT01(resT01.data);

//    const resT01 = await getEgsMLinkageList();
//    setDataListT01(resT01.data);

//    const resT07 = await getEgsMLinkageList();
//    setDataListT07(resT07.data);

//    const resT11 = await getEgsMLinkageList();
//    setDataListT11(resT11.data);



    setOpen(false);
  } catch (e) {
    console.log(e);
  }
};

//// console.log('value 2',value.exhaustId,value.sect1TransportId);
//// console.log('sectTrader',value.sect1TransportId,value.sect2TransportId,value.sect3TransportId,value.sect4TransportId,value.sect5TransportId);
// console.log('destination',value.destination1Id,value.destination2Id,value.destination3Id,value.destination4Id,value.destination5Id);


const wastedataList = dataListWaste.filter(dataListWaste => { return dataListWaste.wastclassCd != '' });
//// console.log('wastedataList',wastedataList);

const toxicdataList = dataListToxic.filter(dataListToxic => { return dataListToxic.toxicSubstanceCd != '' });
//// console.log('toxicdataList',toxicdataList);

const disposaldataList = dataListDisposal.filter(dataListDisposal => { return dataListDisposal.disposalMethodCd != '' });
//// console.log('disposaldataList',disposaldataList);

//const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd == value.sect1TransportMethodCd });
const trans1dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans2dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans3dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans4dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
const trans5dataList = dataListTransport.filter(dataListTransport => { return dataListTransport.transportMethodCd != '' });
//// console.log('transport',value.sect1TransportMethodCd,value.sect2TransportMethodCd,value.sect3TransportMethodCd,value.sect4TransportMethodCd,value.sect5TransportMethodCd);

const unitdataList = dataListUnit.filter(dataListUnit => { return dataListUnit.unitclassCd != '' });
//// console.log('unitdataList',unitdataList);

const packingdataList = dataListPacking.filter(dataListPacking => { return dataListPacking.packingCd != '' });


//const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.traderCd == value.exhaustCd });
//const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.traderKbn == '1000000000' });
const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.egsMTraderId == value.exhaustId });
//// console.log('chargedataList00',chargedataList00);
//const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.traderCd == value.sect1TransportCd });
//const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.egsMTraderId1 });
const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect1TransportId });

const chargedataList02 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect2TransportId });
const chargedataList03 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect3TransportId });
const chargedataList04 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect4TransportId });
const chargedataList05 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.sect5TransportId });

const chargedataList07 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.lastProcessingTraderId });
//// console.log('chargedataList07',chargedataList07,'<-',value.lastProcessingTraderId);
const chargedataList11 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination1Id });
//// console.log('chargedataList11',chargedataList11,'<-',value.destination1Id);

const chargedataList12 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination2Id });
const chargedataList13 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination3Id });
const chargedataList14 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination4Id });
const chargedataList15 = dataListC01.filter(dataListC01 => { return dataListC01.egsMTraderId == value.destination5Id });



//// console.log('<',value.exhaustCd,':',value.sect1TransportCd,'>')
//const linkagedataList00 = dataListT00.filter(dataListT00 => { return dataListT00.traderCd1 == value.exhaustCd && dataListT00.traderCd2 == value.sect1TransportCd && dataListT00.traderKbn1 == '1000000000' });
//const linkagedataList00 = dataListT00.filter(dataListT00 => { return dataListT00.traderCd1 == value.exhaustCd || dataListT00.traderCd1 == value.exhaustCd || dataListT00.traderKbn1 == '1000000000' });
//const linkagedataList00 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn1 == '1000000000'   &&
//                                                                  (  dataListT00.traderCd1 == value.exhaustCd ||
//                                                                     dataListT00.traderCd2 == value.exhaustCd ||
//                                                                   ( dataListT00.traderCd1 != value.exhaustCd &&
//                                                                     dataListT00.traderCd2 != value.exhaustCd    ) ) });
//const linkagedataList00 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn1 == '1000000000' });
//const traderDataList00 = dataListT00.filter(dataListT00 => { return dataListT00.id == value.exhaustId });
const traderDataList00 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect1TransportId && dataListT00.traderKbn2 == '1000000000'});
//// console.log('traderDataList00',traderDataList00);


//const linkagedataList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderCd1 == value.exhaustCd && dataListT01.traderCd2 == value.sect1TransportCd && dataListT01.traderKbn2 == '0100000000'});
//const linkagedataList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderCd1 == value.exhaustCd && dataListT01.traderCd2 == value.sect1TransportCd });
//  // console.log('linkagedataList01',linkagedataList01);
//const linkagedataList01 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0100000000'   &&
//                                                                  (  dataListT00.traderCd1 == value.exhaustCd ||
//                                                                     dataListT00.traderCd2 == value.exhaustCd ||
//                                                                   ( dataListT00.traderCd1 != value.exhaustCd &&
//                                                                     dataListT00.traderCd2 != value.exhaustCd    ) ) });
//const traderSelList01 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn == '0100000000' });
////const traderseldataList01 = dataListT01.filter(dataListT01 => { return dataListT01.id == value.sect1TransportId });
//const traderseldataList01 = dataListT00.filter(dataListT00 => { return dataListT00.id == value.sect1TransportId });
//const traderDataList01 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect1TransportId });   // このままだと排出以外が表示される
const traderDataList01 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect1TransportId
                                                                 && dataListT00.traderKbn2 == '0100000000'});
//// console.log('traderDataList01',traderDataList01,'<-',value.sect1TransportId)

const traderDataList02 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0100000000'});
const traderDataList03 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0100000000'});
const traderDataList04 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0100000000'});
const traderDataList05 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0100000000'});

//const linkagedataList07 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0000100000'   &&
//                                                                  (  dataListT00.traderCd1 == value.exhaustCd ||
//                                                                     dataListT00.traderCd2 == value.exhaustCd ||
//                                                                   ( dataListT00.traderCd1 != value.exhaustCd &&
//                                                                     dataListT00.traderCd2 != value.exhaustCd    ) ) });
////const traderSelList07 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn == '0000100000'
////                                                                  || dataListT01.traderKbn == '1000100000'});
const traderDataList07 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect1TransportId &&
                                                                (   dataListT00.traderKbn2 == '0000100000'
                                                                 || dataListT00.traderKbn2 == '0001001000'
                                                                 || dataListT00.traderKbn2 == '1000100000' ) });

//const linkagedataList11 = dataListT11.filter(dataListT11 => { return dataListT11.traderCd1 != '' && dataListT11.traderKbn2 == '0010000000' });
//const linkagedataList11 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0010000000'   &&
//                                                                  (  dataListT00.traderCd1 == value.exhaustCd ||
//                                                                     dataListT00.traderCd2 == value.exhaustCd ||
//                                                                   ( dataListT00.traderCd1 != value.exhaustCd &&
//                                                                     dataListT00.traderCd2 != value.exhaustCd    ) ) });
//const traderSelList11 = dataListT01.filter(dataListT01 => { return dataListT01.traderKbn == '0010000000' });
const traderSelList11 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect1TransportId && dataListT00.traderKbn2 == '0010000000' });
//// console.log('linkagedataList11',linkagedataList11);

//const traderSelList12 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect2TransportId && dataListT00.traderKbn2 == '0010000000' });
//const traderSelList13 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect3TransportId && dataListT00.traderKbn2 == '0010000000' });
//const traderSelList14 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect4TransportId && dataListT00.traderKbn2 == '0010000000' });
//const traderSelList15 = dataListT00.filter(dataListT00 => { return dataListT00.egsMTraderId1 == value.sect5TransportId && dataListT00.traderKbn2 == '0010000000' });
const traderSelList12 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0010000000' });
const traderSelList13 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0010000000' });
const traderSelList14 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0010000000' });
const traderSelList15 = dataListT00.filter(dataListT00 => { return dataListT00.traderKbn2 == '0010000000' });

  return (
    <>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpaceRow height={20} />

      <form className={classes.root} noValidate autoComplete='off'>
       <table>
        <tr><th>契約名称</th><td><TextField id='reserveName' label='契約名称' variant="outlined" type='text' name='reserveName' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.reserveName} /></td>
            <th>契約日</th><td><TextField id='reserveDate' label='契約日' variant="outlined" type='date' name='reserveDate' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.reserveDate} /></td></tr>
        <tr><th>契約番号</th><td><TextField id='reserveNo' label='契約番号' variant="outlined" type='text' name='reserveNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.reserveNo} /></td>
            <th>契約サブ番号</th><td><TextField id='reserveSubno' label='契約サブ番号' variant="outlined" type='text' name='reserveSubno' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.reserveSubno} /></td></tr>
        <tr>

          <th>排出事業場</th>
         {/*}  <td><TextField id='exhaustCd' label='排出事業場' variant="outlined" type='text' name='exhaustCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustCd} /></td>   */}
{/*
              <td><TextField id='exhaustName' label='排出事業場名' variant="outlined" type='text' name='exhaustName' halfWidth margin="dense" value={value.exhaustName} /></td>
*/}
            <td>
              <Select
              id='exhaustId'
              label='排出事業場'
              variant="outlined"
              type='text'
              name='exhaustId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.exhaustId}
            >
            {traderDataList00.map((traderDataList00) => (
              <MenuItem
                key={traderDataList00.egsMTraderId2}
                value={traderDataList00.egsMTraderId2}
              >
                {traderDataList00.traderName2} : {traderDataList00.jwnetNo}
              </MenuItem>
            ))}
            </Select></td>

            {/* <th>排出事業場担当者</th><td><TextField id='exhaustChargeCd' label='排出事業場担当者CD' variant="outlined" type='text' name='exhaustChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustChargeCd} /></td></tr> */}
            <th>排出事業場担当者</th>
            <td>
            <Select
              id='exhaustChargeId'
              label='排出事業場担当者'
              variant="outlined"
              type='text'
              name='exhaustChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.exhaustChargeId}
            >
            {chargedataList00.map((chargedataList00) => (
              <MenuItem
                key={chargedataList00.id}
                value={chargedataList00.id}
              >
                {chargedataList00.chargeCd}:{chargedataList00.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td></tr>

            <tr>

          <th>最終処分事業場</th>
      {/*}        <td><TextField id='lastProcessingTraderName' label='最終処分事業場名' variant="outlined" type='text' name='lastProcessingTraderName' halfWidth margin="dense" value={value.lastProcessingTraderName} /></td>   */}

          <td>
              <Select
              id='lastProcessingTraderId'
              label='最終処分事業場'
              variant="outlined"
              type='text'
              name='lastProcessingTraderId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.lastProcessingTraderId}
            >
            {traderDataList07.map((traderDataList07) => (
              <MenuItem
                key={traderDataList07.egsMTraderId2}
                value={traderDataList07.egsMTraderId2}
              >
                {traderDataList07.traderName2} {/* {linkagedataList07.traderCd2}:{linkagedataList07.traderName2} */}
              </MenuItem>
            ))}
            </Select></td>


            {/* <th>最終処分事業場担当者</th><td><TextField id='lastProcessingChargeCd' label='最終処分事業場担当者' variant="outlined" type='text' name='lastProcessingChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.lastProcessingChargeCd} /></td></tr> */}
            <th>最終処分事業場担当者</th>
            <td>
            <Select
              id='lastProcessingChargeId'
              label='最終処分事業場担当者'
              variant="outlined"
              type='text'
              name='lastProcessingChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.lastProcessingChargeId}
            >
            {chargedataList07.map((chargedataList07) => (
              <MenuItem
                key={chargedataList07.id}
                value={chargedataList07.id}
              >
                {chargedataList07.chargeCd}:{chargedataList07.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td></tr>
</table><table>
<tr>
{/*
          <th>廃棄物種類</th>
    <td>
        <Select
          id='wasteKindCd'
          label='廃棄物種類'
          variant="outlined"
          type='text'
          name='wasteKindCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.wasteKindCd}
        >
          <MenuItem value={value.wasteKindCd}>
            <em>廃棄物種類</em>
          </MenuItem>
            <MenuItem value={'0100000'}>0100000:燃え殻</MenuItem>
            <MenuItem value={'0110000'}>0110000:焼却灰</MenuItem>
            <MenuItem value={'0111000'}>0111000:石炭灰</MenuItem>
            <MenuItem value={'0112000'}>0112000:廃棄物の焼却灰</MenuItem>
            <MenuItem value={'0120000'}>0120000:廃カーボン・活性炭</MenuItem>
            <MenuItem value={'0200000'}>0200000:汚泥（泥状のもの）</MenuItem>
            <MenuItem value={'0210000'}>0210000:有機性汚泥</MenuItem>
            <MenuItem value={'0211000'}>0211000:下水汚泥</MenuItem>
            <MenuItem value={'0220000'}>0220000:無機性汚泥</MenuItem>
            <MenuItem value={'0221000'}>0221000:建設汚泥（残土を除く）</MenuItem>
            <MenuItem value={'0222000'}>0222000:上水汚泥</MenuItem>
            <MenuItem value={'0300000'}>0300000:廃油</MenuItem>
            <MenuItem value={'0310000'}>0310000:一般廃油</MenuItem>
            <MenuItem value={'0311000'}>0311000:鉱物性油</MenuItem>
            <MenuItem value={'0312000'}>0312000:動植物性油</MenuItem>
            <MenuItem value={'0320000'}>0320000:廃溶剤</MenuItem>
            <MenuItem value={'0330000'}>0330000:固形油</MenuItem>
            <MenuItem value={'0340000'}>0340000:油でい</MenuItem>
            <MenuItem value={'0400000'}>0400000:廃酸</MenuItem>
            <MenuItem value={'0401000'}>0401000:写真定着廃液</MenuItem>
            <MenuItem value={'0500000'}>0500000:廃アルカリ</MenuItem>
            <MenuItem value={'0501000'}>0501000:写真現像廃液</MenuItem>
            <MenuItem value={'0600000'}>0600000:廃プラスチック類</MenuItem>
            <MenuItem value={'0601000'}>0601000:廃タイヤ</MenuItem>
            <MenuItem value={'0602000'}>0602000:自動車用プラスチックバンパー</MenuItem>
            <MenuItem value={'0603000'}>0603000:廃農業用ビニール</MenuItem>
            <MenuItem value={'0604000'}>0604000:プラスチック製廃容器包装</MenuItem>
            <MenuItem value={'0605000'}>0605000:発泡スチロール</MenuItem>
            <MenuItem value={'0606000'}>0606000:発泡ウレタン</MenuItem>
            <MenuItem value={'0607000'}>0607000:発泡ポリスチレン</MenuItem>
            <MenuItem value={'0608000'}>0608000:塩化ビニル製建築資材</MenuItem>
            <MenuItem value={'0700000'}>0700000:紙くず</MenuItem>
            <MenuItem value={'0710000'}>0710000:建設工事の紙くず</MenuItem>
            <MenuItem value={'0711000'}>0711000:ダンボール</MenuItem>
            <MenuItem value={'0800000'}>0800000:木くず</MenuItem>
            <MenuItem value={'0810000'}>0810000:建設工事の木くず</MenuItem>
            <MenuItem value={'0811000'}>0811000:伐採材・伐根材</MenuItem>
            <MenuItem value={'0900000'}>0900000:繊維くず（天然繊維くず）</MenuItem>
            <MenuItem value={'0910000'}>0910000:建設工事の繊維くず</MenuItem>
            <MenuItem value={'1000000'}>1000000:動・植物性残渣</MenuItem>
            <MenuItem value={'4000000'}>4000000:動物系固形不要物</MenuItem>
            <MenuItem value={'1200000'}>1200000:金属くず</MenuItem>
            <MenuItem value={'1210000'}>1210000:鉄くず</MenuItem>
            <MenuItem value={'1220000'}>1220000:非鉄金属くず</MenuItem>
            <MenuItem value={'1221000'}>1221000:鉛製の管又は板</MenuItem>
            <MenuItem value={'1222000'}>1222000:電線くず</MenuItem>
            <MenuItem value={'1300000'}>1300000:ガラスくず、コンクリートくず及び陶磁器くず</MenuItem>
            <MenuItem value={'1310000'}>1310000:ガラスくず</MenuItem>
            <MenuItem value={'1311000'}>1311000:カレット</MenuItem>
            <MenuItem value={'1312000'}>1312000:廃ブラウン管（側面部）</MenuItem>
            <MenuItem value={'1313000'}>1313000:ガラス製廃容器包装</MenuItem>
            <MenuItem value={'1314000'}>1314000:ロックウール</MenuItem>
            <MenuItem value={'1315000'}>1315000:石綿（非飛散性）</MenuItem>
            <MenuItem value={'1316000'}>1316000:グラスウール</MenuItem>
            <MenuItem value={'1317000'}>1317000:岩綿吸音板</MenuItem>
            <MenuItem value={'1320000'}>1320000:陶磁器くず</MenuItem>
            <MenuItem value={'1321000'}>1321000:コンクリートくず</MenuItem>
            <MenuItem value={'1322000'}>1322000:石膏ボード</MenuItem>
            <MenuItem value={'1323000'}>1323000:ALC（軽量気泡コンクリート）</MenuItem>
            <MenuItem value={'1400000'}>1400000:鉱さい</MenuItem>
            <MenuItem value={'1401000'}>1401000:スラグ</MenuItem>
            <MenuItem value={'1500000'}>1500000:がれき類（工作物の新築、改築又は除去に伴って生じた不要物）</MenuItem>
            <MenuItem value={'1501000'}>1501000:コンクリート破片</MenuItem>
            <MenuItem value={'1502000'}>1502000:アスファルト・コンクリート破片</MenuItem>
            <MenuItem value={'1600000'}>1600000:動物のふん尿（畜産農業から排出されたもの）</MenuItem>
            <MenuItem value={'1700000'}>1700000:動物の死体（畜産農業から排出されたもの）</MenuItem>
            <MenuItem value={'1800000'}>1800000:ばいじん（工場の排ガスを処理して得られるばいじん）</MenuItem>
            <MenuItem value={'1900000'}>1900000:処分するために処理したもの（１３号廃棄物）</MenuItem>
            <MenuItem value={'2000000'}>2000000:建設混合廃棄物</MenuItem>
            <MenuItem value={'2010000'}>2010000:安定型建設混合廃棄物</MenuItem>
            <MenuItem value={'2020000'}>2020000:管理型建設混合廃棄物</MenuItem>
            <MenuItem value={'2021000'}>2021000:新築系混合廃棄物</MenuItem>
            <MenuItem value={'2022000'}>2022000:解体系混合廃棄物</MenuItem>
            <MenuItem value={'2100000'}>2100000:安定型混合廃棄物（内訳を入力する必要有り）</MenuItem>
            <MenuItem value={'2200000'}>2200000:管理型混合廃棄物（内訳を入力する必要有り）</MenuItem>
            <MenuItem value={'2300000'}>2300000:シュレッターダスト</MenuItem>
            <MenuItem value={'3000000'}>3000000:廃自動車</MenuItem>
            <MenuItem value={'3010000'}>3010000:廃二輪車</MenuItem>
            <MenuItem value={'3011000'}>3011000:バイク</MenuItem>
            <MenuItem value={'3012000'}>3012000:自転車</MenuItem>
            <MenuItem value={'3100000'}>3100000:廃電気機械器具</MenuItem>
            <MenuItem value={'3101000'}>3101000:廃パチンコ機及び廃パチスロ機</MenuItem>
            <MenuItem value={'3102000'}>3102000:プリント配線板</MenuItem>
            <MenuItem value={'3103000'}>3103000:テレビジョン受信機</MenuItem>
            <MenuItem value={'3104000'}>3104000:エアコンディショナー</MenuItem>
            <MenuItem value={'3105000'}>3105000:冷蔵庫</MenuItem>
            <MenuItem value={'3106000'}>3106000:洗濯機</MenuItem>
            <MenuItem value={'3107000'}>3107000:電子レンジ</MenuItem>
            <MenuItem value={'3108000'}>3108000:パーソナルコンピュータ</MenuItem>
            <MenuItem value={'3109000'}>3109000:電話機</MenuItem>
            <MenuItem value={'3110000'}>3110000:自動販売機</MenuItem>
            <MenuItem value={'3111000'}>3111000:蛍光灯</MenuItem>
            <MenuItem value={'3112000'}>3112000:冷凍庫</MenuItem>
            <MenuItem value={'3500000'}>3500000:廃電池類</MenuItem>
            <MenuItem value={'3510000'}>3510000:鉛蓄電池</MenuItem>
            <MenuItem value={'3520000'}>3520000:乾電池</MenuItem>
            <MenuItem value={'3600000'}>3600000:複合材</MenuItem>
            <MenuItem value={'7000000'}>7000000:燃えやすい廃油</MenuItem>
            <MenuItem value={'7010000'}>7010000:燃えやすい廃油（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7100000'}>7100000:pH2.0以下の廃酸</MenuItem>
            <MenuItem value={'7110000'}>7110000:pH2.0以下の廃酸（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7200000'}>7200000:pH12.5以上の廃アルカリ</MenuItem>
            <MenuItem value={'7210000'}>7210000:pH12.5以上の廃アルカリ（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7300000'}>7300000:感染性廃棄物</MenuItem>
            <MenuItem value={'7400000'}>7400000:特定有害産業廃棄物</MenuItem>
            <MenuItem value={'7410000'}>7410000:廃PCB等・PCB汚染物・PCB処理物</MenuItem>
            <MenuItem value={'7411000'}>7411000:廃PCB</MenuItem>
            <MenuItem value={'7412000'}>7412000:PCB汚染物</MenuItem>
            <MenuItem value={'7413000'}>7413000:PCB処理物</MenuItem>
            <MenuItem value={'7421000'}>7421000:廃石綿等（飛散性）</MenuItem>
            <MenuItem value={'7422000'}>7422000:指定下水汚泥</MenuItem>
            <MenuItem value={'7423000'}>7423000:鉱さい（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7424000'}>7424000:燃え殻（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7425000'}>7425000:廃油（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7426000'}>7426000:汚泥（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7427000'}>7427000:廃酸（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7428000'}>7428000:廃アルカリ（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7429000'}>7429000:ばいじん（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7430000'}>7430000:処分するために処理したもの（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'9000000'}>9000000:土砂</MenuItem>
            <MenuItem value={'9100000'}>9100000:しゅんせつ土</MenuItem>
            <MenuItem value={'1100000'}>1100000:ゴムくず（天然ゴムくず）</MenuItem>
            <MenuItem value={'2400000'}>2400000:石綿含有産業廃棄物</MenuItem>
            <MenuItem value={'2410000'}>2410000:建設混合廃棄物</MenuItem>
            <MenuItem value={'2420000'}>2420000:ガラスくず、コンクリートくず及び陶磁器くず</MenuItem>
            <MenuItem value={'2430000'}>2430000:廃プラスチック類</MenuItem>
            <MenuItem value={'2440000'}>2440000:がれき類（工作物の新築、改築又は除去に伴って生じた不要物）</MenuItem>
            <MenuItem value={'2450000'}>2450000:紙くず</MenuItem>
            <MenuItem value={'2460000'}>2460000:木くず</MenuItem>
            <MenuItem value={'2470000'}>2470000:繊維くず（天然繊維くず）</MenuItem>
            <MenuItem value={'9900000'}>9900000:機密書類</MenuItem>
            <MenuItem value={'9901000'}>9901000:機密書類（紙）</MenuItem>
            <MenuItem value={'9902000'}>9902000:機密書類（プラ）</MenuItem>
            <MenuItem value={'9903000'}>9903000:機密書類（その他）</MenuItem>
            <MenuItem value={'9902001'}>9902001:機密書類</MenuItem>
         </Select>
    </td>
*/}
            <th>廃棄物名称</th>
{/*}
    <td>
        <Select
          id='wasteCd'
          label='廃棄物名称'
          variant="outlined"
          type='text'
          name='wasteCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.wasteCd}
        >
          <MenuItem value={value.wasteCd}>
            <em>廃棄物名称</em>
          </MenuItem>
            <MenuItem value={'0100000'}>0100000:燃え殻</MenuItem>
            <MenuItem value={'0110000'}>0110000:焼却灰</MenuItem>
            <MenuItem value={'0111000'}>0111000:石炭灰</MenuItem>
            <MenuItem value={'0112000'}>0112000:廃棄物の焼却灰</MenuItem>
            <MenuItem value={'0120000'}>0120000:廃カーボン・活性炭</MenuItem>
            <MenuItem value={'0200000'}>0200000:汚泥（泥状のもの）</MenuItem>
            <MenuItem value={'0210000'}>0210000:有機性汚泥</MenuItem>
            <MenuItem value={'0211000'}>0211000:下水汚泥</MenuItem>
            <MenuItem value={'0220000'}>0220000:無機性汚泥</MenuItem>
            <MenuItem value={'0221000'}>0221000:建設汚泥（残土を除く）</MenuItem>
            <MenuItem value={'0222000'}>0222000:上水汚泥</MenuItem>
            <MenuItem value={'0300000'}>0300000:廃油</MenuItem>
            <MenuItem value={'0310000'}>0310000:一般廃油</MenuItem>
            <MenuItem value={'0311000'}>0311000:鉱物性油</MenuItem>
            <MenuItem value={'0312000'}>0312000:動植物性油</MenuItem>
            <MenuItem value={'0320000'}>0320000:廃溶剤</MenuItem>
            <MenuItem value={'0330000'}>0330000:固形油</MenuItem>
            <MenuItem value={'0340000'}>0340000:油でい</MenuItem>
            <MenuItem value={'0400000'}>0400000:廃酸</MenuItem>
            <MenuItem value={'0401000'}>0401000:写真定着廃液</MenuItem>
            <MenuItem value={'0500000'}>0500000:廃アルカリ</MenuItem>
            <MenuItem value={'0501000'}>0501000:写真現像廃液</MenuItem>
            <MenuItem value={'0600000'}>0600000:廃プラスチック類</MenuItem>
            <MenuItem value={'0601000'}>0601000:廃タイヤ</MenuItem>
            <MenuItem value={'0602000'}>0602000:自動車用プラスチックバンパー</MenuItem>
            <MenuItem value={'0603000'}>0603000:廃農業用ビニール</MenuItem>
            <MenuItem value={'0604000'}>0604000:プラスチック製廃容器包装</MenuItem>
            <MenuItem value={'0605000'}>0605000:発泡スチロール</MenuItem>
            <MenuItem value={'0606000'}>0606000:発泡ウレタン</MenuItem>
            <MenuItem value={'0607000'}>0607000:発泡ポリスチレン</MenuItem>
            <MenuItem value={'0608000'}>0608000:塩化ビニル製建築資材</MenuItem>
            <MenuItem value={'0700000'}>0700000:紙くず</MenuItem>
            <MenuItem value={'0710000'}>0710000:建設工事の紙くず</MenuItem>
            <MenuItem value={'0711000'}>0711000:ダンボール</MenuItem>
            <MenuItem value={'0800000'}>0800000:木くず</MenuItem>
            <MenuItem value={'0810000'}>0810000:建設工事の木くず</MenuItem>
            <MenuItem value={'0811000'}>0811000:伐採材・伐根材</MenuItem>
            <MenuItem value={'0900000'}>0900000:繊維くず（天然繊維くず）</MenuItem>
            <MenuItem value={'0910000'}>0910000:建設工事の繊維くず</MenuItem>
            <MenuItem value={'1000000'}>1000000:動・植物性残渣</MenuItem>
            <MenuItem value={'4000000'}>4000000:動物系固形不要物</MenuItem>
            <MenuItem value={'1200000'}>1200000:金属くず</MenuItem>
            <MenuItem value={'1210000'}>1210000:鉄くず</MenuItem>
            <MenuItem value={'1220000'}>1220000:非鉄金属くず</MenuItem>
            <MenuItem value={'1221000'}>1221000:鉛製の管又は板</MenuItem>
            <MenuItem value={'1222000'}>1222000:電線くず</MenuItem>
            <MenuItem value={'1300000'}>1300000:ガラスくず、コンクリートくず及び陶磁器くず</MenuItem>
            <MenuItem value={'1310000'}>1310000:ガラスくず</MenuItem>
            <MenuItem value={'1311000'}>1311000:カレット</MenuItem>
            <MenuItem value={'1312000'}>1312000:廃ブラウン管（側面部）</MenuItem>
            <MenuItem value={'1313000'}>1313000:ガラス製廃容器包装</MenuItem>
            <MenuItem value={'1314000'}>1314000:ロックウール</MenuItem>
            <MenuItem value={'1315000'}>1315000:石綿（非飛散性）</MenuItem>
            <MenuItem value={'1316000'}>1316000:グラスウール</MenuItem>
            <MenuItem value={'1317000'}>1317000:岩綿吸音板</MenuItem>
            <MenuItem value={'1320000'}>1320000:陶磁器くず</MenuItem>
            <MenuItem value={'1321000'}>1321000:コンクリートくず</MenuItem>
            <MenuItem value={'1322000'}>1322000:石膏ボード</MenuItem>
            <MenuItem value={'1323000'}>1323000:ALC（軽量気泡コンクリート）</MenuItem>
            <MenuItem value={'1400000'}>1400000:鉱さい</MenuItem>
            <MenuItem value={'1401000'}>1401000:スラグ</MenuItem>
            <MenuItem value={'1500000'}>1500000:がれき類（工作物の新築、改築又は除去に伴って生じた不要物）</MenuItem>
            <MenuItem value={'1501000'}>1501000:コンクリート破片</MenuItem>
            <MenuItem value={'1502000'}>1502000:アスファルト・コンクリート破片</MenuItem>
            <MenuItem value={'1600000'}>1600000:動物のふん尿（畜産農業から排出されたもの）</MenuItem>
            <MenuItem value={'1700000'}>1700000:動物の死体（畜産農業から排出されたもの）</MenuItem>
            <MenuItem value={'1800000'}>1800000:ばいじん（工場の排ガスを処理して得られるばいじん）</MenuItem>
            <MenuItem value={'1900000'}>1900000:処分するために処理したもの（１３号廃棄物）</MenuItem>
            <MenuItem value={'2000000'}>2000000:建設混合廃棄物</MenuItem>
            <MenuItem value={'2010000'}>2010000:安定型建設混合廃棄物</MenuItem>
            <MenuItem value={'2020000'}>2020000:管理型建設混合廃棄物</MenuItem>
            <MenuItem value={'2021000'}>2021000:新築系混合廃棄物</MenuItem>
            <MenuItem value={'2022000'}>2022000:解体系混合廃棄物</MenuItem>
            <MenuItem value={'2100000'}>2100000:安定型混合廃棄物（内訳を入力する必要有り）</MenuItem>
            <MenuItem value={'2200000'}>2200000:管理型混合廃棄物（内訳を入力する必要有り）</MenuItem>
            <MenuItem value={'2300000'}>2300000:シュレッターダスト</MenuItem>
            <MenuItem value={'3000000'}>3000000:廃自動車</MenuItem>
            <MenuItem value={'3010000'}>3010000:廃二輪車</MenuItem>
            <MenuItem value={'3011000'}>3011000:バイク</MenuItem>
            <MenuItem value={'3012000'}>3012000:自転車</MenuItem>
            <MenuItem value={'3100000'}>3100000:廃電気機械器具</MenuItem>
            <MenuItem value={'3101000'}>3101000:廃パチンコ機及び廃パチスロ機</MenuItem>
            <MenuItem value={'3102000'}>3102000:プリント配線板</MenuItem>
            <MenuItem value={'3103000'}>3103000:テレビジョン受信機</MenuItem>
            <MenuItem value={'3104000'}>3104000:エアコンディショナー</MenuItem>
            <MenuItem value={'3105000'}>3105000:冷蔵庫</MenuItem>
            <MenuItem value={'3106000'}>3106000:洗濯機</MenuItem>
            <MenuItem value={'3107000'}>3107000:電子レンジ</MenuItem>
            <MenuItem value={'3108000'}>3108000:パーソナルコンピュータ</MenuItem>
            <MenuItem value={'3109000'}>3109000:電話機</MenuItem>
            <MenuItem value={'3110000'}>3110000:自動販売機</MenuItem>
            <MenuItem value={'3111000'}>3111000:蛍光灯</MenuItem>
            <MenuItem value={'3112000'}>3112000:冷凍庫</MenuItem>
            <MenuItem value={'3500000'}>3500000:廃電池類</MenuItem>
            <MenuItem value={'3510000'}>3510000:鉛蓄電池</MenuItem>
            <MenuItem value={'3520000'}>3520000:乾電池</MenuItem>
            <MenuItem value={'3600000'}>3600000:複合材</MenuItem>
            <MenuItem value={'7000000'}>7000000:燃えやすい廃油</MenuItem>
            <MenuItem value={'7010000'}>7010000:燃えやすい廃油（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7100000'}>7100000:pH2.0以下の廃酸</MenuItem>
            <MenuItem value={'7110000'}>7110000:pH2.0以下の廃酸（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7200000'}>7200000:pH12.5以上の廃アルカリ</MenuItem>
            <MenuItem value={'7210000'}>7210000:pH12.5以上の廃アルカリ（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7300000'}>7300000:感染性廃棄物</MenuItem>
            <MenuItem value={'7400000'}>7400000:特定有害産業廃棄物</MenuItem>
            <MenuItem value={'7410000'}>7410000:廃PCB等・PCB汚染物・PCB処理物</MenuItem>
            <MenuItem value={'7411000'}>7411000:廃PCB</MenuItem>
            <MenuItem value={'7412000'}>7412000:PCB汚染物</MenuItem>
            <MenuItem value={'7413000'}>7413000:PCB処理物</MenuItem>
            <MenuItem value={'7421000'}>7421000:廃石綿等（飛散性）</MenuItem>
            <MenuItem value={'7422000'}>7422000:指定下水汚泥</MenuItem>
            <MenuItem value={'7423000'}>7423000:鉱さい（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7424000'}>7424000:燃え殻（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7425000'}>7425000:廃油（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7426000'}>7426000:汚泥（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7427000'}>7427000:廃酸（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7428000'}>7428000:廃アルカリ（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7429000'}>7429000:ばいじん（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'7430000'}>7430000:処分するために処理したもの（基準値を超える有害物質を含むもの）</MenuItem>
            <MenuItem value={'9000000'}>9000000:土砂</MenuItem>
            <MenuItem value={'9100000'}>9100000:しゅんせつ土</MenuItem>
            <MenuItem value={'1100000'}>1100000:ゴムくず（天然ゴムくず）</MenuItem>
            <MenuItem value={'2400000'}>2400000:石綿含有産業廃棄物</MenuItem>
            <MenuItem value={'2410000'}>2410000:建設混合廃棄物</MenuItem>
            <MenuItem value={'2420000'}>2420000:ガラスくず、コンクリートくず及び陶磁器くず</MenuItem>
            <MenuItem value={'2430000'}>2430000:廃プラスチック類</MenuItem>
            <MenuItem value={'2440000'}>2440000:がれき類（工作物の新築、改築又は除去に伴って生じた不要物）</MenuItem>
            <MenuItem value={'2450000'}>2450000:紙くず</MenuItem>
            <MenuItem value={'2460000'}>2460000:木くず</MenuItem>
            <MenuItem value={'2470000'}>2470000:繊維くず（天然繊維くず）</MenuItem>
            <MenuItem value={'9900000'}>9900000:機密書類</MenuItem>
            <MenuItem value={'9901000'}>9901000:機密書類（紙）</MenuItem>
            <MenuItem value={'9902000'}>9902000:機密書類（プラ）</MenuItem>
            <MenuItem value={'9903000'}>9903000:機密書類（その他）</MenuItem>
            <MenuItem value={'9902001'}>9902001:機密書類</MenuItem>
         </Select>
    </td>
*/}
    <td>
            <Select
              id='wasteCd'
              label='廃棄物名称１'
              variant="outlined"
              type='text'
              name='wasteCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.wasteCd}
            >
            {wastedataList.map((wastedataList) => (
              <MenuItem
                key={wastedataList.wasteclassCd}
                value={wastedataList.wasteclassCd}
              >
                {wastedataList.wasteclassCd}:{wastedataList.wasteclassName}
              </MenuItem>
            ))}
            </Select>
            </td>

{/*}    </tr><tr>    */}
      <th>処分方法</th>
{/*}
    <td>
        <Select
          id='disposalMethodCd'
          label='処分方法'
          variant="outlined"
          type='text'
          name='disposalMethodCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.disposalMethodCd}
        >
          <MenuItem value={value.disposalMethodCd}>
            <em>処分方法</em>
          </MenuItem>
          <MenuItem value={'100'}>100:再生（※）</MenuItem>
          <MenuItem value={'101'}>101:再使用（リユース）</MenuItem>
          <MenuItem value={'102'}>102:素材再生</MenuItem>
          <MenuItem value={'103'}>103:多用途原材料化</MenuItem>
          <MenuItem value={'104'}>104:燃料化</MenuItem>
          <MenuItem value={'105'}>105:コンポスト化</MenuItem>
          <MenuItem value={'106'}>106:その他再生</MenuItem>
          <MenuItem value={'200'}>200:中間処理</MenuItem>
          <MenuItem value={'201'}>201:脱水</MenuItem>
          <MenuItem value={'202'}>202:機械乾燥</MenuItem>
          <MenuItem value={'203'}>203:天日乾燥</MenuItem>
          <MenuItem value={'204'}>204:焼却</MenuItem>
          <MenuItem value={'205'}>205:油水分解</MenuItem>
          <MenuItem value={'206'}>206:中和</MenuItem>
          <MenuItem value={'207'}>207:破砕</MenuItem>
          <MenuItem value={'208'}>208:圧縮</MenuItem>
          <MenuItem value={'209'}>209:溶融</MenuItem>
          <MenuItem value={'210'}>210:選別</MenuItem>
          <MenuItem value={'211'}>211:固形化</MenuItem>
          <MenuItem value={'212'}>212:ばい焼</MenuItem>
          <MenuItem value={'213'}>213:分解</MenuItem>
          <MenuItem value={'214'}>214:洗浄</MenuItem>
          <MenuItem value={'215'}>215:減菌</MenuItem>
          <MenuItem value={'216'}>216:消毒</MenuItem>
          <MenuItem value={'217'}>217:煮沸</MenuItem>
          <MenuItem value={'299'}>299:その他中間処理</MenuItem>
          <MenuItem value={'300'}>300:最終処分</MenuItem>
          <MenuItem value={'301'}>301:埋立処分</MenuItem>
          <MenuItem value={'302'}>302:安定型埋立処分</MenuItem>
          <MenuItem value={'303'}>303:管理型埋立処分</MenuItem>
          <MenuItem value={'304'}>304:遮断型埋立処分</MenuItem>
          <MenuItem value={'310'}>310:海洋投入</MenuItem>
          <MenuItem value={'900'}>900:その他（有価・買取）</MenuItem>
         </Select>
    </td>
            */}
    <td>
            <Select
              id='disposalMethodCd'
              label='処分方法'
              variant="outlined"
              type='text'
              name='disposalMethodCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.disposalMethodCd}
            >
            {disposaldataList.map((disposaldataList) => (
              <MenuItem
                key={disposaldataList.disposalMethodCd}
                value={disposaldataList.disposalMethodCd}
              >
                {disposaldataList.disposalMethodCd}:{disposaldataList.disposalMethodName}
              </MenuItem>
            ))}
            </Select>
            </td>

      {/*      <th>その他処分方法</th><td><TextField id='otherMethod' label='その他処分方法' variant="outlined" type='text' name='otherMethod' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.otherMethod} /></td> */}
{/*}      <th>登録事業者</th><td><TextField id='registTraderCd' label='登録事業者CD' variant="outlined" type='text' name='registTraderCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.registTraderCd} /></td>  */}
      </tr>
        <tr><th>単位</th>
{/*}
    <td>
        <Select
          id='unitCd'
          label='単位名'
          variant="outlined"
          type='text'
          name='unitCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.unitCd}
        >
      <MenuItem value={value.unitCd}>
      <em>単位名</em>
          </MenuItem>
          <MenuItem value={'1'}>1:トン</MenuItem>
          <MenuItem value={'2'}>2:立方メートル</MenuItem>
          <MenuItem value={'3'}>3:キログラム</MenuItem>
          <MenuItem value={'4'}>4:リットル</MenuItem>
          <MenuItem value={'5'}>5:個・台</MenuItem>
         </Select>
    </td>
            */}
    <td>
            <Select
              id='unitCd'
              label='単位'
              variant="outlined"
              type='text'
              name='unitCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.unitCd}
            >
            {unitdataList.map((unitdataList) => (
              <MenuItem
                key={unitdataList.unitCd}
                value={unitdataList.unitCd}
              >
                {unitdataList.unitCd}:{unitdataList.unitName}
              </MenuItem>
            ))}
            </Select>
            </td>

    <th>荷姿</th>
{/*}
            <td>
        <Select
          id='packingCd'
          label='荷姿名'
          variant="outlined"
          type='text'
          name='packingCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.packingCd}
        >
      <MenuItem value={value.packingCd}>
          <em>荷姿名</em>
          </MenuItem>
          <MenuItem value={'01'}>01:バラ（※）</MenuItem>
          <MenuItem value={'02'}>02:コンテナ</MenuItem>
          <MenuItem value={'03'}>03:ドラム缶</MenuItem>
          <MenuItem value={'04'}>04:石油缶</MenuItem>
          <MenuItem value={'05'}>05:プラスチック容器</MenuItem>
          <MenuItem value={'06'}>06:袋</MenuItem>
          <MenuItem value={'07'}>07:フレコンバック</MenuItem>
          <MenuItem value={'08'}>08:ダンボール箱</MenuItem>
          <MenuItem value={'09'}>09:その他</MenuItem>
          <MenuItem value={'10'}>10:ポリ容器</MenuItem>
          <MenuItem value={'11'}>11:ポリタンク</MenuItem>
         </Select>
    </td>
            */}
            <td>
            <Select
              id='packingCd'
              label='荷姿'
              variant="outlined"
              type='text'
              name='packingCd'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.packingCd}
            >
            {packingdataList.map((packingdataList) => (
              <MenuItem
                key={packingdataList.packingCd}
                value={packingdataList.packingCd}
              >
                {packingdataList.packingCd}:{packingdataList.packingName}
              </MenuItem>
            ))}
            </Select>
            </td>

            </tr>
    {/*    <tr><th>【不要】データシートコード</th><td><TextField id='datasheetCd' label='データシートコード' variant="outlined" type='text' name='datasheetCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.datasheetCd} /></td></tr>  */}
</table><table>
        <tr><th>区間１運搬</th>
            <td>
          {/*}    <TextField id='sect1TransportCd' label='区間１運搬事業場CD' variant="outlined" type='text' name='sect1TransportCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportCd} />   */}
{/*
              <TextField id='sect1TransportName' label='区間１運搬事業場名' variant="outlined" type='text' name='sect1TransportName' halfWidth margin="dense" value={value.sect1TransportName} />
*/}
              <Select
              id='sect1TransportId'
              label='区間１運搬事業場'
              variant="outlined"
              type='text'
              name='sect1TransportId'
              margin="dense"
//              onChange={(e) => handleChange(e)}
              value={value.sect1TransportId}
            >
{/*}            {linkagedataList01.map((linkagedataList01) => (    */}
{/*}            {traderseldataList01.map((traderseldataList01) => (  */}
            {traderDataList01.map((traderDataList01) => (
              <MenuItem
                key={traderDataList01.egsMTraderId1}
                value={traderDataList01.egsMTraderId1}
              >
                {traderDataList01.traderName1}
              </MenuItem>
            ))}
            </Select>
            </td>

<td>
              {/* <TextField id='sect1TransportChargeCd' label='区間１担当者CD' variant="outlined" type='text' name='sect1TransportChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportChargeCd} /> */}
            <Select
              id='sect1TransportChargeId'
              label='区間１運搬担当者'
              variant="outlined"
              type='text'
              name='sect1TransportChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect1TransportChargeId}
            >
            {chargedataList01.map((chargedataList01) => (
              <MenuItem
                key={chargedataList01.id}
                value={chargedataList01.id}
              >
                {chargedataList01.chargeCd}:{chargedataList01.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
      {/*
            <td><TextField id='sect1TransportMethodCd' label='区間１運搬方法CD' variant="outlined" type='text' name='sect1TransportMethodCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect1TransportMethodCd} /></td></tr>
      */}
{/*}
            <td>
        <Select
          id='sect1TransportMethodCd'
          label='運搬方法'
          variant="outlined"
          type='text'
          name='sect1TransportMethodCd'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.sect1TransportMethodCd}
        >
          <MenuItem value={value.sect1TransportMethodCd}>
          <em>運搬方法</em>
          </MenuItem>
          <MenuItem value={'1'}>車両:1</MenuItem>
          <MenuItem value={'2'}>船舶:2</MenuItem>
          <MenuItem value={'3'}>鉄道:3</MenuItem>
          <MenuItem value={'4'}>その他:4</MenuItem>
         </Select>
    </td>
    */}
    <td>
      <Select id='sect1TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect1TransportMethodCd' margin="dense"
      onChange={(e) => handleChange(e)}
       alue={value.sect1TransportMethodCd} >
        {trans1dataList.map((trans1dataList) => ( <MenuItem key={trans1dataList.transportMethodCd} value={trans1dataList.transportMethodCd} > {trans1dataList.transportMethodCd}:{trans1dataList.transportMethodName} </MenuItem> ))}
      </Select>
    </td>

       </tr>
        <tr><th>　運搬先</th>
        {/* <td><TextField id='destination1Cd' label='区間１運搬先事業所CD' variant="outlined" type='text' name='destination1Cd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination1Cd} /></td> */}

        <td>
        <Select
              id='destination1Id'
              label='区間１運搬先事業場'
              variant="outlined"
              type='text'
              name='destination1Id'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination1Id}
            >
            {traderSelList11.map((traderSelList11) => (
              <MenuItem
                key={traderSelList11.egsMTraderId2}
                value={traderSelList11.egsMTraderId2}
              >
                {traderSelList11.traderName2}  {/* {linkagedataList11.traderCd2}:{linkagedataList11.traderName2} */}
              </MenuItem>
            ))}
            </Select>
        </td><td>
            {/* <td><TextField id='destination1ChargeCd' label='区間１運搬先事業所担当者CD' variant="outlined" type='text' name='destination1ChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination1ChargeCd} /></td> */}
            <Select
              id='destination1ChargeId'
              label='区間１運搬先担当者'
              variant="outlined"
              type='text'
              name='destination1ChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination1ChargeId}
            >
            {chargedataList11.map((chargedataList11) => (
              <MenuItem
                key={chargedataList11.id}
                value={chargedataList11.id}
              >
                {chargedataList11.chargeCd}:{chargedataList11.chargeName}
              </MenuItem>
            ))}
            </Select>
        </td>
            <td><TextField id='sect1CarNo' label='区間１運搬車両番号' variant="outlined" type='text' name='sect1CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} /></td>
            </tr>
       </table>
       <table><tr>

</tr>
        <tr><th>区間２運搬</th>
            <td>
              <Select
              id='sect2TransportId'
              label='区間２運搬事業場'
              variant="outlined"
              type='text'
              name='sect2TransportId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect2TransportId}
            >
            {traderDataList02.map((traderDataList02) => (
              <MenuItem
                key={traderDataList02.egsMTraderId2}    // egsMTraderId1
                value={traderDataList02.egsMTraderId2}
              >
                {traderDataList02.traderName2}
              </MenuItem>
            ))}
            </Select>
            </td>

<td>
            <Select
              id='sect2TransportChargeId'
              label='区間２運搬担当者'
              variant="outlined"
              type='text'
              name='sect2TransportChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect2TransportChargeId}
            >
            {chargedataList02.map((chargedataList02) => (
              <MenuItem
                key={chargedataList02.id}
                value={chargedataList02.id}
              >
                {chargedataList02.chargeCd}:{chargedataList02.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
    <td>
      <Select id='sect2TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect2TransportMethodCd' margin="dense"
      onChange={(e) => handleChange(e)}
       alue={value.sect2TransportMethodCd} >
        {trans2dataList.map((trans2dataList) => ( <MenuItem key={trans2dataList.transportMethodCd} value={trans2dataList.transportMethodCd} > {trans2dataList.transportMethodCd}:{trans2dataList.transportMethodName} </MenuItem> ))}
      </Select>
    </td>

       </tr>
        <tr><th>　運搬先</th>
        {/* <td><TextField id='destination2Cd' label='区間２運搬先事業所CD' variant="outlined" type='text' name='destination2Cd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination2Cd} /></td> */}

        <td>
        <Select
              id='destination2Id'
              label='区間２運搬先事業場'
              variant="outlined"
              type='text'
              name='destination2Id'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination2Id}
            >
            {traderSelList12.map((traderSelList12) => (
              <MenuItem
                key={traderSelList12.egsMTraderId2}
                value={traderSelList12.egsMTraderId2}
              >
                {traderSelList12.traderName2}  {/* {linkagedataList12.traderCd2}:{linkagedataList12.traderName2} */}
              </MenuItem>
            ))}
            </Select>
        </td><td>
            {/* <td><TextField id='destination2ChargeCd' label='区間２運搬先事業所担当者CD' variant="outlined" type='text' name='destination2ChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination2ChargeCd} /></td> */}
            <Select
              id='destination2ChargeId'
              label='区間２運搬先担当者'
              variant="outlined"
              type='text'
              name='destination2ChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination2ChargeId}
            >
            {chargedataList12.map((chargedataList12) => (
              <MenuItem
                key={chargedataList12.id}
                value={chargedataList12.id}
              >
                {chargedataList12.chargeCd}:{chargedataList12.chargeName}
              </MenuItem>
            ))}
            </Select>
        </td>
            <td><TextField id='sect2CarNo' label='区間２運搬車両番号' variant="outlined" type='text' name='sect2CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect2CarNo} /></td>
            </tr>
       </table>
       <table><tr>



</tr>
        <tr><th>区間３運搬</th>
            <td>
              <Select
              id='sect3TransportId'
              label='区間３運搬事業場'
              variant="outlined"
              type='text'
              name='sect3TransportId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect3TransportId}
            >
            {traderDataList03.map((traderDataList03) => (
              <MenuItem
                key={traderDataList03.egsMTraderId2}
                value={traderDataList03.egsMTraderId2}
              >
                {traderDataList03.traderName2}
              </MenuItem>
            ))}
            </Select>
            </td>

<td>
            <Select
              id='sect3TransportChargeId'
              label='区間３運搬担当者'
              variant="outlined"
              type='text'
              name='sect3TransportChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect3TransportChargeId}
            >
            {chargedataList03.map((chargedataList03) => (
              <MenuItem
                key={chargedataList03.id}
                value={chargedataList03.id}
              >
                {chargedataList03.chargeCd}:{chargedataList03.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
    <td>
      <Select id='sect3TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect3TransportMethodCd' margin="dense"
      onChange={(e) => handleChange(e)}
       alue={value.sect3TransportMethodCd} >
        {trans3dataList.map((trans3dataList) => ( <MenuItem key={trans3dataList.transportMethodCd} value={trans3dataList.transportMethodCd} > {trans3dataList.transportMethodCd}:{trans3dataList.transportMethodName} </MenuItem> ))}
      </Select>
    </td>

       </tr>
        <tr><th>　運搬先</th>
        {/* <td><TextField id='destination3Cd' label='区間３運搬先事業所CD' variant="outlined" type='text' name='destination3Cd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination3Cd} /></td> */}

        <td>
        <Select
              id='destination3Id'
              label='区間３運搬先事業場'
              variant="outlined"
              type='text'
              name='destination3Id'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination3Id}
            >
            {traderSelList13.map((traderSelList13) => (
              <MenuItem
                key={traderSelList13.egsMTraderId2}
                value={traderSelList13.egsMTraderId2}
              >
                {traderSelList13.traderName2}  {/* {linkagedataList13.traderCd2}:{linkagedataList13.traderName2} */}
              </MenuItem>
            ))}
            </Select>
        </td><td>
            {/* <td><TextField id='destination3ChargeCd' label='区間３運搬先事業所担当者CD' variant="outlined" type='text' name='destination3ChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination3ChargeCd} /></td> */}
            <Select
              id='destination3ChargeId'
              label='区間３運搬先担当者'
              variant="outlined"
              type='text'
              name='destination3ChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination3ChargeId}
            >
            {chargedataList13.map((chargedataList13) => (
              <MenuItem
                key={chargedataList13.id}
                value={chargedataList13.id}
              >
                {chargedataList13.chargeCd}:{chargedataList13.chargeName}
              </MenuItem>
            ))}
            </Select>
        </td>
            <td><TextField id='sect3CarNo' label='区間３運搬車両番号' variant="outlined" type='text' name='sect3CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect3CarNo} /></td>
            </tr>
       </table>
       <table><tr>

</tr>
        <tr><th>区間４運搬</th>
            <td>
              <Select
              id='sect4TransportId'
              label='区間４運搬事業場'
              variant="outlined"
              type='text'
              name='sect4TransportId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect4TransportId}
            >
            {traderDataList04.map((traderDataList04) => (
              <MenuItem
                key={traderDataList04.egsMTraderId2}
                value={traderDataList04.egsMTraderId2}
              >
                {traderDataList04.traderName2}
              </MenuItem>
            ))}
            </Select>
            </td>

<td>
            <Select
              id='sect4TransportChargeId'
              label='区間４運搬担当者'
              variant="outlined"
              type='text'
              name='sect4TransportChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect4TransportChargeId}
            >
            {chargedataList04.map((chargedataList04) => (
              <MenuItem
                key={chargedataList04.id}
                value={chargedataList04.id}
              >
                {chargedataList04.chargeCd}:{chargedataList04.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
    <td>
      <Select id='sect4TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect4TransportMethodCd' margin="dense"
      onChange={(e) => handleChange(e)}
       alue={value.sect4TransportMethodCd} >
        {trans4dataList.map((trans4dataList) => ( <MenuItem key={trans4dataList.transportMethodCd} value={trans4dataList.transportMethodCd} > {trans4dataList.transportMethodCd}:{trans4dataList.transportMethodName} </MenuItem> ))}
      </Select>
    </td>

       </tr>
        <tr><th>　運搬先</th>
        {/* <td><TextField id='destination4Cd' label='区間４運搬先事業所CD' variant="outlined" type='text' name='destination4Cd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination4Cd} /></td> */}

        <td>
        <Select
              id='destination4Id'
              label='区間４運搬先事業場'
              variant="outlined"
              type='text'
              name='destination4Id'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination4Id}
            >
            {traderSelList14.map((traderSelList14) => (
              <MenuItem
                key={traderSelList14.egsMTraderId2}
                value={traderSelList14.egsMTraderId2}
              >
                {traderSelList14.traderName2}  {/* {linkagedataList14.traderCd2}:{linkagedataList14.traderName2} */}
              </MenuItem>
            ))}
            </Select>
        </td><td>
            {/* <td><TextField id='destination4ChargeCd' label='区間４運搬先事業所担当者CD' variant="outlined" type='text' name='destination4ChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination4ChargeCd} /></td> */}
            <Select
              id='destination4ChargeId'
              label='区間４運搬先担当者'
              variant="outlined"
              type='text'
              name='destination4ChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination4ChargeId}
            >
            {chargedataList14.map((chargedataList14) => (
              <MenuItem
                key={chargedataList14.id}
                value={chargedataList14.id}
              >
                {chargedataList14.chargeCd}:{chargedataList14.chargeName}
              </MenuItem>
            ))}
            </Select>
        </td>
            <td><TextField id='sect4CarNo' label='区間４運搬車両番号' variant="outlined" type='text' name='sect4CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect4CarNo} /></td>
            </tr>
       </table>
       <table><tr>

</tr>
        <tr><th>区間５運搬</th>
            <td>
              <Select
              id='sect5TransportId'
              label='区間５運搬事業場'
              variant="outlined"
              type='text'
              name='sect5TransportId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect5TransportId}
            >
            {traderDataList05.map((traderDataList05) => (
              <MenuItem
                key={traderDataList05.egsMTraderId2}
                value={traderDataList05.egsMTraderId2}
              >
                {traderDataList05.traderName2}
              </MenuItem>
            ))}
            </Select>
            </td>

<td>
            <Select
              id='sect5TransportChargeId'
              label='区間５運搬担当者'
              variant="outlined"
              type='text'
              name='sect5TransportChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.sect5TransportChargeId}
            >
            {chargedataList05.map((chargedataList05) => (
              <MenuItem
                key={chargedataList05.id}
                value={chargedataList05.id}
              >
                {chargedataList05.chargeCd}:{chargedataList05.chargeName}
              </MenuItem>
            ))}
            </Select>
            </td>
    <td>
      <Select id='sect5TransportMethodCd' label='運搬方法' variant="outlined" type='text' name='sect5TransportMethodCd' margin="dense"
      onChange={(e) => handleChange(e)}
       alue={value.sect5TransportMethodCd} >
        {trans5dataList.map((trans5dataList) => ( <MenuItem key={trans5dataList.transportMethodCd} value={trans5dataList.transportMethodCd} > {trans5dataList.transportMethodCd}:{trans5dataList.transportMethodName} </MenuItem> ))}
      </Select>
    </td>

       </tr>
        <tr><th>　運搬先</th>
        {/* <td><TextField id='destination5Cd' label='区間５運搬先事業所CD' variant="outlined" type='text' name='destination5Cd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination5Cd} /></td> */}

        <td>
        <Select
              id='destination5Id'
              label='区間５運搬先事業場'
              variant="outlined"
              type='text'
              name='destination5Id'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination5Id}
            >
            {traderSelList15.map((traderSelList15) => (
              <MenuItem
                key={traderSelList15.egsMTraderId2}
                value={traderSelList15.egsMTraderId2}
              >
                {traderSelList15.traderName2}  {/* {linkagedataList15.traderCd2}:{linkagedataList15.traderName2} */}
              </MenuItem>
            ))}
            </Select>
        </td><td>
            {/* <td><TextField id='destination5ChargeCd' label='区間５運搬先事業所担当者CD' variant="outlined" type='text' name='destination5ChargeCd' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.destination5ChargeCd} /></td> */}
            <Select
              id='destination5ChargeId'
              label='区間５運搬先担当者'
              variant="outlined"
              type='text'
              name='destination5ChargeId'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.destination5ChargeId}
            >
            {chargedataList15.map((chargedataList15) => (
              <MenuItem
                key={chargedataList15.id}
                value={chargedataList15.id}
              >
                {chargedataList15.chargeCd}:{chargedataList15.chargeName}
              </MenuItem>
            ))}
            </Select>
        </td>
            <td><TextField id='sect5CarNo' label='区間５運搬車両番号' variant="outlined" type='text' name='sect5CarNo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.sect5CarNo} /></td>
            </tr>
       </table>
       <table><tr>

         <th>備考</th>
         <td><TextField id='memo' label='備考' variant="outlined" type='text' name='memo' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo} /></td>
         <td><TextField id='memo1' label='備考1' variant="outlined" type='text' name='memo1' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} /></td>
         <td><TextField id='memo2' label='備考2' variant="outlined" type='text' name='memo2' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo2} /></td>
         </tr><tr><th></th><td><TextField id='memo3' label='備考3' variant="outlined" type='text' name='memo3' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo3} /></td>
         <td><TextField id='memo4' label='備考4' variant="outlined" type='text' name='memo4' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo4} /></td>
         <td><TextField id='memo5' label='備考5' variant="outlined" type='text' name='memo5' halfWidth margin="dense" onChange={(e) => handleChange(e)} value={value.memo5} /></td>
       </tr></table>
       <table>
       <tr>
       <th>有害物質</th>
       <td>
        <Select
          id='toxicCd01'
          label='有害物質1'
          variant="outlined"
          type='text'
          name='toxicCd01'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd01}
        >
          <MenuItem value={value.toxicCd01}>
          <em>有害物質1</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
    <td>
        <Select
          id='toxicCd02'
          label='有害物質2'
          variant="outlined"
          type='text'
          name='toxicCd02'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd02}
        >
          <MenuItem value={value.toxicCd02}>
          <em>有害物質2</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
    <td>
        <Select
          id='toxicCd03'
          label='有害物質3'
          variant="outlined"
          type='text'
          name='toxicCd03'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd03}
        >
          <MenuItem value={value.toxicCd03}>
          <em>有害物質3</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
    </tr><tr><th></th>
    <td>
        <Select
          id='toxicCd04'
          label='有害物質4'
          variant="outlined"
          type='text'
          name='toxicCd04'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd04}
        >
          <MenuItem value={value.toxicCd04}>
          <em>有害物質4</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
    <td>
        <Select
          id='toxicCd05'
          label='有害物質5'
          variant="outlined"
          type='text'
          name='toxicCd05'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd05}
        >
          <MenuItem value={value.toxicCd05}>
          <em>有害物質5</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
    <td>
        <Select
          id='toxicCd06'
          label='有害物質6'
          variant="outlined"
          type='text'
          name='toxicCd06'
          margin="dense"
          onChange={(e) => handleChange(e)}
          value={value.toxicCd06}
        >
          <MenuItem value={value.toxicCd06}>
          <em>有害物質6</em>
          </MenuItem>
          <MenuItem value={'02'}>02:カドミウム又はその化合物</MenuItem>
          <MenuItem value={'03'}>03:鉛又はその化合物</MenuItem>
          <MenuItem value={'04'}>04:有機燐化合物</MenuItem>
          <MenuItem value={'05'}>05:六価クロム化合物</MenuItem>
          <MenuItem value={'06'}>06:砒素又はその化合物</MenuItem>
          <MenuItem value={'07'}>07:シアン化合物</MenuItem>
          <MenuItem value={'08'}>08:PCB</MenuItem>
          <MenuItem value={'09'}>09:トリクロロエチレン</MenuItem>
          <MenuItem value={'10'}>10:テトラクロロエチレン</MenuItem>
          <MenuItem value={'11'}>11:ジクロロメタン</MenuItem>
          <MenuItem value={'12'}>12:四塩化炭素</MenuItem>
          <MenuItem value={'13'}>13:1・2-ジクロロエタン</MenuItem>
          <MenuItem value={'14'}>14:1・1-ジクロロエチレン</MenuItem>
          <MenuItem value={'15'}>15:シス-1・2-ジクロロエチレン</MenuItem>
          <MenuItem value={'16'}>16:1・1・1-トリクロロエタン</MenuItem>
          <MenuItem value={'17'}>17:1・1・2-トリクロロエタン</MenuItem>
          <MenuItem value={'18'}>18:1・3-ジクロロプロペン（D-D）</MenuItem>
          <MenuItem value={'19'}>19:チウラム</MenuItem>
          <MenuItem value={'20'}>20:シマジン（CAT）</MenuItem>
          <MenuItem value={'21'}>21:チオベンカルブ（ベンチオカーブ）</MenuItem>
          <MenuItem value={'22'}>22:ベンゼン</MenuItem>
          <MenuItem value={'23'}>23:セレン又はその化合物</MenuItem>
          <MenuItem value={'24'}>24:ダイオキシン類（有機塩素化合物）</MenuItem>
          <MenuItem value={'25'}>25:銅又はその化合物</MenuItem>
          <MenuItem value={'26'}>26:亜鉛又はその化合物</MenuItem>
          <MenuItem value={'27'}>27:弗化物</MenuItem>
          <MenuItem value={'28'}>28:ベリリウム又はその化合物</MenuItem>
          <MenuItem value={'29'}>29:クロム又はその化合物</MenuItem>
          <MenuItem value={'30'}>30:ニッケル又はその化合物</MenuItem>
          <MenuItem value={'31'}>31:バナジウム又はその化合物</MenuItem>
          <MenuItem value={'32'}>32:フェノール類</MenuItem>
         </Select>
    </td>
  </tr>
       </table>

       <Grid
          container
          spacing={0}
          className={classes.row}
        >
          <Grid item xs={2}>
            <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>
                有害物質
              </Typography>
            </Box>
          </Grid>
          <Box p={1} flex={1}>
            <Grid
              container
              spacing={1}
            >
              <Grid xs item>
                <Select fullWidth id='toxicCd01' label='有害物質１' variant="outlined" type='text' name='toxicCd01' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd01} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Select fullWidth id='toxicCd02' label='有害物質２' variant="outlined" type='text' name='toxicCd02' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd02} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Select fullWidth id='toxicCd03' label='有害物質３' variant="outlined" type='text' name='toxicCd03' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd03} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Select fullWidth id='toxicCd04' label='有害物質４' variant="outlined" type='text' name='toxicCd04' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd04} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Select fullWidth id='toxicCd05' label='有害物質５' variant="outlined" type='text' name='toxicCd05' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd05} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
              <Grid xs item>
                <Select fullWidth id='toxicCd06' label='有害物質６' variant="outlined" type='text' name='toxicCd06' margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.toxicCd06} >
                  {toxicdataList.map((toxicdataList) => (<MenuItem key={toxicdataList.toxicSubstanceCd} value={toxicdataList.toxicSubstanceCd} >
                    {toxicdataList.toxicSubstanceCd}:{toxicdataList.toxicSubstanceName} </MenuItem>))}
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </form>

      <Button
        type='submit'
        variant='contained'
        color='primary'
        onClick={(e) => handleSubmit(e)}
        style={{ marginRight: 10 }}
      >
        {buttonType}
      </Button>
      <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
    </>
  );
};
export default ProcessFlowAddForm;
