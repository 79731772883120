// /src/lib/api/receipt_load_adm_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptLoadAdmExhaustList = () => {
  return client.get('/receipt_load_adm_exhausts');
};

// 事業場選択一覧
export const getReceiptLoadAdmExhaustUserDetail = (id) => {
  return client.get(`/receipt_load_adm_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptLoadAdmExhaustUser = (id) => {
  return client.get(`/receipt_load_adm_exhausts/${id}/showuser`);
};


