// /src/lib/api/receipt_waste.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptWasteList = () => {
  return client.get('/receipt_wastes');
};

// 事業場選択一覧
export const getReceiptWasteUserDetail = (id) => {
  return client.get(`/receipt_wastes/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptWasteUser = (id) => {
  return client.get(`/receipt_wastes/${id}/showuser`);
};

// 事業場選択一覧  荷降用
export const getReceiptWasteUnload = (id) => {
  return client.get(`/receipt_wastes/${id}/showUnload`);
};

// 詳細
export const getReceiptWasteDetail = (id) => {
  return client.get(`/receipt_wastes/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptWaste = (params) => {
  return client.post('/receipt_wastes', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptWaste = (id, params) => {
  return client.patch(`/receipt_wastes/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptWaste = (id) => {
  return client.delete(`/receipt_wastes/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
