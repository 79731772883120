// /src/components/commons/ListTable.jsx
import React from 'react';
import { Link } from 'react-router-dom';
// style
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// functions
import { subString } from '../../functions/functions';

import '@grapecity/wijmo.styles/wijmo.css';
import './app.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
});

function ListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table' size="small">
          <TableHead>
            <TableRow>
              <TableCell align='center' className={classes.fontWeight}>
                名前
              </TableCell>
              <TableCell align='center' className={classes.fontWeight}>
                猫種
              </TableCell>
              <TableCell align='center' className={classes.fontWeight}>
                好きな食べ物
              </TableCell>
              <TableCell align='center' className={classes.fontWeight}>
                好きなおもちゃ
              </TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='center'></TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item, index) => (
              <TableRow key={index}>
                <TableCell align='center'>{subString(item.name, 15)}</TableCell>
                <TableCell align='center'>
                  {subString(item.nekoType, 15)}
                </TableCell>
                <TableCell align='center'>
                  {subString(item.detailInfo.favoriteFood, 10)}
                </TableCell>
                <TableCell align='center'>
                  {subString(item.detailInfo.favoriteToy, 10)}
                </TableCell>
                {currentUser.id === item.userId ? (
                  <TableCell align='center'>
                    <Link to={`/edit/${item.id}`}>更新</Link>
                  </TableCell>
                ) : (
                  <TableCell align='center'></TableCell>
                )}
                <TableCell align='center'>
                  <Link to={`/post/${item.id}`}>詳細へ</Link>
                </TableCell>
                {currentUser.id === item.userId ? (
                  <TableCell align='center'>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => handleDelete(item)}
                    >
                      削除
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell align='center'></TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FlexGrid showMarquee={true} selectionMode="MultiRange" autoGenerateColumns={false} itemsSource={dataList}>
        <FlexGridFilter />
        <FlexGridColumn binding="id" header="ID" width={50}></FlexGridColumn>
        <FlexGridColumn binding="name" header="名前" width={150}></FlexGridColumn>
        <FlexGridColumn binding="nekoType" header="猫種" width={200}></FlexGridColumn>
        <FlexGridColumn binding="detailInfo.favoriteFood" header="好きな食べ物" width={200}></FlexGridColumn>
        <FlexGridColumn binding="detailInfo.favoriteToy" header="好きなおもちゃ" width={200}></FlexGridColumn>
        <FlexGridColumn header="更新" binding="id" cellTemplate={CellMaker.makeLink({
          text: '<b>更新</b>',
          href: '/edit/${item.id}',
          attributes: {
            rel: 'noopener noreferrer',
            tabIndex: -1
          }
        })} />
        <FlexGridColumn header="詳細" binding="id" cellTemplate={CellMaker.makeLink({
          text: '<b>詳細</b>',
          href: '/post/${item.id}',
          attributes: {
            rel: 'noopener noreferrer',
            tabIndex: -1
          }
        })} />
        <FlexGridColumn header="削除" binding="id" cellTemplate={CellMaker.makeLink({
          click: (e, ctx) => alert('「' + ctx.item.id + '」リンクがクリックされました')
        })} />
      </FlexGrid>
    </>

  );
}
export default ListTable;
