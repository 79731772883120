import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { Dialog,
         DialogTitle,
         DialogContent,
         Backdrop,
         CircularProgress,
         makeStyles,
         Button,
         Typography,
         TextField,
         Paper,
         Slide } from '@material-ui/core';
import Draggable from "react-draggable";
import SpaceRow from '../commons/SpaceRow';
import { createSagyoReport } from '../../lib/api/sagyo_report';

const PaperComponent = ({...props}) => {
    const nodeRef = React.useRef(null);
  
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
        nodeRef={nodeRef}
      >
        <Paper ref={nodeRef} {...props} />
      </Draggable>
    );
  }

function MblSagyoReportAdd(props) {
    const { isOpen, doClose, onDataUpdated } = props;
    const [addData, setAddData] = useState([]);
    const [loading, setLoading] = useState(false);

    const currentDate = dayjs().format('YYYY-MM-DD');
    const currentTime = dayjs().format('HH:mm');

    const useStyles = makeStyles((theme) => ({
        backdrop: {
          zIndex: theme.zIndex.drawer + 1,
          color: '#fff',
        },
      }));
    
    const classes = useStyles();

    const handleFieldChange = (field, value) => {
        setAddData({ ...addData, [field]: value });
  };

    const handleCancel = () => {
        setLoading(false);
        doClose();
    };

    const handleSave = async () => {
        if (window.confirm('作業報告を登録します。よろしいですか？')) {
          setLoading(true);
          const params = generateParams();
          try {
            console.log('登録するデータ:', params);
            const res = await createSagyoReport(params);
            console.log("createSagyoReport_res",res);
            onDataUpdated(); // 親コンポーネントのコールバックを呼び出す
          } catch (e) {
            console.log(e);
            console.log(e.response);
          } 
          setLoading(false);
          doClose(); // ダイアログを閉じる
        }
    };

    const generateParams = () => {
      const mbluser = Cookies.get('mbl_user');
      console.log("mbluser",mbluser);
      const mbluser2 = mbluser.split(':');

      const params = {
          chargeId: mbluser2[0],
          sagyoDate: currentDate,
          sagyoTime: currentTime,
          sagyoPlace: addData.sagyoPlace,
          sagyoTask: addData.sagyoTask,
          sagyoMemo: addData.sagyoMemo,
      };
      return params;
    };

    return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者作業報告登録</h2>
      </DialogTitle>
        <DialogContent>
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <table style={{ fontSize: '1rem' }}>
            <tr>
                <th>日時</th>
                <td>
                  <Typography variant="h6" style={{ fontWeight: 700 }}>{currentDate}　{currentTime}</Typography>
                </td>
            </tr>
            <tr>
                <th>内容</th>
                <td>
                  <TextField
                    type="text"
                    onChange={(e) => handleFieldChange('sagyoTask', e.target.value)}
                    variant="outlined"
                    value={addData.sagyoTask}
                    size="small"
                    multiline
                    maxRows={1}
                    fullWidth
                    style={{ fontWeight: 700, fontSize: '1.2rem' }}
                  />
                </td>
            </tr>
            <tr>
                <th>場所</th>
                <td>
                  <TextField
                    type="text"
                    onChange={(e) => handleFieldChange('sagyoPlace', e.target.value)}
                    variant="outlined"
                    value={addData.sagyoPlace}
                    size="small"
                    multiline
                    maxRows={1}
                    fullWidth
                    style={{ fontWeight: 700, fontSize: '1.2rem' }}
                  />
                </td>
            </tr>
            <tr>
                <th>メモ</th>
                <td>
                  <TextField
                    type="text"
                    onChange={(e) => handleFieldChange('sagyoMemo', e.target.value)}
                    variant="outlined"
                    value={addData.sagyoMemo}
                    size="small"
                    multiline
                    maxRows={4}
                    fullWidth
                    style={{ fontWeight: 700, fontSize: '1.2rem' }}
                  />
                </td>
            </tr>
        </table>
        <SpaceRow height={20} />
        </DialogContent>
        <div style={{ textAlign: 'right' }}>
            <Button variant='contained' onClick={handleCancel} style={{ marginRight: 30 }}>
                戻る
            </Button>
            <Button variant='contained' color="primary" onClick={handleSave} style={{ marginRight: 30 }}>
                登録
            </Button>
        </div>
        <SpaceRow height={20} />
    </Dialog>

    );
}

export default MblSagyoReportAdd;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
