import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"

//import { updateEdiD02Item, getEdiD02ItemDetail } from '../../lib/api/edi_d02_item';
import { updateEgsTGarbageEdi, getEgsTGarbageEdiDetail } from '../../lib/api/egs_t_garbage_edi';
import FormBody from './EdiD02ItemForm';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';

function EdiD02ItemEdit() {
  const [value, setValue] = useState({
    garbageTag: '',
    toxicSubstanceNo: '',
    layoutNo: '',
    functionNo: '',
    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicCd07: '',
    toxicCd08: '',
    toxicCd09: '',
    toxicCd10: '',
    toxicCd11: '',
    toxicCd12: '',
    toxicCd13: '',
    toxicCd14: '',
    toxicCd15: '',
  });

  const query = useParams();

  const history = useHistory();

  useEffect(() => {
    handleGetData(query);
  }, [query]);


  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);




  const handleGetData = async (query) => {
    try {
     setOpen(!open);
      const res = await getEgsTGarbageEdiDetail(query.id);
      // console.log(res.data);
      setValue({
        garbageTag: res.data.garbageTag,
        toxicSubstanceNo: res.data.toxicSubstanceNo,
        layoutNo: 'D02',    // res.data.layoutNo,
        functionNo: res.data.functionNo,
        toxicCd01: res.data.toxicCd01,
        toxicCd02: res.data.toxicCd02,
        toxicCd03: res.data.toxicCd03,
        toxicCd04: res.data.toxicCd04,
        toxicCd05: res.data.toxicCd05,
        toxicCd06: res.data.toxicCd06,
        toxicCd07: res.data.toxicCd07,
        toxicCd08: res.data.toxicCd08,
        toxicCd09: res.data.toxicCd09,
        toxicCd10: res.data.toxicCd10,
        toxicCd11: res.data.toxicCd11,
        toxicCd12: res.data.toxicCd12,
        toxicCd13: res.data.toxicCd13,
        toxicCd14: res.data.toxicCd14,
        toxicCd15: res.data.toxicCd15,
        });
     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue',value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    try {
      const res = await updateEgsTGarbageEdi(query.id, params);
      // console.log(res);
      history.push('/garbage_edi');        // egs_m_reserve_sets
    } catch (e) {
      console.log(e);
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      garbageTag: value.garbageTag,
      toxicSubstanceNo: value.toxicSubstanceNo,
      layoutNo: value.layoutNo,
      functionNo: value.functionNo,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,
      toxicCd07: value.toxicCd07,
      toxicCd08: value.toxicCd08,
      toxicCd09: value.toxicCd09,
      toxicCd10: value.toxicCd10,
      toxicCd11: value.toxicCd11,
      toxicCd12: value.toxicCd12,
      toxicCd13: value.toxicCd13,
      toxicCd14: value.toxicCd14,
      toxicCd15: value.toxicCd15,
    };
    return params;
  };

/*    必要？-S

  // const qrCodeUrl = "https://www.sdgs12.com/rsv/" + value.reserveNo + "/" + value.reserveSubno ;
  const qrCodeUrl = "https://www.sdgs12.com/rsv/" + query.id ;
  // console.log('qrCodeUrl',qrCodeUrl);

      必要？-E
*/

  return (
    <>
      <h1>有害物質情報編集</h1>
      <FormBody
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        value={value}
        buttonType='更新' />

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
}
export default EdiD02ItemEdit;
/*    必要？

      <h2>ＱＲコード</h2>
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>

*/
