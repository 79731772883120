import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
// context
import { AuthContext } from '../../App';
// api
import { signIn } from '../../lib/api/auth';

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 100,  // 650
    },
    fontWeight: {
      fontWeight: 900,
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100ch',    // 150ch
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    container: {
      borderWidth: 1,
      borderColor: "#CCC",
      borderStyle: "solid",
      marginBottom: 8,
      width: "85vw",
      margin: "auto",
    },
    row: {
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: "solid",
      borderColor: "#CCC",
    },
    title_box: {
      background: "#EEE",
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: "#CCC",
      borderStyle: "solid",
      height: "100%",
    },
    value_box: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#CCC",
    },
  }));

function AdmSignInt(props) {
    const { traderCd, rawpasswd, buttonType } = props;
    // // console.log("traderCd",traderCd)
    // // console.log("rawpasswd",rawpasswd)
    const history = useHistory();
    const { setIsSignedIn, currentUser, setCurrentUser } = useContext(AuthContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const signInHandleSubmit = async (e) => {
      e.preventDefault();
      const sysuser = Cookies.get('sys_user');
      // console.log("sysuser1",sysuser);
      if (sysuser === undefined ){
        Cookies.set('sys_user', "test8");
      }
    //   // console.log("currentUser",currentUser)
    //   const sysuser = currentUser.traderCd + ":" + "password";


      const params = generateParams();
      try {
        setOpen(!open);
        const res = await signIn(params);
        if (res.status === 200) {
          Cookies.set('_access_token', res.headers['access-token']);
          Cookies.set('_client', res.headers['client']);
          Cookies.set('_uid', res.headers['uid']);
          setIsSignedIn(true);
          setCurrentUser(res.data.data);
          setOpen(false);
        }
      } catch (e) {
        console.log(e);
        console.error("エラー：", e.message);
        // console.log("エラー：", e.name);
        // history.push('/');
        if (e.name != "TypeError" ) {
          alert('事業者IDまたはパスワードに誤りがあります')
        }
      }
        history.push('/');
        window.location.reload();
    };

    const generateParams = () => {
      const signInParams = {
        email: traderCd + "@sdgs12.com",  // ログイン時、@sdgs12.com 入力を不要とする処理
        password: rawpasswd,
      };
      return signInParams;
    };

    return(
      <>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={(e) => { signInHandleSubmit(e)}} disabled={!traderCd} >
          {buttonType}
        </Button>
        <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
  const styles = {
    grid: {
      '.MuiDataGrid-columnHeaders': {
        backgroundColor: '#65b2c6',
        color: '#fff',
      }
    }
  }
  export default AdmSignInt;
