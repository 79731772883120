import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button, TextField, Typography} from '@material-ui/core';

import { createEgsMCharge, getEgsMChargeDetail } from '../../lib/api/egs_m_charge';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
//import { getTraderAdmList, getTraderAdmUser } from '../../lib/api/trader_adm';
//import FormBody from './EgsMChargeForm';
import FormBody from './EgsMChargeAddForm';  // フォーム分割
import { getCurrentUser } from '../../lib/api/auth';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMChargeAdd(props) {  // processId
  const { isOpen,doClose,dataList2 } = props;

//  const currentUser = getCurrentUser();

  let wkTraderCd;
  let wkTraderName;

//  if (currentUser.traderKbn == '1111111111') {
  // if (currentUser.traderKbn == '1111111111' || currentUser.traderKbn == '0000000001') {
  //   wkTraderCd = ''
  //   wkTraderName = ''
  // }else{
  //   wkTraderCd = currentUser.traderCd
  //   wkTraderName = currentUser.nickname
  // }
  //  wkTraderCd = dataList2[0].traderCd;
  //  wkTraderName = dataList2[0].traderName;

  const [dataListTrader,setDataListTrader] = useState([]);  {/* 事業場 */}
  const [errorChargeCd, setErrorChargeCd] = useState(false);  // 担当者CD
  const [errorChargeName, setErrorChargeName] = useState(false);  // 担当者名
  const [errorChargeKana, setErrorChargeKana] = useState(false);  // ふりがな
  const [errorEmail, setErrorEmail] = useState(false);  // メールアドレス

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    egsMTraderId: 0,
    traderCd: '',
    traderName: '',
    chargeCd: '',
    chargeName: '',
    chargeKana: '',
    email: '',
    emailFlg: '0',
    ediKbn: 0,
    effectiveflg: '1',  //  新規作成時は有効とする　  '0',
  });
  //  console.log('dataList2', dataList2);

  useEffect(() => {
  if (dataList2 != undefined)
  {
     setValue({
      traderCd: dataList2.traderCd2,
      traderName: dataList2.traderName2,
      // chargeCd: res.data.chargeCd,
      // chargeName: res.data.chargeName,
      // chargeKana: res.data.chargeKana,
      // email: res.data.email,
      // emailFlg: res.data.emailFlg,
      // ediKbn: res.data.ediKbn,
      // effectiveflg: res.data.effectiveflg,
    });
  }
  }, []);

/*
  useEffect(() => {
    traderGetList(value.traderCd);
  }, [value.traderCd]);
*/

  // const useStyles = makeStyles((theme) => ({
  //   backdrop: {
  //     zIndex: theme.zIndex.drawer + 1,
  //     color: '#fff',
  //   },
  // }));
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100ch',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
/*
  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMChargeDetail(processId);
      // console.log(res.data);
      setValue({
        egsMTraderId: res.data.egsMTraderId,
        traderCd: res.data.traderCd,
        // chargeCd: res.data.chargeCd,
        // chargeName: res.data.chargeName,
        // chargeKana: res.data.chargeKana,
        // email: res.data.email,
        // emailFlg: res.data.emailFlg,
        // ediKbn: res.data.ediKbn,
        // effectiveflg: res.data.effectiveflg,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
*/
/*
  const traderGetList = async () => {
    try {
      setLoading(true);
      const resT02 = await getTraderUser(value.traderCd);
      // console.log(resT02.data);
      setValue({
        egsMTraderId: resT02.data[0].id,
        traderCd: resT02.data[0].traderCd,
        traderName: resT02.data[0].traderName,
//        chargeCd: resT02.data[0].chargeCdMax,
        chargeCd: resT02.data[0].nextChargeCd,
        chargeName: value.chargeName,
        chargeKana: value.chargeKana,
        email: value.email,
        emailFlg: value.emailFlg,
        ediKbn: value.ediKbn,
        effectiveflg: value.effectiveflg,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
*/

    const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

   const handleSubmit = async (e) => {
     if (window.confirm(dataList2.traderName2 + ' に 担当者「' + value.chargeCd + ':' + value.chargeName + '」を登録します。よろしいですか？')) {
      try {
        e.preventDefault();
        // 追加
        const params = generateParams();
        console.log('params',params)
        try {
          const res = await createEgsMCharge(params);
          // console.log(res);
          handleCancel();
    //      history.push('/permit');  // egs_m_permits
          window.location.reload();
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
      } catch (e) {
        console.log(e.response);
      }
     }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      egsMTraderId: dataList2.egsMTraderId2,
      traderCd: dataList2.traderCd2,
      chargeCd: value.chargeCd,
      chargeName: value.chargeName,
      chargeKana: value.chargeKana,
      email: value.email,
      emailFlg: '1',
      ediKbn: value.ediKbn,
      effectiveflg: '1',
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者新規作成</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
            onClick={e => {
              e.preventDefault();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <div style={{ textAlign: 'left' }}>
        <form className={classes.root} noValidate autoComplete='off'>
          <table>
            <tr><th>事業場CD</th>
              <td>
                <Typography variant="h6" style={{ marginTop: 10 }}>{dataList2.traderCd2}</Typography>
              </td>
            </tr>
            <tr><th>事業場名称</th>
              <td>
                <Typography variant="h6" style={{ marginTop: 10 }}>{dataList2.traderName2}</Typography>
              </td>
            </tr>
            <tr><th>担当者CD *</th>
              <td>
                <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeCd}
                  value={value.chargeCd}
                  inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
                />
              </td>
            </tr>
            <tr><th>担当者名 *</th>
              <td>
                <TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeName}
                  value={value.chargeName}
                />
              </td>
            </tr>
            <tr><th>ふりがな *</th>
              <td>
                <TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeKana}
                  value={value.chargeKana}
                />
              </td>
            </tr>
            <tr><th>メールアドレス</th>
              <td>
                <TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorEmail}
                  value={value.email}
                  inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
                />
              </td>
            </tr>
            {/* <tr><th>事業場CD</th>
              <td>
                <Typography variant="h7" style={{ marginTop: 10 }}>{dataList2[0].traderCd}</Typography>
              </td>
            </tr>
            <tr><th>事業場名称</th>
              <td>
                <Typography variant="h7" style={{ marginTop: 10 }}>{dataList2[0].traderName}</Typography>
              </td>
            </tr>
            <tr><th>担当者CD *</th>
              <td>
                <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeCd}
                  value={value.chargeCd}
                  inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
                />
              </td>
            </tr>
            <tr><th>担当者名 *</th>
              <td>
                <TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeName}
                  value={value.chargeName}
                />
              </td>
            </tr>
            <tr><th>ふりがな *</th>
              <td>
                <TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorChargeKana}
                  value={value.chargeKana}
                />
              </td>
            </tr>
            <tr><th>メールアドレス</th>
              <td>
                <TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
                  onChange={(e) => handleChange(e)}
                  error={errorEmail}
                  value={value.email}
                  inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
                />
              </td>
            </tr> */}
          </table>
        </form>
        </div>
        <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
                handleSubmit(e);
            }}
          style={{ marginRight: 10 }}
        >
          作成
        </Button>
      </div>

        {/* <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          dataList2={dataList2}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='作成' /> */}


        {/* <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop> */}
      </DialogContent>
    </Dialog>
  );
}
export default EgsMChargeAdd;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
