import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button, Grid, Box, Typography } from '@material-ui/core';
import QRCode from "qrcode.react";

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function ExtChargeQrCre(props) {
  const { isOpen, doClose, dataList2 } = props;

  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dialogCustomizedWidth: {
      'width': '50%',
      'maxWidth': 'none',
    }
  }));

  const classes = useStyles();
  const history = useHistory();

  const qrCodeUrl = "https://www.sdgs12.com/" + dataList2.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.chargeCd;
  const traderChargeCd = dataList2.traderCd + "/" + dataList2.chargeCd;
  const qrCodeUrlPlus = qrCodeUrl + "/" + dataList2.exhaustPasswd;

  const handleCancel = () => {
    doClose();
  };

  const printStyles = `
    @media print {
      @page {
        size: A4; /* ページサイズをA4に設定 */
        margin: 0; /* 余白を0に設定 */
      }
      .noPrint {
        display: none;
      }
      .print {
        display: block;
      }
      .MuiDialog-paper {
        border: 2px solid black;
        padding: 10px;
      }
      .qrCodeContainer {
        text-align: right;
      }
    }
  `;

  const handlePrint = () => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = printStyles;
    document.head.appendChild(styleSheet);

    window.print();

    document.head.removeChild(styleSheet);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={doClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      PaperProps={{
        style: {
          maxWidth: '794px',
          width: '100%',
          border: '2px solid black',
          padding: '10px',
        },
      }}
      PaperComponent={PaperComponent} // ダイアログをドラッグ可能にする
    >
      <DialogTitle id="form-dialog-title" style={{ textAlign: 'center' }}>
        <h2>{dataList2.traderName}</h2>
        <Typography variant="h5" gutterBottom style={{ textAlign: 'center', margin: 0 }}>
          排出担当者：{dataList2.chargeName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} className={classes.row}>
          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <QRCode value={qrCodeUrlPlus} />
              <Typography variant="caption" style={{ marginTop: '8px', fontSize: '1.5em' }}>{traderChargeCd}</Typography>            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ textAlign: 'right', margin: '8px' }}>
        <Button variant="contained" color="primary" onClick={handlePrint} className="noPrint">
          印刷（A4）
        </Button>
        <Button variant='contained'
          onClick={e => {
            e.preventDefault();
            handleCancel();
          }}
          style={{ marginLeft: '8px' }}
          className="noPrint"
        >
          戻る
        </Button>
      </div>
    </Dialog>
  );
}export default ExtChargeQrCre;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
