// /src/lib/api/charge_linkage.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getChargeLinkageList = () => {
  return client.get('/charge_linkages');
};

// ユーザ一覧
export const getChargeLinkageUser = (id) => {
  return client.get(`/charge_linkages/${id}/showuser`);
};

// 詳細
export const getChargeLinkageDetail = (id) => {
  return client.get(`/charge_linkages/${id}`);
};

// 運用中廃棄物 idは'all'
export const getChargeLinkageOpe = (id) => {
  return client.get(`/charge_linkages/${id}/showope`);
};
