// /src/lib/api/company.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getCompanyList = () => {
  return client.get('/companies');
};

// 詳細
export const getCompanyDetail = (id) => {
  return client.get(`/companies/${id}/show`);
};

// 事業所指定一覧 idは事業所CD
export const getCompanyUser = (id) => {
  return client.get(`/companies/${id}/showuser`);
};

// 事業所指定一覧 idはグループ１と２
export const getCompanyGroup = (id) => {
  return client.get(`/companies/${id}/showgroup`);
};

// 検索条件 idは'all'
export const getCompanyOperation = (id) => {
  return client.get(`/companies/${id}/showope`);
};

// 新規作成
// header情報を追加
export const createCompany = (params) => {
  return client.post('/companies', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateCompany = (id, params) => {
  return client.patch(`/companies/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteCompany = (id) => {
  return client.delete(`/companies/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
