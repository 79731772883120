// /src/lib/api/egs_m_trader.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsMTraderList = () => {
  return client.get('/egs_m_traders');
};

// 事業所指定一覧 idは事業所CD
export const getEgsMTraderUser = (id) => {
  return client.get(`/egs_m_traders/${id}/showuser`);
};

// phone指定一覧 idはphone番号（ハイフンなし）
export const getEgsMTraderPhone = (id) => {
  return client.get(`/egs_m_traders/${id}/showphone`);
};

// 詳細
export const getEgsMTraderDetail = (id) => {
  return client.get(`/egs_m_traders/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMTrader = (params) => {
  return client.post('/egs_m_traders', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMTrader = (id, params) => {
  return client.patch(`/egs_m_traders/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
// export const updatePassEgsMTrader = (id, params) => {
//   return client.patch(`/egs_m_traders/${id}/userpassupdate`,params, {
//     headers: {
//       'access-token': Cookies.get('_access_token'),
//       client: Cookies.get('_client'),
//       uid: Cookies.get('_uid'),
//     },
//   });
// };
export const updatePassEgsMTrader = (id) => {
  return client.patch(`/egs_m_traders/${id}/userpassupdate`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMTrader = (id) => {
  return client.delete(`/egs_m_traders/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
