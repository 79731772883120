// /src/lib/api/egs_m_permit_municipality.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMPermitMunicipalityList = () => {
  return client.get('/egs_m_permit_municipalities');
};


// 詳細（詳細表示用）
export const getEgsMPermitMunicipalityDetail = (id) => {
  return client.get(`/egs_m_permit_municipalities/${id}`);    // permit_municipalities
};


// 新規作成
// header情報を追加
export const createEgsMPermitMunicipality = (params) => {
  return client.post('/egs_m_permit_municipalities', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMPermitMunicipality = (id, params) => {
  return client.patch(`/egs_m_permit_municipalities/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMPermitMunicipality = (id) => {
  return client.delete(`/egs_m_permit_municipalities/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
