// /src/lib/api/receipt_load_waste.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptLoadWasteList = () => {
  return client.get('/receipt_load_wastes');
};

// 事業場選択一覧
export const getReceiptLoadWasteUserDetail = (id) => {
  return client.get(`/receipt_load_wastes/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptLoadWasteUser = (id) => {
  return client.get(`/receipt_load_wastes/${id}/showuser`);
};

// 詳細
export const getReceiptLoadWasteDetail = (id) => {
  return client.get(`/receipt_load_wastes/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptLoadWaste = (params) => {
  return client.post('/receipt_load_wastes', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptLoadWaste = (id, params) => {
  return client.patch(`/receipt_load_wastes/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptLoadWaste = (id) => {
  return client.delete(`/receipt_load_wastes/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
