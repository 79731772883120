// /src/lib/api/egs_t_garbage_pln.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsTGarbagePlnList = () => {
  return client.get('/egs_t_garbage_plns');
};

// 収集運搬事業者指定一覧 idは事業所CD
export const getEgsTGarbagePlnUser = (id) => {
  return client.get(`/egs_t_garbage_plns/${id}/showuser`);
};
// 排出事業者指定一覧 idは事業所CD
export const getEgsTGarbagePlnExhaust = (id) => {
  return client.get(`/egs_t_garbage_plns/${id}/showexhaust`);
};
// 作成済みチェック idはgarbage_tag
export const getEgsTGarbagePlnGbt = (id) => {
  return client.get(`/egs_t_garbage_plns/${id}/showgbt`);
};

// 新規作成
// header情報を追加
export const createEgsTGarbagePln = (params) => {
  return client.post('/egs_t_garbage_plns', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTGarbagePln = (id, params) => {
  return client.patch(`/egs_t_garbage_plns/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTGarbagePln = (id) => {
  return client.delete(`/egs_t_garbage_plns/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

export const creProcessFlowtogbtpln = (id) => {
  return client.get(`/egs_t_garbage_plns/${id}/create_from_process_flow`);
};

export const PlncopyTmpanddelPln = (id) => {
  return client.get(`/egs_t_garbage_plns/${id}/copy_and_delete`);
};
