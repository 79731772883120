import { LicenseInfo } from '@mui/x-data-grid-pro'
// App.jsx
import React, { useState, useEffect, createContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Cookies from 'js-cookie';

// component
import Home from './components/dashboard/Home';

import List from './components/posts/List';
import New from './components/posts/New';
import Detail from './components/posts/Detail';
import Edit from './components/posts/Edit';

import EgsMTraderList from './components/egs_m_traders/EgsMTraderList';
import EgsMTraderNew from './components/egs_m_traders/EgsMTraderNew';
import EgsMTraderEdit from './components/egs_m_traders/EgsMTraderEdit';
// import EgsMTraderSel from './components/egs_m_traders/EgsMTraderSel';
// import TraderCompanySel from './components/egs_m_traders/TraderCompanySel';
import EgsMTraderAdd from './components/egs_m_traders/EgsMTraderAdd';

import EgsMChargeList from './components/egs_m_charges/EgsMChargeList';
//import EgsMChargeNew from './components/egs_m_charges/EgsMChargeNew';
import EgsMChargeEdit from './components/egs_m_charges/EgsMChargeEdit';
//import ChargeTraderSelect from './components/egs_m_charges/ChargeTraderSelect';
//import EgsMChargeAdd from './components/egs_m_charges/EgsMChargeAdd';
import SagyoReportList from './components/sagyo_report/SagyoReportList';
import InvoiceDataList from './components/invoice_data/InvoiceDataList';

import EgsMEntryList from './components/egs_m_entries/EgsMEntryList';  // 20240430
import EgsMEntryAdmin from './components/egs_m_entries/EgsMEntryAdmin';  // 20240430

import EgsMCompanyList from './components/egs_m_companies/EgsMCompanyList';
//import CompanyList from './components/egs_m_companies/CompanyList';
//import EgsMCompanyNew from './components/egs_m_companies/EgsMCompanyNew';
//import EgsMCompanyEdit from './components/egs_m_companies/EgsMCompanyEdit';
//import CompanySelect from './components/select_companies/CompanySelect';

//import EgsMHandlingList from './components/egs_m_handlings/EgsMHandlingList';
//import EgsMHandlingNew from './components/egs_m_handlings/EgsMHandlingNew';
//import EgsMHandlingEdit from './components/egs_m_handlings/EgsMHandlingEdit';

//import ReserveSetList from './components/egs_m_reserve_sets/ReserveSetList';
//import EgsMReserveSetNew from './components/egs_m_reserve_sets/EgsMReserveSetNew';
//import EgsMReserveSetEdit from './components/egs_m_reserve_sets/EgsMReserveSetEdit';
//import ReserveDetail from './components/egs_m_reserve_sets/ReserveDetail';

import ApproveList from './components/approves/ApproveList';
//import ApproveEdit from './components/approves/ApproveEdit';    // 0518
import TransList from './components/trans_list/TransList';
import GarbageCntList from './components/garbage_cnt/GarbageCntList';  // 20240724

import DemandBList from './components/demand_b/DemandBList';      // 2023/12/26


import PermitList from './components/permit/PermitList';
import PermitNew from './components/permit/PermitNew';
import PermitEdit from './components/permit/PermitEdit';

//import InvoiceList from './components/invoice/InvoiceList';
import ReceiptList from './components/receipt/ReceiptList';
import ReceiptLoadList from './components/receipt/ReceiptLoadList';
//import DisposalList from './components/disposal/DisposalList';
import DisposalList from './components/receipt/DisposalList';
import ReceiptUnloadList from './components/receipt/ReceiptUnloadList';

//import ExhaustPlanList from './components/exhaust_plan/ExhaustPlanList';
//import ExhaustPlanNew from './components/egs_m_exhaust_plan/ExhaustPlanNew';
//import ExhaustPlanEdit from './components/exhaust_plan/ExhaustPlanEdit';
//import ExhaustPlanAdd from './components/select_reserve_sets/ExhaustPlanAdd';
//import ExhaustPlanAdd from './components/exhaust_plan/ExhaustPlanAdd';
//import ReserveSetSel from './components/exhaust_plan/ReserveSetSel';
//import TraderUpd from './components/exhaust_plan/TraderUpd';
//import ChargeUpd from './components/exhaust_plan/ChargeUpd';
//import SectUpd from './components/exhaust_plan/SectUpd';
//import ToxicSel from './components/exhaust_plan/ToxicSel';

//import EgsTGarbageTagList from './components/garbage_info/EgsTGarbageTagList';
//import GarbageTagList from './components/garbage_info/GarbageTagList';
//import EgsTGarbageTagNew from './components/garbage_info/EgsTGarbageTagNew';
//import EgsTGarbageTagEdit from './components/garbage_info/EgsTGarbageTagEdit';
//import GarbageTagPdf from './components/garbage_info/GarbageTagPdf';
//import GarbageSelList from './components/garbage_info/GarbageSelList';
//import ReserveSetSelect from './components/garbage_info/ReserveSetSelect';
//import EgsTGarbageTagAdd from './components/garbage_info/EgsTGarbageTagAdd';      // 処理フローから廃棄物を追加編集

// import DsReserveSetEdit from './components/ds_reserve_sets/DsReserveSetEdit';
import MblEmissionSetEdit from './components/mbl_webapp/MblEmissionSetEdit'; // 排出登録（モバイル）add 20221108
import MblEmissionUpdate from './components/mbl_webapp/MblEmissionUpdate';  // 排出登録（モバイル）add 20230920
import MblProcessFlow from './components/mbl_webapp/MblProcessFlow';         // 処理フロー（モバイル）add 20221215
import MblReceiptUnload1 from './components/mbl_webapp/MblReceiptUnload1';   // 荷降登録区間１（モバイル）add 20221215
import MblReceiptUnload2 from './components/mbl_webapp/MblReceiptUnload2';   // 荷降登録区間２（モバイル）add 20230514
import MblReceiptLoad from './components/mbl_webapp/MblReceiptLoad';         // 積保積込（モバイル）add 20230318
import MblUserSet from './components/mbl_webapp/MblUserSet';                 // 担当者登録（モバイル）add 20221220
import MblGarbageTmpList from './components/mbl_webapp/MblGarbageTmpList';           // 排出登録用データ一覧　add 20230304
import MblSagyoReport from './components/mbl_webapp/MblSagyoReport';   // 担当者作業報告　add 20240512
//import DsEgsTGarbageTagEdit from './components/ds_garbage_info/DsEgsTGarbageTagEdit';
//import DsEgsTGarbagePhoto from './components/ds_garbage_info/DsEgsTGarbagePhoto'; // 廃棄物写真表示　add 20220522

//import DatasheetList from './components/datasheets/DatasheetList';
//import EgsMDatasheetNew from './components/datasheets/EgsMDatasheetNew';
//import EgsMDatasheetEdit from './components/datasheets/EgsMDatasheetEdit';
//import DatasheetSel from './components/datasheets/DatasheetSel';

//import EgsMToxicSubstanceList from './components/egs_m_toxic_substances/EgsMToxicSubstanceList';
//import EgsMToxicSubstanceNew from './components/egs_m_toxic_substances/EgsMToxicSubstanceNew';
//import EgsMToxicSubstanceEdit from './components/egs_m_toxic_substances/EgsMToxicSubstanceEdit';

//import Drilldown from './components/drilldown/DrillDown';
import GarbageStatuList from './components/garbage_status/GarbageStatuList';
//import GarbageStatuAdm from './components/garbage_status/GarbageStatuAdm';
//import GarbageTagDetailPdf from './components/garbage_pdf/GarbageTagDetailPdf';
//import ReserveSetDetailPdf from './components/garbage_pdf/ReserveSetDetailPdf';
//import InvoiceDetailPdf from './components/garbage_pdf/InvoiceDetailPdf';
import ExhaustSum from './components/exhaust_sum/ExhaustSum';
//import GarbageSecondSel from './components/select_reserve_sets/GarbageSecondSel';

import LinkageList from './components/egs_m_linkage/LinkageList';
//import EgsMLinkageNew from './components/egs_m_linkage/EgsMLinkageNew';
import EgsMLinkageEdit from './components/egs_m_linkage/EgsMLinkageEdit';
//import TraderSelect from  './components/egs_m_linkage/TraderSelect';

import ProcessFlowList from './components/egs_m_process_flows/ProcessFlowList';
import EgsMProcessFlowNew from './components/egs_m_process_flows/EgsMProcessFlowNew';
import EgsMProcessFlowEdit from './components/egs_m_process_flows/EgsMProcessFlowEdit';
import LinkageSel from  './components/egs_m_process_flows/LinkageSel';

import ProcessFlowSel from './components/garbage_edi/ProcessFlowSel';
import ProcessFlowAdd from  './components/egs_m_process_flows/ProcessFlowAdd';

import EdiD01List     from './components/garbage_edi/GarbageEdiList';
import GarbageTmpList from './components/garbage_tmp/GarbageTmpList';
import GarbageCsvList from './components/garbage_csv/GarbageCsvList';
import GarbageOldList from './components/garbage_old/GarbageOldList';
import GarbageOldCsvList     from './components/garbage_old_csv/GarbageOldCsvList';

import FirstSelList     from './components/garbage_edi/FirstSelList';                   // 0527
import FirstSelEdit     from './components/garbage_edi/FirstSelEdit';                   // 0601
import EdiD01ItemAdd  from './components/garbage_edi/EdiD01ItemAdd';
import EdiD01ItemEdit from './components/garbage_edi/EdiD01ItemEdit';
import EdiD02ItemEdit from './components/garbage_edi/EdiD02ItemEdit';
//import EdiD03ItemEdit from './components/garbage_edi/EdiD03ItemEdit';
//import EdiD06ItemEdit from './components/garbage_edi/EdiD06ItemEdit';

//import GarbageDetailList from './components/garbage_detail/GarbageDetailList';

//**************************管理ユーザ-S********************************************************
// import EgsMTraderAdmList from './components/egs_m_traders_adm/EgsMTraderAdmList';
// import EgsMTraderAdmNew from './components/egs_m_traders_adm/EgsMTraderAdmNew';
// import EgsMTraderAdmEdit from './components/egs_m_traders_adm/EgsMTraderAdmEdit';
// //import EgsMTraderSel from './components/egs_m_traders_adm/EgsMTraderAdmSel';
// import TraderAdmCompanySel from './components/egs_m_traders_adm/TraderAdmCompanySel';
// import EgsMTraderAdmAdd from './components/egs_m_traders_adm/EgsMTraderAdmAdd';

// import EgsMChargeAdmList from './components/egs_m_charges_adm/EgsMChargeAdmList';
// import EgsMChargeAdmNew from './components/egs_m_charges_adm/EgsMChargeAdmNew';
// import EgsMChargeAdmEdit from './components/egs_m_charges_adm/EgsMChargeAdmEdit';
// import ChargeAdmTraderSelect from './components/egs_m_charges_adm/ChargeAdmTraderSelect';
// import EgsMChargeAdmAdd from './components/egs_m_charges_adm/EgsMChargeAdmAdd';

//import AdmProcessFlowSel from './components/garbage_edi_admin/ProcessFlowSel';

//import AdmEdiD01List     from './components/garbage_edi_admin/GarbageEdiList';
//import AdmFirstSelList     from './components/garbage_edi_admin/FirstSelList';                   // 0527
//import AdmFirstSelEdit     from './components/garbage_edi_admin/FirstSelEdit';                   // 0601
//import AdmEdiD01ItemAdd  from './components/garbage_edi_admin/EdiD01ItemAdd';
//import AdmEdiD01ItemEdit from './components/garbage_edi_admin/EdiD01ItemEdit';
//import AdmEdiD02ItemEdit from './components/garbage_edi_admin/EdiD02ItemEdit';
//import AdmEdiD03ItemEdit from './components/garbage_edi_admin/EdiD03ItemEdit';
//import AdmEdiD06ItemEdit from './components/garbage_edi_admin/EdiD06ItemEdit';

// import AdmProcessFlowSel from './components/garbage_edi_admin/ProcessFlowSel';
// import AdmEdiD01List     from './components/garbage_edi_admin/GarbageEdiList';
// import AdmEdiD01ItemAdd  from './components/garbage_edi_admin/EdiD01ItemAdd';
// import AdmEdiD01ItemEdit from './components/garbage_edi_admin/EdiD01ItemEdit';              // egs_m_reserve_sets
// import AdmEdiD02ItemEdit from './components/garbage_edi_admin/EdiD02ItemEdit';
//import AdmEdiD03ItemEdit from './components/garbage_edi_admin/EdiD03ItemEdit';
//import AdmEdiD06ItemEdit from './components/garbage_edi_admin/EdiD06ItemEdit';

//**************************管理ユーザ-E********************************************************

//import Edi3000R24List from './components/edi3000_r24/Edi3000R24List';      // 0617

import AdmEdiInfoList from './components/adm_edi_info/AdmEdiInfoList';      // 0618

import Header from './components/commons/Header';
// import EgsQrReader from './components/commons/EgsQrReader';
import WebCam from './components/commons/WebCam';

import SignUp from './components/users/SignUp';
import SignIn from './components/users/SignIn';
import OldNews from './components/users/OldNews';
import MainContainer from './components/layout/MainContainer';
import UserPost from './components/users/UserPost';

// style
import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { theme } from './styles/theme';
import { getCurrentUser } from './lib/api/auth';

// import * as wjcCore from '@grapecity/wijmo';
// wjcCore.setLicenseKey(
//   'www.sdgs12.com,515969466526562#B0JoIIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdKx4VshUYxo5dn5Ubu5UdUdGd744RHdjQxl6NRB5Nx3EV8MGVslzZmJDVvkDWPZjT9AXNsFnUIpmRrYHO7QVbyxGaN9WMWRUOOhzMvdTYrJFMyczYHNXewcETXNnYiVje5knNwlTNndVe7VmMCt4LYtWT7lXO4F6QphWashmNPplahBTc6Ija4x4ZvhVVkJWeWNUSHBFaGhTZwdWc5gWMr3UezRESmBzdaZWb9V7aJJ7d5JGd0F6SrEVU7cTZ8YVVz8UViZGWLljNjhUZwpkaKpnQ7NVMvMlVNNXN4A7TBh7R9hGeYlTTkpFNwQVM4BnWOR5NulnRXhlSrtSM5V6YzcnU5MXM03WTmhjWzlmN4QGOy9GSwMFRZRDWDhlT7d5Qm9kTCtiWXVmSxY4Ms9ER0BHeQJ4QpFVc7EDOQNTQyE6Vpt4MPd6c8d7cad6RqJ6KxIFdzwmI0IyUiwiIElTMFNTR5YjI0ICSiwyM9kDM6QzN7QTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiMjM8ITMwACOyIDMyIDMyIiOiQncDJCLi46bj9iMxM7ZkNnL7d7diojIz5GRiwiIrO88vK88GO881O88zK88oK880qL9VOr9VuY91Sr9piY96aZ9e6Z0a0a99m89iojIh94QiwiIyYTN6ITN6YDN9YTO5ETNiojIklkIs4XXbpjInxmZiwiIxYnMJAYM'
//   );

export const AuthContext = createContext();

const App = () => {
  LicenseInfo.setLicenseKey('ec0aa736f45256b9b6b78793e7ac724bTz05NTE2NixFPTE3NTM5MjIwOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=') //MUI pro ライセンスキー
  const [loading, setLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser();

      if (res?.data.isLogin === true) {
        setIsSignedIn(true);
        setCurrentUser(res?.data.data);
      } else {
        // console.log('no current user');
        // token有効期限が切れている場合、古いcookieを全て削除
        Cookies.remove('_access_token');
        Cookies.remove('_client');
        Cookies.remove('_uid');
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    handleGetCurrentUser();
  }, [setCurrentUser]);

  const Private = ({ children }) => {
    if (!loading) {
      if (isSignedIn) {
        return children;
      } else {
        return <Redirect to='/signin' />;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthContext.Provider
            value={{
              loading,
              setLoading,
              isSignedIn,
              setIsSignedIn,
              currentUser,
              setCurrentUser,
            }}
          >
            <CssBaseline />

            <Router>
              <Header />
              <MainContainer>
                <Switch>
                  <Route exact path='/signup' component={SignUp} />
                  <Route exact path='/signin' component={SignIn} />
                  <Route exact path='/oldnews' component={OldNews} />
                  <Private>
                    <Route exact path='/' component={Home} />

                    {/* <Route exact path='/qrreader' component={EgsQrReader} /> */}
                    <Route exact path='/webcam' component={WebCam} />

                    <Route path='/post/:id' component={Detail} />
                    <Route exact path='/new' component={New} />
                    <Route path='/edit/:id' component={Edit} />

                    {/* 追加 */}
                    <Route exact path='/user/posts' component={UserPost} />

                    <Route exact path='/egs_m_traders' component={EgsMTraderList} />
                    <Route path='/egs_m_traders/:id' component={EgsMTraderEdit} />
                    <Route path='/egs_m_tradersNew' component={EgsMTraderNew} />
                    {/* <Route path='/traderSel' component={EgsMTraderSel} />
                    <Route path='/egs_m_tradersCompanySel' component={TraderCompanySel} /> */}
                    <Route path='/egs_m_tradersAdd/:id' component={EgsMTraderAdd} />

                    <Route exact path='/egs_m_charges' component={EgsMChargeList} />
                    <Route path='/egs_m_charges/:id' component={EgsMChargeEdit} />
{/*                    <Route path='/egs_m_chargesNew' component={EgsMChargeNew} />  */}
{/*                    <Route path='/egs_m_chargesSel' component={ChargeTraderSelect} />  */}
{/*                    <Route path='/egs_m_chargesAdd/:id' component={EgsMChargeAdd} />  */}
                    <Route exact path='/sagyo_report' component={SagyoReportList} />
                    <Route exact path='/invoice_data' component={InvoiceDataList} />

                    <Route exact path='/egs_m_entries' component={EgsMEntryList} />
                    <Route path='/egs_m_entries/:id' component={EgsMEntryAdmin} />

                    <Route exact path='/egs_m_companies' component={EgsMCompanyList} />
{/*                    <Route exact path='/egs_m_companies' component={CompanyList} />
                    <Route path='/egs_m_companies/:id' component={EgsMCompanyEdit} />
                    <Route path='/egs_m_companiesNew' component={EgsMCompanyNew} />
                    <Route path='/select_companies/CompanySelect' component={CompanySelect} />  */}

{/*                    <Route exact path='/egs_m_handlings' component={EgsMHandlingList} />
                    <Route path='/egs_m_handlings/:id' component={EgsMHandlingEdit} />
                    <Route path='/egs_m_handlingsNew' component={EgsMHandlingNew} />    */}

{/*                    <Route exact path='/egs_m_reserve_sets' component={ReserveSetList} />
                    <Route path='/egs_m_reserve_sets/:id' component={EgsMReserveSetEdit} />
                    <Route path='/egs_m_reserve_setsNew' component={EgsMReserveSetNew} />
                    <Route path='/egs_m_reserve_detail/:id' component={ReserveDetail} />  */}

                    <Route exact path='/approves' component={ApproveList} />
{/*                    <Route path='/approves/:id' component={ApproveEdit} />  */}
                    <Route exact path='/trans_list' component={TransList} />
                    <Route exact path='/garbage_cnt' component={GarbageCntList} />

                    <Route exact path='/demand_b' component={DemandBList} />

                    <Route exact path='/permit' component={PermitList} />
                    <Route path='/permit/:id' component={PermitEdit} />
                    <Route path='/permitNew' component={PermitNew} />

{/*                    <Route exact path='/exhaust_plan' component={ExhaustPlanList} />
                    <Route path='/exhaust_plan/:id' component={ExhaustPlanEdit} />
                    <Route path='/exhaust_planAdd/:id' component={ExhaustPlanAdd} />   */}
{/*                    <Route path='/egs_m_reserve_setsSel' component={ReserveSetSel} />  */}
{/*                    <Route path='/trader_upd' component={TraderUpd} />
                    <Route path='/charge_upd' component={ChargeUpd} />
                    <Route path='/sect_upd/:id' component={SectUpd} />
                    <Route path='/toxic_sel/:id' component={ToxicSel} />   */}

     {/*               <Route exact path='/invoice' component={InvoiceList} />    */}
                    <Route exact path='/receipt' component={ReceiptList} />
                    <Route exact path='/receipt_load' component={ReceiptLoadList} />
                    <Route exact path='/disposal' component={DisposalList} />
                    <Route exact path='/receipt_unload' component={ReceiptUnloadList} />

{/*                    <Route exact path='/garbage_info' component={GarbageTagList} />
                    <Route path='/garbage_info/:id' component={EgsTGarbageTagEdit} />
                    <Route path='/garbage_infoNew' component={EgsTGarbageTagNew} />
                    <Route path='/garbage_infoSel' component={GarbageSelList} />
                    <Route path='/garbage_reserve_select' component={ReserveSetSelect} />
                    <Route path='/garbage_infoAdd/:id' component={EgsTGarbageTagAdd} />  */}

                    {/* <Route path='/ds_reserve_sets/:id' component={DsReserveSetEdit} /> */}
                    <Route path='/mbl_emission_set/:id' component={MblEmissionSetEdit} />
                    <Route path='/mbl_emission_update/:id' component={MblEmissionUpdate} />                    
                    <Route path='/mbl_process_flow/:id' component={MblProcessFlow} />
                    <Route path='/mbl_receipt_unload1' component={MblReceiptUnload1} />
                    <Route path='/mbl_receipt_unload2' component={MblReceiptUnload2} />
                    <Route path='/mbl_receipt_load' component={MblReceiptLoad} />
                    <Route path='/mbl_user_set/:id' component={MblUserSet} />
                    <Route path='/mbl_garbage_tmp/:id' component={MblGarbageTmpList} />
                    <Route path='/mbl_sagyo_report/:id' component={MblSagyoReport} />
{/*                    <Route path='/ds_garbage_info/:id' component={DsEgsTGarbageTagEdit} /> */}
{/*                    <Route path='/ds_garbage_photo' component={DsEgsTGarbagePhoto} /> */}

{/*                    <Route exact path='/egs_m_toxic_substances' component={EgsMToxicSubstanceList} />
                    <Route path='/egs_m_toxic_substances/:id' component={EgsMToxicSubstanceEdit} />
                    <Route path='/egs_m_toxic_substancesNew' component={EgsMToxicSubstanceNew} />  */}

{/*                    <Route exact path='/drilldown' component={Drilldown} />  */}
                    <Route exact path='/garbage_status' component={GarbageStatuList} />
{/*                    <Route exact path='/garbage_statusAdm' component={GarbageStatuAdm} />    */}
{/*                    <Route exact path='/garbage_pdf' component={GarbageTagDetailPdf} />
                    <Route exact path='/reserve_pdf' component={ReserveSetDetailPdf} />  */}
            {/*        <Route exact path='/invoice_pdf' component={InvoiceDetailPdf} />    */}
                    <Route exact path='/exhaust_sum' component={ExhaustSum} />

                    <Route exact path='/egs_m_process_flows' component={ProcessFlowList} />
                    <Route path='/egs_m_process_flows/:id' component={EgsMProcessFlowEdit} />
                    <Route path='/egs_m_process_flowsNew' component={EgsMProcessFlowNew} />

                    <Route exact path='/garbage_edi' component={EdiD01List} />
                    <Route exact path='/garbage_tmp' component={GarbageTmpList} />
                    <Route exact path='/garbage_csv' component={GarbageCsvList} />
                    <Route exact path='/garbage_old' component={GarbageOldList} />
                    <Route exact path='/garbage_old_csv' component={GarbageOldCsvList} />

{/*                    <Route exact path='/garbage_detail' component={GarbageDetailList} />  */}

                    <Route path='/first_sel/:id' component={FirstSelList} />
                    <Route path='/first_selEdit/:id' component={FirstSelEdit} />
                    <Route path='/edi_d01_itemAdd/:id' component={EdiD01ItemAdd} />
                    <Route path='/edi_d01_item/:id' component={EdiD01ItemEdit} />
                    <Route path='/edi_d02_item/:id' component={EdiD02ItemEdit} />
     {/*               <Route path='/edi_d03_item/:id' component={EdiD03ItemEdit} />   */}
     {/*               <Route path='/edi_d06_item/:id' component={EdiD06ItemEdit} />   */}

                    <Route path='/egs_m_linkageSel' component={LinkageSel} />
                    <Route path='/process_flowAdd/:id' component={ProcessFlowAdd} />

                    <Route exact path='/egs_m_linkage' component={LinkageList} />
                    <Route path='/egs_m_linkage/:id' component={LinkageList} />
                    <Route path='/egs_m_linkage/:id' component={EgsMLinkageEdit} />
{/*}                    <Route path='/egs_m_linkageNew/:id' component={EgsMLinkageNew} />        0516  */}
{/*                    <Route path='/egs_m_linkageNew' component={EgsMLinkageNew} />
                    <Route path='/trader_select' component={TraderSelect} />  */}

{/* //**************************管理ユーザ-S******************************************************** */}
                    {/* <Route exact path='/egs_m_traders_adm' component={EgsMTraderAdmList} />
                    <Route path='/egs_m_traders_adm/:id' component={EgsMTraderAdmEdit} />
                    <Route path='/egs_m_traders_admNew' component={EgsMTraderAdmNew} /> */}
   {/*                 <Route path='/trader_admSel' component={EgsMTraderAdmSel} />      */}
                    {/* <Route path='/egs_m_traders_admCompanySel' component={TraderAdmCompanySel} />
                    <Route path='/egs_m_traders_admAdd/:id' component={EgsMTraderAdmAdd} />

                    <Route exact path='/egs_m_charges_adm' component={EgsMChargeAdmList} />
                    <Route path='/egs_m_charges_adm/:id' component={EgsMChargeAdmEdit} />
                    <Route path='/egs_m_charges_admNew' component={EgsMChargeAdmNew} />
                    <Route path='/egs_m_charges_admSel' component={ChargeAdmTraderSelect} />
                    <Route path='/egs_m_charges_admAdd/:id' component={EgsMChargeAdmAdd} />

                    <Route exact path='/garbage_edi_admin' component={AdmEdiD01List} />
                    <Route path='/edi_d01_item_adminAdd/:id' component={AdmEdiD01ItemAdd} />
                    <Route path='/edi_d01_item_admin/:id' component={AdmEdiD01ItemEdit} />
                    <Route path='/edi_d02_item_admin/:id' component={AdmEdiD02ItemEdit} /> */}
  {/*                  <Route path='/edi_d03_item_admin/:id' component={AdmEdiD03ItemEdit} />    */}
  {/*}                  <Route path='/edi_d06_item_admin/:id' component={AdmEdiD06ItemEdit} />   */}
{/* //**************************管理ユーザ-E******************************************************** */}

{/*                    <Route exact path='/edi3000_r24' component={Edi3000R24List} />  */}

                    <Route exact path='/adm_edi_info' component={AdmEdiInfoList} />
                    <Route path='/process_flowSel' component={ProcessFlowSel} />

                  </Private>
                </Switch>
              </MainContainer>
            </Router>
          </AuthContext.Provider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};
export default App;
/*
                    <Route path='/exhaust_planNew' component={ExhaustPlanNew} />
                    <Route exact path='/garbage_info' component={EgsTGarbageTagList} />
                    <Route path='/select_garbage_seconds/:id' component={GarbageSecondSel} />

                    <Route exact path='/datasheets' component={DatasheetList} />
                    <Route path='/egs_m_datasheets/:id' component={EgsMDatasheetEdit} />
                    <Route path='/egs_m_datasheetNew' component={EgsMDatasheetNew} />
                    <Route path='/datasheetSel' component={DatasheetSel} />

*/
