// /src/lib/api/receipt_load.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptLoadList = () => {
  return client.get('/receipt_loads');
};

// 事業場選択一覧
export const getReceiptLoadUser = (id) => {
  return client.get(`/receipt_loads/${id}/showuser`);
};

// 詳細
export const getReceiptLoadDetail = (id) => {
  return client.get(`/receipt_loads/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptLoad = (params) => {
  return client.post('/receipt_loads', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptLoad = (id, params) => {
  return client.patch(`/receipt_loads/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptLoad = (id) => {
  return client.delete(`/receipt_loads/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
