import React from 'react';
import { Dialog, DialogContent, DialogTitle, Paper, Container, Grid, Typography, Button, Card, CardContent } from '@material-ui/core';
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import QRCode from "qrcode.react";

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  printSection: {
    '@media print': {
      display: 'block',
      height: 'auto',
      overflow: 'visible',
      pageBreakInside: 'avoid',
      margin: 0,
      padding: 0,
    },
  },
  printContainer: {
    '@media print': {
      width: '100%',
      height: 'auto',
      overflow: 'visible',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  printItem: {
    '@media print': {
      pageBreakInside: 'avoid',
      height: 'auto',
      overflow: 'visible',
      marginBottom: theme.spacing(2),
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },

}));

function GarbageEdiExtQrCre(props) {
  const { isOpen, doClose, dataList } = props;
  const classes = useStyles();
  console.log("dataList",dataList)
  
  const handleCancel = () => {
    doClose();
  };

  function EdiQrcode(props) {
    const { data } = props;
    const qrCodeUrl = "https://www.sdgs12.com/waste/" + data.id + "-";
    const flowid = data.id;

    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-start'}}>
          <Typography gutterBottom variant="h5" component="h2" style={{ fontWeight: 'bold' }}>
            {data.exhaustName}
          </Typography>
          <Typography style={{ fontSize: '1.5em',fontWeight: 'bold' }}>
            フロー名称　：{data.reserveName}<br />
            <br />
            フロー番号　：{data.reserveNo}　　サブ番号　：{data.reserveSubno}<br />
            廃棄物種類　：{data.wasteKindName}<br />
            処分方法　　：{data.disposalMethodName}<br />
            単位　　　　：{data.unitName}<br />
            荷姿　　　　：{data.packingName}<br />
            運搬先事業場：{data.destination1Name}<br />
            最終処分場　：{data.lastProcessingTraderName}<br />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ alignSelf: 'flex-end' }}>
          <Typography variant="body2" color="textSecondary" align="center" style={{ fontSize: '2em',fontWeight: 'bold' }}>
            {data.reserveName}<br />            
            <QRCode value={qrCodeUrl} /><br />
            {flowid}<br />
            {data.wasteKindName}
            <br />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
      className={classes.printSection} // 印刷対象のクラスを追加
    >
      <DialogTitle id="form-dialog-title" className={classes.noPrint}>
        <h2>現場設置用ＱＲコード</h2>

      <div style={{ textAlign: 'right' }}>
        <Button
          variant='contained'
          color="primary" 
          onClick={e => {
            e.preventDefault();
            window.print(); // 印刷ダイアログを開く
          }}
          style={{ marginRight: '10px' }} // 右マージンを追加
        >
          印刷（A3）
        </Button>
        <Button
          variant='contained'
          onClick={e => {
            e.preventDefault();
            handleCancel();
          }}
        >
          戻る
        </Button>
      </div>
      </DialogTitle>
      <DialogContent>
        <Container className={`${classes.cardGrid} ${classes.printContainer}`} maxWidth="lg">
          <Grid container spacing={4}>
            {dataList.map((data, index) => (
              <Grid item key={index} xs={12} className={classes.printItem}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <EdiQrcode card={index} data={data} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default GarbageEdiExtQrCre;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});