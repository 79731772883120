import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMTraderList, deleteEgsMTrader } from '../../lib/api/egs_m_trader';
//import { deleteEgsMTrader } from '../../lib/api/egs_m_trader';
import { getEgsMTraderUser,deleteEgsMTrader } from '../../lib/api/egs_m_trader';
//import { getTraderAdmList, getTraderAdmGroup1, getTraderAdmGroup2 } from '../../lib/api/trader_adm';
import { getTraderAdmList, getTraderAdmGroup, getTraderAdmUser, getTraderAdmOperation } from '../../lib/api/trader_adm';
//import { getEgsMTraderUser, deleteEgsMTrader } from '../../lib/api/egs_m_trader';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';

// component
import EgsMTraderListTable from './EgsMTraderListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMTraderList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    if (currentUser.traderKbn != '1111111111'){  //事業場区分は管理者以外？
      handleGetList();
    }
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      res = await getTraderAdmList();
      res = await getTraderAdmOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '__');
      setDataList(res.data);
    }else{                                       //事業場区分は管理者以外？
      if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ１？
//        res = await getTraderAdmGroup1(currentUser.traderCd);
//        res = await getTraderAdmGroup(currentUser.traderCd);
        res = await getTraderAdmOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '__');
        setDataList(res.data);
      }else{                                       //事業場区分は管理者以外？
//        if (currentUser.traderKbn == '0000000102'){  //事業場区分はグループ１？
//          res = await getTraderAdmGroup2(currentUser.traderCd);
//          setDataList(res.data);
//        }else{                                       //事業場区分は管理者以外？
//          res = await getEgsMTraderUser(currentUser.traderCd);
          res = await getTraderAdmUser(currentUser.traderCd);
          setDataList(res.data[0]);
//        }
      }
    }
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {
    if (window.confirm(' 「' + item.traderName + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMTrader(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>事業場マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_traders_admNew')}
        style={{ marginRight: 10 }}
      >
        新規作成
      </Button>
      <Button
        variant='contained'
        color='primary'

        currentUser={currentUser}

        onClick={() => history.push('/egs_m_traders_admCompanySel')}
        style={{ marginRight: 10 }}
      >
        企業参照作成
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/qrreader')}
        style={{ marginRight: 10 }}
      >
        QRコード読み込み
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/webcam')}
        style={{ marginRight: 10 }}
      >
        写真撮影
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/imgdropzone')}
        style={{ marginRight: 10 }}
      >
        写真取り込み
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/imagecard')}
        style={{ marginRight: 10 }}
      >
        写真CARD表示
      </Button> */}

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SpaceRow height={20} />
      <EgsMTraderListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default EgsMTraderList;
