// /src/lib/api/receipt_disposal_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptDisposalExhaustList = () => {
  return client.get('/receipt_disposal_exhausts');
};

// 事業場選択一覧
export const getReceiptDisposalExhaustUserDetail = (id) => {
  return client.get(`/receipt_disposal_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptDisposalExhaustUser = (id) => {
  return client.get(`/receipt_disposal_exhausts/${id}/showuser`);
};


