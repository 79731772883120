import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import SpaceRow from '../commons/SpaceRow';
import { getChargeEffective } from '../../lib/api/charge';

import { makeStyles,styled } from '@material-ui/core/styles';
import { Button, TextField, InputBase, MenuItem, Select, Box } from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import CropFreeIcon from '@material-ui/icons/CropFree';
import Typography from '@material-ui/core/Typography';
import { transportMethodName } from '../../constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function MblUserSet() {
  const classes = useStyles();
  const [value, setValue] = useState([]);

  const query = useParams();
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState({});

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2",dataList2);
  };

  const cols = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        width: 100
      },
    {
      field: 'chargeCd',
      headerName: '担当者CD',
      width: 80
    },
    {
      field: 'chargeName',
      headerName: '担当者名',
      width: 160
    },
    {
        field: 'chargeKana',
        headerName: '担当者名かな',
        width: 160
    },
      {
        field: 'egsMTraderId',
        headerName: '事業場ID',
        hide: true,
        width: 100
      },
      {
          field: 'traderCd',
          headerName: '事業場CD',
          width: 150
      },
      {
          field: 'traderName',
          headerName: '事業場名',
          width: 300
      }
  ]

  useEffect(() => {
    handleGetData(query);
  }, [query]);

  const handleGetData = async (query) => {
    // console.log("query",query);
    const res = await getChargeEffective(query.id);
    // const res = await getProcessFlowReserveNo(query.id);
    setDataList(res.data);
  };

  const handleClickCharge = async() => {
    const mbluser = dataList2.id + ":" + dataList2.chargeCd + ":" + dataList2.chargeName;
    // console.log("mbluser",mbluser);
    Cookies.set('mbl_user', mbluser);
    history.push(`/` );
  };

  return (
    <>
      <h1>スマホ担当者登録（初回のみ）</h1>
      <h2>本スマートフォン利用担当者を選択して「担当者登録」ボタンを押下ください</h2>

      <SpaceRow height={20} />
      <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={dataList}
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        <SpaceRow height={10} />
        <table>
          <tr>
            <th>担当者CD</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.chargeCd}</Typography>
            </td>
          </tr>
          <tr>
            <th>担当者名</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.chargeName}</Typography>
            </td>
          </tr>
          <tr>
            <th>担当者かな</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.chargeKana}</Typography>
            </td>
          </tr>
          </table>
          <SpaceRow height={10} />

          <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          {/* <Button variant='contained' onClick={() => history.push('/')} style={{ marginRight: 30, fontSize: '1.2em' }}>
            戻る
          </Button> */}
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleClickCharge(e)}
            style={{ marginRight: 10, fontSize: '1.2em' }}
            disabled={!dataList2.id}
          >
            担当者登録
          </Button>
        </Box>

    </>
  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default MblUserSet;
