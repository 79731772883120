// /src/lib/api/waste_cd.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getWasteCdList = () => {
  return client.get('/waste_cds');
};

// 詳細
export const getWasteCdDetail = (id) => {
  return client.get(`/waste_cds/${id}`);
};

// 新規作成
// header情報を追加
export const createWasteCd = (params) => {
  return client.post('/waste_cds', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateWasteCd = (id, params) => {
  return client.patch(`/waste_cds/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteWasteCd = (id) => {
  return client.delete(`/waste_cds/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
