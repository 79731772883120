// Form.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, Select, Checkbox, Typography } from '@material-ui/core';
import './app.css';

//import { getEgsMTraderList } from '../../lib/api/egs_m_trader';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
//import { getTraderAdmGroup1, getTraderAdmGroup2 } from '../../lib/api/trader_adm';
import { getTraderAdmGroup } from '../../lib/api/trader_adm';
//import { getCurrentUser } from '../../lib/api/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
}));

const EgsMChargeCreateForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, traderChange, handleSubmit, value, buttonType,    currentUser } = props;

  const [open, setOpen] = React.useState(false);
  const [traderList, setTraderList] = useState([]);
//  const [t02List, setT02List] = useState([]);

  const [errorTraderCd, setErrorTraderCd] = useState(false);  // 事業場CD
  const [errorChargeCd, setErrorChargeCd] = useState(false);  // 担当者CD
  const [errorChargeName, setErrorChargeName] = useState(false);  // 担当者名
  const [errorChargeKana, setErrorChargeKana] = useState(false);  // ふりがな
  const [errorEmail, setErrorEmail] = useState(false);  // メールアドレス
  const [errorEmailFlg, setErrorEmailFlg] = useState(false);  // メールアドレス有効フラグ
  const [errorEffectiveflg, setErrorEffectiveflg] = useState(false);  // 有効フラグ
  const [errorEdiKbn, setErrorEdiKbn] = useState(false);  // EDI担当者区分

  useEffect(() => {
   if (currentUser.traderKbn == '0000000001' || currentUser.traderKbn == '0000000102') {
    handleGetGroup();
   }
  }, []);

  const handleGetGroup = async () => {
    try {
     setOpen(!open);
//     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      const res = await getEgsMTraderList();

      if (currentUser.traderKbn == '0000000001') {
//        const res = await getTraderAdmGroup1(currentUser.traderCd);
        const res = await getTraderAdmGroup(currentUser.traderCd);
        // console.log(res.data);
        setTraderList(res.data);
//      }else{
//        const res = await getTraderAdmGroup2(currentUser.traderCd);
//        // console.log(res.data);
//        setTraderList(res.data);
      }

//    }

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

/*
  const traderdataList1 = traderList.filter(traderList => { return traderList.groupCd1 == currentUser.traderCd });

  if (currentUser.traderKbn == '0000000001') {
    const traderdataList1 = traderList.filter(traderList => { return traderList.groupCd1 == currentUser.traderCd });
    // console.log('traderdataList1',traderdataList1)
  }else{
  if (currentUser.traderKbn == '0000000102') {
    const traderdataList1 = traderList.filter(traderList => { return traderList.groupCd2 == currentUser.traderCd });
    // console.log('traderdataList1',traderdataList1)
  }
  }
*/

  /*
  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      const res = await getEgsMTraderList();
      const res = await getTraderList();
      // console.log(res.data);
      setTraderList(res.data);
//    }

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const traderdataList = traderList.filter(traderList => { return traderList.traderCd == value.traderCd });
  // console.log('traderdataList',traderdataList)


  const traderGetList = async () => {
    try {
//     setOpen(!open);
      const resT02 = await getTraderUser(value.traderCd);
      // console.log(resT02.data);
      setT02List(resT02.data);
//     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const t02dataList = t02List.filter(t02List => { return t02List.traderCd == value.traderCd });
  // console.log('t02dataList',t02dataList)
*/

//  const err_msg = ''
  const checkValidation = () => {

//    useEffect(() => {
//      traderGetList();
//    }, []);

//  alert('emailFlg <' + value.emailFlg + '> effectiveflg <' + value.effectiveflg + '>')

    let available = true;
//    let available = false;
    if (!value.traderName) {  // || !value.traderName) {    //  || !traderdataList.traderName) {
      // console.log('traderCd',value.traderCd,value.traderName); //,traderdataList.traderName);
//      err_msg = err_msg + '事業場CD ';
//      alert('事業場CDに誤りがあります')
      available = false;
      setErrorTraderCd(true);
    }else{
      setErrorTraderCd(false);
    }

//                                                   符号あり        /^-?[0-9]+$/
//                                                   符号なし        /^[0-9]+$/
// if (!value.chargeCd || value.chargeCd < 0 || !value.chargeCd.match(/^[0-9]+$/)) {
//  if (!value.chargeCd.match(/^-?[0-9]+$/)) {
   if (value.chargeCd < '001' || value.chargeCd > '99999') {
//      // console.log('chargeCd',value.chargeCd);
//      err_msg = err_msg + '担当者CD ';
      alert('担当者CDに誤りがあります')
      available = false;
      setErrorChargeCd(true);
    }else{
      setErrorChargeCd(false);
    }

    if (!value.chargeName ) {
//      err_msg = err_msg + '担当者名 ';
//      alert('担当者名に誤りがあります')
      available = false;
      setErrorChargeName(true);
    }else{
      setErrorChargeName(false);
    }

    if (!value.chargeKana ) {
//      err_msg = err_msg + 'ふりがな ';
//      alert('ふりがなに誤りがあります')
      available = false;
      setErrorChargeKana(true);
    }else{
      setErrorChargeKana(false);
    }

///    if (!value.email.match("^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$") ) {
//      alert('メールアドレスに誤りがあります')
///      available = false;
///      setErrorEmail(true);
///    }else{
///      setErrorEmail(false);
///    }

//    if (!value.emailFlg.match("0-1") ) {
///    if (value.emailFlg !== '0' && value.emailFlg !== '1') {
//      alert('メールアドレス有効フラグに誤りがあります')
///      available = false;
///      setErrorEmailFlg(true);
///    }else{
///      setErrorEmailFlg(false);
///    }

//    if (!value.ediKbn.match("01") ) {
    if (value.ediKbn < 0 || value.ediKbn > 1) {
//      alert('EDI区分誤りがあります')
      available = false;
      setErrorEdiKbn(true);
    }else{
      setErrorEdiKbn(false);
    }

//    if (!value.effectiveflg.match("01") ) {
//    if (value.effectiveflg !== '0' && value.effectiveflg !== '1') {
//      alert('有効フラグに誤りがあります')
//      available = false;
//      setErrorEffectiveflg(true);
//    }else{
//      setErrorEffectiveflg(false);
//    }

/*
    if (!err_msg) {
    }else{
     alert(err_msg + 'に誤りがあります');
    }
*/
  return available;
  }

if (currentUser.traderKbn == '1111111111') {    {/*getCurrentUser*/}
  return (
    <>
{/*}      <SpaceRow height={20} />  */}
      <form className={classes.root} noValidate autoComplete='off'>
       <table>

       <tr><th>事業場CD *</th>
         <td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
            onChange={(e) => traderChange(e)}  // handleChange
            error={errorTraderCd}
            value={value.traderCd}
            inputProps={{ maxLength: 15, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
          </td>
        </tr>
        <tr><th>事業場名称</th><td>
            <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderName}</Typography>
{/*}
            <Select
                id='traderCd'  // traderNameではNGだった
                label='事業場名称'
                variant="standard"  // outlined
                type='text'
                name='traderCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.traderName}
                </MenuItem>
              ))}
            </Select>
*/}
          </td></tr>

{/*}
        <tr><th>事業場ID</th><td><TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='number' name='egsMTraderId' fullWidth margin="dense" value={value.egsMTraderId} /></td></tr>
        <tr><th>事業場CD</th><td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd} /></td></tr>
*/}
        <tr><th>担当者CD *</th><td>
          <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeCd}
            value={value.chargeCd}
            inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
{/*}          <Typography variant="h7" style={{ marginTop: 10 }}>{value.chargeCd}</Typography>  */}
{/*}
          <Typography variant="h7" style={{ marginTop: 10, marginLeft: 30 }}>最大担当者CD：</Typography>
            <Select
                id='traderCd'  // chargeCdではNGだった
                label='新・担当者CD'
                variant="standard"  // outlined
                type='text'  //
                name='chartraderCdgeCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
                style={{ marginTop: 10, marginLeft: 10 }}
                >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.chargeCdMax}
                </MenuItem>
              ))}
            </Select>
*/}
          </td>
        </tr>
        <tr><th>担当者名 *</th><td><TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeName}
          value={value.chargeName}
        /></td></tr>
        <tr><th>ふりがな *</th><td><TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeKana}
          value={value.chargeKana}
        /></td></tr>
        <tr><th>メールアドレス</th><td><TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorEmail}
          value={value.email}
          inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
        />
{/*}            <TextField id='emailFlg' label='有効フラグ' variant="outlined" type='text' name='emailFlg' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.emailFlg} />  */}
{/*}        <tr><th>有効フラグ</th><td>  */}
{/*}
          <Checkbox
          id='emailFlg'
          label='有効フラグ'
          type='checkbox'
          defaultChecked
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={(e) => handleChange(e)}
          value={value.emailFlg}
        />
        有効フラグ
*/}
        </td></tr>
       </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
               }
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
{/*}
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
*/}
      </div>
    </>
  );
}else{
  if (currentUser.traderKbn == '0000000001') {    {/*getCurrentUser*/}

  const traderdataList1 = traderList.filter(traderList => { return traderList.groupCd1 == currentUser.traderCd || traderList.groupCd2 == currentUser.traderCd});
  // console.log('traderdataList1',traderdataList1)

  return (
    <>
{/*}      <SpaceRow height={20} />  */}
      <form className={classes.root} noValidate autoComplete='off'>
       <table>

       <tr><th>事業場CD *</th>
         <td>
{/*}
          <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorTraderCd}
            value={value.traderCd}
            inputProps={{ maxLength: 15, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
*/}
          <Select
                id='traderCd'  // traderNameではNGだった
                label='事業場名称'
                variant="outlined"  // standard
                type='text'
                name='traderCd'
                margin="dense"
                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList1.map((traderdataList1) => (
                <MenuItem
                  key={traderdataList1.traderCd}
                  value={traderdataList1.traderCd}
                >
                  {traderdataList1.traderCd}:{traderdataList1.traderName}
                </MenuItem>
              ))}
            </Select>
          </td>
        </tr>
{/*}
        <tr><th>事業場名称</th><td>
            <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderName}</Typography>


          </td></tr>
*/}
{/*}
        <tr><th>事業場ID</th><td><TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='number' name='egsMTraderId' fullWidth margin="dense" value={value.egsMTraderId} /></td></tr>
        <tr><th>事業場CD</th><td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd} /></td></tr>
*/}
        <tr><th>担当者CD *</th><td>
          <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeCd}
            value={value.chargeCd}
            inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
{/*}          <Typography variant="h7" style={{ marginTop: 10 }}>{value.chargeCd}</Typography>  */}
{/*}
          <Typography variant="h7" style={{ marginTop: 10, marginLeft: 30 }}>最大担当者CD：</Typography>
            <Select
                id='traderCd'  // chargeCdではNGだった
                label='新・担当者CD'
                variant="standard"  // outlined
                type='text'  //
                name='chartraderCdgeCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
                style={{ marginTop: 10, marginLeft: 10 }}
                >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.chargeCdMax}
                </MenuItem>
              ))}
            </Select>
*/}
          </td>
        </tr>
        <tr><th>担当者名 *</th><td><TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeName}
          value={value.chargeName}
        /></td></tr>
        <tr><th>ふりがな *</th><td><TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeKana}
          value={value.chargeKana}
        /></td></tr>
        <tr><th>メールアドレス</th><td><TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorEmail}
          value={value.email}
          inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
        />
{/*}            <TextField id='emailFlg' label='有効フラグ' variant="outlined" type='text' name='emailFlg' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.emailFlg} />  */}
{/*}        <tr><th>有効フラグ</th><td>  */}
{/*}
          <Checkbox
          id='emailFlg'
          label='有効フラグ'
          type='checkbox'
          defaultChecked
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={(e) => handleChange(e)}
          value={value.emailFlg}
        />
        有効フラグ
*/}
        </td></tr>
       </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
               }
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
{/*}
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
*/}
      </div>
    </>
  );
}else{
  return (
    <>
{/*}      <SpaceRow height={20} />  */}
      <form className={classes.root} noValidate autoComplete='off'>
       <table>

       <tr><th>事業場CD *</th>
         <td>
            <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderCd}</Typography>
{/*}            <TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' halfWidth margin="dense"
              onChange={(e) => handleChange(e)}
              error={errorTraderCd}
              value={value.traderCd}
              inputProps={{ maxLength: 15, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
  />  */}
          </td>
        </tr>
        <tr><th>事業場名称</th><td>
{/*}            <Typography variant="h7" style={{ marginTop: 10 }}>{currentUser.name}</Typography>  */}
            <Typography variant="h7" style={{ marginTop: 10 }}>{value.traderName}</Typography>
{/*}
            <Select
                id='traderCd'  // traderNameではNGだった
                label='事業場名称'
                variant="standard"  // outlined
                type='text'
                name='traderCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
              >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.traderName}
                </MenuItem>
              ))}
            </Select>
*/}
          </td></tr>

{/*}
        <tr><th>事業場ID</th><td><TextField id='egsMTraderId' label='事業場ID' variant="outlined" type='number' name='egsMTraderId' fullWidth margin="dense" value={value.egsMTraderId} /></td></tr>
        <tr><th>事業場CD</th><td><TextField id='traderCd' label='事業場CD' variant="outlined" type='text' name='traderCd' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.traderCd} /></td></tr>
*/}
        <tr><th>担当者CD *</th><td>
          <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeCd}
            value={value.chargeCd}
            inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
{/*}          <Typography variant="h7" style={{ marginTop: 10 }}>{value.chargeCd}</Typography>  */}
{/*}
          <Typography variant="h7" style={{ marginTop: 10, marginLeft: 30 }}>最大担当者CD：</Typography>
            <Select
                id='traderCd'  // chargeCdではNGだった
                label='新・担当者CD'
                variant="standard"  // outlined
                type='text'  //
                name='chartraderCdgeCd'
                margin="dense"
//                onChange={(e) => handleChange(e)}    // 表示のみか、選択出来ないようにしたいが…
                value={value.traderCd}
                halfWidth
                style={{ marginTop: 10, marginLeft: 10 }}
                >
              {traderdataList.map((traderdataList) => (
                <MenuItem
                  key={traderdataList.traderCd}
                  value={traderdataList.traderCd}
                >
                  {traderdataList.chargeCdMax}
                </MenuItem>
              ))}
            </Select>
*/}
          </td>
        </tr>
        <tr><th>担当者名 *</th><td><TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeName}
          value={value.chargeName}
        /></td></tr>
        <tr><th>ふりがな *</th><td><TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeKana}
          value={value.chargeKana}
        /></td></tr>
        <tr><th>メールアドレス</th><td><TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorEmail}
          value={value.email}
          inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
        />
{/*}            <TextField id='emailFlg' label='有効フラグ' variant="outlined" type='text' name='emailFlg' fullWidth margin="dense" onChange={(e) => handleChange(e)} value={value.emailFlg} />  */}
{/*}        <tr><th>有効フラグ</th><td>  */}
{/*}
          <Checkbox
          id='emailFlg'
          label='有効フラグ'
          type='checkbox'
          defaultChecked
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={(e) => handleChange(e)}
          value={value.emailFlg}
        />
        有効フラグ
*/}
        </td></tr>
       </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
               }
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
{/*}
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
*/}
      </div>
    </>
  );
}
}
};
export default EgsMChargeCreateForm;
