// /src/lib/api/garbage_detail.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getGarbageDetailList = () => {
  return client.get('/garbage_details');
};

// id指定
export const getGarbageDetailId = (id) => {
  return client.get(`/garbage_details/${id}/showid`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageDetailUser = (id) => {
  return client.get(`/garbage_details/${id}/showuser`);
};

// グループ１指定一覧 idはグループCD
export const getGarbageDetailGroup1 = (id) => {
  return client.get(`/garbage_details/${id}/showgroup1`);
};

// グループ２指定一覧 idはグループCD
export const getGarbageDetailGroup2 = (id) => {
  return client.get(`/garbage_details/${id}/showgroup2`);
};

// 運用中廃棄物 idは'all'
export const getGarbageDetailOperation = (id) => {
  return client.get(`/garbage_details/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getGarbageDetailUserOpe = (id) => {
  return client.get(`/garbage_details/${id}/showuserope`);
};

//  運用中廃棄物 グループ１指定一覧 idはグループCD
export const getGarbageDetailGroup1Ope = (id) => {
  return client.get(`/garbage_details/${id}/showgroup1ope`);
};

//  運用中廃棄物 グループ２指定一覧 idはグループCD
export const getGarbageDetailGroup2Ope = (id) => {
  return client.get(`/garbage_details/${id}/showgroup2ope`);
};

// 詳細（追加・更新用）
export const getGarbageDetailDetail = (id) => {
  return client.get(`/garbage_details/${id}`);
};

// 新規作成
// header情報を追加
export const createGarbageDetail = (params) => {
  return client.post('/garbage_details', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateGarbageDetail = (id, params) => {
  return client.patch(`/garbage_details/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteGarbageDetail = (id) => {
  return client.delete(`/garbage_details/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
