import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"

//import ReactToPdf from "react-to-pdf";
import QRCode from "qrcode.react"
import dayjs from 'dayjs';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'

import FormDialog from "../commons/FormDialog";
import './app.css';
import { statusName,ediFlg,toxicSubstanceName } from '../../constants'
/*
import EgsTGarbageEdiEdit from './EdiD01ItemEdit';
//1112import FirstSelEdit from './FirstSelEdit';
//import TransportDateEdit from './TransportDateEdit';
import TransportDateEdit from './TransportDateEdit';
//import TransportDateEdit1 from './TransportDateEdit1';
//import TransportDateEdit2 from './TransportDateEdit2';
//import TransportDateEdit3 from './TransportDateEdit3';
//import TransportDateEdit4 from './TransportDateEdit4';
//import TransportDateEdit5 from './TransportDateEdit5';
import TransportLoadDateEdit from './TransportLoadDateEdit';
import MiddleDateEdit from './MiddleDateEdit';
import LastDateEdit from './LastDateEdit';
//1112import FirstSelList from './FirstSelList';
*/
import GarbageOldListTableForm from "./GarbageOldListTableForm"
/*
import { getEgsTPhotoGbgid } from '../../lib/api/egs_t_photo';
import EgsTGarbageEdi2ndEdit from "./EgsTGarbageEdi2ndEdit"
import EdiD01ItemCancel from "./EdiD01ItemCancel"
import LastDisposalEdit from "./LastDisposalEdit"
*/
import GarbageOldPdf from "./GarbageOldPdf"
import { getGarbageDetailOldOperation  } from '../../lib/api/garbage_detail_old';
import { getEgsTGarbageOldOperation  } from '../../lib/api/egs_t_garbage_old';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_GarbageOld',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#FFC",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function GarbageOldListTable(props) {
  const classes = useStyles();
  const { dataList, currentUser } = props; // handleDelete , handleCancel
  
  const [detailOpen1, setDetailOpen1] = useState(false);
  const [detailOpen2, setDetailOpen2] = useState(false);
/*  
  const [detailOpen30, setDetailOpen30] = useState(false);  // 区間１～５運搬完了日修正
//  const [detailOpen31, setDetailOpen31] = useState(false);  // 区間１運搬完了日修正
//  const [detailOpen32, setDetailOpen32] = useState(false);  // 区間２運搬完了日修正
//  const [detailOpen33, setDetailOpen33] = useState(false);  // 区間３運搬完了日修正
//  const [detailOpen34, setDetailOpen34] = useState(false);  // 区間４運搬完了日修正
//  const [detailOpen35, setDetailOpen35] = useState(false);  // 区間５運搬完了日修正
  const [detailOpen36, setDetailOpen36] = useState(false);  // 区間１～５積込日修正
  const [detailOpen4, setDetailOpen4] = useState(false);  // 中間処理日修正
  const [detailOpen5, setDetailOpen5] = useState(false);  // 最終処分日修正
  const [detailOpen6, setDetailOpen6] = useState(false);  // ２次マニフェスト
  const [detailOpen7, setDetailOpen7] = useState(false);  // 取消（論理削除）
  const [detailOpen8, setDetailOpen8] = useState(false);  // 最終処分終了報告
*/
  const [detailOpen9, setDetailOpen9] = useState(false);  // 廃棄物詳細PDF

  const [dataList2, setDataList2] = useState({});
  const [dataList3, setDataList3] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [curData, setCurData] = useState();

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    manifestNo: '',
//    wasteKindName: '',
    wasteName: '',
    statusName: '',
    exhaustName: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList);
    // console.log('currentUser', currentUser);
    setOpen2(!open2);
    // console.log('filter', filter);
    if (filter.exhaustDateStart == '' ){
//      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
      filter.exhaustDateStart = '2018-01-01';
    }
    if (filter.exhaustDateEnd == '' ){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
//      filter.exhaustDateEnd = '2023-03-31';
    }
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.exhaustName+"_"+filter.wasteName+"_"+filter.sect1TransportName;
    // console.log("param_item",param_item)
    handleGetList(param_item);
    setOpen2(false);
  }

  const handleGetList = async () => {
    // setOpen2(!open2);
    try {
//      const  res = await getGarbageDetailOldOperation(param_item);
      const  res = await getEgsTGarbageOldOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    // setOpen2(false);
  };
/*
  const handleGetPhotos = async () => {
      try {
      const res = await getEgsTPhotoGbgid(dataList2.id);
      setDataList3(res.data);
    } catch (e) {
      console.log(e);
    }

  };
*/
  useEffect(() => {
    // console.log("dataList3",dataList3);
    }, []);

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2.id",dataList2.id);
//    handleGetPhotos(dataList2.id);
  };

  const handleOpen1 = () => {
    setDetailOpen1(true);
  };

  const handleOpen2 = () => {
    setDetailOpen2(true);
  };
/*
  let sectId = '1';
  const handleOpen31 = (sectId) => {
    sectId = '1';
    currentUser.nickname = '1';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間１運搬完了日修正';
    setDetailOpen30(true);    // setDetailOpen31
  };
  const handleOpen32 = (sectId) => {
    sectId = '2';
    currentUser.nickname = '2';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間２運搬完了日修正';
    setDetailOpen30(true);    // setDetailOpen32
  };
  const handleOpen33 = (sectId) => {
    sectId = '3';
    currentUser.nickname = '3';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間３運搬完了日修正';
    setDetailOpen30(true);    // setDetailOpen33
  };
  const handleOpen34 = (sectId) => {
    sectId = '4';
    currentUser.nickname = '4';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間４運搬完了日修正';
    setDetailOpen30(true);    // setDetailOpen34
  };
  const handleOpen35 = (sectId) => {
    sectId = '5';
    currentUser.nickname = '5';
//    alert('sectId='+sectId + currentUser.nickname);
//    titleId=='区間５運搬完了日修正';
    setDetailOpen30(true);    // setDetailOpen35
  };

  const handleOpen36 = (sectId) => {
    sectId = '1';
    currentUser.nickname = '1';
    setDetailOpen36(true);
  };
  const handleOpen37 = (sectId) => {
    sectId = '2';
    currentUser.nickname = '2';
    setDetailOpen36(true);
  };
  const handleOpen38 = (sectId) => {
    sectId = '3';
    currentUser.nickname = '3';
    setDetailOpen36(true);
  };
  const handleOpen39 = (sectId) => {
    sectId = '4';
    currentUser.nickname = '4';
    setDetailOpen36(true);
  };
  const handleOpen3A = (sectId) => {
    sectId = '5';
    currentUser.nickname = '5';
    setDetailOpen36(true);
  };

  const handleOpen4 = () => {
    setDetailOpen4(true);
  };

  const handleOpen5 = () => {
    setDetailOpen5(true);
  };

  const handleOpen6 = () => {
    setDetailOpen6(true);
  };

  const handleOpen7 = () => {
    setDetailOpen7(true);
  };

  const handleOpen8 = () => {
    setDetailOpen8(true);
  };
*/
  const handleOpen9 = () => {
    setDetailOpen9(true);
  };
  

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);

/*
  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // 行の取消（論理削除）
  const cancelRows = () => {
    // console.log('cancelRows',dataList2)
    handleCancel(dataList2);
  }
*/
  // console.log('dataList',dataList);
  // console.log('dataList2',dataList2);
  // console.log('dataList.id',dataList.id);

  const qrCodeUrl = "https://www.sdgs12.com/waste/" + dataList2.garbageTag;
  // console.log('qrCodeUrl', qrCodeUrl);

  const colsA = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 200
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 200
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const colsG = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*    
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 200
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 200
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const cols1 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*    
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    
    {
      field: 'sect1TransportName',  // exhaustName
      headerName: '区間１運搬業者',  // 排出事業場
      width: 300
    },
/*
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬業者',
      hide: false,
      width: 300
    },
*/
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
//    {
//      field: 'sect1TransportName',
//      headerName: '区間１運搬事業者',
//      hide: true,
//      width: 300
//    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]
  const cols2MB = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*    
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      hide:true,
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      hide:true,
      width: 210
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      hide:true,
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      hide:true,
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      hide:true,
      width: 60
    }
  ]

  const cols2PC = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*    
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      width: 120
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 60
    }
  ]

  const cols4 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'secondFlgName',
      headerName: '1/2次',
      width: 50
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 250
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 250
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 140
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 110
    },
/*    
    {
      field: 'ediSendName',
      headerName: 'JWNET登録状況',
      width: 210
    },
    {
      field: 'noticeName',
      headerName: 'JWNET通知情報',
      width: 210
    },
*/    
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 100
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 240
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      hide: true,
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      hide: true,
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      hide: true,
      width: 150
    },
    {
      field: 'memo5',
      headerName: '備考５',
      hide: true,
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'lastProcessingEnd',
      headerName: '最終処分終了日',
      type: 'date',
      hide: true,
      width: 120
    }
  ]

  if (currentUser.traderKbn == '1111111111'){   //事業場区分は管理者？
  return (
    <>
      <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField id="sect1TransportName" value={filter.sect1TransportName} onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　MAX：3000件　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={open2} >
            <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={colsA}
          rows={filteredDataList}
          density="compact"
          // checkboxSelection
          // disableSelectionOnClick
          // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            ColumnMenu: CustomColumnMenu,　// カスタムカラムメニューを指定する
          }}
          setDetailOpen2
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={handleRowClick}
        />
        </Box>

        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageOldPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageOldListTableForm
            dataList2={dataList2}
          />
      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else if ((currentUser.traderKbn == '0001001000') ||
          (currentUser.traderKbn == '0001000000')){   //事業場区分は中間処理・排出事業者？
  return (
    <>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols4}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageOldPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageOldListTableForm
            dataList2={dataList2}
          />
      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else if (currentUser.traderKbn == '0100000000'){   //事業場区分は収集運搬業者？

  return (
    <>
      <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols2MB}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
      </MobileView>

      <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols2PC}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
        <GarbageOldPdf
          currentUser={currentUser}
          isOpen={detailOpen9}
          doClose={() => setDetailOpen9(false)}
          dataList2={dataList2}
        />
        <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
          disabled={!dataList2.id }>
          廃棄物実績報告PDF
        </Button>
        </Box>
        <Box component='div' sx={{textAlign: 'left' }}>
         <GarbageOldListTableForm
          dataList2={dataList2}
        />
      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
      </BrowserView>
    </>
  );
}else if (currentUser.traderKbn == '0000000001'){   //事業場区分はグループ管理者？
  return (
    <>
    <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={colsG}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
    </MobileView>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField id="sect1TransportName" value={filter.sect1TransportName} onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={colsG}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageOldPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageOldListTableForm
            dataList2={dataList2}
          />
      </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}else{                                       //事業場区分は管理者以外？
  return (
    <>
    <MobileView>
      <Grid
        container
        spacing={2}
      >
        <div style={{ width: '100%', height: 600 }}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols1}
              rows={filteredDataList}
              density="compact"
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}setDetailOpen2
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Grid>
    </MobileView>
    <BrowserView>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　マニフェストNo：</Typography>
          <TextField id="manifestNo" value={filter.manifestNo} onChange={e => setFilter({ ...filter, manifestNo: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　区間１運搬事業者：</Typography>
          <TextField id="sect1TransportName" value={filter.sect1TransportName} onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          </Box>
          <TextField
            id="exhaustDate-start" value={filter.exhaustDateStart} onChange={e => setFilter({ ...filter, exhaustDateStart: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-end" value={filter.exhaustDateEnd} onChange={e => setFilter({ ...filter, exhaustDateEnd: e.target.value })}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>　状態：</Typography>
          <TextField id="statusName" value={filter.statusName} onChange={e => setFilter({ ...filter, statusName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出業者：</Typography>
          <TextField id="exhaustName" value={filter.exhaustName} onChange={e => setFilter({ ...filter, exhaustName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
          <TextField id="wasteName" value={filter.wasteName} onChange={e => setFilter({ ...filter, wasteName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
            データ表示
          </Button>
          {/* 　　初期表示：最新60日の排出データを表示 */}
          　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件
        </Grid>
      </Grid>

      <div style={{ width: '100%', height: 600 }}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols1}
            rows={filteredDataList}
            density="compact"
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}setDetailOpen2
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
        <Box component='div' sx={{ p: 1, textAlign: 'right' }}>
          <GarbageOldPdf
            currentUser={currentUser}
            isOpen={detailOpen9}
            doClose={() => setDetailOpen9(false)}
            dataList2={dataList2}
          />
          <Button variant="outlined" color='primary' style={{ marginRight: 10 }} onClick={handleOpen9}
            disabled={!dataList2.id }>
            廃棄物実績報告PDF
          </Button>
          </Box>
          <Box component='div' sx={{textAlign: 'left' }}>
          <GarbageOldListTableForm
            dataList2={dataList2}
          />
        </Box>

      <div className="container">
        {
          dataList3.map((dataList3) =>(
            <div key={dataList3.id} className="card" onClick={e => {
              e.preventDefault();
              setCurData(dataList3);
              setOpen(true);
            }}>
            <img src={dataList3.imageName.url}  alt="" style={{ width: "150px" }}/>
            <div className="card-content">
                <h1 className="card-title">
                {statusName[dataList3.status]}　　{dataList3.content}<br />
                {dataList3.gpsLat}<br />{dataList3.gpsLon}
                </h1>
              </div>
            </div>
          )
        )}
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent>
          <div key={curData?.id} className="card">
            <img src={curData?.imageName?.url} alt="" style={{
              width: "100%"
            }}/>
          </div>
          <Button onClick={() => setOpen(false)} style={{ marginTop: 16, float: 'right' }}>閉じる</Button>
        </DialogContent>
      </Dialog>

      <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（情報更新用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
      </Box>
      </div>
    </BrowserView>
    </>
  );

}
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#B664FE',  // '#65b2c6',
      color: '#fff',
    }
  }
}
export default GarbageOldListTable;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
