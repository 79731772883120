import React, { useEffect, useState, useContext } from 'react';
// import { getGarbageCsvList, getGarbageEdiUser } from '../../lib/api/garbage_edi';
import { getGarbageDetailOldCsvList,getGarbageDetailOldCsvUser,  getGarbageDetailOldCsvGroup1, getGarbageDetailOldCsvGroup2  } from '../../lib/api/garbage_detail_old';
import { deleteEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
//import { getGarbageDetailOldCsvOperation  } from '../../lib/api/garbage_detail_old_csv';
//import { getEgsTGarbageOldCsvOperation  } from '../../lib/api/egs_t_garbage_old_csv';
import { getEgsTGarbageOldOperation  } from '../../lib/api/egs_t_garbage_old';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import GarbageOldCsvListTable from './GarbageOldCsvListTable';
//import GarbageDetailOldCsvListTable from './GarbageDetailOldCsvListTable';

import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GarbageCsvList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open);
/*      
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
        // res = await getGarbageCsvList();
        res = await getGarbageDetailOldCsvList();
      }else{                                       //事業場区分は管理者以外？
        if (currentUser.traderKbn == '0000000001'
         || currentUser.traderKbn == '0000000101' ){  //事業場区分はグループ１？
          // res = await getGarbageCsvList();
//          res = await getGarbageDetailOldCsvGroup1(currentUser.nickname);
          res = await getGarbageDetailOldCsvGroup1(currentUser.traderCd);
        }else{                                       //事業場区分は管理者以外？
          if (currentUser.traderKbn == '0000000002'
           || currentUser.traderKbn == '0000000102' ){  //事業場区分はグループ１？
            // res = await getGarbageCsvList();
//            res = await getGarbageDetailOldCsvGroup2(currentUser.nickname);
            res = await getGarbageDetailOldCsvGroup2(currentUser.traderCd);
          }else{                                       //事業場区分は管理者以外？
            // res = await getGarbageEdiUser(currentUser.traderCd);
        res = await getGarbageDetailOldCsvUser(currentUser.traderCd);
          }
        }
      }
      // console.log(res.data);
*/

//      res = await getGarbageDetailOldCsvOperation(currentUser.traderCd + '_' + currentUser.traderKbn)
//      res = await getEgsTGarbageOldCsvOperation(currentUser.traderCd + '_' + currentUser.traderKbn)
      res = await getEgsTGarbageOldOperation(currentUser.traderCd + '_' + currentUser.traderKbn)

      setDataList(res.data);
      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  // // console.log('currentUser',currentUser);

/*
  const resdataList = dataList.filter(dataList => {
    return dataList.exhaustCd                === currentUser.traderCd ||   // 0527 戻す
//    return dataList.sect1TransportCd           === currentUser.traderCd ||   // 0527 戻す
           dataList.sect1TransportCd         === currentUser.traderCd ||   // 0527 戻す
           dataList.sect2TransportCd         === currentUser.traderCd ||   // 0527 戻す
           dataList.sect3TransportCd         === currentUser.traderCd ||   // 0527 戻す
           dataList.sect4TransportCd         === currentUser.traderCd ||   // 0527 戻す
           dataList.sect5TransportCd         === currentUser.traderCd ||   // 0527 戻す
           dataList.destination1Cd           === currentUser.traderCd ||   // 0527 戻す
           dataList.destination2Cd           === currentUser.traderCd ||   // 0527 戻す
           dataList.destination3Cd           === currentUser.traderCd ||   // 0527 戻す
           dataList.destination4Cd           === currentUser.traderCd ||   // 0527 戻す
           dataList.destination5Cd           === currentUser.traderCd ||   // 0527 戻す
 //          dataList.middleProcessingTraderCd === currentUser.traderCd ||   // 0527 戻す
           dataList.lastProcessingTraderCd   === currentUser.traderCd   // 0527 戻す
      //   dataList.sect1TransportCd         === '400293810220'
  })
*/

//  // console.log('dataList',dataList);
//  // console.log('resdataList',resdataList);

  const history = useHistory();

  const handleDelete = async (item) => {
    if (window.confirm(' ゴミタグ「' + item.garbageTag + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsTGarbageEdi(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  const handleCancel = async (item) => {
    if (window.confirm(' ゴミタグ「' + item.garbageTag + '」を取消（論理削除し）ます。よろしいですか？')) {
      try {
        const res = await deleteEgsTGarbageEdi(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>廃棄物一覧CSV出力（環境ガードシステム 2018年1月～2023年3月）</h1>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

{/*}      <SpaceRow height={20} />    */}
      <GarbageOldCsvListTable
        dataList={dataList}    // resdataList
        handleDelete={handleDelete}
        currentUser={currentUser}

        handleCancel={handleCancel}
      />
    </>
  );
};
export default GarbageCsvList;

/*
        onClick={() => history.push('/garbage_reserve_select')}

        <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/garbage_infoPdf')}
      >
        PDF出力
      </Button>
*/
