// /src/lib/api/entry.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEntryList = () => {
  return client.get('/entries');
};

// 事業所指定一覧 idは事業所CD
export const getEntryUser = (id) => {
  return client.get(`/entries/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD で有効フラグ='1'
export const getEntryEffective = (id) => {
  return client.get(`/entries/${id}/showEffective`);
};

// 担当者指定一覧 idはグループ
export const getEntryGroup = (id) => {
  return client.get(`/entries/${id}/showgroup`);
};

// 担当者指定一覧 idはグループ１
export const getEntryGroup1 = (id) => {
  return client.get(`/entries/${id}/showgroup1`);
};

// 担当者指定一覧 idはグループ２
export const getEntryGroup2 = (id) => {
  return client.get(`/entries/${id}/showgroup2`);
};

// 詳細
export const getEntryDetail = (id) => {
  return client.get(`/entries/${id}`);
};

// 検索条件 idは'all'
export const getEntryOperation = (id) => {
  return client.get(`/entries/${id}/showope`);
};

// 新規作成
// header情報を追加
export const createentry = (params) => {
  return client.post('/entries', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateentry = (id, params) => {
  return client.patch(`/entries/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteentry = (id) => {
  return client.delete(`/entries/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
