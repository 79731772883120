import React from 'react';
import {
    PDFDownloadLink,
    PDFViewer,
    Page,
    View,
    Font,
    Document,
    StyleSheet
} from '@react-pdf/renderer';
import Text from "../pdf_components/atoms/Text";
import Title from "../pdf_components/molecules/Title";
import Link from "../pdf_components/atoms/Link";
import Br from "../pdf_components/atoms/Br";
import { Grid, Col } from "../pdf_components/atoms/Grid";
import {
    ListItem,
    Report,
    PR,
    Exhaust,
    Middle,
    Fainal,
    Toxic,
    Bikou,
    Kukan1Trans,
    Kukan1Desti,
    Kukan2Trans,
    Kukan2Desti,
    Kukan3Trans,
    Kukan3Desti,
    SyobunJyutaku,
    LastSyobunPlace
} from "../pdf_components/molecules/Base";

import fontRegular from '../../fonts/Nasu-Regular.ttf';
import fontBold from '../../fonts/Nasu-Bold.ttf';
import dayjs from 'dayjs';

Font.register({
  family: 'Nasu-Regular',
  src: fontRegular
});

Font.register({
  family: 'Nasu-Bold',
  src: fontBold
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "#000000",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth * 0.5,
    height: window.innerHeight,
  },
  screen: {
    padding: 10
  },
});

function SagyoReportPdfForm({ dataList2, chargeName, sagyouStart, sagyouEnd }) {
  const today = "出力日：" + dayjs().format('YYYY/MM/DD');
  const reportTitle = `作業報告 - ${chargeName}`;
  const reportTitle2 = `作業期間 ： ${sagyouStart} ～ ${sagyouEnd}`;

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.screen}>
            <Title title={reportTitle} createDate={today} />
            <Title title={reportTitle2} />
            <Grid borderTop borderBottom>
              <Report>
                {dataList2.map((data, index) => {
                  // 日付が変更された場合に区切り線を挿入
                  const previousData = dataList2[index - 1];
                  const isNewDate = previousData && data.sagyoDate !== previousData.sagyoDate;
                  return (
                    <React.Fragment key={index}>
                      {isNewDate && <View style={{ width: '100%', height: 1, backgroundColor: '#000', marginVertical: 5 }} />}
                      <View>
                        <Text>
                          {data.sagyoDate}　{dayjs(data.sagyoTime).format('HH:mm')}　　{data.sagyoTask}　{data.sagyoPlace}　{data.sagyoMemo}
                        </Text>
                      </View>
                    </React.Fragment>
                  );
                })}
              </Report>
            </Grid>
            <Text size="normal" style={{ textAlign: "right" }}>
              産業廃棄物SDGs推進システム
            </Text>
            <Br />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default SagyoReportPdfForm;