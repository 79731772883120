import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button } from '@material-ui/core';

import { createEgsMCharge, getEgsMChargeDetail } from '../../lib/api/egs_m_charge';
import { getTraderList, getTraderUser } from '../../lib/api/trader';
//import { getTraderAdmList, getTraderAdmUser } from '../../lib/api/trader_adm';
//import FormBody from './EgsMChargeForm';
import FormBody from './EgsMChargeCreateForm';  // フォーム分割
import { getCurrentUser } from '../../lib/api/auth';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMChargeCreat({ isOpen, doClose, currentUser }) {  // processId



//  const currentUser = getCurrentUser();

  let wkTraderCd;
  let wkTraderName;

//  if (currentUser.traderKbn == '1111111111') {
  if (currentUser.traderKbn == '1111111111' || currentUser.traderKbn == '0000000001') {
    wkTraderCd = ''
    wkTraderName = ''
  }else{
    wkTraderCd = currentUser.traderCd
    wkTraderName = currentUser.nickname
  }


  const [dataListTrader,setDataListTrader] = useState([]);  {/* 事業場 */}


  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    egsMTraderId: 0,
    traderCd: wkTraderCd, // '',
    traderName: wkTraderName, // '',
    chargeCd: '',
    chargeName: '',
    chargeKana: '',
    email: '',
    emailFlg: '0',
    ediKbn: 0,
    effectiveflg: '1',  //  新規作成時は有効とする　  '0',
  });
/*
  useEffect(() => {
    handleGetData(processId);
  }, [processId]);
*/
/*
  useEffect(() => {
    traderGetList(value.traderCd);
  }, [value.traderCd]);
*/

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
/*
  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMChargeDetail(processId);
      // console.log(res.data);
      setValue({
        egsMTraderId: res.data.egsMTraderId,
        traderCd: res.data.traderCd,
        // chargeCd: res.data.chargeCd,
        // chargeName: res.data.chargeName,
        // chargeKana: res.data.chargeKana,
        // email: res.data.email,
        // emailFlg: res.data.emailFlg,
        // ediKbn: res.data.ediKbn,
        // effectiveflg: res.data.effectiveflg,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
*/
/*
  const traderGetList = async () => {
    try {
      setLoading(true);
      const resT02 = await getTraderUser(value.traderCd);
      // console.log(resT02.data);
      setValue({
        egsMTraderId: resT02.data[0].id,
        traderCd: resT02.data[0].traderCd,
        traderName: resT02.data[0].traderName,
//        chargeCd: resT02.data[0].chargeCdMax,
        chargeCd: resT02.data[0].nextChargeCd,
        chargeName: value.chargeName,
        chargeKana: value.chargeKana,
        email: value.email,
        emailFlg: value.emailFlg,
        ediKbn: value.ediKbn,
        effectiveflg: value.effectiveflg,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
*/

    const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const traderChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  
    if (e.target.value != null && e.target.value.length == 12) {
/*
        try {

          const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);
  
        value.address = dataListAddress.data[0].address1
        value.address2 = dataListAddress.data[0].address2
        value.address3 = dataListAddress.data[0].address3
        value.prefectureCd = dataListAddress.data[0].prefectureCd
  
        value.zipAddress = dataListAddress.data[0].address1
        value.zipAddress2 = dataListAddress.data[0].address2
        value.zipAddress3 = dataListAddress.data[0].address3
        } catch (e) {
        console.log(e);
      }
  
    }
*/  
        try {
//          setLoading(true);
//          const dataListTrader = await getTraderAdmUser(e.target.value);
          const dataListTrader = await getTraderUser(e.target.value);
          setDataListTrader(dataListTrader.data);
          // console.log(resT02.data);
//          setValue({
            value.egsMTraderId = dataListTrader.data[0].id
            value.traderCd = dataListTrader.data[0].traderCd
            value.traderName = dataListTrader.data[0].traderName
//            value.chargeCd = dataListTrader.data[0].chargeCdMax
            value.chargeCd = dataListTrader.data[0].nextChargeCd
//            chargeName: value.chargeName,
//            chargeKana: value.chargeKana,
//            value.email = dataListTrader.data[0].email1
//            value.emailFlg = dataListTrader.data[0].emailFlg1
//            ediKbn: value.ediKbn,
//            value.effectiveflg = dataListTrader.data[0].effectiveflg  // tradersにeffectiveflgなし

            setValue({
              ...value,
              [e.target.name]: e.target.value,
            });
        
      
//          });
        } catch (e) {
          console.log(e);
//        } finally {
//          setLoading(false);
        }

    
      }
  };
    const handleSubmit = async (e) => {

    if (window.confirm(' 担当者「' + value.traderCd + ':' + value.traderName + ':' + value.chargeCd + '」を登録します。よろしいですか？')) {
      try {
        e.preventDefault();
        // 追加
        const params = generateParams();
        // console.log('params',params)
        try {
          const res = await createEgsMCharge(params);
          // console.log(res);
          handleCancel();
    //      history.push('/permit');  // egs_m_permits
          window.location.reload();
        } catch (e) {
          console.log(e);
          console.log(e.response);
        }
      } catch (e) {
        console.log(e.response);
      }
    }

/*
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params',params)
    try {
      const res = await createEgsMCharge(params);
      // console.log(res);
      handleCancel();
//      history.push('/egs_m_charges');
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
*/
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
/*
      egs_m_trader_id: value.egsMTraderId,
      trader_cd: value.traderCd,
      charge_cd: value.chargeCd,
      charge_name: value.chargeName,
      charge_kana: value.chargeKana,
      email: value.email,
      email_flg: value.emailFlg,
      edi_kbn: value.ediKbn,
      effectiveflg: value.effectiveflg,
*/
      egsMTraderId: value.egsMTraderId,
      traderCd: value.traderCd,
      chargeCd: value.chargeCd,
      chargeName: value.chargeName,
      chargeKana: value.chargeKana,
      email: value.email,
      emailFlg: value.emailFlg,
      ediKbn: value.ediKbn,
      effectiveflg: value.effectiveflg,

    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者新規作成</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
//              handleBack();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          traderChange={traderChange}
          handleSubmit={handleSubmit}
          currentUser={currentUser}
          value={value}
          buttonType='作成' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMChargeCreat;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
