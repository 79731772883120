import React from "react";
import { View } from "@react-pdf/renderer";
import { Grid, Col } from "../../atoms/Grid";
import Text from "../../atoms/Text";

export default props => (
  <>
    <Col subTitle borderLeft={props.borderLeft} borderRight={props.borderRight}>
      <Text>{props.lable}</Text>
    </Col>
    <Col size={props.size || 2} borderRight>
      <Text>{props.children}</Text>
    </Col>
  </>
);
