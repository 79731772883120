import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { getFirstSelList, getFirstSelUser } from '../../lib/api/first_sel';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#CFF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function FirstSelSelect(props) {
  const { currentUser, isOpen, doClose, dataList } = props;
  const classes = useStyles();
  // const [dataList, setDataList] = useState([]);
  const [open, setOpen] = React.useState(false);
  var selectdataList = [];
  let res;

  useEffect(() => {
    // handleGetList();
  }, []);

  // const handleGetList = async () => {
  //   try {
  //    setOpen(!open);
  //      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
  //       res = await getFirstSelList();
  //     }else{                                       //事業場区分は管理者以外？
  //       res = await getFirstSelUser(currentUser.traderCd);
  //     }
  //     // console.log(res.data);
  //     setDataList(res.data);
  //     setOpen(false);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    // 追加
    // console.log("selRows",selRows);
    if (selRows.current.length > 0){
      for (let i = 0; i<selRows.current.length; i++){
        selectdataList[i] = dataList.filter(dataList => {
        return dataList.id == selRows.current[i] });

      }
    }
      // console.log("selectdataList",selectdataList);
      handleCancel();
  };

  const history = useHistory();

  const [curData, setCurData] = useState();

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    manifestNo: '',
  })

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList);
    // console.log('currentUser', currentUser);
    let originalList = dataList;

    // console.log('originalList', originalList);
    // console.log('filter', filter);
    if (filter.manifestNo) {
      originalList = originalList.filter(item => item.manifestNo === filter.manifestNo);
    }
    if (filter.exhaustDateStart) {
      const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustDateEnd) {
      const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
      });
    }
    setFilteredDataList(originalList);
  }


  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 150
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 200
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteclassName',
      headerName: '廃棄物種類',
      width: 300
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    }
  ]

  let selRows = React.useRef([]);

  const [pageSize, setPageSize] = React.useState(10);

  // console.log('dataList',dataList);
  // console.log('dataList.id',dataList.id);

  return (
  <>
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>１次マニフェスト選択</h2>
      </DialogTitle>
      <DialogContent>

    <Grid
      container
      spacing={2}
    >
    <Grid item xs={1}>
      <Box p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
          排出期間：
        </Typography>
      </Box>
    </Grid>
    <Grid item xs style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    }}>
      <TextField
        id="exhaustDate-start"
        label=""
        type={"date"}
        value={filter.exhaustDateStart}
        onChange={e => setFilter({
          ...filter,
          exhaustDateStart: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
      <TextField
        id="exhaustDate-start"
        label=""
        type={"date"}
        value={filter.exhaustDateEnd}
        onChange={e => setFilter({
          ...filter,
          exhaustDateEnd: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item xs={2}>
      <Box p={1} display={"flex"} alignItems={"center"}>
        <Typography variant="h7" style={{ fontWeight: 700 }}>
          マニフェストNo：
        </Typography>
      </Box>
    </Grid>
    <Grid item xs>
      <TextField
        id="manifestNo"
        label=""
        type={"text"}
        value={filter.manifestNo}
        onChange={e => setFilter({
          ...filter,
          manifestNo: e.target.value
        })}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.preventDefault();
          startFilter();
        }}
        style={{ marginLeft: 24 }}
      >
        検索
      </Button>
    </Grid>
  </Grid>
        <div className={classes.container}>
          <DataGridPro
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            autoHeight
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color='primary' style={{ marginRight: 10 }}
            onClick={(e) => handleSubmit()} >
            登録
          </Button>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>
            戻る
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
    </>
  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default FirstSelSelect;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
