// /src/lib/api/egs_m_wasteclass.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsMWasteclassList = () => {
  return client.get('/egs_m_wasteclasses');
};

// 詳細
export const getEgsMWasteclassDetail = (id) => {
  return client.get(`/egs_m_wasteclasses/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMWasteclass = (params) => {
  return client.post('/egs_m_wasteclasses', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMWasteclass = (id, params) => {
  return client.patch(`/egs_m_wasteclasses/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMWasteclass = (id) => {
  return client.delete(`/egs_m_wasteclasses/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
