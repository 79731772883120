import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { updateEgsMTrader, updatePassEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import FormBody from './EgsMTraderPassForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMTraderPass({ isOpen, doClose, processId }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    rawpasswd: '',
  });

  useEffect(() => {
    // console.log("processId",processId);
    if(processId != undefined){
      handleGetData(processId);
    }
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMTraderDetail(processId);
      // console.log(res.data);
      setValue({
        traderCd: res.data.traderCd,
        rawpasswd: res.data.rawpasswd,  // rawpasswdへログインパスワード保存
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = generateParams();
    if (window.confirm('ログインパスワードを更新します。よろしいですか？')) {
      handleCancel();
      try {
        const res = await updateEgsMTrader(processId, params);
        // console.log("res",res);
        // userテーブルのログインパスワード更新処理
        const tradercdpass = value.traderCd + '-' + value.rawpasswd;
        const res2 = await updatePassEgsMTrader(tradercdpass);
        // console.log("res2",res2);
        alert('ログインパスワードを更新しました')
        window.location.reload();
      } catch (e) {
        console.log(e);
        alert('エラーが発生しました。ログインパスワードは更新されませんでした')
      }
    }else{
      alert('ログインパスワードを更新しませんでした')
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      rawpasswd: value.rawpasswd,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>ログインパスワード変更</h2>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMTraderPass;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
