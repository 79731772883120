import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { updateEgsMCompany, getEgsMCompanyDetail } from '../../lib/api/egs_m_company';
import { getCompanyDetail } from '../../lib/api/company';
import { getAddressZip } from '../../lib/api/address';
import FormBody from './EgsMCompanyForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMCompanyEdit({ isOpen, doClose, processId, dataList2 }) {
  const [loading, setLoading] = useState(false);
  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  const [value, setValue] = useState({
    id: '',
    companyCd: '',
    egsMCompanyId: 0,
    companyCd: '',
    certifyId: '',
    traderKbn: '',

    traderKbn1: '',  // 排出
    traderKbn2: '',  // 収集運搬
    traderKbn3: '',  // 積替え保管
    traderKbn4: '',  // 中間処理
    traderKbn5: '',  // 最終処分
    traderKbn6: '',  // 管理

    checkKbn1: false,  // 排出

    companyName: '',
    companyKana: '',
    companyDispName: '',
    jwnetNo: '',
    jwnetEdipass: '',
    jwnetSubno: '',
    exhaustPasswd: '',
    prefectureCd: '',
    zip: '',
    address: '',
    address2: '',
    address3: '',
    address4: '',

    zipAddress: '',
    zipAddress2: '',
    zipAddress3: '',

    phone: '',
    fax: '',
    email: '',
    permitComno: '',
//    email1: '',
//    email1Flg: '',
//    email2: '',
//    email2Flg: '',
//    email3: '',
//    email3Flg: '',
//    memo: '',
//    egsUserFlg: 0,
//    ediClientKbn: '',
    effectiveflg: '',
//    chargeDivision: '',
//    mkKbn3000: '',

//    rawpasswd: '',
    groupCd1: '',
    groupCd2: '',
    groupCd3: '',
    groupCd4: '',
    groupCd5: '',
    groupId1: 0,
    groupId2: 0,
    groupId3: 0,
    groupId4: 0,
    groupId5: 0,

    businessTypeCd: '',
    businessTypeName: '',
  });

  const zipChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.value != null && e.target.value.length == 7) {
        try {
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

//        value.address = dataListAddress.data[0].address1
//        value.address2 = dataListAddress.data[0].address2
//        value.address3 = dataListAddress.data[0].address3
        value.address = dataListAddress.data[0].address2
        value.address2 = dataListAddress.data[0].address3
        value.prefectureCd = dataListAddress.data[0].prefectureCd

        value.zipAddress = dataListAddress.data[0].address1
        value.zipAddress2 = dataListAddress.data[0].address2
        value.zipAddress3 = dataListAddress.data[0].address3
//        const dataListNewCd = await getCompanyCdNewPref(value.prefectureCd);
//        setDataListNewCd(dataListNewCd.data);

//        value.companyCd = dataListNewCd.data[0].companyCdNew
//        value.jwnetEdipass = dataListNewCd.data[0].passwd1

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }

    }

  };

  useEffect(() => {
    if (processId != undefined){
      handleGetData(processId);
    }
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
//      const res = await getEgsMCompanyDetail(processId);
//      const res = await getCompanyDetail(processId);
      // console.log(res.data);
      setValue({
        id: dataList2.id,    // res.data[0].id
        companyCd: dataList2.companyCd,
        egsMCompanyId: dataList2.egsMCompanyId,
        companyCd: dataList2.companyCd,
        certifyId: dataList2.certifyId,
        traderKbn: dataList2.traderKbn,

        traderKbn1: dataList2.traderKbn.substring(0,1),  // 排出
        traderKbn2: dataList2.traderKbn.substring(1,2),
        traderKbn3: dataList2.traderKbn.substring(2,3),
        traderKbn4: dataList2.traderKbn.substring(3,4),
        traderKbn5: dataList2.traderKbn.substring(4,5),
        traderKbn6: dataList2.traderKbn.substring(9,10),

//////        traderKbn2.defaultChecked={true}, // checked = true,
//        checkKbn1: true,
        checkKbn1: dataList2.traderKbn.substring(0,1).boolean,  // 排出

        companyName: dataList2.companyName,
        companyKana: dataList2.companyKana,
        companyDispName: dataList2.companyDispName,
        jwnetNo: dataList2.jwnetNo,
        jwnetEdipass: dataList2.jwnetEdipass,
        jwnetSubno: dataList2.jwnetSubno,
        exhaustPasswd: dataList2.exhaustPasswd,
        prefectureCd: dataList2.prefectureCd,
        zip: dataList2.zip,
        address: dataList2.address,
        address2: dataList2.address2,
        address3: dataList2.address3,
        address4: dataList2.address4,

        zipAddress: dataList2.address,
        zipAddress2: dataList2.address2,
        zipAddress3: dataList2.address3,

        phone: dataList2.phone,
        fax: dataList2.fax,
        email: dataList2.email,
//        email1: dataList2.email1,
//        email1Flg: dataList2.email1Flg,
//        email2: dataList2.email2,
//        email2Flg: dataList2.email2Flg,
//        email3: dataList2.email3,
//        email3Flg: dataList2.email3Flg,
        permitComno: dataList2.permitComno,
//        memo: dataList2.memo,
//        egsUserFlg: dataList2.egsUserFlg,
//        ediClientKbn: dataList2.ediClientKbn,
        effectiveflg: dataList2.effectiveflg,
//        chargeDivision: dataList2.chargeDivision,
//        mkKbn3000: dataList2.mkKbn3000,

//        rawpasswd: dataList2.rawpasswd,
        groupCd1: dataList2.groupCd1,
        groupCd2: dataList2.groupCd2,
        groupCd3: dataList2.groupCd3,
        groupCd4: dataList2.groupCd4,
        groupCd5: dataList2.groupCd5,
        groupId1: dataList2.groupId1,
        groupId2: dataList2.groupId2,
        groupId3: dataList2.groupId3,
        groupId4: dataList2.groupId4,
        groupId5: dataList2.groupId5,

        businessTypeCd: dataList2.businessTypeCd,
        businessTypeName: dataList2.businessTypeName,

//        companyName: dataList2.companyName,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    if (window.confirm(' 企業マスタ「' + value.companyCd + '」を更新します。よろしいですか？')) {
      try {
        const res = await updateEgsMCompany(processId, params);
        // console.log(res);
        handleCancel();
//        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      companyCd: value.companyCd,
      certifyId: value.certifyId,
//      traderKbn: value.traderKbn,
      traderKbn: value.traderKbn1 + value.traderKbn2 + value.traderKbn3 + value.traderKbn4 + value.traderKbn5 + '0000' + value.traderKbn6,
      companyName: value.companyName,
      companyKana: value.companyKana,
      companyDispName: value.companyDispName,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      prefectureCd: value.prefectureCd,
      zip: value.zip,
      address: value.address,
      address2: value.address2,
      address3: value.address3,
      address4: value.address4,
      phone: value.phone,
      fax: value.fax,
      email: value.email,
//      email1: value.email1,
//      email1Flg: value.email1Flg,
//      email2: value.email2,
//      email2Flg: value.email2Flg,
//      email3: value.email3,
//      email3Flg: value.email3Flg,
      permitComno: value.permitComno,      
//      memo: value.memo,
//      egsUserFlg: value.egsUserFlg,
//      ediClientKbn: value.ediClientKbn,
      effectiveflg: value.effectiveflg,
//      chargeDivision: value.chargeDivision,
//      mkKbn3000: value.mkKbn3000,

      rawpasswd: value.rawpasswd,
      groupCd1: value.groupCd1,
      groupCd2: value.groupCd2,
      groupCd3: value.groupCd3,
      groupCd4: value.groupCd4,
      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,

      businessTypeCd: value.businessTypeCd,
      businessTypeName: value.businessTypeName,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>企業マスタ編集</h2>
        <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleCancel();
        }}>
          戻る
        </Button>
      </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleChange={handleChange}
          zipChange={zipChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMCompanyEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
