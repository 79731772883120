import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from "qrcode.react"

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { updateWkExhaustPeriod, getWkExhaustPeriodList, getWkExhaustPeriodDetail } from '../../lib/api/wk_exhaust_period';

import SpaceRow from '../commons/SpaceRow';
import './app.css';

import '@grapecity/wijmo.styles/wijmo.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import dayjs from 'dayjs';  // 検索用


import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  jaJP
} from '@mui/x-data-grid-pro'


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '運搬業者別積込登録集計',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 300,  // 650
  },
  fontWeight: {
    fontWeight: 2000,  // 900
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',    // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "80vw",    // "85vw"    ⇒　横幅を20％に設定
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function TransListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
  // console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
//  const [dataList2, setDataList2] = useState([]);


const [open, setOpen] = React.useState(false);
const [dataListT01, setDataListT01] = useState([]);    {/* 選択事業場 */}



  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);

// 検索-S
const [filteredDataList, setFilteredDataList] = React.useState([]);
const [filter, setFilter] = React.useState({
//  exhaustFrom: today,
//  exhaustTo: today,
    sectName: '',
    sectCd: '',
    ediflg: '',
})




useEffect(() => {
  handleGetList();
}, []);

const handleGetList = async () => {
  try {
   setOpen(!open);
//      const dataListPrefecture = await getEgsMPrefectureList();
//      setDataListPrefecture(dataListPrefecture.data);
//    // console.log('prefectureCd',value.prefecureCd,'dataListPrefecure',dataListPrefecure.data);

    const resT01 = await getWkExhaustPeriodList();
    // console.log(resT01.data);
    setDataListT01(resT01.data);

   setOpen(false);
  } catch (e) {
    console.log(e);
    console.log(e.response);
  }
};






const [value, setValue] = useState({
  exhaustFrom: '',
  exhaustTo: '',
//    sectCd: '' ,
});

const today = dayjs().format('YYYY-MM-DD');
// console.log('today',today);
value.receiptDate = today;

useEffect(() => {
  setFilteredDataList(dataList);
  // console.log('setFilteredDataList', filteredDataList);
}, [dataList, currentUser])

const startFilter = () => {
  // console.log('dataList', dataList);
  // console.log('currentUser', currentUser);
  let originalList = dataList;

  // console.log('originalList', originalList);
  // console.log('filter', filter);

  if (filter.sectCd) {
    originalList = originalList.filter(item => item.sectCd === filter.sectCd);
  }

//  if(~string.indexOf(pattern)){
//    // 部分一致のときの処理
//  }
    if (filter.sectName) {
    originalList = originalList.filter(item => item.sectName === filter.sectName);
//    originalList = originalList.filter(item => item.sectName.indexOf === filter.sectName);
//    originalList = originalList.filter(item => item.sectName.indexOf(filter.sectName));
  }

  if (filter.prefectureCd) {
    originalList = originalList.filter(item => item.prefectureCd === filter.prefectureCd);
  }

  if (filter.ediflg) {
    originalList = originalList.filter(item => item.ediflg === filter.ediflg);
  }

//  // console.log('originalList', originalList);

/*
  if (filter.exhaustFrom) {
    const startDate = dayjs(filter.exhaustFrom, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
    });
  }
  if (filter.exhaustTo) {
    const endDate = dayjs(filter.exhaustTo, 'YYYY-MM-DD');
    originalList = originalList.filter(item => {
      const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
      return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
    });
  }
*/
  setFilteredDataList(originalList);
}

// 検索-E


//const handleChange = () => {
//  if (inputRef.current) {
//    const ref = inputRef.current;
//    if (!ref.validity.valid) {
//      setInputError(true);
//    } else {
//      setInputError(false);
//    }
//  }


const handleChange = (e) => {
  setValue({
    ...value,
    [e.target.name]: e.target.value,
  });
};


/*
if (window.confirm(' 排出期間を更新します。よろしいですか？')) {
  try {
//    const resUpd = await updateWkExhaustPeriod(1, params);
//    // console.log(resUpd);
//        doClose();
//        history.push('/egs_m_trader_adm');
      window.location.reload();

//        handleGetList();
//        const res = await getTransList();
//        // console.log(res.data);
//        setDataList(res.data);


  } catch (e) {
    console.log(e);
  }
//}

};
*/

const handleSubmit = async (e) => {

  if (window.confirm(' 排出期間を更新します。よろしいですか？')) {
    try {

      e.preventDefault();
      // 追加
      const params = generateParams();
  //    const paramt = generateUserParams();
      try {
        const res = await updateWkExhaustPeriod(1, params);  // query.id
        // console.log('res',res);
  //      const ret = await updateUser(query.id, paramt);
  //      // console.log('ret',ret);
  //      history.push('/egs_m_traders');
//          window.location.reload();
        handleGetList();
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }

    } catch (e) {
      console.log(e);
    }
  }
};


// パラメータのオブジェクト構造を加工
const generateParams = () => {
  const params = {
    id: value.id,
    exhaustFrom: value.exhaustFrom,
    exhaustTo: value.exhaustTo,
  };
  return params;
};




  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };

//  const handleRowClick = (param, event) => {
//    // console.log(param.row);
//    setDataList2(param.row);
//  };

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    // console.log(`handleDetailClick:id=${params.id}`);
  }, []);

  const cols = [
    {
      field: 'sectName',
      headerName: '運搬事業場名',
      width: 250
    },
    {
        field: 'chargeKbn',
        headerName: '料金区分',
        width: 80
    },
    {
      field: 'sectCd',
      headerName: '運搬事業場CD',
      width: 100
    },,
    {
      field: 'ediflgName',
      headerName: '電子・紙',
      width: 80
    },
    {
      field: 'kensu',
      headerName: '収集件数',
      width: 80
    },
    {
      field: 'unit',
      headerName: '単位',
      width: 60
    },
    {
      field: 'exhaustCd',
      headerName: '排出事業場CD',
      width: 100,
      hide: true,
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場名',
      width: 250
    },
    {
      field: 'zip',
      headerName: '郵便番号',
      width: 70
    },
    {
      field: 'address',
      headerName: '住所',
      width: 200
    },
    {
      field: 'phone',
      headerName: '電話番号',
      width: 120
    },
]

//  const qrCodeUrl = "https://www.sdgs12.com/" + currentUser.traderKbn + "/" + dataList2.traderCd + "/" + dataList2.chargeCd
//  // console.log('qrCodeUrl',qrCodeUrl);

  return (
    <>
{/*    検索-S  */}
      <Grid
        container
        spacing={2}
      >

{/*}        <Grid item xs={1}>  */}
{/*}
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20 }}>【日付変更できない】排出期間③：</Typography>
          </Box>
          <TextField
            id="exhaustFrom" value={value.exhaustFrom}
            onChange={(e) => handleChange(e)}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustTo" value={value.exhaustTo}
            onChange={(e) => handleChange(e)}
            label="" type={"date"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
          <Button
            type='submit'    // button
            variant='contained' color='primary'
            onClick={(e) => handleSubmit(e)} style={{ marginLeft: 24 ,      marginBottom : 20 }} >
            排出期間変更
          </Button>
*/}
          <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20}}>県CD：</Typography>
{/*}          <TextField
            id="prefectureCd" value={filter.prefectureCd} onChange={e => setFilter({ ...filter, prefectureCd: e.target.value })}
            label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }}
/>  */}
          <Select
            id='traderKbn' label='都道府県名' name='prefectureCd' value={filter.prefectureCd}  // value={value.prefectureCd}
            variant="outlined" type='text' margin="dense"
//            onChange={(e) => handleChange(e)}
            onChange={e => setFilter({ ...filter, prefectureCd: e.target.value })}
            style={{ marginBottom : 20}}
          >
{/*}            <MenuItem value={value.prefectureCd}>  */}
            <MenuItem value={filter.prefectureCd}>
              <em>都道府県名</em>
            </MenuItem>
            <MenuItem value={''}>:未選択 </MenuItem>
            <MenuItem value={'01'}>01:北海道 </MenuItem>
            <MenuItem value={'02'}>02:青森県 </MenuItem>
            <MenuItem value={'03'}>03:岩手県 </MenuItem>
            <MenuItem value={'04'}>04:宮城県 </MenuItem>
            <MenuItem value={'05'}>05:秋田県 </MenuItem>
            <MenuItem value={'06'}>06:山形県 </MenuItem>
            <MenuItem value={'07'}>07:福島県 </MenuItem>
            <MenuItem value={'08'}>08:茨城県 </MenuItem>
            <MenuItem value={'09'}>09:栃木県 </MenuItem>
            <MenuItem value={'10'}>10:群馬県 </MenuItem>
            <MenuItem value={'11'}>11:埼玉県 </MenuItem>
            <MenuItem value={'12'}>12:千葉県 </MenuItem>
            <MenuItem value={'13'}>13:東京都 </MenuItem>
            <MenuItem value={'14'}>14:神奈川県</MenuItem>
            <MenuItem value={'15'}>15:新潟県 </MenuItem>
            <MenuItem value={'16'}>16:富山県 </MenuItem>
            <MenuItem value={'17'}>17:石川県 </MenuItem>
            <MenuItem value={'18'}>18:福井県 </MenuItem>
            <MenuItem value={'19'}>19:山梨県 </MenuItem>
            <MenuItem value={'20'}>20:長野県 </MenuItem>
            <MenuItem value={'21'}>21:岐阜県 </MenuItem>
            <MenuItem value={'22'}>22:静岡県 </MenuItem>
            <MenuItem value={'23'}>23:愛知県 </MenuItem>
            <MenuItem value={'24'}>24:三重県 </MenuItem>
            <MenuItem value={'25'}>25:滋賀県 </MenuItem>
            <MenuItem value={'26'}>26:京都府 </MenuItem>
            <MenuItem value={'27'}>27:大阪府 </MenuItem>
            <MenuItem value={'28'}>28:兵庫県 </MenuItem>
            <MenuItem value={'29'}>29:奈良県 </MenuItem>
            <MenuItem value={'30'}>30:和歌山県</MenuItem>
            <MenuItem value={'31'}>31:鳥取県 </MenuItem>
            <MenuItem value={'32'}>32:島根県 </MenuItem>
            <MenuItem value={'33'}>33:岡山県 </MenuItem>
            <MenuItem value={'34'}>34:広島県 </MenuItem>
            <MenuItem value={'35'}>35:山口県 </MenuItem>
            <MenuItem value={'36'}>36:徳島県 </MenuItem>
            <MenuItem value={'37'}>37:香川県 </MenuItem>
            <MenuItem value={'38'}>38:愛媛県 </MenuItem>
            <MenuItem value={'39'}>39:高知県 </MenuItem>
            <MenuItem value={'40'}>40:福岡県 </MenuItem>
            <MenuItem value={'41'}>41:佐賀県 </MenuItem>
            <MenuItem value={'42'}>42:長崎県 </MenuItem>
            <MenuItem value={'43'}>43:熊本県 </MenuItem>
            <MenuItem value={'44'}>44:大分県 </MenuItem>
            <MenuItem value={'45'}>45:宮崎県 </MenuItem>
            <MenuItem value={'46'}>46:鹿児島県</MenuItem>
            <MenuItem value={'47'}>47:沖縄県 </MenuItem>
         </Select>

          <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20}}>　運搬事業場名：</Typography>
          <TextField
            id="sectName" value={filter.sectName} onChange={e => setFilter({ ...filter, sectName: e.target.value })}
            label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />
{/*}          <Box p={1} display={"flex"} alignItems={"center"}>  */}
            <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20}}>運搬事業場CD：</Typography>
{/*}          </Box>  */}
{/*}        </Grid> */}
{/*}        <Grid item xs>  */}
          <TextField
            id="sectCd" value={filter.sectCd} onChange={e => setFilter({ ...filter, sectCd: e.target.value })}
            label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }}
          />

          <Typography variant="h7" style={{ fontWeight: 700 ,      marginBottom : 20}}>電子/紙：</Typography>
{/*}          <TextField
            id="ediflg" value={filter.ediflg} onChange={e => setFilter({ ...filter, ediflg: e.target.value })}
            label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }}
/>  */}
          <Select
            id='traderKbn' label='電子／紙' name='ediflg' value={filter.ediflg}  // value={value.ediflg}
            variant="outlined" type='text' margin="dense"
//            onChange={(e) => handleChange(e)}
            onChange={e => setFilter({ ...filter, ediflg: e.target.value })}
            style={{ marginBottom : 20}}
          >
            <MenuItem value={filter.ediflg}>
              <em>電子／紙</em>
            </MenuItem>
            <MenuItem value={''}>:未選択 </MenuItem>
            <MenuItem value={'1'}>1:電子 </MenuItem>
            <MenuItem value={'2'}>2:紙 </MenuItem>
          </Select>

          <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }}
            style={{ marginLeft: 24 ,      marginBottom : 20 }} >
            検索
          </Button>
{/*}        </Grid>  */}
        </Grid>


{/*}       検索-E  */}

      <div className={classes.container}>
       <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
//          pageSize={pageSize}
//          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//          rowsPerPageOptions={[10, 20, 50]}
//          pagination
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}  // dataList
          density="compact"
//          autoHeight
          // checkboxSelection
          // disableSelectionOnClick
          // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//          onRowClick={handleRowClick}
        />
       </Box>
      </div>
      <SpaceRow height={20} />

    </>

  );
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default TransListTable;
