// Form.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, Select, Checkbox, Typography } from '@material-ui/core';
import './app.css';

//import { getCurrentUser } from '../../lib/api/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
}));

const EgsMChargeNewForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleBack, handleChange, handleSubmit, value, buttonType, currentUser     } = props;  // currentUser

  const [open, setOpen] = React.useState(false);

  const [errorChargeCd, setErrorChargeCd] = useState(false);  // 担当者CD
  const [errorChargeName, setErrorChargeName] = useState(false);  // 担当者名
  const [errorChargeKana, setErrorChargeKana] = useState(false);  // ふりがな
  const [errorEmail, setErrorEmail] = useState(false);  // メールアドレス
  const [errorEmailFlg, setErrorEmailFlg] = useState(false);  // メールアドレス有効フラグ
  const [errorEffectiveflg, setErrorEffectiveflg] = useState(false);  // 有効フラグ
  const [errorEdiKbn, setErrorEdiKbn] = useState(false);  // EDI担当者区分

  const checkValidation = () => {

    let available = true;
    if (!value.traderName) {  // || !value.traderName) {    //  || !traderdataList.traderName) {
      // console.log('traderCd',value.traderCd,value.traderName); //,traderdataList.traderName);
//      err_msg = err_msg + '事業場CD ';
//      alert('事業場CDに誤りがあります')
      available = false;
    }else{
    }

//                                                   符号あり        /^-?[0-9]+$/
//                                                   符号なし        /^[0-9]+$/
// if (!value.chargeCd || value.chargeCd < 0 || !value.chargeCd.match(/^[0-9]+$/)) {
//  if (!value.chargeCd.match(/^-?[0-9]+$/)) {
   if (value.chargeCd < '001' || value.chargeCd > '99999') {
//      // console.log('chargeCd',value.chargeCd);
//      err_msg = err_msg + '担当者CD ';
      alert('担当者CDに誤りがあります')
      available = false;
      setErrorChargeCd(true);
    }else{
      setErrorChargeCd(false);
    }

    if (!value.chargeName ) {
//      err_msg = err_msg + '担当者名 ';
//      alert('担当者名に誤りがあります')
      available = false;
      setErrorChargeName(true);
    }else{
      setErrorChargeName(false);
    }

    if (!value.chargeKana ) {
//      err_msg = err_msg + 'ふりがな ';
//      alert('ふりがなに誤りがあります')
      available = false;
      setErrorChargeKana(true);
    }else{
      setErrorChargeKana(false);
    }

///    if (!value.email.match("^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$") ) {
//      alert('メールアドレスに誤りがあります')
///      available = false;
///      setErrorEmail(true);
///    }else{
///      setErrorEmail(false);
///    }

//    if (!value.emailFlg.match("0-1") ) {
///    if (value.emailFlg !== '0' && value.emailFlg !== '1') {
//      alert('メールアドレス有効フラグに誤りがあります')
///      available = false;
///      setErrorEmailFlg(true);
///    }else{
///      setErrorEmailFlg(false);
///    }

//    if (!value.ediKbn.match("01") ) {
    if (value.ediKbn < 0 || value.ediKbn > 1) {
//      alert('EDI区分誤りがあります')
      available = false;
      setErrorEdiKbn(true);
    }else{
      setErrorEdiKbn(false);
    }

  return available;
  }

  return (
    <>
      <form className={classes.root} noValidate autoComplete='off'>
       <table>

       <tr><th>事業場CD *</th>
         <td>
            <Typography variant="h6" style={{ marginTop: 10 }}>{value.traderCd}</Typography>
          </td>
        </tr>
        <tr><th>事業場名称</th><td>
            <Typography variant="h6" style={{ marginTop: 10 }}>{value.traderName}</Typography>
          </td></tr>

        <tr><th>担当者CD *</th><td>
          <TextField id='chargeCd' label='担当者CD' variant="outlined" type='number' name='chargeCd' halfWidth margin="dense"
            onChange={(e) => handleChange(e)}
            error={errorChargeCd}
            value={value.chargeCd}
            inputProps={{ maxLength: 5, pattern: "[1-9][0-9]*" }}  // maxLength: 3, pattern: "0-9"
            />
          </td>
        </tr>
        <tr><th>担当者名 *</th><td><TextField id='chargeName' label='担当者名' variant="outlined" type='text' name='chargeName' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeName}
          value={value.chargeName}
        /></td></tr>
        <tr><th>ふりがな *</th><td><TextField id='chargeKana' label='担当者名かな' variant="outlined" type='text' name='chargeKana' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorChargeKana}
          value={value.chargeKana}
        /></td></tr>
        <tr><th>メールアドレス</th><td><TextField id='email' label='メールアドレス' variant="outlined" type='text' name='email' fullWidth margin="dense"
          onChange={(e) => handleChange(e)}
          error={errorEmail}
          value={value.email}
          inputProps={{ maxLength: 50, pattern: "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$" }}  // maxLength: 3, pattern: "0-9"
        />
        </td></tr>
       </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
//          onClick={(e) => handleSubmit(e)}
          onClick={(e) => {
//          handleSubmit(e);
            if (checkValidation()) {
                handleSubmit(e);
               }
            }}
          style={{ marginRight: 10 }}
        >
          {buttonType}
        </Button>
{/*}
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleBack();
        }}>
          戻る
        </Button>
*/}
      </div>
    </>
  );
};
export default EgsMChargeNewForm;
