// /src/lib/api/receipt_adm_exhaust.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptAdmExhaustList = () => {
  return client.get('/receipt_adm_exhausts');
};

// 事業場選択一覧
export const getReceiptAdmExhaustUserDetail = (id) => {
  return client.get(`/receipt_adm_exhausts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptAdmExhaustUser = (id) => {
  return client.get(`/receipt_adm_exhausts/${id}/showuser`);
};


