import {
    PDFDownloadLink,
    PDFViewer,
    Page,
    View,
    Font,
    Document,
    StyleSheet
} from '@react-pdf/renderer';
import Text from "../pdf_components/atoms/Text";
import Title from "../pdf_components/molecules/Title";
import Link from "../pdf_components/atoms/Link";
import Br from "../pdf_components/atoms/Br";
import { Grid, Col } from "../pdf_components/atoms/Grid";
import {
    ListItem,
    PR,
    Exhaust,
    Middle,
    Fainal,
    Toxic,
    Kukan1Trans,
    Kukan1Desti,
    Kukan2Trans,
    Kukan2Desti,
    Kukan3Trans,
    Kukan3Desti,
    SyobunJyutaku,
    LastSyobunPlace
} from "../pdf_components/molecules/Base";

import fontRegular from '../../fonts/Nasu-Regular.ttf'  //ttfファイル参照
import fontBold from '../../fonts/Nasu-Bold.ttf'        //ttfファイル参照
import dayjs from 'dayjs';

  // Create Document Component
  function GarbageOldPdfForm(props) {
    const { currentUser,isOpen,doClose,dataList2 } = props;

    console.log("dataList2",dataList2);
    console.log("currentUser",currentUser);

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: 'Nasu-Regular',
    src: fontRegular
  });

  // フォント「ナス 太字」
  Font.register({
    family: 'Nasu-Bold',
    src: fontBold
  });
    // CSSスタイル定義
    const wk_styles = StyleSheet.create({
        text1: { fontSize: '20pt', fontFamily: 'Nasu-Regular' },
        text2: { fontSize: '10pt', fontFamily: 'Nasu-Regular' },
        text3: { fontSize: '7pt', fontFamily: 'Nasu-Regular' }
      });

  // Create styles
  const styles = StyleSheet.create({
    page: {
      marginLeft: 10,
      padding: 10,
      backgroundColor: "white",
      color: "#000000",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth*0.5, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    screen: {
        padding: 10
      },
  });
  const today = "出力日：" + dayjs().format('YYYY/MM/DD');
    return (
        <PDFViewer style={styles.viewer}>
        <Document>
        <Page size="A4" style={styles.page}>
        <View style={styles.screen}>
          <Title title="廃棄物実績報告（環境ガードシステム）" createDate= {today} />
          <Grid borderTop>
            <ListItem lable="最新状態" borderLeft borderRight>
            {dataList2.statusName}
            </ListItem>
            <ListItem lable="排出日" borderRight>
            {dataList2.exhaustDate}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <ListItem lable="マニフェスト番号" borderLeft borderRight>
            {dataList2.manifestNo}
            </ListItem>
            <ListItem lable="ゴミタグ番号" borderRight>
            {dataList2.garbageTag}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Exhaust>
              <View>
                <Text>
                  {dataList2.exhaustName}　　　　加入者番号：{dataList2.jwnetNo}
                </Text>
                <Text>
                  〒{dataList2.exhaustAddress}
                </Text>
                <Text>
                  TEL:{dataList2.exhaustPhone}　　　　　　　　　　排出担当者：{dataList2.exhaustChargeName}
                </Text>
              </View>
            </Exhaust>
          </Grid>
          <Grid borderTop>
            <ListItem lable="廃棄物種類" borderLeft borderRight>
            {dataList2.wasteKindName}
            </ListItem>
            <ListItem lable="廃棄物の数量"  borderRight>
            {dataList2.amount1}  ({dataList2.unitName})
            </ListItem>
          </Grid>
          <Grid borderTop>
          <ListItem lable="廃棄物名称" borderLeft borderRight>
            {dataList2.wasteName}
            </ListItem>
            <ListItem lable="荷姿の数量" borderRight>
            {dataList2.packingAmount1}  ({dataList2.packingName})
            </ListItem>
          </Grid>
          <Grid borderTop>
            <ListItem lable="処分方法" borderLeft borderRight>
            {dataList2.disposalMethodName}
            </ListItem>
            <ListItem lable="備考" borderRight>
            {dataList2.memo1}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Toxic>
              <View>
                <Text>
                {dataList2.toxicName01}　{dataList2.toxicName02}　{dataList2.toxicName03}　
                {dataList2.toxicName04}　{dataList2.toxicName05}　{dataList2.toxicName06}
                </Text>
              </View>
            </Toxic>
          </Grid>
          <Grid borderTop>
            <Middle>
              <View>
                <Text>
                  {dataList2.companyName}
                </Text>
                <Text>
                  〒{dataList2.companyZip} {dataList2.companyAddress}
                </Text>
              </View>
            </Middle>
          </Grid>
          <Grid borderTop>
            <Fainal>
              <View>
                <Text>
                {dataList2.lastProcessingTraderName}
                </Text>
                <Text>
                〒{dataList2.lastProcessingTraderAddress}
                </Text>
                <Text>
                TEL:{dataList2.lastProcessingPhone}　　　　　　　　　　処分担当者：{dataList2.lastProcessingChargeName}
                </Text>
              </View>
            </Fainal>
          </Grid>

          <Grid borderTop>
            <ListItem lable="最終処分の場所" borderLeft borderRight>
              {dataList2.lastReportName}
            </ListItem>
            <ListItem lable="メモ"  borderRight>
              {dataList2.memo}
            </ListItem>
          </Grid>

          <Grid borderTop>
            <Kukan1Trans>
              <View>
                <Text>
                  {dataList2.sect1TransportName}　　　　　　　　　　運搬方法：{dataList2.sect1TransportMethodName}　{dataList2.sect1CarNo}
                </Text>
                <Text>
                  〒{dataList2.sect1TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect1TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect1TransportChargeName}
                </Text>
              </View>
            </Kukan1Trans>
          </Grid>
          <Grid borderTop>
            <Kukan1Desti>
              <View>
                <Text>
                  {dataList2.destination1Name}
                </Text>
                <Text>
                  〒{dataList2.destination1Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination1Phone}　　　　　　　　　　荷受担当者：{dataList2.destination1ChargeName}
                </Text>
              </View>
            </Kukan1Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間１）運搬開始日" borderLeft borderRight>
            {dataList2.sect1At}
            </ListItem>
            <ListItem lable="（区間１）運搬終了日" borderRight>
            {dataList2.destination1At}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Kukan2Trans>
              <View>
                <Text>
                  {dataList2.sect2TransportName}　　　　　　　　　　運搬方法：{dataList2.sect2TransportMethodName}　{dataList2.sect2CarNo}
                </Text>
                <Text>
                  〒{dataList2.sect2TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect2TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect2TransportChargeName}
                </Text>
              </View>
            </Kukan2Trans>
          </Grid>
          <Grid borderTop>
            <Kukan2Desti>
              <View>
                <Text>
                  {dataList2.destination2Name}
                </Text>
                <Text>
                  〒{dataList2.destination2Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination2Phone}　　　　　　　　　　荷受担当者：{dataList2.destination2ChargeName}
                </Text>
              </View>
            </Kukan2Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間２）運搬開始日" borderLeft borderRight>
            {dataList2.sect2At}
            </ListItem>
            <ListItem lable="（区間２）運搬終了日" borderRight>
            {dataList2.destination2At}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Kukan3Trans>
              <View>
                <Text>
                  {dataList2.sect3TransportName}　　　　　　　　　　運搬方法：{dataList2.sect3TransportMethodName}　{dataList2.sect3CarNo}
                </Text>
                <Text>
                  〒{dataList2.sect3TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect3TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect3TransportChargeName}
                </Text>
              </View>
            </Kukan3Trans>
          </Grid>
          <Grid borderTop>
            <Kukan3Desti>
              <View>
                <Text>
                  {dataList2.destination3Name}
                </Text>
                <Text>
                  〒{dataList2.destination3Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination3Phone}　　　　　　　　　　荷受担当者：{dataList2.destination3ChargeName}
                </Text>
              </View>
            </Kukan3Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間３）運搬開始日" borderLeft borderRight>
            {dataList2.sect3At}
            </ListItem>
            <ListItem lable="（区間３）運搬終了日" borderRight>
            {dataList2.destination3At}
            </ListItem>
          </Grid>

          <Grid borderTop>
            <SyobunJyutaku>
              <View>
                <Text>
                  {dataList2.sect1TransportName}
                </Text>
                <Text>
                  〒{dataList2.sect1TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect1TransportPhone}
                </Text>
              </View>
            </SyobunJyutaku>
          </Grid>
          <Grid borderTop>
            <LastSyobunPlace>
              <View>
                <Text>
                　
                </Text>
                <Text>
                〒
                </Text>
                <Text>
                TEL:
                </Text>
              </View>
            </LastSyobunPlace>
          </Grid>
          <Grid borderTop borderBottom>
            <ListItem lable="中間処分終了日" borderLeft borderRight>
            {dataList2.middleEnd}
            </ListItem>
            <ListItem lable="最終処分終了日" borderRight>
            {dataList2.lastProcessingEnd}
            </ListItem>
          </Grid>
          {/* <Grid borderTop>
        <PR>
          <View>
            <Text>
              現在フリーランスのエンジニアとしてフロントエンドをメインに活動しています。
              他にもバックエンド、インフラ、ネィティブアプリと幅広く業務で活動しています。
            </Text>
            <Text>
              他にもバックエンド、インフラ、ネィティブアプリと幅広く業務で活動しています。
            </Text>
            <Br />
            <Text>
            {dataList2.exhaustAddress}
            </Text>
            <Br />
            <Text>
              github:<Link>https://github.com/wheatandcat</Link>
            </Text>
            <Text>
              npm:<Link>https://www.npmjs.com/~wheatandcat</Link>
            </Text>
            <Text>
              qiita:<Link>https://qiita.com/wheatandcat</Link>
            </Text>
            <Br />
            <Text>
              個人開発したWEBサービス: <Link>https://dotstamp.com/</Link>
            </Text>
            <View style={{ paddingLeft: 5 }}>
              <Text>github:</Text>
              <Text>
                ・<Link>https://github.com/wheatandcat/dotstamp_client</Link>
              </Text>
              <Text>
                ・<Link>https://github.com/wheatandcat/dotstamp_server</Link>
              </Text>
            </View>
            <Br />
            <Text>
              最近作ったexamples:{" "}
              <Link>https://github.com/wheatandcat/react-graphql-examples</Link>
            </Text>
            <View style={{ paddingLeft: 5 }}>
              <Text>
                ・使用技術：GCP、Firebase、GraphQL、react、react-native、appetize.io
              </Text>
            </View>
          </View>
        </PR>
      </Grid>

      <Grid borderTop borderBottom>
        <ListItem lable="勉強している技術" borderLeft borderRight size={5.15}>
          react、react-native、go言語、graphql、GCP
        </ListItem>
      </Grid> */}


      <Br />
          </View>
          </Page></Document>
          </PDFViewer>
    //   <PDFViewer style={styles.viewer}>
    //     {/* Start of the document*/}
    //     <Document>
    //       {/*render a single page*/}
    //       <Page size="A4" style={styles.page}>
    //         <View style={styles.section}>
    //             <Title title="廃棄物情報詳細" createDate="作成日: 2023年02月16日" />
    //             <Grid borderTop>
    //                 <ListItem lable="名前" borderLeft borderRight>
    //                 {currentUser.name}
    //                 </ListItem>
    //                 <ListItem lable="現住所(都道府県)" borderRight>
    //                 東京
    //                 </ListItem>
    //             </Grid>
    //             <Grid borderTop>
    //                 <ListItem lable="年齢" borderLeft borderRight>
    //                 29
    //                 </ListItem>
    //                 <ListItem lable="性別" borderRight>
    //                 男
    //                 </ListItem>
    //             </Grid>

    //             <Grid borderTop>

    //   </Grid>

    //   <Grid borderTop borderBottom>
    //     <ListItem lable="勉強している技術" borderLeft borderRight size={5.15}>
    //       react、react-native、go言語、graphql、GCP
    //     </ListItem>
    //   </Grid>
    //   <Br />





    //         </View>
    //         <View style={styles.section}>
    //           <Text>World</Text>
    //           <Text style={wk_styles.text1}>大きい文字列です {currentUser.nickname}</Text>
    //         </View>
    //         <View style={{textAlign: 'center', position: 'absolute', top: '200px', left: '35px', width: '40px', height: '32px', borderWidth: '0.2mm 0.2mm 0.2mm 0.2mm', borderStyle: 'solid solid solid solid'}}>

    //             <Text style={wk_styles.text2}>ふつうの文字列です</Text>
    //             <Text style={wk_styles.text3}>小さい文字列です</Text>
    //       </View>
    //       </Page>
    //     </Document>
    //   </PDFViewer>
    );
  }
  export default GarbageOldPdfForm;
