import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import './app.css';
import dayjs from 'dayjs';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import FormControl from '@mui/material/FormControl';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '積込登録',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function ReceiptLoadListTable(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

//  const { dataList, dataListC00, currentUser } = props;
  const { dataList, dataListC00, dataListW00, dataListE00, dataListT00, currentUser } = props;  // wk_charge_id
  const history = useHistory();
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  // console.log('dataListW00',dataListW00);
  // console.log('dataListE00',dataListE00);
  // console.log('dataListT00',dataListT00);
  const [pageSize, setPageSize] = React.useState(10);
  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    exhaustNameFilter: '',
    sect1TransportNameFilter: '',
    wasteNameFilter1: '',
    wasteNameFilter2: '',
    wasteNameFilter3: '',
  })

  const today = dayjs().format('YYYY-MM-DD');
  const wk_today = today;
  // console.log('today',today);
//  value.receiptDate = today;

  const [value, setValue] = useState({
    receiptDate:wk_today, // '',
    receiptChargeId:'',
    receiptChargeCd: '',   // 20240418
    receiptChargeName: '', // 20240418
  });

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList);
    // console.log('currentUser', currentUser);
    let originalList = dataList;

    // console.log('originalList', originalList);
    // console.log('filter', filter);

    if (filter.exhaustDateStart) {
      const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustDateEnd) {
      const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustNameFilter) {
      originalList = originalList.filter(item => item.exhaustName === filter.exhaustNameFilter);
    }

    if (filter.wasteNameFilter1) {
      originalList = originalList.filter(item => item.wasteName === filter.wasteNameFilter1);
    }

    if (filter.sect1TransportNameFilter) {
      originalList = originalList.filter(item => item.sect1TransportName === filter.sect1TransportNameFilter);
    }

    setFilteredDataList(originalList);
  }

  const handleChange = (e) => {

    if (e.target.name == 'receiptChargeId') {
      value.receiptChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240418
      value.receiptChargeName = e.currentTarget.innerText.split(':')[1]  // 20240418
    }

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue',value);

  const handleSubmit = async (e) => {
    //e.preventDefault();
    if (window.confirm(' 積込登録を行います。よろしいですか？')) {

      // console.log('selRows',selRows);
      var params = {};

      for (let i = 0; i<selRows.current.length; i++){
        const resdataList = filteredDataList.filter(filteredDataList => {
          return filteredDataList.id === selRows.current[i]
        })
        // console.log('resdataList',resdataList)
        // console.log('resdataList.nextstatus',resdataList[0].nextStatus)

        if (currentUser.traderKbn == '1111111111') {
          if (resdataList[0].nextStatus == "10"){
            params = {
              status: resdataList[0].nextStatus,
              sect1_at: value.receiptDate,
//              sect1_transport_charge_id: value.receiptChargeId,
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }else if (resdataList[0].nextStatus == "20"){
            params = {
              status: resdataList[0].nextStatus,
              sect2_at: value.receiptDate,
//              sect2_transport_charge_id: value.receiptChargeId,
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }else if (resdataList[0].nextStatus == "30"){
            params = {
              status: resdataList[0].nextStatus,
              sect3_at: value.receiptDate,
//              sect3_transport_charge_id: value.receiptChargeId,
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }else if (resdataList[0].nextStatus == "40"){
            params = {
              status: resdataList[0].nextStatus,
              sect4_at: value.receiptDate,
//              sect4_transport_charge_id: value.receiptChargeId,
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }else if (resdataList[0].nextStatus == "50"){
            params = {
              status: resdataList[0].nextStatus,
              sect5_at: value.receiptDate,
//              sect5_transport_charge_id: value.receiptChargeId,
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }else if (resdataList[0].nextStatus == "60"){
            params = {
              status: resdataList[0].nextStatus,
              middle_start: dayjs().format('YYYY-MM-DD'),
  //            edi_send_flg: 1,        EDI送信フラグは更新不要
            };
          }
        }else{
            if (resdataList[0].nextStatus == "10"){
              params = {
                status: resdataList[0].nextStatus,
                sect1_at: value.receiptDate,
                sect1_transport_charge_id: value.receiptChargeId,
                sect1_transport_charge_cd: value.receiptChargeCd,     // 20240418
                sect1_transport_charge_name: value.receiptChargeName, // 20240418
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }else if (resdataList[0].nextStatus == "20"){
              params = {
                status: resdataList[0].nextStatus,
                sect2_at: value.receiptDate,
                sect2_transport_charge_id: value.receiptChargeId,
                sect2_transport_charge_cd: value.receiptChargeCd,     // 20240418
                sect2_transport_charge_name: value.receiptChargeName, // 20240418
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }else if (resdataList[0].nextStatus == "30"){
              params = {
                status: resdataList[0].nextStatus,
                sect3_at: value.receiptDate,
                sect3_transport_charge_id: value.receiptChargeId,
                sect3_transport_charge_cd: value.receiptChargeCd,     // 20240418
                sect3_transport_charge_name: value.receiptChargeName, // 20240418
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }else if (resdataList[0].nextStatus == "40"){
              params = {
                status: resdataList[0].nextStatus,
                sect4_at: value.receiptDate,
                sect4_transport_charge_id: value.receiptChargeId,
                sect4_transport_charge_cd: value.receiptChargeCd,     // 20240418
                sect4_transport_charge_name: value.receiptChargeName, // 20240418
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }else if (resdataList[0].nextStatus == "50"){
              params = {
                status: resdataList[0].nextStatus,
                sect5_at: value.receiptDate,
                sect5_transport_charge_id: value.receiptChargeId,
                sect5_transport_charge_cd: value.receiptChargeCd,     // 20240418
                sect5_transport_charge_name: value.receiptChargeName, // 20240418
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }else if (resdataList[0].nextStatus == "60"){
              params = {
                status: resdataList[0].nextStatus,
                middle_start: dayjs().format('YYYY-MM-DD'),
    //            edi_send_flg: 1,        EDI送信フラグは更新不要
              };
            }
          }
        // console.log('selRows.current[i]',selRows.current[i]);
        // console.log('params',params);
      try {
       setOpen(!open);
        const res = await updateEgsTGarbageEdi(selRows.current[i], params);
        // console.log(res);
       setOpen(false);
      } catch (e) {
        console.log(e);
      }
      }
      if (window.confirm(' 積込登録を行いました。廃棄物一覧を表示しますか？')) {
        history.push('/'); // 画面更新
      }else{
        window.location.reload();
      }
    }
  };

//  if (currentUser.traderKbn == '1111111111') {
//  }else{
//  }


 const [errorReceiptDate, setErrorReceiptDate] = useState(false);  // 積込日
 const [errorReceiptCharge, setErrorReceiptCharge] = useState(false);  // 積込担当

 const checkValidation = () => {
   let available = true;

   // 行選択チェック
     if (selRows.current.length == 0) {
       alert('更新対象を選択してください')
       available = false;
     }

   // 積込日チェック
     if (value.receiptDate > wk_today) {
       alert('積込日が未来日です <' + value.receiptDate + '>')
         available = false;
         setErrorReceiptDate(true);
     } else {
         if (!value.receiptDate) {
           alert('積込日が未入力です')
           available = false;
           setErrorReceiptDate(true);
         }else{
           setErrorReceiptDate(false);
         }
     }

//      if (!available) {
//        alert('入力に誤りがあります')
//      }

   // 積込担当チェック
     if (currentUser.traderKbn !== '1111111111' && value.receiptChargeId == 0) {
         alert('積込担当を選択してください')
         available = false;
         setErrorReceiptCharge(true);
     }

//      if (!available) {
//          alert('入力に誤りがあります')
//      }

     return available;
 }


if (currentUser.traderKbn == '1111111111') {

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業場',
      width: 250
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 250
    },
    {
      field: 'wasteName',
      headerName: '廃棄物名称',
      width: 150
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width:100
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      width: 300
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '運搬担当者',
      width: 100
    },
    {
      field: 'amount1',
      headerName: '数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業場',
      width: 300
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: true,
      width: 80
    },
    {
      field: 'statusName',
      headerName: '状態',
      hide: true,
      width: 120
    },
    {
      field: 'nextStatus',
      headerName: '次Status',
      hide: true,
      width: 80
    },
 ]

  return (
    <>
      <Grid
        container
        spacing={2}
      >
{/*}
        <Grid item xs={1}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出期間：
            </Typography>
          </Box>
        </Grid>
*/}

        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "left",  // center
          justifyContent: "flex-start",
        }}>
{/*}
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateStart}
            onChange={e => setFilter({
              ...filter,
              exhaustDateStart: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateEnd}
            onChange={e => setFilter({
              ...filter,
              exhaustDateEnd: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          <TextField id="exhaustDate-start" label="" type={"date"} value={filter.exhaustDateStart} onChange={e => setFilter({...filter,exhaustDateStart: e.target.value })} className={classes.textField} InputLabelProps={{shrink: true,}} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>～</Typography>
          <TextField id="exhaustDate-end" label="" type={"date"} value={filter.exhaustDateEnd} onChange={e => setFilter({...filter,exhaustDateEnd: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
{/*}          <TextField id="exhaustNameFilter" label="" type={"text"} value={filter.exhaustNameFilter} onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

             <FormControl sx={{ minWidth: 250 }}>
              <Select
                id='exhaustNameFilter'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.exhaustNameFilter}
                value={filter.exhaustNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListE00.map((dataListE00) => (
                  <MenuItem
                    key={dataListE00.exhaustName}
                    value={dataListE00.exhaustName}
                  >
                    {dataListE00.exhaustCd}:{dataListE00.exhaustName}  {/*{dataListE00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
{/*}          <TextField id="wasteNameFilter1" label="" type={"text"} value={filter.wasteNameFilter1} onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

             <FormControl sx={{ minWidth: 300 }}>
              <Select
                id='wasteNameFilter1'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteNameFilter1'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.wasteNameFilter1}
                value={filter.wasteNameFilter1}
//                defaultValue={wk_charge_id}
              >
                {dataListW00.map((dataListW00) => (
                  <MenuItem
                    key={dataListW00.wasteclassName}
                    value={dataListW00.wasteclassName}
                  >
                    {dataListW00.wasteclassCd}:{dataListW00.wasteclassName}  {/*{dataListW00.wasteclassCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　収集運搬業者：</Typography>
{/*}          <TextField id="sect1TransportNameFilter" label="" type={"text"} value={filter.sect1TransportNameFilter} onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

             <FormControl sx={{ minWidth: 250 }}>
              <Select
                id='sect1TransportNameFilter'
                label='収集運搬業者'
                variant="outlined"
                type='text'
                name='sect1TransportNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.sect1TransportNameFilter}
                value={filter.sect1TransportNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListT00.map((dataListT00) => (
                  <MenuItem
                    key={dataListT00.sect1TransportName}
                    value={dataListT00.sect1TransportName}
                  >
                    {dataListT00.sect1TransportCd}:{dataListT00.sect1TransportName}  {/*{dataListT00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>

          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              startFilter();
            }}
            style={{ marginLeft: 24 }}
          >
            検索
          </Button>
        </Grid>
      </Grid>

    <div className={classes.container}>
    <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
//          pageSize={pageSize}
//          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//          rowsPerPageOptions={[10, 20, 50]}
//          pagination
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}
          density="compact"
//          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
        </Box>
      </div>
      <h3>　↑ 積込対象の廃棄物を選択（ㇾ）して下さい</h3>
      <SpaceRow height={20} />
      <form className={classes.root}>
        <table>
          <tr>
          </tr><tr>
          </tr><tr>
             <FormControl sx={{ minWidth: 100 ,marginTop: 2 }}>
              <th>積込日</th>
            </FormControl>
            <td>
             <FormControl sx={{ minWidth: 120 ,marginLeft: -54 }}>
              <TextField id='receiptDate' variant="outlined" type='date' defaultValue={value.receiptDate} name='receiptDate' halfWidth margin="dense" value={value.receiptDate}
                onChange={(e) => handleChange(e)}
                error={errorReceiptDate}
                />
             </FormControl>
            </td>
{/*}
            <th>積込担当者</th>
            <td>
            <Select
                id='receiptChargeId'
                label='積込担当者'
                variant="outlined"
                type='text'
                name='receiptChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.receiptChargeId}
              >
                {dataListC00.map((dataListC00) => (   // chargedataList00
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
            </td>
*/}
          </tr>
        </table>
      </form>

        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button
           variant="contained"
           color='primary'
//           onClick={(e) => handleSubmit()}
           onClick={(e) => {
//            handleSubmit(e);
              if (checkValidation()) {
                  handleSubmit(e);
                  }
              }}
//           disabled={!selRows.current.length}
           >
            積込実行
          </Button>
        </Box>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>

  );
}else{

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業場',
      width: 250
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 250
    },
    {
      field: 'wasteName',
      headerName: '廃棄物名称',
      width: 150
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width:100
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '運搬担当者',
      width: 100
    },
    {
      field: 'amount1',
      headerName: '数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業場',
      width: 300
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: true,
      width: 80
    },
    {
      field: 'statusName',
      headerName: '状態',
      hide: true,
      width: 120
    },
    {
      field: 'nextStatus',
      headerName: '次Status',
      hide: true,
      width: 80
    },
 ]

  return (
    <>
      <Grid
        container
        spacing={2}
      >
{/*}
        <Grid item xs={1}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出期間：
            </Typography>
          </Box>
        </Grid>
*/}
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "left", // center
          justifyContent: "flex-start",
        }}>
{/*}
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateStart}
            onChange={e => setFilter({
              ...filter,
              exhaustDateStart: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateEnd}
            onChange={e => setFilter({
              ...filter,
              exhaustDateEnd: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          <TextField id="exhaustDate-start" label="" type={"date"} value={filter.exhaustDateStart} onChange={e => setFilter({...filter,exhaustDateStart: e.target.value })} className={classes.textField} InputLabelProps={{shrink: true,}} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>～</Typography>
          <TextField id="exhaustDate-end" label="" type={"date"} value={filter.exhaustDateEnd} onChange={e => setFilter({...filter,exhaustDateEnd: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
{/*}          <TextField id="exhaustNameFilter" label="" type={"text"} value={filter.exhaustNameFilter} onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

             <FormControl sx={{ minWidth: 250 }}>
              <Select
                id='exhaustNameFilter'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.exhaustNameFilter}
                value={filter.exhaustNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListE00.map((dataListE00) => (
                  <MenuItem
                    key={dataListE00.exhaustName}
                    value={dataListE00.exhaustName}
                  >
                    {dataListE00.exhaustCd}:{dataListE00.exhaustName}  {/*{dataListE00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
{/*}          <TextField id="wasteNameFilter1" label="" type={"text"} value={filter.wasteNameFilter1} onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

             <FormControl sx={{ minWidth: 250 }}>
              <Select
                id='wasteNameFilter1'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteNameFilter1'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.wasteNameFilter1}
                value={filter.wasteNameFilter1}
//                defaultValue={wk_charge_id}
              >
                {dataListW00.map((dataListW00) => (
                  <MenuItem
                    key={dataListW00.wasteclassName}
                    value={dataListW00.wasteclassName}
                  >
                    {dataListW00.wasteclassCd}:{dataListW00.wasteclassName}  {/*{dataListW00.wasteclassCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　収集運搬業者：</Typography>
          <TextField id="sect1TransportNameFilter" label="" type={"text"} value={filter.sect1TransportNameFilter} onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
*/}
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              startFilter();
            }}
            style={{ marginLeft: 24 }}
          >
            検索
          </Button>
        </Grid>
      </Grid>

    <div className={classes.container}>
    <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
//          pageSize={pageSize}
//          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//          rowsPerPageOptions={[10, 20, 50]}
//          pagination
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}
          density="compact"
//          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
        </Box>
      </div>
      <h3>　↑ 積込対象の廃棄物を選択（ㇾ）して下さい</h3>
      <SpaceRow height={20} />
      <form className={classes.root}>
        <table>
          <tr>
          </tr><tr>
          </tr><tr>
             <FormControl sx={{ minWidth: 100 ,marginTop: 2 }}>
            <th>積込日</th>
              </FormControl>
            <td>
             <FormControl sx={{ minWidth: 120 }}>
              <TextField id='receiptDate' variant="outlined" type='date' defaultValue={value.receiptDate} name='receiptDate' halfWidth margin="dense" value={value.receiptDate}
                onChange={(e) => handleChange(e)}
                error={errorReceiptDate}
                />
             </FormControl>
            </td>
             <FormControl sx={{ minWidth: 120 ,marginTop: 2 }}>
              <th>積込担当者</th>
            </FormControl>
            <td>
             <FormControl sx={{ minWidth: 150 }}>
              <Select
                id='receiptChargeId'
                label='積込担当者'
                variant="outlined"
                type='text'
                name='receiptChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                error={errorReceiptCharge}
                value={value.receiptChargeId}
              >
                {dataListC00.map((dataListC00) => (   // chargedataList00
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>
            </td>
          </tr>
        </table>
      </form>

        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button
           variant="contained"
           color='primary'
//           onClick={(e) => handleSubmit()}
           onClick={(e) => {
//            handleSubmit(e);
              if (checkValidation()) {
                  handleSubmit(e);
                  }
              }}
//           disabled={!selRows.current.length}
            >
            積込実行
          </Button>
        </Box>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>

  );
}

}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default ReceiptLoadListTable;
