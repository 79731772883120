import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { updateEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import { getAddressZip } from '../../lib/api/address';
import FormBody from './EgsMTraderForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMTraderEdit({ isOpen, doClose, processId, dataList2, currentUser }) {
  const [loading, setLoading] = useState(false);
  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  const [value, setValue] = useState({
    id: '',
    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    certifyId: '',
    traderKbn: '',
    traderName: '',
    traderKana: '',
    traderDispName: '',
    jwnetNo: '',
    jwnetEdipass: '',
    jwnetSubno: '',
    exhaustPasswd: '',
    prefectureCd: '',
    zip: '',
    address: '',
    address2: '',
    address3: '',
    address4: '',

    zipAddress: '',
    zipAddress2: '',
    zipAddress3: '',

    phone: '',
    fax: '',
    email1: '',
    email1Flg: '',
    email2: '',
    email2Flg: '',
    email3: '',
    email3Flg: '',
    memo: '',
    egsUserFlg: 0,
    ediClientKbn: '',
    effectiveflg: '',
    chargeDivision: '',
    mkKbn3000: '',

    rawpasswd: '',
    groupCd1: '',
    groupCd2: '',
    groupCd3: '',
    groupCd4: '',
    groupCd5: '',
    groupId1: 0,
    groupId2: 0,
    groupId3: 0,
    groupId4: 0,
    groupId5: 0,
  });


  const zipChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if (e.target.value != null && e.target.value.length == 7) {
        try {
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

        value.address = dataListAddress.data[0].address2
        value.address2 = dataListAddress.data[0].address3
        value.prefectureCd = dataListAddress.data[0].prefectureCd

        value.zipAddress = dataListAddress.data[0].address1
        value.zipAddress2 = dataListAddress.data[0].address2
        value.zipAddress3 = dataListAddress.data[0].address3

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }

    }

  };

  useEffect(() => {
    if (processId != undefined){
      handleGetData(processId);
    }
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
//      const res = await getEgsMTraderDetail(processId);
      // console.log(res.data);
      setValue({
        id: dataList2.id,
        companyCd: dataList2.companyCd,  // res.data.companyCd
        egsMCompanyId: dataList2.egsMCompanyId,
        traderCd: dataList2.traderCd,
        certifyId: dataList2.certifyId,
        traderKbn: dataList2.traderKbn,
        traderName: dataList2.traderName,
        traderKana: dataList2.traderKana,
        traderDispName: dataList2.traderDispName,
        jwnetNo: dataList2.jwnetNo,
        jwnetEdipass: dataList2.jwnetEdipass,
        jwnetSubno: dataList2.jwnetSubno,
        exhaustPasswd: dataList2.exhaustPasswd,
        prefectureCd: dataList2.prefectureCd,
        zip: dataList2.zip,
        address: dataList2.address,
        address2: dataList2.address2,
        address3: dataList2.address3,
        address4: dataList2.address4,
        phone: dataList2.phone,
        fax: dataList2.fax,
        email1: dataList2.email1,
        email1Flg: dataList2.email1Flg,
        email2: dataList2.email2,
        email2Flg: dataList2.email2Flg,
        email3: dataList2.email3,
        email3Flg: dataList2.email3Flg,
        memo: dataList2.memo,
        egsUserFlg: dataList2.egsUserFlg,
        ediClientKbn: dataList2.ediClientKbn,
        effectiveflg: dataList2.effectiveflg,
        chargeDivision: dataList2.chargeDivision,
        mkKbn3000: dataList2.mkKbn3000,

        rawpasswd: dataList2.rawpasswd,
        groupCd1: dataList2.groupCd1,
        groupCd2: dataList2.groupCd2,
        groupCd3: dataList2.groupCd3,
        groupCd4: dataList2.groupCd4,
        groupCd5: dataList2.groupCd5,
        groupId1: dataList2.groupId1,
        groupId2: dataList2.groupId2,
        groupId3: dataList2.groupId3,
        groupId4: dataList2.groupId4,
        groupId5: dataList2.groupId5,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    if (window.confirm(' 事業場マスタ「' + value.traderCd + '」を更新します。よろしいですか？')) {
      try {
        const res = await updateEgsMTrader(processId, params);
        // console.log(res);
        handleCancel();
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd,
      certifyId: value.certifyId,
      traderKbn: value.traderKbn,
      traderName: value.traderName,
      traderKana: value.traderKana,
      traderDispName: value.traderDispName,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      prefectureCd: value.prefectureCd,
      zip: value.zip,
      address: value.address,
      address2: value.address2,
      address3: value.address3,
      address4: value.address4,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      email1Flg: value.email1Flg,
      email2: value.email2,
      email2Flg: value.email2Flg,
      email3: value.email3,
      email3Flg: value.email3Flg,
      memo: value.memo,
      egsUserFlg: value.egsUserFlg,
      ediClientKbn: value.ediClientKbn,
      effectiveflg: value.effectiveflg,
      chargeDivision: value.chargeDivision,
      mkKbn3000: value.mkKbn3000,

      rawpasswd: value.rawpasswd,
      groupCd1: value.groupCd1,
      groupCd2: value.groupCd2,
      groupCd3: value.groupCd3,
      groupCd4: value.groupCd4,
      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>事業場マスタ編集</h2>
        <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleCancel();
        }}>    {/*  egs_m_traders_adm  */}
          戻る
        </Button>
      </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          zipChange={zipChange}
          currentUser={currentUser}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMTraderEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
