// /src/lib/api/demand_b_kensu0930_edi.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getDemandBKensu0930EdiList = () => {
  return client.get('/demand_b_kensu0930_edis');
};

// 詳細
export const getDemandBKensu0930EdiDetail = (id) => {
  return client.get(`/demand_b_kensu0930_edis/${id}`);
};

// 月指定
export const getDemandBKensu0930EdiMonth = (id) => {
  return client.get(`/demand_b_kensu0930_edis/${id}/showmonth`);
};

// 検索条件指定
export const getDemandBKensu0930EdiOpe = (id) => {
  return client.get(`/demand_b_kensu0930_edis/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getDemandBKensu0930EdiUser = (id) => {
  return client.get(`/demand_b_kensu0930_edis/${id}/showuser`);
};

