import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import { updateEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import { createEgsMTrader, getEgsMTraderDetail } from '../../lib/api/egs_m_trader';
import { getEgsMCompanyDetail } from '../../lib/api/egs_m_company';
import { getPasswdList } from '../../lib/api/passwd'; 
import { getAddressZip } from '../../lib/api/address';
//import FormBody from './EgsMTraderAdmForm';
import FormBody from './EgsMTraderNewForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMTraderNew({ isOpen, doClose, processId, dataList2  }) {
  const [loading, setLoading] = useState(false);
  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}

  const [dataListPasswd1,setDataListPasswd1] = useState([]);  {/* 承認コード */}
  const [dataListPasswd2,setDataListPasswd2] = useState([]);  {/* 生1パスワード */}

  const [value, setValue] = useState({
//    id: '',
    companyCd: '',
    egsMCompanyId: 0,
    traderCd: '',
    traderCd8: '',
    nextTrader: '',
    traderCd2: '',
    certifyId: '',
    traderKbn: '',
    companyName: '',
    traderName: '', //dataList2.companyName,  //'',
    traderKana: '',
    traderDispName: '',
    jwnetNo: '',
    jwnetEdipass: '',
    jwnetSubno: '',
    exhaustPasswd: '',
    prefectureCd: '',
    zip: '',
    address: '',
    address2: '',
    address3: '',
    address4: '',
    phone: '',
    fax: '',
    email1: '',
    email1Flg: '',
    email2: '',
    email2Flg: '',
    email3: '',
    email3Flg: '',
    memo: '',
    egsUserFlg: 0,
    ediClientKbn: '',
    effectiveflg: '',
    chargeDivision: '',
    mkKbn3000: '',

    rawpasswd: '',
    groupCd1: '',
    groupCd2: '',
    groupCd3: '',
    groupCd4: '',
    groupCd5: '',
    groupId1: 0,
    groupId2: 0,
    groupId3: 0,
    groupId4: 0,
    groupId5: 0,

    message1: 'aaa',

    zipAddress: '',
    zipAddress2: '',
    zipAddress3: '',

  });

  useEffect(() => {
    handleGetData(processId);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  
  const handleGetData = async (processId) => {
    try {
      setLoading(true);

        const dataListPasswd1 = await getPasswdList();  // 承認コード
        setDataListPasswd1(dataListPasswd1.data);

        const dataListPasswd2 = await getPasswdList();  // 生パスワード
        setDataListPasswd2(dataListPasswd2.data);

//        const dataListAddress = await getAddressZip();
//        setDataListAddress(dataListAddress.data);


//        value.jwnetEdipass = dataListPasswd.data[0].passwd
/*
        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }
*/        
//      const res = await getEgsMTraderDetail(processId);
//      const res = await getEgsMCompanyDetail(processId);
      // console.log(res.data);
      setValue({

        id: dataList2.id,
        companyCd: dataList2.companyCd,  // res.data.companyCd,
        egsMCompanyId: dataList2.id,
        traderCd8: dataList2.companyCd.substring(0,8),
        nextTrader: dataList2.nextTrader,  //res.data.nextTrader,
        traderCd2: '10',
//        traderCd: res.data.companyCd.substring(0,8) + '0110',
//        traderCd: res.data.companyCd.substring(0,8) + '0000',
//        traderCd: value.traderCd8 + value.nextTrader + value.traderCd2,
        traderCd: dataList2.companyCd.substring(0,8) + dataList2.nextTrader + '10',
        certifyId: '',  // res.data.certifyId,
        traderKbn: dataList2.traderKbn,
        companyName: dataList2.companyName,
        traderName: dataList2.companyName,
        traderKana: dataList2.companyKana,
        traderDispName: dataList2.companyName,
        jwnetNo: dataList2.jwnetNo,
        jwnetEdipass: dataList2.jwnetEdipass,
        jwnetSubno: '',  // res.data.jwnetSubno,
        exhaustPasswd: dataListPasswd1.data[0].passwd,  //'',  // res.data.exhaustPasswd,
        prefectureCd: dataList2.prefectureCd,
        zip: dataList2.zip,
        address: dataList2.address,
        address2: dataList2.address2,
        address3: dataList2.address3,
        address4: dataList2.address4,

//        zipAddress: dataListAddress.data[0].address1, //res.data.address,
//        zipAddress2: dataListAddress.data[0].address2, //res.data.address2,
//        zipAddress3: dataListAddress.data[0].address3, //res.data.address3,

        phone: dataList2.phone,
        fax: dataList2.fax,
        email1: dataList2.email,
        email1Flg: '0',  // res.data.email1Flg,
        email2: '',  // res.data.email2,
        email2Flg: '0',  // res.data.email2Flg,
        email3: '',  // res.data.email3,
        email3Flg: '0',  // res.data.email3Flg,
        memo: '',  // res.data.memo,
        egsUserFlg: '0',  // res.data.egsUserFlg,
        ediClientKbn: '0',  // res.data.ediClientKbn,
        effectiveflg: '0',  // res.data.effectiveflg,
        chargeDivision: '1',  // res.data.chargeDivision,
        mkKbn3000: '',  // res.data.mkKbn3000,

        rawpasswd: dataListPasswd2.data[0].passwd,  //'',  // res.data.rawpasswd,
        groupCd1: dataList2.groupCd1,
        groupCd2: dataList2.groupCd2,
        groupCd3: dataList2.groupCd3,
        groupCd4: dataList2.groupCd4,
        groupCd5: dataList2.groupCd5,
        groupId1: dataList2.groupId1,
        groupId2: dataList2.groupId2,
        groupId3: dataList2.groupId3,
        groupId4: dataList2.groupId4,
        groupId5: dataList2.groupId5,

      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };


  const handleChange = (e) => {

//    let str = "あいうえお";
/*
    // 全角文字チェック
    if (value.email1.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)) {
      //全角文字
      // console.log("全角文字です",value.email1,'>');  // alert
      value.message1 = "全角文字です"
//      setErrorEmail1(true);
    } else {
      //全角文字以外
      // console.log("全角文字ではありません",value.email1,'>');
      value.message1 = "全角文字ではありません"
//      setErrorEmail1(false);
    }
*/

//let value.traderCd2 = '0110';
/*
  switch (true) {
    case value.traderKbn == '1000000000':
//        alert('1111111111');
        value.traderCd2 = '0110';
        break;
    case value.traderKbn == '0100000000':
        value.traderCd2 = '0120';
        break;
    case value.traderKbn == '0010000000':
        value.traderCd2 = '0125';
        break;
    case value.traderKbn == '0001000000':
    case value.traderKbn == '0001001000':
        value.traderCd2 = '0130';
        break;
    case value.traderKbn == '0000100000':
        value.traderCd2 = '0140';
        break;
    default:
//        alert('else');
        value.traderCd2 = '0000';
        break;
  }
*/
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

/*
  const handleChange2 = (e) => {
    handleChange(e);
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
        value.traderCd = value.jwnetNo;
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

  };
*/

const kbnChange = async (e) => {

  setValue({
    ...value,
    [e.target.name]: e.target.value,
  });

  switch (e.target.value) {  // value.traderKbn
    case  '1000000000':  // value.traderKbn
//        alert('1111111111');
        value.traderCd2 = '10';  // 0110
        break;
    case '0100000000':
        value.traderCd2 = '20';
        break;
    case '0010000000':
        value.traderCd2 = '25';
        break;
    case '0001000000':
    case '0001001000':
        value.traderCd2 = '30';
        break;
    case '0000100000':
        value.traderCd2 = '40';
        break;
    default:
//        alert('else');
        value.traderCd2 = '00';
        break;

  }
    value.traderCd = value.traderCd8 + value.nextTrader + value.traderCd2;        

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

};


const prefChange = async (e) => {

  setValue({
    ...value,
    [e.target.name]: e.target.value,
  });
/*
//    if (value.zip != null && value.zip.length == 7) {
  if (e.target.value != null && e.target.value.length == 7) {
      try {
//        const dataListAddress = await getEgsMAddressZip(value.zip);
//        const dataListAddress = await getEgsMAddressZip(8410025);
//        const dataListAddress = await getEgsMAddressZip(e.target.value);
      const dataListAddress = await getAddressZip(e.target.value);
      setDataListAddress(dataListAddress.data);

      value.address = dataListAddress.data[0].address1
      value.address2 = dataListAddress.data[0].address2
      value.address3 = dataListAddress.data[0].address3
      value.prefectureCd = dataListAddress.data[0].prefectureCd

      value.zipAddress = dataListAddress.data[0].address1
      value.zipAddress2 = dataListAddress.data[0].address2
      value.zipAddress3 = dataListAddress.data[0].address3

//      const dataListNewCd = await getCompanyCdNewPref(value.prefectureCd);
//      setDataListNewCd(dataListNewCd.data);

//      value.companyCd = dataListNewCd.data[0].companyCdNew
//      value.jwnetEdipass = dataListNewCd.data[0].passwd1

      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
  
      } catch (e) {
      console.log(e);
    }

  }
*/
};

const zipChange = async (e) => {

  setValue({
    ...value,
    [e.target.name]: e.target.value,
  });

//    if (value.zip != null && value.zip.length == 7) {
  if (e.target.value != null && e.target.value.length == 7) {
      try {
//        const dataListAddress = await getEgsMAddressZip(value.zip);
//        const dataListAddress = await getEgsMAddressZip(8410025);
//        const dataListAddress = await getEgsMAddressZip(e.target.value);
      const dataListAddress = await getAddressZip(e.target.value);
      setDataListAddress(dataListAddress.data);

//      value.address = dataListAddress.data[0].address1
//      value.address2 = dataListAddress.data[0].address2
//      value.address3 = dataListAddress.data[0].address3
      value.address = dataListAddress.data[0].address2
      value.address2 = dataListAddress.data[0].address3
      value.prefectureCd = dataListAddress.data[0].prefectureCd

      value.zipAddress = dataListAddress.data[0].address1
      value.zipAddress2 = dataListAddress.data[0].address2
      value.zipAddress3 = dataListAddress.data[0].address3

//      const dataListNewCd = await getCompanyCdNewPref(value.prefectureCd);
//      setDataListNewCd(dataListNewCd.data);

//      value.companyCd = dataListNewCd.data[0].companyCdNew
//      value.jwnetEdipass = dataListNewCd.data[0].passwd1

      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
  
      } catch (e) {
      console.log(e);
    }

  }

};


  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();

    if (window.confirm(' 事業場マスタ「' + value.traderCd + '」を新規登録します。よろしいですか？')) {
      try {
//      const res = await updateEgsMTrader(processId, params);
        const res = await createEgsMTrader(params);
        // console.log('res',res);
        handleCancel();
//        history.push('/egs_m_trader_adm');
//        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }

/*
    try {
//      const res = await updateEgsMTrader(processId, params);
      const res = await createEgsMTrader(params);
      // console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
*/
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd8 + value.nextTrader + value.traderCd2,    //value.traderCd,
//      traderCd: value.jwnetNo,  // traderCd
      certifyId: value.traderCd,  // certifyId,
      traderKbn: value.traderKbn,
      traderName: value.traderName,
      traderKana: value.traderKana,
      traderDispName: value.traderDispName,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      prefectureCd: value.prefectureCd,
      zip: value.zip,
      address: value.address,
      address2: value.address2,
      address3: value.address3,
      address4: value.address4,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      email1Flg: value.email1Flg,
      email2: value.email2,
      email2Flg: value.email2Flg,
      email3: value.email3,
      email3Flg: value.email3Flg,
      memo: value.memo,
      egsUserFlg: value.egsUserFlg,
      ediClientKbn: value.ediClientKbn,
      effectiveflg: value.effectiveflg,
      chargeDivision: value.chargeDivision,
      mkKbn3000: value.mkKbn3000,

      rawpasswd: value.rawpasswd,
      groupCd1: value.groupCd1,
      groupCd2: value.groupCd2,
      groupCd3: value.groupCd3,
      groupCd4: value.groupCd4,
      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}  // doClose
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>事業場マスタ新規作成</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}

//          handleChange2={handleChange2}

          zipChange={zipChange}
          prefChange={prefChange}
          kbnChange={kbnChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMTraderNew;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
