// /src/lib/api/demand_b_kensu_old.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getDemandBKensuOldList = () => {
  return client.get('/demand_b_kensu_olds');
};

// 詳細
export const getDemandBKensuOldDetail = (id) => {
  return client.get(`/demand_b_kensu_olds/${id}`);
};

// 月指定
export const getDemandBKensuOldMonth = (id) => {
  return client.get(`/demand_b_kensu_olds/${id}/showmonth`);
};

// 検索条件指定
export const getDemandBKensuOldOpe = (id) => {
  return client.get(`/demand_b_kensu_olds/${id}/showope`);
};

// 事業所指定一覧 idは事業所CD
export const getDemandBKensuOldUser = (id) => {
  return client.get(`/demand_b_kensu_olds/${id}/showuser`);
};
