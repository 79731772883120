import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMPermitList, deleteEgsMPermit } from '../../lib/api/egs_m_permit';
import { deleteEgsMPermit } from '../../lib/api/egs_m_permit';
//import { getEgsMPermitList } from '../../lib/api/egs_m_permit';
//import { getPermitList } from '../../lib/api/permit';
import { getPermitUser, getPermitList } from '../../lib/api/permit';
//import { getEgsMPermitUser } from '../../lib/api/egs_m_permit';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import PermitListTable from './PermitListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMPermitList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getEgsMPermitList();
////      const res = await getPermitUser(currentUser.traderCd);
//      const res = await getEgsMPermitUser(currentUser.traderCd);    // ＮＧ
//      const res = await getPermitList();

     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
      const res = await getPermitList();
//      res = await getPermitList();
      // console.log(res.data);
      setDataList(res.data);
    }else{                                       //事業場区分は管理者以外？
      const res = await getPermitUser(currentUser.traderCd);
//      res = await getPermitUser(currentUser.traderCd);
      // console.log(res.data);
      setDataList(res.data);
    }

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  // const handleDelete = async (item) => {

  //  alert(' ' + item.permitName + 'を削除します。よろしいですか？')
  //   // console.log('click', item.id);
  //   try {
  //     const res = await deleteEgsMPermit(item.id);
  //     // console.log(res.data);
  //     handleGetList();
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // };

  const handleDelete = async (item) => {
    if (window.confirm(' 許可証「' + item.permitNo + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMPermit(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>許可証マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_permitsSel')}
        style={{ marginRight: 10 }}
      >
        参照作成
      </Button> */}
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_permitsNew')}
      >
        新規作成
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PermitListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default EgsMPermitList;
