import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles, Button } from '@material-ui/core';

import { updateEgsMCharge, getEgsMChargeDetail } from '../../lib/api/egs_m_charge';
//import FormBody from './EgsMChargeForm';
import FormBody from './EgsMChargeEditForm';  // フォーム分割

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMChargeEdit({ isOpen, doClose, dataList2, processId }) {

//  // console.log("dataList2",processId,dataList2);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    egsMTraderId: 0,
    traderCd: '',
    traderName: '',
    chargeCd: '',
    chargeName: '',
    chargeKana: '',
    email: '',
    emailFlg: '0',
    ediKbn: 0,
    effectiveflg: '0',
  });

  useEffect(() => {
    handleGetData(processId,dataList2);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId, dataList2) => {
    try {
      setLoading(true);
      const res = await getEgsMChargeDetail(processId);
      // console.log(res.data);
      // console.log('dataList2',dataList2);
      setValue({

        egsMTraderId: res.data.egsMTraderId,
        traderCd: res.data.traderCd,
//        traderName: res.data.traderName,
        traderName: dataList2.traderName,
        chargeCd: res.data.chargeCd,
        chargeName: res.data.chargeName,
        chargeKana: res.data.chargeKana,
        email: res.data.email,
        emailFlg: res.data.emailFlg,
        ediKbn: res.data.ediKbn,
        effectiveflg: res.data.effectiveflg,
/*
//        egsMTraderId: dataList2.egsMTraderId,
        traderCd: dataList2.traderCd,
        chargeCd: dataList2.chargeCd,
        chargeName: dataList2.chargeName,
        chargeKana: dataList2.chargeKana,
        email: dataList2.email,
        emailFlg: dataList2.emailFlg,   // コンボボックスで初期表示されない
        ediKbn: dataList2.ediKbn,  // コンボボックスで初期表示されない
        effectiveflg: dataList2.effectiveflg,  // コンボボックスで初期表示されない
*/
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
//  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
/*
    e.preventDefault();
    // 追加
    const params = generateParams();
    try {
      const res = await updateEgsMCharge(processId, params);
      // console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
*/

  if (window.confirm(' 担当者「' + value.traderCd + ':' + value.traderName + ':' + value.chargeCd + '」を更新します。よろしいですか？')) {
    try {
      e.preventDefault();
      // 追加
      const params = generateParams();
//      // console.log('params',params)
      try {
        const res = await updateEgsMCharge(processId, params);
//        // console.log(res);
        handleCancel();
//        history.push('/permit');  // egs_m_permits
        window.location.reload();
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    } catch (e) {
    console.log(e.response);
    }
}

  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      egsMTraderId: value.egsMTraderId,
      traderCd: value.traderCd,
      traderName: value.traderName,
      chargeCd: value.chargeCd,
      chargeName: value.chargeName,
      chargeKana: value.chargeKana,
      email: value.email,
      emailFlg: value.emailFlg,
      ediKbn: value.ediKbn,
      effectiveflg: value.effectiveflg,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
//      onClose={doClose}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>担当者マスタ編集</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained'
//           onClick={() => history.push('/permit')}>
            onClick={e => {
              e.preventDefault();
//              handleBack();
              handleCancel();
            }}>
            戻る
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMChargeEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
