// /src/lib/api/egs_t_garbage_tmp.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsTGarbageTmpList = () => {
  return client.get('/egs_t_garbage_tmps');
};

// 収集運搬事業者指定一覧 idは事業所CD
export const getEgsTGarbageTmpUser = (id) => {
  return client.get(`/egs_t_garbage_tmps/${id}/showuser`);
};
// 排出事業者指定一覧 idは事業所CD
export const getEgsTGarbageTmpExhaust = (id) => {
  return client.get(`/egs_t_garbage_tmps/${id}/showexhaust`);
};
// 作成済みチェック idはgarbage_tag
export const getEgsTGarbageTmpGbt = (id) => {
  return client.get(`/egs_t_garbage_tmps/${id}/showgbt`);
};

// 新規作成
// header情報を追加
export const createEgsTGarbageTmp = (params) => {
  return client.post('/egs_t_garbage_tmps', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsTGarbageTmp = (id, params) => {
  return client.patch(`/egs_t_garbage_tmps/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsTGarbageTmp = (id) => {
  return client.delete(`/egs_t_garbage_tmps/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
