import React, { useEffect, useState, useContext } from 'react';
import { getEdi3000R24ViewList } from '../../lib/api/edi3000_r24_view';
import { useHistory } from 'react-router-dom';
// style
//import { Button } from '@material-ui/core';
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import AdmEdiInfoListTable from './AdmEdiInfoListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function AdmEdiInfoList() {
    const { loading, isSignedIn, setIsSignedIn, currentUser } = useContext(AuthContext);
    const [dataList, setDataList] = useState([]);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        handleGetList();
    }, []);

    const handleGetList = async () => {
        try {
            setOpen(!open);
            //      const res = await getProcessFlowList();
            const res = await getEdi3000R24ViewList();
            // console.log(res.data);
            setDataList(res.data);
            setOpen(false);
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const history = useHistory();

    // const handleDelete = async (item) => {
    //     if (window.confirm(' 処理フロー「' + item.reserveName + '」を削除します。よろしいですか？')) {
    //         // console.log('click', item.id);
    //         try {
    //             const res = await deleteEgsMProcessFlow(item.id);
    //             // console.log(res.data);
    //             handleGetList();
    //         } catch (e) {
    //             console.log(e.response);
    //         }
    //     }
    // };

    return (
        <div>
            <h1>ＥＤＩ通信情報</h1>
            {/* <Button
              variant='contained'
              color='primary'
              onClick={() => history.push('/egs_m_linkageSel')}
            >
              連携マスタより参照作成
            </Button> */}
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <SpaceRow height={20} />
            <AdmEdiInfoListTable
                dataList={dataList}
            />
        </div>
    );
}
export default AdmEdiInfoList;
