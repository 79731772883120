import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";

import dayjs from 'dayjs';
import {
  //  Button,
    TextField,
    MenuItem,
    Select,
    Box,
    Grid,
    Typography,
    InputLabel
  } from '@material-ui/core';
  import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    jaJP
  } from '@mui/x-data-grid-pro'

// import { updateEgsMEntry, getEgsMEntryDetail } from '../../lib/api/egs_m_entry';
import { createEgsMEntry } from '../../lib/api/egs_m_entry';
import { getEntryId } from '../../lib/api/entry';
import FormBody from './EgsMEntryDelForm';
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { getEgsMTraderUser } from '../../lib/api/egs_m_trader';
//import { getLinkageList, getLinkageUser ,getLinkageGroup } from '../../lib/api/linkage';
import { getLinkageExhaust, getLinkageUserExhaust ,getLinkageGroupExhaust } from '../../lib/api/linkage';
import { getAddressZip } from '../../lib/api/address';
import { getAddressCompanyZip } from '../../lib/api/address';
import { getCompanyCdNewPref } from '../../lib/api/company_cd_new';
import { getTraderAdmJwnet} from '../../lib/api/trader_adm';
import { getEntryLinkOpe} from '../../lib/api/entry_link';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}


function EgsMEntryDel(props) {
  const { isOpen, doClose, currentUser } = props;
  // // console.log("dataList2",dataList2);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataList3, setDataList3] = useState([]);
  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  const [dataListNewCd,setDataListNewCd] = useState([]);
  const [dataListTrader,setDataListTrader] = useState([]);

  const [value, setValue] = useState({
    demandId: 0,
    demandCd: '',
    demandName: '',

    egsMCompanyId: 0,
    companyCd: '',

    companyKana: '',
    companyName: '',
    companyZip: '',
    companyPref: '',
    companyCity: '',
    companyTown: '',
    companyAddress: '',
    companyBuilding: '',
    companyPhone: '',
    companyFax: '',
    businessType: '83',
    businessCategory: 'P',
    groupId1: 0,
    groupId2: 0,

    egsMTraderId: 0,
    traderCd: '',
    traderKana: '',
    traderName: '',
    zip: '',
    pref: '',
    city: '',
    town: '',
    address: '',
    building: '',
    phone: '',
    fax: '',
    email1: '',
    memo: '',
    effectiveflg: '0',
    jwnetNo: '',
    jwnetEdipass: '',
    rawpasswd: '',
    exhaustPasswd: '',
    traderKbn: '1000000000',
    chargeDivision: '3',
    prefectureCd: '',
    email2: '',
    email3: '',

    egsMChargeId: 0,
    chargeCd: '',
    daihyo: '',
    daihyoKana1: '',
    daihyoKana2: '',
    daihyoName1: '',
    daihyoName2: '',
    chargeKana1: '',
    chargeKana2: '',
    chargeName1: '',
    chargeName2: '',
    officeDivKana: '',
    officeDivName: '',
    officeChargeKana1: '',
    officeChargeKana2: '',
    officeChargeName1: '',
    officeChargeName2: '',

    entryDate: '',
    companyKind: '99',
    companyKbn: '1',
    henkouDate: '',
    henkouKbn: '3',

  });

  let res;
  let resTrader;
  let selRows = React.useRef([]);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      setOpen(!open);
/*
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getLinkageList();
        res = await getLinkageExhaust();
      }else{                                       //事業場区分は管理者以外？
        if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ管理者？
//          res = await getLinkageList();
//          res = await getLinkageGroup(currentUser.traderCd);
          res = await getLinkageGroupExhaust(currentUser.traderCd);
        }else{                                       //事業場区分は管理者以外？
//          res = await getLinkageUser(currentUser.traderCd);
          res = await getLinkageUserExhaust(currentUser.traderCd);
        }
      }
      // console.log(res.data);

      
      setDataList(res.data);
*/
      resTrader = await getEgsMTraderUser(currentUser.traderCd);
      value.demandId = resTrader.data[0].id 
      value.demandCd = currentUser.traderCd 

      setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

//====================================   フィルタ絞込と一覧-S
const [filteredDataList, setFilteredDataList] = React.useState([]);
const [filter, setFilter] = React.useState({
  exhaustDateStart: '',
  exhaustDateEnd: '',
})

useEffect(() => {
  setFilteredDataList(dataList);
}, [dataList, currentUser])


const [dataList2, setDataList2] = useState({});

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();

  const handleChange = (e) => {
//    value.traderCd = value.phone + value.wasteCd.substring(0,4);
    if ( e.target.name == 'companyName') {value.traderName = e.target.value} 
    if ( e.target.name == 'companyKana') {value.traderKana = e.target.value} 
    if ( e.target.name == 'daihyoName1') {
      value.chargeName1 = e.target.value
      value.officeChargeName1 = e.target.value
    } 
    if ( e.target.name == 'daihyoName2') {
      value.chargeName2 = e.target.value 
      value.officeChargeName2 = e.target.value
    } 
    if ( e.target.name == 'daihyoKana1') {
      value.chargeKana1 = e.target.value
      value.officeChargeKana1 = e.target.value
    } 
    if ( e.target.name == 'daihyoKana2') {
      value.chargeKana2 = e.target.value
      value.officeChargeKana2 = e.target.value
    } 
//    if ( e.target.name == 'companyZip') {value.zip = e.target.value} 
    if ( e.target.name == 'companyAddress') {value.address = e.target.value} 
    if ( e.target.name == 'companyBuilding') {value.building = e.target.value} 
    if ( e.target.name == 'companyPhone') {value.phone = e.target.value} 
    if ( e.target.name == 'companyFax') {value.fax = e.target.value} 


    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

  };

let wk_param

  const jwnetChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    if ( e.target.name == 'jwnetNo' && e.target.value.length == 7) {
      try {

        if ( e.target.name == 'jwnetNo'){
          wk_param = currentUser.traderCd + '_' + currentUser.traderKbn + '_' + e.target.value
        }else{  
          wk_param = currentUser.traderCd + '_' + currentUser.traderKbn + '__' + e.target.value
        }
          
//      const dataListTrader = await getTraderAdmJwnet(e.target.value);
      const dataListTrader = await getEntryLinkOpe(wk_param);
      setDataListTrader(dataListTrader.data);

      value.demandId = dataListTrader.data[0].demandId
      value.demandName = dataListTrader.data[0].demandName

      value.egsMCompanyId = dataListTrader.data[0].egsMCompanyId
      value.companyCd = dataListTrader.data[0].companyCd
      value.companyName = dataListTrader.data[0].companyName
      value.companyKana = dataListTrader.data[0].companyKana

      value.egsMTraderId = dataListTrader.data[0].egsMTraderId
      value.traderCd = dataListTrader.data[0].traderCd
      
      value.traderName = dataListTrader.data[0].traderName
      value.traderKana = dataListTrader.data[0].traderKana

      value.egsMChargeId = dataListTrader.data[0].egsMChargeId
      value.chargeCd = dataListTrader.data[0].chargeCd
      value.daihyo = dataListTrader.data[0].daihyo
      value.daihyoName1 = dataListTrader.data[0].daihyoName1
      value.daihyoName2 = dataListTrader.data[0].daihyoName2
      value.daihyoKana1 = dataListTrader.data[0].daihyoKana1
      value.daihyoKana2 = dataListTrader.data[0].daihyoKana2

      value.zip = dataListTrader.data[0].zip
      value.pref = dataListTrader.data[0].pref
      value.city = dataListTrader.data[0].city
      value.town = dataListTrader.data[0].town
      value.address = dataListTrader.data[0].address
      value.building = dataListTrader.data[0].building
      value.phone = dataListTrader.data[0].phone
      value.fax = dataListTrader.data[0].fax

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
  
      } catch (e) {
      console.log(e);
    }
    console.log(e);

    }

  };

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    // console.log('params',params);
    if (window.confirm(' 加入者マスタ「' + value.traderName + '」を新規登録します。よろしいですか？')) {
      try {
       setOpen(!open);
        const res = await createEgsMEntry(params);
        // console.log('res',res);
        handleCancel();
        history.push('/egs_m_entries');
        window.location.reload();
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      companyCd: value.companyCd,
      egsMCompanyId: value.egsMCompanyId,
      traderCd: value.traderCd,
      companyKana: value.companyKana,
      companyName: value.companyName,
      companyZip: value.companyZip,
      companyPref: value.companyPref,
      companyCity: value.companyCity,
      companyTown: value.companyTown,
      companyAddress: value.companyAddress,
      companyBuilding: value.companyBuilding,
      companyPhone: value.companyPhone,
      companyFax: value.companyFax,
      daihyo: value.daihyo,
      daihyoKana1: value.daihyoKana1,
      daihyoKana2: value.daihyoKana2,
      daihyoName1: value.daihyoName1,
      daihyoName2: value.daihyoName2,
      traderKana: value.traderKana,
      traderName: value.traderName,
      chargeKana1: value.chargeKana1,
      chargeKana2: value.chargeKana2,
      chargeName1: value.chargeName1,
      chargeName2: value.chargeName2,
      zip: value.zip,
      pref: value.pref,
      city: value.city,
      town: value.town,
      address: value.address,
      building: value.building,
      phone: value.phone,
      fax: value.fax,
      email1: value.email1,
      memo: value.memo,
      effectiveflg: value.effectiveflg,
      officeDivKana: value.officeDivKana,
      officeDivName: value.officeDivName,
      officeChargeKana1: value.officeChargeKana1,
      officeChargeKana2: value.officeChargeKana2,
      officeChargeName1: value.officeChargeName1,
      officeChargeName2: value.officeChargeName2,
      email2: value.email2,
      entryDate: value.entryDate,
      businessType: value.businessType,
      businessCategory: value.businessCategory,
      traderKbn: value.traderKbn,
      chargeDivision: value.chargeDivision,
      demandCd: value.demandCd,
      demandId: value.demandId,
      prefectureCd: value.prefectureCd,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      rawpasswd: value.rawpasswd,
      exhaustPasswd: value.exhaustPasswd,
      companyKind: value.companyKind,
      companyKbn: value.companyKbn,
      henkouDate: value.henkouDate,
      egsMTraderId: value.egsMTraderId,
      henkouKbn: value.henkouKbn,
      email3: value.email3,
      };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>加入者マスタ　解約申込</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
      </div>
      </DialogTitle>
      <DialogContent>


        <FormBody
          handleChange={handleChange}
          jwnetChange={jwnetChange}
          handleSubmit={handleSubmit}
          value={value}
          setValue={setValue}
          currentUser={currentUser}
          buttonType='登録' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMEntryDel;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
