import React, { useEffect, useState, useContext } from 'react';
import { getGarbageStatuList } from '../../lib/api/garbage_status';
import { getGarbageStatuUser } from '../../lib/api/garbage_status';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import GarbageStatuListTable from './GarbageStatuListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GarbageStatuList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;              // 0802

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
////      const res = await getGarbageStatuList();
//      const res = await getGarbageStatuUser(currentUser.traderCd);
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？  // 0802
        res = await getGarbageStatuList();
      }else{                                       //事業場区分は管理者以外？
        res = await getGarbageStatuUser(currentUser.traderCd);
      }
        // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>廃棄物状況一覧</h1>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GarbageStatuListTable
        dataList={dataList}
        currentUser={currentUser}
      />
    </>
  );
};
export default GarbageStatuList;
