import {
    PDFDownloadLink,
    PDFViewer,
    Page,
    View,
    Font,
    Document,
    StyleSheet
} from '@react-pdf/renderer';
import Text from "../pdf_components/atoms/Text";
import Title from "../pdf_components/molecules/Title";
import Link from "../pdf_components/atoms/Link";
import Br from "../pdf_components/atoms/Br";
import { Grid, Col } from "../pdf_components/atoms/Grid";
import {
    ListItem,
    PR,
    Exhaust,
    Middle,
    Fainal,
    Toxic,
    FlowMemo,
    FlowName,
    Kukan1Trans,
    Kukan1Desti,
    Kukan2Trans,
    Kukan2Desti,
    Kukan3Trans,
    Kukan3Desti,
    SyobunJyutaku,
    LastSyobunPlace
} from "../pdf_components/molecules/Base";

import fontRegular from '../../fonts/Nasu-Regular.ttf'  //ttfファイル参照
import fontBold from '../../fonts/Nasu-Bold.ttf'        //ttfファイル参照
import dayjs from 'dayjs';

// Create Document Component
function ProcessFlowPdfForm(props) {
    const { currentUser,isOpen,doClose,dataList2 } = props;

    // console.log("dataList2",dataList2);
    // console.log("currentUser",currentUser);

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: 'Nasu-Regular',
    src: fontRegular
  });

  // フォント「ナス 太字」
  Font.register({
    family: 'Nasu-Bold',
    src: fontBold
  });
    // CSSスタイル定義
    const wk_styles = StyleSheet.create({
        text1: { fontSize: '20pt', fontFamily: 'Nasu-Regular' },
        text2: { fontSize: '10pt', fontFamily: 'Nasu-Regular' },
        text3: { fontSize: '7pt', fontFamily: 'Nasu-Regular' }
      });

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "#000000",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth*0.5, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    screen: {
        padding: 10
      },
  });
  const today = "出力日：" + dayjs().format('YYYY/MM/DD');
  const titleid = "ID：";
    return (
        <PDFViewer style={styles.viewer}>
        <Document>
        <Page size="A4" style={styles.page}>
        <View style={styles.screen}>
          <Title title="積込確認（処理フロー）" createDate= {today} />
          <Grid borderTop>
            <ListItem lable="排出日" borderLeft borderRight>
            {dataList2.exhaustDate}
            </ListItem>
            <ListItem lable="処理フローID" borderRight>
            {dataList2.id}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <ListItem lable="フロー番号" borderLeft borderRight>
            {dataList2.reserveNo}
            </ListItem>
            <ListItem lable="サブ番号" borderRight>
            {dataList2.reserveSubno}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <FlowName>
              <View>
                <Text>
                {dataList2.reserveName}
                </Text>
              </View>
            </FlowName>
          </Grid>
          <Grid borderTop>
            <Exhaust>
              <View>
                <Text>
                  {dataList2.exhaustName}　　　　加入者番号：{dataList2.jwnetNo}
                </Text>
                <Text>
                  〒{dataList2.exhaustAddress}
                </Text>
                <Text>
                  TEL:{dataList2.exhaustPhone}　　　　　　　　　　排出担当者：{dataList2.exhaustChargeName}
                </Text>
              </View>
            </Exhaust>
          </Grid>
          <Grid borderTop>
            <ListItem lable="廃棄物種類" borderLeft borderRight>
            {dataList2.wasteKindName}
            </ListItem>
            <ListItem lable="廃棄物の数量"  borderRight>
            {dataList2.amount1} 　　　　　 ({dataList2.unitName})
            </ListItem>
          </Grid>
          <Grid borderTop>
          <ListItem lable="廃棄物名称" borderLeft borderRight>
            {dataList2.wasteName}
            </ListItem>
            <ListItem lable="荷姿の数量" borderRight>
            {dataList2.packingAmount1} 　　　　　 ({dataList2.packingName})
            </ListItem>
          </Grid>
          <Grid borderTop>
            <ListItem lable="処分方法" borderLeft borderRight>
            {dataList2.disposalMethodName}
            </ListItem>
            <ListItem lable="" borderRight>
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Toxic>
              <View>
                <Text>
                {dataList2.toxicName01}　{dataList2.toxicName02}　{dataList2.toxicName03}　
                {dataList2.toxicName04}　{dataList2.toxicName05}　{dataList2.toxicName06}
                </Text>
              </View>
            </Toxic>
          </Grid>
          <Grid borderTop>
            <FlowMemo>
              <View>
                <Text>
                {dataList2.memo}
                </Text>
              </View>
            </FlowMemo>
          </Grid>

          <Grid borderTop>
            <Fainal>
              <View>
                <Text>
                {dataList2.lastProcessingTraderName}　　　　　　　　　　処分担当者：{dataList2.lastProcessingChargeName}
                </Text>
              </View>
            </Fainal>
          </Grid>

          <Grid borderTop>
            <ListItem lable="最終処分の場所" borderLeft borderRight>
              {dataList2.lastReportName}
            </ListItem>
            <ListItem lable="" borderRight>
            </ListItem>
          </Grid>

          <Grid borderTop>
            <Kukan1Trans>
              <View>
                <Text>
                  {dataList2.sect1TransportName}　　　　　　　　　　運搬方法：車両（ {dataList2.sect1CarNo} ）
                </Text>
                <Text>
                  〒{dataList2.sect1TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect1TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect1TransportChargeName}
                </Text>
              </View>
            </Kukan1Trans>
          </Grid>
          <Grid borderTop>
            <Kukan1Desti>
              <View>
                <Text>
                  {dataList2.destination1Name}
                </Text>
                <Text>
                  〒{dataList2.destination1Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination1Phone}　　　　　　　　　　荷受担当者：{dataList2.destination1ChargeName}
                </Text>
              </View>
            </Kukan1Desti>
          </Grid>
          <Grid borderTop>
            <Kukan2Trans>
              <View>
                <Text>
                  {dataList2.sect2TransportName}　　　　　　　　　　運搬方法：車両（ {dataList2.sect2CarNo} ）
                </Text>
                <Text>
                  〒{dataList2.sect2TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect2TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect2TransportChargeName}
                </Text>
              </View>
            </Kukan2Trans>
          </Grid>
          <Grid borderTop>
            <Kukan2Desti>
              <View>
                <Text>
                  {dataList2.destination2Name}
                </Text>
                <Text>
                  〒{dataList2.destination2Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination2Phone}　　　　　　　　　　荷受担当者：{dataList2.destination2ChargeName}
                </Text>
              </View>
            </Kukan2Desti>
          </Grid>
          <Grid borderTop>
            <Kukan3Trans>
              <View>
                <Text>
                  {dataList2.sect3TransportName}　　　　　　　　　　運搬方法：{dataList2.sect3TransportMethodName}　{dataList2.sect3CarNo}
                </Text>
                <Text>
                  〒{dataList2.sect3TransportAddress}
                </Text>
                <Text>
                  TEL:{dataList2.sect3TransportPhone}　　　　　　　　　　運搬担当者：{dataList2.sect3TransportChargeName}
                </Text>
              </View>
            </Kukan3Trans>
          </Grid>
          <Grid borderTop>
            <Kukan3Desti>
              <View>
                <Text>
                  {dataList2.destination3Name}
                </Text>
                <Text>
                  〒{dataList2.destination3Address}
                </Text>
                <Text>
                  TEL:{dataList2.destination3Phone}　　　　　　　　　　荷受担当者：{dataList2.destination3ChargeName}
                </Text>
              </View>
            </Kukan3Desti>
          </Grid>
          <Grid borderTop borderBottom>
            <SyobunJyutaku>
              <View>
                <Text>
                  {dataList2.companyName}
                </Text>
                <Text>
                  〒{dataList2.companyZip}　{dataList2.companyAddress}
                </Text>
                <Text>
                  TEL:{dataList2.companyPhone}
                </Text>
              </View>
            </SyobunJyutaku>
          </Grid>

          <Text size="normal" style={{ textAlign: "right" }}>
            産業廃棄物SDGs推進システム
          </Text>
          <Br />
          </View>
          </Page></Document>
          </PDFViewer>
    );
  }
  export default ProcessFlowPdfForm;
