import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
// import QRCode from "qrcode.react"

import FirstSelSelect from '../garbage_edi/FirstSelSelect';
import SpaceRow from '../commons/SpaceRow';
import { getEgsMProcessFlowDetail } from '../../lib/api/egs_m_process_flow';
import { createEgsTGarbageEdi, updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import { getChargeUser } from '../../lib/api/charge';
import { getEgsMTraderUser }  from '../../lib/api/egs_m_trader';
import { getFirstSelOperation, getFirstSelOpe2, getFirstSelList, getFirstSelUser, getFirstSelUser0 } from '../../lib/api/first_sel';
import { transportMethodName } from '../../constants'

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  InputLabel
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import FormControl from '@mui/material/FormControl';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Sdgs12_Csv',
          delimiter: ',',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '120ch',  // 100ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "60vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#CFF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

function EgsTGarbageEdiAddNew(props) {
  const { currentUser, isOpen, doClose, dataList2, garbageTag } = props;
  const [loading, setLoading] = useState(false);
  // console.log("dataList2",dataList2);
  const classes = useStyles();
  const [dataList3, setDataList3] = useState({});

  const today = dayjs().format('YYYY-MM-DD');
  const wk_today = today;

//  const [value, setValue] = useState([]);
   const [value, setValue] = useState({
     exhaustDate: wk_today, // 0,
  //   garbageTag: '',
  //   manifestNo: '',
     amount1: 0,
     wkAmount1: 0,

  //   unitName: '',
  //   packingName: '',
  //   packingAmount1: 0,
  //   memo1: '',    // memo
  //   exhaustChargeId: 0,
  //   exhaustChargeCd: '',
  //   exhaustChargeName: '',
  //   sect1CarNo: '',
  //   sect1TransportChargeId: 0,
  //   sect1TransportChargeCd: '',
  //   sect1TransportChargeName: '',
  //   secondFlg: '0',       // 2次マニフェストフラグ
  });

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);  {/* 20240527  */}
  const [dataListC00, setDataListC00] = useState([]); {/* 排出担当者 */ }
  const [dataListC01, setDataListC01] = useState([]); {/* 運搬担当者 */ }
  const [chargedataList00, setchargedataList00] = useState([]); {/* 排出担当者 */ }
  const [chargedataList01, setchargedataList01] = useState([]); {/* 運搬担当者 */ }
  const [detailOpen2, setDetailOpen2] = useState(false);

  let res;

  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);
  // console.log("inputError",inputError1,inputError2)

  var param_item = '';    // 20240527

  useEffect(() => {
    handleGetList(dataList2.id);
  }, [dataList2.id]);


  const handleGetList = async () => {
    try {
     setOpen(!open);
      const resC00 = await getChargeUser(dataList2.exhaustCd);
      // console.log("resC00.data",resC00.data);
      setDataListC00(resC00.data);
      const resC01 = await getChargeUser(dataList2.sect1TransportCd);
      // console.log("resC01.data",resC01.data);
      setDataListC01(resC01.data);
/*
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
        res = await getFirstSelList();
      }else{                                       //事業場区分は管理者以外？
//        res = await getFirstSelUser(currentUser.traderCd);
        res = await getFirstSelUser0(currentUser.traderCd);  // 未選択のみ抽出
      }
*/
//////////////////////////////　丸徳商事は初期検索しない  ////////    
      if (currentUser.traderCd != '227190950130' && currentUser.traderCd != '262019100130' ) {
//          res = await getFirstSelUser0(currentUser.traderCd)
          res = await getFirstSelOperation(currentUser.traderCd + '_' + currentUser.traderKbn)
      // console.log(res.data);
      }
      setDataList3(res.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };


  const handleChange = (e) => {
    // console.log('setvalue1', value);
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }
    // console.log('setvalue2', value);

  };


  const handleOpen2 = () => {
    setDetailOpen2(true);
  };

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    if (window.confirm(' 排出登録（マニフェスト情報作成）を行います。よろしいですか？')) {
      // console.log("排出事業者CD",dataList2.exhaustCd)
      const res = await getEgsMTraderUser(dataList2.exhaustCd);
      // console.log("承認コードres",res.data[0].exhaustPasswd)
      if (value.exhaustPasswd != res.data[0].exhaustPasswd && ( currentUser.traderKbn != '0001000000' && currentUser.traderKbn != '0001001000' ) ){
        alert('排出事業者 承認コードが一致しません。確認して再入力ください。')

      }else{
        // 追加
        // console.log("dataListC00",dataListC00);
        if (value.exhaustChargeId != null) {
          const chargedataList00 = dataListC00.filter(dataListC00 => { return dataListC00.id == value.exhaustChargeId });
          value.exhaustChargeCd = chargedataList00[0].chargeCd;
          value.exhaustChargeName = chargedataList00[0].chargeName;
        }else{
          value.exhaustChargeId = 0;
          value.exhaustChargeCd = '';
          value.exhaustChargeName = '';
          }
        // console.log("value.exhaustChargeName",value.exhaustChargeName);
        // setchargedataList00(chargedataList00);
        // // console.log("chargedataList00",chargedataList00);

        // console.log("dataListC01",dataListC01);
        if (value.sect1TransportChargeId != null) {
          const chargedataList01 = dataListC01.filter(dataListC01 => { return dataListC01.id == value.sect1TransportChargeId });
          value.sect1TransportChargeCd = chargedataList01[0].chargeCd;
          value.sect1TransportChargeName = chargedataList01[0].chargeName;
        }else{
          value.sect1TransportChargeId = 0;
          value.sect1TransportChargeCd = '';
          value.sect1TransportChargeName = '';
        }
        // console.log("value.sect1TransportChargeName",value.sect1TransportChargeName);
        // setchargedataList01(chargedataList01);
        // // console.log("chargedataList01",chargedataList01);

        const params = generateParams();
        try {
         setOpen(!open);
          const res = await createEgsTGarbageEdi(params);
          // console.log("createEgsTGarbageEdi_res",res);
          // console.log("params",params);

          const firstparams = {
            // id: dataList2.id,
            selectId: res.data.id,
            selectTag: res.data.garbageTag,

            lastProcessingTraderId: res.data.lastProcessingTraderId,    // 20230411 ２次マニフェストの最終処分場を1次マニフェストに反映する
            lastProcessingTraderCd: res.data.lastProcessingTraderCd,
            lastProcessingTradername: res.data.lastProcessingTraderName,
            lastProcessingChargeId: res.data.lastProcessingChargeId,
            lastProcessingChargeCd: res.data.lastProcessingChargeCd,
            lastProcessingChargeName: res.data.lastProcessingChargeName,
          }

          // console.log("selRows",selRows);
          if (selRows.current.length > 0){
            for (let i = 0; i<selRows.current.length; i++){
              // ここに選択した１次マニフェストの登録処理
              // console.log('selRows.current[i]',selRows.current[i]);
              // console.log("firstparams",firstparams);
              try {
                const res = await updateEgsTGarbageEdi(selRows.current[i], firstparams);
                // console.log("updateEgsTGarbageEdi_res",res);
              } catch (e) {
                console.log(e);
              }
            }
          }
         setOpen(false);
          handleCancel();
            history.push('/');
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const selectChange = async (v) => {

    selRows.current = v;

    // e.preventDefault();
    // 追加
    // // console.log("selRows",selRows);
    value.wkAmount1 = 0
    if (selRows.current.length > 0){
      for (let i = 0; i<selRows.current.length; i++){
        for (let j = 0; j<dataList3.length; j++){
          if (selRows.current[i] == dataList3[j].id) {
//            value.wkAmount1 = value.wkAmount1 + dataList3[j].amount1 * 1
            value.wkAmount1 = Math.round((value.wkAmount1 + dataList3[j].amount1 * 1)*1000)/1000
          }
        }
      }
    }
//    alert(value.wkAmount1)
};

//const amountChange = async (v) => {
const amountChange = async (e) => {

  value.amount1 = value.wkAmount1
  setValue({
    ...value,
    [e.target.name]: e.target.value,
  });

};

  const handleSelect = async (e) => {
    // e.preventDefault();
    // 追加
    // // console.log("selRows",selRows);
/*
    value.amount1 = 0
    if (selRows.current.length > 0){
      for (let i = 0; i<selRows.current.length; i++){
        for (let j = 0; j<dataList3.length; j++){
          if (selRows.current[i] == dataList3[j].id) {
            value.amount1 = value.amount1 + dataList3[j].amount1 * 1
          }
        }
      }
    }
*/
    alert(value.amount1)

};

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    // 2次マニフェストが紙マニフェストでマニフェスト番号登録済みの場合
    if ((value.manifestNo != "") && (value.manifestNo != null)) {
      value.status = '70'
      value.edi_send_flg = '71'
    }else{
      value.status = '10'
      value.edi_send_flg = '01'
    }
    const params = {
      // id: dataList2.id,
      garbageTag: garbageTag,
      reserveNo: dataList2.reserveNo,
      reserveSubno: dataList2.reserveSubno,
      reserveName: dataList2.reserveName,
      manifestNo: value.manifestNo,  // '',
      arrangementNo: dataList2.arrangementNo,
      exhaustDate: value.exhaustDate,
      exhaustId: dataList2.exhaustId,
      exhaustCd: dataList2.exhaustCd,
      exhaustChargeId: value.exhaustChargeId,
      exhaustChargeCd: value.exhaustChargeCd,
      sect1TransportId: dataList2.sect1TransportId,
      sect1TransportCd: dataList2.sect1TransportCd,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,
      sect1TransportMethodId: dataList2.sect1TransportMethodId,
      sect1TransportMethodCd: dataList2.sect1TransportMethodCd,
      sect1CarNo: value.sect1CarNo,
      destination1Id: dataList2.destination1Id,
      destination1Cd: dataList2.destination1Cd,
      destination1ChargeId: dataList2.destination1ChargeId,
      destination1ChargeCd: dataList2.destination1ChargeCd,
      sect2TransportId: dataList2.sect2TransportId,
      sect2TransportCd: dataList2.sect2TransportCd,
      sect2TransportChargeId: dataList2.sect2TransportChargeId,
      sect2TransportChargeCd: dataList2.sect2TransportChargeCd,
      sect2TransportMethodId: dataList2.sect2TransportMethodId,
      sect2TransportMethodCd: dataList2.sect2TransportMethodCd,
      sect2CarNo: dataList2.sect2CarNo,
      destination2Id: dataList2.destination2Id,
      destination2Cd: dataList2.destination2Cd,
      destination2ChargeId: dataList2.destination2ChargeId,
      destination2ChargeCd: dataList2.destination2ChargeCd,
      sect3TransportId: dataList2.sect3TransportId,
      sect3TransportCd: dataList2.sect3TransportCd,
      sect3TransportChargeId: dataList2.sect3TransportChargeId,
      sect3TransportChargeCd: dataList2.sect3TransportChargeCd,
      sect3TransportMethodId: dataList2.sect3TransportMethodId,
      sect3TransportMethodCd: dataList2.sect3TransportMethodCd,
      sect3CarNo: dataList2.sect3CarNo,
      destination3Id: dataList2.destination3Id,
      destination3Cd: dataList2.destination3Cd,
      destination3ChargeId: dataList2.destination3ChargeId,
      destination3ChargeCd: dataList2.destination3ChargeCd,
      sect4TransportId: dataList2.sect4TransportId,
      sect4TransportCd: dataList2.sect4TransportCd,
      sect4TransportChargeId: dataList2.sect4TransportChargeId,
      sect4TransportChargeCd: dataList2.sect4TransportChargeCd,
      sect4TransportMethodId: dataList2.sect4TransportMethodId,
      sect4TransportMethodCd: dataList2.sect4TransportMethodCd,
      sect4CarNo: dataList2.sect4CarNo,
      destination4Id: dataList2.destination4Id,
      destination4Cd: dataList2.destination4Cd,
      destination4ChargeId: dataList2.destination4ChargeId,
      destination4ChargeCd: dataList2.destination4ChargeCd,
      sect5TransportId: dataList2.sect5TransportId,
      sect5TransportCd: dataList2.sect5TransportCd,
      sect5TransportChargeId: dataList2.sect5TransportChargeId,
      sect5TransportChargeCd: dataList2.sect5TransportChargeCd,
      sect5TransportMethodId: dataList2.sect5TransportMethodId,
      sect5TransportMethodCd: dataList2.sect5TransportMethodCd,
      sect5CarNo: dataList2.sect5CarNo,
      destination5Id: dataList2.destination5Id,
      destination5Cd: dataList2.destination5Cd,
      destination5ChargeId: dataList2.destination5ChargeId,
      destination5ChargeCd: dataList2.destination5ChargeCd,
      middleProcessingTraderId: dataList2.middleProcessingTraderId,
      middleProcessingTraderCd: dataList2.middleProcessingTraderCd,
      middleProcessingChargeId: dataList2.middleProcessingChargeId,
      middleProcessingChargeCd: dataList2.middleProcessingChargeCd,
      lastProcessingTraderId: dataList2.lastProcessingTraderId,
      lastProcessingTraderCd: dataList2.lastProcessingTraderCd,
      lastProcessingChargeId: dataList2.lastProcessingChargeId,
      lastProcessingChargeCd: dataList2.lastProcessingChargeCd,
      processTrustTraderId: dataList2.processTrustTraderId,
      processTrustTraderCd: dataList2.processTrustTraderCd,
      middleWasteFlg: dataList2.middleWasteFlg,
      lastPlaceFlg: dataList2.lastPlaceFlg,
      egsMWasteclassId: dataList2.egsMWasteclassId,
      wasteKindCd: dataList2.wasteKindCd,
      wasteCd: dataList2.wasteCd,
      egsMDisposalMethodId: dataList2.egsMDisposalMethodId,
      disposalMethodCd: dataList2.disposalMethodCd,
      otherMethod: dataList2.otherMethod,
      egsMUnitId: dataList2.egsMUnitId,
      unitCd: dataList2.unitCd,
      egsMPackingId: dataList2.egsMPackingId,
      packingCd: dataList2.packingCd,
      egsMDatasheetId: dataList2.egsMDatasheetId,
      datasheetCd: dataList2.datasheetCd,
      registTraderId: dataList2.registTraderId,
      registTraderCd: dataList2.registTraderCd,
      approvalflg: dataList2.approvalflg,
      effectiveflg: dataList2.effectiveflg,

      exhaustName: dataList2.exhaustName,
      sect1TransportName: dataList2.sect1TransportName,
      sect2TransportName: dataList2.sect2TransportName,
      sect3TransportName: dataList2.sect3TransportName,
      sect4TransportName: dataList2.sect4TransportName,
      sect5TransportName: dataList2.sect5TransportName,
      middleProcessingTraderName: dataList2.middleProcessingTraderName,
      lastProcessingTraderName: dataList2.lastProcessingTraderName,
      processTrustTraderName: dataList2.processTrustTraderName,
      destination1Name: dataList2.destination1Name,
      destination2Name: dataList2.destination2Name,
      destination3Name: dataList2.destination3Name,
      destination4Name: dataList2.destination4Name,
      destination5Name: dataList2.destination5Name,
      exhaustChargeName: value.exhaustChargeName,
      sect1TransportChargeName: value.sect1TransportChargeName,
      sect2TransportChargeName: dataList2.sect2TransportChargeName,
      sect3TransportChargeName: dataList2.sect3TransportChargeName,
      sect4TransportChargeName: dataList2.sect4TransportChargeName,
      sect5TransportChargeName: dataList2.sect5TransportChargeName,
      middleProcessingChargeName: dataList2.middleProcessingChargeName,
      lastProcessingChargeName: dataList2.lastProcessingChargeName,
      destination1ChargeName: dataList2.destination1ChargeName,
      destination2ChargeName: dataList2.destination2ChargeName,
      destination3ChargeName: dataList2.destination3ChargeName,
      destination4ChargeName: dataList2.destination4ChargeName,
      destination5ChargeName: dataList2.destination5ChargeName,
      sect1TransportMethodName: transportMethodName[Number(dataList2.sect1TransportMethodCd)],
      sect2TransportMethodName: transportMethodName[Number(dataList2.sect2TransportMethodCd)],
      sect3TransportMethodName: transportMethodName[Number(dataList2.sect3TransportMethodCd)],
      sect4TransportMethodName: transportMethodName[Number(dataList2.sect4TransportMethodCd)],
      sect5TransportMethodName: transportMethodName[Number(dataList2.sect5TransportMethodCd)],
      wasteKindName: dataList2.wasteKindName,
      wasteName: dataList2.wasteName,
      disposalMethodName: dataList2.disposalMethodName,
      unitName: dataList2.unitName,
      packingName: dataList2.packingName,

      memo1: value.memo1,  // memo
//      memo1: dataList2.memo1,

      toxicCd01: dataList2.toxicCd01,
      toxicCd02: dataList2.toxicCd02,
      toxicCd03: dataList2.toxicCd03,
      toxicCd04: dataList2.toxicCd04,
      toxicCd05: dataList2.toxicCd05,
      toxicCd06: dataList2.toxicCd06,
      toxicCd07: dataList2.toxicCd07,

      amount1: value.amount1,
      packingAmount1: value.packingAmount1,

      status: value.status,
      edi_send_flg: value.edi_send_flg,    // EDI送信状態フラグ
      secondFlg: value.secondFlg,       // 2次マニフェストフラグ
    };
    return params;
  };

//////  const today = dayjs().format('YYYY-MM-DD');
  // console.log('today',today);
  // value.exhaustDate = today;
  // console.log("dataList3",dataList3);

  let selRows = React.useRef([]);
  const [pageSize, setPageSize] = React.useState(10);

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    disposalDateStart: '', // exhaustDateStart: '',
    disposalDateEnd: '', // exhaustDateEnd: '',
  })

  useEffect(() => {
    setFilteredDataList(dataList3);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList3, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList3);
    // console.log('currentUser', currentUser);
    let originalList = dataList3;

    // console.log('originalList', originalList);
    // console.log('filter', filter);
/*    if (filter.exhaustDateStart) {
      const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustDateEnd) {
      const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
      });
    }
*/
/*
    if (filter.disposalDateStart) {
      const startDate = dayjs(filter.disposalDateStart, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const middleEnd = dayjs(item.middleEnd, "YYYY-MM-DD");
        return startDate.isBefore(middleEnd, "days") || startDate.isSame(middleEnd, "days");
      });
    }
    if (filter.disposalDateEnd) {
      const endDate = dayjs(filter.disposalDateEnd, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const middleEnd = dayjs(item.middleEnd, "YYYY-MM-DD");
        return endDate.isAfter(middleEnd, "days") || endDate.isSame(middleEnd, "days");
      });
    }
*/
    setFilteredDataList(originalList);

//////////////////////////////　丸徳商事は最大1000件で絞り込む  ////////    
    if (( currentUser.traderCd == '227190950130' || currentUser.traderCd == '262019100130' ) && filter.disposalDateStart == '') {
      filter.disposalDateStart = wk_today; // '2024-05-01';
      value.disposalDateStart = wk_today; // '2024-05-01';
    }
    if (( currentUser.traderCd == '227190950130' || currentUser.traderCd == '262019100130' ) && filter.disposalDateEnd == '') {
      filter.disposalDateEnd = wk_today; // '2024-05-31'
      value.disposalDateEnd = wk_today; //  '2024-05-31'
    } 

    param_item = currentUser.traderCd + "_" + currentUser.traderKbn + "_" + filter.disposalDateStart +"_" + filter.disposalDateEnd + "_";
//    console.log("param_item",param_item)
    handleGetList2(param_item);

  }

  const handleGetList2 = async () => {
    setOpen2(!open2);
    try {
//      const  res = await getFirstSelOpe0(param_item);
      const  res = await getFirstSelOperation(param_item);
      setFilteredDataList(res.data);

      if (res.data.length == 1000) {
        alert('処分期間の全件を取得できません。処分期間を絞り込んでください（最大1000件）')
      } 

    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 100
    },
    {
      field: 'middleEnd',
      headerName: '処分日',
      type: 'date',
      width: 100
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      hide: true,
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストＮｏ',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 200
    },
    {
      field: 'wasteName',
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 200
    }
  ]

  // console.log('dataList3',dataList3);
  // console.log('dataList3.id',dataList3.id);

  if ((currentUser.traderKbn != '0001001000') && (currentUser.traderKbn != '0001000000')){   //事業場区分は中間処理・排出事業者以外？
  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>排出登録（マニフェスト情報作成）</h2>
      </DialogTitle>
      <DialogContent>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleCancel();
        }}>    {/*  egs_m_reserve_sets  */}
          戻る
        </Button>
      </div>
      <form className={classes.root}>
        <table>
          <tr>
            <th>ゴミタグ番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{garbageTag}</Typography>
            </td>
          </tr>
          <tr>
            <th>フロー名称</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveName}</Typography>
            </td>
          </tr>
          <tr>
            <th>フロー番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveNo}</Typography>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>排出・担当</th>
            <td>
             <FormControl sx={{ minWidth: 200 }}>
               <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustName}</Typography>
                　　
             </FormControl>
             <FormControl sx={{ minWidth: 200 }}>
              <Select
                id='exhaustChargeId'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
                {dataListC00.map((dataListC00) => (
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>
            </td>
          </tr>
          <tr>
            <th>収集運搬・担当</th>
            <td>
             <FormControl sx={{ minWidth: 200 }}>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1TransportName}</Typography>
                    　　
             </FormControl>
             <FormControl sx={{ minWidth: 200 }}>
             <Select
                id='sect1TransportChargeId'
                variant="outlined"
                type='text'
                name='sect1TransportChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect1TransportChargeId}
              >
                {dataListC01.map((dataListC01) => (
                  <MenuItem
                    key={dataListC01.id}
                    value={dataListC01.id}
                  >
                    {dataListC01.chargeCd}:{dataListC01.chargeName}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <th>排出日</th>
            <td>
                <TextField id='exhaustDate' variant="outlined" type='date' defaultValue={dataList2.exhaustDate} name='exhaustDate' margin="dense" onChange={(e) => handleChange(e)}  value={value.exhaustDate} />
            </td>
          </tr>

          <tr>
            <th>数量・単位</th>
            <td>
              <FormControl sx={{ minWidth: 200 }}>
                <TextField
                  error={inputError1}
                  inputProps={{ max: 99999.999, min:0.001, step:0.001 }}
                  inputRef={inputRef1}
                  defaultValue=""
                  id='amount1'
                  margin="dense"
                  variant="outlined"
                  type='number'
                  name='amount1'
                  helperText={inputRef1?.current?.validationMessage}
                  onChange={(e) => handleChange(e)}
                  value={value.amount1}
                />
                {/* <TextField id='amount1' variant="outlined" type='number' name='amount1' margin="dense" onChange={(e) => handleChange(e)}  value={value.amount1} /> */}
              </FormControl>
                <Typography variant="h7" style={{ fontWeight: 700 }}>　　{dataList2.unitName}</Typography>
            </td>
          </tr>
          <tr>
            <th>荷姿・荷姿の数量</th>
            <td>
              <FormControl sx={{ minWidth: 200 }}>
                <TextField
                  error={inputError2}
                  inputProps={{ max: 99999, min:1, step:1 }}
                  inputRef={inputRef2}
                  defaultValue=""
                  id='packingAmount1'
                  margin="dense"
                  variant="outlined"
                  type='number'
                  name='packingAmount1'
                  helperText={inputRef2?.current?.validationMessage}
                  onChange={(e) => handleChange(e)}
                  value={value.packingAmount1}
                />
                {/* <TextField id='packingAmount1' variant="outlined" type='number' name='packingAmount1' margin="dense" onChange={(e) => handleChange(e)} value={value.packingAmount1} /> */}
              </FormControl>
                <Typography variant="h7" style={{ fontWeight: 700 }}>　　{dataList2.packingName}</Typography>
            </td>
          </tr>
          <tr>
            <th>運搬方法・車両番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect1TransportMethodCd)]}</Typography>
                {/* <TextField id='sect1TransportMethodName' variant="outlined" type='text' name='sect1TransportMethodName' margin="dense" onChange={(e) => handleChange(e)} value={dataList2.sect1TransportMethodName} /> */}
                　　
                <TextField id='sect1CarNo' variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} />
            </td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
                <TextField id='memo1' variant="outlined" type='text' name='memo1' fullWidth multiline margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} />
            </td>
          </tr>
        </table>
        <table>
          <tr>
          <th>排出事業者<br/>承認コード</th>
          <td>
              <TextField id='exhaustPasswd' variant="outlined" type='text' name='exhaustPasswd' multiline margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustPasswd} />
          </td>
        </tr>
        </table>
      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
            handleSubmit(e);
          }}
          style={{ marginRight: 10 }}
//          disabled={!value.amount1 || !value.packingAmount1 ||inputError1 || inputError2}
//          disabled={!value.amount1 || inputError1 || inputError2}
          disabled={!value.exhaustDate || !value.amount1 || inputError1 || inputError2}
        >
          登録
        </Button>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      </div>
      </DialogContent>
    </Dialog>
  );
}else{   // 事業場区分は中間処理・排出事業者（２次マニフェスト登録）
  value.secondFlg = '1';
  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>排出（２次マニフェスト）登録</h2>
        <div style={{ textAlign: 'right' }}>
          <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
            }}>    {/*  egs_m_reserve_sets  */}
            戻る
          </Button>
          <SpaceRow height={10} />
        </div>
        <table>
          <tr>
            <th>ゴミタグ番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{garbageTag}</Typography>
            </td>
            <th>フロー番号</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveNo}</Typography>
            </td>
          </tr>
          <tr>
            <th>フロー名称</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.reserveName}</Typography>
            </td>
          </tr>
        </table>

      </DialogTitle>
      <DialogContent>

      <form className={classes.root}>

        <h3>【一次マニフェスト選択】</h3>
        <Grid
          container
          spacing={2}
        >
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>

        <Typography variant="h7" style={{ fontWeight: 700 }}>
          処分期間：  {/* 排出期間： */}
        </Typography>

        <TextField
          id="exhaustDate-start"
          label=""
          type={"date"}
          value={filter.disposalDateStart}  // exhaustDateStart
          onChange={e => setFilter({
            ...filter,
            disposalDateStart: e.target.value  // exhaustDateStart
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
        <TextField
          id="exhaustDate-start"
          label=""
          type={"date"}
          value={filter.disposalDateEnd}  // exhaustDateEnd
          onChange={e => setFilter({
            ...filter,
            disposalDateEnd: e.target.value  // exhaustDateEnd
          })}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            startFilter();
          }}
          style={{ marginLeft: 24 }}
        >
          検索
        </Button>
{/*}
        <Button variant="contained" color='secondary' style={{ marginLeft: 10 }}
//            onClick={(e) => handleSelect()} >
            onClick={(e) => handleChange(e)} >
            数量反映
          </Button>
*/}
      </Grid>
    </Grid>

        <div className={classes.container2}>
          <DataGridPro
            // pageSize={pageSize}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            // rowsPerPageOptions={[10, 20, 50]}
            // pagination
            sx={styles.grid}
            columns={cols}
            rows={filteredDataList}
            density="compact"
            autoHeight
            checkboxSelection
            disableSelectionOnClick
//            onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            onSelectionModelChange={(v) => selectChange(v)}
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        <h3>　↑ 対象の一次マニフェストを選択（ㇾ）して下さい</h3>
{/*}        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button variant="contained" color='primary' style={{ marginRight: 10 }}
            onClick={(e) => handleSelect()} >
            一次選択（数量計算）
          </Button>
          </Box>  */}
        {/* <FirstSelSelect
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList={dataList3}
        />
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen2} disabled={!dataList2.id}>
          １次マニフェスト選択
        </Button> */}

      <FormControl sx={{ minWidth: 200 ,marginLeft: 1,marginRight: -5}}>

        <table>
          <tr>
            <th>紙 マニフェスト番号</th>
            <td>
                <TextField id='manifestNo' variant="outlined" type='text' name='manifestNo' multiline margin="dense" onChange={(e) => handleChange(e)} value={value.manifestNo} />
            </td>
          </tr>
          <tr>
            <th>排出・担当</th>
            <td>
             <FormControl sx={{ minWidth: 200 }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.exhaustName}</Typography>
                　　
             </FormControl>
             <FormControl sx={{ minWidth: 200 }}>
              <Select
                id='exhaustChargeId'
                variant="outlined"
                type='text'
                name='exhaustChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.exhaustChargeId}
              >
                {dataListC00.map((dataListC00) => (
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>
            </td>
          </tr>
          <tr>
            <th>収集運搬・担当</th>
            <td>
             <FormControl sx={{ minWidth: 200 }}>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.sect1TransportName}</Typography>
                    　　
             </FormControl>
             <FormControl sx={{ minWidth: 200 }}>
              <Select
                id='sect1TransportChargeId'
                variant="outlined"
                type='text'
                name='sect1TransportChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.sect1TransportChargeId}
              >
                {dataListC01.map((dataListC01) => (
                  <MenuItem
                    key={dataListC01.id}
                    value={dataListC01.id}
                  >
                    {dataListC01.chargeCd}:{dataListC01.chargeName}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>
            </td>
          </tr>
        </table>

        <SpaceRow height={10} />

        <table>
          <tr>
            <th>排出日</th>
            <td>
                <TextField id='exhaustDate' variant="outlined" type='date' defaultValue={value.exhaustDate} name='exhaustDate' margin="dense" onChange={(e) => handleChange(e)}  value={dataList2.exhaustDate} />
            </td>
          </tr>

          <tr>
            <th>数量・単位</th>
            <td>
              <FormControl sx={{ minWidth: 200 }}>
                <TextField
                  error={inputError1}
                  inputProps={{ max: 99999.999, min:0.001, step:0.001 }}
                  inputRef={inputRef1}
                  defaultValue=""
                  id='amount1'
                  margin="dense"
                  variant="outlined"
                  type='number'
                  name='amount1'
                  helperText={inputRef1?.current?.validationMessage}
                  onChange={(e) => handleChange(e)}
                  value={value.amount1}
                />
                {/* <TextField id='amount1' variant="outlined" type='number' name='amount1' margin="dense" onChange={(e) => handleChange(e)}  value={value.amount1} /> */}
              </FormControl>
              <Typography variant="h7" style={{ fontWeight: 700 }}>　　{dataList2.unitName}</Typography>

            <Button variant="contained" color='secondary'
//              style={{ marginLeft: 10 }}
//              style={{ textAlign: 'right' }}
//              style={{ marginTop: 10, textAlign: 'right' }}
              style={{ marginTop: 10, marginLeft: 50 }}
//            onClick={(e) => handleSelect()} >
//              onClick={(e) => handleChange(e)} >
              onClick={(e) => amountChange(e)} >
              数量反映
            </Button>

            </td>
          </tr>
          <tr>
            <th>荷姿・荷姿の数量</th>
            <td>
              <FormControl sx={{ minWidth: 200 }}>
                <TextField
                  error={inputError2}
                  inputProps={{ max: 99999, min:1, step:1 }}
                  inputRef={inputRef2}
                  defaultValue=""
                  id='packingAmount1'
                  margin="dense"
                  variant="outlined"
                  type='number'
                  name='packingAmount1'
                  helperText={inputRef2?.current?.validationMessage}
                  onChange={(e) => handleChange(e)}
                  value={value.packingAmount1}
                />
                {/* <TextField id='packingAmount1' variant="outlined" type='number' name='packingAmount1' margin="dense" onChange={(e) => handleChange(e)} value={value.packingAmount1} /> */}
              </FormControl>
              <Typography variant="h7" style={{ fontWeight: 700 }}>　　{dataList2.packingName}</Typography>
            </td>
          </tr>
          <tr>
            <th>運搬方法・車両番号</th>
            <td>
              <Typography variant="h7" style={{ fontWeight: 700 }}>{transportMethodName[Number(dataList2.sect1TransportMethodCd)]}</Typography>
                {/* <TextField id='sect1TransportMethodName' variant="outlined" type='text' name='sect1TransportMethodName' margin="dense" onChange={(e) => handleChange(e)} value={dataList2.sect1TransportMethodName} /> */}
                　　
              <TextField id='sect1CarNo' variant="outlined" type='text' name='sect1CarNo' margin="dense" onChange={(e) => handleChange(e)} value={value.sect1CarNo} />
            </td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
{/*}                <TextField id='memo' variant="outlined" type='text' name='memo' fullWidth multiline margin="dense" onChange={(e) => handleChange(e)} value={value.memo} />  */}
                <TextField id='memo1' variant="outlined" type='text' name='memo1' fullWidth multiline margin="dense" onChange={(e) => handleChange(e)} value={value.memo1} />
            </td>
          </tr>
        </table>

        <SpaceRow height={10} />
{/*}
        <table>
          <tr>
          <th>排出事業者<br/>承認コード</th>
          <td>
              <TextField id='exhaustPasswd' variant="outlined" type='text' name='exhaustPasswd' multiline margin="dense" onChange={(e) => handleChange(e)} value={value.exhaustPasswd} />
          </td>
        </tr>
        </table>
*/}
        </FormControl>

      </form>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={(e) => {
            handleSubmit(e);
          }}
          style={{ marginRight: 10 }}
//          disabled={!value.amount1 || !value.packingAmount1 ||inputError1 || inputError2}
          disabled={!value.exhaustDate || !value.amount1 ||inputError1 || inputError2}
        >
          登録
        </Button>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      </div>
      </DialogContent>
    </Dialog>
  );
}
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsTGarbageEdiAddNew;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
