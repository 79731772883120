import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import SpaceRow from '../commons/SpaceRow';
import { makeStyles } from '@material-ui/core/styles';
import './app.css';
import dayjs from 'dayjs';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Checkbox
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import FormControl from '@mui/material/FormControl';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '処分登録',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root0: {    // 管理者画面用
    '& > *': {
      margin: theme.spacing(1),
      width: '80ch',  // '100ch',  // このサイズが下段のボックスサイズ
    },
  },
  root1: {    // 処分業者用
    '& > *': {
      margin: theme.spacing(1),
      width: '140ch',  // '100ch',  // このサイズが下段のボックスサイズ
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function DisposalListTable(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

//  const { dataList, dataListC00, currentUser } = props;
  const { dataList, dataListC00, dataListW00, dataListE00, dataListT00, currentUser } = props;  // wk_charge_id
  const history = useHistory();
  const [rows, setRows] = React.useState(dataList);
  const [checked, setChecked] = React.useState(false);
  let selRows = React.useRef([]);
  // console.log(selRows);
  // console.log('dataListW00',dataListW00);
  // console.log('dataListE00',dataListE00);
  // console.log('dataListT00',dataListT00);
  const [pageSize, setPageSize] = React.useState(10);
  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
    exhaustDateStart: '',
    exhaustDateEnd: '',
    exhaustNameFilter: '',
    sect1TransportNameFilter: '',
    wasteNameFilter1: '',
    wasteNameFilter2: '',
    wasteNameFilter3: '',
  })

  const today = dayjs().format('YYYY-MM-DD');
  const wk_today = today;
  // console.log('today',today);
//  value.disposalDate = today;

  const [value, setValue] = useState({
    disposalDate: wk_today, // '',
    disposalChargeId: '',
    disposalChargeCd: '',   // 20240418
    disposalChargeName: '', // 20240418
    lastReportFlg: false,

  });

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
    // console.log('dataList', dataList);
    // console.log('currentUser', currentUser);
    let originalList = dataList;

    // console.log('originalList', originalList);
    // console.log('filter', filter);

    if (filter.exhaustDateStart) {
      const startDate = dayjs(filter.exhaustDateStart, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return startDate.isBefore(exhaustDate, "days") || startDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustDateEnd) {
      const endDate = dayjs(filter.exhaustDateEnd, 'YYYY-MM-DD');
      originalList = originalList.filter(item => {
        const exhaustDate = dayjs(item.exhaustDate, "YYYY-MM-DD");
        return endDate.isAfter(exhaustDate, "days") || endDate.isSame(exhaustDate, "days");
      });
    }
    if (filter.exhaustNameFilter) {
      originalList = originalList.filter(item => item.exhaustName === filter.exhaustNameFilter);
    }

    if (filter.wasteNameFilter1) {
      originalList = originalList.filter(item => item.wasteName === filter.wasteNameFilter1);
    }

    if (filter.sect1TransportNameFilter) {
      originalList = originalList.filter(item => item.sect1TransportName === filter.sect1TransportNameFilter);
    }

    setFilteredDataList(originalList);
  }

  const handleChange = (e) => {

    if (e.target.name == 'disposalChargeId') {
      value.disposalChargeCd = e.currentTarget.innerText.split(':')[0]  // 20240418
      value.disposalChargeName = e.currentTarget.innerText.split(':')[1]  // 20240418
    }

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue',value);
  const handleChange2 = (event) => {
    setChecked(event.target.checked);
    value.lastReportFlg = event.target.checked;
  };

  const handleSubmit = async (e) => {
    //e.preventDefault();
    if (window.confirm(' 処分登録を行います。よろしいですか？')) {

      // console.log('selRows',selRows);
      var params = {};

      for (let i = 0; i<selRows.current.length; i++){
        const resdataList = filteredDataList.filter(filteredDataList => {
          return filteredDataList.id === selRows.current[i]
        })
        // console.log('resdataList',resdataList)
        // console.log('resdataList.nextstatus',resdataList[0].nextStatus)

//  2023/03/15 改造  １．最終処分チェックボックスがオンの時は、強制的に71:最終処分済とする
//                   ２．管理ユーザにも項番１を適用する　　⇒　最終処分業者と、中間処分業者・管理者で機能を分ける　⇒　事業者区分の管理者判断をなくす
//                   ３．最終処分業者は紙マニフェストになるので、この機能は使用しない認識

{/*}
        if (currentUser.traderKbn == '1111111111') {
          params = {
            status: resdataList[0].nextStatus,
            middle_end: value.disposalDate,
//            destination1_charge_id: value.disposalChargeId,
//0313            last_processing_charge_id: value.disposalChargeId,
            edi_send_flg: 61,
          };
        }else{
*/}
          if (currentUser.traderKbn == '0000100000') {  // 最終処分業者
            params = {
              status: resdataList[0].nextStatus,
              middle_end: value.disposalDate,
//              destination1_charge_id: value.disposalChargeId,
              last_processing_charge_id: value.disposalChargeId,
              last_processing_charge_cd: value.disposalChargeCd,  // 20240418
              last_processing_charge_name: value.disposalChargeName,  // 20240418
              edi_send_flg: 71,
            };
          }else{          //  中間処分業者または管理者
            // if (resdataList[0].ediSendFlg == '01' || resdataList[0].ediSendFlg == '02' ||
            //     resdataList[0].ediSendFlg == '11' || resdataList[0].ediSendFlg == '12'){
            //   params = {
            //     status: resdataList[0].nextStatus,
            //     middle_end: value.disposalDate,
            //     destination1_charge_id: value.disposalChargeId,
            //   };
            // }else{
            //   params = {
            //     status: resdataList[0].nextStatus,
            //     middle_end: value.disposalDate,
            //     destination1_charge_id: value.disposalChargeId,
            //     edi_send_flg: 61,
            //   };
            // }

//            if ((resdataList[0].ediSendFlg == '13' && resdataList[0].destination2_cd == null) ||
//                (resdataList[0].ediSendFlg == '23' && resdataList[0].destination3_cd == null) ||
//                (resdataList[0].ediSendFlg == '33' && resdataList[0].destination4_cd == null) ||
//                (resdataList[0].ediSendFlg == '43' && resdataList[0].destination5_cd == null)){
            if ((resdataList[0].ediSendFlg == '13' && resdataList[0].destination2Cd == null) ||
                (resdataList[0].ediSendFlg == '23' && resdataList[0].destination3Cd == null) ||
                (resdataList[0].ediSendFlg == '33' && resdataList[0].destination4Cd == null) ||
                (resdataList[0].ediSendFlg == '43' && resdataList[0].destination5Cd == null)){
              if (value.lastReportFlg) {  // 最終処分＝1:最終処分選択時、中間最終
//              if (event.target.checked == '1') {  // 最終処分＝1:最終処分選択時、中間最終
                  params = {
                  status: 71,
//0324                  middle_end: value.disposalDate,
  //                destination1_charge_id: value.disposalChargeId,
                  last_processing_charge_id: value.disposalChargeId,
                  last_processing_charge_cd: value.disposalChargeCd,  // 20240418
                  last_processing_charge_name: value.disposalChargeName,  // 20240418
    //                  last_report_flg: value.lastReportFlg,
                  last_report_flg: '1',
                  edi_send_flg: 71,
                  last_processing_end: value.disposalDate,
                };
              }else{    // 最終処分=0:最終処分非選択時、中間処分

                //  区間1～5判定
                switch (true) {
                  case resdataList[0].destination5Id > 0:   // 区間5
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination5_charge_id: value.disposalChargeId,
                      destination5_charge_cd: value.disposalChargeCd,  // 20240418
                      destination5_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination4Id > 0:   // 区間4
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination4_charge_id: value.disposalChargeId,
                      destination4_charge_cd: value.disposalChargeCd,  // 20240418
                      destination4_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination3Id > 0:   // 区間3
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination3_charge_id: value.disposalChargeId,
                      destination3_charge_cd: value.disposalChargeCd,  // 20240418
                      destination3_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination2Id > 0:   // 区間2
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination2_charge_id: value.disposalChargeId,
                      destination2_charge_cd: value.disposalChargeCd,  // 20240418
                      destination2_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  default:                                  // 区間1
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination1_charge_id: value.disposalChargeId,
                      destination1_charge_cd: value.disposalChargeCd,  // 20240418
                      destination1_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                }

              }

            }else{
              if (value.lastReportFlg) {  // 最終処分＝1:最終処分選択時、中間最終
                params = {
                  status: 71,
//0324                  middle_end: value.disposalDate,
  //                destination1_charge_id: value.disposalChargeId,
                  last_processing_charge_id: value.disposalChargeId,
                  last_processing_charge_cd: value.disposalChargeCd,  // 20240418
                  last_processing_charge_name: value.disposalChargeName,  // 20240418
//                  last_report_flg: value.lastReportFlg,
                  last_report_flg: '1',
                  edi_send_flg: 71,
                  last_processing_end: value.disposalDate,
                };
              }else{

                //  区間1～5判定
                switch (true) {
                  case resdataList[0].destination5Id > 0:   // 区間5
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination5_charge_id: value.disposalChargeId,
                      destination5_charge_cd: value.disposalChargeCd,  // 20240418
                      destination5_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination4Id > 0:   // 区間4
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination4_charge_id: value.disposalChargeId,
                      destination4_charge_cd: value.disposalChargeCd,  // 20240418
                      destination4_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination3Id > 0:   // 区間3
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination3_charge_id: value.disposalChargeId,
                      destination3_charge_cd: value.disposalChargeCd,  // 20240418
                      destination3_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  case resdataList[0].destination2Id > 0:   // 区間2
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination2_charge_id: value.disposalChargeId,
                      destination2_charge_cd: value.disposalChargeCd,  // 20240418
                      destination2_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                  default:                                  // 区間1
                    params = {
                      status: 61,    //resdataList[0].nextStatus,
      //                destination2_at: value.receiptDate,
                      middle_end: value.disposalDate,
                      destination1_charge_id: value.disposalChargeId,
                      destination1_charge_cd: value.disposalChargeCd,  // 20240418
                      destination1_charge_name: value.disposalChargeName,  // 20240418
      //0313                last_processing_charge_id: value.disposalChargeId,
      //                last_report_flg: value.lastReportFlg,
                      last_report_flg: '0',
                      edi_send_flg: 61,
                      };
                    break;
                }
              

              }
            }
//        }
        }
        // console.log('selRows.current[i]',selRows.current[i]);
        // console.log('params',params);
        try {
        setOpen(!open);
          const res = await updateEgsTGarbageEdi(selRows.current[i], params);
          // console.log(res);
        setOpen(false);
        } catch (e) {
          console.log(e);
        }
      }
      if (window.confirm(' 処分登録を行いました。廃棄物一覧を表示しますか？')) {
        history.push('/'); // 画面更新
      }else{
        window.location.reload();
      }
    }
//  }
  };

//  if (currentUser.traderKbn == '1111111111') {
//  }else{
//  }


  const [errorReceiptDate, setErrorReceiptDate] = useState(false);  // 処分日
  const [errorReceiptCharge, setErrorReceiptCharge] = useState(false);  // 処分担当

  const checkValidation = () => {
    let available = true;

    // 行選択チェック
      if (selRows.current.length == 0) {
        alert('更新対象を選択してください')
        available = false;
      }

    // 処分日チェック
      if (value.disposalDate > wk_today) {
        alert('処分日が未来日です <' + value.disposalDate + '>')
          available = false;
          setErrorReceiptDate(true);
      } else {
          if (!value.disposalDate) {
            alert('処分登録日が未入力です')
            available = false;
            setErrorReceiptDate(true);
          }else{
            setErrorReceiptDate(false);
          }
      }

    // 処分日チェック

    if (value.disposalChargeId == '' && currentUser.traderKbn !== '1111111111') {
      alert('処分登録担当者が未入力です <' + value.disposalChargeId + '>')
        available = false;
        setErrorReceiptCharge(true);
    }

//      if (!available) {
//        alert('入力に誤りがあります')
//      }

    // 処分担当チェック

      if (currentUser.traderKbn !== '1111111111' && value.receiptChargeId == 0) {
          alert('処分担当を選択してください')
          available = false;
          setErrorReceiptCharge(true);
      }

//      if (!available) {
//          alert('入力に誤りがあります')
//      }

      return available;
  }


if (currentUser.traderKbn == '1111111111') {

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
        field: 'exhaustDate',
        headerName: '排出日',
        width: 100
    },
    {
        field: 'destinationAt',
        headerName: '荷受日',
        width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',
      headerName: '廃棄物名称',
      width: 150
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width:100
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'sect1TransportName',
      headerName: '収集運搬業者',
      width: 300
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '運搬担当者',
      width: 100
    },
    {
      field: 'amount1',
      headerName: '数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 150
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
     },
    {
      field: 'status',
      headerName: 'Status',
      width: 70
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 120
    },
    {
      field: 'nextStatus',
      headerName: '次Status',
      width: 80
    }
  ]

  return (
    <>
      <Grid
        container
        spacing={2}
      >
{/*}
        <Grid item xs={1}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出期間：
            </Typography>
          </Box>
        </Grid>
*/}
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
{/*}
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateStart}
            onChange={e => setFilter({
              ...filter,
              exhaustDateStart: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateEnd}
            onChange={e => setFilter({
              ...filter,
              exhaustDateEnd: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
*/}

          <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          <TextField id="exhaustDate-start" label="" type={"date"} value={filter.exhaustDateStart} onChange={e => setFilter({...filter,exhaustDateStart: e.target.value })} className={classes.textField} InputLabelProps={{shrink: true,}} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>～</Typography>
          <TextField id="exhaustDate-end" label="" type={"date"} value={filter.exhaustDateEnd} onChange={e => setFilter({...filter,exhaustDateEnd: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
{/*}          <TextField id="exhaustNameFilter" label="" type={"text"} value={filter.exhaustNameFilter} onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

           <FormControl sx={{ minWidth: 250 }}>
            <Select
                id='exhaustNameFilter'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.exhaustNameFilter}
                value={filter.exhaustNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListE00.map((dataListE00) => (
                  <MenuItem
                    key={dataListE00.exhaustName}
                    value={dataListE00.exhaustName}
                  >
                    {dataListE00.exhaustCd}:{dataListE00.exhaustName}  {/*{dataListE00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
           <FormControl sx={{ minWidth: 300 }}>
{/*}          <TextField id="wasteNameFilter1" label="" type={"text"} value={filter.wasteNameFilter1} onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}
          <Select
                id='wasteNameFilter1'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteNameFilter1'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.wasteNameFilter1}
                value={filter.wasteNameFilter1}
//                defaultValue={wk_charge_id}
              >
                {dataListW00.map((dataListW00) => (
                  <MenuItem
                    key={dataListW00.wasteclassName}
                    value={dataListW00.wasteclassName}
                  >
                    {dataListW00.wasteclassCd}:{dataListW00.wasteclassName}  {/*{dataListW00.wasteclassCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　収集運搬業者：</Typography>
           <FormControl sx={{ minWidth: 250 }}>
{/*}          <TextField id="sect1TransportNameFilter" label="" type={"text"} value={filter.sect1TransportNameFilter} onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

              <Select
                id='sect1TransportNameFilter'
                label='収集運搬業者'
                variant="outlined"
                type='text'
                name='sect1TransportNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.sect1TransportNameFilter}
                value={filter.sect1TransportNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListT00.map((dataListT00) => (
                  <MenuItem
                    key={dataListT00.sect1TransportName}
                    value={dataListT00.sect1TransportName}
                  >
                    {dataListT00.sect1TransportCd}:{dataListT00.sect1TransportName}  {/*{dataListT00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              startFilter();
            }}
            style={{ marginLeft: 24 }}
          >
            検索
          </Button>
        </Grid>
        </Grid>


    <div className={classes.container}>
    <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
//          pageSize={pageSize}
//          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//          rowsPerPageOptions={[10, 20, 50]}
//          pagination
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}
          density="compact"
//          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      </div>
      <h3>　↑ 処分登録対象の廃棄物を選択（ㇾ）して下さい</h3>
      <SpaceRow height={20} />
      <form className={classes.root0}>
        <table>
          <tr>
          </tr><tr>
          </tr><tr>
{/*}            <FormControl sx={{ minWidth: 100 ,marginTop: 2}}>  */}
            <FormControl sx={{ minWidth: 100 ,marginTop: 2 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <th>処分登録日</th>
            </FormControl>
{/*}            <td>  */}
{/*}             <FormControl sx={{ minWidth: 120 ,marginLeft: -54}}>  {/*, marginRight: -200*/}
             <FormControl sx={{ minWidth: 120 ,marginTop: 1 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <TextField id='disposalDate' variant="outlined" type='date' defaultValue={value.disposalDate} name='disposalDate' halfWidth margin="dense" value={value.disposalDate}
                onChange={(e) => handleChange(e)}
                error={errorReceiptDate}
                />
             </FormControl>
{/*}            </td>  */}
{/*}
            <FormControl sx={{ minWidth: 120 ,marginTop: 2}}>
              <th>処分登録担当者</th>
            </FormControl>
            <td>
            <FormControl sx={{ minWidth: 150 }}>
            <Select
                id='disposalChargeId'
                label='処分登録担当者'
                variant="outlined"
                type='text'
                name='disposalChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                value={value.disposalChargeId}
              >
                {dataListC00.map((dataListC00) => (   // chargedataList00
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </td>
*/}

            <FormControl sx={{ minWidth: 80 ,marginTop: 2 ,marginBottom: 1 ,marginLeft: 1 ,marginRight: 1}}>
              <th>最終処分</th>
            </FormControl>

            <FormControl sx={{ minWidth: 50 ,marginTop: 1 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <Checkbox
                checked={checked}
                onChange={handleChange2}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </FormControl>

          </tr>
        </table>
      </form>

        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button
           variant="contained"
           color='primary'
//           onClick={(e) => handleSubmit()}
           onClick={(e) => {
//            handleSubmit(e);
              if (checkValidation()) {
                  handleSubmit(e);
                  }
              }}
//           disabled={!selRows.current.length}
           >
            処分登録実行
          </Button>
        </Box>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>

  );
}else{

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
        field: 'exhaustDate',
        headerName: '排出日',
        width: 100
    },
    {
        field: 'destinationAt',
        headerName: '荷受日',
        width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteName',
      headerName: '廃棄物名称',
      width: 150
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width:100
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
//    {
//      field: 'sect1TransportName',
//      headerName: '収集運搬業者',
//      width: 300
//    },
    {
      field: 'sect1TransportChargeName',
      headerName: '運搬担当者',
      width: 100
    },
    {
      field: 'amount1',
      headerName: '数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 150
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
     },
    {
      field: 'status',
      headerName: 'Status',
      width: 70
    },
    {
      field: 'statusName',
      headerName: '状態',
      width: 120
    },
    {
      field: 'nextStatus',
      headerName: '次Status',
      width: 80
    }
  ]

  return (
    <>
      <Grid
        container
        spacing={2}
      >
{/*}
        <Grid item xs={1}>
          <Box p={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>
              排出期間：
            </Typography>
          </Box>
        </Grid>
*/}
        <Grid item xs style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "left", // center
          justifyContent: "flex-start",
        }}>
{/*}
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateStart}
            onChange={e => setFilter({
              ...filter,
              exhaustDateStart: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}> ～ </div>
          <TextField
            id="exhaustDate-start"
            label=""
            type={"date"}
            value={filter.exhaustDateEnd}
            onChange={e => setFilter({
              ...filter,
              exhaustDateEnd: e.target.value
            })}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
*/}
          <Typography variant="h7" style={{ fontWeight: 700 }}>排出期間：</Typography>
          <TextField id="exhaustDate-start" label="" type={"date"} value={filter.exhaustDateStart} onChange={e => setFilter({...filter,exhaustDateStart: e.target.value })} className={classes.textField} InputLabelProps={{shrink: true,}} />
          <Typography variant="h7" style={{ fontWeight: 700 }}>～</Typography>
          <TextField id="exhaustDate-end" label="" type={"date"} value={filter.exhaustDateEnd} onChange={e => setFilter({...filter,exhaustDateEnd: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
          <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
{/*}          <TextField id="exhaustNameFilter" label="" type={"text"} value={filter.exhaustNameFilter} onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

           <FormControl sx={{ minWidth: 250 }}>
            <Select
                id='exhaustNameFilter'
                label='排出事業場'
                variant="outlined"
                type='text'
                name='exhaustNameFilter'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,exhaustNameFilter: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.exhaustNameFilter}
                value={filter.exhaustNameFilter}
//                defaultValue={wk_charge_id}
              >
                {dataListE00.map((dataListE00) => (
                  <MenuItem
                    key={dataListE00.exhaustName}
                    value={dataListE00.exhaustName}
                  >
                    {dataListE00.exhaustCd}:{dataListE00.exhaustName}  {/*{dataListE00.exhaustCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

          <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物名称：</Typography>
           <FormControl sx={{ minWidth: 250 }}>
{/*}          <TextField id="wasteNameFilter1" label="" type={"text"} value={filter.wasteNameFilter1} onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>  */}

          <Select
                id='wasteNameFilter1'
                label='廃棄物名称'
                variant="outlined"
                type='text'
                name='wasteNameFilter1'
                margin="dense"
//                onChange={(e) => handleChange(e)}
                onChange={e => setFilter({...filter,wasteNameFilter1: e.target.value})}
//                error={errorReceiptCharge}
//                value={value.wasteNameFilter1}
                value={filter.wasteNameFilter1}
//                defaultValue={wk_charge_id}
              >
                {dataListW00.map((dataListW00) => (
                  <MenuItem
                    key={dataListW00.wasteclassName}
                    value={dataListW00.wasteclassName}
                  >
                    {dataListW00.wasteclassCd}:{dataListW00.wasteclassName}  {/*{dataListW00.wasteclassCd}:*/}
                  </MenuItem>
                ))}
              </Select>
             </FormControl>

{/*}
          <Typography variant="h7" style={{ fontWeight: 700 }}>　収集運搬業者：</Typography>
          <TextField id="sect1TransportNameFilter" label="" type={"text"} value={filter.sect1TransportNameFilter} onChange={e => setFilter({...filter,sect1TransportNameFilter: e.target.value})} className={classes.textField} InputLabelProps={{shrink: true,}}/>
*/}
          <Button
            type='button'
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              startFilter();
            }}
            style={{ marginLeft: 24 }}
          >
            検索
          </Button>
        </Grid>
        </Grid>


    <div className={classes.container}>
    <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
//          pageSize={pageSize}
//          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//          rowsPerPageOptions={[10, 20, 50]}
//          pagination
          sx={styles.grid}
          columns={cols}
          rows={filteredDataList}
          density="compact"
//          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      </div>
      <h3>　↑ 処分登録対象の廃棄物を選択（ㇾ）して下さい</h3>
      <SpaceRow height={20} />
      <form className={classes.root1}>
        <table>
          <tr>
          </tr><tr>
          </tr><tr>
            <FormControl sx={{ minWidth: 100 ,marginTop: 2 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <th>処分登録日</th>
            </FormControl>
{/*}            <td>  */}
             <FormControl sx={{ minWidth: 120 ,marginTop: 1 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <TextField id='disposalDate' variant="outlined" type='date' defaultValue={value.disposalDate} name='disposalDate' halfWidth margin="dense" value={value.disposalDate}
                onChange={(e) => handleChange(e)}
                error={errorReceiptDate}
                />
             </FormControl>
{/*}            </td>  */}
{/*}            </FormControl>  */}

            <FormControl sx={{ minWidth: 120 ,marginTop: 2 ,marginRight: 1}}>
              <th>処分登録担当者</th>
            </FormControl>
            <td>
            <FormControl sx={{ minWidth: 220 ,marginLeft: 1}}>
            <Select
                id='disposalChargeId'
                label='処分登録担当者'
                variant="outlined"
                type='text'
                name='disposalChargeId'
                margin="dense"
                onChange={(e) => handleChange(e)}
                error={errorReceiptCharge}
                value={value.disposalChargeId}
              >
                {dataListC00.map((dataListC00) => (   // chargedataList00
                  <MenuItem
                    key={dataListC00.id}
                    value={dataListC00.id}
                  >
                    {dataListC00.chargeCd}:{dataListC00.chargeName}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </td>

{/*}            </FormControl>  */}
{/*}        <FormControl sx={{ minWidth: 80 ,marginTop: 2 ,marginBottom: 1 ,marginLeft: 1 ,marginRight: 1}}> */}
            <FormControl sx={{ minWidth: 80 ,marginTop: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <th>最終処分</th>
            </FormControl>
{/*}            <td>  */}
            {/* <Select
              id='lastReportFlg'
              label='最終処分'
              variant="outlined"
              type='text'
              name='lastReportFlg'
              margin="dense"
              onChange={(e) => handleChange(e)}
              value={value.lastReportFlg}
            >
              <MenuItem value={value.lastReportFlg}>
              </MenuItem>
              <MenuItem value={''}>（未選択）</MenuItem>
              <MenuItem value={'1'}>最終処分</MenuItem>
            </Select> */}
             <FormControl sx={{ minWidth: 50 ,marginTop: 1 ,marginBottom: 2 ,marginLeft: 1 ,marginRight: 1}}>
              <Checkbox
                checked={checked}
                onChange={handleChange2}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </FormControl>
{/*}            </td>  */}
          </tr>
        </table>
      </form>

        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button
           variant="contained"
           color='primary'
//           onClick={(e) => handleSubmit()}
           onClick={(e) => {
//            handleSubmit(e);
              if (checkValidation()) {
                  handleSubmit(e);
                  }
              }}
//           disabled={!selRows.current.length}
            >
            処分登録実行
          </Button>
        </Box>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>

  );
}

}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default DisposalListTable;
