import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
// import { getGarbageCsvList, getGarbageEdiUser } from '../../lib/api/garbage_edi';
import { getGarbageDetailCsvList,getGarbageDetailCsvUser,  getGarbageDetailCsvGroup1, getGarbageDetailCsvGroup2  } from '../../lib/api/garbage_detail_csv';
import { deleteEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import { getGarbageDetailCsvCreate  } from '../../lib/api/garbage_detail_csv';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import GarbageCsvListTable from './GarbageCsvListTable';
//import GarbageDetailCsvListTable from './GarbageDetailCsvListTable';

import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GarbageCsvList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      // setOpen(!open);
      // res = await getGarbageDetailCsvOperation(currentUser.traderCd + '_' + currentUser.traderKbn)
      // setDataList(res.data);
      // setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleExportCSV = async () => {
    if (window.confirm(' CSVファイル（全件・UTF-8形式）を作成しダウンロードします。よろしいですか？')) {
      try {
        setOpen(!open);
        const response = await getGarbageDetailCsvCreate(currentUser.traderCd);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const today = dayjs().format('YYYYMMDD');
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SDGs12_WasteData_'+today+'.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        window.alert(' CSVファイル 「'+ a.download + '」をダウンロードしました。')
        setOpen(false);
      } catch (error) {
        console.error('CSVエクスポートエラー:', error);
      }
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {
    if (window.confirm(' ゴミタグ「' + item.garbageTag + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsTGarbageEdi(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  const handleCancel = async (item) => {
    if (window.confirm(' ゴミタグ「' + item.garbageTag + '」を取消（論理削除し）ます。よろしいですか？')) {
      try {
        const res = await deleteEgsTGarbageEdi(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>廃棄物一覧CSV出力用</h1>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleExportCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（全件・UTF-8形式）
      </Button>
      　　最新データへの更新時刻：10:11 13:11 16:11 23:11
      <SpaceRow height={10} />
      <GarbageCsvListTable
        dataList={dataList}    // resdataList
        handleDelete={handleDelete}
        currentUser={currentUser}
        handleCancel={handleCancel}
      />
    </>
  );
};
export default GarbageCsvList;

/*
        onClick={() => history.push('/garbage_reserve_select')}

        <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/garbage_infoPdf')}
      >
        PDF出力
      </Button>
*/
