import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { createEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import { createSagyoReport } from '../../lib/api/sagyo_report';
import { getEgsTGarbageTmpExhaust, deleteEgsTGarbageTmp } from '../../lib/api/egs_t_garbage_tmp';

import QRReaderDialog from './QRReaderDialog';
import RsvQRReaderDialog from '../dashboard/RsvQRReaderDialog';
import SpaceRow from '../commons/SpaceRow';

import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
import { getChargeUser } from '../../lib/api/charge';
import { updateEgsTGarbageTag } from '../../lib/api/egs_t_garbage_tag';
import { getEgsMTraderUser }  from '../../lib/api/egs_m_trader';

import { makeStyles,styled } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Typography from '@material-ui/core/Typography';
import { transportMethodName } from '../../constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

function MblGarbageTmpList() {
  const classes = useStyles();
  const query = useParams();
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState({});
  const [sagyodataList, setSagyoDataList] = useState({});

  const [value, setValue] = useState({
    telNo: '',
    wasteKind: '',
  });
  const [loading, setLoading] = useState(false);
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [dialogopen2, setDialogOpen2] = React.useState(false);
  const [qrcodeValue, setQrcodeValue] = React.useState();
  const [qrcodeTrader, setQrcodeTrader] = React.useState();
  const [mblUser, setMblUser] = React.useState();

  const inputRef1 = useRef(null);
  const [inputError1, setInputError1] = useState(false);
  const inputRef2 = useRef(null);
  const [inputError2, setInputError2] = useState(false);        

  useEffect(() => {
    handleGetData(query);
    const exhaustTelno = Cookies.get('exhaust_telno');
    setValue({telNo: exhaustTelno});
  }, [query]);

  useEffect(() => {
    if (qrcodeValue) {
      // 区切り文字に「/」を指定
      var rtn = qrcodeValue.split('/');
      // 2番目の要素から3番目の要素まで取り出し（ドメイン）
      var rtn2 = rtn.slice(2, 3);
      // 3番目の要素から4番目の要素まで取り出し（廃棄物タグwaste）
      var rtn3 = rtn.slice(3, 4);
      // 4番目の要素から5番目の要素まで取り出し（ゴミタグ）
      var rtn4 = rtn.slice(4, 5)[0];
    
      var rtnflowtag = rtn4.split('-');
      var rtnflowtag1 = rtnflowtag.slice(1, 2);
      var rtnflowtag2 = rtnflowtag.slice(2, 3);
    
      // 廃棄物タグQRの場合は入力画面、排出担当QRの場合は仮登録一覧
      if (rtn3[0] === "waste") { 
        if (rtnflowtag2.length === 0) {
          var newRtnflowtag = rtn4 + dayjs().unix();
          // console.log("newRtnflowtag", newRtnflowtag);
          history.push(`/mbl_emission_set/${newRtnflowtag}`);
        } else {
          history.push(`/mbl_emission_set/${rtn4}`);
        }
      }else if (rtn3[0].length === 10) {
        history.push(`/mbl_garbage_tmp/${rtn4}`);
      }
    };
  }, [qrcodeValue]);
  
  useEffect(() => {
    if (qrcodeTrader) {
      // 区切り文字に「/」を指定
      var rtn = qrcodeTrader.split( '/' );
      // 2番目の要素から3番目の要素まで取り出し（ドメイン）
      var rtn2 = rtn.slice(2, 3);
      // 3番目の要素から4番目の要素まで取り出し（事業場区分）
      var rtn3 = rtn.slice(3, 4);
      // 4番目の要素から5番目の要素まで取り出し（事業場コード）
      var rtn4 = rtn.slice(4, 5);
      // 5番目の要素から6番目の要素まで取り出し（担当者コード）
      var rtn5 = rtn.slice(5, 6);
      // 4番目の要素から5番目の要素まで取り出し（承認コード）
      var rtn6 = rtn.slice(6, 7);
      const fetchTraderUser = async () => {
        try {
          const response = await getEgsMTraderUser(query.id);
          // responseを使用した処理をここに記述
          // 例: 承認コードが一致するかチェック
          if (rtn6[0] === response.data[0].exhaustPasswd) {
            // 承認コードが一致した場合の処理
            const rescharge = await getChargeUser(rtn4);
            // console.log("rescharge",rescharge)
                  // rtn5と一致するデータを取り出す
            const matchingDataArray = rescharge.data.filter(item => item.chargeCd === rtn5[0]);

            // 一致するデータが見つかった場合の処理
            if (matchingDataArray.length > 0) {
              // console.log("Matching data:", matchingDataArray);

              setLoading(true);
              for (let i = 0; i<dataList.length; i++){
                try {
                  // console.log("dataList",dataList[i]);
                  // 新しいオブジェクトを作成し、exhaustChargeIdを設定
                  const newData = {
                    ...dataList[i],
                    exhaustChargeId: matchingDataArray[0].id,
                    exhaustChargeName: matchingDataArray[0].chargeName,
                    exhaustChargeCd: matchingDataArray[0].chargeCd
                  };
                  const res = await createEgsTGarbageEdi(newData);
                  // console.log("createEgsTGarbageEdi_res",res);
                  const res2 = await deleteEgsTGarbageTmp(newData.id);
                  // console.log("deleteEgsTGarbageTmp_res2",res2);
                } catch (e) {
                  console.log(e);
                }
              };
              // console.log("mblUser",mblUser);
              // const currentDate = new Date();
              // const currentTime = currentDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});             
              // const sr_params = {
              //   chargeId: mblUser,
              //   sagyoDate: currentDate.toISOString().slice(0, 10), // 現在日付を設定
              //   sagyoTime: currentTime, // 現在時刻を設定
              //   sagyoPlace: rescharge.data[0].traderName,
              //   sagyoTask: '廃棄物収集',
              //   sagyoMemo: ''
              // };
              const currentDate = dayjs().format('YYYY-MM-DD');
              const currentTime = dayjs().format('HH:mm');
              const sr_params = {
                  chargeId: mblUser,
                  sagyoDate: currentDate,
                  sagyoTime: currentTime,
                  sagyoPlace: rescharge.data[0].traderName,
                  sagyoTask: '廃棄物収集',
                  sagyoMemo: ''
              };          
              //  console.log('sr_params',sr_params)
              try {
                const res = await createSagyoReport(sr_params);
                // console.log("sr_createSagyoReport_res",res);
              } catch (e) {
                console.log(e);
                console.log(e.response);
              }
      
              setLoading(false);
              window.alert('排出本登録を完了しました。')
              history.push(`/` );
            } else {
              window.alert('排出事業者 担当者コードが一致しません。確認して再登録ください。')
            }
          } else {
            // 承認コードが一致しない場合の処理
            window.alert('排出事業者 承認コードが一致しません。確認して再登録ください。')
          }
        } catch (error) {
          console.error('Error fetching trader user:', error);
        }
      };
      if (window.confirm('排出本登録（マニフェスト情報作成）を行います。よろしいですか？')) {  // getEgsMTraderUserを非同期で呼び出す関数を定義
        // 非同期関数を実行
        fetchTraderUser();
      }   
    };
  }, [qrcodeTrader]);

  const handleGetData = async (query) => {
    const res = await getEgsTGarbageTmpExhaust(query.id);
    // console.log("res",res);
    const mbluser = Cookies.get('mbl_user');
    const mbluser2 = mbluser.split(':');
    // console.log("mbluser2",mbluser2);
    setMblUser(mbluser2[0]);
    // mbluser2[0]とres.data.sect1TransportChargeIdが一致するデータのみをフィルタリング
    const filteredData = res.data.filter(item => item.sect1TransportChargeId.toString() === mbluser2[0]);
    // console.log("filteredData",filteredData);  
    setDataList(filteredData);
  };
  const handleClickRsvno = async() => {
    history.push(`/mbl_process_flow/${value.telNo}${value.wasteKind}` );
  };

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2",dataList2);
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
    if (inputRef1.current) {
      const ref = inputRef1.current;
      if (!ref.validity.valid) {
        setInputError1(true);
      } else {
        setInputError1(false);
      }
    }
    if (inputRef2.current) {
      const ref = inputRef2.current;
      if (!ref.validity.valid) {
        setInputError2(true);
      } else {
        setInputError2(false);
      }
    }
  };

  // 編集
  const handleClickUpdate = () => {
    history.push(`/mbl_emission_update/${dataList2.garbageTag}` );
  };

  // 行の削除
  const delRows = () => {
    //    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  };

  const handleDelete = async (item) => {
    if (window.confirm(' 仮登録「' + item.wasteName + '」を削除します。よろしいですか？')) {
      setLoading(true);
      try {
        const res = await deleteEgsTGarbageTmp(item.id);
        // console.log(res.data);
        // handleGetData(item.exhaustCd);
        window.location.reload();
      } catch (e) {
        console.log(e.response);
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    if (window.confirm('排出本登録（マニフェスト情報作成）を行います。よろしいですか？')) {

      e.preventDefault();
      // console.log("排出事業者CD",value.exhaustCd)
      const res = await getEgsMTraderUser(query.id);
      // console.log("承認コードres",res.data[0].exhaustPasswd)
      // console.log("dataList.length",dataList.length)
      if (value.exhaustPasswd != res.data[0].exhaustPasswd){
        alert('排出事業者 承認コードが一致しません。確認して再入力ください。')
      }else{
        setLoading(true);
        // const params = generateParams();
        // const updatedataList = dataList.map((output, index) => {
        //   console.log("id",dataList[index].id);

        //   return `${index + 1}番目は${output}`;
        // });
        const params = generateParams();
        // console.log('params',params)
        try {
          const res = await createSagyoReport(params);
          // console.log("createSagyoReport_res",res);
        } catch (e) {
          console.log(e);
          console.log(e.response);
        } 
        
        for (let i = 0; i<dataList.length; i++){
          try {
            // console.log("dataList",dataList[i]);
            const res = await createEgsTGarbageEdi(dataList[i]);
            // console.log("createEgsTGarbageEdi_res",res);
            const res2 = await deleteEgsTGarbageTmp(dataList[i].id);
            // console.log("deleteEgsTGarbageTmp_res2",res2);
          } catch (e) {
            console.log(e);
          }
        };
        setLoading(false);
        window.alert('排出本登録を完了しました。')
        history.push('/');
      };
    }
  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    // const currentDate = new Date();
    // const currentTime = currentDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    // const params = {
    //   chargeId: mblUser,
    //   sagyoDate: currentDate.toISOString().slice(0, 10), // 現在日付を設定
    //   sagyoTime: currentTime, // 現在時刻を設定
    //   sagyoPlace: dataList[0].exhaustName,
    //   sagyoTask: '廃棄物収集',
    //   sagyoMemo: ''
    // };
    const currentDate = dayjs().format('YYYY-MM-DD');
    const currentTime = dayjs().format('HH:mm');
    const params = {
        chargeId: mblUser,
        sagyoDate: currentDate,
        sagyoTime: currentTime,
        sagyoPlace: dataList[0].exhaustName,
        sagyoTask: '廃棄物収集',
        sagyoMemo: ''
    };
    return params;
  };

  const handleClickOpen = () => {
    // setOpen(true);
    setDialogOpen(true);
  };
  const handleClickOpen2= () => {
    // setOpen(true);
    setDialogOpen2(true);
  };
  const handleClicClose = (value) => {
    // setOpen(false);
    setDialogOpen(false);
    setQrcodeValue(value, () => {
      // 状態が更新された後に行いたい処理をここに記述

    });
  };
  const handleClicClose2 = (value) => {
    // setOpen(false);
    setDialogOpen2(false);
    setQrcodeTrader(value, () => {
      // 状態が更新された後に行いたい処理をここに記述
    });
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      hide:true,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      type: 'date',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'wasteName',  // wasteclassName
      headerName: '廃棄物種類',
      width: 200
    },
    {
      field: 'amount1',
      headerName: '数量',
      width: 100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
     },
     {
      field: 'memo1',
      headerName: '備考１',
      width: 150
    },
    {
      field: 'memo2',
      headerName: '備考２',
      width: 150
    },
    {
      field: 'memo3',
      headerName: '備考３',
      width: 150
    },
    {
      field: 'memo4',
      headerName: '備考４',
      width: 50
    },
    {
      field: 'memo5',
      headerName: '備考５',
      width: 50
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業者',
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    },
    {
      field: 'sect2TransportName',
      headerName: '区間２運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業者',
      hide: true,
      width: 300
    }
  ]

  return (
    <>
      <h1>排出仮登録一覧</h1>
      <h2>{query.id}　{dataList2.exhaustName}</h2>

      <SpaceRow height={20} />
      <Box sx={{ height: 400, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols}
              rows={dataList}
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        <SpaceRow height={10} />
        <table>
          <tr>
            <th>廃棄物種類</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.wasteName}</Typography>
            </td>
          </tr>
          <tr>
            <th>処分方法</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.disposalMethodName}</Typography>
            </td>
          </tr>
          <tr>
            <th>運搬先事業場</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.destination1Name}</Typography>
            </td>
          </tr>
          <tr>
            <th>最終処分場</th>
            <td>
                <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.lastProcessingTraderName}</Typography>
            </td>
          </tr>
        </table>

        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>詳細</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <table>
                <tr>
                  <th>数量　（単位）</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.amount1}　（{dataList2.unitName}）</Typography>
                  </td>
                </tr>
                <tr>
                  <th>荷姿数量　（荷姿）</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.packingAmount1}　（{dataList2.packingName}）</Typography>
                  </td>
                </tr>
                <tr>
                  <th>備考１</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo1}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>備考２</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo2}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>備考３</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo3}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>備考４</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo4}</Typography>
                  </td>
                </tr>
                <tr>
                  <th>備考５</th>
                  <td>
                      <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.memo5}</Typography>
                  </td>
                </tr>
              </table>
            </AccordionDetails>
            <Button variant="contained" color='primary' onClick={handleClickUpdate} disabled={!dataList2.id} style={{ marginLeft: 360,marginRight: 10,marginBottom:10, fontSize: '0.8em' }}>
              仮登録データ修正
            </Button>
          </Accordion>

        <SpaceRow height={10} />
        <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id} style={{ marginLeft: 10, fontSize: '1.0em' }}>
          削除
        </Button>

        <Button variant='contained'   color="primary" onClick={handleClickOpen} style={{ marginLeft: 20, fontSize: '1.0em' }}>
          連続排出仮登録（廃棄物QRコード読込）
        </Button>
        <RsvQRReaderDialog qrcodeValue={qrcodeValue} open={dialogopen} onClose={handleClicClose} />

        <SpaceRow height={10} />
        <table>
          <tr>
          <th>連続排出仮登録<br/>処理フロー番号</th>
          <td>
              <TextField
                error={inputError2}
                inputProps={{ inputMode: "numeric", maxLength: 4, pattern: "^[0-9]+$" }}
                inputRef={inputRef2}
                defaultValue=""
                id='wasteKind'
                variant="outlined"
                type='text'
                name='wasteKind'
                label="廃棄物種類コード"
                fullWidth
                margin="dense"
                helperText={inputRef2?.current?.validationMessage}
                onChange={(e) => handleChange(e)}
                value={value.wasteKind}
              />
          </td>
          <td>
          <Button
            variant='contained'
            color='primary'
            onClick={(e) => handleClickRsvno(e)}
            style={{ marginRight: 10, fontSize: '1.2em' }}
            disabled={!value.telNo || !value.wasteKind}
          >
            送信
          </Button>
          </td>
        </tr>
      </table>



      <SpaceRow height={30} />
      <Typography variant="h7" style={{ fontWeight: 700 }}>■■■■■ 上記、仮登録データを全て本登録します ■■■■■</Typography>
      <SpaceRow height={10} />
      <Button variant='contained' onClick={handleClickOpen2} style={{ marginLeft: 20, fontSize: '1.2em', backgroundColor: 'blue', color: 'white' }}>
          排出本登録（排出担当者QRコード読込）
      </Button>
      <QRReaderDialog qrcodeTrader={qrcodeTrader} open={dialogopen2} onClose={handleClicClose2} />
      <SpaceRow height={10} />
        <table>
          <tr>
          <th>排出本登録<br/>承認コード</th>
          <td>
              <TextField
                type="password"
                autoComplete="off"
                error={inputError1}
                inputProps={{ inputMode: "numeric", maxLength: 12, pattern: "^[0-9]+$" }}
                inputRef={inputRef1}
                defaultValue=""
                id='exhaustPasswd'
                variant="outlined"
                name='exhaustPasswd'
                label="排出事業者 承認コード"
                fullWidth
                helperText={inputRef1?.current?.validationMessage}
                onChange={(e) => handleChange(e)}
                value={value.exhaustPasswd}

              />
          </td>
          <td>
          <Button
            variant='contained'
            onClick={(e) => handleSubmit(e)}
            style={{ marginRight: 10, fontSize: '1.2em', backgroundColor: 'blue', color: 'white' }}
            disabled={!value.exhaustPasswd}
          >
            本登録
          </Button>
          </td>
        </tr>
      </table>

      <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#F6BF00',
      color: '#fff',
    }
  }
}
export default MblGarbageTmpList;