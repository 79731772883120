import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import { blue } from '@material-ui/core/colors';
import { QrReader } from 'react-qr-reader'
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

// QRコードのデータを区切り文字「/」で分割し、5番目までの要素を取得する関数
const getFifthElement = (data) => {
  if (data) {
    const dataArray = data.split('/');
    const dataToShow = dataArray.slice(0, 6).join('/');
    return dataToShow;
  }
  return 'No result';
};

function RsvQRReaderDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [data, setData] = useState('No result');

  const history = useHistory();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">排出登録（廃棄物QRコード読込）</DialogTitle>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
          if (!!result) {
            setData(getFifthElement(result.text)); // 5番目までの要素を表示
          }
          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <p>{data}</p>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          キャンセル
        </Button>
        <Button onClick={() => handleListItemClick(data)} key={data} color="primary">
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default RsvQRReaderDialog;

RsvQRReaderDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };
