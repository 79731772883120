import React, { useEffect, useState, useContext } from 'react';
import { getReceiptList, deleteReceipt } from '../../lib/api/receipt';
//import { getReceiptUser } from '../../lib/api/receipt';
import { getReceiptUnload } from '../../lib/api/receipt';
//import { getChargeUser } from '../../lib/api/charge';
import { getChargeEffective } from '../../lib/api/charge';
//import { getReceiptWasteUser } from '../../lib/api/receipt_waste';
import { getReceiptUnloadWasteUser } from '../../lib/api/receipt_unload_waste';
import { getReceiptAdmWasteList } from '../../lib/api/receipt_adm_waste';
import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
//import { getReceiptExhaustUser } from '../../lib/api/receipt_exhaust';
import { getReceiptUnloadExhaustUser } from '../../lib/api/receipt_unload_exhaust';
import { getReceiptAdmExhaustList } from '../../lib/api/receipt_adm_exhaust';
import { getReceiptAdmTransportList } from '../../lib/api/receipt_adm_transport';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import ReceiptUnloadListTable from './ReceiptUnloadListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ReceiptUnloadList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [dataListC00, setDataListC00] = useState([]); {/* 荷降担当者 */ }
  const [dataListW00, setDataListW00] = useState([]); {/* 廃棄物名称 */ }
  const [dataListE00, setDataListE00] = useState([]); {/* 排出事業場 */ }
  const [dataListT00, setDataListT00] = useState([]); {/* 収集運搬業者 */ }

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;
  let resW00;
  let resE00;
  let resT00;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getReceiptList();
//      const res = await getReceiptUser(currentUser.traderCd);
       if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
        res = await getReceiptList();
//        resW00 = await getReceiptWasteList();
//        resW00 = await getEgsMWasteclassList();
        resW00 = await getReceiptAdmWasteList();
        resE00 = await getReceiptAdmExhaustList();
        resT00 = await getReceiptAdmTransportList();
        // console.log('resT00',resT00.data);
        setDataListT00(resT00.data);

        }else{                                       //事業場区分は管理者以外？
//        res = await getReceiptUser(currentUser.traderCd);
        res = await getReceiptUnload(currentUser.traderCd);
//        resW00 = await getReceiptWasteNameUser(currentUser.traderCd);
//        resW00 = await getEgsMWasteclassList();
//        resW00 = await getReceiptWasteUser(currentUser.traderCd);
        resW00 = await getReceiptUnloadWasteUser(currentUser.traderCd);
//        resE00 = await getReceiptExhaustUser(currentUser.traderCd);
        resE00 = await getReceiptUnloadExhaustUser(currentUser.traderCd);
      }
      // console.log('res',res.data);
      setDataList(res.data);

//      const resW00 = await getEgsMWasteclassList();
      // console.log('resW00',resW00.data);
      setDataListW00(resW00.data);

      // console.log('resE00',resE00.data);
      setDataListE00(resE00.data);

//      const resC00 = await getChargeUser(currentUser.traderCd);
      const resC00 = await getChargeEffective(currentUser.traderCd);    // 有効フラグ='1'のみを対象とする
      setDataListC00(resC00.data);
      // console.log('resC00',currentUser.traderCd,resC00.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>荷降登録　　　　
       <Button onClick={() => window.location.reload()} color="primary">最新（再読み込み）</Button>
      </h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/receiptNew')}
        style={{ marginRight: 10 }}
      >
        荷降実行
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ReceiptUnloadListTable
        dataList={dataList}
        dataListC00={dataListC00}
        dataListW00={dataListW00}
        dataListE00={dataListE00}
        dataListT00={dataListT00}
        currentUser={currentUser}
      />
    </>
  );
};
export default ReceiptUnloadList;

/*
      <SpaceRow height={20} />
      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/receiptNew')}
      >
        新規作成
      </Button>
*/
