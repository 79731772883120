import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Button, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import { updateEgsMCompany, getEgsMCompanyDetail } from '../../lib/api/egs_m_company';
import { createEgsMCompany, getEgsMCompanyDetail } from '../../lib/api/egs_m_company';
import { getCompanyCdNewPref } from '../../lib/api/company_cd_new'; 
//import { getEgsMAddressZip } from '../../lib/api/egs_m_address';
import { getAddressZip } from '../../lib/api/address';
//import FormBody from './EgsMCompanyAdmForm';
import FormBody from './EgsMCompanyNewForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMCompanyNew({ isOpen, doClose, processId }) {
  const [loading, setLoading] = useState(false);
  
  const [dataListNewCd,setDataListNewCd] = useState([]);  {/* 新企業CD */}
  const [dataListAddress,setDataListAddress] = useState([]);  {/* 郵便番号 */}
  
  const [value, setValue] = useState({
    id: '',
    companyCd: '',
    certifyId: '',
    traderKbn: '',

    traderKbn1: '1',  // 排出
    traderKbn2: '0',  // 収集運搬
    traderKbn3: '0',  // 積替保管
    traderKbn4: '0',  // 中間処理
    traderKbn5: '0',  // 最終処分
    traderKbn6: '0',  // 管理

    traderKbn: '1000000000',  // 排出

    companyName: '',
    companyKana: '',
//    companyDispName: '',
    jwnetNo: '',
    jwnetEdipass: '',
    jwnetSubno: '',
    exhaustPasswd: '',
    prefectureCd: '',
    zip: '',
    address: '',
    address2: '',
    address3: '',
    address4: '',

    zipAddress: '',
    zipAddress2: '',
    zipAddress3: '',

    phone: '',
    fax: '',
    email: '',
//    email1: '',
//    email1Flg: '',
//    email2: '',
//    email2Flg: '',
//    email3: '',
//    email3Flg: '',
//    memo: '',
    permitComno: '',
    categoryCd: '',
    businessTypeCd: '',
//    egsUserFlg: 0,
//    ediClientKbn: '',
    effectiveflg: '1',  // デフォルトは有効とする
//    chargeDivision: '',
//    mkKbn3000: '',

//    rawpasswd: '',
    groupCd1: '',
    groupCd2: '',
    groupCd3: '',
    groupCd4: '',
    groupCd5: '',
    groupId1: 0,
    groupId2: 0,
    groupId3: 0,
    groupId4: 0,
    groupId5: 0,

//    message1: 'aaa'

  });

  useEffect(() => {
    handleGetData(processId);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
      const res = await getEgsMCompanyDetail(processId);
      // console.log(res.data);
      setValue({

        id: res.data.id,
        companyCd: res.data.companyCd,
        certifyId: res.data.certifyId,
        traderKbn: res.data.traderKbn,

        traderKbn1: res.data.traderKbn.substring(0,1),
        traderKbn2: res.data.traderKbn.substring(1,2),
        traderKbn3: res.data.traderKbn.substring(2,3),
        traderKbn4: res.data.traderKbn.substring(3,4),
        traderKbn5: res.data.traderKbn.substring(4,5),
        traderKbn6: res.data.traderKbn.substring(9,10),

        companyName: res.data.companyName,
        companyKana: res.data.companyKana,
//        companyDispName: res.data.companyDispName,
        jwnetNo: res.data.jwnetNo,
        jwnetEdipass: res.data.jwnetEdipass,
        jwnetSubno: res.data.jwnetSubno,
        exhaustPasswd: res.data.exhaustPasswd,
        prefectureCd: res.data.prefectureCd,
        zip: res.data.zip,
        address: res.data.address,
        address2: res.data.address2,
        address3: res.data.address3,
        address4: res.data.address4,

        zipAddress: res.data.address,
        zipAddress2: res.data.address2,
        zipAddress3: res.data.address3,

        phone: res.data.phone,
        fax: res.data.fax,
        email: res.data.email,
//        email1: res.data.email1,
//        email1Flg: res.data.email1Flg,
//        email2: res.data.email2,
//        email2Flg: res.data.email2Flg,
//        email3: res.data.email3,
//        email3Flg: res.data.email3Flg,
        permitComno: res.data.permitComno,
        categoryCd: res.data.categoryCd,
        businessTypeCd: res.data.businessTypeCd,
//        memo: res.data.memo,
//        egsUserFlg: res.data.egsUserFlg,
//        ediClientKbn: res.data.ediClientKbn,
        effectiveflg: res.data.effectiveflg,
//        chargeDivision: res.data.chargeDivision,
//        mkKbn3000: res.data.mkKbn3000,

//        rawpasswd: res.data.rawpasswd,
        groupCd1: res.data.groupCd1,
        groupCd2: res.data.groupCd2,
        groupCd3: res.data.groupCd3,
        groupCd4: res.data.groupCd4,
        groupCd5: res.data.groupCd5,
        groupId1: res.data.groupId1,
        groupId2: res.data.groupId2,
        groupId3: res.data.groupId3,
        groupId4: res.data.groupId4,
        groupId5: res.data.groupId5,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const prefChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

//    if (value.prefectureCd > '00' && value.prefectureCd < '48') {
    if (e.target.value > '00' && e.target.value < '48') {
        //      import { getCompanyCdNewPref } from '../../lib/api/company_cd_new';
      try {
//        const res = await getCompanyCdNewPref(value.prefectureCd);
//        const dataListNewCd = await getCompanyCdNewPref(value.prefectureCd);
//        const dataListNewCd = await getCompanyCdNewPref(41);
        const dataListNewCd = await getCompanyCdNewPref(e.target.value);
        setDataListNewCd(dataListNewCd.data);

        value.companyCd = dataListNewCd.data[0].companyCdNew
        value.jwnetEdipass = dataListNewCd.data[0].passwd1

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }

    }

  };

  const zipChange = async (e) => {

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

//    if (value.zip != null && value.zip.length == 7) {
    if (e.target.value != null && e.target.value.length == 7) {
        try {
//        const dataListAddress = await getEgsMAddressZip(value.zip);
//        const dataListAddress = await getEgsMAddressZip(8410025);
//        const dataListAddress = await getEgsMAddressZip(e.target.value);
        const dataListAddress = await getAddressZip(e.target.value);
        setDataListAddress(dataListAddress.data);

//        value.address = dataListAddress.data[0].address1
//        value.address2 = dataListAddress.data[0].address2
//        value.address3 = dataListAddress.data[0].address3
        value.address = dataListAddress.data[0].address2
        value.address2 = dataListAddress.data[0].address3
        value.prefectureCd = dataListAddress.data[0].prefectureCd

        value.zipAddress = dataListAddress.data[0].address1
        value.zipAddress2 = dataListAddress.data[0].address2
        value.zipAddress3 = dataListAddress.data[0].address3

        const dataListNewCd = await getCompanyCdNewPref(value.prefectureCd);
        setDataListNewCd(dataListNewCd.data);

        value.companyCd = dataListNewCd.data[0].companyCdNew
//        value.jwnetEdipass = dataListNewCd.data[0].passwd1

        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
    
        } catch (e) {
        console.log(e);
      }

    }

  };


  const handleChange = (e) => {

    if (e.target.id == 'traderKbn1') {
      if (e.target.checked){
          value.traderKbn1 = '1'
      }else{
          value.traderKbn1 = '0'
      }
     }  

     if (e.target.id == 'traderKbn2') {
      if (e.target.checked){
          value.traderKbn2 = '1'
      }else{
          value.traderKbn2 = '0'
      }
     }  

     if (e.target.id == 'traderKbn3') {
      if (e.target.checked){
          value.traderKbn3 = '1'
      }else{
          value.traderKbn3 = '0'
      }
     }  

     if (e.target.id == 'traderKbn4') {
      if (e.target.checked){
          value.traderKbn4 = '1'
      }else{
          value.traderKbn4 = '0'
      }
     }  

     if (e.target.id == 'traderKbn5') {
      if (e.target.checked){
          value.traderKbn5 = '1'
      }else{
          value.traderKbn5 = '0'
      }
     }  

     if (e.target.id == 'traderKbn6') {
      if (e.target.checked){
          value.traderKbn6 = '1'
      }else{
          value.traderKbn6 = '0'
      }
     }  

     value.traderKbn = value.traderKbn1 + value.traderKbn2 + value.traderKbn3 + value.traderKbn4 + value.traderKbn5 + '0000' + value.traderKbn6;

//     event.target.traderKbn6.checked=true;
////     value.traderKbn6.checked=true;
//     target.id='traderKbn6'.checked=true;


//    let str = "あいうえお";

    // 全角文字チェック
    if (value.email.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)) {
      //全角文字
      // console.log("全角文字です",value.email,'>');  // alert
      value.message1 = "全角文字です"
//      setErroremail(true);
    } else {
      //全角文字以外
      // console.log("全角文字ではありません",value.email,'>');
      value.message1 = "全角文字ではありません"
//      setErroremail(false);
    }

    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();

    if (window.confirm(' 企業マスタ「' + value.companyCd + '」を新規登録します。よろしいですか？')) {
      try {
//      const res = await updateEgsMCompany(processId, params);
        const res = await createEgsMCompany(params);
        // console.log('res',res);
        handleCancel();
//        history.push('/egs_m_company_adm');
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }

/*
    try {
//      const res = await updateEgsMCompany(processId, params);
      const res = await createEgsMCompany(params);
      // console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
*/
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      id: value.id,
      companyCd: value.companyCd,
      certifyId: value.certifyId,
//      traderKbn: value.traderKbn,

//      traderKbn: value.traderKbn1 || value.traderKbn2 || value.traderKbn3 || value.traderKbn4 || value.traderKbn5 || value.traderKbn6,
      traderKbn: value.traderKbn,

      companyName: value.companyName,
      companyKana: value.companyKana,
//      companyDispName: value.companyDispName,
      jwnetNo: value.jwnetNo,
      jwnetEdipass: value.jwnetEdipass,
      jwnetSubno: value.jwnetSubno,
      exhaustPasswd: value.exhaustPasswd,
      prefectureCd: value.prefectureCd,
      zip: value.zip,
      address: value.address,
      address2: value.address2,
      address3: value.address3,
      address4: value.address4,
      phone: value.phone,
      fax: value.fax,
      email: value.email,
//      email1: value.email1,
//      email1Flg: value.email1Flg,
//      email2: value.email2,
//      email2Flg: value.email2Flg,
//      email3: value.email3,
//      email3Flg: value.email3Flg,
      permitComno: value.permitComno,
//      categoryCd: value.categoryCd,
      businessTypeCd: value.businessTypeCd,
//      memo: value.memo,
//      egsUserFlg: value.egsUserFlg,
//      ediClientKbn: value.ediClientKbn,
      effectiveflg: value.effectiveflg,
//      chargeDivision: value.chargeDivision,
//      mkKbn3000: value.mkKbn3000,

//      rawpasswd: value.rawpasswd,
      groupCd1: value.groupCd1,
      groupCd2: value.groupCd2,
      groupCd3: value.groupCd3,
      groupCd4: value.groupCd4,
      groupCd5: value.groupCd5,
      groupId1: value.groupId1,
      groupId2: value.groupId2,
      groupId3: value.groupId3,
      groupId4: value.groupId4,
      groupId5: value.groupId5,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}  // doClose
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>企業マスタ新規作成</h2>
        <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          handleCancel();
        }}>
          戻る
        </Button>
      </div>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          zipChange={zipChange}
          prefChange={prefChange}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMCompanyNew;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
