// /src/lib/api/egs_m_company.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsMCompanyList = () => {
  return client.get('/egs_m_companies');
};

// 詳細
export const getEgsMCompanyDetail = (id) => {
  return client.get(`/egs_m_companies/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMCompany = (params) => {
  return client.post('/egs_m_companies', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMCompany = (id, params) => {
  return client.patch(`/egs_m_companies/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMCompany = (id) => {
  return client.delete(`/egs_m_companies/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
