import React, { useState } from 'react';
import {
    PDFDownloadLink,
    PDFViewer,
    Page,
    View,
    Font,
    Document,
    StyleSheet
} from '@react-pdf/renderer';
import Text from "../pdf_components/atoms/Text";
import Title from "../pdf_components/molecules/Title";
import Link from "../pdf_components/atoms/Link";
import Br from "../pdf_components/atoms/Br";
import { Grid, Col } from "../pdf_components/atoms/Grid";
import {
    ListItem,
    PR,
    Exhaust,
    Middle,
    Fainal,
    Toxic,
    Bikou,
    Kukan1Trans,
    Kukan1Desti,
    Kukan2Trans,
    Kukan2Desti,
    Kukan3Trans,
    Kukan3Desti,
    SyobunJyutaku,
    LastSyobunPlace
} from "../pdf_components/molecules/Base";
import {
  Button,
} from '@material-ui/core';

import fontRegular from '../../fonts/Nasu-Regular.ttf'  //ttfファイル参照
import fontBold from '../../fonts/Nasu-Bold.ttf'        //ttfファイル参照
import dayjs from 'dayjs';

// Create Document Component
function GarbageEdiPdfDLForm(props) {
  const { currentUser,isOpen,doClose,dataList } = props;
  const [loading, setLoading] = useState(false);
  
  console.log("dataList",dataList);
  // console.log("currentUser",currentUser);

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: 'Nasu-Regular',
    src: fontRegular
  });

  // フォント「ナス 太字」
  Font.register({
    family: 'Nasu-Bold',
    src: fontBold
  });
  // CSSスタイル定義
  const wk_styles = StyleSheet.create({
      text1: { fontSize: '20pt', fontFamily: 'Nasu-Regular' },
      text2: { fontSize: '10pt', fontFamily: 'Nasu-Regular' },
      text3: { fontSize: '7pt', fontFamily: 'Nasu-Regular' }
    });

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      color: "#000000",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    viewer: {
      width: window.innerWidth*0.5, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    screen: {
        padding: 10
      },
  });

  const handleCancel = () => {
    setLoading(false); 
    alert(`${dataList.length}件の廃棄物実績報告のPDFファイルをダウンロードします。`);
    doClose();
  };


  const fileName = `廃棄物実績報告_${dayjs().format('YYYYMMDDHHmmss')}.pdf`;

  return (
    <PDFDownloadLink document={
      <Document>
        {dataList.map((data, index) => (
          <Page key={index} size="A4" style={styles.page}>
            <View style={styles.screen}>
              <Title title="廃棄物実績報告" createDate={`出力日：${dayjs().format('YYYY/MM/DD')}`} id={`ID：${data.id}`} />
              <Grid borderTop>
                <ListItem lable="最新状態" borderLeft borderRight>
                  {data.statusName}
                </ListItem>
                <ListItem lable="排出日" borderRight>
                  {data.exhaustDate}
                </ListItem>
              </Grid>
              <Grid borderTop>
                <ListItem lable="マニフェスト番号" borderLeft borderRight>
                  {data.manifestNo}
                </ListItem>
                <ListItem lable="ゴミタグ番号" borderRight>
                  {data.garbageTag}
                </ListItem>
              </Grid>
              <Grid borderTop>
                <Exhaust>
                  <View>
                    <Text>
                      {data.exhaustName}　　　　加入者番号：{data.jwnetNo}
                    </Text>
                    <Text>
                      〒{data.exhaustAddress}
                    </Text>
                    <Text>
                      TEL:{data.exhaustPhone}　　　　　　　　　排出担当者：{data.exhaustChargeName}
                    </Text>
                  </View>
                </Exhaust>
              </Grid>
          <Grid borderTop>
            <ListItem lable="廃棄物種類" borderLeft borderRight>
            {data.wasteKindName}
            </ListItem>
            <ListItem lable="廃棄物の数量"  borderRight>
            {data.amount1}  ({data.unitName})
            </ListItem>
          </Grid>
          <Grid borderTop>
          <ListItem lable="廃棄物名称" borderLeft borderRight>
            {data.wasteName}
            </ListItem>
            <ListItem lable="荷姿の数量" borderRight>
            {data.packingAmount1}  ({data.packingName})
            </ListItem>
          </Grid>
          <Grid borderTop>
            <ListItem lable="処分方法" borderLeft borderRight>
            {data.disposalMethodName}
            </ListItem>
            <ListItem lable="" borderRight>
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Toxic>
              <View>
                <Text>
                {data.toxicName01}　{data.toxicName02}　{data.toxicName03}　
                {data.toxicName04}　{data.toxicName05}　{data.toxicName06}
                </Text>
              </View>
            </Toxic>
          </Grid>
          <Grid borderTop>
            <Bikou>
              <View>
                <Text>
                {data.memo1}　{data.memo2}　{data.memo3}　{data.memo4}　{data.memo5}
                </Text>
              </View>
            </Bikou>
          </Grid>
          <Grid borderTop>
            <Middle>
              <View>
                <Text>
                  {data.companyName}
                </Text>
                <Text>
                  〒{data.companyZip} {data.companyAddress}
                </Text>
              </View>
            </Middle>
          </Grid>
          <Grid borderTop>
            <Fainal>
              <View>
                <Text>
                {data.lastProcessingTraderName}　　　　　　　　　　処分担当者：{data.lastProcessingChargeName}
                </Text>
              </View>
            </Fainal>
          </Grid>

          <Grid borderTop>
            <ListItem lable="最終処分の場所" borderLeft borderRight>
              {data.lastReportName}
            </ListItem>
            <ListItem lable="処理フローメモ"  borderRight>
              {data.memo}
            </ListItem>
          </Grid>

          <Grid borderTop>
            <Kukan1Trans>
              <View>
                <Text>
                  {data.sect1TransportName}　　　　　　　　　　運搬方法：車両（ {data.sect1CarNo} ）
                </Text>
                <Text>
                  〒{data.sect1TransportAddress}
                </Text>
                <Text>
                  TEL:{data.sect1TransportPhone}　　　　　　　　　　運搬担当者：{data.sect1TransportChargeName}
                </Text>
              </View>
            </Kukan1Trans>
          </Grid>
          <Grid borderTop>
            <Kukan1Desti>
              <View>
                <Text>
                  {data.destination1Name}
                </Text>
                <Text>
                  〒{data.destination1Address}
                </Text>
                <Text>
                  TEL:{data.destination1Phone}　　　　　　　　　　荷受担当者：{data.destination1ChargeName}
                </Text>
              </View>
            </Kukan1Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間１）運搬開始日" borderLeft borderRight>
            {data.sect1At}
            </ListItem>
            <ListItem lable="（区間１）運搬終了日" borderRight>
            {data.destination1At}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Kukan2Trans>
              <View>
                <Text>
                  {data.sect2TransportName}　　　　　　　　　　運搬方法：車両（ {data.sect2CarNo} ）
                </Text>
                <Text>
                  〒{data.sect2TransportAddress}
                </Text>
                <Text>
                  TEL:{data.sect2TransportPhone}　　　　　　　　　　運搬担当者：{data.sect2TransportChargeName}
                </Text>
              </View>
            </Kukan2Trans>
          </Grid>
          <Grid borderTop>
            <Kukan2Desti>
              <View>
                <Text>
                  {data.destination2Name}
                </Text>
                <Text>
                  〒{data.destination2Address}
                </Text>
                <Text>
                  TEL:{data.destination2Phone}　　　　　　　　　　荷受担当者：{data.destination2ChargeName}
                </Text>
              </View>
            </Kukan2Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間２）運搬開始日" borderLeft borderRight>
            {data.sect2At}
            </ListItem>
            <ListItem lable="（区間２）運搬終了日" borderRight>
            {data.destination2At}
            </ListItem>
          </Grid>
          <Grid borderTop>
            <Kukan3Trans>
              <View>
                <Text>
                  {data.sect3TransportName}　　　　　　　　　　運搬方法：{data.sect3TransportMethodName}　{data.sect3CarNo}
                </Text>
                <Text>
                  〒{data.sect3TransportAddress}
                </Text>
                <Text>
                  TEL:{data.sect3TransportPhone}　　　　　　　　　　運搬担当者：{data.sect3TransportChargeName}
                </Text>
              </View>
            </Kukan3Trans>
          </Grid>
          <Grid borderTop>
            <Kukan3Desti>
              <View>
                <Text>
                  {data.destination3Name}
                </Text>
                <Text>
                  〒{data.destination3Address}
                </Text>
                <Text>
                  TEL:{data.destination3Phone}　　　　　　　　　　荷受担当者：{data.destination3ChargeName}
                </Text>
              </View>
            </Kukan3Desti>
          </Grid>
          <Grid borderTop>
            <ListItem lable="（区間３）運搬開始日" borderLeft borderRight>
            {data.sect3At}
            </ListItem>
            <ListItem lable="（区間３）運搬終了日" borderRight>
            {data.destination3At}
            </ListItem>
          </Grid>

          <Grid borderTop>
            <SyobunJyutaku>
              <View>
                <Text>
                  {data.companyName}
                </Text>
                <Text>
                  〒{data.companyZip}　{data.companyAddress}
                </Text>
                <Text>
                  TEL:{data.companyPhone}
                </Text>
              </View>
            </SyobunJyutaku>
          </Grid>
          <Grid borderTop>
            <LastSyobunPlace>
              <View>
                <Text>
                　{data.lastProcessingTraderName}
                </Text>
                <Text>
                〒{data.lastProcessingTraderAddress}
                </Text>
                <Text>
                TEL:{data.lastProcessingPhone}　　　　　　　　　　処分担当者：{data.lastProcessingChargeName}
                </Text>
              </View>
            </LastSyobunPlace>
          </Grid>
          <Grid borderTop borderBottom>
            <ListItem lable="中間処分終了日" borderLeft borderRight>
            {data.middleEnd}
            </ListItem>
            <ListItem lable="最終処分終了日" borderRight>
            {data.lastProcessingEnd}
            </ListItem>
          </Grid>
          <Text size="normal" style={{ textAlign: "right" }}>
            産業廃棄物SDGs推進システム
          </Text>
          <Br />
            </View>
          </Page>
        ))}
      </Document>
    }  fileName={fileName}>
      {/* {({ blob, url, loading, error }) => (loading ? '生成中...' : 'PDFをダウンロード')} */}
      {/* // PDFをダウンロードするボタンに変更する修正 */}
      <Button variant='contained' color='primary' style={{ marginleft: '10px' }} onClick={() => {
        if (dataList.length > 300) {
          alert('データが300件を超えるため、PDFを作成できません。');
        } else {
          setLoading(true); // ボタンがクリックされたらloadingをtrueに設定
          handleCancel();         
        }
      }} disabled={dataList.length === 0}>
        {loading ? '生成中...' : 'PDFダウンロード'}
      </Button>
    </PDFDownloadLink>
  );
}export default GarbageEdiPdfDLForm;