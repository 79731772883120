// src/components/users/SignForm.jsx
import React from 'react';
import { Link } from 'react-router-dom';

import SpaceRow from '../commons/SpaceRow';
// style
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
} from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    textTransform: 'none',
  },
  header: {
    textAlign: 'center',
  },
  card: {
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  box: {
    marginTop: '2rem',
  },
  link: {
    textDecoration: 'none',
  },
}));

const SignForm = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleSubmit,
    signType,
    name,
    setName,
    passwordConfirmation,
    setPasswordConfirmation,
    trader_cd,
    setTraderCd,
    trader_kbn,
    setTraderKbn,
  } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <SpaceRow height={100} />
      <form noValidate autoComplete='on'>
        <Card className={classes.card}>
          {/* <CardHeader className={classes.header} title={signType} /> */}
          <CardContent>
            {signType === 'signUp' && (
              <TextField
                variant='outlined'
                required
                fullWidth
                label='trader_cd'
                value={trader_cd}
                margin='dense'
                onChange={(event) => setTraderCd(event.target.value)}
              />
            )}
            {signType === 'signUp' && (
              <TextField
                variant='outlined'
                required
                fullWidth
                label='trader_kbn'
                value={trader_kbn}
                margin='dense'
                onChange={(event) => setTraderKbn(event.target.value)}
              />
            )}
            {signType === 'signUp' && (
              <TextField
                variant='outlined'
                required
                fullWidth
                label='Name'
                value={name}
                margin='dense'
                onChange={(event) => setName(event.target.value)}
              />
            )}
            <TextField
              variant='outlined'
              required
              fullWidth
              label='事業者ID'
              value={email}
              margin='dense'
              autoComplete='email'
              onChange={(event) => setEmail(event.target.value)}
              type='email'
            />
            <TextField
              variant='outlined'
              required
              fullWidth
              label='パスワード'
              type='password'
              // placeholder='At least 6 characters'
              placeholder=''
              value={password}
              margin='dense'
              autoComplete='current-password'
              onChange={(event) => setPassword(event.target.value)}
            />
            {signType === 'signUp' && (
              <TextField
                variant='outlined'
                required
                fullWidth
                label='Password Confirmation'
                type='password'
                value={passwordConfirmation}
                margin='dense'
                autoComplete='current-password'
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
              />
            )}
            <Button
              type='submit'
              variant='contained'
              size='large'
              fullWidth
              color='default'
              disabled={!email || !password ? true : false}
              className={classes.submitBtn}
              onClick={handleSubmit}
            >
              ログイン
            </Button>
            {isMobile && (
              <Typography variant="body2" style={{ marginTop: '1rem', textAlign: 'left' }}>
                ・事業者IDには、事業場CDにハイフン（-）をつけて　担当者CDを付加してご入力ください。<br />
                　　　例：401234560120-001<br />
                　担当者CDが不明の場合は、事業場CDのみご入力　　ください。（ハイフン不要）<br />
                　担当者CDは、スマホ担当者登録画面の担当者CDを　ご確認ください。
              </Typography>
            )}
            {signType === 'signIn' && (
              <Box textAlign='center' className={classes.box}>
                {/* <Typography variant='body2'>
                  Don't have an account? &nbsp;
                  <Link to='/signup' className={classes.link}>
                    Sign Up now!
                  </Link>
                </Typography> */}
              </Box>
            )}
          </CardContent>
        </Card>
      </form>
    </>
  );
};
export default SignForm;
