import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMChargeList, deleteEgsMCharge } from '../../lib/api/egs_m_charge';
import { deleteEgsMCharge } from '../../lib/api/egs_m_charge';
//import { getEgsMChargeList } from '../../lib/api/egs_m_charge';
//import { getChargeList } from '../../lib/api/charge';
import { getChargeList, getChargeUser, getChargeGroup, getChargeGroup1, getChargeGroup2, getChargeOperation } from '../../lib/api/charge';
//import { getEgsMChargeUser } from '../../lib/api/egs_m_charge';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import EgsMChargeListTable from './EgsMChargeListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMChargeList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      res = await getChargeList();
      res = await getChargeOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '_');
    }else{                                       //事業場区分は管理者以外？
      if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ１？
//        res = await getChargeGroup1(currentUser.traderCd);
//        res = await getChargeGroup(currentUser.traderCd);
        res = await getChargeOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '_');
        setDataList(res.data);
//      }else{                                       //事業場区分は管理者以外？
//        if (currentUser.traderKbn == '0000000102'){  //事業場区分はグループ１？
//          res = await getChargeGroup2(currentUser.traderCd);
//          setDataList(res.data);
        }else{                                       //事業場区分は管理者以外？
          res = await getChargeUser(currentUser.traderCd);
        }
//      }
    }    
      console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  // const handleDelete = async (item) => {

  //  alert(' ' + item.chargeName + 'を削除します。よろしいですか？')
  //   console.log('click', item.id);
  //   try {
  //     const res = await deleteEgsMCharge(item.id);
  //     console.log(res.data);
  //     handleGetList();
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // };

  const handleDelete = async (item) => {
    if (window.confirm(' 担当者「' + item.chargeName + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMCharge(item.id);
        console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>担当者マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_chargesSel')}
        style={{ marginRight: 10 }}
      >
        参照作成
      </Button> */}
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_chargesNew')}
      >
        新規作成
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EgsMChargeListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default EgsMChargeList;
