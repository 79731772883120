import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { makeStyles, Button, TextField, Typography } from '@material-ui/core';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function InputSupportDialog(props) {
  const { onClose, dataList, open } = props;
  // console.log("dataList",dataList)
  const [value, setValue] = useState({
    text1: '',
    amount1: '',
    packingAmount1: '',
    text2: '',
    amount2: '',
    packingAmount2: '',
    text3: '',
    amount3: '',
    packingAmount3: '',
    text4: '',
    amount4: '',
    packingAmount4: '',
    text5: '',
    amount5: '',
    packingAmount5: ''
  });
  const [result1, setResult1] = useState(0);
  const [result2, setResult2] = useState(0);
  const [result3, setResult3] = useState(0);
  const [result4, setResult4] = useState(0);
  const [result5, setResult5] = useState(0);

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    const packingAmount = value.packingAmount1;
    setResult1(amount * packingAmount);
    setValue({
      ...value,
      amount1: amount,
    });
  };
  
  const handlePackingAmountChange = (e) => {
    const packingAmount = e.target.value;
    const amount = value.amount1;
    setResult1(amount * packingAmount);
    setValue({
      ...value,
      packingAmount1: packingAmount,
    });
  };

  const handleAmount2Change = (e) => {
    const amount = e.target.value;
    const packingAmount = value.packingAmount2;
    setResult2(amount * packingAmount);
    setValue({
      ...value,
      amount2: amount,
    });
  };
  
  const handlePackingAmount2Change = (e) => {
    const packingAmount = e.target.value;
    const amount = value.amount2;
    setResult2(amount * packingAmount);
    setValue({
      ...value,
      packingAmount2: packingAmount,
    });
  };

  const handleAmount3Change = (e) => {
    const amount = e.target.value;
    const packingAmount = value.packingAmount3;
    setResult3(amount * packingAmount);
    setValue({
      ...value,
      amount3: amount,
    });
  };
  
  const handlePackingAmount3Change = (e) => {
    const packingAmount = e.target.value;
    const amount = value.amount3;
    setResult3(amount * packingAmount);
    setValue({
      ...value,
      packingAmount3: packingAmount,
    });
  };

  const handleAmount4Change = (e) => {
    const amount = e.target.value;
    const packingAmount = value.packingAmount4;
    setResult4(amount * packingAmount);
    setValue({
      ...value,
      amount4: amount,
    });
  };
  
  const handlePackingAmount4Change = (e) => {
    const packingAmount = e.target.value;
    const amount = value.amount4;
    setResult4(amount * packingAmount);
    setValue({
      ...value,
      packingAmount4: packingAmount,
    });
  };
  const handleAmount5Change = (e) => {
    const amount = e.target.value;
    const packingAmount = value.packingAmount5;
    setResult5(amount * packingAmount);
    setValue({
      ...value,
      amount5: amount,
    });
  };
  
  const handlePackingAmount5Change = (e) => {
    const packingAmount = e.target.value;
    const amount = value.amount5;
    setResult5(amount * packingAmount);
    setValue({
      ...value,
      packingAmount5: packingAmount,
    });
  };

  const totalResult = result1 + result2 + result3 + result4 + result5;
  const totalpackingResult = (
    (value.packingAmount1 !== '' ? parseInt(value.packingAmount1) : 0) +
    (value.packingAmount2 !== '' ? parseInt(value.packingAmount2) : 0) +
    (value.packingAmount3 !== '' ? parseInt(value.packingAmount3) : 0) +
    (value.packingAmount4 !== '' ? parseInt(value.packingAmount4) : 0) +
    (value.packingAmount5 !== '' ? parseInt(value.packingAmount5) : 0)
  );

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  // 半角カタカナを全角カタカナに変換する関数
  const convertHalfWidthKatakanaToFullWidth = (input) => {
    return input.replace(/[\uFF65-\uFF9F]/g, function(match) {
      const chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  };

  // 容器1-5の入力値が半角カタカナの場合、全角カタカナに変換
  const handleKatakanaChange = (e, containerNumber) => {
    let inputValue = e.target.value;
    if (/[\uFF65-\uFF9F]/.test(inputValue)) { // 半角カタカナの正規表現
      inputValue = convertHalfWidthKatakanaToFullWidth(inputValue);
    }

    // 各容器の値を更新
    setValue({
      ...value,
      [`text${containerNumber}`]: inputValue,
    });
  };

  // 使用例
  const handleChange = (e) => {
    handleKatakanaChange(e, 1);
  };

  const handle2Change = (e) => {
    handleKatakanaChange(e, 2);
  };

  const handle3Change = (e) => {
    handleKatakanaChange(e, 3);
  };

  const handle4Change = (e) => {
    handleKatakanaChange(e, 4);
  };

  const handle5Change = (e) => {
    handleKatakanaChange(e, 5);
  };

  const handleSubmit = () => {
    dataList.amount1 = totalResult;
    dataList.packingAmount1 = totalpackingResult; 
    if (value.amount1) {
      dataList.memo1 = value.text1 + " " + value.amount1 + "x" + value.packingAmount1;
    }else{
      dataList.memo1 = '';
    }
    if (value.amount2) {
      dataList.memo2 = value.text2 + " " + value.amount2 + "x" + value.packingAmount2;
    }else{
      dataList.memo2 = '';
    }
    if (value.amount3) {
      dataList.memo3 = value.text3 + " " + value.amount3 + "x" + value.packingAmount3;
    }else{
      dataList.memo3 = '';
    }
    if (value.amount4) {
      dataList.memo4 = value.text4 + " " + value.amount4 + "x" + value.packingAmount4;
    }else{
      dataList.memo4 = '';
    }
    if (value.amount5) {
      dataList.memo5 = value.text5 + " " + value.amount5 + "x" + value.packingAmount5;
    }else{
      dataList.memo5 = '';
    }
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h4>入力支援（数量、荷姿数量、備考１～５）</h4>
      </DialogTitle>
      <DialogContent style={{ marginTop: '-10px' }}>
        <Typography variant="h6" style={{ textAlign: 'center' }}>廃棄物の数量計: {totalResult} <strong>{dataList.unitName}</strong>
        <br />
        荷姿の数量計: {totalpackingResult} <strong>{dataList.packingName}</strong></Typography>
        <br /><br />
        <Typography variant="h7" style={{ display: 'flex', alignItems: 'center' }}>備考１：
          <TextField label="容器1" variant="outlined" type="text" name="text1" value={value.text1} onChange={handleChange} style={{ textAlign: 'right', width: '30%', marginRight: 0, fontSize: '1.5em' }} />
          <TextField label="容量1" variant="outlined" type="number" value={value.amount1} onChange={handleAmountChange} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
          <Typography variant="body1" style={{ fontSize: '1.5em' }}>×</Typography>
          <TextField label="荷姿数量1" variant="outlined" type="number" value={value.packingAmount1} onChange={handlePackingAmountChange} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
            　{result1}
        </Typography>
        <br />
        <Typography variant="h7" style={{ display: 'flex', alignItems: 'center' }}>備考２：
          <TextField label="容器2" variant="outlined" type="text" name="text2" value={value.text2} onChange={handle2Change} style={{ textAlign: 'right', width: '30%', marginRight: 0, fontSize: '1.5em' }} />
          <TextField label="容量2" variant="outlined" type="number" value={value.amount2} onChange={handleAmount2Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
          <Typography variant="body1" style={{ fontSize: '1.5em' }}>×</Typography>
          <TextField label="荷姿数量2" variant="outlined" type="number" value={value.packingAmount2} onChange={handlePackingAmount2Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
            　{result2}
        </Typography>
        <br />
        <Typography variant="h7" style={{ display: 'flex', alignItems: 'center' }}>備考３：
          <TextField label="容器3" variant="outlined" type="text" name="text3" value={value.text3} onChange={handle3Change} style={{ textAlign: 'right', width: '30%', marginRight: 0, fontSize: '1.5em' }} />
          <TextField label="容量3" variant="outlined" type="number" value={value.amount3} onChange={handleAmount3Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
          <Typography variant="body1" style={{ fontSize: '1.5em' }}>×</Typography>
          <TextField label="荷姿数量3" variant="outlined" type="number" value={value.packingAmount3} onChange={handlePackingAmount3Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
            　{result3}
        </Typography>
        <br />
        <Typography variant="h7" style={{ display: 'flex', alignItems: 'center' }}>備考４：
          <TextField label="容器4" variant="outlined" type="text" name="text4" value={value.text4} onChange={handle4Change} style={{ textAlign: 'right', width: '30%', marginRight: 0, fontSize: '1.5em' }} />
          <TextField label="容量4" variant="outlined" type="number" value={value.amount4} onChange={handleAmount4Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
          <Typography variant="body1" style={{ fontSize: '1.5em' }}>×</Typography>
          <TextField label="荷姿数量4" variant="outlined" type="number" value={value.packingAmount4} onChange={handlePackingAmount4Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
            　{result4}
        </Typography>
        <br />
        <Typography variant="h7" style={{ display: 'flex', alignItems: 'center' }}>備考５：
          <TextField label="容器5" variant="outlined" type="text" name="text5" value={value.text5} onChange={handle5Change} style={{ textAlign: 'right', width: '30%', marginRight: 0, fontSize: '1.5em' }} />
          <TextField label="容量5" variant="outlined" type="number" value={value.amount5} onChange={handleAmount5Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
          <Typography variant="body1" style={{ fontSize: '1.5em' }}>×</Typography>
          <TextField label="荷姿数量5" variant="outlined" type="number" value={value.packingAmount5} onChange={handlePackingAmount5Change} style={{ textAlign: 'right', width: '20%', marginRight: 0, fontSize: '1.5em' }} />
            　{result5}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
            キャンセル
          </Button>
        <Button autoFocus onClick={handleSubmit} color="primary">
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default InputSupportDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});