// /src/lib/api/wk_exhaust_sum.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getExhaustSumList = () => {
  return client.get('/exhaust_sums');
};

// 事業所指定一覧 idは事業所CD
export const getExhaustSumUser = (id) => {
  return client.get(`/exhaust_sums/${id}/showuser`);
};
