import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMCompanyList, deleteEgsMCompany } from '../../lib/api/egs_m_company';
//import { deleteEgsMCompany } from '../../lib/api/egs_m_company';
import { getEgsMCompanyUser,deleteEgsMCompany } from '../../lib/api/egs_m_company';
//import { getCompanyList, getCompanyGroup1, getCompanyGroup2 } from '../../lib/api/trader_adm';
import { getCompanyList, getCompanyGroup, getCompanyUser ,getCompanyOperation } from '../../lib/api/company';
//import { getEgsMCompanyUser, deleteEgsMCompany } from '../../lib/api/egs_m_company';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';

// component
import EgsMCompanyListTable from './EgsMCompanyListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMCompanyList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //企業区分は管理者？
//      res = await getCompanyList();
      res = await getCompanyOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '__');
      setDataList(res.data);
    }else{                                       //企業区分は管理者以外？
      if (currentUser.traderKbn == '0000000001'){  //企業区分はグループ１？
//        res = await getCompanyGroup1(currentUser.traderCd);
//        res = await getCompanyGroup(currentUser.traderCd);
        res = await getCompanyOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '__');
        setDataList(res.data);
      }else{                                       //企業区分は管理者以外？
//        if (currentUser.traderKbn == '0000000102'){  //企業区分はグループ１？
//          res = await getCompanyGroup2(currentUser.traderCd);
//          setDataList(res.data);
//        }else{                                       //企業区分は管理者以外？
//          res = await getEgsMCompanyUser(currentUser.traderCd);
          res = await getCompanyUser(currentUser.traderCd);
          setDataList(res.data[0]);
//        }
      }
    }
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  const handleDelete = async (item) => {
    if (window.confirm(' 「' + item.companyName + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMCompany(item.id);
        // console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>企業マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_companys_admNew')}
        style={{ marginRight: 10 }}
      >
        新規作成
      </Button>
      <Button
        variant='contained'
        color='primary'

        currentUser={currentUser}

        onClick={() => history.push('/egs_m_companys_admCompanySel')}
        style={{ marginRight: 10 }}
      >
        企業参照作成
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/qrreader')}
        style={{ marginRight: 10 }}
      >
        QRコード読み込み
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/webcam')}
        style={{ marginRight: 10 }}
      >
        写真撮影
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/imgdropzone')}
        style={{ marginRight: 10 }}
      >
        写真取り込み
      </Button>

      <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/imagecard')}
        style={{ marginRight: 10 }}
      >
        写真CARD表示
      </Button> */}

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SpaceRow height={20} />
      <EgsMCompanyListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default EgsMCompanyList;
