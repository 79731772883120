// /src/lib/api/garbage_statu.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getGarbageStatuList = () => {
  return client.get('/garbage_status');
};

// 事業所指定一覧 idは事業所CD
export const getGarbageStatuUser = (id) => {
  return client.get(`/garbage_status/${id}/showuser`);
};

// 詳細
export const getGarbageStatuDetail = (id) => {
  return client.get(`/garbage_status/${id}`);
};

// 新規作成
// header情報を追加
export const createGarbageStatu = (params) => {
  return client.post('/garbage_status', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateGarbageStatu = (id, params) => {
  return client.patch(`/garbage_status/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteGarbageStatu = (id) => {
  return client.delete(`/garbage_status/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
