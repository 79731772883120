// /src/lib/api/company.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getCompanyCdNewList = () => {
  return client.get('/company_cd_news');
};

// 詳細
export const getCompanyCdNewDetail = (id) => {
  return client.get(`/company_cd_news/${id}`);
};

// 事業所指定一覧 idはグループ１と２
export const getCompanyCdNewPref = (id) => {
  return client.get(`/company_cd_news/${id}/showPref`);
};

// 新規作成
// header情報を追加
export const createCompanyCdNew = (params) => {
  return client.post('/company_cd_news', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateCompanyCdNew = (id, params) => {
  return client.patch(`/company_cd_news/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteCompanyCdNew = (id) => {
  return client.delete(`/company_cd_news/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
