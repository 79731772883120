// /src/lib/api/receipt_adm_waste.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptAdmWasteList = () => {
  return client.get('/receipt_adm_wastes');
};

// 事業場選択一覧
export const getReceiptAdmWasteUserDetail = (id) => {
  return client.get(`/receipt_adm_wastes/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptAdmWasteUser = (id) => {
  return client.get(`/receipt_adm_wastes/${id}/showuser`);
};

// 詳細
export const getReceiptAdmWasteDetail = (id) => {
  return client.get(`/receipt_adm_wastes/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptAdmWaste = (params) => {
  return client.post('/receipt_adm_wastes', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptAdmWaste = (id, params) => {
  return client.patch(`/receipt_adm_wastes/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptAdmWaste = (id) => {
  return client.delete(`/receipt_adm_wastes/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
