export const statusName = [
    "排出前","","","","","","","","","",
    "区間１運搬中","区間１運搬先","","","","","","","","",
    "区間２運搬中","区間２運搬先","","","","","","","","",
    "区間３運搬中","区間３運搬先","","","","","","","","",
    "区間４運搬中","区間４運搬先","","","","","","","","",
    "区間５運搬中","区間５運搬先","","","","","","","","",
    "中間処理中","中間処理済","","","","","","","","",
    "最終処分中","最終処分済","","","","","","","","",
    "","","","","","","","","","",
    "","","","","","","","","","",
];
export const ediFlg = [
    "送信不要","未送信（変更あり）","未送信（CSV作成済）","送信済（CSV受信済）","","","","取消未反映","取消送信済","取消受信済",
];
export const prefectureName = [
    "","北海道","青森県","岩手県","宮城県","秋田県","山形県","福島県","茨城県","栃木県",
    "群馬県","埼玉県","千葉県","東京都","神奈川県","新潟県","富山県","石川県","福井県","山梨県",
    "長野県","岐阜県","静岡県","愛知県","三重県","滋賀県","京都府","大阪府","兵庫県","奈良県",
    "和歌山県","鳥取県","島根県","岡山県","広島県","山口県","徳島県","香川県","愛媛県","高知県",
    "福岡県","佐賀県","長崎県","熊本県","大分県","宮崎県","鹿児島県","沖縄県"
];
export const toxicSubstanceName = [
    "","水銀又はその化合物","カドミウム又はその化合物","鉛又はその化合物","有機燐化合物",
    "六価クロム化合物","砒素又はその化合物","シアン化合物","PCB","トリクロロエチレン",
    "テトラクロロエチレン","ジクロロメタン","四塩化炭素","1・2-ジクロロエタン","1・1-ジクロロエチレン",
    "シス-1・2-ジクロロエチレン","1・1・1-トリクロロエタン","1・1・2-トリクロロエタン","1・3-ジクロロプロペン（D-D）","チウラム",
    "シマジン（CAT）","チオベンカルブ（ベンチオカーブ）","ベンゼン","セレン又はその化合物","ダイオキシン類（有機塩素化合物）",
    "銅又はその化合物","亜鉛又はその化合物","弗化物","ベリリウム又はその化合物","クロム又はその化合物",
    "ニッケル又はその化合物","バナジウム又はその化合物","フェノール類",
]
export const transportMethodName = [
    "","車両","船舶","鉄道","その他","","","","","",
];
