import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import GarbageOldListTable from './GarbageOldListTable';
import { getEgsTGarbageOldCsvCreate } from '../../lib/api/egs_t_garbage_old';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const GarbageOldList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
      // setOpen(!open); 
      //   res = await getEgsTGarbageOldOperation(currentUser.traderCd + "_" + currentUser.traderKbn);
       // setDataList(res.data);
      // setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const handleExportCSV = async () => {
    if (window.confirm(' CSVファイル（全件・UTF-8形式）を作成しダウンロードします。よろしいですか？')) {
      try {
        setOpen(!open);
        const response = await getEgsTGarbageOldCsvCreate(currentUser.traderCd);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SDGs12_WasteOld201801-202303.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        window.alert(' CSVファイル 「'+ a.download + '」をダウンロードしました。')
        setOpen(false);
      } catch (error) {
        console.error('CSVエクスポートエラー:', error);
      }
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>廃棄物一覧（環境ガードシステム 2018年1月～2023年3月）</h1>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Button
            type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); handleExportCSV(); }} disabled={currentUser.traderCd.length!=12} style={{ marginLeft: 24 }} >
            CSVファイルダウンロード（全件・UTF-8形式）
      </Button>
      <SpaceRow height={10} />

      <GarbageOldListTable
        dataList={dataList}    // resdataList
//        handleDelete={handleDelete}
        currentUser={currentUser}

//        handleCancel={handleCancel}
      />
    </>
  );
};
export default GarbageOldList;

/*
        onClick={() => history.push('/garbage_reserve_select')}

        <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/garbage_infoPdf')}
      >
        PDF出力
      </Button>
*/
