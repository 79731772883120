// /src/lib/api/garbage_cnt.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getGarbageCntList = () => {
  return client.get('/garbage_cnts');
};

// 事業所指定一覧 idは事業所CD
export const getGarbageCntUser = (id) => {
  return client.get(`/garbage_cnts/${id}/showuser`);
};

// 運用中廃棄物 idは'all'
export const getGarbageCntOpe = (id) => {
  return client.get(`/garbage_cnts/${id}/showope`);
};

