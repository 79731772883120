import React, { useEffect, useState, useContext } from 'react';
import { getDemandBCnt0930EdiList } from '../../lib/api/demand_b_cnt0930_edi';
import { getDemandBCnt1001EdiList } from '../../lib/api/demand_b_cnt1001_edi';
import { getDemandBCntOldList } from '../../lib/api/demand_b_cnt_old';
//import { getEgsMChargeUser } from '../../lib/api/egs_m_charge';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import DemandBList0930 from './DemandBList0930';
import DemandBList1001 from './DemandBList1001';
import DemandBListOld from './DemandBListOld';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DemandBList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [cnt0930List, setCnt0930List] = useState([]);
  const [cnt1001List, setCnt1001List] = useState([]);
  const [cntOldList, setCntOldList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res1;
  let res2;
  let res3;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
//     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
      res1 = await getDemandBCnt0930EdiList();
      res2 = await getDemandBCnt1001EdiList();
      res3 = await getDemandBCntOldList();
    }    
//      console.log(res1.data);
      setCnt0930List(res1.data);
      setCnt1001List(res2.data);
      setCntOldList(res3.data);
//     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

//  console.log(cnt0930List);

  const history = useHistory();

  return (
    <>
      <h1>Ｂ料金集計</h1>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DemandBList0930
        cnt0930List={cnt0930List}
        currentUser={currentUser}
      />
      <DemandBList1001
        cnt1001List={cnt1001List}
        currentUser={currentUser}
      />
      <DemandBListOld
        cntOldList={cntOldList}
        currentUser={currentUser}
  />
    </>
  );
};
export default DemandBList;
