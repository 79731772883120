import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';

import { makeStyles } from '@material-ui/core/styles';
import './app.css';
import dayjs from 'dayjs';
import ApproveListTableForm from './ApproveListTableForm';

import '@grapecity/wijmo.styles/wijmo.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { isNumber, saveFile } from '@grapecity/wijmo';
import { CellType, CellRange } from '@grapecity/wijmo.grid';

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '選択実行',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function ApproveListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
  const history = useHistory();
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);

  const [dataList2, setDataList2] = useState({});


  // 行の更新
  const updateRows = () => {
    // console.log(selRows);
    const updateselRows = selRows.current.map((output, index) => {
      // console.log(dataList[index].status);
      return `${index + 1}番目は${output}`;
    });
    // console.log(updateselRows);
    // if(selRows.current.length == 0) return;
    // const newRows = rows.filter(v => selRows.current.indexOf(v.id) == -1); /* チェックの入ったid(行)を除外する */
    // setRows(newRows);
  }

  const handleSubmit = async (e) => {
    //e.preventDefault();
    // console.log('selRows',selRows);
    var params = {};

    for (let i = 0; i<selRows.current.length; i++){
      const resdataList = dataList.filter(dataList => {
         return dataList.id === selRows.current[i]
      })
      // console.log('resdataList',resdataList)
      // console.log('resdataList.nextstatus',resdataList[0].nextStatus)

      if (resdataList[0].nextStatus == "11"){
        params = {
          status: resdataList[0].nextStatus,
          destination1_at: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }else if (resdataList[0].nextStatus == "21"){
        params = {
          status: resdataList[0].nextStatus,
          destination2_at: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }else if (resdataList[0].nextStatus == "31"){
        params = {
          status: resdataList[0].nextStatus,
          destination3_at: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }else if (resdataList[0].nextStatus == "41"){
        params = {
          status: resdataList[0].nextStatus,
          destination4_at: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }else if (resdataList[0].nextStatus == "51"){
        params = {
          status: resdataList[0].nextStatus,
          destination5_at: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }else if (resdataList[0].nextStatus == "60"){
        params = {
          status: resdataList[0].nextStatus,
          middle_start: dayjs().format('YYYY-MM-DD'),
          edi_send_flg: 1,
        };
      }
      // console.log('params',params);
    try {
      const res = await updateEgsTGarbageEdi(selRows.current[i], params);
      // console.log(res);
    } catch (e) {
      console.log(e);
    }
    history.push('/'); // 画面更新
    }
  };

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    // console.log("dataList2.id",dataList2.id);
  };

  const cols = [
    {
      field: 'selectFlg',
      headerName: '選択',
      width: 50
    },
    {
      field: 'authName',
      headerName: '承認',
      width: 100
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 120
    },
    {
      field: 'exhaustName',  // traderName
      headerName: '承認事業場',
      width: 200
    },
    {
      field: 'infoName',
      headerName: '承認種別',
      width: 150
    },
    {
      field: 'kukan',
      headerName: '区間',
      width: 80
    },
    {
      field: 'ediCompareItemNameId',
      headerName: '変更桁位置',
      width: 100
    },
    {
      field: 'oldExhaustDate',
      headerName: '排出日・変更前',
      width: 120
    },
    {
      field: 'newExhaustDate',
      headerName: '変更後',
      width: 120
    },
    {
      field: 'oldAmount1',
      headerName: '数量・変更前',
      width: 100
    },
    {
      field: 'newAmount1',
      headerName: '変更後',
      width: 100
    },
    {
      field: 'oldPackingAmount1',
      headerName: '荷姿数量・変更前',
      width: 100
    },
    {
      field: 'newPackingAmount1',
      headerName: '変更後',
      width: 100
    },
    {
      field: 'oldDisposalMethodName',
      headerName: '処分方法・変更前',
      width: 150
    },
    {
      field: 'newDisposalMethodName',
      headerName: '変更後',
      width: 150
    }
  ]

return (
    <>
    <div className={classes.container}>
        <DataGridPro
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          pagination
          sx={styles.grid}
          columns={cols}
          rows={dataList}
          density="compact"
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}

          onRowClick={handleRowClick}

        />
      </div>
        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button variant="contained" color='primary' onClick={(e) => handleSubmit()} >選択実行</Button>
        </Box>

      <div className={classes.container} >  {/*ref={pdfRef}> */}
        <ApproveListTableForm
          dataList2={dataList2}
        />
      </div>


    </>

  );
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default ApproveListTable;
