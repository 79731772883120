// /src/lib/api/exhaust_plan.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEditDelRequestList = () => {
  return client.get('/edit_del_requests');
};

// 詳細
export const getEditDelRequestDetail = (id) => {
  console.log(id)
  return client.get(`/edit_del_requests/${id}`);
};

// 事業所指定一覧 idは収集・処分事業所CD
export const getEditDelRequestUser = (id) => {
  return client.get(`/edit_del_requests/${id}/showuser`);
};
// 事業所指定一覧 idは排出事業所CD
export const getEditDelRequestExhaust = (id) => {
  return client.get(`/edit_del_requests/${id}/showexhaust`);
};
// idはマニフェストNo
export const getEditDelRequestManiNo = (id) => {
  return client.get(`/edit_del_requests/${id}/showmanifestno`);
};
// 新規作成
// header情報を追加
export const createEditDelRequest = (params) => {
  return client.post('/edit_del_requests', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEditDelRequest = (id, params) => {
  return client.patch(`/edit_del_requests/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
// manifest_noに基づいてstatusを'処理中'に更新する
export const updateStatusRun = (manifestNo) => {
  return client.patch(`/edit_del_requests/update_status_run`, {
    manifest_no: manifestNo
  }, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
// manifest_noに基づいてstatusを'処理済'に更新する
export const updateStatusDone = (manifestNo) => {
  return client.patch(`/edit_del_requests/update_status_done`, {
    manifest_no: manifestNo
  }, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
// 削除
// header情報を追加
export const deleteEditDelRequest = (id) => {
  return client.delete(`/edit_del_requests/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
