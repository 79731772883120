// src/components/users/SignIn.jsx
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// style
import { Backdrop,CircularProgress,makeStyles,Grid,Typography,Link,Button,Card, CardHeader, CardContent, } from '@material-ui/core';
import SpaceRow from '../commons/SpaceRow';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  newscard: {
    padding: theme.spacing(2),
    maxWidth: 1000,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.ecotechl.com/">
        ecotechl.com
      </Link>{' '}
      {'2023-'+ new Date().getFullYear()}
      {'.'}
      {'　　'}
      <Link target="_blank" color="inherit" href="https://s3-sdgs12com-manual.s3.ap-northeast-1.amazonaws.com/SDGs12TermsofUse_20230201.pdf">
        利用規約
      </Link>{' '}
    </Typography>
  );
}

const ManualDisp = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <h1>産業廃棄物SDGs推進システム</h1>

        <SpaceRow height={75} />
        <Card className={classes.newscard}>
          <CardHeader
              title="【収集運搬業者向け操作マニュアル一覧】"
          />
          <CardContent>

          <Link target="_blank" variant="h6" href="https://www.ecotechl.com/wp-content/uploads/2025/03/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%EF%BC%88%E5%8F%8E%E9%9B%86%E9%81%8B%E6%90%AC%E6%A5%AD%E8%80%85%E7%89%88%EF%BC%89_20230305.pdf">●操作マニュアル（収集運搬業者版）</Link><br />
          <Link target="_blank" variant="h6" href="https://www.ecotechl.com/wp-content/uploads/2025/03/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%EF%BC%88%EF%BC%B1%EF%BC%B2%E3%82%B3%E3%83%BC%E3%83%89%E5%88%A9%E7%94%A8%EF%BC%89_20240313.pdf">●操作マニュアル（ＱＲコード利用）</Link><br />
          <Link target="_blank" variant="h6" href="https://www.ecotechl.com/wp-content/uploads/2025/03/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%EF%BC%88%E3%82%B9%E3%83%9E%E3%83%9B%E6%93%8D%E4%BD%9C%EF%BC%89_20240314.pdf">●操作マニュアル（スマホ操作）</Link><br />
          <Link target="_blank" variant="h6" href="https://www.ecotechl.com/wp-content/uploads/2025/03/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%EF%BC%88%E8%AB%8B%E6%B1%82%E6%9B%B8%E4%BD%9C%E6%88%90%EF%BC%89_20241122.pdf">●操作マニュアル（請求書作成）</Link><br />

          </CardContent>
      </Card>
      </Grid>
      <SpaceRow height={50} />
      <Copyright />
    </div>
  );
};
export default ManualDisp;
