import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { getReceiptLoadList, getReceiptLoadUser } from '../../lib/api/receipt_load';
import { updateEgsTGarbageEdi } from '../../lib/api/egs_t_garbage_edi';
import { createSagyoReport } from '../../lib/api/sagyo_report';
import { Box,Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
import {
    DataGridPro,
    GridColDef,
    GridRowsProp,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    jaJP
  } from '@mui/x-data-grid-pro'
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// style
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '積保積込登録',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const MblReceiptUnload = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let selRows = React.useRef([]);
  let res;
  let receiptChargeId;

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'destination2Name',
      headerName: '区間２運搬先事業場',
      width: 250
    },
    {
      field: 'exhaustDate',
      headerName: '排出日',
      width: 100
    },
    {
      field: 'garbageTag',
      headerName: 'ゴミタグ',
      hide: true,
      width: 200
    },
    {
      field: 'manifestNo',
      headerName: 'マニフェストNo',
      width: 150
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 250
    },
    {
      field: 'wasteName',
      headerName: '廃棄物名称',
      width: 150
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width:100
    },
    {
      field: 'sect1TransportName',
      headerName: '区間１運搬事業者',
      hide: true,
      width: 300
    },
    {
      field: 'sect1TransportChargeName',
      headerName: '運搬担当者',
      width: 100
    },
    {
      field: 'amount1',
      headerName: '数量',
      width:100,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingAmount1',
      headerName: '荷姿数量',
      width:90,
      headerAlign: 'center', // 列ヘッダの表示位置
      align: 'right'         // セルテキストの表示位置
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 150
    },
    {
      field: 'memo1',
      headerName: '備考',
      width: 150
    },
    {
      field: 'destination1Name',
      headerName: '区間１運搬先事業場',
      width: 300
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: true,
      width: 80
    },
    {
      field: 'statusName',
      headerName: '状態',
      hide: true,
      width: 120
    },
    {
      field: 'nextStatus',
      headerName: '次Status',
      hide: true,
      width: 80
    },
 ]

  useEffect(() => {
    handleGetList();
  }, []);

  const history = useHistory();

  const handleGetList = async () => {
    try {
        setOpen(!open);
        res = await getReceiptLoadUser(currentUser.traderCd);
        // console.log('res',res.data);
        setDataList(res.data);
        // const userdataList = res.data;
        // const mbluser = Cookies.get('mbl_user');
        // if (mbluser !== undefined){
        //   const mbluser2 = mbluser.split(':');
        //   if (mbluser2[0] !== undefined){
        //     const resdataUser = userdataList.filter(userdataList => {
        //       return ((userdataList.sect1TransportChargeId == mbluser2[0]) ||
        //               (userdataList.sect2TransportChargeId == mbluser2[0]) ||
        //               (userdataList.sect3TransportChargeId == mbluser2[0]) ||
        //               (userdataList.sect4TransportChargeId == mbluser2[0]) ||
        //               (userdataList.sect5TransportChargeId == mbluser2[0]))
        //     })
        //     // console.log("resdataUser",resdataUser);
        //     setDataList(resdataUser);
        //   }
        // }else{
        //   setDataList(res.data);
        // }
        setOpen(false);
    } catch (e) {
        console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    if (selRows.current.length >0){
      var params = {};
      const today = dayjs().format('YYYY-MM-DD');
      // console.log('today',today);
      const mbluser = Cookies.get('mbl_user');
      if (mbluser !== undefined){
        const mbluser2 = mbluser.split(':');
        //e.preventDefault();
        if (window.confirm(' 積替え保管施設での積込登録を行います。\n　積込日：'+today+'\n　積込担当者：'+mbluser2[2]+'\nよろしいですか？')) {
        // console.log('selRows',selRows);
          for (let i = 0; i<selRows.current.length; i++){

            if (dataList[i].nextStatus == "10"){
              params = {
                status: dataList[i].nextStatus,
                sect1_at: today,
                sect1_transport_charge_id: mbluser2[0],
                sect1_transport_charge_cd: mbluser2[1],
                sect1_transport_charge_name: mbluser2[2],
              };
            }else if (dataList[i].nextStatus == "20"){
              params = {
                status: dataList[i].nextStatus,
                sect2_at: today,
                sect2_transport_charge_id: mbluser2[0],
                sect2_transport_charge_cd: mbluser2[1],
                sect2_transport_charge_name: mbluser2[2],
              };
            }else if (dataList[i].nextStatus == "30"){
              params = {
                status: dataList[i].nextStatus,
                sect3_at: today,
                sect3_transport_charge_id: mbluser2[0],
                sect3_transport_charge_cd: mbluser2[1],
                sect3_transport_charge_name: mbluser2[2],
              };
            }else if (dataList[i].nextStatus == "40"){
              params = {
                status: dataList[i].nextStatus,
                sect4_at: today,
                sect4_transport_charge_id: mbluser2[0],
                sect4_transport_charge_cd: mbluser2[1],
                sect4_transport_charge_name: mbluser2[2],
              };
            }else if (dataList[i].nextStatus == "50"){
              params = {
                status: dataList[i].nextStatus,
                sect5_at: today,
                sect5_transport_charge_id: mbluser2[0],
                sect5_transport_charge_cd: mbluser2[1],
                sect5_transport_charge_name: mbluser2[2],
              };
            }else if (dataList[i].nextStatus == "60"){
              params = {
                status: dataList[i].nextStatus,
                middle_start: today,
              };
            }

            // }
            // console.log('selRows.current[i]',selRows.current[i]);
            // console.log('params',params);
            try {
            setOpen(!open);
              const res = await updateEgsTGarbageEdi(selRows.current[i], params);
              // console.log(res);
            setOpen(false);
            } catch (e) {
              console.log(e);
            }

          }

          // console.log("mbluser2",mbluser2);
          // const currentDate = new Date();
          // const currentTime = currentDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});             
          // const sr_params = {
          //   chargeId: mbluser2[0],
          //   sagyoDate: currentDate.toISOString().slice(0, 10), // 現在日付を設定
          //   sagyoTime: currentTime, // 現在時刻を設定
          //   sagyoPlace: dataList[0].sect2TransportName,
          //   sagyoTask: '区間２積込',
          //   sagyoMemo: ''
          // };
          const currentDate = dayjs().format('YYYY-MM-DD');
          const currentTime = dayjs().format('HH:mm');
          const sr_params = {
              chargeId: mbluser2[0],
              sagyoDate: currentDate,
              sagyoTime: currentTime,
              sagyoPlace: dataList[0].sect2TransportName,
              sagyoTask: '区間２積込',
              sagyoMemo: ''
          }; 
          // console.log('sr_params',sr_params)
          try {
            const res = await createSagyoReport(sr_params);
            // console.log("sr_createSagyoReport_res",res);
          } catch (e) {
            console.log(e);
            console.log(e.response);
          }

          window.alert("積込登録を完了しました。")
        }
      }else{
        window.alert("担当者が登録されておりません。")
      }
    }else{
      window.alert("積込データが選択されておりません。")
    }
    history.push('/'); // 画面更新
  };

  return (
    <>
      <h1>積保積込登録　　　　　　　　　　　</h1>

      {/* <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <div className={classes.container}>
      <Box sx={{ height: 520, width: '100%' }}>
        <DataGridPro
          sx={styles.grid}
          columns={cols}
          rows={dataList}
          density="compact"
          // autoHeight
          checkboxSelection
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            },
          }}
          disableSelectionOnClick
          onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
          components={{
            Toolbar: CustomToolbar,　// カスタムツールバーを指定する
          }}
          showColumnRightBorder // 列ヘッダセルの右側に線を引く
          showCellRightBorder   // セルの右側に線を引く
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      </div>
      <h3>　↑ 積替え保管施設より積込対象の廃棄物を選択（ㇾ）して下さい</h3>

      <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant='contained' onClick={() => history.push(`/` )} style={{ marginRight: 30, fontSize: '1.2em' }}>
            戻る
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={(e) => handleSubmit(e)}
            style={{ marginRight: 10, fontSize: '1.2em' }}
          >
            積保積込登録
          </Button>
        </Box>
        <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
const styles = {
    grid: {
      // '.MuiDataGrid-toolbarContainer': {
      //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
      // },
      // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
      //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
      // },
       // 列ヘッダに背景色を指定
      '.MuiDataGrid-columnHeaders': {
        backgroundColor: '#65b2c6',
        color: '#fff',
      }
    }
   }
export default MblReceiptUnload;
