import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

//import { updateEgsMLinkage, getEgsMLinkageDetail } from '../../lib/api/egs_m_linkage';
import { updateEgsMLink, getEgsMLinkDetail } from '../../lib/api/egs_m_link';
import FormBody from './EgsMLinkageForm';

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

function EgsMLinkageEdit({ isOpen, doClose, processId }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    egsMTraderId1: 0,
    egsMTraderId2: 0,
  });

  useEffect(() => {
    handleGetData(processId);
  }, [processId]);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const handleGetData = async (processId) => {
    try {
      setLoading(true);
//      const res = await getEgsMLinkageDetail(processId);
      const res = await getEgsMLinkDetail(processId);
      // console.log(res.data);
      setValue({
        egsMTraderId1: res.data.egsMTraderId1,
        egsMTraderId2: res.data.egsMTraderId2,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  // console.log('setvalue', value);

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 追加
    const params = generateParams();
    try {
//      const res = await updateEgsMLinkage(processId, params);
      const res = await updateEgsMLink(processId, params);
      // console.log(res);
      handleCancel();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };


  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      egsMTraderId1: value.egsMTraderI1,
      egsMTraderId2: value.egsMTraderI2,
    };
    return params;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={doClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>連携マスタ編集</h2>
      </DialogTitle>
      <DialogContent>

        <FormBody
          handleBack={handleCancel}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}
export default EgsMLinkageEdit;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
