// /src/lib/api/egs_m_process_flow.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMProcessFlowList = () => {
  return client.get('/egs_m_process_flows');
};


// 詳細（詳細表示用）
export const getEgsMReserveDetail = (id) => {
  return client.get(`/egs_m_process_flows/${id}`);    // egs_m_process_flows
};


// 詳細（追加・更新用）
export const getEgsMProcessFlowDetail = (id) => {
  return client.get(`/egs_m_process_flows/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMProcessFlow = (params) => {
  return client.post('/egs_m_process_flows', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMProcessFlow = (id, params) => {
  return client.patch(`/egs_m_process_flows/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMProcessFlow = (id) => {
  return client.delete(`/egs_m_process_flows/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
