import React, { useEffect, useState, useContext } from 'react';
//import { getDisposalList, getDisposalUser } from '../../lib/api/disposal';
import { getReceiptDisposalList, getReceiptDisposalUser } from '../../lib/api/receipt_disposal';
//import { getChargeUser } from '../../lib/api/charge';
import { getChargeEffective } from '../../lib/api/charge';
import { getReceiptDisposalWasteUser } from '../../lib/api/receipt_disposal_waste';
import { getReceiptDisposalAdmWasteList } from '../../lib/api/receipt_disposal_adm_waste';
////import { getEgsMWasteclassList } from '../../lib/api/egs_m_wasteclass';
import { getReceiptDisposalExhaustUser } from '../../lib/api/receipt_disposal_exhaust';
import { getReceiptDisposalAdmExhaustList } from '../../lib/api/receipt_disposal_adm_exhaust';
import { getReceiptDisposalAdmTransportList } from '../../lib/api/receipt_disposal_adm_transport';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import DisposalListTable from './DisposalListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DisposalList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [dataListC00, setDataListC00] = useState([]); {/* 処分登録担当者 */ }
  const [dataListW00, setDataListW00] = useState([]); {/* 廃棄物名称 */ }
  const [dataListE00, setDataListE00] = useState([]); {/* 排出事業場 */ }
  const [dataListT00, setDataListT00] = useState([]); {/* 収集運搬業者 */ }

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;
  let resW00;
  let resE00;
  let resT00;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
       if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getDisposalList();
        res = await getReceiptDisposalList();
        resW00 = await getReceiptDisposalAdmWasteList();
        resE00 = await getReceiptDisposalAdmExhaustList();
        resT00 = await getReceiptDisposalAdmTransportList();
        // console.log('resT00',resT00.data);
        setDataListT00(resT00.data);
      }else{                                       //事業場区分は管理者以外？
//        res = await getDisposalUser(currentUser.traderCd);
        res = await getReceiptDisposalUser(currentUser.traderCd);
        resW00 = await getReceiptDisposalWasteUser(currentUser.traderCd);
        resE00 = await getReceiptDisposalExhaustUser(currentUser.traderCd);
      }
      // console.log(res.data);
      setDataList(res.data);

//      const resC00 = await getChargeUser(currentUser.traderCd);
      const resC00 = await getChargeEffective(currentUser.traderCd);
      setDataListC00(resC00.data);
      // console.log('resC00',currentUser.traderCd,resC00.data);

      // console.log('resW00',resW00.data);
      setDataListW00(resW00.data);

      // console.log('resE00',resE00.data);
      setDataListE00(resE00.data);

     setOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();

  if (currentUser.traderKbn == '0000100000') {  // 最終処分業者
    return (
      <>
        <h1>最終処分登録</h1>
         <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DisposalListTable
          dataList={dataList}
          dataListC00={dataListC00}
          dataListW00={dataListW00}
          dataListE00={dataListE00}
          dataListT00={dataListT00}
          currentUser={currentUser}
        />
      </>
    );
    }else{
      return (
        <>
          <h1>処分登録　　　　
            <Button onClick={() => window.location.reload()} color="primary">最新（再読み込み）</Button>
          </h1>
           <Backdrop className={classes.backdrop} open={open} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <DisposalListTable
            dataList={dataList}
            dataListC00={dataListC00}
            dataListW00={dataListW00}
            dataListE00={dataListE00}
            dataListT00={dataListT00}
            currentUser={currentUser}
          />
        </>
      );
      }

};
export default DisposalList;
