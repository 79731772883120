// /src/lib/api/exhaust_plan.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getInvoiceSettingList = () => {
  return client.get('/invoice_settings');
};

// 詳細
export const getInvoiceSettingDetail = (id) => {
  console.log(id)
  return client.get(`/invoice_settings/${id}`);
};

// 事業所指定一覧 idは事業所CD
export const getInvoiceSettingUser = (id) => {
  return client.get(`/invoice_settings/show_user/${id}`);
};
// 新規作成
// header情報を追加
export const createInvoiceSetting = (params) => {
  return client.post('/invoice_settings', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateInvoiceSetting = (id, params) => {
  return client.patch(`/invoice_settings/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteInvoiceSetting = (id) => {
  return client.delete(`/invoice_settings/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
