// /src/lib/api/egs_m_disposal_method.js
import client from './client';
import Cookies from 'js-cookie';


// 一覧
export const getEgsMDisposalMethodList = () => {
  return client.get('/egs_m_disposal_methods');
};

// 事業所指定一覧 idは事業所CD
export const getEgsMDisposalMethodUser = (id) => {
  return client.get(`/egs_m_disposal_methods/${id}/showuser`);
};

// 詳細（追加・更新用）
export const getEgsMDisposalMethodDetail = (id) => {
  return client.get(`/egs_m_disposal_methods/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMDisposalMethod = (params) => {
  return client.post('/egs_m_disposal_methods', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMDisposalMethod = (id, params) => {
  return client.patch(`/egs_m_disposal_methods/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMDisposalMethod = (id) => {
  return client.delete(`/egs_m_disposal_methods/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
