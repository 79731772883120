// /src/lib/api/receipt.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptList = () => {
  return client.get('/receipts');
};

// 事業場選択一覧
export const getReceiptUserDetail = (id) => {
  return client.get(`/receipts/${id}/showuserDetail`);
};

// 事業場選択一覧
export const getReceiptUser = (id) => {
  return client.get(`/receipts/${id}/showuser`);
};

// 事業場選択一覧　荷降用
export const getReceiptUnload = (id) => {
  return client.get(`/receipts/${id}/showUnload`);
};

// 詳細
export const getReceiptDetail = (id) => {
  return client.get(`/receipts/${id}`);
};

// 新規作成
// header情報を追加
export const createReceipt = (params) => {
  return client.post('/receipts', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceipt = (id, params) => {
  return client.patch(`/receipts/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceipt = (id) => {
  return client.delete(`/receipts/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
