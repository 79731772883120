import React, { useEffect, useState, useContext } from 'react';
import { getExhaustSumList } from '../../lib/api/exhaust_sum';
//import { getExhaustSumList } from '../../lib/api/exhaust_sum';
//import { getExhaustSumUser } from '../../lib/api/exhaust_sum';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import ExhaustSumTable from './ExhaustSumTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ExhaustSum = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const res = await getExhaustSumList();
//      const res = await getExhaustSumUser(currentUser.traderCd);
      const res = await getExhaustSumList();
      // console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  return (
    <>
      <h1>排出集計</h1>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ExhaustSumTable
        dataList={dataList}
        currentUser={currentUser}
      />
    </>
  );
};
export default ExhaustSum;
