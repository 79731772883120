import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import dayjs from 'dayjs';
import SpaceRow from '../commons/SpaceRow';

import { updateEgsTGarbageEdi, getEgsTGarbageEdiDetail } from '../../lib/api/egs_t_garbage_edi';
//import { createEgsApprovalWaitList } from '../../lib/api/egs_approval_wait_list';
import { createEgsTApproveWait } from '../../lib/api/egs_t_approve_wait';

import { getFirstSelList, getFirstSelUser, getFirstSelId , getFirstSelId0} from '../../lib/api/first_sel';

//import FormBody from './EdiD01Item2ndForm';
//import FormBody from './EdiD01ItemForm';
import FormBody from './LastDisposalForm';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
import {
//  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Typography,
  InputLabel
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
//  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'


const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
{/*}      <GridToolbarFilterButton />  */}
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  container2: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "60vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#CFF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));



function LastDisposalEdit({ currentUser,    isOpen, doClose, ediId , dataList2}) {

  // console.log("dataList2",ediId,dataList2);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dataList3, setDataList3] = useState({});

  const [value, setValue] = useState({
    garbageTag: '',
    processingSeqno: '',
    manifestNo: '',
    arrangementNo: '',
    exhaustDate: '',
    exhaustId: '',
    exhaustCd: '',
    exhaustName: '',
    exhaustChargeId: '',
    exhaustChargeCd: '',
    exhaustChargeName: '',
    sect1TransportId: '',
    sect1TransportCd: '',
    sect1TransportName: '',
    sect1TransportChargeId: '',
    sect1TransportChargeCd: '',
    sect1TransportChargeName: '',
    sect1TransportMethodId: '',
    sect1TransportMethodCd: '',
    sect1TransportMethodName: '',
    sect1CarNo: '',
    destination1Id: '',
    destination1Cd: '',
    destination1Name: '',
    destination1ChargeId: '',
    destination1ChargeCd: '',
    destination1ChargeName: '',
    sect2TransportId: '',
    sect2TransportCd: '',
    sect2TransportName: '',
    sect2TransportChargeId: '',
    sect2TransportChargeCd: '',
    sect2TransportChargeName: '',
    sect2TransportMethodId: '',
    sect2TransportMethodCd: '',
    sect2TransportMethodName: '',
    sect2CarNo: '',
    destination2Id: '',
    destination2Cd: '',
    destination2Name: '',
    destination2ChargeId: '',
    destination2ChargeCd: '',
    destination2ChargeName: '',
    sect3TransportId: '',
    sect3TransportCd: '',
    sect3TransportName: '',
    sect3TransportChargeId: '',
    sect3TransportChargeCd: '',
    sect3TransportChargeName: '',
    sect3TransportMethodId: '',
    sect3TransportMethodCd: '',
    sect3TransportMethodName: '',
    sect3CarNo: '',
    destination3Id: '',
    destination3Cd: '',
    destination3Name: '',
    destination3ChargeId: '',
    destination3ChargeCd: '',
    destination3ChargeName: '',
    sect4TransportId: '',
    sect4TransportCd: '',
    sect4TransportName: '',
    sect4TransportChargeId: '',
    sect4TransportChargeCd: '',
    sect4TransportChargeName: '',
    sect4TransportMethodId: '',
    sect4TransportMethodCd: '',
    sect4TransportMethodName: '',
    sect4CarNo: '',
    destination4Id: '',
    destination4Cd: '',
    destination4Name: '',
    destination4ChargeId: '',
    destination4ChargeCd: '',
    destination4ChargeName: '',
    sect5TransportId: '',
    sect5TransportCd: '',
    sect5TransportName: '',
    sect5TransportChargeId: '',
    sect5TransportChargeCd: '',
    sect5TransportChargeName: '',
    sect5TransportMethodId: '',
    sect5TransportMethodCd: '',
    sect5TransportMethodName: '',
    sect5CarNo: '',
    destination5Id: '',
    destination5Cd: '',
    destination5Name: '',
    destination5ChargeId: '',
    destination5ChargeCd: '',
    destination5ChargeName: '',






    lastProcessingTraderId: '',
    lastProcessingTraderCd: '',
    lastProcessingTraderName: '',
    lastProcessingChargeId: '',
    lastProcessingChargeCd: '',
    lastProcessingTraderChargeName: '',



    wasteCd: '',
    wasteName: '',

    disposalMethodCd: '',
    disposalMethodName: '',






//    amount: '',
    amount1: '',
    amount2: '',
    amount3: '',
    amount4: '',
    amount5: '',
    packingAmount1: '',





    unitCd: '',
    unitName: '',

    packingCd: '',
    packingName: '',
//    packingAmount: '',
    packingAmount1: '',






    memo: '',
    memo1: '',
    memo2: '',
    memo3: '',
    memo4: '',
    memo5: '',
    toxicCd01: '',
    toxicCd02: '',
    toxicCd03: '',
    toxicCd04: '',
    toxicCd05: '',
    toxicCd06: '',
    toxicName01: '',
    toxicName02: '',
    toxicName03: '',
    toxicName04: '',
    toxicName05: '',
    toxicName06: '',

    status: '',
    statusName: '',
    sect1At: '',
    destination1At: '',
    sect2At: '',
    destination2At: '',
    sect3At: '',
    destination3At: '',
    sect4At: '',
    destination4At: '',
    sect5At: '',
    destination5At: '',
    middleStart: '',
    middleEnd: '',
    lastProcessingStart: '',
    lastProcessingEnd: '',

    ediSendFlg: '',






    reserveAuthorityCd: '',
    lastReportFlg: '',
    lastReportName: '',
    middleProcessingInfoFlg: '',


    lastProcessingCnt: '',
  });


  const history = useHistory();

  useEffect(() => {
    handleGetData(ediId,dataList2);
  }, [ediId]);

/*
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
*/

//  const classes = useStyles();
//  const [open, setOpen] = React.useState(false);
//  const [dataList3, setDataList3] = useState({});

  var selectdataList = [];
  let res;

  useEffect(() => {
    handleGetList(dataList2);
  }, [dataList2]);

  const handleGetList = async () => {
    try {
     setOpen(!open);
//      const resC00 = await getChargeUser(dataList2.exhaustCd);
//      // console.log("resC00.data",resC00.data);
//      setDataListC00(resC00.data);
//      const resC01 = await getChargeUser(dataList2.sect1TransportCd);
//      // console.log("resC01.data",resC01.data);
//      setDataListC01(resC01.data);

/*
      if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//        res = await getFirstSelList();
//        res = await getFirstSelList2(23);
//        res = await getFirstSelId(dataList2.id);
        res = await getFirstSelId0(dataList2.id);
      }else if (currentUser.traderKbn == '0001001000'){   //事業場区分は中間処理・排出事業者？
        res = await getFirstSelList();
      }else{                                       //事業場区分は管理者以外？
        res = await getFirstSelUser(currentUser.traderCd);
      }
*/
      res = await getFirstSelId0(dataList2.id);  // 事業者区分に関わらず、選択している1次マニフェストのみを抽出する

      // console.log(res.data);
      setDataList3(res.data);
//      // console.log('test1',selRows.current[0])

//      const dataListTransport = await getEgsMTransportMethodList();
//      setDataListTransport(dataListTransport.data);


     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };



  const handleGetData = async (id,dataList2) => {
    try {
      setOpen(!open);
      const res = await getEgsTGarbageEdiDetail(id);
      // console.log(res.data);
      // console.log(ediId,id,dataList2);
      setValue({
//        garbageTag: res.data.garbageTag,
//        layoutNo: 'D01',    // res.data.layoutNo,
//        layoutNo: res.data.layoutNo,

        garbageTag: res.data.garbageTag,
        processingSeqno: res.data.processingSeqno,
        manifestNo: res.data.manifestNo,
        arrangementNo: res.data.arrangementNo,
        exhaustDate: res.data.exhaustDate,
        exhaustId: res.data.exhaustId,
        exhaustCd: res.data.exhaustCd,
        exhaustName: res.data.exhaustName,
        exhaustChargeId: res.data.exhaustChargeId,
        exhaustChargeCd: res.data.exhaustChargeCd,
        exhaustChargeName: res.data.exhaustChargeName,
        sect1TransportId: res.data.sect1TransportId,
        sect1TransportCd: res.data.sect1TransportCd,
        sect1TransportName: res.data.sect1TransportName,
        sect1TransportChargeId: res.data.sect1TransportChargeId,
        sect1TransportChargeCd: res.data.sect1TransportChargeCd,
        sect1TransportChargeName: res.data.sect1TransportChargeName,
        sect1TransportMethodId: res.data.sect1TransportMethodId,
        sect1TransportMethodCd: res.data.sect1TransportMethodCd,
        sect1TransportMethodName: res.data.sect1TransportMethodName,
        sect1CarNo: res.data.sect1CarNo,
        destination1Id: res.data.destination1Id,
        destination1Cd: res.data.destination1Cd,
        destination1Name: res.data.destination1Name,
        destination1ChargeId: res.data.destination1ChargeId,
        destination1ChargeCd: res.data.destination1ChargeCd,
        destination1ChargeName: res.data.destination1ChargeName,
        sect2TransportId: res.data.sect2TransportId,
        sect2TransportCd: res.data.sect2TransportCd,
        sect2TransportName: res.data.sect2TransportName,
        sect2TransportChargeId: res.data.sect2TransportChargeId,
        sect2TransportChargeCd: res.data.sect2TransportChargeCd,
        sect2TransportChargeName: res.data.sect2TransportChargeName,
        sect2TransportMethodId: res.data.sect2TransportMethodId,
        sect2TransportMethodCd: res.data.sect2TransportMethodCd,
        sect2TransportMethodName: res.data.sect2TransportMethodName,
        sect2CarNo: res.data.sect2CarNo,
        destination2Id: res.data.destination2Id,
        destination2Cd: res.data.destination2Cd,
        destination2Name: res.data.destination2Name,
        destination2ChargeId: res.data.destination2ChargeId,
        destination2ChargeCd: res.data.destination2ChargeCd,
        destination2ChargeName: res.data.destination2ChargeName,
        sect3TransportId: res.data.sect3TransportId,
        sect3TransportCd: res.data.sect3TransportCd,
        sect3TransportName: res.data.sect3TransportName,
        sect3TransportChargeId: res.data.sect3TransportChargeId,
        sect3TransportChargeCd: res.data.sect3TransportChargeCd,
        sect3TransportChargeName: res.data.sect3TransportChargeName,
        sect3TransportMethodId: res.data.sect3TransportMethodId,
        sect3TransportMethodCd: res.data.sect3TransportMethodCd,
        sect3TransportMethodName: res.data.sect3TransportMethodName,
        sect3CarNo: res.data.sect3CarNo,
        destination3Id: res.data.destination3Id,
        destination3Cd: res.data.destination3Cd,
        destination3Name: res.data.destination3Name,
        destination3ChargeId: res.data.destination3ChargeId,
        destination3ChargeCd: res.data.destination3ChargeCd,
        destination3ChargeName: res.data.destination3ChargeName,
        sect4TransportId: res.data.sect4TransportId,
        sect4TransportCd: res.data.sect4TransportCd,
        sect4TransportName: res.data.sect4TransportName,
        sect4TransportChargeId: res.data.sect4TransportChargeId,
        sect4TransportChargeCd: res.data.sect4TransportChargeCd,
        sect4TransportChargeName: res.data.sect4TransportChargeName,
        sect4TransportMethodId: res.data.sect4TransportMethodId,
        sect4TransportMethodCd: res.data.sect4TransportMethodCd,
        sect4TransportMethodName: res.data.sect4TransportMethodName,
        sect4CarNo: res.data.sect4CarNo,
        destination4Id: res.data.destination4Id,
        destination4Cd: res.data.destination4Cd,
        destination4Name: res.data.destination4Name,
        destination4ChargeId: res.data.destination4ChargeId,
        destination4ChargeCd: res.data.destination4ChargeCd,
        destination4ChargeName: res.data.destination4ChargeName,
        sect5TransportId: res.data.sect5TransportId,
        sect5TransportCd: res.data.sect5TransportCd,
        sect5TransportName: res.data.sect5TransportName,
        sect5TransportChargeId: res.data.sect5TransportChargeId,
        sect5TransportChargeCd: res.data.sect5TransportChargeCd,
        sect5TransportChargeName: res.data.sect5TransportChargeName,
        sect5TransportMethodId: res.data.sect5TransportMethodId,
        sect5TransportMethodCd: res.data.sect5TransportMethodCd,
        sect5TransportMethodName: res.data.sect5TransportMethodName,
        sect5CarNo: res.data.sect5CarNo,
        destination5Id: res.data.destination5Id,
        destination5Cd: res.data.destination5Cd,
        destination5Name: res.data.destination5Name,
        destination5ChargeId: res.data.destination5ChargeId,
        destination5ChargeCd: res.data.destination5ChargeCd,
        destination5ChargeName: res.data.destination5ChargeName,






        lastProcessingTraderId: res.data.lastProcessingTraderId,
        lastProcessingTraderCd: res.data.lastProcessingTraderCd,
        lastProcessingTraderName: res.data.lastProcessingTraderName,
        lastProcessingChargeId: res.data.lastProcessingChargeId,
        lastProcessingChargeCd: res.data.lastProcessingChargeCd,
        lastProcessingTraderChargeName: res.data.lastProcessingTraderChargeName,



        wasteCd: res.data.wasteCd,
        wasteName: res.data.wasteName,

        disposalMethodCd: res.data.disposalMethodCd,
        disposalMethodName: res.data.disposalMethodName,






//        amount: res.data.amount,
        amount1: res.data.amount1,
        amount2: res.data.amount2,
        amount3: res.data.amount3,
        amount4: res.data.amount4,
        amount5: res.data.amount5,
        packingAmount1: res.data.packingAmount1,

        unitCd: res.data.unitCd,
        unitName: res.data.unitName,

        packingCd: res.data.packingCd,
        packingName: res.data.packingName,
//        packingAmount: res.data.packingAmount,
        packingAmount1: res.data.packingAmount1,






        memo: res.data.memo,
        memo1: res.data.memo1,
        memo2: res.data.memo2,
        memo3: res.data.memo3,
        memo4: res.data.memo4,
        memo5: res.data.memo5,
        toxicCd01: res.data.toxicCd01,
        toxicCd02: res.data.toxicCd02,
        toxicCd03: res.data.toxicCd03,
        toxicCd04: res.data.toxicCd04,
        toxicCd05: res.data.toxicCd05,
        toxicCd06: res.data.toxicCd06,
        toxicName01: res.data.toxicName01,
        toxicName02: res.data.toxicName02,
        toxicName03: res.data.toxicName03,
        toxicName04: res.data.toxicName04,
        toxicName05: res.data.toxicName05,
        toxicName06: res.data.toxicName06,

        status: res.data.status,
        statusName: res.data.statusName,
        sect1At: res.data.sect1At,
        destination1At: res.data.destination1At,
        sect2At: res.data.sect2At,
        destination2At: res.data.destination2At,
        sect3At: res.data.sect3At,
        destination3At: res.data.destination3At,
        sect4At: res.data.sect4At,
        destination4At: res.data.destination4At,
        sect5At: res.data.sect5At,
        destination5At: res.data.destination5At,
        middleStart: res.data.middleStart,
        middleEnd: res.data.middleEnd,
        lastProcessingStart: res.data.lastProcessingStart,
        lastProcessingEnd: res.data.lastProcessingEnd,

        ediSendFlg: res.data.ediSendFlg,






        reserveAuthorityCd: res.data.reserveAuthorityCd,
        lastReportFlg: res.data.lastReportFlg,
        middleProcessingInfoFlg: res.data.middleProcessingInfoFlg,


        lastProcessingCnt: res.data.lastProcessingCnt,

      // 0901-E


      garbageTag: dataList2.garbageTag,
      processingSeqno: dataList2.processingSeqno,
      manifestNo: dataList2.manifestNo,
      arrangementNo: dataList2.arrangementNo,
      exhaustDate: dataList2.exhaustDate,
      exhaustId: dataList2.exhaustId,
      exhaustCd: dataList2.exhaustCd,
      exhaustName: dataList2.exhaustName,
      exhaustChargeId: dataList2.exhaustChargeId,
      exhaustChargeCd: dataList2.exhaustChargeCd,
      exhaustChargeName: dataList2.exhaustChargeName,
      sect1TransportId: dataList2.sect1TransportId,
      sect1TransportCd: dataList2.sect1TransportCd,
      sect1TransportName: dataList2.sect1TransportName,
      sect1TransportChargeId: dataList2.sect1TransportChargeId,
      sect1TransportChargeCd: dataList2.sect1TransportChargeCd,
      sect1TransportChargeName: dataList2.sect1TransportChargeName,
      sect1TransportMethodId: dataList2.sect1TransportMethodId,
      sect1TransportMethodCd: dataList2.sect1TransportMethodCd,
      sect1TransportMethodName: dataList2.sect1TransportMethodName,
      sect1CarNo: dataList2.sect1CarNo,
      destination1Id: dataList2.destination1Id,
      destination1Cd: dataList2.destination1Cd,
      destination1Name: dataList2.destination1Name,
      destination1ChargeId: dataList2.destination1ChargeId,
      destination1ChargeCd: dataList2.destination1ChargeCd,
      destination1ChargeName: dataList2.destination1ChargeName,
      sect2TransportId: dataList2.sect2TransportId,
      sect2TransportCd: dataList2.sect2TransportCd,
      sect2TransportName: dataList2.sect2TransportName,
      sect2TransportChargeId: dataList2.sect2TransportChargeId,
      sect2TransportChargeCd: dataList2.sect2TransportChargeCd,
      sect2TransportChargeName: dataList2.sect2TransportChargeName,
      sect2TransportMethodId: dataList2.sect2TransportMethodId,
      sect2TransportMethodCd: dataList2.sect2TransportMethodCd,
      sect2TransportMethodName: dataList2.sect2TransportMethodName,
      sect2CarNo: dataList2.sect2CarNo,
      destination2Id: dataList2.destination2Id,
      destination2Cd: dataList2.destination2Cd,
      destination2Name: dataList2.destination2Name,
      destination2ChargeId: dataList2.destination2ChargeId,
      destination2ChargeCd: dataList2.destination2ChargeCd,
      destination2ChargeName: dataList2.destination2ChargeName,
      sect3TransportId: dataList2.sect3TransportId,
      sect3TransportCd: dataList2.sect3TransportCd,
      sect3TransportName: dataList2.sect3TransportName,
      sect3TransportChargeId: dataList2.sect3TransportChargeId,
      sect3TransportChargeCd: dataList2.sect3TransportChargeCd,
      sect3TransportChargeName: dataList2.sect3TransportChargeName,
      sect3TransportMethodId: dataList2.sect3TransportMethodId,
      sect3TransportMethodCd: dataList2.sect3TransportMethodCd,
      sect3TransportMethodName: dataList2.sect3TransportMethodName,
      sect3CarNo: dataList2.sect3CarNo,
      destination3Id: dataList2.destination3Id,
      destination3Cd: dataList2.destination3Cd,
      destination3Name: dataList2.destination3Name,
      destination3ChargeId: dataList2.destination3ChargeId,
      destination3ChargeCd: dataList2.destination3ChargeCd,
      destination3ChargeName: dataList2.destination3ChargeName,
      sect4TransportId: dataList2.sect4TransportId,
      sect4TransportCd: dataList2.sect4TransportCd,
      sect4TransportName: dataList2.sect4TransportName,
      sect4TransportChargeId: dataList2.sect4TransportChargeId,
      sect4TransportChargeCd: dataList2.sect4TransportChargeCd,
      sect4TransportChargeName: dataList2.sect4TransportChargeName,
      sect4TransportMethodId: dataList2.sect4TransportMethodId,
      sect4TransportMethodCd: dataList2.sect4TransportMethodCd,
      sect4TransportMethodName: dataList2.sect4TransportMethodName,
      sect4CarNo: dataList2.sect4CarNo,
      destination4Id: dataList2.destination4Id,
      destination4Cd: dataList2.destination4Cd,
      destination4Name: dataList2.destination4Name,
      destination4ChargeId: dataList2.destination4ChargeId,
      destination4ChargeCd: dataList2.destination4ChargeCd,
      destination4ChargeName: dataList2.destination4ChargeName,
      sect5TransportId: dataList2.sect5TransportId,
      sect5TransportCd: dataList2.sect5TransportCd,
      sect5TransportName: dataList2.sect5TransportName,
      sect5TransportChargeId: dataList2.sect5TransportChargeId,
      sect5TransportChargeCd: dataList2.sect5TransportChargeCd,
      sect5TransportChargeName: dataList2.sect5TransportChargeName,
      sect5TransportMethodId: dataList2.sect5TransportMethodId,
      sect5TransportMethodCd: dataList2.sect5TransportMethodCd,
      sect5TransportMethodName: dataList2.sect5TransportMethodName,
      sect5CarNo: dataList2.sect5CarNo,
      destination5Id: dataList2.destination5Id,
      destination5Cd: dataList2.destination5Cd,
      destination5Name: dataList2.destination5Name,
      destination5ChargeId: dataList2.destination5ChargeId,
      destination5ChargeCd: dataList2.destination5ChargeCd,
      destination5ChargeName: dataList2.destination5ChargeName,






      lastProcessingTraderId: dataList2.lastProcessingTraderId,
      lastProcessingTraderCd: dataList2.lastProcessingTraderCd,
      lastProcessingTraderName: dataList2.lastProcessingTraderName,
      lastProcessingChargeId: dataList2.lastProcessingChargeId,
      lastProcessingChargeCd: dataList2.lastProcessingChargeCd,
      lastProcessingTraderChargeName: dataList2.lastProcessingTraderChargeName,



      wasteCd: dataList2.wasteCd,
//      wasteName: dataList2.wasteName,
      wasteKindName: dataList2.wasteKindName,
      wasteName: dataList2.wasteName,

      disposalMethodCd: dataList2.disposalMethodCd,
      disposalMethodName: dataList2.disposalMethodName,






//        amount: dataList2.amount,
      amount1: dataList2.amount1,
      amount2: dataList2.amount2,
      amount3: dataList2.amount3,
      amount4: dataList2.amount4,
      amount5: dataList2.amount5,
      packingAmount1: dataList2.packingAmount1,

      unitCd: dataList2.unitCd,
      unitName: dataList2.unitName,

      packingCd: dataList2.packingCd,
      packingName: dataList2.packingName,
//        packingAmount: dataList2.packingAmount,
      packingAmount1: dataList2.packingAmount1,






      memo: dataList2.memo,
      memo1: dataList2.memo1,
      memo2: dataList2.memo2,
      memo3: dataList2.memo3,
      memo4: dataList2.memo4,
      memo5: dataList2.memo5,
      toxicCd01: dataList2.toxicCd01,
      toxicCd02: dataList2.toxicCd02,
      toxicCd03: dataList2.toxicCd03,
      toxicCd04: dataList2.toxicCd04,
      toxicCd05: dataList2.toxicCd05,
      toxicCd06: dataList2.toxicCd06,
      toxicName01: dataList2.toxicName01,
      toxicName02: dataList2.toxicName02,
      toxicName03: dataList2.toxicName03,
      toxicName04: dataList2.toxicName04,
      toxicName05: dataList2.toxicName05,
      toxicName06: dataList2.toxicName06,

      status: dataList2.status,
      statusName: dataList2.statusName,
      sect1At: dataList2.sect1At,
      destination1At: dataList2.destination1At,
      sect2At: dataList2.sect2At,
      destination2At: dataList2.destination2At,
      sect3At: dataList2.sect3At,
      destination3At: dataList2.destination3At,
      sect4At: dataList2.sect4At,
      destination4At: dataList2.destination4At,
      sect5At: dataList2.sect5At,
      destination5At: dataList2.destination5At,
      middleStart: dataList2.middleStart,
      middleEnd: dataList2.middleEnd,
      lastProcessingStart: dataList2.lastProcessingStart,
      lastProcessingEnd: dataList2.lastProcessingEnd,

      ediSendFlg: dataList2.ediSendFlg,






      reserveAuthorityCd: dataList2.reserveAuthorityCd,
      lastReportFlg: dataList2.lastReportFlg,
      lastReportName: dataList2.lastReportName,
      middleProcessingInfoFlg: dataList2.middleProcessingInfoFlg,


      lastProcessingCnt: dataList2.lastProcessingCnt,




      // 0901-E
          });
    } catch (e) {
      console.log(e);
    } finally {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
/*

//    let str = "あいうえお";

    // 荷姿数量チェック
    if (value.packingAmount1 > 99999) {
      alert('荷姿数量が５桁を超えます <' + value.packingAmount1 + '>')
//      setErrorEmail1(true);
    } else {
      if (value.packingAmount1 < 0) {
        alert('荷姿数量がマイナスです <' + value.packingAmount1 + '>')
  //      setErrorEmail1(true);
      }
    }

    // 数量チェック
//    if (length(value.amount1) > 9 || value.amount1 > 99999.999) {
    if (value.amount1 > 99999.999) {
        alert('数量が５桁を超えます <' + value.packingAmount1 + '>')
//      setErrorEmail1(true);
//    } else {
    }
*/


setValue({
  ...value,
  [e.target.name]: e.target.value,
});
};
// console.log('setvalue',value);

let selRows = React.useRef([]);
const [pageSize, setPageSize] = React.useState(10);

const cols = [
  { field: 'selectName', headerName: '選択', width: 0 },
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'secondFlg', headerName: '2次フラグ', width: 0 },
  { field: 'selectFlg', headerName: '選択フラグ', width: 50 },
  { field: 'selectTag', headerName: '選択タグ', width: 100 },
  { field: 'selectId', headerName: '選択ID', width: 60 },
  { field: 'statusName', headerName: '状態', width: 100 },
  { field: 'exhaustDate', headerName: '排出日', type: 'date', width: 100 },
  { field: 'garbageTag', headerName: 'ゴミタグ', width: 200 },
  { field: 'manifestNo', headerName: 'マニフェストＮｏ', width: 150 },
  { field: 'exhaustName', headerName: '排出事業場', width: 200 },
  { field: 'wasteName', headerName: '廃棄物種類', width: 200 },
  { field: 'amount1', headerName: '数量', width: 100 },
  { field: 'unitName', headerName: '単位', width: 100 },
  { field: 'lastProcessingTraderName', headerName: '最終処分場', width: 200 }
]

  const handleCancel = () => {
    doClose();
  };

  const handleSubmit = () => {
    if (selRows.current.length) {
      if (window.confirm(' ２次マニフェスト「' + value.garbageTag + ':' + value.manifestNo + '」と選択1次マニフェストを更新します。よろしいですか？')) {
        handle1st();
        handle2nd();
      }
      }else{
        if (window.confirm(' ２次マニフェスト「' + value.garbageTag + ':' + value.manifestNo + '」を更新します。よろしいですか？')) {
//          handle1st();
          handle2nd();
        }
        }
  };

  const handle1st = async (e) => {
    // console.log('dataList3',dataList3,dataList3[0].selectId)
    //  更新時点で選択されていたものを非選択にする
    //  A,B,C,D,Eから選択をA,C,EからA,D,Eにする場合、ここでA,C,Eを未選択に戻した後、
    //  A,D,Eを後続で選択状態に更新する

      // チェック変更（新たに☑されたもの）がなければ、非選択に戻す必要なし
      if (selRows.current.length) {
        for (let i = 0; i<dataList3.length; i++){
          if (dataList3[i].selectId == dataList2.id) {
    //         // console.log(i,dataList3[i].selectId)
//               alert('№.' + i + '<' + dataList3[i].selectId + '>');

             const paramsCancel = {
              secondFlg: '0',
              selectFlg: '0',
              selectTag: '',
              selectId: 0,
             }

             const resCancel = await updateEgsTGarbageEdi(dataList3[i].id,paramsCancel);
             // console.log("updateEgsTGarbageEdi_cancel",resCancel);

          }
        }
      }


    const params = generateParams();
    try {

//        const res = await createEgsTGarbageEdi(params);
      const res = await updateEgsTGarbageEdi(dataList2.id,params);
//        // console.log("createEgsTGarbageEdi_res",res);
      // console.log("updateEgsTGarbageEdi_res",res);
      // console.log("params",params);

      const firstparams = {
        // id: dataList2.id,
        secondFlg: '0',
        selectFlg: '1',
        selectId: res.data.id,
        selectTag: res.data.garbageTag,
      }

      // console.log("selRows",selRows);
      if (selRows.current.length > 0){
        for (let i = 0; i<selRows.current.length; i++){
          // ここに選択した１次マニフェストの登録処理
          // console.log('selRows.current[i]',selRows.current[i]);
          // console.log("firstparams",firstparams);
          try {
            const res = await updateEgsTGarbageEdi(selRows.current[i], firstparams);
            // console.log("updateEgsTGarbageEdi_res",res);
          } catch (e) {
            console.log(e);
          }
        }
      }

      handleCancel();
//          history.push('/garbage_edi');
        window.location.reload();
    } catch (e) {
      console.log(e);
    }
};

  const handle2nd = async (e) => {
//????    e.preventDefault();
    // 追加
    const paramsSect1 = generateParamsSect1();
    // console.log(paramsSect1);
    const params = generateParams();
    // console.log(params);
{/*}
    try {
      const res = await updateEgsTGarbageEdi(ediId, params);
      // console.log(res);
      doClose();
      // egs_m_reserve_sets edi_d01
    } catch (e) {
      console.log(e);
    }
*/}

//    if (window.confirm(' ２次マニフェスト「' + value.garbageTag + ':' + value.manifestNo + '」を最終処分終了報告します。よろしいですか？')) {
      try {
       setOpen(!open);
        const res = await updateEgsTGarbageEdi(ediId, params);
        // console.log(res);

/*
        if ( (value.manifestNo == '') || value.manifestNo === '999' ){
          //      alert('マニフェスト番号未取得',value.manifestNo)
              }else{
          //      alert('マニフェスト番号取得済',value.manifestNo)
                if ((value.exhaustDate            !== dataList2.exhaustDate)            ||
                    (value.amount1                !== dataList2.amount1)                ||
                    (value.packingAmount1         !== dataList2.packingAmount1)         ||
                    (value.disposalMethodName     !== dataList2.disposalMethodName)     ||
                    (value.sect1TransportChargeId !== dataList2.sect1TransportChargeId) ||
                    (value.sect1TransportMethodCd !== dataList2.sect1TransportMethodCd) ||
                    (value.sect1CarNo             !== dataList2.sect1CarNo)             ||
                    (value.destination1ChargeId   !== dataList2.Destination1ChargeId)   ||
                    (value.memo1                  !== dataList2.memo1)                  )
                {
//                  alert('数量１変更' + dataList2.amount1 + '⇒' + value.amount1 )
                   if (window.confirm(' 区間１承認待ちデータを作成します「' + dataList2.amount1 + '⇒' + value.amount1 + '」を更新します。よろしいですか？')) {
                      try {
//                      const resApproval = await createEgsApprovalWaitList(paramsSect1);
                      const resApproval = await createEgsTApproveWait(paramsSect1);
                      // console.log(resApproval);
                    } catch (e) {
                      console.log(e);
                    }
                  }
                }
              }
*/

//        doClose();
//        history.push('/egs_m_trader_adm');
       setOpen(false);
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
//    }

  };

  // パラメータのオブジェクト構造を加工
  const generateParams = () => {
    const params = {
      garbageTag: value.garbageTag,
      processingSeqno: value.processingSeqno,
      manifestNo: value.manifestNo,
      arrangementNo: value.arrangementNo,
      exhaustDate: value.exhaustDate,
      exhaustId: value.exhaustId,
      exhaustCd: value.exhaustCd,
      exhaustName: value.exhaustName,
      exhaustChargeId: value.exhaustChargeId,
      exhaustChargeCd: value.exhaustChargeCd,
      exhaustChargeName: value.exhaustChargeName,
      sect1TransportId: value.sect1TransportId,
      sect1TransportCd: value.sect1TransportCd,
      sect1TransportName: value.sect1TransportName,
      sect1TransportChargeId: value.sect1TransportChargeId,
      sect1TransportChargeCd: value.sect1TransportChargeCd,
      sect1TransportChargeName: value.sect1TransportChargeName,
      sect1TransportMethodId: value.sect1TransportMethodId,
      sect1TransportMethodCd: value.sect1TransportMethodCd,
      sect1TransportMethodName: value.sect1TransportMethodName,
      sect1CarNo: value.sect1CarNo,
      destination1Id: value.destination1Id,
      destination1Cd: value.destination1Cd,
      destination1Name: value.destination1Name,
      destination1ChargeId: value.destination1ChargeId,
      destination1ChargeCd: value.destination1ChargeCd,
      destination1ChargeName: value.destination1ChargeName,
      sect2TransportId: value.sect2TransportId,
      sect2TransportCd: value.sect2TransportCd,
      sect2TransportName: value.sect2TransportName,
      sect2TransportChargeId: value.sect2TransportChargeId,
      sect2TransportChargeCd: value.sect2TransportChargeCd,
      sect2TransportChargeName: value.sect2TransportChargeName,
      sect2TransportMethodId: value.sect2TransportMethodId,
      sect2TransportMethodCd: value.sect2TransportMethodCd,
      sect2TransportMethodName: value.sect2TransportMethodName,
      sect2CarNo: value.sect2CarNo,
      destination2Id: value.destination2Id,
      destination2Cd: value.destination2Cd,
      destination2Name: value.destination2Name,
      destination2ChargeId: value.destination2ChargeId,
      destination2ChargeCd: value.destination2ChargeCd,
      destination2ChargeName: value.destination2ChargeName,
      sect3TransportId: value.sect3TransportId,
      sect3TransportCd: value.sect3TransportCd,
      sect3TransportName: value.sect3TransportName,
      sect3TransportChargeId: value.sect3TransportChargeId,
      sect3TransportChargeCd: value.sect3TransportChargeCd,
      sect3TransportChargeName: value.sect3TransportChargeName,
      sect3TransportMethodId: value.sect3TransportMethodId,
      sect3TransportMethodCd: value.sect3TransportMethodCd,
      sect3TransportMethodName: value.sect3TransportMethodName,
      sect3CarNo: value.sect3CarNo,
      destination3Id: value.destination3Id,
      destination3Cd: value.destination3Cd,
      destination3Name: value.destination3Name,
      destination3ChargeId: value.destination3ChargeId,
      destination3ChargeCd: value.destination3ChargeCd,
      destination3ChargeName: value.destination3ChargeName,
      sect4TransportId: value.sect4TransportId,
      sect4TransportCd: value.sect4TransportCd,
      sect4TransportName: value.sect4TransportName,
      sect4TransportChargeId: value.sect4TransportChargeId,
      sect4TransportChargeCd: value.sect4TransportChargeCd,
      sect4TransportChargeName: value.sect4TransportChargeName,
      sect4TransportMethodId: value.sect4TransportMethodId,
      sect4TransportMethodCd: value.sect4TransportMethodCd,
      sect4TransportMethodName: value.sect4TransportMethodName,
      sect4CarNo: value.sect4CarNo,
      destination4Id: value.destination4Id,
      destination4Cd: value.destination4Cd,
      destination4Name: value.destination4Name,
      destination4ChargeId: value.destination4ChargeId,
      destination4ChargeCd: value.destination4ChargeCd,
      destination4ChargeName: value.destination4ChargeName,
      sect5TransportId: value.sect5TransportId,
      sect5TransportCd: value.sect5TransportCd,
      sect5TransportName: value.sect5TransportName,
      sect5TransportChargeId: value.sect5TransportChargeId,
      sect5TransportChargeCd: value.sect5TransportChargeCd,
      sect5TransportChargeName: value.sect5TransportChargeName,
      sect5TransportMethodId: value.sect5TransportMethodId,
      sect5TransportMethodCd: value.sect5TransportMethodCd,
      sect5TransportMethodName: value.sect5TransportMethodName,
      sect5CarNo: value.sect5CarNo,
      destination5Id: value.destination5Id,
      destination5Cd: value.destination5Cd,
      destination5Name: value.destination5Name,
      destination5ChargeId: value.destination5ChargeId,
      destination5ChargeCd: value.destination5ChargeCd,
      destination5ChargeName: value.destination5ChargeName,






      lastProcessingTraderId: value.lastProcessingTraderId,
      lastProcessingTraderCd: value.lastProcessingTraderCd,
      lastProcessingTraderName: value.lastProcessingTraderName,
      lastProcessingChargeId: value.lastProcessingChargeId,
      lastProcessingChargeCd: value.lastProcessingChargeCd,
      lastProcessingTraderChargeName: value.lastProcessingTraderChargeName,



      wasteCd: value.wasteCd,
      wasteName: value.wasteName,

      disposalMethodCd: value.disposalMethodCd,
      disposalMethodName: value.disposalMethodName,






//      amount: value.amount,
      amount1: value.amount1,
      amount2: value.amount2,
      amount3: value.amount3,
      amount4: value.amount4,
      amount5: value.amount5,
      packingAmount1: value.packingAmount1,

      unitCd: value.unitCd,
      unitName: value.unitName,

      packingCd: value.packingCd,
      packingName: value.packingName,
//      packingAmount: value.packingAmount,
      packingAmount1: value.packingAmount1,






      memo: value.memo,
      memo1: value.memo1,
      memo2: value.memo2,
      memo3: value.memo3,
      memo4: value.memo4,
      memo5: value.memo5,
      toxicCd01: value.toxicCd01,
      toxicCd02: value.toxicCd02,
      toxicCd03: value.toxicCd03,
      toxicCd04: value.toxicCd04,
      toxicCd05: value.toxicCd05,
      toxicCd06: value.toxicCd06,
      toxicName01: value.toxicName01,
      toxicName02: value.toxicName02,
      toxicName03: value.toxicName03,
      toxicName04: value.toxicName04,
      toxicName05: value.toxicName05,
      toxicName06: value.toxicName06,

//      status: value.status,
      status: '71',
//      statusName: value.statusName,
      statusName: '最終処分終了済',

      sect1At: value.sect1At,
      destination1At: value.destination1At,
      sect2At: value.sect2At,
      destination2At: value.destination2At,
      sect3At: value.sect3At,
      destination3At: value.destination3At,
      sect4At: value.sect4At,
      destination4At: value.destination4At,
      sect5At: value.sect5At,
      destination5At: value.destination5At,
      middleStart: value.middleStart,
      middleEnd: value.middleEnd,
      lastProcessingStart: value.lastProcessingStart,
      lastProcessingEnd: value.lastProcessingEnd,

//      ediSendFlg: value.ediSendFlg,
      ediSendFlg: '71',    // EDI送信状態フラグ：最終処分終了報告作成前






      reserveAuthorityCd: value.reserveAuthorityCd,
      lastReportFlg: value.lastReportFlg,
      middleProcessingInfoFlg: value.middleProcessingInfoFlg,


      lastProcessingCnt: value.lastProcessingCnt,

    };
    return params;
  };

  // パラメータのオブジェクト構造を加工
  const generateParamsSect1 = () => {
    const paramsSect1 = {
      egsTGarbageEdiId: dataList2.id,
      manifestNo: value.manifestNo,
      kukan: '1',  // value.kukan,
      infoNo: '203',  // value.infoNo,
      ediCompareItemNameId: 31,  // 数量１のカラム位置は31で良いのか不明,
      oldExhaustDate: dataList2.exhaustDate,
      newExhaustDate: value.exhaustDate,
      oldAmount1: dataList2.amount1,
      newAmount1: value.amount1,
      oldPackingAmount1: dataList2.packingAmount1,
      newPackingAmount1: value.packingAmount1,
      oldDisposalMethodCd: dataList2.disposalMethodCd,
      newDisposalMethodCd: value.disposalMethodCd,
      oldTransportChargeId: dataList2.sect1TransportChargeId,
      newTransportChargeId: value.sect1TransportChargeId,
      oldTransportMethodCd: dataList2.sect1TransportMethodCd,
      newTransportMethodCd: value.sect1TransportMethodCd,
      oldCarNo: dataList2.sect1CarNo,
      newCarNo: value.sect1CarNo,
      oldDestinationChargeId: dataList2.destination1ChargeId,
      newDestinationChargeId: value.destination1ChargeId,
      oldMemo: dataList2.memo1,
      newMemo: value.memo1,

      oldEdi3100R25ItemId: '',  // ここでは設定不要
      newEdi3100R25ItemId: '',  // ここでは設定不要,
      egsMTraderId: value.exhaustId,
      authFlg: '1',  // value.authFlg,
    };
    return paramsSect1;
  };

/*    必要？-S

  // const qrCodeUrl = "https://www.sdgs12.com/rsv/" + value.reserveNo + "/" + value.reserveSubno ;
  const qrCodeUrl = "https://www.sdgs12.com/rsv/" + query.id ;
  // console.log('qrCodeUrl',qrCodeUrl);

      必要？-E
*/

  return (




    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>最終処分終了報告</h2>
        <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
          e.preventDefault();
          // history.push('/garbage_edi');
//          props.handleCancel();
          handleCancel();
        }}>    {/*  egs_m_reserve_sets  */}
          戻る
        </Button>
      </div>
      </DialogTitle>

      <DialogContent>

      <form className={classes.root}>

        <h3>【一次マニフェスト】</h3>
        <Grid
          container
          spacing={2}
        >
        </Grid>

        <div className={classes.container2}>
         <Box sx={{ height: 300, width: '100%' }}>  {/*520*/} {/*120*/}
          <DataGridPro
//            pageSize={pageSize}
//            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//            rowsPerPageOptions={[10, 20, 50]}
//            pagination
            sx={styles.grid}
            columns={cols}
            rows={dataList3}  // filteredDataList
            density="compact"
//            autoHeight
//0105            checkboxSelection
//0105            disableSelectionOnClick
//0105            onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
         </Box>
        </div>
{/*}
        <h3>　↑ 対象の一次マニフェストを選択（ㇾ）して下さい。※　前回選択済は選択欄：☑</h3>

          <SpaceRow height={100} />
*/}

      </form>
      </DialogContent>


      <DialogContent>
        <FormBody
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          value={value}
          buttonType='更新' />

        <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
///////////}
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default LastDisposalEdit;
/*    必要？

      <h2>ＱＲコード</h2>
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>

*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
