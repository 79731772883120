// /src/lib/api/egs_m_permit.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getEgsMPermitList = () => {
  return client.get('/egs_m_permits');
};

// 詳細
export const getEgsMPermitDetail = (id) => {
  return client.get(`/egs_m_permits/${id}`);
};

// 新規作成
// header情報を追加
export const createEgsMPermit = (params) => {
  return client.post('/egs_m_permits', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateEgsMPermit = (id, params) => {
  return client.patch(`/egs_m_permits/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteEgsMPermit = (id) => {
  return client.delete(`/egs_m_permits/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
