import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import QRCode from "qrcode.react"

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Container from '@material-ui/core/Container';
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  jaJP
} from '@mui/x-data-grid-pro'

const PaperComponent = ({...props}) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable
      handle="#form-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={nodeRef}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function GarbageEdiQrCreate(props) {
  const { isOpen, doClose, dataList2 } = props;
  const classes = useStyles();
  // const [unixDate, setUnixDate] = useState([]);
  // let d = new Date();
  // setUnixDate(d.valueOf());
  // // console.log('unixDate',unixDate);
  //var qrCodeUrl = [];
  //for (let i = 0; i < 9; i++) {
    // let d = new Date();
    // const qrCodeUrl = "https://www.sdgs12.com/waste/" + dataList2.id + "-" + d.valueOf();
  //  // console.log('garbageTagurl', garbageTagurl);
    // qrCodeUrl.push("https://www.sdgs12.com/waste/");
  // }

  const handleCancel = () => {
    doClose();
  };
  const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://www.ecotechl.com/">
          ecotechl.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  function EdiQrcode(props) {
    const { card } = props;
    // // console.log("card",card)
    // const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));
    // (async () => {
    //   // console.log('スタート');
    //   await sleep(1000);
    //   // console.log('1秒経ってる!')
    // })();
    let d = new Date();
    let sqtime = d.valueOf() + card;
    const qrCodeUrl = "https://www.sdgs12.com/waste/" + dataList2.id + "-" + sqtime ;

    // const handleSleep = async (e) => {
    //   const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));
    //     try {
    //       const res = await sleep(10);
    //       // console.log("res",res)
    //        } catch (e) {
    //       console.log(e);
    //     }
    // };
    // handleSleep();

    // console.log('qrCodeUrl', qrCodeUrl);
    return (
      <Typography variant="body2" color="textSecondary" align="center">
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>
      <h3>{dataList2.sect1TransportName}</h3>
      </Typography>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="form-dialog-title"
      maxWidth={"xl"}
      PaperComponent={PaperComponent}
    >
      <DialogTitle id="form-dialog-title">
        <h2>貼付用ＱＲコード</h2>
      </DialogTitle>
      <div style={{ textAlign: 'right' }}>
        <Button variant='contained' onClick={e => {
            e.preventDefault();
            handleCancel();
          }}>    {/*  egs_m_reserve_sets  */}
            戻る
        </Button>
      </div>
      <DialogContent>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>

                  {/* <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  /> */}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {dataList2.exhaustName}
                    </Typography>
                    <Typography>
                      {dataList2.reserveNo}<br />
                      {dataList2.reserveName}<br />
                    </Typography>
                    {/* <QRCode value={qrCodeUrl} />
                      <p>{qrCodeUrl}</p>
                      <h3>{dataList2.sect1TransportName}</h3> */}
                    <EdiQrcode card={card} />
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Copyright />
      </DialogContent>
    </Dialog>
  );
}const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default GarbageEdiQrCreate;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
