// /src/lib/api/demand_b_cnt_old.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getDemandBCntOldList = () => {
  return client.get('/demand_b_cnt_olds');
};

// 詳細
export const getDemandBCntOldDetail = (id) => {
  return client.get(`/demand_b_cnt_olds/${id}`);
};

// 月指定
export const getDemandBCntOldMonth = (id) => {
  return client.get(`/demand_b_cnt_olds/${id}/showmonth`);
};

