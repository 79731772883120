// /src/lib/api/charge.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getChargeList = () => {
  return client.get('/charges');
};

// 事業所指定一覧 idは事業所CD
export const getChargeUser = (id) => {
  return client.get(`/charges/${id}/showuser`);
};

// 事業所指定一覧 idは事業所CD で有効フラグ='1'
export const getChargeEffective = (id) => {
  return client.get(`/charges/${id}/showEffective`);
};

// 担当者指定一覧 idはグループ
export const getChargeGroup = (id) => {
  return client.get(`/charges/${id}/showgroup`);
};

// 担当者指定一覧 idはグループ１
export const getChargeGroup1 = (id) => {
  return client.get(`/charges/${id}/showgroup1`);
};

// 担当者指定一覧 idはグループ２
export const getChargeGroup2 = (id) => {
  return client.get(`/charges/${id}/showgroup2`);
};

// 詳細
export const getChargeDetail = (id) => {
  return client.get(`/charges/${id}`);
};

// 検索条件 idは'all'
export const getChargeOperation = (id) => {
  return client.get(`/charges/${id}/showope`);
};
// 検索条件 idは'trader_cd'-'charge_cd'
export const getChargeLoginUser = (id) => {
  return client.get(`/charges/${id}/loginuser`);
};
// 新規作成
// header情報を追加
export const createCharge = (params) => {
  return client.post('/charges', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateCharge = (id, params) => {
  return client.patch(`/charges/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteCharge = (id) => {
  return client.delete(`/charges/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
