// /src/lib/api/trans_list.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getTransList = () => {
  return client.get('/trans_lists');
};

// 詳細
export const getTransDetail = (id) => {
  return client.get(`/trans_lists/${id}`);
};

// 新規作成
// header情報を追加
export const createTrans = (params) => {
  return client.post('/trans_lists', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateTrans = (id, params) => {
  return client.patch(`/trans_lists/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteTrans = (id) => {
  return client.delete(`/trans_lists/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
