import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  jaJP
} from '@mui/x-data-grid-pro'

import './app.css';
import { prefectureName } from '../../constants'
import SpaceRow from '../commons/SpaceRow';

import EgsMCompanyEdit from "../egs_m_companies/EgsMCompanyEdit";
import EgsMCompanyNew from "../egs_m_companies/EgsMCompanyNew";    // 新規作成
import EgsMTraderNew from "../egs_m_companies/EgsMTraderNew";    // 事業場新規作成
//import EgsMCompanyAdd from "../egs_m_companies/EgsMCompanyAdd";    // 新規作成（複写）
import EgsMCompanyListTableForm from "../egs_m_companies/EgsMCompanyListTableForm";
import { getCompanyOperation  } from '../../lib/api/company';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
          csvOptions={{
          fileName: '企業',
          utf8WithBom: true,
        }}
       />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
//   fontWeight: {
//     fontWeight: 900,
//   },
// });
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 100,  // 650
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',    // 150ch
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

// const grid1 = React.createRef();
const rowDetailRef = React.createRef();
const grid = React.createRef();

function EgsMCompanyListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
//  console.log('currentUser',currentUser);

  const [open2, setOpen2] = React.useState(false);

  const [dataList2, setDataList2] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);    // 新規作成
  const [addOpen, setAddOpen] = useState(false);    // 新規作成（複写）
  const [traderCreOpen, setTraderCreOpen] = useState(false);    // 事業場新規作成

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [filter, setFilter] = React.useState({
//    exhaustDateStart: '',
//    exhaustDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
    companyName: '',
//    statusName: '',
    companyCd: '',
    sect1TransportName: ''
  })
  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
/*    
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
*/
//    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.companyCd+"_"+filter.companyName+"_"+filter.sect1TransportName;
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.companyCd+"_"+filter.companyName+"_";
    console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getCompanyOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };

  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    console.log("dataList2",dataList2);
  };
  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };
  const handleAddOpen = () => {
    setAddOpen(true);
  };

  const handleTraderCreOpen = () => {
    setTraderCreOpen(true);
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'jwnetNo',
      headerName: '加入者番号',
      width: 120
    },
    {
      field: 'companyCd',
      headerName: '企業CD',
      width: 120
    },
    {
      field: 'traderKbnName',
      headerName: '事業者区分',
      width: 120
    },
    {
      field: 'companyName',
      headerName: '企業名',
      width: 300
    },
    {
      field: 'zip',
      headerName: '郵便番号',
      width: 100
    },
    {
      field: 'prefectureName',
      headerName: '都道府県',
      width: 100
    },
    {
      field: 'address',
      headerName: '住所（市区町村）',
      width: 150
    },
    {
      field: 'address2',
      headerName: '住所（町域）',
      width: 200
    },
    {
      field: 'address3',
      headerName: '住所（丁目・番地以降）',
      width: 200
    },
    {
      field: 'phone',
      headerName: '連絡先電話番号',
      width: 150
    },
    {
      field: 'email1',
      headerName: 'メールアドレス',
      width: 200
    }
    // {
    //   field: 'chargeDivisionName',
    //   headerName: '料金区分',
    //   width: 80
    // },
    // {
    //   field: 'email1',
    //   headerName: 'メールアドレス',
    //   width: 200
    // },
    // {
    //   field: 'email1Flg',
    //   headerName: '有効',
    //   width: 100
    // },
    // {
    //   field: 'exhaustPasswd',
    //   headerName: '企業パスワード',
    //   width: 150
    // },
    // {
    //   field: 'rawpasswd',
    //   headerName: '生パスワード',
    //   width: 150
    // }
  ]

  const handleOpen = () => {
    setDetailOpen(true);
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  console.log("selRows",selRows);
  const [pageSize, setPageSize] = React.useState(10);

  // 行の削除
  const delRows = () => {
    console.log('delRows',dataList2)
    handleDelete(dataList2);
  }
  //企業区分はシステム管理者（'1111111111'）？
  if (currentUser.traderKbn == '1111111111'){
    return (
      <>
      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　企業CD：</Typography>
            <TextField id="companyCd" value={filter.companyCd} onChange={e => setFilter({ ...filter, companyCd: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　企業名：</Typography>
            <TextField id="companyName" value={filter.companyName} onChange={e => setFilter({ ...filter, companyName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={filteredDataList}
                density="compact"
                components={{
                  Toolbar: CustomToolbar,　// カスタムツールバーを指定する
                }}
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <EgsMCompanyEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            processId={dataList2.id}
            dataList2={dataList2}
          />
          
        <EgsMCompanyNew    // 新規作成
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
//            processId={dataList2.id}
          />
{/*}
        <EgsMCompanyAdd    // 新規作成（複写）
            isOpen={addOpen}
            doClose={() => setAddOpen(false)}
            processId={dataList2.id}
          />
*/}

        <EgsMTraderNew    // 事業場新規作成
            isOpen={traderCreOpen}
            doClose={() => setTraderCreOpen(false)}
            processId={dataList2.id}
            dataList2={dataList2}
          />

          <EgsMCompanyListTableForm
            dataList2={dataList2}
          />
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleCreOpen}>
              新規作成
          </Button>
{/*}          <Button variant="contained" color="primary" style={{ marginRight: 400 }} onClick={handleAddOpen} disabled={!dataList2.id}>
              新規作成（複写）
          </Button> */}
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: 50 }} onClick={handleTraderCreOpen} disabled={!dataList2.id}>
            事業場新規作成
          </Button>
        </Box>
        </BrowserView>

      </>
    );
  }else{                                       //企業区分は管理者以外？

  //企業区分はグループ管理者（'0000000001'）？
  if (currentUser.traderKbn == '0000000001'){
    return (
      <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　企業CD：</Typography>
            <TextField id="companyCd" value={filter.companyCd} onChange={e => setFilter({ ...filter, companyCd: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　企業名：</Typography>
            <TextField id="companyName" value={filter.companyName} onChange={e => setFilter({ ...filter, companyName: e.target.value })} label="" type={"text"} className={classes.textField} InputLabelProps={{ shrink: true, }} />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
                sx={styles.grid}
                columns={cols}
                rows={filteredDataList}  // dataList
                density="compact"
                components={{
                  Toolbar: CustomToolbar,　// カスタムツールバーを指定する
                }}
                showColumnRightBorder // 列ヘッダセルの右側に線を引く
                showCellRightBorder   // セルの右側に線を引く
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <EgsMCompanyEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            processId={dataList2.id}
          />
          
        <EgsMCompanyNew    // 新規作成
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
//            processId={dataList2.id}
          />
{/*}
        <EgsMCompanyAdd    // 新規作成（複写）
            isOpen={addOpen}
            doClose={() => setAddOpen(false)}
            processId={dataList2.id}
          />
*/}
          <EgsMCompanyListTableForm
            dataList2={dataList2}
          />

        <EgsMTraderNew    // 事業場新規作成
            isOpen={traderCreOpen}
            doClose={() => setTraderCreOpen(false)}
            processId={dataList2.id}
            dataList2={dataList2}
          />

{/*}
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleCreOpen}>
              新規作成
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
        </Box>
*/}
        </BrowserView>

      </>
    );
  }else{                                       //企業区分は管理者以外？

    return (
      <>
        <EgsMCompanyListTableForm
          dataList2={dataList}
        />
      </>
    );
    }
  }
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
}
export default EgsMCompanyListTable;
