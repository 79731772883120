import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getPasswdList = () => {
  return client.get('/passwds');
};

// 詳細
export const getPasswdDetail = (id) => {
  return client.get(`/passwds/${id}`);
};

