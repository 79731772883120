// components/commons/Header.jsx
import React, { useContext, useState } from 'react';
import { isMobile, BrowserView, MobileView } from "react-device-detect"
import { useHistory, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
// style
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MenuIcon from '@material-ui/icons/Menu';
import { grey } from '@material-ui/core/colors';
// api
import { signOut } from '../../lib/api/auth';
// context
import { AuthContext } from '../../App';
// component
import HeaderDrawer from './HeaderDrawer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    linkBtn: {
      textTransform: 'none',
    },
  })
);

const drawerItemMenu0 = [
  { label: 'Home', path: '/' },
  { label: '例外', path: '/' },
  { label: '企業一覧', path: '/egs_m_companies' },
  { label: '事業場一覧', path: '/egs_m_traders' },
  { label: '事業場一覧新規作成', path: '/egs_m_tradersNew' },
];
const drawerItemMenu1 = [
  { label: '排出Home', path: '/' },
//  { label: '排出imgposts', path: '/imgposts' },
//  { label: '排出数量ドリルダウン', path: '/drilldown' },
//  { label: '連携一覧（不要）', path: '/egs_m_linkage' },
  { label: '処理フロー一覧', path: '/egs_m_process_flows' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
//  { label: '廃棄物状況', path: '/garbage_status' },
  { label: '', path: '' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
  { label: '廃棄物一覧 旧システム　（2018年1月～2023年3月）', path: '/garbage_old' },
];
const drawerItemMenu2 = [
  { label: '収集運搬Home', path: '/' },
  { label: '仮登録状況・予約一覧', path: '/garbage_tmp' },
  { label: '積込登録', path: '/receipt_load' },
  { label: '荷降登録', path: '/receipt_unload' },
  { label: '処理フロー一覧', path: '/egs_m_process_flows' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '加入者登録・変更・解約一覧', path: '/egs_m_entries' },
  { label: '許可証マスタ', path: '/permit' },
  { label: '連携・担当者マスタ', path: '/egs_m_linkage' },
  { label: '', path: '' },
  { label: '担当者作業報告', path: '/sagyo_report' },
  { label: '請求データ表示・出力　　（請求書作成）', path: '/invoice_data' },
  { label: '', path: '' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
  { label: '廃棄物一覧 旧システム　（2018年1月～2023年3月）', path: '/garbage_old' },
];
const drawerItemMenu3 = [
  { label: '積替保管Home', path: '/' },
  { label: '荷受登録', path: '/receipt' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '', path: '' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
];
const drawerItemMenu4 = [
  { label: '中間Home', path: '/' },
  { label: '処理フロー・排出登録', path: '/egs_m_process_flows' },
  { label: '荷降登録', path: '/receipt_unload' },
  { label: '荷受登録', path: '/receipt' },
  { label: '処分登録', path: '/disposal' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
//  { label: '廃棄物詳細', path: '/garbage_detail' },
//  { label: '排出登録', path: '/exhaust_plan' },
//  { label: '企業マスタ', path: '/egs_m_companies' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '許可証マスタ', path: '/permit' },
  { label: '連携・担当者マスタ', path: '/egs_m_linkage' },
];
const drawerItemMenu5 = [
  { label: '最終処分Home', path: '/' },
  { label: '荷受登録', path: '/receipt' },
  { label: '処分登録', path: '/disposal' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
//  { label: '廃棄物詳細', path: '/garbage_detail' },
//  { label: '企業マスタ', path: '/egs_m_companies' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '許可証マスタ', path: '/permit' },
];
const drawerItemMenu47 = [
  { label: '中間処理Home', path: '/' },
//  { label: '連携一覧（不要）', path: '/egs_m_linkage' },
  { label: '荷受登録', path: '/receipt' },
  { label: '処分登録', path: '/disposal' },
  { label: '処理フロー・排出登録', path: '/egs_m_process_flows' },
//  { label: '廃棄物詳細', path: '/garbage_detail' },
//  { label: '排出登録', path: '/exhaust_plan' },
//  { label: '企業マスタ', path: '/egs_m_companies' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '許可証マスタ', path: '/permit' },
  { label: '連携・担当者マスタ', path: '/egs_m_linkage' },
  { label: '', path: '' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
];
const drawerItemMenu8 = [
  { label: 'システム管理者Home', path: '/' },
  { label: '仮登録状況・予約一覧', path: '/garbage_tmp' },
  { label: '処理フロー・排出登録', path: '/egs_m_process_flows' },
  { label: '荷降登録', path: '/receipt_unload' },
  { label: '荷受登録', path: '/receipt' },
  { label: '積込登録', path: '/receipt_load' },
  { label: '処分登録', path: '/disposal' },
  { label: '企業マスタ', path: '/egs_m_companies' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
  { label: '担当者マスタ', path: '/egs_m_charges' },
  { label: '加入者登録・変更・解約一覧', path: '/egs_m_entries' },
  { label: '許可証マスタ', path: '/permit' },
  { label: '連携・担当者マスタ', path: '/egs_m_linkage' },
  { label: '', path: '' },
  { label: '請求データ表示・出力　　（請求書作成）', path: '/invoice_data' },
  { label: '', path: '' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
  { label: '廃棄物一覧 旧システム　（2018年1月～2023年3月）', path: '/garbage_old' },
  //  { label: '排出数量ドリルダウン', path: '/drilldown' },
  { label: '', path: '' },
//  { label: '廃棄物状況', path: '/garbage_status' },
  { label: '排出集計', path: '/exhaust_sum' },
  { label: '運搬業者別積込登録集計一覧', path: '/trans_list' },
  { label: '', path: '' },
  { label: 'EDI通信情報', path: '/adm_edi_info' },
  { label: '承認待ち一覧', path: '/approves' },
];
const drawerItemMenu001 = [
  { label: 'グループ管理Home', path: '/' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
  { label: '廃棄物一覧 旧システム　（2018年1月～2023年3月）', path: '/garbage_old' },
  { label: '企業マスタ一覧', path: '/egs_m_companies' },
//  { label: '事業場マスタ一覧', path: '/egs_m_traders' },
//  { label: '担当者マスタ一覧', path: '/egs_m_charges' },
//  { label: '処理フロー・排出登録', path: '/egs_m_process_flows' },
];
const drawerItemMenu9 = [
  { label: 'システム管理Home', path: '/adm_edi_info' },
  { label: '仮登録状況・予約一覧', path: '/garbage_tmp' },
//  { label: '事業場一覧', path: '/egs_m_traders' },
//  { label: '事業場一覧新規作成', path: '/egs_m_tradersNew' },
  { label: '事業場マスタ', path: '/egs_m_traders' },
//0518  { label: '廃棄物編集一覧', path: '/garbage_info' },
//  { label: '廃棄物実績報告書', path: '/garbage_pdf' },
  { label: '承認待ち一覧', path: '/approves' },
  { label: '企業一覧', path: '/egs_m_companies' },
  { label: '運搬業者別積込登録集計一覧', path: '/trans_list' },
  { label: '連携・担当者マスタ', path: '/egs_m_linkage' },
  { label: '処理フロー・排出登録', path: '/egs_m_process_flows' },
//  { label: '廃棄物一覧', path: '/garbage_edi' },
  { label: '廃棄物一覧 CSV出力用', path: '/garbage_csv' },
//  { label: '旧・廃棄物一覧', path: '/garbage_old' },
  { label: '廃棄物一覧 旧システム　（2018年1月～2023年3月）', path: '/garbage_old' },
//  { label: '廃棄物詳細', path: '/garbage_detail' },
//  { label: '廃棄物データシート', path: '/datasheets' },
//  { label: '許可証一覧', path: '/permit' },
//  { label: '処理フロー一覧', path: '/egs_m_reserve_sets' },
//  { label: '電子ﾏﾆﾌｪｽﾄ送り状', path: '/invoice' },
//  { label: '排出登録', path: '/exhaust_plan' },
//  { label: '荷受登録', path: '/receipt' },
//  { label: '処分登録', path: '/disposal' },
//  { label: 'マスタメンテ', path: '/qrreader' },
//  { label: '担当者一覧', path: '/egs_m_charges' },
//  { label: '許可品目一覧', path: '/egs_m_handlings' },
];

function Header() {
  const { loading, isSignedIn, setIsSignedIn, currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  // console.log("currentUser",currentUser);

  const handleSignOut = async (e) => {
    if (window.confirm('ログアウトします。よろしいですか？')) {
      Cookies.remove('exhaust_telno');
      Cookies.remove('mbl_user');  // 20241129add
    // 20241129del
      // const mbluser = Cookies.get('mbl_user');
      // if (mbluser !== undefined ){
      //   if (window.confirm('スマホ利用者情報を残します。よろしいですか？')) {
      //   }else{
      //     if (window.confirm('スマホ利用者情報をクリアします。よろしいですか？')) {
      //       Cookies.remove('mbl_user');
      //     }
      //   }
      // }
      const sysuser = Cookies.get('sys_user');
      if (sysuser !== undefined ){
          Cookies.remove('sys_user');
      }
      try {
        const res = await signOut();

        if (res.data.success === true) {
          Cookies.remove('_access_token');
          Cookies.remove('_client');
          Cookies.remove('_uid');

          setIsSignedIn(false);
          history.push('/signin');
          // console.log('succeeded in sign out');
        } else {
          // console.log('failed in sign out');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const AuthButtons = () => {
    if (!loading) {
      if (isSignedIn) {
        if(currentUser.uid !== undefined){
          const manual_url = currentUser.traderKbn === "0100000000" 
            ? `https://www.sdgs12.com/manualdisp`
            : `https://s3-sdgs12com-manual.s3.ap-northeast-1.amazonaws.com/sdgs12_sousa_${currentUser.traderKbn}.pdf`;

          return (
          <>
            {isMobile ? (
              <MobileView>
              <h5>{currentUser.nickname}　　</h5>
              </MobileView>
            ) : (
              <h2>{currentUser.nickname}　　</h2>
            )}
            <Tooltip title="操作説明書">
              <a target="_blank" href={manual_url}>
                <IconButton size="large" aria-label="manual" style={{ color: grey[50] }}>
                  <MenuBookIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Button
              color='inherit'
              className={classes.linkBtn}
              onClick={handleSignOut}
            >
              ログアウト
            </Button>
          </>
        );
        }
      } else {
        return (
          <>
            <Button
              component={Link}
              to='/signin'
              color='inherit'
              className={classes.linkBtn}
            >
              ログイン
            </Button>
            {/* <Button
              component={Link}
              to='/signup'
              color='inherit'
              className={classes.linkBtn}
            >
              Sign Up
            </Button> */}
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const HeaderDrawerMenu = () => {
    if (currentUser !== undefined){
      // 排出
      if (currentUser.traderKbn === "1000000000") {
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu1}/>
          </>
        )
      // 収集運搬
      }else if(currentUser.traderKbn === "0100000000"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu2}/>
          </>
        )
      // 積替保管
      }else if(currentUser.traderKbn === "0010000000"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu3}/>
          </>
        )
      // 中間　　⇒　2023/1/6 中間は中間排出扱いとする
{/*}
      }else if(currentUser.traderKbn === "0001000000"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu4}/>
          </>
        )
*/}
      // 最終
      }else if(currentUser.traderKbn === "0000100000"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu5}/>
          </>
        )
      // 中間排出
      }else if(currentUser.traderKbn === "0001001000" ||
               currentUser.traderKbn === "0001000000"){  // 2023/1/6 中間は中間排出扱いとする
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu47}/>
          </>
        )
      // システム開発者
      }else if(currentUser.traderKbn === "1111111111"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu8}/>
          </>
        )
      // グループ管理者
      }else if(currentUser.traderKbn === "0000000001"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu001}/>
          </>
        )
      // システム管理者
      }else if(currentUser.traderKbn === "0000000010"){
        return (
          <>
            <HeaderDrawer
              open={open}
              handleDrawerToggle={handleDrawerToggle}
              drawerItem = {drawerItemMenu9}/>
          </>
        )
      }
    }else{
      return (
        <>
          <HeaderDrawer
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            drawerItem = {drawerItemMenu0}/>
        </>
      )
    }
  }

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position='fixed'>
          <Toolbar>
          <BrowserView>
            {isSignedIn && currentUser && (
              <IconButton
                edge='start'
                className={classes.menuButton}
                color='inherit'
                aria-label='menu'
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
          </BrowserView>
            {isMobile ? (
              <MobileView>
              <Typography variant='subtitle2' className={classes.title}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  産業廃棄物SDGs推進システム
                </Link>
              </Typography>
              </MobileView>
            ) : (

              <Typography variant='h6' className={classes.title}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  産業廃棄物SDGs推進システム
                </Link>
              </Typography>
            )}

            <AuthButtons />
          </Toolbar>
        </AppBar>
      </div>
      <HeaderDrawerMenu/>
    </>
  );
}
export default Header;
/*
  { label: '排出数量ドリルダウン', path: '/drilldown' },
  { label: '廃棄物状況', path: '/garbage_status' },
  { label: '006 担当者情報一覧 ～008,065', path: '/egs_m_charges' },
  { label: '009 廃棄物編集一覧 ～014、022～026、028～037、056', path: '/garbage_info' },
  { label: '026 廃棄物実績報告書', path: '/garbage_pdf' },
  { label: '015 承認待ち一覧', path: '/approves' },
  { label: '016 企業一覧 ～018', path: '/egs_m_companies' },
  { label: '018 運搬業者別積込登録集計一覧 ～019', path: '/trans_list' },
  { label: '034 廃棄物データシート一覧 ～035～037', path: '/datasheets' },
  { label: '038 許可証一覧 ～042', path: '/permit' },
  { label: '043 処理フロー一覧 ～045～049、051、054', path: '/egs_m_reserve_sets' },
  { label: '050 電子マニフェスト送り状印刷一覧 、052', path: '/invoice' },
  { label: '053 排出登録一覧 、055、057～059', path: '/exhaust_plan' },
  { label: '060 荷受登録一覧 061', path: '/receipt' },
  { label: '062 処分登録一覧 063', path: '/disposal' },
  { label: '066 許可品目情報 ～068', path: '/egs_m_handlings' },
*/
