// /src/lib/api/charge_sel.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getChargeSelList = () => {
  return client.get('/charge_sels');
};

// 詳細
export const getChargeSelDetail = (id) => {
  return client.get(`/charge_sels/${id}`);
};

// 新規作成
// header情報を追加
export const createChargeSel = (params) => {
  return client.post('/charge_sels', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateChargeSel = (id, params) => {
  return client.patch(`/charge_sels/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteChargeSel = (id) => {
  return client.delete(`/charge_sels/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
