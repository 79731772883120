// /src/lib/api/receipt_disposal.js
import client from './client';
import Cookies from 'js-cookie';

// 一覧
export const getReceiptDisposalList = () => {
  return client.get('/receipt_disposals');
};

// 事業場選択一覧
export const getReceiptDisposalUser = (id) => {
  return client.get(`/receipt_disposals/${id}/showuser`);
};

// 詳細
export const getReceiptDisposalDetail = (id) => {
  return client.get(`/receipt_disposals/${id}`);
};

// 新規作成
// header情報を追加
export const createReceiptDisposal = (params) => {
  return client.post('/receipt_disposals', params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 更新
// header情報を追加
export const updateReceiptDisposal = (id, params) => {
  return client.patch(`/receipt_disposals/${id}`, params, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};

// 削除
// header情報を追加
export const deleteReceiptDisposal = (id) => {
  return client.delete(`/receipt_disposals/${id}`, {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid'),
    },
  });
};
