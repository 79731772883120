import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SpaceRow from '../commons/SpaceRow';
import PermitEdit from "./PermitEdit";
import PermitNew from "./PermitNew";
import './app.css';

import '@grapecity/wijmo.styles/wijmo.css';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";

import {
  Button,
  TextField,
  MenuItem,
  Select,
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  Typography
} from '@material-ui/core';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCsvExportOptions,
  GridActionsCellItem,
  jaJP
} from '@mui/x-data-grid-pro'


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '許可証マスタ',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "85vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#EEE",
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function PermitListTable(props) {
  const classes = useStyles();
  const { dataList, handleDelete, currentUser } = props;
  // console.log('currentUser',currentUser);
  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log(selRows);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataList2, setDataList2] = useState([]);

  // const resdataList = dataList.filter(dataList => {
  //   return dataList.traderCd === currentUser.traderCd
  // })
  const [detailOpen, setDetailOpen] = useState(false);
  const [creOpen, setCreOpen] = useState(false);

  const handleEditOpen = () => {
    setDetailOpen(true);
  };
  const handleCreOpen = () => {
    setCreOpen(true);
  };

  const handleRowClick = (param, event) => {
    // console.log(param.row);
    setDataList2(param.row);
  };

  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  // アイコンをクリックしたときの処理
  const handleDetailClick = React.useCallback((params) => (event) => {
    event.stopPropagation();
    // console.log(`handleDetailClick:id=${params.id}`);
  }, []);


//  if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//  }else{                                       //事業場区分は管理者以外？
//    }

  if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
    const cols = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      },
      {
        field: 'traderCd',
        headerName: '事業場CD',
        width: 120
      },
      {
        field: 'traderName',
        headerName: '事業場名称',
        width: 150
      },
      {
        field: 'permitNo',
        headerName: '許可証番号',
        width: 100
      },
      {
        field: 'municipalityName',
        headerName: '発行自治体',
        width: 100
      },
      {
        field: 'permitType',
        headerName: '種別',
        width: 250
      },
      {
        field: 'dispName',
        headerName: '概要',
        width: 250
      },
      {
        field: 'content',
        headerName: '詳細',
        width: 300
      },
      {
        field: 'imgSeqno',
        headerName: '画像',
        width:70
      },
    ]

    return (
      <>
        <div className={classes.container}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
  //          pageSize={pageSize}
  //          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
  //          rowsPerPageOptions={[10, 20, 50]}
  //          pagination
            sx={styles.grid}
            columns={cols}
            rows={dataList}
            density="compact"
  //          autoHeight
            // checkboxSelection
            // disableSelectionOnClick
            // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
          </Box>
          </div>
          <PermitEdit
            isOpen={detailOpen}
            doClose={() => setDetailOpen(false)}
            processId={dataList2.id}
            currentUser={currentUser}
            dataList2={dataList2}
            />
          <PermitNew
            isOpen={creOpen}
            doClose={() => setCreOpen(false)}
//            processId={dataList2.id}
            currentUser={currentUser}
            />
        <SpaceRow height={20} />


        <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
        <div className={classes.container}>
          <Grid
            container
            spacing={0}
            className={classes.row}
            style={{
              borderTopWidth: 2,
              borderTopColor: "black"
            }}
          >
          </Grid>

            <Grid
              container
              spacing={0}
              className={classes.row}
            >
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>事業場CD</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderCd}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>事業場名称</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.traderName}</Typography>
                </Box>
              </Grid>
              <Grid item xs />
            </Grid>

            <Grid
              container
              spacing={0}
              className={classes.row}
            >
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>許可証番号</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.permitNo}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>発行自治体</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.municipalityName}</Typography>
                </Box>
              </Grid>
              <Grid item xs />
            </Grid>

            <Grid
              container
              spacing={0}
              className={classes.row}
            >
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>種別</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.permitType}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>概要</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.dispName}</Typography>
                </Box>
              </Grid>
              <Grid item xs />
            </Grid>

            <Grid
              container
              spacing={0}
              className={classes.row}
            >
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>詳細</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.content}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>画像</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box bgcolor="primary.gray" color="primary.black" p={1}>
                  <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.imgSeqno}</Typography>
                </Box>
              </Grid>
              <Grid item xs />
            </Grid>


          <Grid
            container
            spacing={0}
            className={classes.row}
            style={{
              borderTopWidth: 2,
              borderTopColor: "black"
            }}
          >
          </Grid>
          </div>

        <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 400 }} onClick={handleCreOpen}>
              新規作成
          </Button>
            <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen} disabled={!dataList2.id} >
              編集
            </Button>
            <Button variant="contained" color='secondary' onClick={delRows}  disabled={!dataList2.id} >削除</Button>
        </Box>

      </>
    );


    }else{                                       //事業場区分は管理者以外？
      const cols = [
        {
          field: 'id',
          headerName: 'ID',
          width: 70
        },
        {
          field: 'permitNo',
          headerName: '許可証番号',
          width: 100
        },
        {
          field: 'municipalityName',
          headerName: '発行自治体',
          width: 100
        },
        {
          field: 'permitType',
          headerName: '種別',
          width: 250
        },
        {
          field: 'dispName',
          headerName: '概要',
          width: 250
        },
        {
          field: 'content',
          headerName: '詳細',
          width: 300
        },
        {
          field: 'imgSeqno',
          headerName: '画像',
          width:70
        },
      ]

      return (
        <>
          <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
    //          pageSize={pageSize}
    //          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
    //          rowsPerPageOptions={[10, 20, 50]}
    //          pagination
              sx={styles.grid}
              columns={cols}
              rows={dataList}
              density="compact"
    //          autoHeight
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={(v) => selRows.current = v} /* チェックが入った行をselRowsに入れる(配列) */
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
            </Box>
            </div>
            <PermitEdit
              isOpen={detailOpen}
              doClose={() => setDetailOpen(false)}
              processId={dataList2.id}
              currentUser={currentUser}
              dataList2={dataList2}
            />
            <PermitNew
              isOpen={creOpen}
              doClose={() => setCreOpen(false)}
//              processId={dataList2.id}
              currentUser={currentUser}
            />
          <SpaceRow height={20} />


          <Box component='div' sx={{ p: 2, textAlign: 'right' }}></Box>
          <div className={classes.container}>
            <Grid
              container
              spacing={0}
              className={classes.row}
              style={{
                borderTopWidth: 2,
                borderTopColor: "black"
              }}
            >
            </Grid>

              <Grid
                container
                spacing={0}
                className={classes.row}
              >
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>許可証番号</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.permitNo}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>発行自治体</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.municipalityName}</Typography>
                  </Box>
                </Grid>
                <Grid item xs />
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes.row}
              >
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>種別</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.permitType}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>概要</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.dispName}</Typography>
                  </Box>
                </Grid>
                <Grid item xs />
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes.row}
              >
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>詳細</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.content}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.title_box} p={1} display={"flex"} alignItems={"center"}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>画像</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box bgcolor="primary.gray" color="primary.black" p={1}>
                    <Typography variant="h7" style={{ fontWeight: 700 }}>{dataList2.imgSeqno}</Typography>
                  </Box>
                </Grid>
                <Grid item xs />
              </Grid>


            <Grid
              container
              spacing={0}
              className={classes.row}
              style={{
                borderTopWidth: 2,
                borderTopColor: "black"
              }}
            >
            </Grid>
            </div>

          <Box component='div' sx={{ p: 2, textAlign: 'left' }}>
            <Button variant="contained" color="primary" style={{ marginRight: 400 }} onClick={handleCreOpen}>
                新規作成
            </Button>
              <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleEditOpen}  disabled={!dataList2.id} >
                編集
              </Button>
              <Button variant="contained" color='secondary' onClick={delRows}  disabled={!dataList2.id} >削除</Button>
          </Box>

        </>
      );

}
}
const styles = {
  grid: {
    // '.MuiDataGrid-toolbarContainer': {
    //   borderBottom: 'solid 1px rgba(224, 224, 224, 1)'
    // },
    // '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
    //   borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
    // },
     // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    }
  }
 }
export default PermitListTable;
