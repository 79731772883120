import React, { useEffect, useState, useContext } from 'react';
//import { getEgsMEntryList, deleteEgsMEntry } from '../../lib/api/egs_m_entry';
import { deleteEgsMEntry } from '../../lib/api/egs_m_entry';
//import { getEgsMEntryList } from '../../lib/api/egs_m_entry';
//import { getEntryList } from '../../lib/api/entry';
import { getEntryList, getEntryUser, getEntryGroup, getEntryGroup1, getEntryGroup2, getEntryOperation } from '../../lib/api/entry';
//import { getEgsMEntryUser } from '../../lib/api/egs_m_entry';
import { useHistory } from 'react-router-dom';
// style
import { Button,Backdrop,CircularProgress,makeStyles } from '@material-ui/core';
// component
import EgsMEntryListTable from './EgsMEntryListTable';
import SpaceRow from '../commons/SpaceRow';
// context
import { AuthContext } from '../../App';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const EgsMEntryList = () => {
  const { loading, isSignedIn, setIsSignedIn, currentUser } =
    useContext(AuthContext);
  const [dataList, setDataList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let res;

  useEffect(() => {
    handleGetList();
  }, []);

  const handleGetList = async () => {
    try {
     setOpen(!open);
     if (currentUser.traderKbn == '1111111111'){  //事業場区分は管理者？
//      res = await getEntryList();
      res = await getEntryOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '_');
    }else{                                       //事業場区分は管理者以外？
      if (currentUser.traderKbn == '0000000001'){  //事業場区分はグループ１？
//        res = await getEntryGroup1(currentUser.traderCd);
//        res = await getEntryGroup(currentUser.traderCd);
        res = await getEntryOperation(currentUser.traderCd + '_' + currentUser.traderKbn + '_');
        setDataList(res.data);
//      }else{                                       //事業場区分は管理者以外？
//        if (currentUser.traderKbn == '0000000102'){  //事業場区分はグループ１？
//          res = await getEntryGroup2(currentUser.traderCd);
//          setDataList(res.data);
        }else{                                       //事業場区分は管理者以外？
          res = await getEntryUser(currentUser.traderCd);
        }
//      }
    }    
      console.log(res.data);
      setDataList(res.data);
     setOpen(false);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  const history = useHistory();

  // const handleDelete = async (item) => {

  //  alert(' ' + item.entryName + 'を削除します。よろしいですか？')
  //   console.log('click', item.id);
  //   try {
  //     const res = await deleteEgsMEntry(item.id);
  //     console.log(res.data);
  //     handleGetList();
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // };

  const handleDelete = async (item) => {
    if (window.confirm(' 加入者「' + item.traderName + '」を削除します。よろしいですか？')) {
      try {
        const res = await deleteEgsMEntry(item.id);
        console.log(res.data);
        handleGetList();
      } catch (e) {
        console.log(e.response);
      }
    }
  };

  return (
    <>
      <h1>加入者マスタ</h1>
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_entriesSel')}
        style={{ marginRight: 10 }}
      >
        参照作成
      </Button> */}
      {/* <Button
        variant='contained'
        color='primary'
        onClick={() => history.push('/egs_m_entriesNew')}
      >
        新規作成
      </Button> */}
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EgsMEntryListTable
        dataList={dataList}
        handleDelete={handleDelete}
        currentUser={currentUser}
      />
    </>
  );
};
export default EgsMEntryList;
