import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from "react-device-detect"
import { Link, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"
import dayjs from 'dayjs';
import SpaceRow from '../commons/SpaceRow';

import { makeStyles } from '@material-ui/core/styles';
// style
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import './app.css';

import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColumnMenu,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  jaJP
} from '@mui/x-data-grid-pro'
import FormDialog from "../commons/FormDialog";
import EgsMProcessFlowEdit from "./EgsMProcessFlowEdit";
import EgsMProcessFlowEdit4 from "./EgsMProcessFlowEdit4";    // 中間処理業者用
import EgsMProcessFlowNew from "./EgsMProcessFlowNew";
import EgsMProcessFlowNew4 from "./EgsMProcessFlowNew4";    // 中間処理業者用
import EgsMProcessFlowAdd from "./EgsMProcessFlowAdd";
import EgsMProcessFlowAdd4 from "./EgsMProcessFlowAdd4";    // 中間処理業者用
import EgsTGarbageEdiAddNew from "./EgsTGarbageEdiAddNew";
import GarbageEdiQrCreate from "./GarbageEdiQrCreate";
import GarbageEdiExtQrCre from "./GarbageEdiExtQrCre";
import ProcessFlowListTableForm from "./ProcessFlowListTableForm"
import ProcessFlowPdf from "./ProcessFlowPdf"

import { toxicSubstanceName } from '../../constants'
import { getProcessFlowOperation  } from '../../lib/api/process_flow';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: '処理フロー',
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      componentsProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        // columnMenuSortItem: {
        //   displayOrder: 10, // Previously `0`
        // },
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  fontWeight: {
    fontWeight: 900,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    borderWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    marginBottom: 8,
    width: "95vw",
    margin: "auto",
  },
  row: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
  title_box: {
    background: "#CFF",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: "#CCC",
    borderStyle: "solid",
    height: "100%",
  },
  value_box: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderColor: "#CCC",
  },
}));

function ProcessFlowListTable(props) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const { dataList, handleDelete, currentUser } = props;
  const [dataList3, setDataList3] = useState([]);
  const [detailOpen1, setDetailOpen1] = useState(false);    // 編集
  const [detailOpen14, setDetailOpen14] = useState(false);  // 中間処理業者用
  const [detailOpen2, setDetailOpen2] = useState(false);
  const [detailOpen3, setDetailOpen3] = useState(false);    //新規登録
  const [detailOpen34, setDetailOpen34] = useState(false);  // 中間処理業者用
  const [detailOpen4, setDetailOpen4] = useState(false);    // 新規登録（複写）
  const [detailOpen44, setDetailOpen44] = useState(false);  // 中間処理業者用
  const [detailOpen5, setDetailOpen5] = useState(false);    // 貼付用QRコード
  const [detailOpen6, setDetailOpen6] = useState(false);    // 現場設置用QRコード
  const [detailOpen9, setDetailOpen9] = useState(false);  // 積込確認（処理フロー）PDF

  const [dataList2, setDataList2] = useState({});
  const [clickedRowId, setClickedRowId] = useState(null);

  const [qrCode, setQrCode] = React.useState(null);

  const [filteredDataList, setFilteredDataList] = React.useState([]);
  const [selectedDataList, setSelectedDataList] = React.useState([]);　//2024-01-17add
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [filter, setFilter] = React.useState({
//    exhaustDateStart: '',
//    exhaustDateEnd: '',
//    manifestNo: '',
//    wasteKindName: '',
    wasteKindName: '',
//    statusName: '',
    exhaustName: '',
    sect1TransportName: '',
    reserveNo: '',
  })

  const query = useParams();
  const pdfRef = React.createRef();

  var param_item = '';

  useEffect(() => {
    setFilteredDataList(dataList);
    // console.log('setFilteredDataList', filteredDataList);
  }, [dataList, currentUser])

  const startFilter = () => {
/*
    console.log('dataList', dataList);
    console.log('currentUser', currentUser);

    console.log('filter', filter);
    if (filter.exhaustDateStart == ''){
      filter.exhaustDateStart = dayjs().subtract(60,'d').format('YYYY-MM-DD');
    }
    if (filter.exhaustDateEnd == ''){
      filter.exhaustDateEnd = dayjs().format('YYYY-MM-DD');
    }
*/
//    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustDateStart+"_"+filter.exhaustDateEnd+"_"+filter.manifestNo+"_"+filter.statusName+"_"+filter.exhaustName+"_"+filter.wasteKindName+"_"+filter.sect1TransportName;
    param_item = currentUser.traderCd + "_"+currentUser.traderKbn + "_"+filter.exhaustName+"_"+filter.wasteKindName+"_"+filter.reserveNo+"_"+filter.sect1TransportName+"_";
    // console.log("param_item",param_item)
    handleGetList(param_item);
  }

  const handleGetList = async () => {
    setOpen2(!open2);
    try {
      const  res = await getProcessFlowOperation(param_item);
      setFilteredDataList(res.data);
    } catch (e) {
      console.log(e);
    }
    setOpen2(false);
  };
/*
  const handleGetPhotos = async () => {
      try {
      const res = await getEgsTPhotoGbgid(dataList2.id);
      setDataList3(res.data);
    } catch (e) {
      console.log(e);
    }

  };
*/
  const handleRowClick = (param, event) => {
    setDataList2(param.row);
    setClickedRowId(param.id); // 追加: クリックされた行のIDを状態に保存
      // console.log("dataList2",dataList2);
      // console.log("selRows",selRows);
  };

// チェックボックスで選択されたレコードを特定の項目に絞ってCSV形式に変換する関数
const convertToCSV = (selectedDataList) => {
  // 選択した項目のカラム名を指定
  const selectedColumns = ['id', '排出予定日', '収集担当者ID', '数量', '荷姿数量', '備考１', '備考２', '備考３', '備考４', '備考５','フロー番号','サブ番号','フロー名称','排出事業場','廃棄物種類','処分方法','単位','荷姿','運搬先業者','最終処分場']; // 必要なカラム名を指定

  // ヘッダー行を作成
  const header = selectedColumns.join(',') + '\n';

  // 選択した項目の値を取得してCSV形式に変換
  const rows = selectedDataList.map(row => {
    // 当日の日付を取得してyyyyMMdd形式にフォーマット
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');

    // 排出予定日が存在しない場合、当日の日付をセット
    if (!row['排出予定日']) {
      row['排出予定日'] = currentDate;
    }

    // 「収集担当者ID」に対応する項目をセット
    row['収集担当者ID'] = row['sect1TransportChargeId'];
    row['フロー番号'] = row['reserveNo'];
    row['サブ番号'] = row['reserveSubno'];
    row['フロー名称'] = row['reserveName'];
    row['排出事業場'] = row['exhaustName'];
    row['廃棄物種類'] = row['wasteKindName'];
    row['処分方法'] = row['disposalMethodName'];
    row['単位'] = row['unitName'];
    row['荷姿'] = row['packingName'];
    row['運搬先業者'] = row['destination1Name'];
    row['最終処分場'] = row['lastProcessingTraderName'];
    
    // 数量と荷姿数量にそれぞれ1をセット
    row['数量'] = 1;
    row['荷姿数量'] = 1;

    const rowData = selectedColumns.map(col => {
      // 排出予定日のフォーマットをyyyyMMdd形式に変換
      if (col === '排出予定日') {
        return row[col].replace(/-/g, '');
      }
      return row[col];
    });
    return rowData.join(',');
  }).join('\n');

  return header + rows;
};

// ダウンロードするCSVファイル名を生成する関数
const generateCSVFileName = () => {
  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // 当日の日付をyyyymmdd形式で取得
  return `flowdata_${currentDate}.csv`; // ファイル名を生成
};
// CSVファイルをダウンロードする関数内でファイル名を生成してダウンロードする
const downloadCSV = (csvData) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = generateCSVFileName(); // ファイル名を生成して設定
  a.click();
  window.URL.revokeObjectURL(url);
};
  // チェックボックスで選択されたレコードをエクスポートするボタンのクリックハンドラ
  const handleExportButtonClick = () => {
    // const selectedRowsData = filteredDataList.filter(row => selectedRowIds.includes(row.id));
    if (window.confirm(' 選択された処理フローの仮登録用CSVファイル（ひな型）をダウンロードします。よろしいですか？')) 
    {// 選択されたレコードをCSV形式に変換
      const csvData = convertToCSV(selectedDataList);
      // CSVデータをダウンロード
      downloadCSV(csvData);
    }
  };

  const handleOpen = () => {
    setDetailOpen1(true);
  };

  const handleOpen14 = () => {  // 中間処理業者用　編集
    setDetailOpen14(true);
  };

  const handleOpen2 = () => {
    setDetailOpen2(true);
  };

  const handleOpen3 = () => {
    setDetailOpen3(true);
  };

  const handleOpen34 = () => {  // 中間処理業者用新規作成
    setDetailOpen34(true);
  };

  const handleOpen4 = () => {
    setDetailOpen4(true);
  };

  const handleOpen44 = () => {  // 中間処理業者用　新規作成（複写）
    setDetailOpen44(true);
  };

  const handleOpen5 = () => {
    setDetailOpen5(true);
  };

  const handleOpen6 = () => {
    setDetailOpen6(true);
  };
  const handleOpen9 = () => {
    setDetailOpen9(true);
  };

  const [rows, setRows] = React.useState(dataList);
  let selRows = React.useRef([]);
  // console.log("selRows0",selRows);
  const [pageSize, setPageSize] = React.useState(10);

  // 行の削除
  const delRows = () => {
    // console.log('delRows',dataList2)
    handleDelete(dataList2);
  }

  let d = new Date();
  const garbageTag = dataList2.id + "-" + d.valueOf();
  const qrCodeUrl = "https://www.sdgs12.com/waste/" + garbageTag;
  // console.log('qrCodeUrl', qrCodeUrl);

  if (currentUser.traderKbn == '1000000000'){  //事業場区分は排出事業者？

    const cols1 = [
      {
        field: 'id',
        headerName: 'ID',
        width: 70
      },
      {
        field: 'reserveNo',
        headerName: 'フロー番号',
        width: 140
      },
      {
        field: 'reserveSubno',
        headerName: 'サブ番号',
        width: 60
      },
      {
        field: 'reserveName',
        headerName: 'フロー名称',
        width: 300
      },
/*
      {
        field: 'exhaustName',
        headerName: '排出事業場',
        width: 300
      },
*/
      {
        field: 'wasteKindName',
        headerName: '廃棄物種類',
        width: 400
      },
      {
        field: 'disposalMethodName',
        headerName: '処分方法',
        width: 100
      },
      {
        field: 'unitName',
        headerName: '単位',
        width: 100
      },
      {
        field: 'packingName',
        headerName: '荷姿',
        width: 100
      },
      {
        field: 'sect1TransportName',
        headerName: '運搬業者',
        width: 300
      },
      {
        field: 'lastProcessingTraderName',
        headerName: '最終処分場',
        width: 300
      }
    ]

    return (
      <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />           
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　運搬業者：</Typography>
            <TextField
              id="sect1TransportName"
              value={filter.sect1TransportName}
              onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols1}  // cols
              rows={filteredDataList}
              density="compact"
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
        <EgsTGarbageEdiAddNew
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList2={dataList2}
          garbageTag={garbageTag}
        />
        <GarbageEdiQrCreate
          isOpen={detailOpen5}
          doClose={() => setDetailOpen5(false)}
          dataList2={dataList2}
        />

        <ProcessFlowListTableForm
          dataList2={dataList2}
        />
        <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（新規登録用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
        {/* <Button variant="contained" color="primary" style={{ marginRight: 100 }} onClick={handleOpen2} disabled={!dataList2.id}>
            排出登録
        </Button> */}
        <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen5} disabled={!dataList2.id}>
            貼付用ＱＲコード
        </Button>
        </Box>
        </BrowserView>
      </>
    );
}else if (currentUser.traderKbn == '0100000000'){   //事業場区分は収集運搬業者？

  const cols2 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70
    },
    {
      field: 'reserveNo',
      headerName: 'フロー番号',
      width: 140
    },
    {
      field: 'reserveSubno',
      headerName: 'サブ番号',
      width: 60
    },
    {
      field: 'reserveName',
      headerName: 'フロー名称',
      width: 300
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteKindName',
      headerName: '廃棄物種類',
      width: 400
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width: 100
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 100
    },
/*
    {
      field: 'sect1TransportName',
      headerName: '運搬業者',
      width: 300
    },
*/
    {
      field: 'destination1Name',
      headerName: '運搬先業者',
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    }
  ]


  if((currentUser.traderCd == '400293810220') ||      //事業者は大谷化学工業？
     (currentUser.traderCd == '407615460120') ||      //事業者はケア・ルートサービス？
     (currentUser.traderCd == '012765440120')) {      //事業者は北海道メディカルソリューション？
    return (      // 排出登録あり
      <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
            <TextField
              id="exhaustName"
              value={filter.exhaustName}
              onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols2}  // cols
              rows={filteredDataList}  // dataList
              density="compact"
              checkboxSelection
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                },
              }}
              disableSelectionOnClick
              onSelectionModelChange={(newSelection) => {
                const selectedRowsData = filteredDataList.filter((row) =>
                  newSelection.includes(row.id)
                );
                setSelectedDataList(selectedRowsData);
              }}
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
              getRowClassName={(params) =>
                params.id === clickedRowId ? 'clicked-row' : ''
              }
            />
          </Box>
        </div>

        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography  variant="h6" style={{ fontWeight: 'bold' }}>
              ↑ ＱＲコードまたは排出仮登録用CSVファイルを作成する処理フローを選択
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginLeft: '10px', marginRight: '10px', fontSize: '1.0em' }} 
              onClick={handleOpen6}
              disabled={!selectedDataList.length} // チェックされたデータがない場合はボタンを非活性化
            >
              現場設置用ＱＲコード作成
            </Button>

            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginRight: '10px', fontSize: '1.0em' }} 
              onClick={handleExportButtonClick}
              disabled={!selectedDataList.length} // チェックされたデータがない場合はボタンを非活性化
            >
              排出仮登録用CSVファイル（ひな型）ダウンロード
            </Button>
          </div>

          <Button variant="outlined" color='primary' onClick={handleOpen9} disabled={!dataList2.id }>
            積込確認（処理フロー）PDF
          </Button>
        </Box>
        <ProcessFlowPdf
          currentUser={currentUser}
          isOpen={detailOpen9}
          doClose={() => setDetailOpen9(false)}
          dataList2={dataList2}
        />
        <EgsTGarbageEdiAddNew
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList2={dataList2}
          garbageTag={garbageTag}
        />
        <GarbageEdiQrCreate
          isOpen={detailOpen5}
          doClose={() => setDetailOpen5(false)}
          dataList2={dataList2}
        />
        <GarbageEdiExtQrCre
          isOpen={detailOpen6}
          doClose={() => setDetailOpen6(false)}
          dataList={selectedDataList}
          selRows={selRows.current}
        />
        <EgsMProcessFlowNew       // 新規登録
          isOpen={detailOpen3}
          doClose={() => setDetailOpen3(false)}
          currentUser={currentUser}
        />
        <EgsMProcessFlowAdd       // 新規登録（複写）
          isOpen={detailOpen4}
          doClose={() => setDetailOpen4(false)}
          dataList2={dataList2}
          currentUser={currentUser}
        />
        <EgsMProcessFlowEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          dataList2={dataList2}
        />
        <ProcessFlowListTableForm
          dataList2={dataList2}
        />
        <BrowserView>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen3}>
            新規登録
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen4} disabled={!dataList2.id}>
            新規登録（複写）
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
        </Box>
        </BrowserView>
        <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（新規登録用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
        <Button variant="contained" color="primary" style={{ marginRight: 100, fontSize: '1.2em' }} onClick={handleOpen2} disabled={!dataList2.id}>
            排出登録（マニフェスト情報作成）
        </Button>
        <BrowserView>
        <SpaceRow height={30} />
        <Button variant="contained" color="primary" style={{ marginRight: 50, fontSize: '1.0em' }} onClick={handleOpen5} disabled={!dataList2.id}>
            貼付用ＱＲコード
        </Button>
        </BrowserView>
        <MobileView>
          <SpaceRow height={30} />
          <Button variant='contained' onClick={() => history.push('/')} style={{ marginRight: 100 }}>
            ホームへ戻る
          </Button>
        </MobileView>
        </Box>
        </BrowserView>
      </>
    );
  }else{       // 収集運搬業者

    return (
      <>
      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
            <TextField
              id="exhaustName"
              value={filter.exhaustName}
              onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

        <div className={classes.container}>
          <Box sx={{ height: 520, width: '100%' }}>
            <DataGridPro
              sx={styles.grid}
              columns={cols2}  // cols
              rows={filteredDataList}  // dataList
              density="compact"
              checkboxSelection
              initialState={{
                pinnedColumns: {
                  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                },
              }}
              disableSelectionOnClick
              onSelectionModelChange={(newSelection) => {
                const selectedRowsData = filteredDataList.filter((row) =>
                  newSelection.includes(row.id)
                );
                setSelectedDataList(selectedRowsData);
              }}
              components={{
                Toolbar: CustomToolbar,　// カスタムツールバーを指定する
              }}
              showColumnRightBorder // 列ヘッダセルの右側に線を引く
              showCellRightBorder   // セルの右側に線を引く
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              onRowClick={handleRowClick}
              getRowClassName={(params) =>
                params.id === clickedRowId ? 'clicked-row' : ''
              }
            />
          </Box>
        </div>

        <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography  variant="h6" style={{ fontWeight: 'bold' }}>
              ↑ ＱＲコードまたは排出仮登録用CSVファイルを作成する処理フローを選択
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginLeft: '10px', marginRight: '10px', fontSize: '1.0em' }} 
              onClick={handleOpen6}
              disabled={!selectedDataList.length} // チェックされたデータがない場合はボタンを非活性化
            >
              現場設置用ＱＲコード作成
            </Button>

            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginRight: '10px', fontSize: '1.0em' }} 
              onClick={handleExportButtonClick}
              disabled={!selectedDataList.length} // チェックされたデータがない場合はボタンを非活性化
            >
              排出仮登録用CSVファイル（ひな型）ダウンロード
            </Button>
          </div>

          {/* <Button variant="outlined" color='primary' onClick={handleOpen9} disabled={!dataList2.id }>
            積込確認（処理フロー）PDF
          </Button> */}
        </Box>

        <ProcessFlowPdf
          currentUser={currentUser}
          isOpen={detailOpen9}
          doClose={() => setDetailOpen9(false)}
          dataList2={dataList2}
        />
        <EgsTGarbageEdiAddNew
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList2={dataList2}
          garbageTag={garbageTag}
        />
        <GarbageEdiQrCreate
          isOpen={detailOpen5}
          doClose={() => setDetailOpen5(false)}
          dataList2={dataList2}
        />
        <GarbageEdiExtQrCre
          isOpen={detailOpen6}
          doClose={() => setDetailOpen6(false)}
          dataList={selectedDataList}
          selRows={selRows.current}
        />
        <EgsMProcessFlowNew       // 新規登録
          isOpen={detailOpen3}
          doClose={() => setDetailOpen3(false)}
          currentUser={currentUser}
        />
        <EgsMProcessFlowAdd       // 新規登録（複写）
          isOpen={detailOpen4}
          doClose={() => setDetailOpen4(false)}
          dataList2={dataList2}
          currentUser={currentUser}
        />
        <EgsMProcessFlowEdit
          isOpen={detailOpen1}
          doClose={() => setDetailOpen1(false)}
          dataList2={dataList2}
        />
        <ProcessFlowListTableForm
          dataList2={dataList2}
        />
        <BrowserView>
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen3}>
            新規登録
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen4} disabled={!dataList2.id}>
            新規登録（複写）
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
        </Box>
        </BrowserView>
        <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（新規登録用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
        {/* <Button variant="contained" color="primary" style={{ marginRight: 100, fontSize: '1.2em' }} onClick={handleOpen2} disabled={!dataList2.id}>
            排出登録（マニフェスト情報作成）
        </Button> */}
        <BrowserView>
        <SpaceRow height={30} />
        <Button variant="contained" color="primary" style={{ marginRight: 50, fontSize: '1.0em' }} onClick={handleOpen5} disabled={!dataList2.id}>
            貼付用ＱＲコード
        </Button>
        </BrowserView>
        <MobileView>
          <SpaceRow height={30} />
          <Button variant='contained' onClick={() => history.push('/')} style={{ marginRight: 100 }}>
            ホームへ戻る
          </Button>
        </MobileView>
        </Box>
        </BrowserView>
      </>
    );
  }
}else if ((currentUser.traderKbn == '0010000000') ||
          (currentUser.traderKbn == '0010000000') ||
          (currentUser.traderKbn == '0000100000')){ //事業場区分は積替保管または中間処理または最終処分業者？

            const cols4 = [
              {
                field: 'id',
                headerName: 'ID',
                width: 70
              },
              {
                field: 'reserveNo',
                headerName: 'フロー番号',
                width: 140
              },
              {
                field: 'reserveSubno',
                headerName: 'サブ番号',
                width: 60
              },
              {
                field: 'reserveName',
                headerName: 'フロー名称',
                width: 300
              },
              {
                field: 'exhaustName',
                headerName: '排出事業場',
                width: 300
              },
              {
                field: 'wasteKindName',
                headerName: '廃棄物種類',
                width: 400
              },
              {
                field: 'disposalMethodName',
                headerName: '処分方法',
                width: 100
              },
              {
                field: 'unitName',
                headerName: '単位',
                width: 100
              },
              {
                field: 'packingName',
                headerName: '荷姿',
                width: 100
              },
                      {
                field: 'sect1TransportName',
                headerName: '運搬業者',
                width: 300
              },
              {
                field: 'lastProcessingTraderName',
                headerName: '最終処分場',
                width: 300
              }
            ]

  return (
    <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
            <TextField
              id="exhaustName"
              value={filter.exhaustName}
              onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

      <div className={classes.container}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols4}  // cols
            rows={filteredDataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
      <EgsTGarbageEdiAddNew
        currentUser={currentUser}
        isOpen={detailOpen2}
        doClose={() => setDetailOpen2(false)}
        dataList2={dataList2}
        garbageTag={garbageTag}
      />
      <ProcessFlowListTableForm
        dataList2={dataList2}
      />
      <Box component='div' sx={{ textAlign: 'left' }}>
      <h3>ＱＲコード（新規登録用）</h3>
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>
      </Box>
      </BrowserView>
    </>
  );
}else if ((currentUser.traderKbn == '0001001000') ||
          (currentUser.traderKbn == '0001000000')) {   //事業場区分は中間処理・排出事業者？

            const cols5 = [
              {
                field: 'id',
                headerName: 'ID',
                width: 70
              },
              {
                field: 'reserveNo',
                headerName: 'フロー番号',
                width: 140
              },
              {
                field: 'reserveSubno',
                headerName: 'サブ番号',
                width: 60
              },
              {
                field: 'reserveName',
                headerName: 'フロー名称',
                width: 300
              },
              {
                field: 'exhaustName',
                headerName: '排出事業場',
                width: 300
              },
              {
                field: 'wasteKindName',
                headerName: '廃棄物種類',
                width: 400
              },
              {
                field: 'disposalMethodName',
                headerName: '処分方法',
                width: 100
              },
              {
                field: 'unitName',
                headerName: '単位',
                width: 100
              },
              {
                field: 'packingName',
                headerName: '荷姿',
                width: 100
              },
                      {
                field: 'sect1TransportName',
                headerName: '運搬業者',
                width: 300
              },
              {
                field: 'lastProcessingTraderName',
                headerName: '最終処分場',
                width: 300
              }
            ]

  return (
    <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />           
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　運搬業者：</Typography>
            <TextField
              id="sect1TransportName"
              value={filter.sect1TransportName}
              onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

      <div className={classes.container}>
      <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols5}  // cols
            rows={filteredDataList}  // dataList
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
        <EgsTGarbageEdiAddNew
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList2={dataList2}
          garbageTag={garbageTag}
        />
        <EgsMProcessFlowNew4       // 新規登録  // 中間処理業者用
          isOpen={detailOpen34}
          doClose={() => setDetailOpen34(false)}
          currentUser={currentUser}
        />
        <EgsMProcessFlowAdd4       // 新規登録（複写）  // 中間処理業者用
          isOpen={detailOpen44}
          doClose={() => setDetailOpen44(false)}
          dataList2={dataList2}
          currentUser={currentUser}
        />
        <EgsMProcessFlowEdit4       // 編集  // 中間処理業者用
          isOpen={detailOpen14}
          doClose={() => setDetailOpen14(false)}
          dataList2={dataList2}
        />
        <ProcessFlowListTableForm
          dataList2={dataList2}
        />
        <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen34}>
            新規登録
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen44} disabled={!dataList2.id}>
            新規登録（複写）
          </Button>
          <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen14} disabled={!dataList2.id}>
            編集
          </Button>
          <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
            削除
          </Button>
        </Box>
        <Box component='div' sx={{ textAlign: 'left' }}>
        <h3>ＱＲコード（新規登録用）</h3>
        <QRCode value={qrCodeUrl} />
        <p>{qrCodeUrl}</p>
          <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen2} disabled={!dataList2.id}>
            ２次マニフェスト登録
          </Button>
        </Box>
        </BrowserView>
    </>
  );
}else{

  const cols6 = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70
    },
    {
      field: 'reserveNo',
      headerName: 'フロー番号',
      width: 140
    },
    {
      field: 'reserveSubno',
      headerName: 'サブ番号',
      width: 60
    },
    {
      field: 'reserveName',
      headerName: 'フロー名称',
      width: 300
    },
    {
      field: 'exhaustName',
      headerName: '排出事業場',
      width: 300
    },
    {
      field: 'wasteKindName',
      headerName: '廃棄物種類',
      width: 400
    },
    {
      field: 'disposalMethodName',
      headerName: '処分方法',
      width: 100
    },
    {
      field: 'unitName',
      headerName: '単位',
      width: 100
    },
    {
      field: 'packingName',
      headerName: '荷姿',
      width: 100
    },
    {
      field: 'sect1TransportName',
      headerName: '運搬業者',
      width: 300
    },
    {
      field: 'lastProcessingTraderName',
      headerName: '最終処分場',
      width: 300
    }
  ]

  return (
    <>

      <BrowserView>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
            <Typography variant="h7" style={{ fontWeight: 700 }}>　フロー番号：</Typography>
            <TextField
              id="reserveNo"
              value={filter.reserveNo}
              onChange={e => setFilter({ ...filter, reserveNo: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　排出事業場：</Typography>
            <TextField
              id="exhaustName"
              value={filter.exhaustName}
              onChange={e => setFilter({ ...filter, exhaustName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />       
            <Typography variant="h7" style={{ fontWeight: 700 }}>　廃棄物種類：</Typography>
            <TextField
              id="wasteKindName"
              value={filter.wasteKindName}
              onChange={e => setFilter({ ...filter, wasteKindName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
            <Typography variant="h7" style={{ fontWeight: 700 }}>　運搬業者：</Typography>
            <TextField
              id="sect1TransportName"
              value={filter.sect1TransportName}
              onChange={e => setFilter({ ...filter, sect1TransportName: e.target.value })}
              label=""
              type="text"
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  startFilter();
                }
              }}
            />
           <Button
              type='button' variant='contained' color='primary' onClick={(e) => { e.preventDefault(); startFilter(); }} style={{ marginLeft: 24 }} >
              データ表示
            </Button>
            　　初期表示：必要に応じて検索キーを入力し「データ表示」を押して下さい　MAX：3000件{/*　初期表示：最新60日のデータを表示*/}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={open2} >
              <CircularProgress color="inherit" />
        </Backdrop>

      <div className={classes.container}>
        <Box sx={{ height: 520, width: '100%' }}>
          <DataGridPro
            sx={styles.grid}
            columns={cols6}  // cols
            rows={filteredDataList}
            density="compact"
            components={{
              Toolbar: CustomToolbar,　// カスタムツールバーを指定する
            }}
            showColumnRightBorder // 列ヘッダセルの右側に線を引く
            showCellRightBorder   // セルの右側に線を引く
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>

      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <EgsTGarbageEdiAddNew
          currentUser={currentUser}
          isOpen={detailOpen2}
          doClose={() => setDetailOpen2(false)}
          dataList2={dataList2}
          garbageTag={garbageTag}
      />
      <GarbageEdiQrCreate
        isOpen={detailOpen5}
        doClose={() => setDetailOpen5(false)}
        dataList2={dataList2}
      />
      <EgsMProcessFlowNew       // 新規登録
        isOpen={detailOpen3}
        doClose={() => setDetailOpen3(false)}
        currentUser={currentUser}
      />
      <EgsMProcessFlowAdd       // 新規登録（複写）
        isOpen={detailOpen4}
        doClose={() => setDetailOpen4(false)}
        dataList2={dataList2}
      />
      <EgsMProcessFlowEdit
        isOpen={detailOpen1}
        doClose={() => setDetailOpen1(false)}
        dataList2={dataList2}
      />
      <ProcessFlowListTableForm
        dataList2={dataList2}
      />
      <Box component='div' sx={{ p: 2, textAlign: 'right' }}>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen3}>
          新規登録
        </Button>
        <Button variant="contained" color="primary" style={{ marginRight: 50 }} onClick={handleOpen4} disabled={!dataList2.id}>
          新規登録（複写）
        </Button>
        <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={handleOpen} disabled={!dataList2.id}>
          編集
        </Button>
        <Button variant="contained" color='secondary' onClick={delRows} disabled={!dataList2.id}>
          削除
        </Button>
      </Box>
      <Box component='div' sx={{ textAlign: 'left' }}>
      <h3>ＱＲコード（新規登録用）</h3>
      <QRCode value={qrCodeUrl} />
      <p>{qrCodeUrl}</p>
      <Button variant="contained" color="primary" style={{ marginRight: 100, fontSize: '1.2em' }} onClick={handleOpen2} disabled={!dataList2.id}>
          排出登録（マニフェスト情報作成）
      </Button>
      <SpaceRow height={30} />
      <Button variant="contained" color="primary" style={{ marginRight: 50, fontSize: '1.0em' }} onClick={handleOpen5} disabled={!dataList2.id}>
          貼付用ＱＲコード
      </Button>
        </Box>
        </BrowserView>
    </>
  );
}};
const styles = {
  grid: {
    // 列ヘッダに背景色を指定
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: '#65b2c6',
      color: '#fff',
    },
    // 選択した行の背景色を薄黄色に指定
    '.MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'rgba(255, 255, 0, 0.2)',
    },
    // クリックで選択した行の背景色を薄桃色に指定
    '& .MuiDataGrid-row:hover': {
      backgroundColor: 'rgba(220, 220, 220, 0.7)', // 薄灰色
    },
    '& .clicked-row': {
      backgroundColor: 'rgba(255, 182, 193, 0.3) !important', // 薄桃色
    },
  }
}
export default ProcessFlowListTable;
